import {ModelInterface} from './entity';
import {Place} from './place';
import {ComsumePaymentData} from './request';

export enum PlatformEventType {
  ACCOUNT_CREATED = 0,
  ACCOUNT_CONFIRMED = 1,
  ACCOUNT_CHANGED_PASSWORD = 3,
  ACCOUNT_LOGIN_OK = 4,
  // ACCOUNT_LOGIN_BAD = 5,
  PAYMENT_CREATED = 6,
  PAYMENT_USED = 7,
  PAYMENT_DUPLICATED = 8,
  PAYMENT_REFUNDED = 9,
  PAYMENT_VALIDATED = 10,
  PAYMENT_UPDATED = 11,
  PAYMENT_REFUSED = 12,
  PAYMENT_SUSPEND_CHANGE = 13,
  PAYMENT_LOCKED = 14,
  PAYMENT_UNLOCKED = 15,
  PAYMENT_CHARGED_BACK = 16,
  PAYMENT_EXTENDED = 17,
  PAYMENT_LINKED_CREATED = 18,
  PAYMENT_USAGE_VOIDED = 19,
  PAYMENT_VOIDED = 20,
  PAYMENT_CHANGED = 21,
  PAYMENT_PRINTED = 22,
  PAYMENT_FINALIZED = 23,
  PAYMENT_USED_FORCED = 24
}

export interface PlatformEvent extends ModelInterface<number> {
  type: PlatformEventType;
  context: string;
  actor_id: number;
  payment_id: string;
  place_id: string;
  internalIndexedData: string;
  consumeData?: ComsumePaymentData
  place?: Place;
}
