/* tslint:disable */
export const fr_overrides_strings = {
  'summary.warning_validity_list': '<b>AVIS IMPORTANT :</b> Veuillez noter que la validité de l’autorisation de voyage électronique pour la/les personne(s) suivante(s) commence à la date de délivrance, <b><u>NON</u></b> à la date d’entrée au Zimbabwe : <b>{names}</b>.<br><br>La durée autorisée du séjour sera <b> déterminée par les autorités de l’immigration au point d’entrée à l’arrivée (généralement 30 jours)</b>. Veuillez planifier votre voyage en conséquence.',
  'summary.warning_validity': '<b>AVIS IMPORTANT :</b> Veuillez noter que la validité de l’autorisation de voyage électronique pour ce visa commence à la date de délivrance, <b><u>NON</u></b> à la date d’entrée au Zimbabwe. <b>La durée autorisée du séjour sera déterminée par les autorités de l’immigration au point d’entrée à l’arrivée (généralement 30 jours)</b>. Veuillez planifier votre voyage en conséquence.',
  'UsageUntil': 'Valide jusqu\'à',
  'updateField.desc': 'Veuillez fournir les informations mises à jour pour le champ suivant : <b>{field}</b>',
  'global.checkOut.withoutCamera': 'Sortie sans caméra',
  'global.checkIn.withoutCamera': 'Entrée sans caméra',
  'tooltip.checkInWithoutCamera': 'Enregistrer la personne sans utiliser la caméra',
  'global.checkOut.withCamera': 'Sortie avec caméra',
  'supervision.zweentry': 'Entrée nécessitant l\'approbation d\'un superviseur',
  'DYN.permanent-resident-permit': 'Permis de résident permanent',
  'DYN.permanent-resident-permit.desc': 'Un permis de résident permanent permet une résidence indéfinie au Zimbabwe. Il est généralement délivré aux personnes ayant résidé dans le pays pendant une période prolongée et répondant aux critères d\'éligibilité.',
  'changeFingerprint': 'Changer d\'empreinte digitale',
  'scan.supervisor.timeout': 'Souhaitez-vous contourner la vérification de l\'empreinte digitale du superviseur ?',
  'supervision.manualCatC': 'Entrée manuelle pour un pays de catégorie C',
  'supervision.blacklist': 'Candidat sur liste noire',
  'supervision.specifiedNationals': 'Pays nécessitant une supervision',
  'ask.downgrade': 'Êtes-vous sûr de vouloir rétrograder cette demande à un visa à entrée unique ? Cette action est irréversible.',
  'checkin': 'Enregistrement',
  'checkout': 'Sortie',
  'scan.addFingerprint.error': 'Une erreur s\'est produite lors de l\'ajout de l\'empreinte digitale. Veuillez réessayer.',
  'scan.supervisor.wrongFingerprint': 'L\'empreinte digitale ne correspond pas à celle du superviseur enregistrée dans le système. Veuillez réessayer.',
  'scan.now': 'Scanner maintenant',
  'scan.fingerprintSupervisor.desc': 'Lorsque vous êtes prêt, en tant que superviseur, placez votre doigt sur le scanner et cliquez sur <b>Scanner maintenant</b> pour procéder à l\'action suivante :</br><b>{actionText}</b>',
  'scan.supervisor.noFingerprint': 'Aucune donnée d\'empreinte digitale trouvée pour ce nom de compte superviseur',
  'scan.supervisor.noAccountName': 'Aucun nom de compte superviseur fourni',
  'supervisorAccountName': 'Nom du compte superviseur',
  'scan.supervisor.desc': 'Veuillez entrer le nom de compte du superviseur pour procéder à l\'action suivante :</br><b>{actionText}</b>',
  'addFingerprint': 'Ajouter une empreinte digitale',
  'all.supervisions': 'Toutes les révisions de supervision',
  'tooltip.allSupervisions': 'Une liste de toutes les révisions soumises concernant la supervision de la demande.',
  'refusalReason.doSupervisor.desc.DYN.zwevisa': 'Veuillez fournir une brève description de la raison du <b>REFUS</b>. Ce message sera envoyé à l\'agent de la frontière.',
  'approveOrRefuse': 'Approuver ou refuser',
  'request.do_supervisor_review': 'Révision par un superviseur',
  'request.do_supervisor_review.desc': 'Un superviseur peut réviser la demande pour s\'assurer que les informations fournies sont exactes et conformes aux réglementations du pays.',
  'request.askSupervisorReview': 'Votre révision a été soumise avec succès.',
  'refusalReason.reviewSupervisor.desc.DYN.zwevisa': 'Veuillez fournir une brève description de la révision comme <b>NON RECOMMANDÉE</b>. La révision sera envoyée au superviseur pour approbation.',
  'request.askSupervisorReview.error': 'Une erreur s\'est produite lors de la révision de la demande. Veuillez réessayer.',
  'askSupervisorReview': 'Quelle est votre recommandation ? Votre révision sera envoyée au superviseur pour approbation.',
  'doSupervisorReview': 'Souhaitez-vous approuver ou refuser cette demande ? En fonction de votre décision, l\'agent de la frontière pourra enregistrer ou non le candidat.',
  'request.ask_supervisor_review': 'Révision au superviseur',
  'request.supervisor_review': 'Vous pouvez demander une révision par un superviseur pour cette demande. Le superviseur révisera la demande et vous pourrez ensuite continuer le processus.',
  'place.countriesSelected': 'Sélectionnez les pays qui nécessiteront une entrée supervisée',
  'visa.catC.ordinary': 'Cette demande concerne l\'entrée sans visa pour les personnes détenant une <b>nationalité de catégorie C</b> et un <b>passeport ordinaire</b>. L\'entrée peut être uniquement <b>REFUSÉE</b> au candidat.',
  'err.AddressZimbabwe.required': 'Adresse au Zimbabwe requise',
  'zwevisaextc.title': 'Extension de séjour pour la catégorie C',
  'zwevisaextab.title': 'Extension de séjour pour les catégories A ou B',
  'DYN.payment_text.zwevisaextc': 'L\'extension de séjour pour la catégorie C vous permet de prolonger votre séjour au Zimbabwe.',
  'DYN.payment_text.zwevisaextab': 'L\'extension de séjour pour les catégories A ou B vous permet de prolonger votre séjour au Zimbabwe.',
  'extend.start': 'Une demande d\'extension de séjour peut être soumise pour un séjour prolongé au Zimbabwe. Au cours du processus d\'extension, vous pourriez être amené à fournir des informations ou des documents supplémentaires. Veuillez sélectionner le nombre de jours pour lesquels vous souhaitez prolonger votre visa à la page suivante.',
  'visaExtend.desc': 'Vous pouvez prolonger votre visa pour un séjour plus long au Zimbabwe. Veuillez sélectionner le nombre de jours pour lesquels vous souhaitez prolonger votre visa.',
  'kiosk.standby.title': 'PRÊT À DÉCOUVRIR LE ZIMBABWE ?',
  'kiosk.standbyDesc.title': 'Bienvenue au Kiosque de Déclaration d\'Entrée du Zimbabwe',
  'kiosk.standbyDesc.li4': 'Un agent de l\'immigration procédera ensuite au contrôle final et validera votre entrée au Zimbabwe.',
  'kiosk.standbyDesc.text3': 'Nous vous souhaitons un agréable séjour au Zimbabwe !',
  'kiosk.success.text3': 'Nous vous souhaitons un agréable séjour et de merveilleuses découvertes au Zimbabwe !',
  'kiosk.qr.url': 'https://www.evisa.gov.zw/',
  'PARX_SUB3_SEC12': 'Paragraphe X Sous-section 3 Section 12',
  'PARB_SEC17': 'Paragraphe B Section 17',
  'SUB1_SEC18': 'Sous-section 1 Section 18',
  'PARX_SUB1_SEC14': 'Paragraphe X Sous-section 1 Section 14',
  'SUBPARIII_PARF_SUB1_SEC14': 'Sous-paragraphe III Paragraphe F Sous-section 1 Section 14',
  'err.addressZimbabwe.required': 'Adresse au Zimbabwe requise',
  'PreviousConvictionsZimAsk': 'Avez-vous, ou l\'un de vos proches, été condamné pour un crime au Zimbabwe ?',
  'err.PreviousConvictionsZimAsk.required': 'Veuillez répondre à la question sur les condamnations antérieures au Zimbabwe',
  'err.PreviousConvictionsZim.required': 'Les condamnations antérieures au Zimbabwe sont requises',
  'form.plannedEntry': 'Quel est le point d\'entrée prévu au Zimbabwe ?',
  'form.plannedEntry.desc': 'Veuillez sélectionner le point d\'entrée par lequel vous prévoyez d\'entrer au Zimbabwe.',
  'zwevisa.DYN.desc': 'Permet une entrée unique dans le pays dans un délai spécifié d\'un (1) mois, nécessitant un nouveau visa pour les entrées ultérieures.',
  'zwevisa.DYN.long_desc': 'Ce visa permet une entrée unique dans le pays dans un délai spécifié d\'un (1) mois.</br>Il est adapté aux voyageurs prévoyant une seule visite au Zimbabwe dans un court laps de temps.</br>Après l\'entrée initiale, un nouveau visa est requis pour les visites ultérieures, ce qui le rend moins pratique pour ceux qui ont besoin de faire plusieurs visites dans un court délai.',
  'zwevisa_double.DYN.desc': 'Permet deux entrées distinctes dans le pays dans un délai de trois (3) mois, idéal pour les voyageurs ayant prévu plusieurs visites.',
  'zwevisa_double.DYN.long_desc': 'Le visa à double entrée permet deux entrées distinctes dans le pays dans un délai de trois (3) mois.</br>Ce type de visa est idéal pour les voyageurs ayant prévu plusieurs visites, comme ceux qui font des affaires ou assistent à des événements au Zimbabwe.</br>Il offre plus de flexibilité que le visa à entrée unique mais nécessite néanmoins un nouveau visa pour les visites supplémentaires au-delà des deux entrées autorisées.',
  'zwevisa_multiple.DYN.desc': 'Accorde au titulaire plusieurs entrées dans le pays de destination sur une période spécifiée de six (6) mois, facilitant les déplacements fréquents pour affaires ou tourisme.',
  'zwevisa_multiple.DYN.long_desc': 'Ce visa accorde au titulaire plusieurs entrées dans le pays de destination sur une période spécifiée de six (6) mois.</br>Il convient aux voyageurs fréquents qui doivent visiter le Zimbabwe à plusieurs reprises pour affaires ou tourisme.</br>Le visa à entrées multiples offre le plus de flexibilité parmi les types de visa mentionnés, permettant des entrées illimitées dans la période désignée.',
  'zwevisa_kaza.DYN.desc': 'Un visa spécial permettant l\'entrée dans plusieurs pays de la zone de conservation transfrontalière de Kavango Zambezi (KAZA TFCA) pour une période d\'un (1) mois, favorisant les efforts de conservation et de promotion du tourisme régional.',
  'zwevisa_kaza.DYN.long_desc': 'Le KAZA UNIVISA est un visa spécial qui permet l\'entrée dans plusieurs pays de la zone de conservation transfrontalière de Kavango Zambezi (KAZA TFCA) pour une période d\'un (1) mois.</br>Il favorise le tourisme régional et les efforts de conservation en facilitant les déplacements entre les pays participants, dont <b>le Zimbabwe</b>, <b>la Zambie</b>, et <b>le Botswana</b>.</br>Le KAZA UNIVISA est valable pour 30 jours et permet des entrées multiples dans la zone KAZA TFCA.',
  'zwevisaarr.DYN.desc': 'Permet aux voyageurs éligibles d\'obtenir un visa à l\'arrivée au point d\'entrée de la destination, simplifiant ainsi le processus d\'entrée pour les visites de courte durée. Ce visa a une durée de validité d\'un (1) mois.',
  'zwevisaarr.DYN.long_desc': 'Ce visa permet aux voyageurs éligibles d\'obtenir un visa à l\'arrivée au point d\'entrée de la destination, simplifiant ainsi le processus d\'entrée pour les visites de courte durée.</br>Il est adapté aux voyageurs qui n\'ont pas le temps ou les ressources pour demander un visa à l\'avance.</br>Le visa à l\'arrivée est généralement valable pour une courte période, comme 30 jours, et peut avoir des restrictions sur le nombre d\'entrées autorisées. Ce visa a une durée de validité d\'un (1) mois.',
  'zwevisaarr_double.DYN.desc': 'Permet aux voyageurs éligibles d\'obtenir un visa permettant deux entrées à l\'arrivée au point d\'entrée de la destination, offrant une flexibilité pour plusieurs visites. Ce visa est valable dans un délai spécifié de trois (3) mois.',
  'zwevisaarr_double.DYN.long_desc': 'Le visa à double entrée à l\'arrivée permet aux voyageurs éligibles d\'obtenir un visa permettant deux entrées à l\'arrivée au point d\'entrée de la destination.</br>Il offre une flexibilité aux voyageurs qui ont besoin de faire plusieurs visites pendant la période de validité du visa.</br>Ce type de visa est adapté aux voyageurs d\'affaires ou aux touristes prévoyant de visiter le Zimbabwe et d\'autres pays voisins. Ce visa est valable dans un délai spécifié de trois (3) mois.',
  'zwevisaarr_multiple.DYN.desc': 'Accorde aux voyageurs éligibles un visa permettant des entrées multiples à l\'arrivée au point d\'entrée de la destination, répondant aux besoins des visiteurs fréquents. Ce visa est valable dans un délai spécifié de six (6) mois.',
  'zwevisaarr_multiple.DYN.long_desc': 'Ce visa accorde aux voyageurs éligibles un visa permettant des entrées multiples à l\'arrivée au point d\'entrée de la destination.</br>Il répond aux besoins des visiteurs fréquents qui doivent se rendre plusieurs fois au Zimbabwe sur une courte période.</br>Le visa à entrées multiples à l\'arrivée offre le plus de flexibilité parmi les types de visa mentionnés, permettant des entrées illimitées dans la période désignée. Ce visa est valable dans un délai spécifié de six (6) mois.',
  'zwevisaarr_kaza.DYN.desc': 'Une variante du KAZA Univisa obtenue à l\'arrivée au point d\'entrée de la destination, offrant une commodité aux voyageurs explorant plusieurs pays de la zone de conservation KAZA TFCA. Ce visa a une durée de validité d\'un (1) mois.',
  'zwevisaarr_kaza.DYN.long_desc': 'Cette variante du KAZA UNIVISA est obtenue à l\'arrivée au point d\'entrée de la destination.</br>Elle offre une commodité aux voyageurs explorant plusieurs pays de la zone de conservation KAZA TFCA, dont <b>le Zimbabwe</b>, <b>la Zambie</b>, et <b>le Botswana</b>.</br>Le KAZA UNIVISA à l\'arrivée est valable pour un (1) mois et permet des entrées multiples dans la zone KAZA TFCA.',
  'zweentry.DYN.desc': 'Les citoyens zimbabwéens, ainsi que les voyageurs de certains pays sélectionnés, peuvent entrer sans obtenir un visa au préalable.',
  'zweentry.DYN.long_desc': 'Les citoyens zimbabwéens, ainsi que les voyageurs de certains pays sélectionnés, peuvent entrer sans obtenir un visa au préalable.</br>Ce type de visa est idéal pour les visites de courte durée et les activités touristiques.</br>Il permet aux voyageurs d\'entrer dans le pays sans avoir besoin de faire une demande de visa, simplifiant ainsi les procédures d\'entrée.</br></br>Cependant, il est important de noter que l\'entrée sans visa ne permet pas des entrées multiples ou des séjours prolongés au-delà de la période de visite spécifiée.',
  'zwevisa_visitor_details_text': '<div class="mb_40">Si vous êtes un ressortissant d\'un pays listé dans <a href="https://www.evisa.gov.zw/faq.html" target="_blank"><b>la Catégorie A (cliquez pour ouvrir)</b></a>, vous n\'avez pas besoin d\'un visa pour voyager au Zimbabwe.</div>',
  'zwevisa.description': 'Le visa à entrée unique permet une seule entrée au Zimbabwe pour une période d\'un (1) mois. Une fois utilisé, la réentrée est restreinte sans l\'obtention d\'un nouveau visa.',
  'zwevisa_double.description': 'Le visa à double entrée permet deux entrées au Zimbabwe dans un délai de trois (3) mois à compter de la date de délivrance.',
  'zwevisa_multiple.description': 'Le visa à entrées multiples permet des entrées illimitées au Zimbabwe dans un délai de six (6) mois à compter de la date de délivrance.',
  'zwevisaarr_multiple.description': 'Le visa à entrées multiples à l\'arrivée permet des entrées illimitées au Zimbabwe dans un délai de six (6) mois à compter de la date de délivrance. Ce visa est obtenu directement à l\'arrivée au point d\'entrée, offrant une solution pratique et efficace pour les voyageurs prévoyant plusieurs visites à court terme.',
  'zwevisa_kaza.description': 'Le KAZA UNIVISA est un document de voyage unique qui améliore votre exploration de la zone de conservation transfrontalière de Kavango Zambezi (KAZA TFCA). Englobant la Zambie, le Zimbabwe, le Botswana, la Namibie et l\'Angola, ce visa simplifie les déplacements entre ces régions diversifiées, vous permettant de découvrir sans interruption les richesses culturelles et naturelles qu\'elles offrent.',
  'zwevisaarr_multiple': 'Visa à entrées multiples à l\'arrivée',
  'zwevisaarr.description': 'Le visa à entrée unique à l\'arrivée est un document de voyage simple qui permet aux visiteurs éligibles de demander et de recevoir un visa à leur arrivée au point d\'entrée de leur destination. Caractérisé par un traitement rapide, un paiement direct des frais et une large éligibilité, il offre une option pratique et flexible pour les séjours de courte durée. Les voyageurs doivent vérifier les exigences spécifiques pour leur destination, car l\'éligibilité peut varier selon les pays.',
  'zwevisaarr.title': 'Visa à entrée unique à l\'arrivée',
  'zwevisaarr_double.title': 'Visa à double entrée à l\'arrivée',
  'zwevisaarr_kaza.title': 'Visa KAZA à l\'arrivée',
  'zwevisaarr_multiple.title': 'Visa à entrées multiples à l\'arrivée',
  'zwevisa': 'Visa à entrée unique',
  'zwevisa_double': 'Visa à double entrée',
  'zwevisa_multiple': 'Visa à entrées multiples',
  'zwevisa_kaza': 'KAZA Univisa',
  'zwevisa_double.title': 'Visa à double entrée',
  'zwevisa_multiple.title': 'Visa à entrées multiples',
  'zwevisa_kaza.title': 'KAZA Univisa',
  'zwevisaarr': 'Visa à entrée unique à l\'arrivée',
  'zwevisaarr_double': 'Visa à double entrée à l\'arrivée',
  'zwevisaarr_kaza': 'KAZA Univisa à l\'arrivée',
  'PreviousZimbabweanVisa': 'Visa zimbabwéen précédent',
  'err.PreviousZimbabweanVisa.required': 'Le visa zimbabwéen précédent est requis',
  'visaPriceArrival.desc': 'Ceci est le prix que vous devrez payer à votre arrivée au Zimbabwe. Ce paiement n\'est pas remboursable.',
  'document_text': 'Veuillez fournir les informations suivantes de manière véridique et telles qu\'elles apparaissent sur votre passeport ou d\'autres documents légaux. Le Département de l\'immigration (Zimbabwe) ne sera pas tenu responsable des informations falsifiées ou des désagréments qui en découleraient. Les formats de documents acceptés sont JPEG, PNG ou PDF, et doivent être inférieurs à 1 Mo.',
  'PreviousConvictionsZim': 'Nature de la/les condamnation(s) au Zimbabwe',
  'travel_information_text': 'Veuillez fournir les informations suivantes de manière véridique et telles qu\'elles apparaissent sur votre passeport ou d\'autres documents légaux. Le Département de l\'immigration (Zimbabwe) ne sera pas tenu responsable des informations falsifiées ou des désagréments qui en découleraient.',
  'AddressZimbabwe': 'Adresse au Zimbabwe',
  'form.addressZimbabwe': 'Adresse de l\'hôte',
  'form.previousConvictionsZim': 'Condamnations antérieures au Zimbabwe',
  'proof.residence.zim': 'Preuve de résidence au Zimbabwe',
  'err.PreviousConvictionsZim.pattern': 'Les condamnations antérieures au Zimbabwe doivent avoir un format correct',
  'DYN.payment_text.zwevisa': '<p>Cette demande est pour un <b>Visa à entrée unique</b>.</p> <p>Le Visa à entrée unique permet une seule entrée au Zimbabwe pour une période d\'un (1) mois. Une fois utilisé, la réentrée est restreinte sans l\'obtention d\'un nouveau Visa.</p> <div><p class="mb_2"><b>Veuillez lire attentivement ceci :</b></p> <p>Vous ne pouvez pas voyager tant que vous n\'avez pas reçu l\'approbation de la demande de visa. La demande que vous soumettez et payez maintenant doit encore passer par le processus de contrôle administratif. Vous recevrez des notifications par e-mail lors de l\'approbation, de la demande de révision ou du refus de cette demande.</p></div> <b>Le Département de l\'immigration du Zimbabwe se réserve le droit de modifier le type de visa pendant le traitement à sa discrétion. Cela peut affecter le coût de votre visa. Le coût réel du visa sera indiqué dans la réponse d\'approbation. Aucune modification n\'est autorisée après le paiement.</b></p>',
  'DYN.payment_text.zwevisa_double': '<p>Cette demande est pour un <b>Visa à double entrée</b>.</p> <p>Le Visa à double entrée permet deux entrées au Zimbabwe dans un délai de trois (3) mois à compter de la date de délivrance.</p> <div><p class="mb_2"><b>Veuillez lire attentivement ceci :</b></p> <p>Vous ne pouvez pas voyager tant que vous n\'avez pas reçu l\'approbation de la demande de visa. La demande que vous soumettez et payez maintenant doit encore passer par le processus de contrôle administratif. Vous recevrez des notifications par e-mail lors de l\'approbation, de la demande de révision ou du refus de cette demande.</p></div> <b>Le Département de l\'immigration du Zimbabwe se réserve le droit de modifier le type de visa pendant le traitement à sa discrétion. Cela peut affecter le coût de votre visa. Le coût réel du visa sera indiqué dans la réponse d\'approbation. Aucune modification n\'est autorisée après le paiement.</b></p>',
  'DYN.payment_text.zwevisa_kaza': '<p>Cette demande est pour un <b>KAZA Univisa</b>.</p> <p>Le KAZA UNIVISA est un document de voyage unique qui améliore votre exploration de la zone de conservation transfrontalière de Kavango Zambezi (KAZA TFCA) pour une période d\'un (1) mois. En englobant la Zambie, le Zimbabwe, le Botswana, la Namibie et l\'Angola, ce visa simplifie les déplacements à travers ces régions diversifiées, vous permettant de découvrir sans interruption les richesses culturelles et naturelles qu\'elles offrent.</p> <div><p class="mb_2"><b>Veuillez lire attentivement ceci :</b></p> <p>Vous ne pouvez pas voyager tant que vous n\'avez pas reçu l\'approbation de la demande de visa. La demande que vous soumettez et payez maintenant doit encore passer par le processus de contrôle administratif. Vous recevrez des notifications par e-mail lors de l\'approbation, de la demande de révision ou du refus de cette demande.</p></div> <b>Le Département de l\'immigration du Zimbabwe se réserve le droit de modifier le type de visa pendant le traitement à sa discrétion. Cela peut affecter le coût de votre visa. Le coût réel du visa sera indiqué dans la réponse d\'approbation. Aucune modification n\'est autorisée après le paiement.</b></p>',
  'DYN.payment_text.zwevisa_multiple': '<p>Cette demande est pour un <b>Visa à entrées multiples</b>.</p> <p>Le Visa à entrées multiples permet des entrées illimitées au Zimbabwe dans un délai de six (6) mois à compter de la date de délivrance.</p> <div><p class="mb_2"><b>Veuillez lire attentivement ceci :</b></p> <p>Vous ne pouvez pas voyager tant que vous n\'avez pas reçu l\'approbation de la demande de visa. La demande que vous soumettez et payez maintenant doit encore passer par le processus de contrôle administratif. Vous recevrez des notifications par e-mail lors de l\'approbation, de la demande de révision ou du refus de cette demande.</p></div> <b>Le Département de l\'immigration du Zimbabwe se réserve le droit de modifier le type de visa pendant le traitement à sa discrétion. Cela peut affecter le coût de votre visa. Le coût réel du visa sera indiqué dans la réponse d\'approbation. Aucune modification n\'est autorisée après le paiement.</b></p>',
  'DYN.payment_text.zwevisaarr': '<p>Cette demande est pour un <b>Visa à entrée unique à l\'arrivée</b>.</p> <p>Le Visa à entrée unique à l\'arrivée est un document de voyage simple qui permet aux visiteurs éligibles de demander et de recevoir un visa à leur arrivée au point d\'entrée de leur destination avec une validité d\'un (1) mois. Caractérisé par un traitement rapide, un paiement direct des frais et une large éligibilité, il offre une option pratique et flexible pour les séjours de courte durée. Les voyageurs doivent vérifier les exigences spécifiques pour leur destination, car l\'éligibilité peut varier selon les pays.</p><b>Le Département de l\'immigration du Zimbabwe se réserve le droit de modifier le type de visa pendant le traitement à sa discrétion. Cela peut affecter le coût de votre visa. Le coût réel du visa sera indiqué dans la réponse d\'approbation. Aucune modification n\'est autorisée après le paiement.</b></p>',
  'DYN.payment_text.zwevisaarr_double': '<p>Cette demande est pour un <b>Visa à double entrée</b>.</p> <p>Le Visa à double entrée permet deux entrées dans le pays dans une période spécifiée, généralement trois (3) mois à compter de la date de délivrance. Il est conçu pour ceux qui prévoient d\'entrer dans le pays deux fois pour des séjours de courte durée. Ce visa offre la commodité de postuler à l\'arrivée au point d\'entrée de votre destination, avec un traitement rapide et un paiement direct des frais. L\'éligibilité et les exigences peuvent varier d\'un pays à l\'autre, les voyageurs doivent donc vérifier les détails spécifiques à leur destination.</p><b>Le Département de l\'immigration se réserve le droit de modifier le type de visa pendant le traitement. Cela peut affecter le coût du visa. Le coût final sera indiqué dans la réponse d\'approbation. Aucune modification n\'est autorisée après le paiement.</b></p>',
  'DYN.payment_text.zwevisaarr_kaza': '<p>Cette demande est pour un <b>Visa KAZA à l\'arrivée</b>.</p> <p>Le Visa KAZA à l\'arrivée facilite l\'entrée dans la zone de conservation transfrontalière Kavango Zambezi, englobant la Zambie, le Zimbabwe, le Botswana, la Namibie et l\'Angola pour une période d\'un (1) mois. Il permet aux voyageurs éligibles de demander et de recevoir un visa directement à leur arrivée au point d\'entrée de leur destination. Ce visa est connu pour son traitement rapide, son paiement direct des frais et son éligibilité large, en faisant une option flexible pour ceux qui souhaitent explorer les paysages culturels et naturels riches de la région du KAZA pour des séjours de courte durée. Les voyageurs doivent s\'assurer qu\'ils répondent aux exigences spécifiques pour chaque pays au sein de la zone KAZA TFCA.</p><b>Les autorités d\'immigration du KAZA se réservent le droit de modifier le type de visa pendant le traitement, ce qui pourrait affecter les frais de visa. Le coût réel du visa sera spécifié dans votre avis d\'approbation. Aucun changement n\'est autorisé après le paiement.</b></p>',
  'DYN.payment_text.zwevisaarr_multiple': '<p>Cette demande est pour un <b>Visa à entrées multiples à l\'arrivée</b>.</p> <p>Le Visa à entrées multiples à l\'arrivée permet des entrées illimitées dans le pays de destination dans un délai de six (6) mois à compter de la date de délivrance. Caractérisé par un traitement rapide et un paiement direct des frais, il offre une option flexible pour les voyageurs nécessitant un accès fréquent pour des séjours de courte durée.</p><b>Le Département de l\'immigration se réserve le droit de modifier le type de visa pendant le traitement à sa discrétion. Cela peut affecter le coût de votre visa. Le coût réel du visa sera indiqué dans la réponse d\'approbation. Aucune modification n\'est autorisée après le paiement.</b></p>',
  'DYN.payment_text.zweentry': '<p>Cette demande est pour une <b>Entrée sans visa</b>.</p> <p>L\'Entrée sans visa permet aux voyageurs de certains pays de rentrer sans obtenir de visa au préalable, généralement pour des séjours de courte durée.</p> <div><p class="mb_2"></p>',
  'zwevisaarr_double.description': 'Le Visa à double entrée à l\'arrivée permet aux voyageurs deux entrées dans un pays, généralement dans une période de trois mois à compter de la date de délivrance. Ce visa est obtenu directement à l\'arrivée au point d\'entrée, offrant une option rapide et pratique pour ceux qui prévoient d\'entrer dans le pays deux fois pour des séjours de courte durée. Il est essentiel que les voyageurs vérifient les exigences et critères d\'éligibilité spécifiques au pays de destination.',
  'zwevisaarr_kaza.description': 'Le Visa KAZA à l\'arrivée permet une délivrance directe au point d\'entrée au sein de la zone de conservation transfrontalière de Kavango Zambezi, couvrant la Zambie, le Zimbabwe, le Botswana, la Namibie et l\'Angola. Ce processus simplifié permet aux voyageurs d\'obtenir rapidement leur visa à leur arrivée pour des visites de courte durée, facilitant l\'exploration des nombreuses attractions diversifiées de la région. Il est important pour les voyageurs de vérifier les exigences d\'entrée spécifiques à chaque pays de la zone KAZA TFCA.',
  'print.desc.part1': 'La possession d\'une autorisation de voyage électronique (eTA) ne garantit pas l\'entrée dans la République du Zimbabwe. L\'admission sera déterminée par les fonctionnaires de l\'immigration au point d\'entrée.<br><span class="mt_10">Il est interdit de se livrer à toute forme de commerce ou d\'emploi au Zimbabwe sans le permis requis. Veuillez présenter votre passeport et cette eTA lorsque cela vous sera demandé par les fonctionnaires de l\'immigration ou de la compagnie aérienne.</span>',
  'tooltip.addressZimbabwe': 'Si le demandeur a une adresse au Zimbabwe, veuillez la saisir ici.',
  'err.PreviousConvictionsZim.maxlength': 'Les condamnations antérieures au Zimbabwe doivent comporter moins de 50 caractères',
  'zweprovrespermit': 'Permis de résidence provisoire',
  'zwevisaextc': 'Extension de séjour pour la catégorie C',
  'zwevisaextab': 'Extension de séjour pour les catégories A ou B',
  'zwefeedback': 'Formulaire de retour d\'expérience de séjour',
  'zwevisa_visitor_details_title': 'Votre nationalité relève-t-elle du régime de la catégorie A ?',
  'refusalReason.desc.DYN.zwevisa': '<p><b>Veuillez noter que si le terme "liste noire" est utilisé, la personne associée à cette demande de visa sera mise sur liste noire.</b></p><p>Veuillez fournir une brève description de la raison du refus.</p>',
  'refusalReason.desc.extension.DYN.zwevisa': '<p>Veuillez fournir une brève description de la raison du refus de cette demande d\'extension.</p>',
  'refusalReason.review.desc.DYN.zwevisa': 'Veuillez fournir une brève description de la révision comme <b>NON RECOMMANDÉE</b>. Cette description sera envoyée au prochain réviseur.',
  'approveReason.review.desc.DYN.zwevisa': 'Veuillez fournir une brève description de la révision comme <b>RECOMMANDÉE</b>. Cette description sera envoyée au prochain réviseur.',
  'deletePerson.error.DYN.zwevisa': 'Une erreur s\'est produite lors de la suppression de la personne. Vous ne pouvez pas supprimer une personne qui a déjà une demande soumise.',
  'DYN.zwevisaarr': 'Visa à entrée unique à l\'arrivée',
  'notice.desc.BORDER_PASS': 'Un Border Pass est un document officiel délivré par le gouvernement du Zimbabwe permettant aux individus de traverser légalement des points frontaliers spécifiés.',
  'scan.visaCreated.exit': 'La demande de visa a été créée avec succès. Vous pouvez maintenant enregistrer la personne en sortie.',
  'scan.visaCreated.entry': 'La demande de visa a été créée avec succès. Vous pouvez maintenant enregistrer la personne en entrée.',
  'forceComparison.show': 'Afficher la comparaison avec le document scanné',
  'forceComparison.hide': 'Masquer la comparaison avec le document scanné',
  'tooltip.forceComparison': 'Comparer les informations entre le document scanné et la demande.',
  'noScannedComparison': 'Aucune donnée correspondante trouvée dans la demande',
  'transfertFromPassport': 'Transférer les informations du document scanné.',
  'tooltip.transfertFromPassport': 'Les informations entre le passeport et le document scanné sont différentes. Vous pouvez transférer les informations du document scanné vers la demande.',
  'CompanionsSize': 'Nombre de compagnons de voyage',
  'transit.consume': 'Transit',
  'tooltip.scanPassport': 'Scanner le passeport de la personne',
  'passport.scan': 'Scanner le passeport',
  'tooltip.transitRegister': 'Enregistrer le transit de la personne',
  'transit.register': 'Enregistrer un transit',
  'global.goLatest': 'Ouvrir la dernière demande visitée',
  'tooltip.goLatest': 'Vous pouvez retourner à la demande précédente que vous avez visitée',
  'scan.choice': 'Sélectionner le mode',
  'form.ad': 'E-mail/Nom d\'utilisateur',
  'printerOffline': 'L\'imprimante thermique est hors ligne. Veuillez vérifier la connexion USB avec l\'appareil et réessayer. Souhaitez-vous tout de même imprimer le document avec une imprimante classique ?',
  'printerNotFound': 'L\'imprimante thermique n\'est pas détectée dans votre liste d\'appareils. Veuillez vérifier la connexion et vous assurer que les pilotes sont installés. Souhaitez-vous tout de même imprimer le document avec une imprimante classique ?',
  'datePrinted': 'Date d\'impression',
  'printThermal': 'Imprimer avec une imprimante thermique',
  'tooltip.printThermal': 'Imprimer le document avec une imprimante thermique, ou décocher pour imprimer avec une imprimante classique',
  'application.overStay': 'La demande est <b>en dépassement de séjour</b>',
  'watchlistFound': 'La demande <b>correspond à la liste de surveillance</b>',
  'apipnrFound': 'La demande <b>correspond à la liste API/PNR</b>',
  'payAttention': 'Je ferai attention',
  'deportedMode': 'Marquer comme expulsé',
  'tooltip.deportedMode': 'Marquer la personne comme expulsée. Si vous marquez la personne comme expulsée, elle sera considérée comme telle dans le système lors de l\'enregistrement de la personne.',
  'deportedComment': 'Raison du statut d\'expulsé',
  'payment.inReturned': 'Cette demande a été enregistrée avec le <b>statut d\'expulsé</b> au guichet de l\'agent.',
  'reasonAdd': '<p><b>Raison</b> : <br/> {reason}</p>',
  'global.deported': 'Expulsé',
  'photo.entryCountry.INRETURNED': 'Photo à l\'enregistrement avec statut d\'expulsé',
  'print.notices': 'Imprimer l\'avis',
  'ask.noticePrint': 'Souhaitez-vous imprimer l\'avis daté du {date} ?',
  'select.notice': 'Sélectionner l\'avis à imprimer',
  'ask.selectNotice': 'Veuillez sélectionner l\'avis que vous souhaitez imprimer.',
  'noChoice.selectedNotice': 'Aucun avis sélectionné',
  'tooltip.printNotices': 'Imprimer l\'avis imprimé par l\'agent lors de l\'enregistrement ou du refus',
  'ask.revertConsume': 'Êtes-vous sûr de vouloir annuler cet événement ?',
  'cancelled': 'Annulé',
  'tooltip.revertConsume': 'Vous pouvez annuler cet événement de cette demande',
  'done.revertConsume': 'L\'événement a été annulé avec succès',
  'revert.consume': 'Raison de l\'annulation',
  'error.revertConsume': 'Une erreur s\'est produite lors de l\'annulation de l\'événement. Veuillez réessayer.',
  'outUsage.AB': 'Cette demande est <b>hors de sa période de validité</b>. Veuillez orienter le candidat vers le kiosque ou l\'assister pour soumettre à nouveau sa demande.',
  'outUsage.C': 'Cette demande est <b>hors de sa période de validité</b>. Veuillez fermer cette demande, puis créer une nouvelle entrée pour le candidat et procéder au refus.',
  'print.date': 'Date d\'impression',
  'print.id': 'Lié à l\'ID de la demande',
  'internalReference.badPattern': 'La référence interne doit suivre le format "numéro_anné" (ex. : 140_2024).',
  'showFullDatas': 'Afficher tous les documents',
  'hideFullDatas': 'Masquer tous les documents',
  'tooltip.showFullDatas': 'Afficher ou masquer tous les documents de la demande',
  'watchlist.null': '<b>Le système {system} n’a pas pu être vérifié</b>',
  'watchlist': 'Liste de surveillance',
  'apipnr': 'API-PNR',
  'global.refuseNoCam': 'Refuser sans caméra',
  'global.refuseIn': 'Refuser à l’enregistrement',
  'global.refuseOut': 'Refuser à la sortie',
  'photo.entryCountry.REFUSEIN': 'Photo à l’enregistrement avec statut de refus',
  'photo.entryCountry.REFUSEOUT': 'Photo à la sortie avec statut de refus',
  'global.refuseFingerPrint': 'Refuser avec empreinte digitale',
  'tooltip.refuseFingerPrint': 'Refuser la personne avec empreinte digitale',
  'tooltip.consumeRefuseNoCam': 'Refuser la personne sans utiliser la caméra',
  'ask.refuseCamera': 'Refuser avec caméra',
  'tooltip.disabledBecauseReview': 'Cette personne est actuellement liée à une application en cours de révision par un superviseur. Pour créer une nouvelle application pour cette personne, vous devez d’abord attendre la fin de la révision.',
  'client.extended.noValidPerson': 'Aucune personne associée à ce paiement n’a été trouvée. Veuillez vérifier votre liste de personnes enregistrées et réessayer.',
  'zwevisaarr.arrivalPrint': 'Visa à entrée unique à payer à l’arrivée',
  'zwevisaarr_double.arrivalPrint': 'Visa à double entrée à payer à l’arrivée',
  'zwevisaarr_multiple.arrivalPrint': 'Visa à entrées multiples à payer à l’arrivée',
  'zwevisaarr_kaza.arrivalPrint': 'KAZA Univisa à payer à l’arrivée',
  'transfertHKG': 'Marquer cette application comme une application de Hong Kong',
  'tooltip.transfertHKG': 'Vous pouvez marquer cette application comme une application de Hong Kong à partir d’un passeport chinois scanné',
  'ask.updateForHKG': 'Êtes-vous sûr de vouloir mettre à jour cette application pour une application de Hong Kong?',
  'done.updateForHKG': 'L’application a été mise à jour avec succès pour une application de Hong Kong',
  'error.updateForHKG': 'Une erreur est survenue lors de la mise à jour de l’application pour une application de Hong Kong. Veuillez réessayer.'
};