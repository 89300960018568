import {OnDestroy, AfterViewInit, ElementRef, EventEmitter, Output, Directive} from '@angular/core';

@Directive()
export abstract class AbstractPrintComponent implements AfterViewInit, OnDestroy {
  @Output() init = new EventEmitter<void>();

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit(): void {
    const images: NodeListOf<HTMLImageElement> = this.elRef.nativeElement.querySelectorAll('img');
    const svgs: NodeListOf<SVGElement> = this.elRef.nativeElement.querySelectorAll('svg');
    const qrCodes: NodeListOf<HTMLElement> = this.elRef.nativeElement.querySelectorAll('qrcode');

    const loadingImages = Array.from(images).filter(img => !img.complete);
    const loadingSVGs = Array.from(svgs).filter(svg => {
      const {width, height} = svg.getBoundingClientRect();
      return width === 0 || height === 0;
    });

    // Verification of qrcode content (wait for images or SVGs in each qrcode tag)
    const qrcodePromises = Array.from(qrCodes).map(qr => new Promise<void>(resolve => {
      const checkQRCodeContent = () => {
        const innerImg = qr.querySelector('img');
        const innerSvg = qr.querySelector('svg');

        // Checks whether the image or SVG inside is loaded
        if((innerImg && innerImg.complete) || (innerSvg && innerSvg.getBoundingClientRect().width > 0 && innerSvg.getBoundingClientRect().height > 0)) {
          resolve();
        } else {
          // Re-check after a short delay
          setTimeout(checkQRCodeContent, 100);
        }
      };
      checkQRCodeContent();
    }));

    const allLoadingPromises = [
      ...loadingImages.map(img => new Promise(resolve => img.addEventListener('load', resolve, {once: true}))),
      ...loadingSVGs.map(svg => new Promise(resolve => svg.addEventListener('load', resolve, {once: true}))),
      ...qrcodePromises
    ];

    Promise.all(allLoadingPromises).then(() => {
      this.init.emit();
    });
  }

  ngOnDestroy(): void {
    //In case the component is destroyed before the event is emitted
    this.init.complete();
  }
}
