<div id="layout" class="f_col">
  <app-view-title [bgImage]="api.env.theme.header_background">{{'choose-lang.title' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_scroll-wrapper_l resp_c-50">
      <app-paragraph textAlign="center" class="mt_20">{{'choose-lang.desc' | translate}}</app-paragraph>
      <div class="f_grow mt_20 mb_10">
        <app-slide-element  *ngFor="let language of languages" (btnClicked)="changeLang(language.value)"
                            leftIcon="language"
                            class="mt_10">
          {{language.label | translate}}
        </app-slide-element>
        <div class="flex f_col mt_20">
            <app-button class="btn-changeLang" size="sm" type="button" (click)="nav.to(data?.fromRoute)" class="fullWidth resp_minSize_l">{{'global.back' | translate}}</app-button>
        </div>
      </div>
    </div>
  </div>
</div>
