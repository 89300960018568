<div class="printFrames" *ngIf="data">
  <div class="printFrame frame_tl">
    <img class="printFrame_bg" src="./assets/images/print_bg.png" alt="">
    <span class="frame_number">1</span>
    <img class="" [src]="environment.theme.logo" alt="logo" width="100" height="100">
    <div *ngIf="environment.type === DocumentType.ZWEVISA" class="flex f_center">
      <div class="f_col f_align_c">
        <img src="./assets-projects/zimmigration.png" width="80px" alt="">
      </div>
      <div class="f_col f_align_c">
        <img src="./assets-projects/emblem.png" width="80px" alt="">
      </div>
    </div>
    <img *ngIf="api.userInfo?.server?.country && environment.type === DocumentType.PASS" class="w-20 mx-auto" [ngSrc]="'assets/images/flags/'+api.userInfo.server.country+'.png'" alt="flag" width="200" height="200">
  </div>

  <div class="printFrame frame_tr">
    <img class="printFrame_bg" src="./assets/images/print_bg.png" alt="">
    <span class="frame_number">4</span>
    <div class="flex f_align_c mb_10">
      <img class="printFrame_header_icon" src="assets/images/icons_clr/error.png" alt="">
      <span class="frame_title">{{'print.notice.title' | translate}}</span>
    </div>
    <p>{{'print.notice.desc1' | translate}}</p>
    <p>{{'print.notice.desc2' | translate}}</p>
    <div class="flex justify-between flex-grow">
      <span *ngIf="api.userInfo?.server.emailConfig.phone.trim()">TEL: {{api.userInfo?.server.emailConfig.phone}}</span>
      <span>EMAIL: {{api.userInfo?.server.emailConfig.email}}</span>
    </div>
    <div class="flex f_align_c mt_20 mb_20">
      <img class="printFrame_header_icon" src="assets/images/icons_clr/origami.png">
      <span class="frame_title">{{'print.notice.folding' | translate}}</span>
    </div>
    <img class="mb-14" ngSrc="assets/images/folding.png" alt="folding" width="320" height="100">
  </div>

  <div class="printFrame frame_bl">
    <img class="printFrame_bg" src="./assets/images/print_bg.png" alt="">
    <span class="frame_number">2</span>
    <div class="flex f_align_c mb_10">
      <img class="printFrame_header_icon" src="assets/images/icons_clr/about.png">
      <span class="frame_title">{{'print.informations' | translate}}</span>
    </div>
    <ng-container *ngIf="api.env.type === DocumentType.PASS">
      <div class="printFrame_data" *ngIf="data.metadata?.firstName">
        <span>{{'form.name' | translate}}:</span><span>{{data.metadata.firstName}} {{data.metadata.lastName}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.metadata?.passportNumber">
        <span>{{'form.passportNumber' | translate}}:</span><span>{{data.metadata?.passportNumber}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.metadata?.phone">
        <span>{{'form.phone' | translate}}:</span><span>{{data.metadata.phone}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.metadata?.idCard">
        <span>{{'form.idCard' | translate}}:</span><span>{{data.metadata?.idCard}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.amountCents">
        <span>{{'form.amount' | translate}}:</span><span>{{data.amountCents / 100}} {{data.currency}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.metadata?.buyingOption">
        <span>{{'form.buyingOption' | translate}}: </span><span>{{data.metadata?.buyingOption?.type ? data.metadata?.buyingOption?.type : data.metadata?.buyingOption}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.type && !api.userRole.isCustomer">
        <span>{{'form.type' | translate}}:</span><span>{{data.type}}</span>
      </div>
      <div class="printFrame_data" *ngIf="api.userRole.isCustomer">
        <span>{{'form.type' | translate}}: </span><span>{{data.paymentType}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.created_place_id">
        <span>{{'form.place' | translate}}:</span><span>{{data.created_place_id}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.createdAt">
        <span>{{'form.created_date' | translate}}:</span><span>{{data.createdAt | date}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.id">
        <span>{{'form.id' | translate}}:</span><span>{{data.id}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.metadata?.worker">
        <span>{{'form.worker' | translate}}:</span><span>{{sync.userDisplay(api.userInfo.id)}} #{{api.userInfo.id}}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="api.env.type !== DocumentType.PASS">
      <div class="printFrame_data" *ngIf="data.metadata?.FirstName">
        <span>{{'form.name' | translate}}:</span><span>{{data.metadata.FirstName}} {{data.metadata?.LastName}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.metadata?.BirthDay">
        <span>{{'form.birthDate' | translate}}:</span><span>{{data.metadata?.BirthDay | date}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.metadata?.Nationality">
        <span>{{'form.nationality' | translate}}:</span><span>{{('DYN.' + data.metadata?.Nationality) | translate}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.metadata?.passportNumber || data.metadata?.PassportNumber">
        <span>{{'form.passportNumber' | translate}}:</span><span>{{data.metadata?.PassportNumber}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.operationId">
        <span>{{('document.type.DYN.' + api.env.type) | translate}}: </span><span>{{
        ((data.operationId === DocumentType.ZWEVISAARR ||
          data.operationId === DocumentType.ZWEVISAARR_DOUBLE ||
          data.operationId === DocumentType.ZWEVISAARR_KAZA ||
          data.operationId === DocumentType.ZWEVISAARR_MULTIPLE)
          ? (data.operationId + '.arrivalPrint')
          : data.operationId) | translate}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.usageAfter">
        <span>{{'visaIntro.validFrom' | translate}}: </span><span>{{data.usageAfter | date}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.usageUntil">
        <span>{{'visaIntro.validUntil' | translate}}: </span><span>{{data.usageUntil | date}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.operationId">
        <span>{{'visaIntro.usageCount' | translate}}: </span><span>{{getUsageCount(data.operationId)}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.externalId">
        <span>{{'form.externalId' | translate}}: </span><span>{{data.externalId | lowercase}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.id">
        <span>{{'trip.name' | translate}}:</span><span>{{data.batchId | lowercase}}</span>
      </div>
      <div class="printFrame_data" *ngIf="data.id">
        <span>{{'application.id' | translate}}:</span><span>{{data.id | lowercase}}</span>
      </div>
    </ng-container>
  </div>

  <div class="printFrame frame_br">
    <img class="printFrame_bg" src="./assets/images/print_bg.png" alt="">
    <span class="frame_number">3</span>
    <div class="flex f_align_c mb_10">
      <img class="printFrame_header_icon" src="assets/images/icons_clr/qr.png">
      <span class="frame_title">{{'print.qr.title' | translate}}</span>
    </div>
    <div *ngIf="api.env.type === DocumentType.ZWEVISA">
      <div class="flex f_col gap_10">
        <app-paragraph innerhtml="{{('print.desc.part1' + ((data.subState === RequestSubState.REFUSED_IN || data.subState === RequestSubState.REFUSED_OUT) ? '.refuse' : '')) | translate}}" textAlign="justify"></app-paragraph>
      </div>
    </div>
    <div *ngIf="api.env.type === DocumentType.PASS">
      <p>{{'print.qr.desc' | translate}}</p>
      <p class="small" *ngIf="environment.type !== DocumentType.PASS">{{'qr.request_desc' | translate}}</p>
      <p *ngIf="environment.type !== DocumentType.PASS" class="fw_bold">{{(isValid ? 'qrDesc.valid' : 'qrDesc.invalid') | translate}}</p>
    </div>
    <qrcode *ngIf="isValid || api.env.type === DocumentType.PASS" class="qr-print" elementType="svg" errorCorrectionLevel="M" [qrdata]="data.qrCode" [width]="250"></qrcode>
  </div>
</div>
