<div id="layout" class="f_col">
  <app-view-title fromRoute="my-account" [chooseLangBtn]="false">{{'printSettings' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
        <app-paragraph *ngIf="!printDocumentSelected" textAlign="center" class="mb_20 mt_10">{{'printSettings.desc' | translate}}</app-paragraph>
        <div class="flex f_center gap_10" [ngClass]="{'f_wrap': !printDocumentSelected}">
          <ng-container *ngIf="!printDocumentSelected">
            @for(document of ListDocumentsPrintable; let index = $index; track document.name;) {
              <div class="big_btn big_btn-resp btn_prints" (click)="selectDocument(document.name)">
                <fa-icon [icon]="document.icon" class="mr_5"></fa-icon>
                <span>{{('DYN.' + document.name) | translate}}</span>
              </div>
            }
          </ng-container>
          <div *ngIf="printDocumentSelected" class="flex f_col f_center">
            <app-paragraph [innerHTML]="'printSettings.selected' | translate : {documentName: ('DYN.' + printDocumentSelected) | translate}" textAlign="center" class="mb_20 mt_10"></app-paragraph>
            <form *ngIf="form" class="flex f_col gap_30" id="form" [formGroup]="form" (ngSubmit)="submitForm(printDocumentSelected)">
              <app-input class="fullWidth" icon="print" type="select" formCtrlName="defaultPrinter" [formGrp]="form" label="{{'defaultPrinter' | translate}}" [selectOptions]="print.printerList"></app-input>
              <app-checkbox-element class="fullWidth" [formGrp]="form" formCtrlName='colorMode' text='colorMode'></app-checkbox-element>
              <app-input class="fullWidth" icon="scroll" type="select" formCtrlName="paperSize" [formGrp]="form" label="{{'paperSize' | translate}}" [selectOptions]="paperSizeSelectOptions"></app-input>
            </form>
          </div>
        </div>
        <div class="flex f_col mb_20 mt_20 btn-changepwd">
          <div class="flex gap_10 f_align_c">
            <app-button size="xsm" color="transparent" border="true" type="button" (click)="goBack()" class="fullWidth resp_minSize_l">{{(printDocumentSelected ? 'global.cancel' : 'global.back') | translate}}</app-button>
            <app-button *ngIf="printDocumentSelected" size="xsm" type="submit" formId="form" class="fullWidth resp_minSize_l">{{'global.confirm' | translate}}</app-button>
          </div>
        </div>
    </div>
  </div>
  <div class="resp_maxSize_l flex gap_5 mb_20">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <app-button border="true" class="resp_maxSize_l" (btnClicked)="goBack()">
      <fa-icon icon="arrow-left"></fa-icon>
    </app-button>
    <app-button *ngIf="printDocumentSelected" type="button" (click)="submitForm(printDocumentSelected)" class="fullWidth  resp_maxSize_l">{{'global.confirm' | translate}}</app-button>
  </div>
</div>
