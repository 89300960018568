import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import {Observable, Subject} from 'rxjs';
import {DevicesService, LoaderService, NavigateService, ShortcutAction} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {rotateBase64Image} from 'src/app/shared/utils/images';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WebcamComponent implements OnInit, OnDestroy {

  @Input() backUrl: string;
  @Input() backUrlParams: any;
  @Input() imageQuality = 1;
  @Input() brokenButton = false;
  @Input() fullHeight = false;
  @Input() canRotateCamera = false;
  @Output() output: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitManualProblem: EventEmitter<any> = new EventEmitter<any>();
  @Output() cameraProblem: EventEmitter<any> = new EventEmitter<any>();

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  // toggle webcam on/off
  public showWebcam = false;
  public multipleWebcamsAvailable = true;
  public mediaDevices: MediaDeviceInfo[];
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    width: {ideal: window.innerWidth},
    height: {ideal: window.innerHeight},
    focusMode: 'manual',
    focusDistance: {ideal: 100.0}
  } as any;
  public errors: WebcamInitError[] = [];

  public webcamImage: WebcamImage = undefined;
  public videoInitialized: boolean;
  public deviceReady = false;

  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  private resumeDeviceId: string;

  eventPause = this.onCameraPause.bind(this);
  eventResume = this.onCameraResume.bind(this);
  rotationClass: string = 'rotate-0';

  constructor(public nav: NavigateService,
    public loader: LoaderService,
    private devices: DevicesService,
    public api: ApiService) {
  }

  public ngOnInit(): void {
    this.loader.loading(true);
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.mediaDevices = mediaDevices;
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        this.showWebcam = true;
        this.applyRotation();
      });
    if(this.devices.isDevices('cordova')) {
      document.addEventListener('pause', this.eventPause);
      document.addEventListener('resume', this.eventResume);
    }
  }

  public ngOnDestroy() {
    if(this.devices.isDevices('cordova')) {
      document.removeEventListener('pause', this.eventPause);
      document.removeEventListener('resume', this.eventResume);
    }
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(state: boolean): void {
    this.showWebcam = state || !this.showWebcam;
    if(!this.showWebcam) {
      this.turnOffCamera();
    }
  }

  public turnOffCamera(): void {
    this.deviceId = null;
    this.showWebcam = false;
    this.nextWebcam.complete();
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
    this.cameraProblem.emit();
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.deviceReady = false;
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    const actualRotate = localStorage.getItem('cameraRotate');
    const degrees = actualRotate ? parseInt(actualRotate) : 0;
    if(degrees !== 0) {
      rotateBase64Image(this.webcamImage.imageAsDataUrl, degrees).then(rotatedImageBase64 => {
        this.output.emit(rotatedImageBase64);
      }).catch(() => {
        this.output.emit(this.webcamImage.imageAsDataUrl);
      });
    } else {
      this.output.emit(this.webcamImage.imageAsDataUrl);
    }
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
    setTimeout(() => {
      this.deviceReady = true;
      this.loader.loading(false);
    }, 35);
  }

  onCameraPause() {
    this.deviceReady = false;
    this.resumeDeviceId = this.deviceId;
    this.deviceId = null;
    this.nextWebcam.next(null);
  }

  onCameraResume() {
    this.deviceId = this.resumeDeviceId;
    this.nextWebcam.next(this.deviceId);
    this.deviceReady = true;
  }

  backUrlRedirect(backUrl: string) {
    if(backUrl === 'nfc-read-draw-signature') {
      this.nav.to(this.backUrl, undefined, {state: {type: 'manual'}, queryParams: this.backUrlParams});
    } else {
      this.nav.to(this.backUrl, undefined, {queryParams: this.backUrlParams});
    }
  }

  problemManualCamera() {
    this.emitManualProblem.emit();
  }

  rotateCamera() {
    const actualRotate = localStorage.getItem('cameraRotate');
    let newRotate;
    if(actualRotate !== null) {
      newRotate = (Number(actualRotate) + 90) % 360;
      if(newRotate === 0) {
        localStorage.removeItem('cameraRotate');
      } else {
        localStorage.setItem('cameraRotate', newRotate.toString());
      }
    } else {
      newRotate = 90;
      localStorage.setItem('cameraRotate', newRotate.toString());
    }
    this.applyRotation();
  }

  applyRotation() {
    const rotateValue = localStorage.getItem('cameraRotate');
    this.rotationClass = `rotate-${rotateValue ? rotateValue : 0}`;
  }

  protected readonly ShortcutAction = ShortcutAction;
}
