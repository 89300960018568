import {Component, Input} from '@angular/core';
import {PrintApprovalLetterData} from '../../shared/models/request';

@Component({
  selector: 'app-print-approval-letter',
  templateUrl: './print-approval-letter.component.html',
  styleUrl: './print-approval-letter.component.scss'
})
export class PrintApprovalLetterComponent {
  @Input() qrCode: string;
  @Input() data: PrintApprovalLetterData;
}
