import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {User, UserPlatformRole, UserTable} from 'src/app/shared/models/user';
import {ApiService} from '../../shared/services/api.service';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {FormBuilder, FormGroup} from '@angular/forms';
import {truncateString} from '../../shared/utils/truncateString';
import {ActivatedRoute} from '@angular/router';
import {QueryService} from 'src/app/shared/services/query.service';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  data: User[];
  filteredData: User[] = [];
  defaultPaginatorSize = 10;
  scrollNumber = 30;
  scrollState = false;
  totalCount: number;
  maxUser: number;
  currentIndex = 0;
  firstCall = false;
  role = UserPlatformRole;
  timer: NodeJS.Timeout;
  displayedColumns: string[] = ['userId', 'name', 'role', 'email', 'creationDate'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<UserTable>([]);

  constructor(public nav: NavigateService,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef, public query: QueryService) {
  }

  ngOnInit(): void {
    this.setForm();
  }

  ngAfterViewInit() {
    this.loader.loading(true);
    this.getUsers().then(() => {
      this.loader.loading(false);
      this.firstCall = true;
      this.route.queryParams.subscribe((params: any) => {
        this.applyFilter(params.search);
        this.form.controls.text.setValue(params.search);
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  setForm() {
    this.form = this.formBuilder.group({
      text: ['', []]
    });
  }

  getUsers(offset = 0) {
    !this.scrollState && this.loader.loading(true);
    return this.api.users(true, 'name', 'ASC', offset, this.paginator.pageSize).then((res) => {
      this.maxUser = res.count as number;
      this.totalCount = +res.count;
      setTimeout(() => {
        this.paginator.length = this.totalCount;
      });
      const newData = res.result.sort((x, y) => x.name.localeCompare(y.name));
      this.filteredData = [...(this.filteredData || []), ...newData];
      this.filteredData = this.filterDuplicateUsers(this.filteredData);
      this.data = this.filteredData;
      this.loader.loading(false);
      this.updateDataSource();
      return this.filteredData;
    }).catch((err) => {
      this.loader.loading(true, {type: 'error', message: err});
      throw err;
    });
  }

  filterDuplicateUsers(users: User[]) {
    const uniqueIds = new Set();
    return users.filter(user => {
      if(!uniqueIds.has(user.id)) {
        uniqueIds.add(user.id);
        return true;
      }
      return false;
    });
  }

  applyFilter(filterValue: string) {
    if(filterValue?.length > 1) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.loader.loading(true);
        const filterArray = this.data.filter((data: any) => this.query.filterData(undefined, data.userId, filterValue, true) ||
            this.query.filterData(undefined, data.name, filterValue) ||
            this.query.filterData(undefined, data.accountName, filterValue, true));
        return this.api.adminSearchUser(filterValue).then((users: User[]) => {
          this.loader.loading(false);
          this.filteredData = filterArray.concat(users).filter((v, i, a)=>a.findIndex(v2=>(v2?.id === v?.id))===i);
          this.updateDataSource();
        }).catch(err => {
          this.loader.loading(false);
          console.log(err);
          this.filteredData = [];
          this.updateDataSource();
        });
      }, 500);
    } else {
      this.loader.loading(false);
      this.getUsers().then(() => {
        this.updateDataSource();
      });
    }
  }

  onScroll(event: any) {
    this.scrollState = true;
    if(this.filteredData?.length !== this.maxUser) {
      if(event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        this.scrollNumber += this.scrollNumber;
        this.loader.loadingElement('bottom', true, 'xs');
        setTimeout(() =>{
          this.getUsers().then(() => {
            this.scrollState = false;
            this.loader.loadingElement('bottom', false);
            this.applyFilter(this.route.snapshot.params.search);
          });
        }, 1000);
      }
    }
    this.loader.loading(false);
  }

  truncateUid(id: string): string {
    return id.length > 8 ? truncateString(id, 8) + '...' : id;
  }

  updateDataSource() {
    const transformedData = this.filteredData.map(request => ({
      id: request.id,
      userId: request.userId,
      name: request.name,
      role: request.role,
      email: request.accountName,
      creationDate: request.confirmedAt
    }));
    this.dataSource = new MatTableDataSource<UserTable>(transformedData as any);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.loader.loading(false);
  }


  onPageChange(event: any) {
    this.currentIndex = event.pageIndex;
    this.getUsers(this.currentIndex * this.paginator.pageSize);
  }
}
