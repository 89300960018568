<mat-dialog-content class="dialog_content">
  <app-paragraph class="mb_40 fw_bold fs-h5 " textAlign="center">{{'errNoCard.modify' | translate}}</app-paragraph>
  <form class="mb_20 f_col gap_30 newPeople-form-container" id="form" [formGroup]="form" (ngSubmit)="submit()">
    <app-input *ngIf="data?.firstName" [formCtrlName]="PassFields.FIRST_NAME" [formGrp]="form"
    label="form.firstName" icon="user">
    </app-input>

    <app-input *ngIf="data?.lastName" [formCtrlName]="PassFields.LAST_NAME" [formGrp]="form"
           label="form.lastName" icon="user">
    </app-input>

    <app-input *ngIf="data?.birthDate" [formCtrlName]="PassFields.BIRTH_DATE" [formGrp]="form" type="date"
              label="form.birthDate" icon="cake-candles" [maxDate]="maxBirthDate" [autoMinMaxDate]="true">
    </app-input>

    <app-input *ngIf="data?.documentNumber" [formCtrlName]="PassFields.DOCUMENT_NUMBER" [formGrp]="form"
              label="form.passportNumber" icon="hashtag">
    </app-input>

    <app-input *ngIf="data?.sex" [formCtrlName]="PassFields.SEX" [formGrp]="form" type="select"
               label="form.gender" icon="venus-mars"
               [selectOptions]="genderOptions">
    </app-input>

    <app-input *ngIf="data?.nationality" [formCtrlName]="PassFields.NATIONALITY" [formGrp]="form" type="select"
              [selectOptions]="request.getNationalities(true)"
              label="form.nationality" icon="globe">
    </app-input>

    <app-input *ngIf="data?.expirationDate" [formCtrlName]="PassFields.EXPIRATION_DATE" [formGrp]="form" type="date"
               label="form.expireDate" icon="calendar" [disabled]="!form.controls[PassFields.NATIONALITY].value" [minDate]="getMinExpirationDate()" [maxDate]="maxExpiryDate" [autoMinMaxDate]="true">
    </app-input>
    <app-errors-list [dark]="true" *ngIf="submitted"
                     [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
  </form>
  <div class="mt_20 flex gap_10 f_center">
    <app-button size="sm" class="dialog_close"(btnClicked)="closeDialog()">
      {{'global.close' | translate}}
    </app-button>
    <app-button size="sm" type="submit" formId="form">
      {{'global.confirm' | translate}}
    </app-button>
  </div>
</mat-dialog-content>
