import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as uuid from 'uuid';
import {FormService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from '../../shared/services/api.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignUpComponent implements OnInit {

  form: FormGroup;
  submitted: boolean;
  pswdStrength: number;

  constructor(public nav: NavigateService,
    public forms: FormService,
    public api: ApiService,
    private formBuilder: FormBuilder,
    private loader: LoaderService) {}

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      firstName: ['',
        Validators.required
      ],
      lastName: ['',
        Validators.required
      ],
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20)
      ]],
      password2: ['',
        Validators.required
      ]
    }, {
      validator: this.forms.mustMatch('password', 'password2')
    });
  }

  submit() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.loader.loading(true);
      this.api.register(this.form.get('email').value, this.form.get('password').value, uuid.v1(), this.form.get('firstName').value + ' ' + this.form.get('lastName').value)
        .then(() => {
          this.loader.loading(false);
          this.submitted = false;
          this.nav.to(environment.noConfirm ? 'email-valid' : 'email-sent');
        }).catch((err) => {
          this.loader.loading(true, {type: 'warn', message: err});
          this.submitted = false;
        });
    }
  }

  checkPswdStrength($event: any) {
    this.pswdStrength = this.forms.checkPswdStrength($event);
  }
}
