import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {
  ConsumeRequestData,
  CountryCat, GetPaymentParams,
  OperationType,
  PassportType, PurposeOfVisit,
  Request,
  RequestState,
  RequestWithDuplicata
} from '../models/request';
import {isJsonParsable, parseIndexedData, parseMetadata} from '../utils/parseMetadata';
import {RequestService} from './request.service';
import {DocumentType, Gender} from '../models/user';
import {endOfDay} from 'date-fns';
import {PlatformEvent, PlatformEventType} from '../models/information';
import {Place} from '../models/place';
import {generateQrOffline} from '../utils/generateQr';
import {QrService} from './qr.service';
import {StorageService} from './storage.service';
import {Store} from '../models/store';
import {Subject} from 'rxjs';
import {PlacesService} from './places.service';
import {LoaderService} from 'ngx-satoris';
import {isEmptyObject} from '../utils/object';
import {Person} from '../models/person';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  public current$: Subject<RequestWithDuplicata> = new Subject();

  constructor(private api: ApiService,
              private request: RequestService,
              private qr: QrService,
              private loader: LoaderService,
              private places: PlacesService,
              private storage: StorageService) { }

  getPayment(id: string, loader = false, callParams: GetPaymentParams = {history: true, watchlist: true, apipnr: true, inbound: true, fullMetadata: true}, setQR = true): void {
    let savedFound: RequestWithDuplicata = undefined;
    let savedCallParamsDiff = false;
    this.storage.getFromStorage(Store.PAYMENT_STORE, id).then((res: RequestWithDuplicata) => {
      if(res) {
        savedFound = res;
        if(!savedFound.qrCode && savedFound.externalId) savedFound.qrCode = generateQrOffline(savedFound.id, this.api.userPlaceId, savedFound.externalId);
        savedFound.allProcessed = false;
        this.current$.next(savedFound);
      }
    }).finally(() => {
      let current: RequestWithDuplicata = undefined;
      this.api.status = 'info.readingPayment';

      if(loader) this.loader.loading(true);
      this.api.payment(id, callParams.history, callParams.watchlist, callParams.apipnr, callParams.inbound, savedFound?.lastModified || undefined).then((res: RequestWithDuplicata) => {
        current = res;
        current.savedCallParams = callParams;

        if(!savedFound?.savedCallParams || this.hasPropertyChangedToTrue(savedFound.savedCallParams, callParams)) savedCallParamsDiff = true;
        if(!!savedFound && !savedCallParamsDiff && this.hasNotBeenModified(savedFound, current)) {
          savedFound.allProcessed = true;
          this.save(savedFound, true);
          return;
        } else {
          current.allProcessed = false;
        }

        this.processOfflineData(current, setQR);

        if(loader) this.loader.loading(false);
        this.save(current, !savedFound);

        Promise.all([
          this.places.getPlace(current.created_place_id),
          this.places.getPlace(current.consumed_place_id),
          this.getConsumeData(current, callParams.fullMetadata),
          this.getMetadata(current, callParams.fullMetadata),
          this.getSerializedData(current, callParams.fullMetadata)])
          .then(([createdPlace, consumedPlace, consumeData, metadata, serializedData]: [Place, Place, ConsumeRequestData, any, any]) => {
            current.createdPlace = createdPlace;
            current.consumedPlace = consumedPlace;
            current.consumeData = consumeData;
            current.metadata = metadata || serializedData;
          }).finally(() => {
            this.processEvents(current).finally(() => {
              current.localUsageUntilfromServer = this.request.calculateLocalUntil(current.operationId, current.metadata.PassportType, current.metadata.Nationality);

              if(current.localUsageUntilfromServer) current.isDeclaration = true;

              if(current.metadata) {
                current.canBeDeported =  this.api.env.nationalityNoDeclaration.includes(current.metadata.Nationality) || current.metadata.PassportType === PassportType.REFUGEE;
              }
            }).finally(() => {
              this.setCurrentBatchViews(current).finally(() => {
                current.allProcessed = true;
                this.save(current, true);
              });
            });
          });
      });
    }).catch((err) => {
      this.loader.loading(true, {type: 'error', message: err});
    }).finally(() => {
      this.api.status = undefined;
    });
  }

  processOfflineData(current: RequestWithDuplicata, setQR = true) {
    const indexedData = parseIndexedData(current.internalIndexedData, current.operationId, this.api.userInfo.server);
    const parsedMetadata = isJsonParsable(current.metadata);
    if(indexedData && (!current.metadata || (parsedMetadata && isEmptyObject(parsedMetadata)))) {
      current.metadata = {
        Documents: {
          PurposeOfVisit: indexedData['documents.PurposeOfVisit'] as PurposeOfVisit || undefined
        } || undefined,
        FirstName: indexedData.firstName || undefined,
        LastName: indexedData.lastName || undefined,
        Gender: indexedData.gender as Gender || undefined,
        Nationality: indexedData.nationality || undefined,
        PassportType: indexedData.passportType as PassportType || undefined,
        PassportNumber: indexedData.passportNumber || undefined
      };
    }

    const today = endOfDay(new Date());
    const usageAfter = endOfDay(new Date(current.usageAfter));
    const usageUntil = endOfDay(new Date(current.usageUntil));
    const localUsageUntil = endOfDay(new Date(current.localUsageUntil));

    current.qrCode = generateQrOffline(current.id, this.api.userPlaceId, current.externalId);
    current.checkoutIsNext = this.request.isNextCheckout(current);
    current.actualManualConfirmRounds = this.getManualConfirmRounds(current);
    current.document = this.api.userInfo.server.paymentRequests.find(d => d.id === current.operationId);
    current.isDiplomatic = this.isDiplomatic(indexedData);
    current.isBlacklisted = this.isBlacklisted(current);
    current.isExtension = this.isExtension(current);
    current.usageAfterValid = today >= usageAfter;
    current.usageUntilValid = today <= usageUntil;
    current.localUsageUntilValid = current.localUsageUntil ? today <= localUsageUntil : true;
    current.isRevisionSinceOneWeek = new Date().getTime() - new Date(current.createdAt).getTime() > 604800000;
    current.isUsage = this.isUsage(current);
    current.cancelledEvents = this.getCancelledEvents(current);
    current.moveEvents = this.getMoveEvents(current);
    current.usageCount = this.calculateUsageCount(current);

    if(current.metadata.Nationality || current.metadata.PassportType) {
      current.canBeDeported = this.api.env.nationalityNoDeclaration.includes(current.metadata.Nationality) || current.metadata.PassportType === PassportType.REFUGEE;
    }

    this.setCurrentCountryCat(indexedData, current);

    if(setQR) {
      this.qr.setCurrentQrData(undefined, current);
      this.qr.qrOfflineGenerate(this.api.userPlaceId, current.id, current.externalId);
    }
  }

  getCancelledEvent(req: RequestWithDuplicata, eventId: number): PlatformEvent {
    if(req.cancelledEvents) {
      return req.cancelledEvents.find(e => +e.id === +eventId) || null;
    } else {
      return this.getCancelledEvents(req)?.find(e => +e.id === +eventId) || null;
    }
  }

  hasPropertyChangedToTrue(from: GetPaymentParams, to: GetPaymentParams): boolean {
    return Object.keys(from).some((key) => from[key as keyof GetPaymentParams] === false && to[key as keyof GetPaymentParams] === true);
  }

  private save(current: RequestWithDuplicata, next: boolean): void {
    this.storage.saveToStorage(Store.PAYMENT_STORE, current).catch(error => {
      console.error(`Error saving ${current.id}:`, error);
    });

    if(next) {
      this.current$.next(current);
    }
  }

  private getCancelledEvents(req: RequestWithDuplicata | Request): PlatformEvent[] {
    return req.events
      .filter(event => event.type === PlatformEventType.PAYMENT_VOIDED)
      .map(voidEvent => {
        const voidedEventId = JSON.parse(voidEvent.context).platform_event_id;
        const usageEvent = req.events.find(e => e.id === voidedEventId && e.type === PlatformEventType.PAYMENT_USAGE_VOIDED);
        if(usageEvent) {
          return {
            ...usageEvent,
            cancellationReason: JSON.parse(voidEvent.context).reason
          };
        }
        return null;
      }).filter(event => event !== null);
  }

  private getSerializedData(req: RequestWithDuplicata, fullMetadata: boolean): Promise<any> {
    return this.api.getMetadataPayment(req.serialized || req.consumeData, undefined, fullMetadata).then((res: any) => res.status === 'fulfilled' && res.value ? res.value : parseMetadata(req.metadata).metadata || parseMetadata(req.metadata));
  }

  private getMetadata(req: RequestWithDuplicata | Request, fullMetadata: boolean): Promise<any> {
    return this.api.getMetadataPayment(req.serialized || req.consumeData, undefined, fullMetadata).then((res: any) => res ? res : null);
  }

  private getConsumeData(req: RequestWithDuplicata, fullMetadata: boolean): Promise<ConsumeRequestData | undefined> {
    if(req.moveEvents.length) {
      for(const event of req.moveEvents) {
        const consumeDataUrl = JSON.parse(event.context as string).consumeData;
        if(req.consumeData === consumeDataUrl) {
          return this.api.getMetadataPayment(consumeDataUrl, undefined, fullMetadata) as Promise<ConsumeRequestData>;
        }
      }
    }
    return Promise.resolve(undefined);
  }

  private getMoveEvents(req: RequestWithDuplicata): PlatformEvent[] {
    return req.events?.filter((e: PlatformEvent) => (e.type === PlatformEventType.PAYMENT_USED || e.type === PlatformEventType.PAYMENT_USAGE_VOIDED) && e.consumeData?.Operation !== OperationType.REFUSEIN && e.consumeData?.Operation !== OperationType.REFUSEOUT).sort((a, b) => a.updatedOn.localeCompare(b.updatedOn));
  }

  private getManualConfirmRounds(req: RequestWithDuplicata | Request) {
    return this.api.userInfo.server.paymentRequests.find(document => document.id === req.operationId)?.manualConfirmRounds;
  }

  private isDiplomatic(indexedData: any) {
    return indexedData.passportType === PassportType.DIPLOMATIC;
  }

  private isExtension(req: RequestWithDuplicata | Request) {
    return req.operationId === DocumentType.ZWEVISAEXTAB || req.operationId === DocumentType.ZWEVISAEXTC;
  }

  private isBlacklisted(req: RequestWithDuplicata) {
    return this.request.isBlacklisted(req) && req.state === RequestState.EXPIRED;
  }

  private setCurrentCountryCat(indexedData: any, current: RequestWithDuplicata) {
    if(this.request.isCountryCat(indexedData.nationality, CountryCat.A)) {
      current.countryCat = CountryCat.A;
    } else if(this.request.isCountryCat(indexedData.nationality, CountryCat.B)) {
      current.countryCat = CountryCat.B;
    } else if(this.request.isCountryCat(indexedData.nationality, CountryCat.C)) {
      current.countryCat = CountryCat.C;
    }
  }

  private setCurrentBatchViews(current: RequestWithDuplicata): Promise<void> {
    if(!current.fetchedAt) {
      const batchViewPromises = current.batch.views.map(view => {
        const actionToBatch = (batchToOperate: RequestWithDuplicata) => {
          const indexData = parseIndexedData(batchToOperate.internalIndexedData, batchToOperate.operationId, this.api.userInfo.server);
          current.batch.views[current.batch.views.findIndex(b => b.id === view.id)] = {...view, ...indexData};
        };

        if(view.id === current.id) {
          actionToBatch(current);
          return Promise.resolve();
        } else {
          return this.api.payment(view.id).then((batch: RequestWithDuplicata) => actionToBatch(batch)).catch(err => {
            console.error(`Error fetching batch with id ${view.id}:`, err);
            return Promise.resolve();
          });
        }
      });
      return Promise.all(batchViewPromises).then(() => Promise.resolve());
    } else {
      return Promise.resolve();
    }
  }


  private processEvents(request: RequestWithDuplicata): Promise<void> {
    if(request.moveEvents.length) {
      const allPromises = request.moveEvents.map((event: PlatformEvent, index) => {
        const context: any = isJsonParsable(event.context);
        const eventPromises: Promise<void>[] = [];

        if(context && context.consumeData) {
          const metadataPromise = this.api.getMetadataPayment(context.consumeData, undefined, true).then((res: ConsumeRequestData) => {
            event.consumeData = res;
          });
          eventPromises.push(metadataPromise);

          if(!event.consumeData) {
            const internalData = event.internalIndexedData;
            let operation = null;
            if(internalData.includes('|IN|')) {
              operation = OperationType.IN;
            } else if(internalData.includes('|OUT|')) {
              operation = OperationType.OUT;
            }
            event.consumeData = {Operation: operation};
          }
        }

        if(context && context.place_id) {
          const placePromise = this.places.getPlace(context.place_id).then((place: Place) => {
            event.place = place;
          }).catch(err => {
            console.error(`Failed to get place for event ${index}:`, err);
            event.place = null;
          });
          eventPromises.push(placePromise);
        }
        return Promise.all(eventPromises).then(() => Promise.resolve());
      });

      return Promise.all(allPromises).then(() => {
        this.save(request, true);
        return Promise.resolve();
      }).catch(err => {
        console.error('Error during the process of move events:', err);
        return Promise.reject(err);
      });
    } else {
      return Promise.resolve();
    }
  }

  private hasNotBeenModified(req1: RequestWithDuplicata, req2: RequestWithDuplicata): boolean {
    return req1.updatedOn === req2.updatedOn;
  }

  private calculateUsageCount(request: RequestWithDuplicata): number {
    if(!request?.events || request.events.length === 0) {
      return request.usageCount;
    }

    const sortedEvents = request.events.sort((a, b) => new Date(a.updatedOn).getTime() - new Date(b.updatedOn).getTime());

    const countForcedOutEvents = sortedEvents.reduce((count, event, index) => {
      if(event.internalIndexedData?.includes(`|${OperationType.OUT}`) &&
        index > 0 &&
        sortedEvents[index - 1].type === PlatformEventType.PAYMENT_USED_FORCED) {
        return count + 1;
      }
      return count;
    }, 0);

    return countForcedOutEvents + request.usageCount;
  }

  private isUsage(request: RequestWithDuplicata): boolean {
    if(request.document?.isUsage) {
      return eval(request.document.isUsage)({Operation: request.checkoutIsNext ? OperationType.OUT : OperationType.IN});
    } else {
      return false;
    }
  }

  getRequestData(specificPerson?: Person[]): Promise<void> {
    const relevantPersons = specificPerson?.length ? specificPerson : this.api.listPersons;
    return Promise.all(relevantPersons.map((person: Person) => this.api.personRequests(person.id, true))).then((payments: RequestWithDuplicata[][]) => {
      const paymentData = payments.reduce((acc, curVal) => acc.concat(curVal), []);
      paymentData.map(payment => (this.processOfflineData(payment, false), payment));
      paymentData.forEach((payment: RequestWithDuplicata) => {
        if(payment.internalIndexedData) {
          payment.metadata = parseIndexedData(payment.internalIndexedData, payment.operationId, this.api.userInfo.server);
        } else {
          payment.metadata = isJsonParsable(payment?.metadata);
        }
      });
      if(this.request.allMyPayments?.length) {
        paymentData.forEach((newPayment) => {
          const existingPaymentIndex = this.request.allMyPayments.findIndex(payment => payment.id === newPayment.id);
          if(existingPaymentIndex >= 0) {
            this.request.allMyPayments[existingPaymentIndex] = newPayment;
          } else {
            this.request.allMyPayments.push(newPayment);
          }
        });
      } else {
        this.request.allMyPayments = paymentData;
      }
      this.request.allMyPayments.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      // get all batchIds for each person
      this.request.getBatchIds(this.api.listPersons);
      this.request.allMyApplications = undefined;
      // get all batchIds for all users
      this.request.allBatchIds = (specificPerson?.length ? this.request.allMyPayments : paymentData)?.reduce((acc: string[], request: RequestWithDuplicata) => {
        if(!acc.includes(request.batchId) && request.batchId && request.operationId !== DocumentType.ZWEVISAEXTAB && request.operationId !== DocumentType.ZWEVISAEXTC) {
          acc.push(request.batchId);
        }
        return acc;
      }, []) || [];
      // get all extensions for all users
      this.request.allExtensions = paymentData?.filter((request: RequestWithDuplicata) => request.operationId === DocumentType.ZWEVISAEXTAB || request.operationId === DocumentType.ZWEVISAEXTC) || [];
      //get all applications for all users sorted by batchIds, with each their assigned requests and their extensions within those requests
      this.request.allMyApplications = this.request.allBatchIds.map((batchId: string) => ({
        batchId,
        requests: this.request.allMyPayments.filter((request: RequestWithDuplicata) => request.batchId === batchId).map((request: RequestWithDuplicata) => {
          request.extensions = this.request.allExtensions.filter((extension: RequestWithDuplicata) => {
            const extensionIndexedData = parseIndexedData(extension.internalIndexedData, extension.operationId, this.api.userInfo.server);
            return extensionIndexedData.visaReference === request.id;
          });
          return request;
        })
      }));
      this.request.invalidatePersons = [];
    });
  }
}
