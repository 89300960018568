import {Component, ViewEncapsulation} from '@angular/core';
import {PrintService} from '../../shared/services/print.service';
import {DocsPrintType} from '../../shared/models/print';
import {NoticeType} from '../../shared/models/request';

@Component({
  selector: 'app-print-master',
  templateUrl: './print-master.component.html',
  styleUrl: './print-master.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PrintMasterComponent {
  constructor(public print: PrintService) {
  }

  protected readonly DocsPrintType = DocsPrintType;
  protected readonly NoticeType = NoticeType;
}
