import {Injectable} from '@angular/core';
import {Store} from '../models/store';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  idbstorage: IDBDatabase;

  constructor(private api: ApiService) { }

  saveToStorage<T>(store: Store, data: T, storageLimit = 500, removeFraction = 5): Promise<void> {
    return new Promise((resolve, reject) => {
      if(!this.api.userInfo) return reject('No user info');

      const timestamp = Date.now();

      this.getFromStorage(store, (data as any).id).then(existingItem => {
        if(existingItem) {
          const objStore = this.idbstorage?.transaction(store, 'readwrite').objectStore(store);
          objStore.put({...data, person_id: this.api.userInfo.id, lastModified: timestamp}).onsuccess = () => resolve();
        } else {
          const objStore = this.idbstorage?.transaction(store, 'readwrite').objectStore(store);
          const countRequest = objStore.count();

          countRequest.onsuccess = () => {
            const itemCount = countRequest.result;
            const removeCount = Math.floor(storageLimit / removeFraction);

            if(itemCount >= storageLimit) {
              const objStoreGetAll = this.idbstorage?.transaction(store, 'readwrite').objectStore(store);
              const getAllRequest = objStoreGetAll.getAll();

              getAllRequest.onsuccess = (e: any) => {
                const allItems = e.target.result;
                const items: { key: string, lastModified: number }[] = allItems.map((item: any) => ({
                  key: item.id,
                  lastModified: item.lastModified
                }));

                items.sort((a, b) => a.lastModified - b.lastModified);
                const keysToDelete = items.slice(0, removeCount).map(item => item.key);

                let deletedCount = 0;

                keysToDelete.forEach((key) => {
                  const deleteStore = this.idbstorage?.transaction(store, 'readwrite').objectStore(store);
                  deleteStore.delete(key).onsuccess = () => {
                    deletedCount++;
                    if(deletedCount === keysToDelete.length) {
                      const objStoreAdd = this.idbstorage?.transaction(store, 'readwrite').objectStore(store);
                      objStoreAdd.add({...data, person_id: this.api.userInfo.id, lastModified: timestamp}).onsuccess = () => resolve();
                    }
                  };
                });
              };

              getAllRequest.onerror = () => reject('Failed to retrieve items from storage');
            } else {
              const objStoreAdd = this.idbstorage?.transaction(store, 'readwrite').objectStore(store);
              objStoreAdd.add({...data, person_id: this.api.userInfo.id, lastModified: timestamp}).onsuccess = () => resolve();
            }
          };

          countRequest.onerror = () => reject('Failed to count items in storage');
        }
      }).catch(reject);
    });
  }


  getFromStorage<T>(store: Store, id: string, returnBool = false, getDate = false): Promise<T | { item: T; lastModified: number } | boolean | undefined> {
    return new Promise((resolve, reject) => {
      if(!this.api.userInfo) reject('No user info');

      const objStore: IDBObjectStore = this.idbstorage?.transaction(store, 'readonly').objectStore(store);
      objStore.get(id).onsuccess = (e: any) => {
        const result = e.target.result;

        if(returnBool) {
          resolve(!!result);
        } else if(getDate && result) {
          resolve({item: result as T, lastModified: result.lastModified});
        } else {
          resolve(result as T);
        }
      };
    });
  }

  removeFromStorage(store: Store, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if(!this.api.userInfo) reject('No user info');

      const objStore = this.idbstorage?.transaction(store, 'readwrite').objectStore(store);
      const request = objStore.delete(id);
      request.onsuccess = () => resolve();
      request.onerror = () => resolve();
    });
  }

  readStorage<T>(store: Store): Promise<T | undefined> {
    return new Promise((resolve, reject) => {
      if(!this.api.userInfo) reject('No user info');

      const objStore = this.idbstorage?.transaction(store, 'readonly').objectStore(store);
      const query = objStore.openCursor();
      let resolved = false;
      let defaultRow: T | undefined;

      query.onsuccess = (e: any) => {
        const cursor = e.target.result;
        if(cursor) {
          if(!resolved && cursor.value.id === this.api.userInfo.id) {
            resolve(cursor.value as T);
            resolved = true;
          } else if(!cursor.value.person_id) {
            defaultRow = cursor.value as T;
          }
          cursor.continue();
        } else if(!resolved) {
          resolve(defaultRow);
        }
      };
      query.onerror = reject;
    });
  }
}
