import {Injectable} from '@angular/core';
import {ApiCall, ApiQueueData, ApiServiceMethodNames} from '../models/queue';
import {ApiService} from './api.service';
import {LangService, LoaderService} from 'ngx-satoris';
import {format} from 'date-fns';
import {Store} from '../models/store';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class QueueService {

  public queue: ApiCall[] = [];
  private isProcessing = false;
  private isExporting = false;
  private isStopping = false;
  private processDelay = 3000;
  private showConsoleLogs = true;

  constructor(private api: ApiService,
    private lang: LangService,
    private storage: StorageService,
    private loader: LoaderService) {
    this.api.signOutObservable.subscribe(() => this.stop());
  }

  start(): void {
    if(this.isProcessing) {
      return;
    } else {
      this.isStopping = false;
      this.loadSyncQueue().then(() => this.processQueue());
    }
  }

  stop(): void {
    this.isStopping = true;
    this.queue = [];
    this.isProcessing = false;
    this.isExporting = false;
  }

  requestInQueue(id: string): boolean {
    return this.queue.some(q => q.requestId === id);
  }

  addToQueue(methodName: ApiServiceMethodNames, params: any[], customTimeout?: number, dependentCalls: Array<{ methodName: ApiServiceMethodNames; params: any[], requestId?: string}> = [], requestId?: string): Promise<any> {
    const parentId = this.generateCallId();

    const handleTimeoutError = (error: any, currentMethodName: ApiServiceMethodNames, currentParams: any[], resolve: Function, depCalls?: Array<{methodName: ApiServiceMethodNames; params: any[]}>, depParentId?: string): void => {
      const existingCall = this.queue.find(q => q.methodName === currentMethodName && JSON.stringify(q.params) === JSON.stringify(currentParams));

      if(existingCall) {
        this.showConsoleLogs && console.log('Existing call found in queue, returning its promise:', currentMethodName);
        resolve(null);
        return;
      }

      this.showConsoleLogs && console.log('Timeout detected, adding call to queue:', currentMethodName);
      this.queue.push({id: parentId, methodName: currentMethodName, params: currentParams, parentId: depParentId, error: error.message, requestId});

      depCalls?.forEach((call: {methodName: ApiServiceMethodNames; params: any[], requestId?: string}) => {
        const depId = this.generateCallId();
        this.queue.push({id: depId, methodName: call.methodName, params: call.params, parentId, requestId: call.requestId});
        this.showConsoleLogs && console.log('Adding dependent call to queue:', call.methodName);
      });

      this.saveSyncQueue()
        .then(() => {
          if(!this.isProcessing) {
            this.processQueue();
          }
          resolve(null);
        });
    };

    const executeApiCall = (method: ApiServiceMethodNames, methodParams: any[], depCalls?: Array<{methodName: ApiServiceMethodNames; params: any[]}>, depParentId?: string): Promise<any> => {
      this.api.directReturnError = true;
      if(customTimeout) this.api.customTimeout = customTimeout;

      return new Promise((resolve, reject) => {
        this.api[method](...methodParams)
          .then((result: any) => {
            this.api.directReturnError = false;
            this.api.customTimeout = undefined;
            this.showConsoleLogs && console.log('API call succeeded:', method);
            resolve(result === null ? true : result);
          })
          .catch((error: { name: string; }) => {
            this.api.directReturnError = false;
            this.api.customTimeout = undefined;
            this.showConsoleLogs && console.log('API call failed:', method);

            if(error.name === 'TimeoutError') {
              handleTimeoutError(error, method, methodParams, resolve, depCalls, depParentId);
            } else {
              reject(error);
            }
          });
      });
    };

    return new Promise((resolve, reject) => {
      executeApiCall(methodName, params, dependentCalls)
        .then(result => {
          if(dependentCalls.length && result !== null) {
            return Promise.all(dependentCalls.map(depCall => executeApiCall(depCall.methodName, depCall.params, undefined, parentId))).then(() => resolve(result));
          } else {
            resolve(result);
          }
        })
        .catch(reject);
    });
  }


  openImportDialog(): void {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json';
    input.onchange = (e: any) => {
      if(e.target.files.length > 0) {
        this.importQueueFromJSON(e.target.files[0]);
      }
    };
    input.click();
  }

  openExportDialog(event: any): void {
    event.stopPropagation();
    this.loader.loading(true, {type: 'info', btnLabel: 'queue.export', custom: {innerHtml: this.lang.transform('queue.export.desc'), icon: 'cloud-arrow-down'}}).then((done: boolean) => {
      if(done) {
        this.exportQueueToJSON();
      }
    });
  }

  private processQueue(): void {
    if(this.isStopping || this.isProcessing || this.queue.length === 0 || this.isExporting) {
      return;
    }

    this.isProcessing = true;
    this.showConsoleLogs && console.log('Starting queue processing of length:', this.queue.length);

    const processNext = (): Promise<void> => {
      this.showConsoleLogs && console.log('🔥------------------------');
      if(this.queue.length) this.queue = this.filterQueueDuplicates(this.queue);

      if(this.queue.length === 0 || this.isStopping || this.isExporting) {
        this.showConsoleLogs && console.log('Queue is empty or stopping process.');
        this.isProcessing = false;
        return Promise.resolve();
      }

      const currentCall = this.queue[0];
      this.showConsoleLogs && console.log('Starting process of call:', currentCall);

      if(this.canProcess(currentCall)) {
        this.showConsoleLogs && console.log('Call can be processed after verification');
        const method = (this.api[currentCall.methodName] as Function).bind(this.api);
        this.showConsoleLogs && console.log('Calling method:', currentCall.methodName);

        return method(...currentCall.params)
          .then((result: any) => {
            this.showConsoleLogs && console.log('Method call succeeded:', result);
            this.removeCall(currentCall);
            return this.saveSyncQueue().then(() => {
              setTimeout(() => processNext(), this.processDelay);
            });
          })
          .catch((error: { message: any; }) => {
            const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
            this.showConsoleLogs && console.log('Method call error:', errorMessage);
            currentCall.error = errorMessage;

            if(errorMessage.includes('client.extended.uuidUsed') || errorMessage.includes('client.extended.maxUsageCountReached')) {
              this.removeCall(currentCall, true);
            } else {
              this.showConsoleLogs && console.log('Moving call to the end of the queue:', currentCall.methodName);
              this.queue.push(this.queue.shift()!);
            }
            return this.saveSyncQueue().then(() => {
              setTimeout(() => processNext(), this.processDelay);
            });
          });
      } else {
        this.showConsoleLogs && console.log('Moving call to the end of the queue because it has a parent');
        this.queue.push(this.queue.shift()!);
        return this.saveSyncQueue().then(() => {
          setTimeout(() => processNext(), this.processDelay);
        });
      }
    };

    processNext()
      .catch((err) => {
        console.error('Error in queue processing:', err);
        this.isProcessing = false;
      });
  }


  private generateCallId(): string {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }

  private canProcess(call: ApiCall): boolean {
    if(!call.parentId) {
      return true;
    }
    return !this.queue.some(q => q.id === call.parentId);
  }

  private removeCall(call: ApiCall, removeDependants?: boolean): void {
    const callIndex = this.queue.findIndex(c => c.id === call.id);
    if(callIndex > -1) {
      this.showConsoleLogs && console.log('Removing call from queue:', call);
      this.queue.splice(callIndex, 1);
      if(removeDependants) {
        this.showConsoleLogs && console.log('Removing call and dependents from queue:', call);
        this.queue.filter(q => q.parentId === call.id).forEach(depCall => this.removeCall(depCall, true));
      }
    }
  }

  private loadSyncQueue(): Promise<any> {
    return this.storage.readStorage<ApiQueueData>(Store.QUEUE_STORE).then((storedQueue: ApiQueueData) => {
      if(storedQueue && storedQueue.data) {
        this.queue = storedQueue.data;
      }
    });
  }

  private saveSyncQueue(): Promise<any> {
    return new Promise((resolve, reject) => {
      if(!this.api.userInfo) reject('No user info');
      const trans = this.storage.idbstorage.transaction(Store.QUEUE_STORE, 'readwrite');
      const objStore = trans?.objectStore?.(Store.QUEUE_STORE);
      const request = objStore.put({id: this.api.userInfo.id, data: this.queue});
      request.onsuccess = resolve;
      request.onerror = reject;
      this.showConsoleLogs && console.log('Queue saved to storage');
    });
  }

  private importQueueFromJSON(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const jsonContent = e.target.result;
      try {
        const importedQueue: ApiCall[] = JSON.parse(jsonContent);
        importedQueue.forEach(call => {
          this.queue.push({
            id: call.id,
            methodName: call.methodName,
            params: call.params,
            parentId: call.parentId
          });
        });
        this.showConsoleLogs && console.log(`Imported ${importedQueue.length} calls to queue`);
        this.saveSyncQueue().then(() => {
          if(!this.isProcessing) {
            this.processQueue();
          }
        });
      } catch (error) {
        console.error('Failed to parse JSON file:', error);
      }
    };
    reader.readAsText(file);
  }

  private exportQueueToJSON(): void {
    this.isExporting = true;
    const jsonContent = JSON.stringify(this.queue, null, 2); // JSON.stringify avec indentations pour la lisibilité
    const blob = new Blob([jsonContent], {type: 'application/json;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `BMS_userID_${this.api.userInfo.id}_placeID_${this.api.userPlaceId}_${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Suppression des éléments exportés de la queue
    this.queue = [];
    this.saveSyncQueue().then(() => {
      this.isExporting = false;
      window.location.reload();
    });
  }

  private filterQueueDuplicates(queue: ApiCall[]): ApiCall[] {
    const uniqueCalls = new Map<string, ApiCall>();

    queue.forEach((call: ApiCall) => {
      if(!call) return;
      // Créer une clé unique basée sur methodName et params
      const callSignature = `${call.methodName}-${JSON.stringify(call.params)}`;

      // Ajouter l'appel au Map uniquement si la signature n'existe pas déjà
      if(!uniqueCalls.has(callSignature)) {
        uniqueCalls.set(callSignature, call);
      }
    });

    // Retourner les valeurs uniques du Map sous forme de tableau
    return Array.from(uniqueCalls.values());
  }
}
