import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ActivatedRoute} from '@angular/router';
import {DocumentType} from '../../../shared/models/user';
import {
  CurrencySign,
  RequestRemoteState,
  RequestState,
  RequestWithDuplicata
} from '../../../shared/models/request';
import {ApiService} from '../../../shared/services/api.service';
import {capitalizeFirstLetter, convertUpperCase, formatDate} from '../../../shared/utils/string';
import {environment} from 'src/environments/environment';
import {RequestService} from '../../../shared/services/request.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReceiptComponent {

  paymentId: string;
  actualPayment: RequestWithDuplicata;
  CurrencySign = CurrencySign;
  RequestRemoteState= RequestRemoteState;
  RequestState = RequestState;
  formatDate = formatDate;
  convertUpperCase = convertUpperCase;

  constructor(public nav: NavigateService,
    public api: ApiService,
    private route: ActivatedRoute,
    private request: RequestService) {
    this.paymentId = this.route.snapshot.paramMap.get('paymentId');
    this.actualPayment = this.request.allMyPayments.find((payment: RequestWithDuplicata) => payment.id === this.paymentId);
  }

  capitalize(string: string) {
    return capitalizeFirstLetter(string);
  }

  protected readonly environment = environment;
  protected readonly DocumentType = DocumentType;
}
