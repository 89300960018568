import {Component, EventEmitter, OnInit, ViewEncapsulation} from '@angular/core';
import {DialogFormInput, LoaderService, NavigateService, ShortcutAction} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {
  Currency,
  OperationType,
  PrintReceiptData,
  PurposeOfVisit,
  RequestCashType,
  RequestWithDuplicata
} from 'src/app/shared/models/request';
import {DocumentType} from 'src/app/shared/models/user';
import {ScanDocumentService} from '../../../shared/services/scan-document.service';
import {ActivatedRoute} from '@angular/router';
import {RequestService} from 'src/app/shared/services/request.service';
import {isJsonParsable} from 'src/app/shared/utils/parseMetadata';
import {QueueService} from '../../../shared/services/queue.service';
import {ApiServiceMethodNames} from '../../../shared/models/queue';
import {StorageService} from '../../../shared/services/storage.service';
import {Store} from '../../../shared/models/store';
import {PrintService} from '../../../shared/services/print.service';
import {AllDocumentsPrintType, DocsPrintType} from '../../../shared/models/print';
import {generateQrOffline} from '../../../shared/utils/generateQr';
import {Subscription} from 'rxjs';

declare const window: any;

@Component({
  selector: 'app-admin-request-face-check',
  templateUrl: './admin-request-face-check.component.html',
  styleUrls: ['./admin-request-face-check.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminRequestFaceCheckComponent implements OnInit{

  optionsCamera = {
    x: 0,
    y: 0,
    width: window.screen.width,
    height: window.innerHeight,
    camera: 'front',
    toBack: false,
    tapFocus: true,
    previewDrag: false,
    storeToFile: false,
    disableExifHeaderStripping: true
  };

  picture: string;
  imageQuality = 1;
  showCamera = false;
  backParams = {};
  backSelect = {};
  DocumentType = DocumentType;
  query: {
    createdAt: string,
    paymentId: string,
    placeID: string,
    userPlaceID: string,
    operation: OperationType,
    from: string,
    paymentReference: string,
    cashType: RequestCashType,
    amount: string,
    currency: Currency,
    fromScanPassport: boolean,
    fromScanFingerprint: string,
    localUsageUntil: Date,
    fromNoticePrint: string,
    buyDocument: DocumentType,
    nameClient: string,
    paymentIsNotDocument: 'Yes' | 'No',
    borderPass: string,
    purposeOfVisit: PurposeOfVisit
    skipCamera: 'true' | 'false',
    nationality: string;
    gender: string;
    HQReference: string;
    vendorName: string;
    vendorAddress: string;
    vendorEmail: string;
    serialNumber: number;
    deportedComment: string;
    forceOperation: OperationType;
  };

  closePreview: EventEmitter<any> = new EventEmitter<any>();
  showFaceCheck: boolean;
  faceCheckScore = 0;
  faceCheckScoreColor: string;
  scoreInterval: any;
  serialNumber: number;

  current: RequestWithDuplicata;

  printSubscription: Subscription;

  constructor(public nav: NavigateService,
    public scanDocument: ScanDocumentService,
    public api: ApiService,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private queue: QueueService,
    private print: PrintService,
    private storage: StorageService,
    private request: RequestService) {
    this.route.queryParams.subscribe((params: any) => {
      const {createdAt, paymentId, placeID, userPlaceID, operation, from, paymentReference, provisionalPermit, localUsageUntil, occupationMedia, fromScanPassport, fromScanFingerprint, fromNoticePrint, cashType, referencePrint, datePrint, nameClient, amount, currency, agentId, typePayment, placeId, buyDocument, idRequest, paymentIsNotDocument, borderPass, purposeOfVisit, skipCamera, nationality, gender, HQReference, vendorName, vendorAddress, vendorEmail, serialNumber, deportedComment, forceOperation,  ...rest} = params;
      this.query = {createdAt, paymentId, placeID, userPlaceID, operation, occupationMedia, provisionalPermit, localUsageUntil, from, paymentReference, fromScanPassport, fromNoticePrint, cashType, fromScanFingerprint, referencePrint, datePrint, nameClient, amount, currency, agentId, typePayment, placeId, buyDocument, idRequest, paymentIsNotDocument,  borderPass, purposeOfVisit, skipCamera, nationality, gender, HQReference, vendorName, vendorAddress, vendorEmail, serialNumber, deportedComment, forceOperation,  ...rest};
      this.backSelect = {from: this.query.from, ...rest};
      this.backParams = (fromScanFingerprint === 'true' || fromNoticePrint === 'true') ? params : this.backSelect;

      this.storage.getFromStorage(Store.PAYMENT_STORE, this.query.paymentId).then((data: RequestWithDuplicata) => {
        this.current = data;
      });
    });
  }

  ngOnInit(): void {
    if(this.query.skipCamera === 'true') {
      this.consumePayment(false, false, true);
      return;
    }
    this.loader.loading(true);
    setTimeout(() => {
      this.loader.loading(false);
      this.showCamera = true;
    }, 1500);
  }

  ngOnDestroy() {
    clearInterval(this.scoreInterval);
    this.printSubscription?.unsubscribe();
  }

  setPicture(imageUrl: string) {
    this.picture = imageUrl;
  }


  close() {
    this.picture = '';
    this.faceCheckScoreColor = undefined;
    this.faceCheckScore = 0;
    this.showFaceCheck = false;
    clearInterval(this.scoreInterval);
  }

  faceCheck() {
    clearInterval(this.scoreInterval);
    this.loader.loading(true);
    this.api.facialCheck(this.picture.split(',')[1], this.scanDocument.documentPhoto.split(',')[1]).then((res) => {
      const score = res * 100;
      this.loader.loading(false);
      this.showFaceCheck = true;
      this.scoreInterval = setInterval(() => {
        if(this.faceCheckScore < score) {
          this.faceCheckScore++;
          //color goes from red to green as the score goes up between 0 and 100
          this.faceCheckScoreColor = 'rgb(' + (255 - (this.faceCheckScore * 2.2)) + ', ' + (this.faceCheckScore * 2.2) + ', 0)';
        }
      }, 1000 / score);
    }).catch(() => {
      this.loader.loading(true, {type: 'error', message: 'error.faceCheck'});
    });
  }

  consumePayment(cameraManualProblem = false, cameraProblem = false, skipCamera = false) {
    const action = () => {
      const receiptToPrint = this.query?.paymentReference && this.query?.cashType && this.query?.currency ? this.query?.paymentReference : undefined;
      this.nav.to('admin-request-face-check', undefined, {queryParams: {
        paymentId: this.query.paymentId,
        userPlaceID: this.query.userPlaceID,
        placeID: this.query.placeID,
        createdAt: new Date(this.query.createdAt),
        operation: this.query.operation,
        cashType: this.query.cashType,
        amount: this.query.amount,
        currency: this.query.currency,
        borderPass: this.query.borderPass,
        localUsageUntil: this.query.localUsageUntil,
        nameClient: this.query.nameClient,
        buyDocument: this.query.buyDocument,
        paymentIsNotDocument: this.query.paymentIsNotDocument,
        ...this.backParams,
        fromScanPassport: this.query.fromScanPassport,
        fromScanFingerprint: this.query.fromScanFingerprint,
        fromNoticePrint: this.query.fromNoticePrint,
        skipCamera: this.query.skipCamera,
        paymentReference: this.query.paymentReference,
        purposeOfVisit: this.query.purposeOfVisit,
        nationality: this.query.nationality,
        gender: this.query.gender,
        HQReference: this.query.HQReference || '',
        vendorName: this.query.vendorName,
        vendorAddress: this.query.vendorAddress,
        vendorEmail: this.query.vendorEmail,
        deportedComment: this.query.deportedComment,
        forceOperation: this.query.forceOperation
      }});
      if(skipCamera) {
        this.loader.loading(true);
        return this.performSyncQueue(this.query.operation).then(this.handleFinalSteps).catch(() => {
          this.loader.loading(true, {type: 'error', message: 'payment.consume.error.DYN.' + this.api.env.type}).then(() => {
            this.goBack();
          });
        });
      }

      const dialogInput = this.query.operation === OperationType.INRETURNED ? [{
        formCtrlName: 'deportedComment',
        type: 'textarea',
        label: 'deportedComment',
        maxLength: 1000
      }] as DialogFormInput[] : [];

      this.loader.loading(true, {type: cameraProblem? 'warn' : 'info', message: cameraProblem ? 'camera.init.error' : cameraManualProblem ? 'camera.problem.manual' : ('payment.consume.ask.DYN.' + this.api.env.type), btnLabel: 'global.confirm', dialogInput}).then((done: any) =>{
        if(done) {
          this.loader.loading(true);
          if(this.query.fromScanFingerprint !== 'true') {
            window.sessionStorage.removeItem('fingerprint');
          }
          if(this.query.fromNoticePrint !== 'true') {
            window.sessionStorage.removeItem('noticePrint');
          }
          let consumePromises;
          if(this.query.purposeOfVisit === PurposeOfVisit.TRANSIT_VISA && this.query.operation !== OperationType.REFUSEIN) {
            consumePromises = this.performSyncQueue(OperationType.IN, OperationType.OUT, done.deportedComment);
          } else {
            consumePromises = this.performSyncQueue(this.query.operation, undefined, done.deportedComment);
          }
          return consumePromises
            .then(this.handleFinalSteps)
            .catch(() => {
              this.loader.loading(true, {type: 'error', message: 'payment.consume.error.DYN.' + this.api.env.type}).then(() => {
                if(skipCamera) {
                  this.goBack();
                }
              });
            });
        } else {
          if(cameraProblem) {
            if(this.query.fromScanPassport) {
              this.nav.to(this.query.from);
            } else {
              this.nav.to('admin-request-select/' + this.query.paymentId, undefined, {queryParams: {...this.backSelect, receiptToPrint}});
            }
          }
        }
      });
    };
    action();
  }

  goBack() {
    if(this.query.fromNoticePrint === 'true') {
      this.nav.to('admin-request-notice/' + this.query.paymentId, undefined, {queryParams: this.backParams});
    } else if(this.query.fromScanFingerprint === 'true') {
      this.nav.to('admin-scan-fingerprint');
    } else if(this.query.from && this.query.fromScanPassport) {
      this.nav.to(this.query.from, undefined, {queryParams: this.backParams});
    } else {
      this.nav.to('admin-request-select/' + this.query.paymentId, undefined, {queryParams: this.backParams});
    }
  }

  askPrint() {
    return this.loader.loading(true, {type: 'info', message: 'payment.consume.print.done.DYN.' + this.api.env.type}).then(() => {
      this.doPrint().then(() => {
        this.printSubscription = this.print.printLoaded.subscribe((loaded: AllDocumentsPrintType) => {
          if(loaded) {
            this.loader.loading(true, {type: 'info', message: 'payment.ask.print', btnLabel: 'global.yes', custom: {closeBtnLabel: 'global.no.retry'}}).then((done) => {
              if(done) {
                this.storage.removeFromStorage(Store.PAYMENT_STORE, this.query.paymentId);
                this.nav.to('dashboard');
              } else {
                this.printSubscription.unsubscribe();
                return this.askPrint();
              }
            }).catch(() => {
              this.loader.loading(true, {type: 'error', message: 'error.printReceipt'});
            });
          }
        });
      });
    });
  }


  doPrint = () => new Promise<void>((resolve) => {
    const receiptData: PrintReceiptData = {
      referencePrint: this.query.paymentReference,
      datePrint: new Date(),
      nameClient: this.query.nameClient,
      amount: +this.query.amount,
      currency: this.query.currency,
      agentId: this.api.userInfo.id,
      typePayment: this.query.cashType,
      placeId: this.api.userPlaceId,
      buyDocument: (this.query.borderPass && this.query.borderPass === 'Yes') ? DocumentType.BORDER_PASS : this.query.buyDocument,
      idRequest: this.query.paymentId,
      operation: this.query.operation,
      nationality: this.query.nationality,
      gender: this.query.gender,
      HQReference: this.query.HQReference,
      vendorName: this.query.vendorName,
      vendorAddress: this.query.vendorAddress,
      vendorEmail: this.query.vendorEmail,
      serialNumber: this.serialNumber,
      qrCode: generateQrOffline(this.query.paymentId, this.api.userPlaceId, undefined)
    };
    const printReceiptThermal = () => this.request.printReceipt(receiptData).then(() => resolve()).catch(([error]: [string]) => {
      this.loader.loading(true, {type: 'warn', message: error, btnLabel: 'global.print'}).then((done) => {
        if(done) {
          this.print.print<PrintReceiptData>(DocsPrintType.RECEIPT, receiptData);
        }
        resolve();
      });
    });
    const storedValue = isJsonParsable(localStorage.getItem('printThermal'));
    const printThermal = storedValue !== null ? storedValue : true;
    if(this.api.isElectronApp && printThermal) {
      return printReceiptThermal();
    } else {
      this.print.print<PrintReceiptData>(DocsPrintType.RECEIPT, receiptData);
      resolve();
    }
  });

  performSyncQueue(operation: OperationType, secondOperation?: OperationType, deportedComment?: string): Promise<any> {
    const noticePrintData = window.sessionStorage.getItem('noticePrint') ? JSON.parse(window.sessionStorage.getItem('noticePrint')) : undefined;
    const fingerPrintData = window.sessionStorage.getItem('fingerprint') ? window.sessionStorage.getItem('fingerprint') : undefined;

    const createPaymentParams = (op: OperationType) => ({
      ProfilePicture: (this.query.purposeOfVisit === PurposeOfVisit.TRANSIT_VISA && op === OperationType.OUT) ? undefined : this.picture,
      Operation: op,
      PaymentReference: (this.query.purposeOfVisit === PurposeOfVisit.TRANSIT_VISA && op === OperationType.OUT) ? undefined : this.query.paymentReference,
      PaymentIsNotDocument: this.query.paymentIsNotDocument,
      PaymentMode: (this.query.purposeOfVisit === PurposeOfVisit.TRANSIT_VISA && op === OperationType.OUT) ? undefined : this.query.cashType,
      PaymentAmountCents: (this.query.purposeOfVisit === PurposeOfVisit.TRANSIT_VISA && op === OperationType.OUT) ? undefined : this.query.paymentIsNotDocument === 'Yes' ? +this.query.amount : undefined,
      PaymentCurrency: (this.query.purposeOfVisit === PurposeOfVisit.TRANSIT_VISA && op === OperationType.OUT) ? undefined : this.query.currency,
      Fingerprint: fingerPrintData ? JSON.parse(fingerPrintData) : undefined,
      ReturnedPassengerReason: this.query.operation === OperationType.INRETURNED ? (this.query.deportedComment !== undefined ? this.query.deportedComment : deportedComment) : undefined,
      ...((this.query.purposeOfVisit === PurposeOfVisit.TRANSIT_VISA) ? {} : noticePrintData)
    });
    const action = (): Promise<any> => {
      const mainCallParams = [
        this.query.paymentId,
        this.query.userPlaceID,
        new Date(),
        this.query.placeID,
        new Date(this.query.createdAt),
        '',
        createPaymentParams(operation),
        this.query.localUsageUntil ? new Date(new Date(this.query.localUsageUntil).getTime() + 24 * 60 * 60 * 1000) : undefined,
        this.query.forceOperation ? true : undefined
      ];

      const dependantCalls: Array<{methodName: ApiServiceMethodNames; params: any[]}> = secondOperation ? [
        {
          methodName: 'consumePayment',
          params: [
            this.query.paymentId,
            this.query.userPlaceID,
            new Date(),
            this.query.placeID,
            new Date(this.query.createdAt),
            '',
            createPaymentParams(secondOperation),
            this.query.localUsageUntil ? new Date(new Date(this.query.localUsageUntil).getTime() + 24 * 60 * 60 * 1000) : undefined,
            this.query.forceOperation ? true : undefined
          ]
        }
      ] : [];
      return this.queue.addToQueue('consumePayment', mainCallParams, 5000, dependantCalls, this.query.paymentId);
    };
    return action();
  }

  handleFinalSteps = () => {
    window.sessionStorage.removeItem('fingerprint');
    window.sessionStorage.removeItem('noticePrint');
    this.loader.loading(false);
    if(this.query?.paymentReference && this.query?.cashType && this.query?.currency) {
      return this.askPrint();
    } else {
      this.storage.removeFromStorage(Store.PAYMENT_STORE, this.query.paymentId);
      return this.nav.to('dashboard');
    }
  };
  protected readonly ShortcutAction = ShortcutAction;
}
