/* tslint:disable */
export const cn_strings = {
  'transactionReference.alt': '交易参考',
  'paymentType.alt': '支付类型',
  'changeGender.ask': '申请中的性别必须更改为男性或女性。',
  'print.askMultiple': '您想在打印输出中包含所有相关文件吗？',
  'application.id': '申请编号',
  'printSticker': '打印签证贴纸',
  'print.receiptA5': '打印 A5 收据',
  'correctionFields': '以下字段已更正：',
  'corrected.desc': '此签证申请已由申请人在 {date} 更正。',
  'recentlyCorrected': '已更正',
  'visaValidFrom': '入境有效期从',
  'visaValidUntil': '入境有效期至',
  'online': '在线',
  'offline': '离线',
  'receiptNr': '收据编号',
  'issuingOfficer.alt': '签发官员',
  'placeOfIssue': '签发地点',
  'fileRefNr': '档案参考编号',
  'tooltip.hasBeenCorrected': '此申请已由申请人更正。',
  'tooltip.extendStay': '延长申请人的停留时间',
  'printFoldable': '打印可折叠文件',
  'printApprovalLetter': '打印批准信',
  'operation.REFUSEOUT': '拒绝出境',
  'events': '事件',
  'tooltip.events': '这些是事件的详细信息。根据事件类型和执行的操作，有些可能比其他事件有更多信息。',
  'form.paymentReference': '支付参考',
  'error.closeApplication': '关闭申请时发生错误。请再试一次。',
  'closeApplication': '标记为已关闭',
  'tooltip.closeApplication': '将申请标记为已关闭。此操作不可撤销。',
  'tooltip.tableNationalityTitle': '申请人的国籍',
  'understand': '我理解',
  'ExtraDocument1': '额外文件 #1',
  'ExtraDocument2': '额外文件 #2',
  'ExtraDocument3': '额外文件 #3',
  'ExtraDocument4': '额外文件 #4',
  'ExtraDocument5': '额外文件 #5',
  'paperSize': '纸张宽度',
  'global.changePrinterSize': '更改收据纸张宽度',
  'change_lang': '更改语言',
  'queue.export.desc': '是否要将当前队列导出为 JSON 文件？<b>导出后，本地队列将被删除</b>。您确定要继续吗？',
  'queue.export': '导出为 JSON',
  'queue.import': '从 JSON 导入队列',
  'nationality': '国籍',
  'gender': '性别',
  'HQReference': '总部参考',
  'vendorName': '供应商名称',
  'vendorAddress': '供应商地址',
  'vendorEmail': '供应商电子邮件',
  'idRequest': '申请编号',
  'serialNumber': '序列号',
  'global.resetDefaults': '恢复默认设置',
  'shortcuts.desc': '您可以在下方设置自己的键盘快捷键。点击您想设置新快捷键的键，并按下键盘上的所需键。',
  'press.key': '按下任意键设置快捷键...',
  'dashboard.keyboardShortcuts': '键盘快捷键',
  'tooltip.changeShortcuts': '更改键盘快捷键',
  'global.changeShortcuts': '更改快捷键',
  'retake_picture': '重新拍照',
  'error.faceCheck': '处理人脸匹配时发生错误。请再试一次。',
  'faceCheck.see_score': '人脸匹配',
  'recommendOrNot': '推荐与否',
  'recommended': '推荐',
  'not_recommended': '不推荐',
  'error.downgrade': '降级请求时发生错误。请再试一次。',
  'done.downgrade': '请求已成功降级为单次入境签证。',
  'request.downgrade': '降级',
  'err.externalCode.pattern': '外部代码 (ICAO) 必须具有正确的格式：4 个大写字母',
  'request.extendStay': '延长停留时间',
  'ResidencePermitNumber': '居住许可编号',
  'start_continue': '继续支付',
  'error.payment.noInternalReference': '此申请需要总部参考才能进行审核。请等待提供参考。',
  'err.Occupation.required': '职业为必填项',
  'err.HomeAddress.required': '家庭地址为必填项',
  'err.PassportCountry.required': '护照国家为必填项',
  'request.revision': '是否要激活自动跳过功能？此功能将自动跳过不需要您输入的已审核步骤。',
  'notice.BORDER_PASS': '边境通行证',
  'form.borderPassNumber': '边境通行证编号',
  'form.dateOfBirth': '出生日期',
  'form.issueDate': '签发日期',
  'form.authorizedCrossingPoints': '授权过境点',
  'form.issuedBy': '签发人',
  'err.borderPassNumber.required': '边境通行证编号为必填项',
  'err.dateOfBirth.required': '出生日期为必填项',
  'err.expiryDate.required': '到期日期为必填项',
  'err.authorizedCrossingPoints.required': '授权过境点为必填项',
  'err.issuedBy.required': '签发人为必填项',
  'err.issueDate.required': '签发日期为必填项',
  'form.externalCode': '外部代码 (ICAO)',
  'err.invoke': '错误',
  'ask.pendingRequest': '您有一个待更正的请求。现在是否打开？',
  'entry.confirmation': '您确定提供的所有信息正确吗？点击“确认”后，您将提交申请。',
  'free': '免费',
  'last_update': '最后更新',
  'count': '计数',
  'tooltip.linkedExtensions': '这是停留延期。点击查看详细信息。',
  'extension': '延期',
  'client.extended.badPaymentMetadata': '支付元数据无效。请重试，或如果问题持续，请联系支持。',
  'DaysRequested': '请求的天数',
  'payment.cancelled': '付款已取消。如果您希望继续，请重试。您将被重定向到上一页。',
  'payment.declined': '付款已被拒绝。请检查您的付款详细信息和资金可用性。您将被重定向到上一页。',
  'extension.ref': '停留延期参考',
  'extensions.desc': '待审核的停留延期',
  'extend': '延长',
  'err.SpouseBirthDay.maxDate': '配偶的出生日期必须在今天之前',
  'CheckboxVisaExtendFees': '我确认提供的信息真实准确，并且我同意停留延期费用、条款和隐私政策。',
  'err.AttestantPhone.pattern': '电话号码格式不正确，例如：+263771234567',
  'err.AttestantEmail.email': '担保人电子邮件格式不正确',
  'err.AttestantDocument.required': '担保人文件为必填项',
  'err.AffidavitCustody.required': '监护宣誓书为必填项',
  'err.CopyOfEntry.required': '入境副本为必填项',
  'err.DepartureTicket.required': '出境机票为必填项',
  'AttestantDocument': '担保人文件',
  'AffidavitCustody': '监护宣誓书',
  'CopyOfEntry': '入境副本',
  'DepartureTicket': '出境机票',
  'DYN.Self': '本人',
  'DYN.Spouse/Dependent': '配偶/家属',
  'DYN.Employee/Employer': '雇员/雇主',
  'DYN.Co-Director': '联合董事',
  'DYN.Lawyer': '律师',
  'VisaReference': '签证参考',
  'ReasonForExtension': '延期原因',
  'AttestantID': '担保人 ID',
  'AttestantName': '担保人姓名',
  'AttestantPhone': '担保人电话',
  'AttestantEmail': '担保人电子邮件',
  'AttestantAddress': '担保人地址',
  'AttestantRelation': '与担保人的关系',
  'err.VisaReference.required': '签证参考为必填项',
  'err.ReasonForExtension.required': '延期原因为必填项',
  'err.AttestantID.required': '担保人 ID 为必填项',
  'err.AttestantName.required': '担保人姓名为必填项',
  'err.AttestantPhone.required': '担保人电话为必填项',
  'err.AttestantEmail.required': '担保人电子邮件为必填项',
  'err.AttestantAddress.required': '担保人地址为必填项',
  'err.AttestantRelation.required': '与担保人的关系为必填项',
  'start_extend': '延长停留时间',
  'visaExtend.title': '停留延期',
  'days': '天',
  'day': '天',
  'err.Email.email': '电子邮件格式不正确',
  'err.EmergencyEmail.email': '紧急联系人电子邮件格式不正确',
  'kiosk.standby.text1': '通过这台平板电脑或在线，几次点击即可开始填写您的声明。',
  'kiosk.standbyDesc.text1': '在您开始之前，请注意以下信息：',
  'kiosk.standbyDesc.li1': '您将填写一份收集您的个人数据和旅行详细信息的电子表格。',
  'kiosk.standbyDesc.li2': '所提供的所有信息必须准确无误，并与您的旅行证件（护照、签证等）相符。',
  'kiosk.standbyDesc.li3': '完成表格后，您可以直接携带身份证件前往移民柜台。',
  'kiosk.standbyDesc.li5': '如果您遇到任何困难，请随时向边境站的工作人员寻求帮助。',
  'kiosk.standbyDesc.text2': '准备好填写入境声明时，点击“开始”。',
  'kiosk.success.title': '申请提交成功',
  'kiosk.success.text1': '现在您可以携带旅行证件前往移民柜台。官员将进行最终检查并确认您的入境。',
  'kiosk.success.text2': '如果您有任何问题或困难，请随时向可用的工作人员寻求帮助。',
  'confirm.revision': '确认修订',
  'print.again': '不，再次打印',
  'print.isDone': '通知是否已成功打印？请确保将打印的通知提供给申请人。',
  'err.otherMissingDocument.required': '其他缺失文件为必填项',
  'err.reportAt.required': '报告地点为必填项',
  'err.reportOn.required': '报告日期为必填项',
  'err.reportInterval.required': '报告间隔为必填项',
  'err.address.required': '地址为必填项',
  'err.periodFrom.required': '起始日期为必填项',
  'err.periodTo.required': '结束日期为必填项',
  'form.hostPhone': '房东电话',
  'err.hostPhone.required': '房东电话为必填项',
  'transit': '过境',
  'conditionalEntry': '有条件入境',
  'departure': '离境',
  'err.host.required': '房东为必填项',
  'err.purpose.required': '访问目的为必填项',
  'err.fullNames.required': '全名为必填项',
  'err.officer.required': '官员为必填项',
  'err.paragraph.required': '段落为必填项',
  'err.dateOfRefusal.required': '拒绝日期为必填项',
  'form.paragraph': '段落',
  'form.dateOfRefusal': '拒绝日期',
  'form.purpose': '访问目的',
  'form.host': '房东',
  'form.officer': '官员',
  'form.reportAt': '报告地点',
  'form.reportOn': '报告日期',
  'form.reportInterval': '报告间隔',
  'notice.desc.RESTRICTION': '临时限制通知告知申请人完成流程所需的文件。您可以选择申请人必须在指定期限内提供的缺失文件。',
  'notice.REFUSAL': '入境拒绝通知',
  'notice.desc.REFUSAL': '申请人被拒绝入境。',
  'notice.desc.REPORTING': '访客通知允许申请人入境，但可能需要进一步报告。您可以选择申请人必须在指定期限内提供的缺失文件。',
  'err.hqReference.required': '总部参考为必填项',
  'err.stationReference.required': '站点参考为必填项',
  'err.reportLocation.required': '报告地点为必填项',
  'err.daysUntilReport.required': '报告截止天数为必填项',
  'err.businessAddress.required': '业务地址为必填项',
  'err.residentialAddress.required': '居住地址为必填项',
  'form.stationReference': '站点参考',
  'form.fullNames': '全名',
  'form.reportLocation': '报告地点',
  'form.daysUntilReport': '报告截止天数',
  'form.otherMissingDocument': '其他缺失文件',
  'form.residentialAddress': '居住地址',
  'form.businessAddress': '业务地址',
  'form.hqReference': '总部参考',
  'nationalPassport': '国家护照',
  'residentPermit': '居住许可',
  'proofOfInvestment': '投资证明',
  'evidenceOfMeans': '资金证明',
  'evidenceOfResidentialStatus': '居住状态证明',
  'studentScholarPermit': '学生/学者许可证',
  'radiologicalCertificate': '放射学证书',
  'temporaryPermit': '临时许可证',
  'evidenceOfEmployment': '就业证明',
  'temporaryEmploymentPermit': '临时工作许可证',
  'fullAddressOfDestination': '目的地完整地址',
  'appealOutcome': '上诉结果',
  'request.notice': '选择打印通知',
  'notice.title': '可打印的通知',
  'notice.desc': '请从下方列表中选择一项通知。填写所需字段后，您可以为申请人打印通知。',
  'notice.RESTRICTION': '临时限制通知',
  'notice.REPORTING': '访客通知',
  'notice.RESTRICTION.desc': '申请人必须在指定期限内提供缺失文件。',
  'notice.REFUSAL.desc': '申请人被拒绝入境。',
  'notice.REPORTING.desc': '申请人允许入境，但可能需要进一步报告。',
  'passportType.kioskWarning': '所选国籍属于 C 类政权。请立即前往移民官员处。',
  'free.charge.total': '总费用免费',
  'err.entryPoints': '加载计划入境口岸时出错',
  'err.PreviousConvictionsHomeAsk.required': '请回答关于您在国内是否有犯罪记录的问题',
  'summary.your_meta': '您的数据（点击展开）',
  'summary.payOnline': '我更愿意现在在线支付，而不是在抵达时在官员柜台支付。',
  'summary.payOnline.desc': '选择以下选项将带您进入支付页面。如果您选择不在线支付，您将在抵达时在官员柜台支付签证费用。',
  'summary.meta': '的数据（点击展开）',
  'EmergencyLastName': '紧急联系人姓氏',
  'EmergencyFirstName': '紧急联系人名字',
  'EmergencyEmail': '紧急联系人电子邮件',
  'EmergencyPhone': '紧急联系人电话',
  'err.EmergencyLastName.required': '紧急联系人姓氏为必填项',
  'err.EmergencyFirstName.required': '紧急联系人名字为必填项',
  'err.EmergencyEmail.required': '紧急联系人电子邮件为必填项',
  'err.EmergencyPhone.required': '紧急联系人电话为必填项',
  'person.name': '选定人员的姓名',
  'DYN.identity-card': '身份证',
  'DYN.resident-permit': '临时居留许可',
  'DYN.identity-card.desc': '身份证是政府签发的用于国内身份识别的文件。',
  'DYN.resident-permit.desc': '临时居留许可是政府为外籍人员签发的官方文件，允许其在指定时间内居住在该国。',
  'err.visaFeesAndTerms.required': '请接受签证费用、使用条款和隐私政策',
  'err.NonOrdinaryPassMission.required': '官方访问的原因是必填项',
  'NonOrdinaryPassMission': '官方访问的原因',
  'err.PreviousConvictionsHome.required': '国内犯罪记录是必填项',
  'PreviousConvictionsHomeAsk': '您或您的家属是否在本国有任何犯罪记录？',
  'PlaceOfBirthCountry': '出生国家',
  'PlaceOfBirthCity': '出生城市',
  'OtherName': '其他名字',
  'travelName': '申请参考',
  'plannedEntry': '计划入境口岸',
  'summary.title': '摘要',
  'urgent': '紧急',
  'visaType': '文件类型',
  'passportType': '身份证明类型',
  'err.plannedEntry.required': '计划入境口岸为必填项',
  'understood': '我同意',
  'passportType.acknowledge': '选择此护照类型，即表示您承认不遵守使用条件可能导致入境被拒绝。',
  'form.travelName.already': '<b>此申请已提交，并带有以下申请参考。如果您想更改参考，请重新开始新申请。</b>',
  'application.current_person': '您正在为...编辑申请',
  'DYN.USD': '美元',
  'DYN.EUR': '欧元',
  'DYN.ZWL': '津巴布韦元',
  'DYN.GBP': '英镑',
  'DYN.BWP': '博茨瓦纳普拉',
  'participants.edit': '编辑申请人',
  'request.existSaved': '已找到保存的申请，您想加载其数据吗？（如果您点击“否”，该申请将被删除，您将重新开始一个新的申请）',
  'participant.hasData': '您已经为此参与者输入了数据。如果继续，数据将会丢失。您确定要继续吗？',
  'participants.title': '申请人',
  'participants.desc': '请选择您自己和/或将成为此申请一部分的人员。</br><b>最后的支付将为所有选择的人进行。</b></br></br>您可以随时通过点击表单上方显示的姓名，添加/删除人员或编辑其申请。</br></br>{participantText}',
  'participant.ready': '准备好时，点击“继续”。',
  'participant.missing': '继续之前，请创建至少一个人。',
  'travelName.title': '申请参考',
  'form.travelName': '申请参考',
  'form.travelName.desc': '申请参考将用于更轻松地识别您的申请。',
  'err.travelName.required': '申请参考为必填项',
  'nationality.title': '国籍',
  'nationality.desc': '请选择您的国籍。可用文件类型的列表将根据此选择而有所不同。',
  'passportType.title': '文件类型',
  'passportType.desc': '请选择您在此申请中使用的文件类型。',
  'visaType.title': '可用入境选项',
  'visaType.desc': '请选择您希望申请的入境选项。此列表会根据您的国籍和护照类型有所不同。',
  'urgent.title': '这是否紧急？',
  'urgent.desc': '如果此签证申请为<b>紧急</b>，请选择此选项。处理时间将缩短，但将收取<b>额外费用</b>来提供此服务。</br></br>请注意，处理时间可能会根据签证类型和申请国家的不同而有所变化。',
  'urgent.checkbox': '我希望我的签证申请作为紧急事项处理，并且我理解此服务将收取额外费用。',
  'visa.paidOnArrival.title': '抵达时支付签证费用',
  'visa.paidOnArrival.desc': '允许符合条件的旅行者在目的地的入境口岸获得签证，简化了短期访问的入境流程。',
  'visa.paidOnline.title': '在线支付签证费用',
  'visa.paidOnline.desc': '允许在指定的一（1）个月期间内一次或多次入境该国，之后的入境需要重新签发签证。',
  'dashboard.zweentry': '入境声明',
  'PassportType': '护照类型',
  'PassportCountry': '护照签发国家',
  'form.passportCountry': '护照签发国家',
  'DYN.ordinary': '普通护照',
  'DYN.diplomatic': '外交护照',
  'DYN.official': '公务护照',
  'DYN.service': '服务护照',
  'DYN.refugee': '难民护照',
  'DYN.ordinary.desc': '普通护照是为公民签发的最常见类型的护照，适用于一般旅行。',
  'DYN.diplomatic.desc': '外交护照是为外交官和领事签发的，用于公务旅行和在国外的居住。',
  'DYN.refugee.desc': '难民护照是由国家签发给被认定为难民的人，用于出国旅行。',
  'DYN.official.desc': '公务护照是为政府工作人员签发的，用于公务旅行和在国外的居住。',
  'DYN.service.desc': '服务护照是为政府工作人员签发的，用于公务旅行和在国外的居住。',
  'DYN.marine': '海员护照',
  'DYN.marine.desc': '海员护照是为水手和船员签发的，用于旅行和在国外的居住。',
  'DYN.laissez-passer': '通行证',
  'DYN.laissez-passer.desc': '通行证是由联合国或其机构签发的，用于公务旅行和在国外的居住。',
  'err.PassportType.required': '护照类型为必填项',
  'Urgent': '这是一个紧急请求。我理解处理时间将会缩短，但费用将会更高。',
  'photo.passport.scanned': '护照已扫描',
  'review.nr.final': '最终审核',
  'review.nr': '审核编号',
  'err.ArrivalDate.maxDateMilliTo': '护照的到期日必须在您到达该国日期的至少六（6）个月之后。请在申请签证前更新此护照。',
  'err.DateOfExpiry.maxDateMilliTo': '您的护照将在到达该国的六（6）个月内到期。请在申请签证前更新此护照。',
  'place.update.error': '更新地点时发生错误',
  'dashboard.kiosk.start': '开始流程',
  'form.role.kiosk': '自助服务终端',
  'currency_desc': '请选择入境时可用的资金金额和货币：',
  'FundsAvailable': '金额',
  'FundsAvailableCurrency': '货币',
  'err.FundsAvailable.required': '入境时可用的资金为必填项',
  'err.FundsAvailableCurrency.required': '入境时可用的资金货币为必填项',
  'emergency_contact_text': '请提供紧急情况下可以联系的人员的联系方式。',
  'emergency_contact': '紧急联系人 / 近亲',
  'err.BirthDay.mustBeEqualOrBefore': '出生日期必须等于或早于护照签发日期',
  'err.DateOfIssue.mustBeEqualOrAfter': '签发日期必须等于或晚于出生日期',
  'internet.lost': '互联网连接丢失。</br>请检查您的连接。',
  'err.DateOfExpiry.maxDateMilliFromToday': '您的护照将在今天起的六（6）个月内到期。请在申请签证前更新此护照。',
  'err.DepartureDate.maxDateMilliFrom': '离境日期与到达日期之间的时间过长。请参阅签证的时间限制。',
  'requirements': '要求',
  'err.file.size': '文件大小太大。最大尺寸为：',
  'revision.success': '您的请求更新已成功提交。批准后，您将收到包含二维码的电子邮件。',
  'PAYMENT_UPDATED': '请求已更新',
  'revision.free': '免费修订',
  'err.Email.pattern': '电子邮件格式不正确',
  'err.Phone.pattern': '电话号码格式不正确，例如：+263 77 123 4567',
  'revision.start': '我们已尽力根据您在初始申请中提供的信息填写了字段，除引起问题的字段外。请检查信息并进行更正。您想继续吗？',
  'file_uploaded': '文件已上传并存储',
  'revision.person_not_found': '无法找到与此请求关联的申请人。',
  'refusal.desc2': '以下字段存在问题：',
  'start_correction': '开始更正',
  'PAYMENT_SUSPEND_CHANGE': '请求已暂停，已提出更改。',
  'payment.selectMode': '请选择有问题的字段。您可以选择多个字段。',
  'suspendReason.desc': '请简要描述修订请求的原因。此描述将与所选的问题字段一起发送给请求者。',
  'refusalReason.incomplete_or_missing': '表单信息不完整或缺失。',
  'refusalReason.inconsistencies_or_contradictions': '提供的信息存在不一致或矛盾之处。',
  'refusalReason.incorrect_misleading_info': '不正确、误导或虚假的信息。',
  'refusalReason.missing_or_illegible_documents': '未提供所需文件，文件不完整或不可读。',
  'refusalReason.documents_not_compliant': '文件不符合要求（质量、有效性）。',
  'refusalReason.non_compliance_visa_requirements': '不符合特定签证要求。',
  'refusalReason.terms_conditions_not_accepted': '未接受签证条款和条件。',
  'refusalReason.payment_issues': '签证费用未支付或支付失败。',
  'refusalReason.problems_with_personal_details': '与申请人的年龄、国籍或婚姻状况相关的问题。',
  'refusalReason.criminal_history_travel_issues': '与犯罪记录或旅行记录相关的问题。',
  'refusalReason.inadequate_purpose_of_visit': '签证类型的访问目的不充分或不符合要求。',
  'refusalReason.security_background_concerns': '安全或背景调查中存在疑虑。',
  'refusalReason.doubts_about_authenticity': '对所提供的信息或文件的真实性表示怀疑。',
  'refusalReason.legitimacy_concerns': '在停留或邀请的合法性方面发现问题。',
  'refusalReason.form_entry_or_technical_issues': '申请过程中的表格输入错误或技术问题。',
  'refusalReason.electronic_submission_difficulties': '与电子文件提交相关的困难。',
  'refusalReason.host_country_capacity_or_restrictions': '东道国的接待能力已超出或当前有接待限制。',
  'refusalReason.exceptional_diplomatic_political_considerations': '特殊的外交或政治考虑。',
  'refusalReason.exceptional_circumstances_humanitarian_reasons': '因特殊情况或人道主义原因拒签。',
  'refusalReason.other': '其他，请说明。',
  'err.BirthDay.maxDate': '出生日期必须在今天之前',
  'err.DepartureDate.minDate': '离境日期必须在今天之后',
  'err.ArrivalDate.minDate': '到达日期必须在今天之后',
  'err.DateOfExpiry.minDate': '到期日期必须在今天之后',
  'err.DateOfIssue.maxDate': '签发日期必须在今天之前',
  'err.ArrivalDate.mustBeEqualOrBefore': '到达日期必须等于或早于离境日期',
  'err.DepartureDate.mustBeEqualOrAfter': '离境日期必须等于或晚于到达日期',
  'qr.request_desc': '此二维码作为签证处理的数字通行证。政府保留在签证管制时批准或拒绝入境的权利。',
  'PAYMENT_REFUSED': '付款被拒绝',
  'request_state.PENDING': '待定',
  'document.Screening': '筛查',
  'deletePerson.error.DYN.pass': '删除人员时发生错误。您无法删除已为文件支付费用的人。',
  'deletePerson.error': '删除人员时发生错误。您无法删除已提交申请的人。',
  'miscellaneousTrip': '旅行详细信息',
  'miscellaneous_text': '请如实填写以下信息',
  'err.SpouseBirthDay.max': '配偶的出生日期无效',
  'err.ArrivalDate.min': '到达日期无效',
  'err.DepartureDate.min': '离境日期无效',
  'err.DateOfIssue.max': '签发日期无效',
  'err.BirthDay.max': '出生日期无效',
  'err.dependants_date_of_birth.max': '家属的出生日期无效',
  'err.DateOfExpiry.min': '到期日期无效',
  'dependants': '家属',
  'err.PassportPhoto.required': '需要最近的照片',
  'err.PassportScan.required': '需要护照扫描件',
  'err.ProofOfResidenceHost.required': '需要住宿证明',
  'err.BusinessLetter.required': '需要商业信函',
  'err.BusinessProfile.required': '需要商业简介',
  'err.InvitationLetterAndBusinessProfile.required': '需要邀请函和商业简介',
  'err.SpouseFullName.required': '需要配偶的姓名',
  'err.SpousePassportNumber.required': '需要配偶的护照号码',
  'err.SpouseBirthDay.required': '需要配偶的出生日期',
  'err.SpousePlaceOfBirth.required': '需要配偶的出生地点',
  'err.ArrivalDate.required': '到达日期为必填项',
  'err.DepartureDate.required': '离境日期为必填项',
  'err.DateOfIssue.required': '签发日期为必填项',
  'err.DateOfExpiry.required': '到期日期为必填项',
  'err.Email.required': '电子邮件为必填项',
  'err.Phone.required': '电话号码为必填项',
  'err.HostName.required': '房东姓名为必填项',
  'err.LastName.required': '姓氏为必填项',
  'err.FirstName.required': '名字为必填项',
  'err.BirthDay.required': '出生日期为必填项',
  'err.PlaceOfBirthCity.required': '出生城市为必填项',
  'err.PlaceOfBirthCountry.required': '出生国家为必填项',
  'err.Gender.required': '性别为必填项',
  'err.MaritalStatus.required': '婚姻状况为必填项',
  'err.Nationality.required': '国籍为必填项',
  'err.PurposeOfVisit.required': '访问目的为必填项',
  'male': '男性',
  'female': '女性',
  'other': '其他',
  'single': '单身',
  'married': '已婚',
  'separated': '分居',
  'divorced': '离婚',
  'widowed': '丧偶',
  'select_reason_first': '请在上一页选择访问原因以查看所需文件列表。',
  'visaIntro.title': '申请',
  'askDocument': '开始申请',
  'cart.load': '已找到保存的申请，是否加载其数据？（如果您点击“否”，该申请将被删除）',
  'cart.load.otherConfig': '已找到保存的申请，但您选择的文件来自不同类型的签证表格。是否继续并删除以前的申请？',
  'cart.keep': '是否要保存您的请求并稍后继续？',
  'err.email.pattern': '电子邮件格式不正确',
  'err.phone.pattern': '电话号码格式不正确，例如：+263 77 123 4567',
  'remove': '移除',
  'dependant_add': '添加家属',
  'dependant': '家属',
  'err.dependants_name.required': '家属姓名为必填项',
  'err.dependants_passport_number.required': '家属护照号码为必填项',
  'err.dependants_date_of_birth.required': '家属出生日期为必填项',
  'err.dependants_place_of_birth.required': '家属出生地点为必填项',
  'upload_file': '上传文件（< {size}）',
  'PassportPhoto': '最近的照片',
  'PassportScan': '护照扫描件',
  'ProofOfResidenceHost': '住宿证明',
  'AddressAtDestination': '目的地地址',
  'HostInvitationLetter': '房东邀请函',
  'ProofOfResidence': '居住证明',
  'ApplicationLetter': '申请函',
  'ResidentialStatusOfHost': '房东的居住状态',
  'ReasonOfVisitProof': '访问目的证明',
  'BusinessLetter': '商业信函',
  'BusinessProfile': '商业简介',
  'InvitationLetterAndBusinessProfile': '邀请函和商业简介',
  'InvitationLetter': '邀请函',
  'LetterOfAcceptanceFromSchool': '确认信',
  'err.AddressAtDestination.required': '目的地地址为必填项',
  'err.HostInvitationLetter.required': '房东邀请函为必填项',
  'err.ProofOfResidence.required': '居住证明为必填项',
  'err.ApplicationLetter.required': '申请函为必填项',
  'err.ResidentialStatusOfHost.required': '房东的居住状态为必填项',
  'err.ReasonOfVisitProof.required': '访问目的证明为必填项',
  'err.LetterOfAcceptanceFromSchool.required': '确认信为必填项',
  'err.InvitationLetter.required': '邀请函为必填项',
  'DYN.male': '男性',
  'DYN.female': '女性',
  'DYN.other': '其他',
  'DYN.single': '单身',
  'DYN.married': '已婚',
  'DYN.separated': '分居',
  'DYN.divorced': '离婚',
  'DYN.widowed': '丧偶',
  'passport.valid': '扫描的文件似乎没有被篡改或吊销，并由签发国签署。',
  'travel_information_title': '注意',
  'zwevisa_application_title': '在继续之前，请确保',
  'zwevisa_application_text': '<ol><li><b>1. </b>您的信息准确真实</li><li><b>2. </b>您拥有 MasterCard 或 Visa 3D Secure 卡，并且有足够的资金支付签证费用。</li><li><b>3. </b>通过支付和提交申请，您确认所有输入信息正确真实。伪造信息以获取签证是非法的，一经定罪，将受到惩罚。</li></ol>',
  'visaPrice.desc': '在申请结束时，您需要使用 Visa 或 MasterCard 进行支付。此支付不可退还。',
  'visaPrice': '签证价格',
  'required': '必填',
  'DYN.holiday_visa': '度假签证',
  'DYN.live_here': '长期居住',
  'DYN.business_visa': '商务签证',
  'DYN.conferencing_visa': '会议签证',
  'DYN.transit_visa': '过境签证',
  'DYN.student_visa': '学习签证',
  'paypal': '贝宝',
  'document': '文件',
  'Dependants': '家属',
  'dependants_name': '姓名',
  'dependants_passport_number': '护照号码',
  'dependants_date_of_birth': '出生日期',
  'dependants_place_of_birth': '出生地点',
  'Spouse': '配偶',
  'SpouseFullName': '配偶姓名',
  'SpousePassportNumber': '配偶护照号码',
  'SpouseBirthDay': '配偶出生日期',
  'SpousePlaceOfBirth': '配偶出生地点',
  'ArrivalDate': '预计到达日期',
  'DepartureDate': '预计离境日期',
  'ReferencePreviousVisa': '上一个签证的参考',
  'PreviousConvictionsHome': '在本国的定罪性质',
  'travel_information': '旅行信息',
  'DateOfIssue': '签发日期',
  'DateOfExpiry': '到期日期',
  'PlaceofIssue': '签发地点',
  'Email': '电子邮件',
  'Phone': '电话号码',
  'Occupation': '职业',
  'HomeAddress': '当前居住地址',
  'HostName': '接待方名称',
  'form.seniority': '资历等级',
  'LastName': '姓氏',
  'FirstName': '名字',
  'BirthDay': '出生日期',
  'PlaceOfBirth': '出生地',
  'Gender': '性别',
  'MaritalStatus': '婚姻状况',
  'CheckboxVisaFees': '我确认所提供的信息真实准确，并同意签证费用、条款和隐私政策。',
  'PassportNumber': '护照号码',
  'Nationality': '国籍',
  'PurposeOfVisit': '访问目的',
  'VisaType': '签证类型',
  'ResidenceProof': '居住证明',
  'DestinationAddress': '目的地地址',
  'visitor_details': '申请人详细信息',
  'personal_details': '个人信息',
  'contact_details': '联系方式',
  'miscellaneous_details': '旅行详细信息',
  'spouse_details': '配偶信息',
  'payment': '支付',
  'dashboard.documents.DYN.pass': '文件请求',
  'global.scan_qr': '验证二维码',
  'qr.nosuch': '加载中... 系统中尚未找到二维码。请检查互联网连接。',
  'qr.faked': '服务器响应：二维码不存在，可能是伪造的！',
  'qr.quicklook': '关键信息',
  'global.toPayments.DYN.pass': '查看支付',
  'dashboard.documents.DYN.zwevisa': '签证申请',
  'global.toPayments.DYN.zwevisa': '查看申请',
  'form.createPlaceId': '地点ID',
  'payment.seeDuplicata': '打开副本',
  'payment.duplicate.open': '打开新支付？',
  'ONE_DAY': '1天',
  'THREE_DAYS': '3天',
  'ONE_WEEK': '1周',
  'TWO_WEEKS': '2周',
  'ONE_MONTH': '1个月',
  'TWO_MONTHS': '2个月',
  'SIX_MONTHS': '6个月',
  'ONE_YEAR': '1年',
  'dropdown.since': '自从',
  'payments.hasFilter': '筛选已激活',
  'payments.clear': '重置',
  'payments.clearFilters': '清除所有筛选',
  'place_created.open': '打开地点信息页面',
  'place_created.desc': '新地点已创建，现在可用。',
  'payment_status.DYN.NONE': '-- 无筛选 --',
  'dropdown.state': '状态',
  'payment.create_noExternalId': '付款已登记，但需要主管批准才能使用。一旦付款被接受或拒绝，将通过电子邮件通知您。',
  'payment.qrList.title': '二维码支付列表',
  'see.qr_list': '查看二维码列表',
  'form.role.partner_registrer': '合作伙伴注册',
  'form.reason': '原因',
  'err.reason.required': '原因是必填项',
  'global.reject': '拒绝',
  'payment.validate_desc': '您确定要验证此支付吗？',
  'payment.reject_desc': '您确定要拒绝此支付吗？别忘了添加原因',
  'qr.title': '二维码',
  'payments.count.DYN.pass': '支付次数：',
  'payments.count.DYN.zwevisa': '申请次数：',
  'dashboard': '仪表板',
  'dashboard.document': '签证申请',
  'dashboard.follow_up': '申请跟进',
  'dashboard.people.DYN.pass': '人员',
  'dashboard.people.DYN.zwevisa': '已保存的人员',
  'dashboard.my_account': '我的账户',
  'form.refunded_date': '退款日期',
  'form.enter_message': '输入信息',
  'form.end_date': '结束日期',
  'form.start_date': '开始日期',
  'form.search_anything': '搜索任何内容',
  'form.invalid_end_date': '无效的结束日期',
  'form.invalid_start_date': '无效的开始日期',
  'form.id': 'ID',
  'form.enter_date_range': '输入日期范围',
  'payments.scan': '扫描二维码',
  'print.qr.desc': '这是一个唯一且安全的二维码，可以根据指定的次数使用。请勿刮擦以保持其可用性。',
  'print.qr.title': '二维码',
  'print.informations': '信息',
  'print.notice.folding': '折叠说明',
  'print.notice.desc2': '如果对本文件有任何疑问，请通过电话或电子邮件联系支持。',
  'print.notice.desc1': '请将本文件远离水或火源。',
  'print.notice.title': '注意',
  'client.extended.openSuchPayment': '此用户已激活一笔付款',
  'form.choose_role': '选择角色',
  'create_card_payment.fail_title': '支付处理中',
  'create_card_payment.fail_text': '这可能需要一段时间。完成后，您将通过电子邮件收到通知。付款和二维码将在您的付款中可用。',
  'err.externalId.required': '付款参考是必填项',
  'err.placeExternalId.required': '外部ID是必填项',
  'form.externalId': '付款参考',
  'form.userExternalId': '外部ID',
  'global.confirm': '确认',
  'global.submit': '提交',
  'global.create': '创建',
  'global.back': '返回',
  'global.close': '关闭',
  'global.contact_support': '联系支持',
  'global.previous': '上一步',
  'global.next': '下一步',
  'global.accept': '接受',
  'global.under_dev': '开发中',
  'global.logout': '退出',
  'global.support': '支持',
  'global.consume': '消费',
  'global.validate': '验证',
  'global.refund': '退款',
  'global.duplicate': '复制',
  'global.delete': '删除',
  'global.see_more': '查看更多',
  'global.update': '更新',
  'global.modify': '修改',
  'global.download': '下载',
  'global.personal': '个人',
  'global.print': '打印',
  'global.print_c7': '打印票据',
  'global.print_a4': '打印证书',
  'global.checkIn': '入住',
  'global.checkOut': '退房',
  'choose-lang.title': '语言偏好',
  'choose-lang.desc': '请选择将用于此应用程序的语言。',
  'global.all': '全部',
  'global.personalNum': '个人编号',
  'global.passportNum': '护照编号',
  'global.not_available': '不可用',
  'global.show_qr': '显示二维码',
  'global.show_qr_signature': '显示已签名的二维码',
  'global.no_results_for': '未找到任何结果',
  'global.delete_sure': '此操作不可逆，您确定要删除此项吗？',
  'global.add_new': '新增',
  'global.add': '添加',
  'global.permissions': '权限',
  'global.send': '发送',
  'global.block': '封锁',
  'global.blocked': '已封锁',
  'global.unblock': '解锁',
  'global.daily_otp': '每日代码',
  'global.refresh': '刷新',
  'global.connect': '连接',
  'global.disconnect': '断开连接',
  'form.email': '电子邮件',
  'form.name': '名称',
  'form.firstName': '名字',
  'form.lastName': '姓氏',
  'form.password': '密码',
  'form.password2': '重新输入密码',
  'form.linkType': '此人与你的关系是什么？',
  'form.userId': '用户ID',
  'form.parentId': '父ID',
  'form.amount': '金额',
  'form.currency': '货币',
  'form.metadata': '元数据',
  'form.buyingOption': '购买的文件',
  'form.place': '地点',
  'form.terms': '我同意服务条款和隐私政策',
  'form.role': '角色',
  'form.no_data': '没有匹配筛选条件的数据',
  'form.filter': '筛选',
  'form.phoneNumbers': '电话号码',
  'form.connectUAPattern': '用户代理模式',
  'form.connectIPRange': 'IP范围',
  'form.state': '状态',
  'form.type': '类型',
  'form.operation': '操作',
  'form.created_date': '创建日期',
  'form.created_place': '创建地点',
  'form.consumed_place': '已使用地点',
  'form.refunded_place': '退款地点',
  'form.email_code': '电子邮件中的代码',
  'form.addToPlace': '地点ID',
  'form.placeToAdd': '要添加的地点',
  'form.message': '信息',
  'form.phone': '电话号码',
  'form.worker': '员工ID',
  'form.camera': '摄像头',
  'form.comment': '可选评论',
  'form.password_old': '当前密码',
  'form.password_new': '新密码',
  'form.password_new2': '重新输入新密码',
  'form.idCard': '身份证号',
  'form.type.CASH': '现金',
  'form.type.CARD': '卡片',
  'form.currency.EUR': '欧元',
  'form.currency.USD': '美元',
  'form.currency.ZAR': '兰特',
  'form.role.customer': '顾客',
  'form.role.worker': '员工',
  'form.confirmed': '已确认？',
  'yes': '是',
  'no': '否',
  'form.borderPass': '发放边境通行证',
  'form.role.orbis_consumer': 'Orbis 消费者',
  'form.role.admin': '管理员',
  'form.choose_currency': '选择货币',
  'form.choose_place': '选择地点',
  'form.choose_type': '选择类型',
  'form.choose_buyingOption': '选择文件',
  'form.choose_camera': '选择摄像头',
  'form.perm1': '创建二维码',
  'form.perm2.DYN.pass': '使用二维码',
  'form.perm2.DYN.zwevisa': '处理签证申请',
  'form.perm3.DYN.pass': '复制/退款二维码及子地点',
  'form.perm3.DYN.zwevisa': '审查签证申请',
  'form.perm4.DYN.pass': '创建/管理用户及创建子地点',
  'form.perm4.DYN.zwevisa': '创建/管理地点、用户和签证申请',
  'done.password_changed': '密码已更改',
  'done.user_created': '用户已创建',
  'done.place_created': '地点已创建',
  'done.account_created': '账户已创建，请检查您的电子邮件以进行验证',
  'client.extended.clientNotAllowed': '您的账户当前不允许在此地点使用。请检查您的连接设置。',
  'err.submit': '表单提交错误',
  'err.email.required': '电子邮件是必填项',
  'err.email.email': '电子邮件格式必须正确：email@mail.com',
  'err.password.required': '密码是必填项',
  'err.password.minlength': '密码长度必须在6到20个字符之间',
  'err.password.maxlength': '密码长度必须在6到20个字符之间',
  'err.password2.required': '必须重新输入密码',
  'err.password2.mustMatch': '两次密码不匹配',
  'err.role.required': '角色是必填项',
  'err.name.required': '名称是必填项',
  'err.firstName.required': '名字是必填项',
  'err.lastName.required': '姓氏是必填项',
  'err.terms.required': '必须接受条款和条件',
  'err.amount.required': '金额是必填项',
  'err.currency.required': '货币是必填项',
  'err.metadata.required': '元数据是必填项',
  'err.place.required': '地点是必填项',
  'err.noSmsPerm': '未授予SMS权限',
  'err.noSmsReceive': '无法绑定SMS接收',
  'err.code.required': '电子邮件中的代码是必填项',
  'err.buyingOption.required': '购买选项是必填项',
  'err.addToPlace.required': '地点ID是必填项',
  'err.phone.required': '电话号码是必填项',
  'err.message.required': '信息是必填项',
  'err.text.maxlength': '文本过长',
  'err.old.required': '当前密码是必填项',
  'err.idCard.required': '身份证号是必填项',
  'helps.showProceed': '显示继续',
  'helps.showScan': '显示扫描文档',
  'helps.captureFingerprint': '采集指纹',
  'payment_status.DYN.undefined': '当前状态 - 不是操作',
  'client.extended.missingResourceLogin': '无法连接，凭证错误',
  'client.extended.linkedAccountExists': '此电子邮件已存在关联账户',
  'client.extended.attachedPayments': '无法修改此人，已经有创建的申请与其关联。',
  'client.extended.badPayload': '请求信息有误',
  'client.extended.placeNotAllowed': '地点不允许',
  'client.extended.locked': '账户已锁定',
  'client.extended.clientBadOtp': '每日代码错误',
  'time': '时间',
  'place': '地点',
  'home.first': '您的账户已验证，您现在可以登录。',
  'payOnline': '在线支付',
  'login.sign_in': '登录',
  'login.sign_up': '注册',
  'login.forgot_password': '忘记密码？',
  'photo.entryCountry': '入境/出境时的照片',
  'photo.unavailable': '照片不可用',
  'global.entry': '入境',
  'global.exit': '出境',
  'menu.create_user': '创建用户',
  'menu.create_place': '创建地点',
  'menu.create_payment': '创建支付',
  'menu.users': '用户',
  'menu.places': '地点',
  'menu.payments': '支付',
  'menu.about': '关于',
  'menu.contact': '联系',
  'create_user.title': '创建用户',
  'create_payment.title': '创建支付',
  'create_place.title': '创建地点',
  'users.title': '用户数据库',
  'payments.title': '支付数据库',
  'payments.employee_graph': '员工支付图表',
  'payments.open_scan': '扫描二维码',
  'payments.download_csv': '下载CSV',
  'create_card_payment.title': '在线支付',
  'create_card_payment.success_title': '支付已收到！',
  'create_card_payment.success_text': '您的支付已成功收到。二维码已通过电子邮件发送给您，也可以通过点击下方按钮访问。',
  'create_card_payment.no_data': '此支付不再可用，请检查您的电子邮件以获取二维码，或从菜单中点击支付。',
  'places.title': '地点列表',
  'forgot_password.title': '重置密码',
  'forgot_password.title_new': '设置新密码',
  'forgot_password.sent': '确认已发送！',
  'forgot_password.sent_text': '确认电子邮件已发送，其中包含重置密码的链接。',
  'forgot_password.done': '密码已重置',
  'forgot_password.done_text': '您已成功重置密码。现在可以登录。',
  'welcome.first': '您的账户已验证！',
  'welcome.title_1.DYN.pass': '你好！',
  'welcome.text_1.DYN.pass': '感谢您加入{appName}。我们很自豪能提供将您的支付转换为安全且唯一二维码的最佳方式。',
  'welcome.text_1.DYN.zwevisa': '感谢您加入{appName}。我们很自豪能提供在线申请签证的最佳方式。',
  'welcome.text_1.1.DYN.pass': '让我们进一步了解我们吧？',
  'welcome.text_1.1.DYN.zwevisa': '让我们进一步了解我们吧？',
  'welcome.title_2.DYN.pass': '{appName}是什么？',
  'welcome.text_2.DYN.pass': '{appName}让您在线提交支付，以换取安全且唯一的二维码。',
  'welcome.text_2.DYN.zwevisa': '{appName}让您在线申请签证，并通过电子邮件收到安全且唯一的二维码，包含签证所需的所有信息。',
  'welcome.text_2.1.DYN.pass': '然后可以使用此代码在当地管理处支付官方文件，如文件、身份证等。',
  'welcome.text_2.1.DYN.zwevisa': '此代码可用作您批准的签证申请的边境证明。',
  'welcome.title_3': '作为员工',
  'welcome.text_3.DYN.pass': '通过{appName}，客户可以来找您支付官方文件。您可以通过银行卡或现金登记支付。',
  'welcome.text_3.DYN.zwevisa': '通过{appName}，您可以在边境检查入境和出境人员。根据情况和您的权限，您还可以批准或拒绝签证申请。',
  'welcome.text_3.1.DYN.pass': '然后生成一个安全且唯一的二维码，准备打印给您的客户。',
  'welcome.text_3.1.DYN.zwevisa': '您还可以管理您的组织的用户和地点。',
  'welcome.title_4.DYN.pass': '更好的是',
  'welcome.text_4.DYN.pass': '您甚至可以离线注册支付。我们对我们的二维码生成方式安全可靠充满信心。',
  'welcome.text_4.DYN.zwevisa': '您甚至可以离线管理签证申请。我们对我们的二维码生成方式安全可靠充满信心。',
  'welcome.text_4.1.DYN.pass': '一旦您重新上线，一切都会再次同步。',
  'welcome.text_4.1.DYN.zwevisa': '一旦您重新上线，一切都会再次同步。',
  'welcome.title_5.DYN.pass': '尽情享受吧！',
  'welcome.text_5.DYN.pass': '感谢使用{appName}，如果有任何反馈，请使用主页上的联系按钮与我们联系。',
  'payment_status.DYN.zwevisa.PROPOSED': '待付款',
  'payment_status.DYN.zwevisa.PAID': '准备入住',
  'payment_status.DYN.zwevisa.PAID_NOT_CONFIRMED': '等待审核',
  'payment_status.DYN.zwevisa.USED': '已退房',
  'payment_status.DYN.zwevisa.DUPLICATED': '已复制',
  'payment_status.DYN.zwevisa.REFUNDED': '已退款',
  'payment_status.DYN.zwevisa.EXPIRED': '被拒',
  'payment_status.DYN.zwevisa.PENDING': '等待修正',
  'payment_status.DYN.zwevisa.DELAY_PAY_NOT_CONFIRMED': '延迟付款 - 未确认',
  'payment_status.DYN.zwevisa.DELAY_PAY_NOT_CONFIRMED.FREE': '免费 - 未确认',
  'payment_status.DYN.zwevisa.DELAY_PAY': '准备到达时付款',
  'payment_status.DYN.pass.PROPOSED': '已提议',
  'payment_status.DYN.pass.PAID': '已支付',
  'payment_status.DYN.pass.PAID_NOT_CONFIRMED': '未确认',
  'payment_status.DYN.pass.USED': '已使用',
  'payment_status.DYN.pass.DUPLICATED': '已复制',
  'payment_status.DYN.pass.REFUNDED': '已退款',
  'payment_status.DYN.pass.EXPIRED': '已过期',
  'payments.make_bundle': '打包',
  'payments.validate': '确认',
  'passportPage.askPassport': '护照申请',
  'documentContinue.title': '继续购物',
  'documentContinue.stop': '重新购买',
  'documentContinue.desc1': '您已经开始了文件申请过程。',
  'documentContinue.desc2': '您想继续您的购买吗？',
  'payment.history': '支付历史',
  'permission.no_place_found': '没有找到此用户的新地点',
  'bt.available_devices': '可用蓝牙设备',
  'bt.no_available_devices': '未检测到可用蓝牙设备',
  'emailSend.title': '电子邮件验证',
  'emailSend.description': '为了验证您的账户，您必须点击发送到您电子邮件中的验证链接，24小时内有效。',
  'emailValid.title': '账户已验证',
  'emailValid.description': '您的 {appName} 账户已成功验证。您现在可以使用您的凭证登录个人空间。',
  'documentManagement.title': '签证申请',
  'passportPage.select': '选择想要申请护照的人。',
  'passportPage.choosePerson': '选择申请人',
  'preenroll.title': '预注册',
  'preenroll.description': '预注册允许您在完成文件之前开始第一步。然后您只需前往认证中心进行验证。',
  'passportEmbassy.title': '大使馆护照',
  'passportEmbassy.description': '大使馆护照是主权国家为其外交官出差以及随行成员颁发的文件。',
  'zwevisa.title': '签证',
  'passwordSend.title': '确认已发送',
  'passwordSend.description': '您已收到一封确认邮件，其中包含一个重置密码的链接。您有24小时的时间点击它。',
  'forgotPassword.title': '忘记密码',
  'forgotPassword.description': '要重置密码，请在下方输入您的账户电子邮件。',
  'forgotPassword.description.electron': '要重置密码，请在下方输入您的账户电子邮件。如果您正在尝试恢复<b>活动目录</b>账户的密码，请联系支持。',
  'passwordNew.title': '新密码',
  'modifiedPassword.title': '密码已更改',
  'modifiedPassword.description': '密码已更改，请重新登录。',
  'people.title.DYN.pass': '人员',
  'people.title.DYN.zwevisa': '已保存人员',
  'people.addPerson.DYN.pass': '添加人员',
  'people.addPerson.DYN.zwevisa': '创建新人员',
  'meansPayment.title': '支付方式',
  'onlinePayment.title': 'PayPal支付',
  'onlinePayment.description': '通过您的信用卡在线完成支付。',
  'onlinePayment.pay': '支付',
  'qrPayment.title': '二维码支付',
  'qrPayment.description': '要完成支付，请扫描有效的二维码。',
  'qrPayment.scan': '扫描二维码',
  'processPayment.description': '要进行支付，请输入您的信用卡信息。',
  'form.cardNumber': '卡号',
  'form.expireDate': '到期日期',
  'successPayment.title': '订单确认',
  'successPayment.description1': '此支付已完成。文件正在处理中，当文件准备好或需要修订时您将收到通知。',
  'successPayment.description2': '如果您没有收到通知，请通过您的账户页面访问支持。',
  'trackingControl.title.DYN.pass': '请求跟进',
  'trackingControl.title.DYN.zwevisa': '跟踪此申请',
  'trackingControl.enroll': '注册已准备好',
  'trackingControl.treatment': '正在处理',
  'modifyData': '修改数据',
  'stopModifyData': '停止修改数据',
  'trackingControl.revision': '修订请求',
  'askRevisionProcess': '处理正在进行中',
  'askRevision': '请求修订',
  'seeRevision': '查看修订',
  'askOrdinaryPassport': '普通护照申请',
  'askPassport': '申请护照',
  'askVisa': '申请签证',
  'actualRevision': '请求审核',
  'askRevision.description': '为处理您的普通护照请求，请向我们发送您当前居住证的副本。',
  'peopleNew.title.DYN.pass': '新人员',
  'peopleNew.title.DYN.zwevisa': '新人员',
  'peopleFound.title.DYN.pass': '找到的人员',
  'peopleFound.title.DYN.zwevisa': '找到的人员',
  'peopleFound.description.DYN.pass': '输入信息对应的人员已被添加到保存的人员列表中。',
  'peopleFound.description.DYN.zwevisa': '输入信息对应的人员已被添加到保存的人员列表中。',
  'payment.commandHistory': '支付历史',
  'menu.placesPerm': '地点和权限',
  'block.message': '您想要封锁此用户吗？',
  'menu.addPlace': '添加地点',
  'label.numberAccount': '账户号',
  'place.sheet.open': '打开地点信息页面',
  'place.delete': '删除与此地点的链接',
  'place.update.success': '地点已成功更新。',
  'place.new': '新地点',
  'place.create': '创建地点',
  'place.add.ask': '您想要将此位置链接到此用户吗？',
  'place.add.ok': '您已成功将此地点链接到此用户。',
  'place.delete.link': '删除与此地点的链接',
  'place.delete.link.ask': '您确定要取消与此地点的链接吗？',
  'place.delete.link.done': '您已成功删除与地点的链接。',
  'user.new': '新用户',
  'user.create': '创建用户',
  'user.created': '新用户创建成功。现在可以使用此账户。',
  'user.sheet.open': '打开用户信息页面',
  'payment.sheet.open': '打开支付信息页面',
  'err.createplace': '无法创建。ID已存在。',
  'place.parent': '父地点',
  'dropdown.sorting': '排序',
  'dropdown.qr': '扫描二维码',
  'dropdown.pass': '扫描文件',
  'err.noReader': '读卡器未连接。请连接读卡器以继续。',
  'err.noCard': '读卡器中未检测到有效的生物识别文件。这可能是由于使用了非生物识别文件、未经授权的副本或伪造的文件，或读取天线出现故障。',
  'err.noConnect': '此文件不符合ICAO标准协议。这可能是由于使用了非生物识别文件、未经授权的副本或伪造的文件，或读取天线出现故障。',
  'err.noBAC': '无法建立与文件的安全通道，它是真实的吗？请手动全面核实此文件并询问持有者。',
  'err.alteredDoc': '无法读取文件字段，它是真实的吗？请手动全面核实此文件并询问持有者。',
  'err.revokedDocSigner': '此文件的签发者已被其发行机构吊销。可能是文件被欺诈性签发。请全面询问持有者。',
  'err.unverifiedDoc': '无法验证文件的有效性，身份验证协议错误，这可能表明该文件是在非ICAO主列表国签发的，可能是伪造的文件，或读取天线出现故障。',
  'err.noPassiveAuthentication': '无法验证文件的有效性，身份验证协议错误，可能是伪造的文件，或读取天线出现故障。',
  'err.noResult': '未找到文件',
  'dropdown.user': '用户',
  'dropdown.meansPayment': '支付方式类型',
  'payment.new.DYN.pass': '新支付',
  'payment.new.DYN.zwevisa': '新签证出口',
  'payment.create': '创建支付',
  'payment.created.register': '支付已注册',
  'payment.created': '您的支付已保存，现在可以在支付列表中查看。',
  'payment.created.qr': '包含生成的二维码的电子邮件已发送到所提供的电子邮件地址。',
  'text.status': '状态',
  'phone.number': '电话号码',
  'idcard.number': '身份证号',
  'form.userCreator': '由用户创建',
  'form.userRefund': '由用户退款',
  'form.userConsumed': '由用户消费',
  'PRICEUP': '价格 0-9',
  'PRICEDOWN': '价格 9-0',
  'RECENT': '最新',
  'LATEST': '最旧',
  'STATUSUP': '状态 a-z',
  'STATUSDOWN': '状态 z-a',
  'payment.success': '您的支付已注册',
  'err.meansPayment.required': '支付方式是必需的',
  'payment.consume.ask.DYN.pass': '您确定要消费此支付吗？',
  'payment.consume.ask.DYN.zwevisa': '您确定要办理此签证申请的入境/出境手续吗？',
  'payment.consume.done.DYN.pass': '您的支付已成功消费',
  'payment.consume.done.DYN.zwevisa': '您的签证申请已成功办理入境/出境手续',
  'payment.consume.refuse.DYN.zwevisa': '此签证申请已成功被拒绝。',
  'checkinOut.refuse': '您确定要拒绝此申请吗？',
  'payment.duplicate.ask': '您确定要复制此支付吗？',
  'payment.duplicate.done': '您的支付已成功复制',
  'payment.refund.ask': '您确定要退款此支付吗？',
  'payment.refund.done': '您的支付已成功退款',
  'payment.refund.exist': '此支付已被退款',
  'payment.paid.error': "您的支付需要在状态 '完成' 下",
  'unlink.place.error': '您必须至少有一个活跃的地点才能取消链接此地点。请先添加另一个地点',
  'block.done': '账户已被封锁',
  'block.unblock': '账户已被解封',
  'dailyOtp.txt': '每日安全代码',
  'place-add.add': '添加地点',
  'permission.title': '需要权限: 相机',
  'qr.see': '查看二维码',
  'qr.desc.DYN.pass': '这是支付的二维码。它将在打印时出现，并将发送到注册时输入的电子邮件地址。',
  'qr.desc.DYN.zwevisa': '这是申请的二维码。它将在打印时出现，并将发送到注册时输入的电子邮件地址。',
  'global.print.a4': '打印A4文件',
  'dashboard.place.verif': '如果您还没有输入地点，请进行输入',
  'uptodate': '您已是最新',
  'block.yourself': '您不能封锁自己',
  'lastPlace.delete': '此账户中的最后一个地点即将被删除。这样做将使此账户无法使用。继续吗？',
  'place.add.done': '该地点已成功添加到此用户。',
  'notallow.payment': '您无权创建支付',
  'no.connection': '要访问此页面，您必须连接到互联网。请重新登录',
  'place.delete.success': '该地点已被删除。',
  'wrong.current': '您输入了错误的当前密码',
  'no.place': '此账户没有地点。',
  'no.payment': '没有符合当前筛选条件的支付',
  'email.error': '相似的电子邮件已经存在',
  'email.notexist': '电子邮件不存在',
  'one.payment': '您必须至少创建一个或更多支付并/或进行筛选。',
  'err.client.extended.uuidUsed': '此支付已被消费',
  'no.activecamera': '未发现活跃的相机',
  'no.accessPermission': '此账户没有访问权限',
  'download.success': '您的文件已下载至下载文件夹',
  'print.name': '打印机',
  'print.success': '您的票已打印',
  'print.need': '您必须先连接到打印机才能打印',
  'printer.connect': '您现在已连接到打印机：',
  'permission.desc': '您要使用的服务需要使用设备的相机权限',
  'err.personnalNR.required': '需要个人编号',
  'err.passportNR.required': '需要护照号码',
  'createPeople.ask.DYN.pass': '您确定要创建此人吗？',
  'createPeople.ask.DYN.zwevisa': '您确定要创建此新人员吗？',
  'createPeopleDuplicate.ask.DYN.zwevisa': '似乎您的注册人员中已有同名人员。您仍然想创建此人吗？',
  'createPeople.done.DYN.pass': '此人已被创建',
  'createPeople.done.DYN.zwevisa': '此人已被创建',
  'deletePerson.DYN.pass': '删除此人',
  'deletePerson.DYN.zwevisa': '删除此人',
  'deletePerson.ask.DYN.pass': '您确定要删除此人吗？',
  'deletePerson.ask.DYN.zwevisa': '您确定要删除此人吗？',
  'deletePerson.valid.DYN.pass': '此人已被删除',
  'deletePerson.valid.DYN.zwevisa': '此人已被删除',
  'personModification.ask.DYN.pass': '您确定要更改此人的数据吗？',
  'personModification.ask.DYN.zwevisa': '您确定要更改此人的数据吗？',
  'personModification.valid.DYN.pass': '此人的数据已被修改',
  'personModification.valid.DYN.zwevisa': '此人的数据已被修改',
  'noPayment': '无支付',
  'selfie.desc': '文件申请需要照片，请确保在良好的光线下，保持手机稳定，避免照片模糊。同时，确保照片中您的面部细节清晰可见。',
  'selfie.take': '拍照',
  'ZWEVISA.title': '签证',
  'EMBASSYPASS.title': '大使馆护照',
  'PREENROLL.title': '预注册',
  'pay.temp': '支付暂时不可用，请选择预注册',
  'OK': '请求已接受。准备注册',
  'OK_MODIFIED': '请求已接受。正在处理中。',
  'DYN.NOK': '请求被拒绝',
  'NOK_TO_MODIFY': '请求被拒绝。需要采取行动',
  'PROCESSING': '处理中',
  'form.merchantReference': '商户参考',
  'form.pan': '卡号',
  'err.MerchantReference.required': '需要商户参考',
  'err.PAN.required': '需要卡号',
  'err.ExpiryDate.required': '需要有效期',
  'WIP': '工作进行中',
  'PAYMENT_CREATED': '支付已创建',
  'PAYMENT_VALIDATED': '支付已验证',
  'menu.requests.DYN.zwevisa': '申请',
  'menu.createRequests.DYN.zwevisa': '新申请',
  'menu.requests.DYN.pass': '请求',
  'menu.createRequests.DYN.pass': '新请求',
  'no.data': '没有符合当前筛选条件的数据',
  'dropdown.requestState': '请求状态',
  'dropdown.paymentState': '支付状态',
  'one.request': '您必须至少有一个可见请求。',
  'request_state.SUBMITTED': '已提交',
  'request_state.CONFIRMED': '已确认',
  'request_state.TREATED': '已处理',
  'request_state.TRANSFERRED': '已转移',
  'request_state.CANCELLED': '已取消',
  'request_state.REFUSED': '已拒绝',
  'request_state.UNUSABLE': '不可用',
  'request_state.NULL': '未支付',
  'request_state.NONE': '-- 无筛选 --',
  'getPayment.error': '检索支付时出错。',
  'adminPaymentResult.tilte': '支付结果',
  'ask.disconnect': '您确定要断开连接吗？',
  'global.yes': '是',
  'zwevisa.type.holiday': '假日',
  'zwevisa.type.business': '商务',
  'zwevisa.type.conferencing': '会议',
  'zwevisa.type.transit': '过境',
  'zwevisa.type.student': '学习',
  'zwevisa.entry.single': '单次入境',
  'zwevisa.entry.double': '双次入境',
  'zwevisa.entry.kaza': 'Kaza通用签证',
  'applicant.gender.male': '男性',
  'applicant.gender.female': '女性',
  'applicant.gender.other': '其他',
  'marital.status.single': '单身',
  'marital.status.married': '已婚',
  'marital.status.separated': '分居',
  'marital.status.divorced': '离婚',
  'marital.status.widowed': '丧偶',
  'form.search': '搜索',
  'role.customer': '客户',
  'role.partner_registrer': '合作伙伴注册人',
  'request_state.PROPOSED': '待付款',
  'request_state.PAID': '已完成',
  'request_state.USED': '已关闭',
  'request_state.DUPLICATED': '已复制',
  'request_state.REFUNDED': '已退款',
  'request_state.EXPIRED': '已拒绝',
  'request_state.PAID_NOT_CONFIRMED': '等待审核',
  'request_state.DELAY_PAY_NOT_CONFIRMED': '稍后支付 - 未确认',
  'request_state.DELAY_PAY': '准备到达后支付',
  'payment_status.DYN.zwevisa.0': '待付款',
  'payment_status.DYN.zwevisa.1': '准备办理入境',
  'payment_status.DYN.zwevisa.6': '等待审核',
  'payment_status.DYN.zwevisa.2.9': '等待监督',
  'payment_status.DYN.zwevisa.2.10': '已监督',
  'payment_status.desc.DYN.zwevisa.2.10': '申请已由监督员审核，准备由边境检查官处理。',
  'payment_status.desc.DYN.zwevisa.2.9': '申请正在等待监督。监督员可能需要在边境检查官处理前审核申请。',
  'payment_status.DYN.zwevisa.7': '等待更正',
  'payment_status.DYN.zwevisa.8': '稍后支付 - 未确认',
  'payment_status.DYN.zwevisa.9': '准备到达后支付',
  'payment_status.DYN.zwevisa.9.entry': '准备办理入境',
  'payment_status.DYN.zwevisa.9.exit': '准备办理出境',
  'payment_status.DYN.zwevisa.2': '已出境',
  'payment_status.DYN.zwevisa.3': '已复制',
  'payment_status.DYN.zwevisa.4': '已退款',
  'payment_status.DYN.zwevisa.5': '已拒绝',
  'payment_status.extension.DYN.zwevisa.5': '延期被拒绝',
  'payment_status.DYN.zwevisa.5.blacklisted': '已拒绝 - 被列入黑名单',
  'payment_status.DYN.pass.0': '已提议',
  'payment_status.DYN.pass.1': '已付款',
  'payment_status.DYN.pass.6': '未确认',
  'payment_status.DYN.pass.2': '已使用',
  'payment_status.DYN.pass.3': '已复制',
  'payment_status.DYN.pass.4': '已退款',
  'payment_status.DYN.pass.5': '已过期',
  'treatmentDone': '处理完成。请求修订',
  'sendRevision': '已发送修订',
  'enrolReady': '申请已接受。准备注册',
  'err.personalNr.required': '需要个人编号',
  'err.passportNr.required': '需要护照号码',
  'createPeople.success.DYN.pass': '人员已成功创建',
  'createPeople.success.DYN.zwevisa': '人员已成功创建',
  'request.0': '申请已接受。准备注册',
  'request.1': '申请已接受。正在处理中',
  'request.2': '申请被拒绝',
  'request.3': '申请被拒绝。需要采取行动',
  'request.4': '正在处理中',
  'adminRequestResult.title.DYN.pass': '已选请求 - {paymentID}',
  'adminRequestResult.title.DYN.zwevisa': '已选申请 - {paymentID}',
  'form.requestState': '请求状态',
  'form.paymentType': '支付类型',
  'form.paymentState': '支付状态',
  'payment.type.PHYSICAL': '实物',
  'payment.type.PAYPAL': 'PayPal',
  'payment.type.WU': '西联汇款',
  'payment.type.MNTA': 'CBZ',
  'payment.type.online': '在线',
  'payment.type.offline': '离线',
  'request.state.DYN.pass': '请求状态',
  'request.state.DYN.zwevisa': '申请状态',
  'payment.continue': '继续支付',
  'form.birthDate': '出生日期',
  'form.birthCountry': '出生国家',
  'form.placeOfBirth': '出生城市',
  'form.residenceAdress': '居住地址',
  'form.marialStatus': '婚姻状况',
  'form.dateOfIssue': '护照签发日期',
  'form.dateOfExpiry': '护照到期日期',
  'form.placeOfIssue': '签发地点',
  'form.departureDate': '出发日期',
  'form.arrivalDate': '到达日期',
  'form.referencePreviousVisa': '先前签证参考',
  'form.passportNumber': '护照号码',
  'form.passportType': '护照类型',
  'form.hostName': '主办方名称',
  'form.purposeOfVisit': '访问目的',
  'form.previousConvictionsHome': '本国的前科记录',
  'form.spouse': '配偶',
  'form.SpousePlaceOfBirth': '配偶的出生地',
  'form.spouse.birthDay': '配偶的出生日期',
  'form.spousePassportNumber': '配偶的护照号码',
  'form.spousePlaceOfBirth': '配偶的出生地',
  'form.dependants': '受扶养者',
  'form.dependantFullName': '受扶养者的全名',
  'form.dependantPassportNumber': '受扶养者的护照号码',
  'form.dependantPlaceOfBirth': '受扶养者的出生地',
  'form.dependantBirthDay': '受扶养者的出生日期',
  'global.approve': '批准',
  'global.refuse': '拒绝',
  'global.suspend': '请求修订',
  'global.unprocess': '取消处理',
  'personal.data': '个人数据',
  'contact.data': '联系数据',
  'divers.data.DYN.zwevisa': '旅行详情',
  'divers.data.DYN.pass': '请求详情',
  'dependant.data': '受扶养者数据',
  'spouse.data': '配偶数据',
  'paymentState.0': '待付款',
  'paymentState.1': '办理入境/出境',
  'paymentState.2': '已关闭',
  'paymentState.3': '已复制',
  'paymentState.4': '已退款',
  'paymentState.5': '已拒绝',
  'paymentState.6': '已付款 - 未确认',
  'payment.refuse.ask.DYN.pass': '您确定要<b>拒绝</b>此支付吗？',
  'payment.refuse.ask.DYN.zwevisa': '您确定要<b>拒绝</b>此签证申请吗？',
  'payment.refuse.ask.extension.DYN.zwevisa': '您确定要<b>拒绝</b>此延期请求吗？',
  'payment.approve.ask.DYN.pass': '您确定要<b>批准</b>此支付吗？',
  'payment.approve.ask.DYN.zwevisa': '您确定要<b>批准</b>此签证申请吗？',
  'payment.approve.ask.extension.DYN.zwevisa': '您确定要<b>批准</b>此延期请求吗？',
  'payment.review.approve.ask.DYN.zwevisa': '您确定要将此签证申请审核为<b>推荐</b>吗？',
  'payment.review.approve.success.DYN.zwevisa': '此签证申请已被审核为<b>推荐</b>。',
  'payment.review.approve.success.extension.DYN.zwevisa': '此延期请求已被审核为<b>推荐</b>。',
  'payment.review.refuse.ask.DYN.zwevisa': '您确定要将此签证申请审核为<b>不推荐</b>吗？',
  'payment.review.refuse.ask.extension.DYN.zwevisa': '您确定要将此延期请求审核为<b>不推荐</b>吗？',
  'payment.review.refuse.success.DYN.zwevisa': '此签证申请已被审核为<b>不推荐</b>。',
  'payment.review.refuse.success.extension.DYN.zwevisa': '此延期请求已被审核为<b>不推荐</b>。',
  'payment.review.approve.extension.ask.DYN.zwevisa': '您确定要<b>批准</b>此延期请求吗？',
  'payment.validate.ask.DYN.pass': '您确定要<b>批准</b>此支付吗？',
  'payment.validate.ask.DYN.zwevisa': '您确定要<b>批准</b>此签证申请吗？',
  'payment.validate.extension.ask.DYN.zwevisa': '您确定要<b>批准</b>此延期请求吗？',
  'payment.validate.review.ask.DYN.pass': '您确定要<b>批准</b>此支付吗？修订请求将发送给下一个审核员',
  'payment.validate.review.ask.DYN.zwevisa': '您确定要<b>推荐</b>此签证申请吗？修订请求将发送给下一个审核员',
  'payment.refuse.success.DYN.pass': '支付已成功<b>拒绝</b>。',
  'payment.refuse.success.DYN.zwevisa': '签证申请已成功<b>拒绝</b>。',
  'payment.refuse.success.extension.DYN.zwevisa': '延期请求已成功<b>拒绝</b>。',
  'payment.approve.success.DYN.pass': '支付已成功<b>批准</b>。',
  'payment.approve.success.DYN.zwevisa': '签证申请已成功<b>批准</b>。客户将通过电子邮件收到通知。',
  'payment.approve.success.extension.DYN.zwevisa': '延期请求已成功<b>批准</b>。',
  'payment.suspend.success.DYN.pass': '支付已成功<b>暂停</b>。修订请求已发送给客户。',
  'payment.suspend.success.DYN.zwevisa': '签证申请已成功<b>暂停</b>。修订请求已发送给客户。',
  'payment.validate.success.DYN.pass': '支付已成功<b>批准</b>。',
  'payment.validate.success.DYN.zwevisa': '签证申请已成功<b>批准</b>。',
  'error.payment.refuse.error.DYN.pass': '拒绝支付时发生错误。',
  'error.payment.refuse.error.DYN.zwevisa': '拒绝签证申请时发生错误。',
  'error.payment.suspend.error.DYN.pass': '暂停支付时发生错误。',
  'error.payment.suspend.error.DYN.zwevisa': '暂停签证申请时发生错误。',
  'error.payment.validate.error.DYN.pass': '批准支付时发生错误。',
  'error.payment.validate.error.DYN.zwevisa': '批准签证申请时发生错误。',
  'global.no': '否',
  'global.Yes': '是',
  'global.No': '否',
  'zwevisa.type.holiday_visa': '假日签证',
  'photo.entryContry': '入境国照片',
  'documents': '文件',
  'photo.passport': '护照照片',
  'data.biographic': '护照生物页面',
  'residence.proof': '居住证明',
  'address.destination': '目的地地址',
  'previous.visa': '先前签证',
  'invite.letter': '邀请函',
  'residential.status': '居住状态',
  'biometric.data': '生物特征数据',
  'hostInvitation.letter': '主办方的邀请函',
  'proof.residenceHost': '主办方的居住证明',
  'hostResidential.status': '主办方的居住状态',
  'reasonOfVisit.proof': '访问目的的证明',
  'inviteBusiness.letter': '发件公司的商务函',
  'business.letter': '商务函',
  'business.profile': '旅行者的职业档案',
  'application.letter': '签证申请函',
  'acceptanceFromScool.letter': '确认函',
  'date.creation': '创建日期',
  'document.Passport': '护照',
  'completeName': '全名',
  'price': '价格',
  'global.retry': '重试',
  'err.loginWeak': '连接问题，请重试',
  'URGENTPASS.title': '紧急护照',
  'refusalReason': '拒绝原因',
  'error.payment.noRefuseReason': '请输入消息。',
  'error.payment.noSuspendReason': '请输入暂停原因。此原因将发送给客户。',
  'refusal.desc.DYN.pass': '此支付已被拒绝。',
  'refusal.desc.DYN.zwevisa': '此签证申请已被拒绝。',
  'refusal.desc.extension.DYN.zwevisa': '此延期请求已被拒绝。',
  'refusal.desc.DYN.zwevisa.blacklisted': '此签证申请已被拒绝并列入黑名单。',
  'approval.desc.DYN.pass': '此支付已被批准。',
  'approval.desc.DYN.zwevisa': '此签证申请已被批准。',
  'approval.desc.extension.DYN.zwevisa': '此延期请求已被批准。',
  'review.approval.desc.DYN.zwevisa': '此签证申请符合要求。',
  'review.approval.desc.extension.DYN.zwevisa': '此延期请求符合要求。',
  'review.refusal.desc.DYN.zwevisa': '此签证申请不符合要求。',
  'review.refusal.desc.extension.DYN.zwevisa': '此延期请求不符合要求。',
  'err.getUsers: client.extended.notActiveToken': '您的会话已过期。请重新登录。',
  'forgetPassword.success': '您的密码已成功重置。您现在可以登录了。',
  'VISA_DOUBLE.title': '双次入境签证',
  'VISA_KAZA.title': 'Kaza签证',
  'history': '历史记录',
  'noPaymentFilter': '没有进行中的请求',
  'menu.request': '请求',
  'menu.tracking': '追踪',
  'menu.people.DYN.pass': '人员',
  'menu.people.DYN.zwevisa': '已保存的人员',
  'DYN.AGO': '安哥拉',
  'DYN.ABW': '阿鲁巴',
  'DYN.ATG': '安提瓜和巴布达',
  'DYN.BHS': '巴哈马',
  'DYN.BRB': '巴巴多斯',
  'DYN.BLZ': '伯利兹',
  'DYN.BWA': '博茨瓦纳',
  'DYN.CYM': '开曼群岛',
  'DYN.CYP': '塞浦路斯',
  'DYN.FJI': '斐济',
  'DYN.COD': '刚果民主共和国',
  'DYN.GHA': '加纳',
  'DYN.GRD': '格林纳达',
  'DYN.HKG': '中国香港特别行政区',
  'DYN.KEN': '肯尼亚',
  'DYN.KIR': '基里巴斯',
  'DYN.JAM': '牙买加',
  'DYN.LDW': '背风群岛',
  'DYN.LSO': '莱索托',
  'DYN.MDG': '马达加斯加',
  'DYN.MWI': '马拉维',
  'DYN.MYS': '马来西亚',
  'DYN.MDV': '马尔代夫',
  'DYN.MLT': '马耳他',
  'DYN.MUS': '毛里求斯',
  'DYN.MSR': '蒙特塞拉特',
  'DYN.MOZ': '莫桑比克',
  'DYN.NAM': '纳米比亚',
  'DYN.NRU': '瑙鲁',
  'DYN.LCA': '圣卢西亚',
  'DYN.VCT': '圣文森特和格林纳丁斯',
  'DYN.WSM': '西萨摩亚',
  'DYN.SYC': '塞舌尔',
  'DYN.SGP': '新加坡',
  'DYN.SLB': '所罗门群岛',
  'DYN.ZAF': '南非',
  'DYN.KNA': '圣基茨和尼维斯',
  'DYN.SWZ': '斯威士兰',
  'DYN.TON': '汤加',
  'DYN.TTO': '特立尼达和多巴哥',
  'DYN.TCA': '特克斯和凯科斯群岛',
  'DYN.TUV': '图瓦卢',
  'DYN.UGA': '乌干达',
  'DYN.TZA': '坦桑尼亚联合共和国',
  'DYN.VUT': '瓦努阿图',
  'DYN.VAT': '梵蒂冈',
  'DYN.VEN': '委内瑞拉（玻利瓦尔共和国）',
  'DYN.USA': '美利坚合众国',
  'DYN.URY': '乌拉圭',
  'DYN.UZB': '乌兹别克斯坦',
  'DYN.UKR': '乌克兰',
  'DYN.ARE': '阿拉伯联合酋长国',
  'DYN.GBR': '大不列颠及北爱尔兰联合王国',
  'DYN.TUR': '土耳其',
  'DYN.TKM': '土库曼斯坦',
  'DYN.SWE': '瑞典',
  'DYN.CHE': '瑞士',
  'DYN.TJK': '塔吉克斯坦',
  'DYN.KAZ': '哈萨克斯坦',
  'DYN.SUR': '苏里南',
  'DYN.ESP': '西班牙',
  'DYN.SVK': '斯洛伐克',
  'DYN.SVN': '斯洛文尼亚',
  'DYN.SEN': '塞内加尔',
  'DYN.SRB': '塞尔维亚',
  'DYN.SMR': '圣马力诺',
  'DYN.STP': '圣多美和普林西比',
  'DYN.NLD': '荷兰',
  'DYN.NCL': '新喀里多尼亚',
  'DYN.NZL': '新西兰',
  'DYN.NIC': '尼加拉瓜',
  'DYN.MEX': '墨西哥',
  'DYN.FSM': '密克罗尼西亚联邦',
  'DYN.MDA': '摩尔多瓦',
  'DYN.MCO': '摩纳哥',
  'DYN.MHL': '马绍尔群岛',
  'DYN.LVA': '拉脱维亚',
  'DYN.NFK': '诺福克岛',
  'DYN.MNP': '北马里亚纳群岛',
  'DYN.NOR': '挪威',
  'DYN.PLW': '帕劳',
  'DYN.PSE': '巴勒斯坦',
  'DYN.PAN': '巴拿马',
  'DYN.PNG': '巴布亚新几内亚',
  'DYN.PRY': '巴拉圭',
  'DYN.PER': '秘鲁',
  'DYN.POL': '波兰',
  'DYN.PRT': '葡萄牙',
  'DYN.PRI': '波多黎各',
  'DYN.REU': '留尼汪',
  'DYN.ROU': '罗马尼亚',
  'DYN.RUS': '俄罗斯联邦',
  'DYN.RWA': '卢旺达',
  'DYN.LIE': '列支敦士登',
  'DYN.LTU': '立陶宛',
  'DYN.LUX': '卢森堡',
  'DYN.MAC': '中国澳门特别行政区',
  'DYN.JPN': '日本',
  'DYN.KWT': '科威特',
  'DYN.KGZ': '吉尔吉斯斯坦',
  'DYN.KOR': '韩国',
  'DYN.GUY': '圭亚那',
  'DYN.HTI': '海地',
  'DYN.HND': '洪都拉斯',
  'DYN.IRL': '爱尔兰',
  'DYN.ISR': '以色列',
  'DYN.ITA': '意大利',
  'DYN.HUN': '匈牙利',
  'DYN.ISL': '冰岛',
  'DYN.IND': '印度',
  'DYN.IDN': '印度尼西亚',
  'DYN.IRN': '伊朗',
  'DYN.GUM': '关岛',
  'DYN.GTM': '危地马拉',
  'DYN.GIB': '直布罗陀',
  'DYN.GRC': '希腊',
  'DYN.GEO': '格鲁吉亚',
  'DYN.DEU': '德国',
  'DYN.DNK': '丹麦',
  'DYN.EST': '爱沙尼亚',
  'DYN.ETH': '埃塞俄比亚',
  'DYN.FIN': '芬兰',
  'DYN.FRA': '法国',
  'DYN.GUF': '法属圭亚那',
  'DYN.PYF': '法属波利尼西亚',
  'DYN.ATF': '法属南部领土',
  'DYN.CZE': '捷克共和国',
  'DYN.COM': '科摩罗',
  'DYN.DOM': '多米尼加共和国',
  'DYN.ECU': '厄瓜多尔',
  'DYN.EGY': '埃及',
  'DYN.SLV': '萨尔瓦多',
  'DYN.GNQ': '赤道几内亚',
  'DYN.CHL': '智利',
  'DYN.CHN': '中国',
  'DYN.CHN_NT': '中国（非旅游）',
  'DYN.COK': '库克群岛',
  'DYN.CRI': '哥斯达黎加',
  'DYN.HRV': '克罗地亚',
  'DYN.CUB': '古巴',
  'DYN.BDI': '布隆迪',
  'DYN.CAN': '加拿大',
  'DYN.CPV': '佛得角',
  'DYN.BRA': '巴西',
  'DYN.VGB': '英属维尔京群岛',
  'DYN.BRN': '文莱达鲁萨兰国',
  'DYN.BGR': '保加利亚',
  'DYN.BMU': '百慕大',
  'DYN.BTN': '不丹',
  'DYN.BIH': '波斯尼亚和黑塞哥维那',
  'DYN.BLR': '白俄罗斯',
  'DYN.BEL': '比利时',
  'DYN.BHR': '巴林',
  'DYN.ARG': '阿根廷',
  'DYN.ARM': '亚美尼亚',
  'DYN.AUS': '澳大利亚',
  'DYN.AUT': '奥地利',
  'DYN.AZE': '阿塞拜疆',
  'DYN.ALB': '阿尔巴尼亚',
  'DYN.DZA': '阿尔及利亚',
  'DYN.AIA': '安圭拉',
  'DYN.BGD': '孟加拉国',
  'DYN.BEN': '贝宁',
  'DYN.AFG': '阿富汗',
  'DYN.BOL': '玻利维亚',
  'DYN.BFA': '布基纳法索',
  'DYN.KHM': '柬埔寨',
  'DYN.CMR': '喀麦隆',
  'DYN.CIV': '科特迪瓦',
  'DYN.CAF': '中非共和国',
  'DYN.TCD': '乍得',
  'DYN.COL': '哥伦比亚',
  'DYN.ERI': '厄立特里亚',
  'DYN.COG': '刚果布拉柴维尔',
  'DYN.PRK': '朝鲜民主主义人民共和国',
  'DYN.GAB': '加蓬',
  'DYN.GMB': '冈比亚',
  'DYN.DJI': '吉布提',
  'DYN.GIN': '几内亚科纳克里',
  'DYN.GNB': '几内亚比绍',
  'DYN.IRQ': '伊拉克',
  'DYN.KOS': '科索沃',
  'DYN.LAO': '老挝人民民主共和国',
  'DYN.JOR': '约旦',
  'DYN.LBR': '利比里亚',
  'DYN.LBY': '利比亚',
  'DYN.MLI': '马里',
  'DYN.QAT': '卡塔尔',
  'DYN.REF': '难民',
  'DYN.PHL': '菲律宾',
  'DYN.OMN': '阿曼',
  'DYN.PAK': '巴基斯坦',
  'DYN.LBN': '黎巴嫩',
  'DYN.MRT': '毛里塔尼亚',
  'DYN.MNG': '蒙古',
  'DYN.MNE': '黑山',
  'DYN.NER': '尼日尔',
  'DYN.NGA': '尼日利亚',
  'DYN.MMR': '缅甸',
  'DYN.MAR': '摩洛哥',
  'DYN.NPL': '尼泊尔',
  'DYN.SAU': '沙特阿拉伯',
  'DYN.SLE': '塞拉利昂',
  'DYN.SOM': '索马里',
  'DYN.LKA': '斯里兰卡',
  'DYN.SSD': '南苏丹',
  'DYN.SDN': '苏丹',
  'DYN.THA': '泰国',
  'DYN.MKD': '前南斯拉夫马其顿共和国',
  'DYN.TGO': '多哥',
  'DYN.SYR': '叙利亚阿拉伯共和国',
  'DYN.TWN': '台湾',
  'DYN.TUN': '突尼斯',
  'DYN.VNM': '越南',
  'DYN.YEM': '也门',
  'DYN.TLS': '东帝汶',
  'DYN.AND': '安道尔',
  'DYN.VIR': '美属维尔京群岛',
  'DYN.XKX': '科索沃',
  'DYN.ZWE': '津巴布韦',
  'DYN.ZMB': '赞比亚',
  'DYN.ASM': '美属萨摩亚',
  'DYN.DMA': '多米尼克',
  'DYN.XXA': '无国籍',
  'DYN.XXB': '难民（日内瓦公约）',
  'DYN.XXC': '难民（其他）',
  'DYN.UNA': '联合国机构',
  'DYN.UNO': '联合国组织',
  'err.Occupation.pattern': '职业必须具有正确格式',
  'err.PreviousConvictionsHome.pattern': '本国的前科记录必须具有正确格式',
  'err.PlaceOfBirth.pattern': '出生地点的格式必须正确',
  'PAYMENT_USED': '支付已完成',
  'document.type.DYN.pass': '文件类型',
  'document.type.DYN.zwevisa': '申请类型',
  'filterPayment.error': '筛选请求时发生错误。您想要重试吗？',
  'filterPaymentNoFirst.error': '检索请求时发生错误。您想要重试吗？',
  'dependantIndex': '依赖人 {index}',
  'form.spouseFullName': '配偶的全名',
  'qrDesc.valid': '在进入或离开国家时，请在签证检查点出示此二维码。没有有效的二维码，您不能旅行。',
  'qrDesc.invalid': '此签证申请当前无效。没有有效的签证申请，您不能旅行。请联系签证办公室了解更多信息。',
  'err.pay': '支付过程中发生错误。请重试。',
  'payment.noEvents': '此申请当前不包括任何与旅行相关的处理（申请的使用情况）。',
  'global.cancel': '取消',
  'end.suspend': '完成修改',
  'visa_application': '签证申请要求',
  'trips.title': '跟踪申请',
  'trips.desc': '申请',
  'BatchId': '申请参考编号',
  'newBatchId': '添加',
  'err.BatchId.required': '申请参考编号是必需的',
  'newBatchId.desc': '请输入此次旅行的名称。',
  'ask.newBatchId': '请创建一个新的申请参考编号。',
  'personLinkType.DYN.0': '未知',
  'personLinkType.DYN.1': '子女',
  'personLinkType.DYN.2': '父母',
  'personLinkType.DYN.3': '配偶',
  'personLinkType.DYN.4': '其他',
  'personLinkType.DYN.5': '本人',
  'createPeople.error.DYN.pass': '创建人员时发生错误。',
  'createPeople.error.DYN.zwevisa': '创建人员时发生错误。',
  'batchIds.desc': '与参考编号 {trip} 相关的申请：',
  'trip.name': '申请参考编号',
  'visaIntro.validFrom': '开始日期',
  'visaIntro.validUntil': '到期日期',
  'visaIntro.usageCount': '允许使用次数',
  'client.extended.passportNumberAlreadyExists': '此护照号码已被使用。请输入另一个号码。',
  'client.extended.alreadyDecided': '您已审查过此签证申请。您无法再次审查。',
  'client.extended.outOfUsage': '此申请已超过有效期',
  'global.review': '审查',
  'ask.review.0': '选择您对该签证申请的评估。',
  'ask.review.extension.0': '选择您对该延期请求的评估。',
  'ask.review.1': '选择您对该签证申请的推荐意见。',
  'ask.review.2': '选择您对该签证申请的最终决定。',
  'error.review.noChoice': '请选择一个审查状态以继续。',
  'all.reviews': '该签证申请的所有审查',
  'all.reviews.extension': '该延期请求的所有审查',
  'validate.desc': '此签证申请已于 {date} 被用户审查并批准',
  'validate.extension.desc': '此延期请求已于 {date} 被用户审查并批准',
  'refusal.review.desc': '此签证申请已于 {date} 被用户审查并拒绝',
  'refusal.review.extension.desc': '此延期请求已于 {date} 被用户审查并拒绝',
  'refusal.review.desc.blacklisted': '此签证申请已于 {date} 被用户审查并拒绝且列入黑名单',
  'global.revision': '修改',
  'revison.cancel': '取消修改',
  'approve.text': '此申请已被批准。',
  'refuse.text': '此签证申请已被拒绝。',
  'pending.review.desc': '此签证申请的修改请求已于 {date} 被用户提交给申请人',
  'global.reason': '原因',
  'pending.desc': '此签证申请当前等待客户的修改。',
  'success.onArrival': '您的请求已成功提交。您可以在顶部查看此申请的二维码。进入或离开国家时，请在签证检查点出示此二维码。没有有效的签证申请，您不能旅行。',
  'successRequest.title': '请求已成功提交',
  'successRequest.title.kiosk': '申请已成功提交',
  'amountDue': '价格：',
  'amountDue.total.now': '当前总应付金额：',
  'amountDue.total.later': '在官员处应付总金额：',
  'noPrint.mobile': '移动设备不支持打印页面',
  'err.PassportNumber.required': '需要文件号码（护照、居留许可）',
  'err.FundsAvailable.pattern': '入境时可用资金必须符合正确格式',
  'onArrival.reset': '开始新流程',
  'onArrival.reset.ask': '您真的想开始一个新流程吗？',
  'stillHere': '您还在吗？该流程将在20秒后重置。',
  'stillHere.successPayment': '您还在吗？',
  'print.request': '打印申请',
  'EXPIRED.globalExplain': '此请求已被拒绝。不符合我们的标准。',
  'payment_status.desc.DYN.7': '此签证申请已被审查为挂起状态，当前等待客户的修改。',
  'payment_status.DYN.zwevisa.6.review': '等待审查 - {reviewCount}/2',
  'payment_status.desc.DYN.zwevisa.6.review': '此签证申请当前等待审查，已审查 {reviewCount} 次。',
  'payment_status.DYN.zwevisa.6.finalReview': '等待最终审查',
  'payment_status.desc.DYN.zwevisa.6.finalReview': '此签证申请已被审查，当前等待最终审查。',
  'payment_status.desc.DYN.zwevisa.0': '此申请当前等待支付机构的支付验证。如果尚未支付，可以现在完成。',
  'payment_status.desc.DYN.zwevisa.7': '此签证申请当前等待客户的修改。',
  'payment_status.desc.DYN.zwevisa.5': '此签证申请已被拒绝。',
  'payment_status.desc.extension.DYN.zwevisa.5': '此延期请求已被拒绝。',
  'payment_status.desc.DYN.zwevisa.5.blacklisted': '此签证申请已被拒绝且该人被列入黑名单。',
  'payment_status.desc.DYN.zwevisa.5.checkIn': '此申请在官员处的签到时被拒绝。',
  'payment_status.desc.DYN.zwevisa.5.checkIn.reason': '此申请在官员处的签到时被拒绝。原因:  {reason}',
  'payment_status.DYN.zwevisa.5.checkIn': '签到时被拒绝',
  'payment_status.desc.DYN.zwevisa.5.checkOut': '此申请在官员处的签退时被拒绝。',
  'payment_status.DYN.zwevisa.5.checkOut': '签退时被拒绝',
  'payment_status.desc.DYN.zwevisa.9': '此签证申请已准备好在官员处支付。支付后可完成签到。',
  'payment_status.desc.DYN.zwevisa.9.entry': '此申请已准备好在官员处签到。',
  'payment_status.desc.DYN.zwevisa.9.exit': '此申请已准备好签退，并需支付官员处签发的边境通行证。',
  'payment_status.DYN.zwevisa.2.out': '准备签退',
  'payment_status.DYN.zwevisa.2.outFinish': '签退完成',
  'payment_status.DYN.zwevisa.2.closed': '已关闭',
  'payment_status.desc.DYN.zwevisa.2.closed': '此申请已关闭。',
  'payment_status.desc.DYN.zwevisa.2.zweexit': '此申请已准备好在官员处签退。',
  'payment_status.DYN.zwevisa.2.entryFinish': '签到完成',
  'payment_status.DYN.zwevisa.2.in': '准备签到',
  'payment_status.desc.DYN.zwevisa.2.out': '此申请已签到。下一步是在官员处签退。',
  'payment_status.desc.DYN.zwevisa.2.outFinish': '此申请已签退。客户现在可以旅行。',
  'payment_status.desc.DYN.zwevisa.2.entryFinish': '此申请已签到。客户现在可以进入该国。',
  'payment_status.desc.DYN.zwevisa.2.in': '此申请等待在官员处签到。',
  'ask.isArrivalPaid': '以下支付必须立即以任何以下货币支付：',
  'ask.isArrivalPaid.exit': '边境通行证必须立即以任何以下货币支付：',
  'ask.isArrivalPaid.confirm': '您确认已完成支付吗？如果确认，请输入支付参考、支付方式和使用的货币。您还可以更改最长停留日期（只能缩短日期）。',
  'ask.isArrivalPaid.confirm.exit': '您确认已完成支付吗？如果确认，请输入支付参考和使用的货币。',
  'ask.checkoutExpired.confirm': '您确认已完成支付吗？如果确认，请输入支付参考和使用的货币。',
  'no_trips': '您没有任何旅行。',
  'err.canActivate': '发生错误',
  'form.passportNumber.dateOfIssue': '护照签发日期',
  'form.passportNumber.dateOfExpiry': '护照到期日期',
  'form.passportNumber.placeOfIssue': '护照签发地',
  'nextOfKin.data': '紧急联系人 / 近亲',
  'form.otherLinkType': '指定关系类型',
  'err.passportNr.pattern': '护照号码必须符合正确格式',
  'err.otherLinkType.required': '您必须指定关系类型',
  'err.otherLinkType.pattern': '关系类型必须符合正确格式',
  'camera.problem.manual': '您即将未经客户照片检查出此签证申请。您确定要继续吗？',
  'camera.problem.button': '摄像头有问题吗？',
  'global.noFilter': '–无筛选–',
  'zweentry': '无需签证的入境',
  'zweentry.title': '无需签证的入境',
  'zweentry.description': '来自选定国家的旅客可以在不事先获得签证的情况下入境，通常是短期停留。',
  'free.charge': '免费',
  'camera.init.error': '初始化摄像头时发生错误。您想在没有客户照片的情况下签到/签退此申请吗？',
  'error.payment.noPaymentReference': '请输入支付参考、支付方式和使用的货币。',
  'paymentReference.ask': '支付参考',
  'paymentReference.askExpired': '超期费用的支付参考',
  'session.expired': '您的会话已过期。请重新登录。',
  'error.approveRefuse.DYN.zwevisa': '批准或拒绝签证申请时发生错误。',
  'error.approveRefuse.DYN.pass': '批准或拒绝支付时发生错误。',
  'phone.pattern': '(例如：+263771234567)',
  'global.receipt.id': '申请 ID：{paymentId}',
  'global.preview': '预览',
  'ask.place.updatePermissions': '您确定要更新此地点的权限吗？',
  'place.updatePermissions.success': '此地点的权限已成功更新。',
  'err.place.updatePermissions': '更新地点权限时发生错误。',
  'err.seniority.required': '资历必须是1到3之间的数字。',
  'err.seniority.pattern': '资历必须是1到3之间的数字。',
  'global.urgent': '紧急',
  'form.usageAfter': '有效期从',
  'form.usageUntil': '有效期至',
  'form.localUsageUntil': '最长停留日期',
  'id.payment': '支付ID',
  'internalReference': '总部参考',
  'payment_status.DYN.zwevisa.6.0': '等待审查 0/2',
  'payment_status.DYN.zwevisa.6.1': '等待审查 1/2',
  'payment_status.DYN.zwevisa.6.2': '等待最终审查',
  'payment_status.DYN.zwevisa.2.0': '签到完成',
  'payment_status.DYN.zwevisa.2.4': '准备签退',
  'payment_status.DYN.zwevisa.2.5': '签退完成',
  'payment_status.DYN.zwevisa.2.6': '签到时被拒绝',
  'payment_status.DYN.zwevisa.2.7': '签退时被拒绝',
  'payment_status.DYN.zwevisa.2.8': '已关闭',
  'internalReference.create': '添加总部参考',
  'internalReference.edit': '编辑总部参考',
  'internalReference.ask.edit': '请输入新的总部参考。',
  'internalReference.ask.create': '请输入总部参考。',
  'internalReference.done.create': '总部参考已成功添加。',
  'internalReference.done.edit': '总部参考已成功编辑。',
  'error.internalReference': '添加/编辑总部参考时发生错误。',
  'error.paymentUntil.expired': '此申请已超出有效期。您可以继续为客户办理签退手续，但请注意，由于签证申请的过期状态，需支付100美元的费用。支付必须立即以任何以下货币进行：',
  'internalReference.desc': '为此申请添加总部参考（例如：140_2024）。',
  'visaApplication.id': '签证申请ID',
  'titlePrint': '签证申请  {paymentID}',
  'titlePrint.extension': '延期请求  {paymentID}',
  'visa.data': '签证数据',
  'validated': '有效',
  'globalStatus.awaitingConfirmation': '等待确认',
  'globalStatus.awaitingVerification': '等待修改',
  'globalStatus.refused': '被拒绝',
  'globalStatus.valid': '有效',
  'globalStatus.draft': '等待支付',
  'PENDING.globalExplain': '此请求等待修改。请检查信息并进行修改。',
  'PAID_NOT_CONFIRMED.globalExplain': '此请求等待我们服务的确认。',
  'PROPOSED.globalExplain': '此请求正在等待支付。如果您尚未确认支付，可以现在进行。',
  'usageValidityConditions': '移民局保留随时取消签证的权利，如果签证持有人未能遵守相关条件。',
  'afterWithoutUntil': '您被允许从 {dateAfter} 到 {deadlineDate} 之间入境。',
  'afterWithoutUntilAndMillis': '您被允许从 {dateAfter} 开始旅行。',
  'afterAndUntil': '您被允许从 {dateAfter} 到 {dateUntil} 之间旅行。',
  'photo.current': '当前照片',
  'photo.scanned': '从护照扫描的照片',
  'createdAt': '创建日期',
  'all.infos': '关于此申请的所有信息',
  'afterRefused': '声明的旅行日期：{dateAfter}。',
  'DYN.paymentError.desc.success': '您的订单已成功记录。如果此申请的任何部分需要我们团队的批准，一旦完成此过程，您将收到通知。否则，请知悉我们已收到您的请求。<p><b>感谢您选择我们的服务。</b></p>',
  'successPayment.kiosk': '<p>您的申请已成功记录。您现在可以前往官员处进行签到。</p><p><b>感谢您选择我们的服务。</b></p>',
  'sinceOneWeek': '请注意，此签证申请已处于等待审查状态超过一周。',
  'client.extended.onlyAdminDecide': '与您相同资历的工作人员已审查过此签证申请。您无法再次审查。',
  'error.paymentUntil.before': '日期太早。此申请将从 {dateAfter} 开始生效。',
  'applicant.gender.FEMALE': '女性',
  'applicant.gender.MALE': '男性',
  'entry.consume': '入境',
  'exit.consume': '出境',
  'scan.noConsumeMode': '作为边境官员，您负责管理入境和出境。请选择与您当前任务相对应的选项：处理入境或管理出境。',
  'scan.kiosk': '未找到此人处于"准备签到"状态的入境记录。请要求此人到自助服务终端创建新申请。',
  'scan.office': '未找到此人处于"准备签到"状态的入境记录。此人的国籍属于C类，这意味着此人需要签证。请将此人护送到办公室，办理紧急签证或拒绝入境。',
  'scan.noVisaFound': '未找到此人处于"准备签退"状态的出境记录。您想创建一个准备签退的记录吗？',
  'zweexit': '无需签证的出境',
  'err.scanPassport': '扫描护照时发生错误。请确保正确插入并重试。如果问题仍然存在，请重新启动应用程序或联系支持。',
  'exit.register': '记录出境',
  'entry.register': '记录入境',
  'exit.create': '创建无需签证的出境',
  'exit.ask': '您确定要为此人注册无需签证的出境吗？',
  'exit.success': '无需签证的出境已成功注册。您现在可以为此人办理签退手续。',
  'border': '边境官员',
  'global.signout': '登出',
  'search.manually': '手动搜索申请',
  'scan.applicationQr': '扫描申请二维码',
  'err.client.extended.maxUsageCountReached': '此申请的最大使用次数已达到。',
  'form.firstName.scanned': '扫描的名字',
  'form.lastName.scanned': '扫描的姓氏',
  'form.documentNumber.scanned': '扫描的护照号码',
  'form.gender.scanned': '扫描的性别',
  'form.birthDate.scanned': '扫描的出生日期',
  'form.nationality.scanned': '扫描的国籍',
  'scanned.passport': '扫描的护照',
  'application.data': '申请数据',
  'no.passportPhoto': '无照片',
  'scanned.list.checkIn': '以下是与您的护照号码相关的申请',
  'overstay': '逾期停留',
  'method.payment': '支付方式',
  'review.desc': '批准/拒绝',
  'approval.usageUntil.DYN.zwevisa': '更改到期日期（可选）',
  'scanned.list': '找到的申请列表',
  'err.EmergencyPhone.pattern': '紧急联系电话号码必须符合正确格式',
  'visa.blacklist': '此签证申请已被列入黑名单。此人不得入境。',
  'scan.noEntryFound': '未找到此人处于"准备签到"状态的入境记录。您想创建一个准备签到的记录吗？',
  'err.EmergencyEmail.pattern': '紧急联系人电子邮件必须符合正确格式',
  'global.syncing': '正在同步...',
  'payment.consume.error.DYN.zwevisa': '处理申请时发生错误。',
  'tooltip.Qr': '您可以直接扫描与申请相关的二维码，以找到该申请的详细信息。',
  'tooltip.arrivals': '选中此复选框，以边境官员身份管理入境。',
  'tooltip.departures': '选中此复选框，以边境官员身份管理出境。',
  'tooltip.searchManually': '使用多种标准搜索特定申请。',
  'tooltip.signout': '退出应用程序。',
  'tooltip.my_account': '访问您的帐户设置。',
  'tooltip.dashboard': '访问仪表板。',
  'tooltip.createRequests': '创建新申请。',
  'tooltip.trips': '查看您的申请状态，如果申请已批准，打印相关文件。',
  'tooltip.tracking': '跟踪您的申请。',
  'tooltip.people': '管理与您的帐户相关的人员。',
  'tooltip.users': '管理应用程序的用户。',
  'tooltip.requests': '管理申请请求。',
  'tooltip.places': '管理应用程序的地点。',
  'tooltip.role': '您在应用程序中的角色和姓名。',
  'tooltip.exitRegister': '为此人手动注册无需签证的出境。',
  'tooltip.entryRegister': '为此人手动注册无需签证的入境。',
  'tooltip.support': '如果遇到任何问题，请联系支持。',
  'tooltip.chooseLang': '更改应用程序语言。当前仅支持英语。',
  'tooltip.changePassword': '更改您的密码。',
  'tooltip.search': '根据姓名、护照号码、支付ID或参考编号过滤申请。',
  'tooltip.internalReference': '根据内部参考过滤申请。',
  'tooltip.internalReferenceNoFilter': '如果有内部参考，可点击该参考直接跳转到相关参考。',
  'tooltip.state': '根据状态过滤申请。',
  'tooltip.createdDate': '根据创建日期使用"从"和"到"日期过滤申请。',
  'tooltip.createdDateSince': '根据创建日期过滤申请。',
  'tooltip.overstay': '根据是否已超过在国家的停留期限筛选申请。',
  'tooltip.clearAllFilters': '清除应用于申请的所有过滤器。',
  'tooltip.filterLength': '符合应用过滤条件的申请数量。',
  'tooltip.downloadCsv': '以CSV格式下载申请。',
  'tooltip.tablePassportTitle': '根据护照号码对申请进行排序。',
  'tooltip.tablePassport': '与申请相关的护照号码',
  'tooltip.tableInternalReferenceTitle': '根据内部参考对申请进行排序。',
  'tooltip.tableInternalReference': '与申请相关的内部参考',
  'tooltip.tableBatchIdTitle': '根据申请参考对申请进行排序。',
  'tooltip.tableBatchId': '与申请相关的申请参考',
  'tooltip.tableCreationDateTitle': '根据创建日期对申请进行排序。',
  'tooltip.tableCreationDate': '申请的创建日期',
  'tooltip.tableStateTitle': '根据状态对申请进行排序。',
  'tooltip.tableState': '申请的状态',
  'tooltip.tableCompleteNameTitle': '根据完整姓名对申请进行排序。',
  'tooltip.tableCompleteName': '与申请相关的人员的完整姓名',
  'tooltip.tablePriceTitle': '根据价格对申请进行排序。',
  'tooltip.tablePrice': '申请的价格',
  'tooltip.tableUsageAfterTitle': '根据开始日期对申请进行排序。',
  'tooltip.tableUsageAfter': '申请的开始日期',
  'tooltip.tableUsageUntilTitle': '根据到期日期对申请进行排序。',
  'tooltip.tableUsageUntil': '申请的到期日期',
  'tooltip.tableIdTitle': '根据ID对申请进行排序。',
  'tooltip.tableId': '申请的ID',
  'tooltip.tablePerPage': '更改每页显示的申请数量。',
  'tooltip.tableNumberOfPage': '控制分页和每页显示的申请数量，以便更轻松地导航。',
  'tooltip.history': '您申请的支付历史。',
  'tooltip.addPerson': '添加与您的帐户相关的新人员。',
  'tooltip.listQr': '查看所有筛选后的申请及其二维码。',
  'tooltip.urgent': '这意味着该申请被标记为紧急，应该尽快处理。',
  'tooltip.operationId': '申请的类型。',
  'tooltip.purposeOfVisit': '此次申请的访问目的。',
  'tooltip.amount': '此申请的价格。',
  'tooltip.paymentType': '此签证申请的支付类型。',
  'tooltip.paymentReference': '此签证申请的支付参考。',
  'tooltip.review': '审查此签证申请并批准或拒绝并添加评论。',
  'tooltip.revision': '要求客户修改签证申请。',
  'tooltip.approve': '作为最终决定批准此签证申请。您还可以添加评论。如果存在"黑名单"一词，则该人将被列入黑名单。',
  'tooltip.refuse': '作为最终决定拒绝此签证申请。您还可以添加评论。',
  'tooltip.qrSee': '查看此申请的二维码并打印与之相关的文件。',
  'tooltip.internalEdit': '编辑此签证申请的内部参考。',
  'tooltip.internalAdd': '为此申请添加内部参考。',
  'tooltip.printApplication': '打印包含所有信息的申请。',
  'tooltip.allReviews': '所有关于此签证申请的审查，包含日期和进行审查的用户。',
  'tooltip.version': '应用程序的当前版本。',
  'tooltip.consumeProfile.IN': '入境点捕获的照片。',
  'tooltip.consumeNoProfile.IN': '入境点未捕获照片。',
  'tooltip.consumeTime.IN': '入境点的日期和时间。',
  'tooltip.consumePlace.IN': '入境点的位置。',
  'tooltip.consumeProfile.OUT': '出境点捕获的照片。',
  'tooltip.consumeNoProfile.OUT': '出境点未捕获照片。',
  'tooltip.consumeTime.OUT': '出境点的日期和时间。',
  'tooltip.consumePlace.OUT': '出境点的位置。',
  'tooltip.passportComparison': '从护照扫描的信息。允许您将信息与申请的实际信息进行比较。',
  'tooltip.classicComparison': '申请的实际信息。允许您将信息与从护照扫描的信息进行比较。',
  'tooltip.trackingValid': '申请的状态。此处，申请有效。这意味着您可以根据有效期日期旅行。',
  'tooltip.trackingPending': '申请的状态。此处，申请等待修改。这意味着您需要修改某些信息，然后申请才能获得批准。',
  'tooltip.trackingRefused': '申请的状态。此处，申请已被拒绝。这意味着您不能使用此申请旅行。',
  'tooltip.trackingAwaitingConfirmation': '申请的状态。此处，申请正在等待我们的服务确认。',
  'tooltip.trackingApplication': '与此申请参考相关的申请',
  'tooltip.trackingApplicationReference': '这些申请的申请参考',
  'tooltip.allTrackingInfo': '关于此申请的所有信息',
  'tooltip.trackingPrint': '打印与此申请相关的文件',
  'tooltip.docFirstName': '输入与护照上的姓名一致的名字。',
  'tooltip.docLastName': '输入与护照上的姓氏一致的姓氏。',
  'tooltip.docPassportNumber': '输入此人的护照号码。',
  'tooltip.docLinkType': '选择您与此人的关系类型。',
  'tooltip.docLinkTypeOther': '具体说明您与此人的关系类型。',
  'tooltip.applicationReferenceRequest': '请求的申请参考。每个请求都是唯一的，系统会自动生成。',
  'tooltip.portEntryRequest': '请求的入境口岸。即此人将进入国家的地点。',
  'tooltip.otherName': '输入申请人护照上的其他名字。',
  'tooltip.spouseCheckbox': '如果申请人有配偶，请勾选此框，并填写以下信息。',
  'tooltip.hostName': '将接待申请人的人/组织的名称。',
  'tooltip.scanPassport': '扫描申请人的护照，以查找与此护照相关的申请。如果没有找到申请，您可以创建一个新申请。',
  'tooltip.passportScanned': '从护照扫描的信息。',
  'tooltip.scannedCard': '使用扫描信息找到的类似申请。',
  'scan.error': '扫描文件时发生错误。',
  'tooltip.webcamProblems': '如果摄像头无法正常工作，您仍然可以在没有照片的情况下签到或签退申请。',
  'tooltip.checkOut': '为此人办理签退手续。',
  'tooltip.checkIn': '为此人办理签到手续。',
  'tooltip.consumeRefuse': '拒绝此人的入境或出境。',
  'tooltip.finalRefuse': '申请的最终决定。此人将不能入境。',
  'tooltip.finalApprove': '申请的最终决定。此人将能够入境或离境。',
  'tooltip.finalPending': '申请的最终决定。此人将不能入境，直到申请得到修改和批准。',
  'tooltip.finalRefuseBlacklist': '申请的最终决定。此人将不能入境，并被列入黑名单。',
  'ask.updateScannedPassport': '您是否要使用扫描信息更新申请信息？',
  'done.updateScannedPassport': '申请信息已成功更新。',
  'error.updateScannedPassport': '更新申请信息时发生错误。',
  'photo.entryCountry.IN': '签到时的照片',
  'photo.entryCountry.OUT': '签退时的照片',
  'err.noPassiveAuthenticationCSCA': '文件有效，但无法验证其签发机构。',
  'err.OtherName.pattern': '其他名字只能包含不带重音符号的字母（小写或大写）、空格或连字符，必须与护照上显示的一致。',
  'err.FirstName.pattern': '名字只能包含不带重音符号的字母（小写或大写）、空格或连字符，必须与护照上显示的一致。',
  'err.LastName.pattern': '姓氏只能包含不带重音符号的字母（小写或大写）、空格或连字符，必须与护照上显示的一致。',
  'err.PassportNumber.pattern': '护照号码只能包含不带重音符号的字母（小写或大写）、数字、空格或连字符，必须与护照上显示的一致。',
  'err.passport.expired': '护照已过期。',
  'externalIndexedData': '找到多个护照',
  'client.extended.chargedBack': '此签证申请已被扣回。个人仍然可以继续办理签退手续，但必须以现金方式重新支付入境费。',
  'ask.chargedBack.confirm': '您确认此人已重新支付入境费吗？如果确认，请输入支付参考和使用的货币。',
  'error.payment.noReferenceChargedBack': '请输入支付参考和使用的货币。',
  'client.extended.entryChargedBack': '此签证申请已被扣回。此人不能继续办理签到，必须创建一个新的入境申请。',
  'tooltip.syncing': '当前正在同步的申请流程数量。',
  'adChoice': '与活动目录的连接',
  'form.username': '用户名',
  'err.username.required': '用户名是必需的',
  'err.username.minlength': '用户名必须至少包含4个字符',
  'external.down': '我们应用程序依赖的服务当前不可用。请稍后再试。',
  'global.connection.server': '服务不可用。很抱歉给您带来不便，请稍后重试。',
  'global.connection.timeout': '上传您的请求超时。请检查您的互联网连接并重试。',
  'global.connection.problem': '上传您的请求时出错。请检查您是否未使用VPN、防火墙，确保您的互联网连接稳定，并重试。',
  'err.passport.issuedSoon': '护照最近签发（不到一个月）。',
  'global.reload': '重新加载',
  'error.passportScan': '证书字节（b64）：<pre>{certBytes}</pre>',
  'glpi.err.noPassiveAuthenticationCSCA': '护照的证书哈希无法在ICAO主列表中找到，导致"err.noPassiveAuthenticationCSCA"错误。',
  'glpi.err.unverifiedDoc': '无法检索被动身份验证的证书字节，导致"err.unverifiedDoc"错误。',
  'glpi.err.noPassiveAuthentication': '无法执行被动身份验证，导致"err.noPassiveAuthentication"错误。',
  'glpi.err.noConnect': '无法与护照芯片建立连接，导致"err.noConnect"错误。',
  'glpi.err.noBAC': '使用提供的MRZ信息无法建立BAC连接，触发"err.noBAC"错误。',
  'glpi.err.alteredDoc': '一个或多个条目的数据组哈希（DGS）不正确，导致"err.alteredDoc"错误。',
  'glpi.err.revokedDocSigner': '文件签发者的证书序列号出现在证书吊销列表（CRL）上，导致"err.revokedDocSigner"错误。',
  'watchlistCollapse': '<p>找到的观察名单 - <span class="watchlistCategories">{categories}</span></p>',
  'watchlistResponse.level': '此对应的警报级别',
  'match.type': '匹配类型',
  'match.hair': '头发',
  'match.eyes': '眼睛',
  'match.height': '身高',
  'match.weight': '体重',
  'match.race': '种族',
  'global.category': '类别',
  'watchlist.alertLevel': '观察名单的警报级别',
  'watchlist.match': '匹配 {numberMatch} | <span class="watchlistCategories">{matchCategory}</span>',
  'Nationalities': '国籍 {index}',
  'global.score': '评分',
  'watchlist.name': '观察名单的名称',
  'alias': '别名 {index}',
  'document.number': '文件号码 {index}',
  'document.issuer': '文件签发者 {index}',
  'document.type': '文件类型 {index}',
  'dateOfBirth': '出生日期 {index}',
  'watchlist.marks': '标记',
  'watchlist.image.link': '找到的图像链接 {index}',
  'image.match': '图像匹配 {index}',
  'tooltip.watchlist': '此人的观察名单信息。',
  'watchList.category': '观察名单的类别',
  'err.LastName.maxlength': '姓氏必须少于50个字符',
  'err.FirstName.maxlength': '名字必须少于50个字符',
  'err.OtherName.maxlength': '其他名字必须少于50个字符',
  'err.PlaceOfBirthCity.maxlength': '出生城市必须少于50个字符',
  'err.Phone.maxlength': '电话号码必须少于20个字符',
  'err.Occupation.maxlength': '职业必须少于50个字符',
  'err.HostName.maxlength': '接待者名称必须少于50个字符',
  'err.EmergencyLastName.maxlength': '紧急联系人姓氏必须少于50个字符',
  'err.EmergencyFirstName.maxlength': '紧急联系人名字必须少于50个字符',
  'err.EmergencyPhone.maxlength': '紧急联系人电话号码必须少于20个字符',
  'err.PlaceofIssue.maxlength': '签发地必须少于50个字符',
  'err.SpouseFullName.maxlength': '配偶名称必须少于50个字符',
  'err.SpousePassportNumber.maxlength': '配偶护照号码必须少于20个字符',
  'err.SpousePlaceOfBirth.maxlength': '配偶出生地必须少于50个字符',
  'err.PassportNumber.maxlength': '护照号码必须少于20个字符',
  'err.PreviousConvictionsHome.maxlength': '在本国的先前定罪必须少于50个字符',
  'err.FundsAvailable.maxlength': '入境时可用资金必须少于50个字符',
  'err.firstName.maxlength': '名字必须少于50个字符',
  'err.lastName.maxlength': '姓氏必须少于50个字符',
  'err.passportNr.maxlength': '护照号码必须少于20个字符',
  'err.otherLinkType.maxlength': '关系类型必须少于50个字符',
  'err.id.maxlength': '外部ID必须少于50个字符',
  'err.name.maxlength': '名称必须少于50个字符',
  'err.password2.maxlength': '密码必须在6到20个字符之间',
  'err.email.maxlength': '电子邮件必须少于50个字符',
  'err.userId.maxlength': '用户ID必须少于50个字符',
  'err.phoneNumbersCsv.required': '电话号码是必需的',
  'err.idParent.required': '父母ID是必需的',
  'err.phoneNumbersCsv.maxlength': '电话号码必须少于20个字符',
  'watchlist.level': '警报级别',
  'ask.fingerprint': '您想要采集此人的指纹吗？',
  'scan.fingerprint.desc': '准备好后，点击下方按钮开始采集此人的指纹。当指纹设备的红灯亮起时，此人必须将食指放在扫描器上，官员会进行提示。',
  'scan.fingerprint.noResponse': '指纹扫描仪无响应。此人是否将手指放在扫描器上？设备是否已正确连接？',
  'scan.fingerprint': '指纹',
  'fingerprint.scan.start': '扫描指纹',
  'scan.fingerprint.success': '指纹已成功扫描。扫描指纹的质量为 {quality}/100。如果质量不够好，您可以重试此过程。',
  'fingerprint.notAvailable': '看起来指纹扫描仪不可用，或驱动程序未安装。您仍然可以在没有指纹的情况下继续签到。',
  'skip': '跳过',
  'global.checkOut.fingerprint': '使用指纹签退',
  'global.checkIn.fingerprint': '使用指纹签到',
  'tooltip.checkOut.fingerprint': '使用指纹为此人办理签退手续。',
  'tooltip.checkIn.fingerprint': '使用指纹为此人办理签到手续。',
  'paymentError.title': '订单问题',
  'DYN.paymentError.desc.fail': '很抱歉，支付失败。请重试。',
  'DYN.paymentError.desc.error': '很抱歉，支付处理返回错误。请联系我们',
  'DYN.paymentError.desc.later': '很抱歉，支付处理暂时被拒绝。请重试。',
  'tooltip.applicationId': '申请的ID。',
  'client.extended.missingResource': '您请求的资源当前不可用。请稍后再试。',
  'skip.valid': '自动跳过已审查的步骤',
  'extension.data': '延期数据',
  'attestantAddress': '证人地址',
  'attestantEmail': '证人的电子邮件',
  'attestantID': '证人的ID',
  'attestantName': '证人姓名',
  'attestantPhone': '证人电话',
  'attestantRelation': '与证人的关系',
  'daysRequested': '请求的天数',
  'tooltip.visaApplicationReference': '前往与此延期请求相关的签证申请',
  'extend.visaReference': '与此延期请求相关的申请',
  'data.copyOfEntry': '入境副本',
  'data.departureTicket': '离境机票',
  'data.attestantDocument': '证人的文件',
  'data.affidavitCustody': '监护声明书',
  'reasonForExtention': '延期原因',
  'final.ZWEVISAEXTAB': '此延期请求需要官员批准。',
  'zwevisaExtValid': '延期已验证',
  'zwevisaExtValid.desc': '此延期请求已由官员验证。',
  'zwevisaExtC.review.state': '等待审查',
  'zwevisaExtC.review.desc': '此延期请求等待审查。',
  'zwevisaExtC.finalReview.desc': '此延期请求已被审查，当前等待最终审查。',
  'zwevisaExtC.finalReview.state': '等待最终审查',
  'err.AttestantName.maxlength': '证人姓名必须少于50个字符',
  'tooltip.tableLocalUsageUntilTitle': '根据最长停留日期对申请进行排序。',
  'tooltip.tableLocalUsageUntil': '申请的到期日期指的是最长停留日期。',
  'ask.usageUntil.confirm': '您可以在此更改最长停留日期。您只能缩短日期。',
  'usageUntil.ask': '更改最长停留日期（可选）',
  'error.bad.localUsageUntil': '最长停留日期必须早于当前到期日期且晚于今天。',
  'no.limit': '无限制',
  'to.determined': '待定',
  'none': '无',
  'change.password.ad': '由于您拥有活动目录帐户，您不能在此更改密码。欲了解更多信息，请联系支持。',
  'noticePrint.title': '已打印给客户的访客通知信息',
  'reportingPost': '报告岗位',
  'noticePrint.matTooltip': '在签到过程中打印给客户的访客通知信息',
  'err.paragraph.pattern': '该字段只能包含一个不带重音符号的字母。',
  'ask.print': '您想打印访客通知还是临时限制通知？',
  'notice.confirm': '您确定通知中的信息正确吗？',
  'camera.printNotice': '打印通知',
  'global.refusePrint': '打印并提交',
  'global.nextPrint': '打印并继续',
  'err.periodFrom.periodFromGreaterThanPeriodTo': '开始时间必须早于结束时间。',
  'err.externalId.maxlength': '外部ID必须少于60个字符',
  'payment_status.DYN.pass.9': '稍后支付 - 已确认',
  'payment.reject.success': '支付已成功拒绝。',
  'payment.reject_reason': '拒绝原因',
  'err.fileSize': '文件大小太大。最大大小为：{size}',
  'scan.birthCertificate': '上传出生证明',
  'err.scanBirthCertificate.required': '出生证明是必需的',
  'document.zafembpass': '南非大使馆护照',
  'preenroll': '预注册',
  'screen': '筛查',
  'pass': '护照',
  'zafembpass': '南非大使馆护照',
  'document.pass': '护照',
  'payment.validate.success': '付款已成功验证。',
  'document.screen': '筛查',
  'payment.consume.print.done.DYN.zwevisa': '签证申请已成功办理入境/出境。收据将打印。',
  'payment.ask.print': '收据已打印吗？',
  'global.no.retry': '否（重试）',
  'print.receipt': '打印收据',
  'tooltip.printReceipt': '打印申请的收据',
  'ask.printReceipt': '您要打印日期为{date}的收据吗？',
  'done.printReceipt': '收据已成功打印。',
  'error.printReceipt': '打印收据时发生错误。请重试。',
  'ask.selectReceipt': '请选择您要打印的收据。',
  'error.noReceiptSelected': '请选择要打印的收据。',
  'operation.OUT': '签退',
  'operation.IN': '签到',
  'noChoice.selectedReceipt': '请选择要打印的收据。',
  'select.receipt': '选择收据',
  'receiptFor': '收据',
  'receiptFor.alt': '收据',
  'paymentType': '支付类型',
  'totalAmount.alt': '总金额',
  'totalAmount': '总金额',
  'totalAmountCurrency': '以支付货币计算的总金额',
  'electronic.receipt': '收据',
  'date': '日期',
  'client': '客户',
  'reference': '参考',
  'agentID': '代理ID',
  'desc': '描述',
  'details': '详细信息',
  'client.extended.minUsageNotMet': '有效期至少应在有效期开始后一天。',
  'err.BirthDay.minDate': '出生日期必须是150年以内的日期。',
  'err.SpouseBirthDay.minDate': '配偶的出生日期必须是150年以内的日期。',
  'payment.assigned.warning': '此申请目前正在由另一名官员（官员：{officerId}）在24小时内使用。您是否仍想打开此申请？',
  'lock': '锁定',
  'tooltip.tableLockTitle': '官员的活动使用',
  'tooltip.tableLock.me': '您当前是此申请的活动官员',
  'tooltip.tableLock.other': '此申请当前由官员{officer}使用',
  'client.extended.uuidNotPaid': '付款尚未验证。请先验证付款再继续。',
  'form.operationComments': '操作说明',
  'err.operationComments.required': '必须填写操作说明',
  'err.operationComments.maxlength': '操作说明不得超过500个字符',
  'err.DateOfExpiry.maxDate': '到期日期必须在未来10年以内。',
  'err.ArrivalDate.maxDate': '到达日期必须在未来150年以内。',
  'err.DepartureDate.maxDate': '出发日期必须在未来150年以内。',
  'err.periodFrom.minDate': '开始日期必须在今天之后。',
  'err.periodFrom.maxDate': '开始日期必须在未来150年以内。',
  'err.periodTo.minDate': '结束日期必须在今天之后。',
  'err.periodTo.maxDate': '结束日期必须在未来150年以内。',
  'err.reportOn.minDate': '报告日期必须在今天之后。',
  'err.reportOn.maxDate': '报告日期必须在未来150年以内。',
  'err.PlaceofIssue.required': '签发地点是必需的',
  'err.borderPass.required': '边境通行证是必需的',
  'exit.borderPass.success': '免签离境已成功注册。您将被重定向到申请详情。',
  'borderPass.emit': '已签发边境通行证',
  'tooltip.borderPass': '边境通行证是允许没有护照的个人过境的文件。',
  'zweexit.title': '免签离境',
  'borderPass.title': '边境通行证',
  'print.borderPass': '打印边境通行证',
  'print.borderPass.ask': '边境通行证已打印吗？',
  'err.borderPassNumber.maxlength': '边境通行证号码不得超过20个字符',
  'err.issueDate.afterExpiryDate': '签发日期必须早于到期日期。',
  'err.BirthDay.afterExpiryDate': '出生日期必须早于到期日期。',
  'err.BirthDay.afterIssueDate': '出生日期必须早于签发日期。',
  'apiPnrCollapse': '找到PNR',
  'apipnrResponse.match': '匹配{numberMatch}',
  'match.name': '名称',
  'match.score.name': '名称评分',
  'match.flightCarrier': '航班承运人',
  'match.flightNumber': '航班号',
  'match.documentType': '文件类型',
  'match.documentNumber': '文件编号',
  'match.documentIssuer': '文件签发者',
  'match.timeType': '事件类型',
  'match.timeDate': '事件日期',
  'match.timeScope': '事件范围',
  'match.nationality': '国籍',
  'match.gender': '性别',
  'match.uuid': 'UUID',
  'match.travelerUUID': '旅行者UUID',
  'tooltip.apiPnr': '找到的PNR信息。',
  'match.documentIssued': '文件签发日期',
  'match.documentExpiration': '文件到期日期',
  'match.documentScore': '文件评分',
  'match.timeScore': '事件评分',
  'match.flightOriginDomestic': '航班国内起点',
  'match.flightOriginIcao': '航班起点ICAO代码',
  'match.flightOriginLat': '航班起点纬度',
  'match.flightOriginLng': '航班起点经度',
  'match.flightOriginIata': '航班起点IATA代码',
  'match.flightOriginCity': '航班起点城市',
  'match.flightOriginState': '航班起点州',
  'match.flightOriginCountry': '航班起点国家',
  'match.flightOriginContinent': '航班起点大陆',
  'match.flightOriginName': '航班起点名称',
  'match.flightOriginTimezone': '航班起点时区',
  'match.flightDestinationDomestic': '航班国内目的地',
  'match.flightDestinationIcao': '航班目的地ICAO代码',
  'match.flightDestinationLat': '航班目的地纬度',
  'match.flightDestinationLng': '航班目的地经度',
  'match.flightDestinationIata': '航班目的地IATA代码',
  'match.flightDestinationCity': '航班目的地城市',
  'match.flightDestinationState': '航班目的地州',
  'match.flightDestinationCountry': '航班目的地国家',
  'match.flightDestinationContinent': '航班目的地大陆',
  'match.flightDestinationName': '航班目的地名称',
  'match.flightDestinationTimezone': '航班目的地时区',
  'match.localDepartureDate': '本地出发日期',
  'match.localDepartureTime': '本地出发时间',
  'match.localDepartureTotalMinutes': '本地出发总分钟数',
  'match.localDepartureDay': '本地出发日',
  'match.localDepartureZone': '本地出发区域',
  'match.airportDepartureDate': '机场出发日期',
  'match.airportDepartureTime': '机场出发时间',
  'match.airportDepartureTotalMinutes': '机场出发总分钟数',
  'match.airportDepartureDay': '机场出发日',
  'match.airportDepartureZone': '机场出发区域',
  'match.utcDepartureDate': 'UTC出发日期',
  'match.utcDepartureTime': 'UTC出发时间',
  'match.utcDepartureTotalMinutes': 'UTC出发总分钟数',
  'match.utcDepartureDay': 'UTC出发日',
  'match.utcDepartureZone': 'UTC出发区域',
  'match.originalDeparture': '原始出发',
  'match.flightDepartureUtcstr': '航班UTC出发',
  'match.localArrivalDate': '本地到达日期',
  'match.localArrivalTime': '本地到达时间',
  'match.localArrivalTotalMinutes': '本地到达总分钟数',
  'match.localArrivalDay': '本地到达日',
  'match.localArrivalZone': '本地到达区域',
  'match.airportArrivalDate': '机场到达日期',
  'match.airportArrivalTime': '机场到达时间',
  'match.airportArrivalTotalMinutes': '机场到达总分钟数',
  'match.airportArrivalDay': '机场到达日',
  'match.airportArrivalZone': '机场到达区域',
  'match.utcArrivalDate': 'UTC到达日期',
  'match.utcArrivalTime': 'UTC到达时间',
  'match.utcArrivalTotalMinutes': 'UTC到达总分钟数',
  'match.utcArrivalDay': 'UTC到达日',
  'match.utcArrivalZone': 'UTC到达区域',
  'match.originalArrival': '原始到达',
  'match.flightArrivalUtcstr': '航班UTC到达',
  'match.flightBound': '航班方向',
  'print.receipt.select': '收据将被打印。',
  'ask.notice': '打印访客通知或临时限制通知',
  'tooltip.checkIn.notice': '为此人办理入境并打印访客通知或临时限制通知。',
  'global.checkIn.notice': '带通知的签到',
  'event.downgrade': '此申请已从{previousPayment}降级为{newPayment}，时间为{date}，用户{userId}。',
  'form.numberPeople': '与申请人同行的人数',
  'feedbackForm': '反馈表',
  'form.totalSpend': '停留期间的总花费',
  'countryPermanentResidence': '永久居住国',
  'countryDestination': '目的地国家',
  'form.feedbackComments': '附加评论',
  'feedback.submit': '提交反馈',
  'err.fundsSpend.required': '停留期间的总花费是必需的',
  'err.fundsCurrency.required': '花费的货币是必需的',
  'err.countryResidence.required': '永久居住国是必需的',
  'err.countryDestination.required': '目的地国家是必需的',
  'err.feedbackComment.required': '附加评论是必需的',
  'feedback.submitted': '反馈已成功提交。',
  'start_feedback': '提供反馈',
  'start': '开始',
  // Trads lib
  'demoMode.on': '当前为演示模式',
  'demoMode.off': '启用演示模式',
  'alreadyAccount': '已有账号？',
  'close': '关闭',
  'createAccount': '创建账户',
  'err.accountValidated': '您的账户已验证',
  'err.confirm': '无法确认电子邮件',
  'err.consumeLogin': '登录时出错',
  'err.consumeLoginWeak': '无法登录',
  'err.country.required': '国家是必需的',
  'err.err': '错误',
  'err.firstName.pattern': '名字格式不正确',
  'err.gender.required': '性别是必需的',
  'err.info': '无法读取账户信息',
  'err.lastName.pattern': '姓氏格式不正确',
  'err.middleName.pattern': '中间名格式不正确',
  'err.middleName.required': '中间名是必需的',
  'err.nationality.pattern': '国籍格式不正确',
  'err.nationality.required': '国籍是必需的',
  'err.noEncryptedPDF': '无法读取文件，文件为加密的PDF或受保护',
  'err.otp.required': '验证码（OTP 1）是必需的',
  'err.otp2.required': '验证码（OTP 2）是必需的',
  'err.profession.pattern': '职业格式不正确',
  'err.profession.required': '职业是必需的',
  'err.rcs.required': '公司编号是必需的',
  'err.rearm': '无法重新发送电子邮件',
  'err.register': '无法创建账户',
  'err.register2FA': '2FA注册错误',
  'err.registerUserVoteRepartition': '无法关联用户',
  'err.registerVote': '无法记录投票',
  'err.reinforceTenantLink': '无法为用户添加角色',
  'err.requiredField': '缺少必填字段',
  'err.resetPassword': '无法更改密码',
  'err.street.required': '街道名称是必需的',
  'err.streetNumber.required': '街道号码是必需的',
  'err.tenantLang.required': '语言是必需的',
  'err.updateUserMeeting': '用户未保存',
  'err.zip.required': '邮政编码是必需的',
  'error.basic': '发生意外错误',
  'form.address': '地址',
  'form.birthPlace': '出生地',
  'form.canNr': 'CAN编号',
  'form.choose_country': '选择国家',
  'form.choose_gmt': '选择时区',
  'form.choose_lang': '选择语言',
  'form.choose_nationality': '选择国籍',
  'form.city': '城市',
  'form.code': '代码',
  'form.connectIpRange': 'IP范围',
  'form.connectUaPattern': 'UA模式',
  'form.country': '国家',
  'form.country.BEL': '比利时',
  'form.country.CIV': '科特迪瓦',
  'form.country.LUX': '卢森堡',
  'form.country.ZWE': '津巴布韦',
  'form.dateFrom': '从',
  'form.dateTo': '到',
  'form.docNr': '文件编号',
  'form.expiryDate': '到期日期',
  'form.gender': '性别',
  'form.gender.female': '女',
  'form.gender.male': '男',
  'form.gmt': '时区',
  'form.gmtShiftMinutes.DYN-360': '-6（纽约，…）',
  'form.gmtShiftMinutes.DYN-540': '-9（旧金山，…）',
  'form.gmtShiftMinutes.DYN0': '+0（伦敦，…）',
  'form.gmtShiftMinutes.DYN60': '+1（卢森堡，巴黎，布鲁塞尔，…）',
  'form.height': '身高（厘米）',
  'form.identifier': '标识符',
  'form.lang': '语言',
  'form.lang.EN': 'English',
  'form.lang.FR': 'Français',
  'form.lang.IT': 'Italiano',
  'form.lang.JA': '日本語',
  'form.lang.DE': 'Deutsch',
  'form.lang.CN': '中国人',
  'form.lang.RU': 'Pусский',
  'form.middleName': '中间名',
  'form.mrz': 'MRZ',
  'form.nationality': '国籍',
  'form.nationality.BEL': '比利时',
  'form.nationality.CIV': '科特迪瓦',
  'form.nationality.LUX': '卢森堡',
  'form.nationality.ZWE': '津巴布韦',
  'form.personalNr': '个人编号',
  'form.passportNr': '护照号码',
  'form.phoneNumber': '电话号码',
  'form.profession': '职业',
  'form.rcs': 'RCS编号',
  'form.street': '街道名称',
  'form.streetNr': '街道号码',
  'form.tenantLang.DYN0': '英语',
  'form.tenantLang.DYN1': '法语',
  'form.tva': 'VAT编号',
  'form.user': '用户',
  'form.zip': '邮政编码',
  'global.clear': '清除',
  'global.save': '保存',
  'global.continue': '继续',
  'global.error': '发生错误，请重试',
  'global.more': '更多',
  'global.history': '历史记录',
  'global.see': '查看',
  'global.faq': '常见问题',
  'global.changePassword': '更改密码',
  'global.ddlDoc': '下载文件',
  'global.no_data_available': '无可用数据',
  'global.not_yet_implemented.desc': '抱歉，此页面尚未实现',
  'global.not_yet_implemented.title': '惊喜！',
  'global.search': '搜索',
  'global.receipt': '收据',
  'global.ddlReceipt': '下载收据',
  'global.myDatas': '我的数据',
  'global.share': '分享',
  'global.notAccount': '还没有账号？',
  'global.ready': '准备就绪',
  'global.fileSend': '选择文件',
  'global.start': '开始',
  'infinite': '无限',
  'login.back_sign_in': '返回登录',
  'login.code': '验证',
  'login.create_account': '创建账户',
  'login.didReset': '密码已更改，请登录',
  'login.email_verification': '电子邮件验证',
  'login.email_verification2': '已发送',
  'login.email_verification_text': '感谢您的注册。要验证您的账户并继续注册，请点击发送到电子邮件中的链接',
  'login.forInvite': '登录时，您正在使用以下邀请：',
  'login.forgot': '忘记了您的',
  'login.forgot_sent': '电子邮件',
  'login.forgot_sent2': '已发送',
  'login.forgot_sent_text': '您的密码重置请求已发送到电子邮件地址',
  'login.has_account': '现有用户',
  'login.in': '登录',
  'login.invalid': '账户或密码不正确，请重试！',
  'login.lost': '丢失',
  'login.lost_otp': '丢失OTP',
  'login.lost_otp_text': '如果您无法访问Google Authenticator（例如手机丢失或更换，应用程序卸载…），您将无法登录DocSeal。这是我们安全策略的一部分。请点击下方联系支持，验证您的身份并帮助您恢复完整的账户访问。',
  'login.noInviteDone': '无法使用邀请，是否已过期或已被使用？',
  'login.otp': '验证码',
  'login.password': '密码',
  'login.registered': '账户已创建，您可以在确认电子邮件中的链接后登录！',
  'login.reset': '验证码已通过电子邮件发送',
  'login.setPassword': '选择密码',
  'login.setPasswordLong': '为方便登录选择您的密码',
  'login.sign': '登录',
  'login.to_sign_in': '返回登录',
  'login.up': '注册',
  'nan': '不可用',
  'sign.prepared': '文件正在准备中',
  'signIn.forgotPassword': '忘记密码？',
  'signIn.title': '访问',
  'signInPro.title': '专业访问',
  'signUp.title': '创建账户',
  'global.reset': '重置'
  // 
};
