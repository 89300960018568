import {Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {PrintReceiptData} from '../../shared/models/request';
import {QrService} from '../../shared/services/qr.service';
import {RequestService} from '../../shared/services/request.service';
import {format} from 'date-fns';
import {SyncService} from '../../shared/services/sync.service';
import {environment} from '../../../environments/environment';
import {AbstractPrintComponent} from '../abstract-print/abstract-print.component';

@Component({
  selector: 'app-print-receipt-a5',
  templateUrl: './print-receipt-a5.component.html',
  styleUrl: './print-receipt-a5.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PrintReceiptA5Component extends AbstractPrintComponent {
  @Input() data : PrintReceiptData;
  @Output() init: EventEmitter<any> = new EventEmitter<any>();

  format = format;
  constructor(public qr: QrService, public request: RequestService, public sync: SyncService, elRef: ElementRef) {
    super(elRef);
  }

  protected readonly environment = environment;
}
