/* tslint:disable */
export const fr_strings = {
  'transactionReference.alt': 'Référence de la transaction',
  'paymentType.alt': 'Type de paiement',
  'changeGender.ask': 'Le genre sur la demande doit être modifié en Homme ou Femme.',
  'print.askMultiple': 'Souhaitez-vous inclure tous les documents associés dans l’impression ?',
  'application.id': 'ID de la demande',
  'printSticker': 'Imprimer l’autocollant de visa',
  'print.receiptA5': 'Imprimer le reçu en A5',
  'correctionFields': 'Les champs suivants ont été corrigés :',
  'corrected.desc': 'Cette demande de visa a été corrigée par le demandeur le {date}.',
  'recentlyCorrected': 'Corrigé',
  'visaValidFrom': 'Validité d’entrée à partir de',
  'visaValidUntil': 'Validité d’entrée jusqu’au',
  'online': 'En ligne',
  'offline': 'Hors ligne',
  'receiptNr': 'Numéro de reçu',
  'issuingOfficer.alt': 'Agent émetteur',
  'placeOfIssue': 'Lieu d’émission',
  'fileRefNr': 'Numéro de référence du dossier',
  'tooltip.hasBeenCorrected': 'Cette demande a été corrigée par le demandeur.',
  'tooltip.extendStay': 'Prolonger le séjour du demandeur',
  'printFoldable': 'Imprimer pliable',
  'printApprovalLetter': 'Imprimer la lettre d’approbation',
  'operation.REFUSEOUT': 'Sortie refusée',
  'events': 'Événements',
  'tooltip.events': 'Voici les détails des événements. Certains peuvent contenir plus d’informations que d’autres selon le type d’événement et l’opération effectuée.',
  'form.paymentReference': 'Référence de paiement',
  'error.closeApplication': 'Une erreur est survenue lors de la fermeture de la demande. Veuillez réessayer.',
  'closeApplication': 'Marquer comme fermée',
  'tooltip.closeApplication': 'Marquer la demande comme fermée. Cette action est irréversible.',
  'tooltip.tableNationalityTitle': 'Nationalité du demandeur',
  'understand': 'Je comprends',
  'ExtraDocument1': 'Document supplémentaire n°1',
  'ExtraDocument2': 'Document supplémentaire n°2',
  'ExtraDocument3': 'Document supplémentaire n°3',
  'ExtraDocument4': 'Document supplémentaire n°4',
  'ExtraDocument5': 'Document supplémentaire n°5',
  'paperSize': 'Largeur du papier',
  'global.changePrinterSize': 'Modifier la largeur du papier de reçu',
  'change_lang': 'Changer de langue',
  'queue.export.desc': 'Souhaitez-vous exporter la file d’attente actuelle vers un fichier JSON ? <b>Votre file d’attente locale sera supprimée après l’exportation</b>. Êtes-vous sûr de vouloir continuer ?',
  'queue.export': 'Exporter en JSON',
  'queue.import': 'Importer la file d’attente depuis un JSON',
  'nationality': 'Nationalité',
  'gender': 'Genre',
  'HQReference': 'Référence du QG',
  'vendorName': 'Nom du fournisseur',
  'vendorAddress': 'Adresse du fournisseur',
  'vendorEmail': 'Email du fournisseur',
  'idRequest': 'ID de la demande',
  'serialNumber': 'Numéro de série',
  'global.resetDefaults': 'Réinitialiser par défaut',
  'shortcuts.desc': 'Vous pouvez définir vos propres raccourcis clavier ci-dessous. Cliquez sur la touche que vous souhaitez définir comme raccourci et appuyez sur la touche souhaitée sur votre clavier.',
  'press.key': 'Appuyez sur une touche pour définir le raccourci...',
  'dashboard.keyboardShortcuts': 'Raccourcis clavier',
  'tooltip.changeShortcuts': 'Modifier les raccourcis clavier',
  'global.changeShortcuts': 'Modifier les raccourcis',
  'retake_picture': 'Reprendre la photo',
  'error.faceCheck': 'Une erreur est survenue lors du traitement de la correspondance faciale. Veuillez réessayer.',
  'faceCheck.see_score': 'Correspondance faciale',
  'recommendOrNot': 'Recommander ou non',
  'recommended': 'Recommandé',
  'not_recommended': 'Non recommandé',
  'error.downgrade': 'Une erreur est survenue lors du déclassement de la demande. Veuillez réessayer.',
  'done.downgrade': 'La demande a été rétrogradée avec succès en visa d’entrée unique.',
  'request.downgrade': 'Rétrograder',
  'err.externalCode.pattern': 'Le code externe (OACI) doit respecter le format correct : 4 lettres majuscules',
  'request.extendStay': 'Prolonger le séjour',
  'ResidencePermitNumber': 'Numéro du permis de séjour',
  'start_continue': 'Reprendre le paiement',
  'error.payment.noInternalReference': 'Une référence HQ est requise pour que cette demande soit examinée. Veuillez attendre que la référence soit fournie.',
  'err.Occupation.required': 'La profession est requise',
  'err.HomeAddress.required': 'L’adresse du domicile est requise',
  'err.PassportCountry.required': 'Le pays du passeport est requis',
  'request.revision': 'Souhaitez-vous activer l’Autoskip ? Cette fonctionnalité permettra de passer automatiquement les étapes examinées qui ne nécessitent pas votre intervention.',
  'notice.BORDER_PASS': 'Passage frontalier',
  'form.borderPassNumber': 'Numéro du passage frontalier',
  'form.dateOfBirth': 'Date de naissance',
  'form.issueDate': 'Date d’émission',
  'form.authorizedCrossingPoints': 'Points de passage autorisés',
  'form.issuedBy': 'Délivré par',
  'err.borderPassNumber.required': 'Le numéro de passage frontalier est requis',
  'err.dateOfBirth.required': 'La date de naissance est requise',
  'err.expiryDate.required': 'La date d’expiration est requise',
  'err.authorizedCrossingPoints.required': 'Les points de passage autorisés sont requis',
  'err.issuedBy.required': 'Délivré par est requis',
  'err.issueDate.required': 'La date d’émission est requise',
  'form.externalCode': 'Code externe (OACI)',
  'err.invoke': 'Erreur',
  'ask.pendingRequest': 'Vous avez une correction en attente. Souhaitez-vous l’ouvrir maintenant ?',
  'entry.confirmation': 'Êtes-vous sûr que toutes les informations fournies sont correctes ? En cliquant sur "Confirmer", vous soumettrez la demande.',
  'free': 'Gratuit',
  'last_update': 'Dernière mise à jour',
  'count': 'Nombre',
  'tooltip.linkedExtensions': 'Il s’agit d’une prolongation de séjour. Cliquez pour voir les détails.',
  'extension': 'Extension',
  'client.extended.badPaymentMetadata': 'Les métadonnées de paiement sont invalides. Veuillez réessayer ou contacter le support si le problème persiste.',
  'DaysRequested': 'Jours demandés',
  'payment.cancelled': 'Le paiement a été annulé. Veuillez réessayer si vous souhaitez continuer. Vous serez redirigé vers la page précédente.',
  'payment.declined': 'Le paiement a été refusé. Veuillez vérifier vos coordonnées de paiement et la disponibilité des fonds. Vous serez redirigé vers la page précédente.',
  'extension.ref': 'Référence de prolongation de séjour',
  'extensions.desc': 'Prolongation(s) de séjour en attente d’examen',
  'extend': 'Prolonger',
  'err.SpouseBirthDay.maxDate': 'La date de naissance du conjoint doit être antérieure à aujourd’hui',
  'CheckboxVisaExtendFees': 'Je confirme que les informations fournies sont exactes et je suis d’accord avec les frais de prolongation de séjour, les conditions et la politique de confidentialité.',
  'err.AttestantPhone.pattern': 'Le numéro de téléphone doit respecter un format correct, par exemple : +263771234567',
  'err.AttestantEmail.email': 'L’email de l’attestant doit respecter un format correct',
  'err.AttestantDocument.required': 'Le document de l’attestant est requis',
  'err.AffidavitCustody.required': 'L’affidavit de garde est requis',
  'err.CopyOfEntry.required': 'La copie de l’entrée est requise',
  'err.DepartureTicket.required': 'Le billet de départ est requis',
  'AttestantDocument': 'Document de l’attestant',
  'AffidavitCustody': 'Affidavit de garde',
  'CopyOfEntry': 'Copie de l’entrée',
  'DepartureTicket': 'Billet de départ',
  'DYN.Self': 'Soi-même',
  'DYN.Spouse/Dependent': 'Conjoint/Dépendant',
  'DYN.Employee/Employer': 'Employé/Employeur',
  'DYN.Co-Director': 'Co-Directeur',
  'DYN.Lawyer': 'Avocat',
  'VisaReference': 'Référence de visa',
  'ReasonForExtension': 'Raison de la prolongation',
  'AttestantID': 'ID de l’attestant',
  'AttestantName': 'Nom de l’attestant',
  'AttestantPhone': 'Téléphone de l’attestant',
  'AttestantEmail': 'Email de l’attestant',
  'AttestantAddress': 'Adresse de l’attestant',
  'AttestantRelation': 'Relation de l’attestant',
  'err.VisaReference.required': 'La référence de visa est requise',
  'err.ReasonForExtension.required': 'La raison de la prolongation est requise',
  'err.AttestantID.required': 'L’ID de l’attestant est requis',
  'err.AttestantName.required': 'Le nom de l’attestant est requis',
  'err.AttestantPhone.required': 'Le téléphone de l’attestant est requis',
  'err.AttestantEmail.required': 'L’email de l’attestant est requis',
  'err.AttestantAddress.required': 'L’adresse de l’attestant est requise',
  'err.AttestantRelation.required': 'La relation de l’attestant est requise',
  'start_extend': 'Prolonger le séjour',
  'visaExtend.title': 'Prolongation de séjour',
  'days': 'jours',
  'day': 'jour',
  'err.Email.email': 'L’email doit respecter un format correct',
  'err.EmergencyEmail.email': 'L’email du contact d’urgence doit respecter un format correct',
  'kiosk.standby.text1': 'Commencez par remplir votre déclaration en quelques clics sur cette tablette ou en ligne.',
  'kiosk.standbyDesc.text1': 'Avant de commencer, veuillez noter les informations suivantes :',
  'kiosk.standbyDesc.li1': 'Vous remplirez un formulaire électronique qui recueille vos données personnelles et les détails de votre voyage.',
  'kiosk.standbyDesc.li2': 'Toutes les informations fournies doivent être exactes et correspondre à vos documents de voyage (passeport, visa, etc.).',
  'kiosk.standbyDesc.li3': 'Après avoir rempli le formulaire, vous pouvez vous rendre directement au guichet d’immigration avec vos documents d’identification.',
  'kiosk.standbyDesc.li5': 'Si vous avez des difficultés, n’hésitez pas à demander de l’aide au personnel du poste frontière.',
  'kiosk.standbyDesc.text2': 'Cliquez sur "Commencer" lorsque vous êtes prêt à remplir votre déclaration d’entrée.',
  'kiosk.success.title': 'Demande soumise avec succès',
  'kiosk.success.text1': 'Vous pouvez maintenant vous rendre au guichet d’immigration avec vos documents de voyage. Un agent effectuera les vérifications finales et validera votre entrée dans le pays.',
  'kiosk.success.text2': 'Si vous avez des questions ou des difficultés, n’hésitez pas à demander de l’aide au personnel disponible.',
  'confirm.revision': 'Confirmer la révision',
  'print.again': 'Non, imprimer à nouveau',
  'print.isDone': 'L’avis a-t-il été imprimé avec succès ? Veuillez vous assurer de fournir l’avis imprimé au demandeur.',
  'err.otherMissingDocument.required': 'Un autre document manquant est requis',
  'err.reportAt.required': 'Lieu de rapport requis',
  'err.reportOn.required': 'Date de rapport requise',
  'err.reportInterval.required': 'Intervalle de rapport requis',
  'err.address.required': 'Adresse requise',
  'err.periodFrom.required': 'Période à partir de requise',
  'err.periodTo.required': 'Période jusqu’à requise',
  'form.hostPhone': 'Téléphone de l’hôte',
  'err.hostPhone.required': 'Le téléphone de l’hôte est requis',
  'transit': 'Transit',
  'conditionalEntry': 'Entrée conditionnelle',
  'departure': 'Départ',
  'err.host.required': 'L’hôte est requis',
  'err.purpose.required': 'La raison est requise',
  'err.fullNames.required': 'Le nom complet est requis',
  'err.officer.required': 'L’agent est requis',
  'err.paragraph.required': 'Le paragraphe est requis',
  'err.dateOfRefusal.required': 'La date de refus est requise',
  'form.paragraph': 'Paragraphe',
  'form.dateOfRefusal': 'Date de refus',
  'form.purpose': 'Raison',
  'form.host': 'Hôte',
  'form.officer': 'Agent',
  'form.reportAt': 'Lieu de rapport',
  'form.reportOn': 'Date de rapport',
  'form.reportInterval': 'Intervalle de rapport',
  'notice.desc.RESTRICTION': 'L’avis de restriction provisoire informe le demandeur des documents requis pour compléter le processus. Vous pouvez sélectionner les documents manquants que le demandeur doit fournir dans un délai imparti.',
  'notice.REFUSAL': 'Avis de refus d’entrée',
  'notice.desc.REFUSAL': 'Le demandeur se voit refuser l’entrée dans le pays.',
  'notice.desc.REPORTING': 'L’avis au visiteur permet au demandeur d’entrer dans le pays, mais peut être soumis à des rapports supplémentaires. Vous pouvez sélectionner les documents manquants que le demandeur doit fournir dans un délai imparti.',
  'err.hqReference.required': 'La référence HQ est requise',
  'err.stationReference.required': 'La référence de la station est requise',
  'err.reportLocation.required': 'Lieu de rapport requis',
  'err.daysUntilReport.required': 'Nombre de jours avant le rapport requis',
  'err.businessAddress.required': 'Adresse de l’entreprise requise',
  'err.residentialAddress.required': 'Adresse résidentielle requise',
  'form.stationReference': 'Référence de la station',
  'form.fullNames': 'Nom complet',
  'form.reportLocation': 'Lieu de rapport',
  'form.daysUntilReport': 'Nombre de jours avant le rapport',
  'form.otherMissingDocument': 'Autre document manquant',
  'form.residentialAddress': 'Adresse résidentielle',
  'form.businessAddress': 'Adresse de l’entreprise',
  'form.hqReference': 'Référence du QG',
  'nationalPassport': 'Passeport national',
  'residentPermit': 'Permis de séjour',
  'proofOfInvestment': 'Preuve d’investissement',
  'evidenceOfMeans': 'Justificatif de moyens',
  'evidenceOfResidentialStatus': 'Justificatif de statut résidentiel',
  'studentScholarPermit': 'Permis d’étudiant/chercheur',
  'radiologicalCertificate': 'Certificat radiologique',
  'temporaryPermit': 'Permis temporaire',
  'evidenceOfEmployment': 'Justificatif d’emploi',
  'temporaryEmploymentPermit': 'Permis de travail temporaire',
  'fullAddressOfDestination': 'Adresse complète de destination',
  'appealOutcome': 'Résultat de l’appel',
  'request.notice': 'Sélectionnez un avis à imprimer',
  'notice.title': 'Avis disponibles à imprimer',
  'notice.desc': 'Veuillez sélectionner un avis dans la liste ci-dessous. Après avoir rempli les champs requis, vous pouvez imprimer l’avis pour le demandeur.',
  'notice.RESTRICTION': 'Avis de restriction provisoire',
  'notice.REPORTING': 'Avis au visiteur',
  'notice.RESTRICTION.desc': 'Le demandeur doit fournir les documents manquants dans un délai imparti.',
  'notice.REFUSAL.desc': 'Le demandeur se voit refuser l’entrée dans le pays.',
  'notice.REPORTING.desc': 'Le demandeur est autorisé à entrer, mais peut être soumis à des rapports supplémentaires.',
  'passportType.kioskWarning': 'La nationalité sélectionnée est soumise au régime de la catégorie C. Veuillez vous rendre immédiatement chez un agent d’immigration.',
  'free.charge.total': 'Total gratuit',
  'err.entryPoints': 'Une erreur est survenue lors du chargement des points d’entrée prévus',
  'err.PreviousConvictionsHomeAsk.required': 'Veuillez répondre à la question concernant les condamnations antérieures dans votre pays d’origine',
  'summary.your_meta': 'Vos données (cliquez pour ouvrir)',
  'summary.payOnline': 'Je préfère payer en ligne maintenant plutôt qu’au guichet de l’agent à l’arrivée.',
  'summary.payOnline.desc': 'Sélectionner l’option ci-dessous vous amène à la page de paiement. Si vous choisissez de ne pas payer en ligne, vous paierez les frais de visa au guichet de l’agent à l’arrivée pour les types de visa "Visa à l’arrivée".',
  'summary.meta': ' Informations (cliquez pour ouvrir)',
  'EmergencyLastName': 'Nom de famille du contact d’urgence',
  'EmergencyFirstName': 'Prénom du contact d’urgence',
  'EmergencyEmail': 'Email du contact d’urgence',
  'EmergencyPhone': 'Téléphone du contact d’urgence',
  'err.EmergencyLastName.required': 'Le nom de famille du contact d’urgence est requis',
  'err.EmergencyFirstName.required': 'Le prénom du contact d’urgence est requis',
  'err.EmergencyEmail.required': 'L’email du contact d’urgence est requis',
  'err.EmergencyPhone.required': 'Le téléphone du contact d’urgence est requis',
  'person.name': 'Nom de la personne sélectionnée',
  'DYN.identity-card': 'Carte d’identité',
  'DYN.resident-permit': 'Permis de séjour temporaire',
  'DYN.identity-card.desc': 'Une carte d’identité est un document délivré par le gouvernement utilisé à des fins d’identification nationale.',
  'DYN.resident-permit.desc': 'Un permis de séjour temporaire est un document officiel délivré par un gouvernement permettant à un ressortissant étranger de résider dans un pays pour une durée déterminée.',
  'err.visaFeesAndTerms.required': 'Veuillez accepter les frais de visa et les conditions d’utilisation et la politique de confidentialité',
  'err.NonOrdinaryPassMission.required': 'La raison de la visite officielle est requise',
  'NonOrdinaryPassMission': 'Raison de la visite officielle',
  'err.PreviousConvictionsHome.required': 'Les condamnations antérieures dans le pays d’origine sont requises',
  'PreviousConvictionsHomeAsk': 'Avez-vous, ou un de vos dépendants, été condamné pour un crime dans votre pays d’origine ?',
  'PlaceOfBirthCountry': 'Pays de naissance',
  'PlaceOfBirthCity': 'Ville de naissance',
  'OtherName': 'Autre(s) nom(s)',
  'travelName': 'Référence de la demande',
  'plannedEntry': 'Port d’entrée prévu',
  'summary.title': 'Résumé',
  'urgent': 'Urgent',
  'visaType': 'Type de document',
  'passportType': 'Type d’identification',
  'err.plannedEntry.required': 'Le port d’entrée prévu est requis',
  'understood': 'Je suis d’accord',
  'passportType.acknowledge': 'En sélectionnant ce type de passeport, vous reconnaissez que le non-respect des conditions d’utilisation peut entraîner un refus d’entrée.',
  'form.travelName.already': '<b>Cette demande a déjà été soumise avec la référence de demande ci-dessous. Si vous souhaitez modifier la référence, veuillez commencer une nouvelle demande.</b>',
  'application.current_person': 'Vous modifiez actuellement la demande pour',
  'DYN.USD': '$ USD',
  'DYN.EUR': '€ EUR',
  'DYN.ZWL': 'ZWL',
  'DYN.GBP': '£ GBP',
  'DYN.BWP': 'BWP',
  'participants.edit': 'Modifier les demandeurs',
  'request.existSaved': 'Une demande enregistrée a été trouvée, souhaitez-vous charger ses données ? (Si vous cliquez sur "non", la demande sera supprimée et vous commencerez une nouvelle)',
  'participant.hasData': 'Vous avez déjà saisi des données pour ce participant. Si vous continuez, les données seront perdues. Êtes-vous sûr de vouloir continuer ?',
  'participants.title': 'Demandeur(s)',
  'participants.desc': 'Veuillez vous sélectionner vous-même et/ou la ou les personnes qui feront partie de cette demande.</br><b>Le paiement à la fin sera effectué pour toutes les personnes sélectionnées.</b></br></br>Vous pouvez ajouter/retirer une personne ou modifier sa demande à tout moment en cliquant sur le nom qui apparaîtra au-dessus du formulaire.</br></br>{participantText}',
  'participant.ready': 'Lorsque vous êtes prêt, cliquez sur "Continuer".',
  'participant.missing': 'Avant de continuer, veuillez créer au moins une personne.',
  'travelName.title': 'Référence de la demande',
  'form.travelName': 'Référence de la demande',
  'form.travelName.desc': 'La référence de la demande sera utilisée pour identifier plus facilement votre demande.',
  'err.travelName.required': 'La référence de la demande est requise',
  'nationality.title': 'Nationalité',
  'nationality.desc': 'Veuillez sélectionner votre nationalité. La liste des types de documents disponibles variera en fonction de ce choix.',
  'passportType.title': 'Type de document',
  'passportType.desc': 'Veuillez sélectionner le type de document que vous utilisez pour cette demande.',
  'visaType.title': 'Options d’entrée disponibles',
  'visaType.desc': 'Veuillez sélectionner l’option d’entrée pour laquelle vous souhaitez faire une demande. Cette liste varie selon votre nationalité et le type de passeport.',
  'urgent.title': 'Est-ce urgent ?',
  'urgent.desc': 'Si cette demande de visa est <b>urgente</b>, sélectionnez cette option. Le temps de traitement sera plus court, mais un <b>frais supplémentaire</b> sera facturé pour ce service.</br></br>Veuillez noter que le temps de traitement peut varier selon le type de visa et le pays de la demande.',
  'urgent.checkbox': 'Je souhaite que ma demande de visa soit traitée en urgence et je comprends qu’un frais supplémentaire sera facturé pour ce service.',
  'visa.paidOnArrival.title': 'Visa payé à l’arrivée',
  'visa.paidOnArrival.desc': 'Permet aux voyageurs éligibles d’obtenir un visa à l’arrivée au port d’entrée, simplifiant ainsi le processus d’entrée pour les séjours de courte durée.',
  'visa.paidOnline.title': 'Visa payé en ligne',
  'visa.paidOnline.desc': 'Permet une ou plusieurs entrées dans le pays pendant une période d’un (1) mois, nécessitant un nouveau visa pour les entrées ultérieures.',
  'dashboard.zweentry': 'Déclaration d’entrée',
  'PassportType': 'Type de passeport',
  'PassportCountry': 'Pays émetteur du passeport',
  'form.passportCountry': 'Pays émetteur du passeport',
  'DYN.ordinary': 'Passeport ordinaire',
  'DYN.diplomatic': 'Passeport diplomatique',
  'DYN.official': 'Passeport officiel',
  'DYN.service': 'Passeport de service',
  'DYN.refugee': 'Passeport de réfugié',
  'DYN.ordinary.desc': 'Un passeport ordinaire est le type de passeport le plus courant, délivré aux citoyens à des fins de voyage général.',
  'DYN.diplomatic.desc': 'Un passeport diplomatique est délivré aux diplomates et consuls pour les voyages et séjours officiels à l’étranger.',
  'DYN.refugee.desc': 'Un passeport de réfugié est délivré par un pays aux personnes qu’il reconnaît comme réfugiées pour leur permettre de voyager à l’étranger.',
  'DYN.official.desc': 'Un passeport officiel est délivré aux employés du gouvernement pour les voyages et séjours officiels à l’étranger.',
  'DYN.service.desc': 'Un passeport de service est délivré aux employés du gouvernement pour les voyages et séjours officiels à l’étranger.',
  'DYN.marine': 'Passeport maritime',
  'DYN.marine.desc': 'Un passeport maritime est délivré aux marins et membres d’équipage pour les voyages et séjours à l’étranger.',
  'DYN.laissez-passer': 'Laissez-passer',
  'DYN.laissez-passer.desc': 'Un laissez-passer est un document de voyage délivré par les Nations Unies ou ses agences pour les voyages et séjours officiels à l’étranger.',
  'err.PassportType.required': 'Le type de passeport est requis',
  'Urgent': 'Ceci est une demande urgente. Je comprends que le temps de traitement sera plus court, mais les frais seront plus élevés.',
  'photo.passport.scanned': 'Passeport scanné maintenant',
  'review.nr.final': 'Examen final',
  'review.nr': 'Examen n° ',
  'err.ArrivalDate.maxDateMilliTo': 'Une période d’au moins six (6) mois entre la date d’expiration de votre passeport et la date de votre arrivée dans le pays est requise. Veuillez renouveler ce passeport avant de faire une demande de visa.',
  'err.DateOfExpiry.maxDateMilliTo': 'Votre passeport expire dans moins de six (6) mois à compter de la date d’arrivée dans le pays. Veuillez renouveler ce passeport avant de faire une demande de visa.',
  'place.update.error': 'Une erreur est survenue lors de la mise à jour du lieu',
  'dashboard.kiosk.start': 'Commencer le processus',
  'form.role.kiosk': 'Kiosque',
  'currency_desc': 'Veuillez sélectionner le montant et la devise des fonds disponibles au moment de l’entrée :',
  'FundsAvailable': 'Montant',
  'FundsAvailableCurrency': 'Devise',
  'err.FundsAvailable.required': 'Les fonds disponibles au moment de l’entrée sont requis',
  'err.FundsAvailableCurrency.required': 'La devise des fonds disponibles au moment de l’entrée est requise',
  'emergency_contact_text': 'En cas d’urgence, veuillez fournir les coordonnées d’une personne à contacter.',
  'emergency_contact': 'Contact d’urgence / Proche',
  'err.BirthDay.mustBeEqualOrBefore': 'La date de naissance doit être égale ou antérieure à la date d’émission du passeport',
  'err.DateOfIssue.mustBeEqualOrAfter': 'La date d’émission doit être égale ou postérieure à la date de naissance',
  'internet.lost': 'Connexion Internet perdue.</br> Veuillez vérifier votre connexion.',
  'err.DateOfExpiry.maxDateMilliFromToday': 'Votre passeport expire dans moins de six (6) mois à compter d’aujourd’hui. Veuillez renouveler ce passeport avant de faire une demande de visa.',
  'err.DepartureDate.maxDateMilliFrom': 'La date de départ est trop éloignée de la date d’arrivée. Veuillez vous référer à la durée limite du visa.',
  'requirements': 'Exigences',
  'err.file.size': 'La taille du fichier est trop grande. La taille maximale est de : ',
  'revision.success': 'Votre mise à jour de la demande a été soumise avec succès. Vous recevrez un email avec le code QR dès qu’elle sera approuvée.',
  'PAYMENT_UPDATED': 'La demande a été mise à jour',
  'revision.free': 'Révision gratuite',
  'err.Email.pattern': 'L’email doit respecter un format correct',
  'err.Phone.pattern': 'Le numéro de téléphone doit respecter un format correct, par exemple : +263771234567',
  'revision.start': 'Nous avons pris soin de remplir les champs avec les informations que vous avez fournies lors de la demande initiale, sauf pour les champs qui posent problème. Veuillez vérifier les informations et les corriger. Souhaitez-vous continuer ?',
  'file_uploaded': 'Fichier déjà téléchargé et stocké',
  'revision.person_not_found': 'Le demandeur associé à cette demande n’a pas pu être trouvé.',
  'refusal.desc2': 'Les champs suivants posent problème :',
  'start_correction': 'Commencer la correction',
  'PAYMENT_SUSPEND_CHANGE': 'Demande suspendue, modifications demandées.',
  'payment.selectMode': 'Veuillez sélectionner les champs qui posent problème. Vous pouvez sélectionner plusieurs champs.',
  'suspendReason.desc': 'Veuillez écrire une brève description de la demande de révision. Cette description sera envoyée au demandeur avec les champs problématiques sélectionnés.',
  'refusalReason.incomplete_or_missing': 'Informations incomplètes ou manquantes dans le formulaire.',
  'refusalReason.inconsistencies_or_contradictions': 'Incohérences ou contradictions dans les informations fournies.',
  'refusalReason.incorrect_misleading_info': 'Informations incorrectes, trompeuses ou frauduleuses.',
  'refusalReason.missing_or_illegible_documents': 'Documents requis non fournis, incomplets ou illisibles.',
  'refusalReason.documents_not_compliant': 'Documents non conformes aux exigences (qualité, validité).',
  'refusalReason.non_compliance_visa_requirements': 'Non-respect des exigences spécifiques du visa.',
  'refusalReason.terms_conditions_not_accepted': 'Conditions et termes du visa non acceptés.',
  'refusalReason.payment_issues': 'Non-paiement des frais de visa ou échec du processus de paiement.',
  'refusalReason.problems_with_personal_details': 'Problèmes liés à l’âge, la nationalité ou l’état civil du demandeur.',
  'refusalReason.criminal_history_travel_issues': 'Problèmes liés au casier judiciaire ou à l’historique de voyage.',
  'refusalReason.inadequate_purpose_of_visit': 'But de la visite inadéquat ou non conforme au type de visa.',
  'refusalReason.security_background_concerns': 'Problèmes lors des vérifications de sécurité ou des antécédents.',
  'refusalReason.doubts_about_authenticity': 'Doutes sur l’authenticité des informations ou des documents fournis.',
  'refusalReason.legitimacy_concerns': 'Problèmes concernant la légitimité du séjour ou de l’invitation.',
  'refusalReason.form_entry_or_technical_issues': 'Erreurs de saisie dans le formulaire ou problèmes techniques lors du processus de demande.',
  'refusalReason.electronic_submission_difficulties': 'Difficultés liées à la soumission électronique de documents.',
  'refusalReason.host_country_capacity_or_restrictions': 'Capacité du pays hôte dépassée ou restrictions actuelles.',
  'refusalReason.exceptional_diplomatic_political_considerations': 'Considérations diplomatiques ou politiques exceptionnelles.',
  'refusalReason.exceptional_circumstances_humanitarian_reasons': 'Circonstances exceptionnelles ou raisons humanitaires de refus.',
  'refusalReason.other': 'Autre, veuillez spécifier.',
  'err.BirthDay.maxDate': 'La date de naissance doit être antérieure à aujourd’hui',
  'err.DepartureDate.minDate': 'La date de départ doit être postérieure à aujourd’hui',
  'err.ArrivalDate.minDate': 'La date d’arrivée doit être postérieure à aujourd’hui',
  'err.DateOfExpiry.minDate': 'La date d’expiration doit être postérieure à aujourd’hui',
  'err.DateOfIssue.maxDate': 'La date d’émission doit être antérieure à aujourd’hui',
  'err.ArrivalDate.mustBeEqualOrBefore': 'La date d’arrivée doit être égale ou antérieure à la date de départ',
  'err.DepartureDate.mustBeEqualOrAfter': 'La date de départ doit être égale ou postérieure à la date d’arrivée',
  'qr.request_desc': 'Ce code QR sert de laissez-passer numérique pour le traitement des visas. Le gouvernement se réserve le droit d’approuver ou de refuser l’entrée au moment du contrôle des visas.',
  'PAYMENT_REFUSED': 'Paiement refusé',
  'request_state.PENDING': 'En attente',
  'document.Screening': 'Dépistage',
  'deletePerson.error.DYN.pass': 'Une erreur est survenue lors de la suppression de la personne. Vous ne pouvez pas supprimer une personne ayant déjà payé pour un document.',
  'deletePerson.error': 'Une erreur est survenue lors de la suppression de la personne. Vous ne pouvez pas supprimer une personne ayant déjà une demande soumise.',
  'miscellaneousTrip': 'Détails du voyage',
  'miscellaneous_text': 'Veuillez entrer les informations suivantes de manière honnête',
  'err.SpouseBirthDay.max': 'Date de naissance du conjoint invalide',
  'err.ArrivalDate.min': 'Date d’arrivée invalide',
  'err.DepartureDate.min': 'Date de départ invalide',
  'err.DateOfIssue.max': 'Date d’émission invalide',
  'err.BirthDay.max': 'Date de naissance invalide',
  'err.dependants_date_of_birth.max': 'Date de naissance du dépendant invalide',
  'err.DateOfExpiry.min': 'Date d’expiration invalide',
  'dependants': 'Dépendants',
  'err.PassportPhoto.required': 'Une photo récente est requise',
  'err.PassportScan.required': 'Le scan du passeport est requis',
  'err.ProofOfResidenceHost.required': 'La preuve de résidence de l’hôte/réservation est requise',
  'err.BusinessLetter.required': 'Une lettre d’entreprise est requise',
  'err.BusinessProfile.required': 'Un profil d’entreprise est requis',
  'err.InvitationLetterAndBusinessProfile.required': 'Une lettre d’invitation et un profil d’entreprise sont requis',
  'err.SpouseFullName.required': 'Le nom complet du conjoint est requis',
  'err.SpousePassportNumber.required': 'Le numéro de passeport du conjoint est requis',
  'err.SpouseBirthDay.required': 'La date de naissance du conjoint est requise',
  'err.SpousePlaceOfBirth.required': 'Le lieu de naissance du conjoint est requis',
  'err.ArrivalDate.required': 'La date d’arrivée est requise',
  'err.DepartureDate.required': 'La date de départ est requise',
  'err.DateOfIssue.required': 'La date d’émission est requise',
  'err.DateOfExpiry.required': 'La date d’expiration est requise',
  'err.Email.required': 'L’email est requis',
  'err.Phone.required': 'Le numéro de téléphone est requis',
  'err.HostName.required': 'Le nom de l’hôte est requis',
  'err.LastName.required': 'Le nom de famille est requis',
  'err.FirstName.required': 'Le(s) prénom(s) est requis',
  'err.BirthDay.required': 'La date de naissance est requise',
  'err.PlaceOfBirthCity.required': 'La ville de naissance est requise',
  'err.PlaceOfBirthCountry.required': 'Le pays de naissance est requis',
  'err.Gender.required': 'Le genre est requis',
  'err.MaritalStatus.required': 'Le statut matrimonial est requis',
  'err.Nationality.required': 'La nationalité est requise',
  'err.PurposeOfVisit.required': 'La raison de la visite est requise',
  'male': 'Homme',
  'female': 'Femme',
  'other': 'Autre',
  'single': 'Célibataire',
  'married': 'Marié',
  'separated': 'Séparé',
  'divorced': 'Divorcé',
  'widowed': 'Veuf',
  'select_reason_first': 'Veuillez sélectionner la raison de la visite à la page précédente pour voir la liste des documents requis.',
  'visaIntro.title': 'Demande',
  'askDocument': 'Commencer la demande',
  'cart.load': 'Une demande enregistrée a été trouvée, souhaitez-vous charger ses données ? (Si vous cliquez sur "non", la demande sera supprimée)',
  'cart.load.otherConfig': 'Une demande enregistrée a été trouvée, mais le document que vous avez sélectionné provient d’un autre type de formulaire de visa. Souhaitez-vous continuer et supprimer la demande précédente ?',
  'cart.keep': 'Souhaitez-vous enregistrer votre demande et continuer plus tard ?',
  'err.email.pattern': 'L’email doit respecter un format correct',
  'err.phone.pattern': 'Le numéro de téléphone doit respecter un format correct, par exemple : +263 77 123 4567',
  'remove': 'Supprimer',
  'dependant_add': 'Ajouter un dépendant',
  'dependant': 'Dépendant',
  'err.dependants_name.required': 'Le nom du dépendant est requis',
  'err.dependants_passport_number.required': 'Le numéro de passeport du dépendant est requis',
  'err.dependants_date_of_birth.required': 'La date de naissance du dépendant est requise',
  'err.dependants_place_of_birth.required': 'Le lieu de naissance du dépendant est requis',
  'upload_file': 'Télécharger un fichier (< {size})',
  'PassportPhoto': 'Photo récente',
  'PassportScan': 'Scan du passeport',
  'ProofOfResidenceHost': 'Preuve de résidence de l’hôte/réservation',
  'AddressAtDestination': 'Adresse à destination',
  'HostInvitationLetter': 'Lettre d’invitation de l’hôte',
  'ProofOfResidence': 'Preuve de résidence',
  'ApplicationLetter': 'Lettre de demande',
  'ResidentialStatusOfHost': 'Statut résidentiel de l’hôte',
  'ReasonOfVisitProof': 'Preuve de la raison de la visite',
  'BusinessLetter': 'Lettre d’entreprise',
  'BusinessProfile': 'Profil d’entreprise',
  'InvitationLetterAndBusinessProfile': 'Lettre d’invitation et profil d’entreprise',
  'InvitationLetter': 'Lettre d’invitation',
  'LetterOfAcceptanceFromSchool': 'Lettre de confirmation',
  'err.AddressAtDestination.required': 'L’adresse à destination est requise',
  'err.HostInvitationLetter.required': 'La lettre d’invitation de l’hôte est requise',
  'err.ProofOfResidence.required': 'La preuve de résidence est requise',
  'err.ApplicationLetter.required': 'La lettre de demande est requise',
  'err.ResidentialStatusOfHost.required': 'Le statut résidentiel de l’hôte est requis',
  'err.ReasonOfVisitProof.required': 'La preuve de la raison de la visite est requise',
  'err.LetterOfAcceptanceFromSchool.required': 'La lettre de confirmation est requise',
  'err.InvitationLetter.required': 'La lettre d’invitation est requise',
  'DYN.male': 'Homme',
  'DYN.female': 'Femme',
  'DYN.other': 'Autre',
  'DYN.single': 'Célibataire',
  'DYN.married': 'Marié',
  'DYN.separated': 'Séparé',
  'DYN.divorced': 'Divorcé',
  'DYN.widowed': 'Veuf',
  'passport.valid': 'Le document scanné ne semble pas avoir été altéré ou révoqué et est signé par le pays émetteur.',
  'travel_information_title': 'Attention',
  'zwevisa_application_title': 'Avant de continuer, assurez-vous que',
  'zwevisa_application_text': '<ol><li><b>1. </b>Vos informations sont exactes et véridiques</li><li><b>2. </b>Vous avez une carte MasterCard ou Visa 3D Secure et des fonds suffisants pour payer les frais de visa.</li><li><b>3. </b>En effectuant votre paiement et en soumettant cette demande, vous attestez également que toutes les informations saisies sont correctes et véridiques. La falsification d’informations pour l’acquisition d’un visa est illégale et passible de sanctions après condamnation.</li></ol>',
  'visaPrice.desc': 'Vous serez tenu de procéder à ce paiement par Visa ou MasterCard à la fin de la demande. Ce paiement est non remboursable.',
  'visaPrice': 'Prix du visa',
  'required': 'Requis',
  'DYN.holiday_visa': 'Vacances',
  'DYN.live_here': 'Résident à long terme',
  'DYN.business_visa': 'Affaires',
  'DYN.conferencing_visa': 'Conférence',
  'DYN.transit_visa': 'Transit',
  'DYN.student_visa': 'Études',
  'paypal': 'PayPal',
  'document': 'Document',
  'Dependants': 'Dépendants',
  'dependants_name': 'Nom',
  'dependants_passport_number': 'Numéro de passeport',
  'dependants_date_of_birth': 'Date de naissance',
  'dependants_place_of_birth': 'Lieu de naissance',
  'Spouse': 'Conjoint',
  'SpouseFullName': 'Nom du conjoint',
  'SpousePassportNumber': 'Numéro de passeport du conjoint',
  'SpouseBirthDay': 'Date de naissance du conjoint',
  'SpousePlaceOfBirth': 'Lieu de naissance du conjoint',
  'ArrivalDate': 'Date d’arrivée prévue',
  'DepartureDate': 'Date de départ prévue',
  'ReferencePreviousVisa': 'Référence du visa précédent',
  'PreviousConvictionsHome': 'Nature de la/les condamnation(s) dans le pays d’origine',
  'travel_information': 'Informations sur le voyage',
  'DateOfIssue': 'Date d’émission',
  'DateOfExpiry': 'Date d’expiration',
  'PlaceofIssue': 'Lieu d’émission',
  'Email': 'Email',
  'Phone': 'Numéro de téléphone',
  'Occupation': 'Profession',
  'HomeAddress': 'Adresse résidentielle actuelle',
  'HostName': 'Nom de l’hôte',
  'form.seniority': 'Niveau d’ancienneté',
  'LastName': 'Nom de famille',
  'FirstName': 'Prénom(s)',
  'BirthDay': 'Date de naissance',
  'PlaceOfBirth': 'Lieu de naissance',
  'Gender': 'Genre',
  'MaritalStatus': 'Statut matrimonial',
  'CheckboxVisaFees': 'Je confirme que les informations fournies sont exactes et je suis d’accord avec les frais de visa, les conditions d’utilisation et la politique de confidentialité.',
  'PassportNumber': 'Numéro de passeport',
  'Nationality': 'Nationalité',
  'PurposeOfVisit': 'Raison de la visite',
  'VisaType': 'Type de visa',
  'ResidenceProof': 'Preuve de résidence',
  'DestinationAddress': 'Adresse de destination',
  'visitor_details': 'Détails du demandeur',
  'personal_details': 'Détails personnels',
  'contact_details': 'Détails de contact',
  'miscellaneous_details': 'Détails du voyage',
  'spouse_details': 'Détails du conjoint',
  'payment': 'Paiement',
  'dashboard.documents.DYN.pass': 'Demande de document',
  'global.scan_qr': 'Vérifier le code QR',
  'qr.nosuch': 'Chargement... Le code QR n’est pas encore trouvé dans le système. Vérifiez la connexion Internet.',
  'qr.faked': 'La réponse du serveur est que le code QR n’existe pas. Il est très probablement faux !',
  'qr.quicklook': 'Informations clés',
  'global.toPayments.DYN.pass': 'Voir les paiements',
  'dashboard.documents.DYN.zwevisa': 'Demande de visa',
  'global.toPayments.DYN.zwevisa': 'Voir les demandes',
  'form.createPlaceId': 'ID du lieu',
  'payment.seeDuplicata': 'Ouvrir le duplicata',
  'payment.duplicate.open': 'Ouvrir le nouveau paiement ?',
  'ONE_DAY': '1 jour',
  'THREE_DAYS': '3 jours',
  'ONE_WEEK': '1 semaine',
  'TWO_WEEKS': '2 semaines',
  'ONE_MONTH': '1 mois',
  'TWO_MONTHS': '2 mois',
  'SIX_MONTHS': '6 mois',
  'ONE_YEAR': '1 an',
  'dropdown.since': 'Depuis',
  'payments.hasFilter': 'Filtre activé',
  'payments.clear': 'Réinitialiser',
  'payments.clearFilters': 'Effacer tous les filtres',
  'place_created.open': 'Ouvrir la page d’informations du lieu',
  'place_created.desc': 'Le nouveau lieu a été créé et est maintenant utilisable.',
  'payment_status.DYN.NONE': '-- Pas de filtre --',
  'dropdown.state': 'Statut',
  'payment.create_noExternalId': 'Le paiement est enregistré, mais nécessite la validation d’un superviseur avant d’être consommé. Un email vous sera envoyé dès que le paiement sera accepté ou refusé.',
  'payment.qrList.title': 'Liste des paiements QR',
  'see.qr_list': 'Voir la liste des codes QR',
  'form.role.partner_registrer': 'Registre des partenaires',
  'form.reason': 'Raison',
  'err.reason.required': 'La raison est requise',
  'global.reject': 'Rejeter',
  'payment.validate_desc': 'Êtes-vous sûr de vouloir valider ce paiement ?',
  'payment.reject_desc': 'Êtes-vous sûr de vouloir rejeter ce paiement ? N’oubliez pas d’ajouter une raison.',
  'qr.title': 'Code QR',
  'payments.count.DYN.pass': 'Nombre de paiements : ',
  'payments.count.DYN.zwevisa': 'Nombre de demandes : ',
  'dashboard': 'Tableau de bord',
  'dashboard.document': 'Demande de visa',
  'dashboard.follow_up': 'Suivi de la demande',
  'dashboard.people.DYN.pass': 'Personnes',
  'dashboard.people.DYN.zwevisa': 'Personnes enregistrées',
  'dashboard.my_account': 'Mon compte',
  'form.refunded_date': 'Date de remboursement',
  'form.enter_message': 'Entrez un message',
  'form.end_date': 'Date de fin',
  'form.start_date': 'Date de début',
  'form.search_anything': 'Rechercher n’importe quoi',
  'form.invalid_end_date': 'Date de fin invalide',
  'form.invalid_start_date': 'Date de début invalide',
  'form.id': 'ID',
  'form.enter_date_range': 'Entrez une plage de dates',
  'payments.scan': 'Scanner le QR',
  'print.qr.desc': 'Ceci est un code QR unique et sécurisé, ne peut être utilisé que le nombre de fois spécifié. Ne grattez pas pour le garder utilisable.',
  'print.qr.title': 'Code QR',
  'print.informations': 'Informations',
  'print.notice.folding': 'Instructions de pliage',
  'print.notice.desc2': 'En cas de question concernant ce document, veuillez contacter le support par téléphone ou email.',
  'print.notice.desc1': 'Gardez ce document à l’abri de l’eau ou des flammes.',
  'print.notice.title': 'Avis',
  'client.extended.openSuchPayment': 'Un paiement est déjà actif pour cet utilisateur',
  'form.choose_role': 'Choisir le rôle',
  'create_card_payment.fail_title': 'Paiement en cours',
  'create_card_payment.fail_text': 'Cela peut prendre un moment. Vous serez notifié par email une fois terminé. Le paiement et le code QR seront disponibles dans vos paiements.',
  'err.externalId.required': 'La référence de paiement est requise',
  'err.placeExternalId.required': 'ID externe requis',
  'form.externalId': 'Référence de paiement',
  'form.userExternalId': 'ID externe',
  'global.confirm': 'Confirmer',
  'global.submit': 'Soumettre',
  'global.create': 'Créer',
  'global.back': 'Retour',
  'global.close': 'Fermer',
  'global.contact_support': 'Contacter le support',
  'global.previous': 'Précédent',
  'global.next': 'Suivant',
  'global.accept': 'Accepter',
  'global.under_dev': 'En cours de développement',
  'global.logout': 'Se déconnecter',
  'global.support': 'Support',
  'global.consume': 'Consommer',
  'global.validate': 'Valider',
  'global.refund': 'Rembourser',
  'global.duplicate': 'Dupliquer',
  'global.delete': 'Supprimer',
  'global.see_more': 'Voir plus',
  'global.update': 'Mettre à jour',
  'global.modify': 'Modifier',
  'global.download': 'Télécharger',
  'global.personal': 'Personnel',
  'global.print': 'Imprimer',
  'global.print_c7': 'Imprimer le ticket',
  'global.print_a4': 'Imprimer le certificat',
  'global.checkIn': 'Enregistrement',
  'global.checkOut': 'Départ',
  'choose-lang.title': 'Préférence de langue',
  'choose-lang.desc': 'Veuillez choisir la langue qui sera utilisée pour l\'application.',
  'global.all': 'Tout',
  'global.personalNum': 'N° personnel',
  'global.passportNum': 'N° de passeport',
  'global.not_available': 'Non disponible',
  'global.show_qr': 'Code QR',
  'global.show_qr_signature': 'Code QR (signé)',
  'global.no_results_for': 'Aucun résultat trouvé pour',
  'global.delete_sure': 'Cette action est irréversible, êtes-vous sûr de vouloir supprimer cela ?',
  'global.add_new': 'Ajouter nouveau',
  'global.add': 'Ajouter',
  'global.permissions': 'Autorisations',
  'global.send': 'Envoyer',
  'global.block': 'Bloquer',
  'global.blocked': 'Bloqué',
  'global.unblock': 'Débloquer',
  'global.daily_otp': 'Code quotidien',
  'global.refresh': 'Rafraîchir',
  'global.connect': 'Connecter',
  'global.disconnect': 'Déconnecter',
  'form.email': 'Email',
  'form.name': 'Nom',
  'form.firstName': 'Prénom(s)',
  'form.lastName': 'Nom de famille',
  'form.password': 'Mot de passe',
  'form.password2': 'Retaper le mot de passe',
  'form.linkType': 'Quel est le lien de cette personne avec vous ?',
  'form.userId': 'ID utilisateur',
  'form.parentId': 'ID parent',
  'form.amount': 'Montant',
  'form.currency': 'Devise',
  'form.metadata': 'Métadonnées',
  'form.buyingOption': 'Document acheté',
  'form.place': 'Lieu',
  'form.terms': 'J’accepte les Conditions d’utilisation et la Politique de confidentialité',
  'form.role': 'Rôle',
  'form.no_data': 'Aucune donnée ne correspond au filtre',
  'form.filter': 'Filtres',
  'form.phoneNumbers': 'Numéros de téléphone',
  'form.connectUAPattern': 'Modèle d’agent utilisateur',
  'form.connectIPRange': 'Plage d’IP',
  'form.state': 'État',
  'form.type': 'Type',
  'form.operation': 'Opération',
  'form.created_date': 'Date de création',
  'form.created_place': 'Lieu de création',
  'form.consumed_place': 'Lieu consommé',
  'form.refunded_place': 'Lieu remboursé',
  'form.email_code': 'Code de l’email',
  'form.addToPlace': 'ID du lieu',
  'form.placeToAdd': 'Lieu à ajouter',
  'form.message': 'Message',
  'form.phone': 'Numéro de téléphone',
  'form.worker': 'ID employé',
  'form.camera': 'Caméra',
  'form.comment': 'Commentaire facultatif',
  'form.password_old': 'Mot de passe actuel',
  'form.password_new': 'Nouveau mot de passe',
  'form.password_new2': 'Retaper le nouveau mot de passe',
  'form.idCard': 'N° de carte d’identité',
  'form.type.CASH': 'Espèces',
  'form.type.CARD': 'Carte',
  'form.currency.EUR': 'Euro',
  'form.currency.USD': 'Dollar américain',
  'form.currency.ZAR': 'Rand',
  'form.role.customer': 'Client',
  'form.role.worker': 'Employé',
  'form.confirmed': 'Confirmé ?',
  'yes': 'Oui',
  'no': 'Non',
  'form.borderPass': 'Émettre un passage frontalier',
  'form.role.orbis_consumer': 'Consommateur Orbis',
  'form.role.admin': 'Administrateur',
  'form.choose_currency': 'Choisissez une devise',
  'form.choose_place': 'Choisissez un lieu',
  'form.choose_type': 'Choisissez un type',
  'form.choose_buyingOption': 'Choisissez un document',
  'form.choose_camera': 'Choisissez la caméra',
  'form.perm1': 'Créer un code QR',
  'form.perm2.DYN.pass': 'Consommer un code QR',
  'form.perm2.DYN.zwevisa': 'Consommer une demande de visa',
  'form.perm3.DYN.pass': 'Dupliquer / Rembourser un code QR et Z des sous-lieux',
  'form.perm3.DYN.zwevisa': 'Examiner une demande de visa',
  'form.perm4.DYN.pass': 'Créer / Gérer des utilisateurs & Créer des sous-lieux',
  'form.perm4.DYN.zwevisa': 'Créer/gérer des lieux, utilisateurs et demandes de visa',
  'done.password_changed': 'Mot de passe changé',
  'done.user_created': 'L’utilisateur a été créé',
  'done.place_created': 'Le lieu a été créé',
  'done.account_created': 'Le compte a été créé, vérifiez vos emails pour validation',
  'client.extended.clientNotAllowed': 'Votre compte n’est actuellement pas autorisé sur ce lieu. Veuillez vérifier vos paramètres de connexion.',
  'err.submit': 'Erreur lors de la soumission du formulaire',
  'err.email.required': 'L’email est requis',
  'err.email.email': 'L’email doit avoir un format correct : email@mail.com',
  'err.password.required': 'Le mot de passe est requis',
  'err.password.minlength': 'Le mot de passe doit comporter entre 6 et 20 caractères',
  'err.password.maxlength': 'Le mot de passe doit comporter entre 6 et 20 caractères',
  'err.password2.required': 'La répétition du mot de passe est requise',
  'err.password2.mustMatch': 'Les mots de passe ne correspondent pas',
  'err.role.required': 'Le rôle est requis',
  'err.name.required': 'Le nom est requis',
  'err.firstName.required': 'Le(s) prénom(s) est requis',
  'err.lastName.required': 'Le nom de famille est requis',
  'err.terms.required': 'Les conditions générales doivent être acceptées',
  'err.amount.required': 'Le montant est requis',
  'err.currency.required': 'La devise est requise',
  'err.metadata.required': 'Les métadonnées sont requises',
  'err.place.required': 'Le lieu est requis',
  'err.noSmsPerm': 'Le droit d’envoyer des SMS n’a pas été accordé',
  'err.noSmsReceive': 'Impossible de se lier à la lecture des SMS',
  'err.code.required': 'Le code de l’email est requis',
  'err.buyingOption.required': 'L’option d’achat est requise',
  'err.addToPlace.required': 'L’ID du lieu est requis',
  'err.phone.required': 'Le numéro de téléphone est requis',
  'err.message.required': 'Le message est requis',
  'err.text.maxlength': 'Le texte est trop long',
  'err.old.required': 'Le mot de passe actuel est requis',
  'err.idCard.required': 'Le numéro de carte d’identité est requis',
  'helps.showProceed': 'Montrer la procédure',
  'helps.showScan': 'Montrer le document à scanner',
  'helps.captureFingerprint': 'Capturer l’empreinte digitale',
  'payment_status.DYN.undefined': 'État actuel - aucune opération',
  'client.extended.missingResourceLogin': 'Impossible de se connecter, identifiants incorrects',
  'client.extended.linkedAccountExists': 'Un compte avec cet email existe déjà',
  'client.extended.attachedPayments': 'Vous ne pouvez pas modifier cette personne, elle a déjà une demande liée à elle.',
  'client.extended.badPayload': 'Mauvaises informations dans la requête',
  'client.extended.placeNotAllowed': 'Le lieu n’est pas autorisé',
  'client.extended.locked': 'Le compte est verrouillé',
  'client.extended.clientBadOtp': 'Mauvais code quotidien',
  'time': 'Heure',
  'place': 'Lieu',
  'home.first': 'Votre compte est maintenant validé et vous pouvez vous connecter.',
  'payOnline': 'Payer en ligne',
  'login.sign_in': 'Se connecter',
  'login.sign_up': 'S’inscrire',
  'login.forgot_password': 'Mot de passe oublié ?',
  'photo.entryCountry': 'Photo à l’enregistrement/départ',
  'photo.unavailable': 'Photo indisponible',
  'global.entry': 'Entrée',
  'global.exit': 'Sortie',
  'menu.create_user': 'Utilisateur +',
  'menu.create_place': 'Lieu +',
  'menu.create_payment': 'Paiement +',
  'menu.users': 'Utilisateurs',
  'menu.places': 'Lieux',
  'menu.payments': 'Paiements',
  'menu.about': 'À propos',
  'menu.contact': 'Contact',
  'create_user.title': 'Créer un utilisateur',
  'create_payment.title': 'Créer un paiement',
  'create_place.title': 'Créer un lieu',
  'users.title': 'Base de données des utilisateurs',
  'payments.title': 'Base de données des paiements',
  'payments.employee_graph': 'Graphique des paiements par employé',
  'payments.open_scan': 'Scanner le code QR',
  'payments.download_csv': 'Télécharger le CSV',
  'create_card_payment.title': 'Paiement en ligne',
  'create_card_payment.success_title': 'Paiement reçu !',
  'create_card_payment.success_text': 'Votre paiement a été reçu avec succès. Votre code QR vous a été envoyé par email et est également accessible en cliquant sur le bouton ci-dessous.',
  'create_card_payment.no_data': 'Ce paiement n’est plus disponible ici, consultez vos emails pour récupérer le code QR ou cliquez sur Paiements dans le menu',
  'places.title': 'Liste des lieux',
  'forgot_password.title': 'Réinitialiser le mot de passe',
  'forgot_password.title_new': 'Définir un nouveau mot de passe',
  'forgot_password.sent': 'Confirmation envoyée !',
  'forgot_password.sent_text': 'Un email de confirmation a été envoyé avec un lien pour réinitialiser votre mot de passe.',
  'forgot_password.done': 'Mot de passe réinitialisé',
  'forgot_password.done_text': 'Vous avez réinitialisé votre mot de passe avec succès. Vous pouvez maintenant vous connecter.',
  'welcome.first': 'Votre compte est validé !',
  'welcome.title_1.DYN.pass': 'Bonjour !',
  'welcome.text_1.DYN.pass': 'Merci de nous rejoindre chez {appName}. Nous sommes fiers de vous offrir le meilleur moyen de convertir votre paiement en un code QR sécurisé et unique.',
  'welcome.text_1.DYN.zwevisa': 'Merci de nous rejoindre chez {appName}. Nous sommes fiers de vous offrir le meilleur moyen de demander un visa en ligne.',
  'welcome.text_1.1.DYN.pass': 'Apprenons-en un peu plus sur nous, d’accord ?',
  'welcome.text_1.1.DYN.zwevisa': 'Apprenons-en un peu plus sur nous, d’accord ?',
  'welcome.title_2.DYN.pass': 'Qu’est-ce que {appName} ?',
  'welcome.text_2.DYN.pass': '{appName} vous permet de soumettre un paiement en ligne en échange d’un code QR sécurisé et unique.',
  'welcome.text_2.DYN.zwevisa': '{appName} vous permet de demander un visa en ligne et de le recevoir par email ainsi qu’un code QR sécurisé et unique contenant toutes les informations nécessaires pour votre visa.',
  'welcome.text_2.1.DYN.pass': 'Ce code est ensuite utilisable pour payer un document officiel tel qu’un document, une carte d’identité, etc. dans votre administration locale.',
  'welcome.text_2.1.DYN.zwevisa': 'Ce code est ensuite utilisable comme preuve de votre demande de visa approuvée à la frontière.',
  'welcome.title_3': 'En tant qu’employé',
  'welcome.text_3.DYN.pass': 'Avec {appName}, un client peut venir vous voir pour payer un document officiel. Vous pouvez enregistrer le paiement par carte ou en espèces.',
  'welcome.text_3.DYN.zwevisa': 'Avec {appName}, vous pouvez enregistrer et sortir des personnes à la frontière. Vous pouvez également approuver ou refuser des demandes de visa en fonction de la situation et de vos autorisations.',
  'welcome.text_3.1.DYN.pass': 'Un code QR sécurisé et unique est ensuite généré et prêt à être imprimé pour votre client.',
  'welcome.text_3.1.DYN.zwevisa': 'Vous pouvez également gérer les utilisateurs et les lieux de votre organisation.',
  'welcome.title_4.DYN.pass': 'Encore mieux',
  'welcome.text_4.DYN.pass': 'Vous pouvez même enregistrer des paiements hors ligne. Nous avons confiance en notre manière sûre et fiable de générer nos codes QR.',
  'welcome.text_4.DYN.zwevisa': 'Vous pouvez même gérer les demandes de visa hors ligne. Nous avons confiance en notre manière sûre et fiable de générer nos codes QR.',
  'welcome.text_4.1.DYN.pass': 'Dès que vous serez de retour en ligne, tout sera à nouveau synchronisé.',
  'welcome.text_4.1.DYN.zwevisa': 'Dès que vous serez de retour en ligne, tout sera à nouveau synchronisé.',
  'welcome.title_5.DYN.pass': 'Profitez-en !',
  'welcome.text_5.DYN.pass': 'Merci d’utiliser {appName} et n’hésitez pas à nous envoyer vos commentaires en utilisant le bouton Contact sur la page d’accueil.',
  'payment_status.DYN.zwevisa.PROPOSED': 'Paiement en attente',
  'payment_status.DYN.zwevisa.PAID': 'Prêt à l’enregistrement',
  'payment_status.DYN.zwevisa.PAID_NOT_CONFIRMED': 'En attente de révision',
  'payment_status.DYN.zwevisa.USED': 'Sortie effectuée',
  'payment_status.DYN.zwevisa.DUPLICATED': 'Dupliqué',
  'payment_status.DYN.zwevisa.REFUNDED': 'Remboursé',
  'payment_status.DYN.zwevisa.EXPIRED': 'Refusé',
  'payment_status.DYN.zwevisa.PENDING': 'En attente de correction',
  'payment_status.DYN.zwevisa.DELAY_PAY_NOT_CONFIRMED': 'Payer plus tard - Non confirmé',
  'payment_status.DYN.zwevisa.DELAY_PAY_NOT_CONFIRMED.FREE': 'Gratuit - Non confirmé',
  'payment_status.DYN.zwevisa.DELAY_PAY': 'Prêt à payer à l’arrivée',
  'payment_status.DYN.pass.PROPOSED': 'Proposé',
  'payment_status.DYN.pass.PAID': 'Payé',
  'payment_status.DYN.pass.PAID_NOT_CONFIRMED': 'Non confirmé',
  'payment_status.DYN.pass.USED': 'Utilisé',
  'payment_status.DYN.pass.DUPLICATED': 'Dupliqué',
  'payment_status.DYN.pass.REFUNDED': 'Remboursé',
  'payment_status.DYN.pass.EXPIRED': 'Expiré',
  'payments.make_bundle': 'Créer un lot',
  'payments.validate': 'Confirmer',
  'passportPage.askPassport': 'Demande de passeport',
  'documentContinue.title': 'Continuer vos achats',
  'documentContinue.stop': 'Refaire votre achat',
  'documentContinue.desc1': 'Vous avez déjà commencé le processus de demande d’un document.',
  'documentContinue.desc2': 'Souhaitez-vous continuer votre achat ?',
  'payment.history': 'Historique des paiements',
  'permission.no_place_found': 'Aucun nouveau lieu disponible pour cet utilisateur',
  'bt.available_devices': 'Appareils Bluetooth disponibles',
  'bt.no_available_devices': 'Aucun appareil Bluetooth disponible détecté',
  'emailSend.title': 'Vérification de l’email',
  'emailSend.description': 'Pour valider votre compte, vous devez cliquer sur le lien contenu dans un email de validation envoyé à votre adresse email dans les 24 heures.',
  'emailValid.title': 'Compte validé',
  'emailValid.description': 'Votre compte {appName} a été validé avec succès. Vous pouvez maintenant accéder à votre espace personnel en vous connectant avec vos identifiants.',
  'documentManagement.title': 'Demande de visa',
  'passportPage.select': 'Sélectionnez les personnes qui souhaitent demander un passeport.',
  'passportPage.choosePerson': 'Sélectionnez le(s) demandeur(s)',
  'preenroll.title': 'Pré-inscription',
  'preenroll.description': 'La pré-inscription vous permet de commencer la première étape avant de finaliser votre document. Ensuite, vous n’aurez plus qu’à vous rendre dans un centre agréé pour le valider.',
  'passportEmbassy.title': 'Document de l’ambassade',
  'passportEmbassy.description': 'Un passeport d’ambassade est un document délivré par un État souverain à ses diplomates pour leurs déplacements professionnels, ainsi qu’aux membres accompagnants.',
  'zwevisa.title': 'Visa',
  'passwordSend.title': 'Confirmation envoyée',
  'passwordSend.description': 'Vous avez reçu un email de confirmation avec un lien pour réinitialiser votre mot de passe. Vous avez 24 heures pour cliquer dessus.',
  'forgotPassword.title': 'Mot de passe oublié',
  'forgotPassword.description': 'Pour réinitialiser votre mot de passe, entrez votre email ci-dessous.',
  'forgotPassword.description.electron': 'Pour réinitialiser votre mot de passe, entrez votre email de compte ci-dessous. Si vous essayez de récupérer un mot de passe pour un compte <b>annuaire actif</b>, veuillez contacter le support.',
  'passwordNew.title': 'Nouveau mot de passe',
  'modifiedPassword.title': 'Mot de passe changé',
  'modifiedPassword.description': 'Le mot de passe a été changé. Veuillez vous reconnecter.',
  'people.title.DYN.pass': 'Personnes',
  'people.title.DYN.zwevisa': 'Personnes enregistrées',
  'people.addPerson.DYN.pass': 'Ajouter une personne',
  'people.addPerson.DYN.zwevisa': 'Créer une nouvelle personne',
  'meansPayment.title': 'Moyens de paiement',
  'onlinePayment.title': 'Paiement PayPal',
  'onlinePayment.description': 'Finalisez votre paiement en ligne avec votre carte de crédit.',
  'onlinePayment.pay': 'Payer',
  'qrPayment.title': 'Paiement par code QR',
  'qrPayment.description': 'Pour compléter votre paiement, scannez un code QR valide.',
  'qrPayment.scan': 'Scanner le code QR',
  'processPayment.description': 'Pour effectuer un paiement, entrez les informations de votre carte de crédit.',
  'form.cardNumber': 'Numéro de carte',
  'form.expireDate': 'Date d’expiration',
  'successPayment.title': 'Confirmation de commande',
  'successPayment.description1': 'Ce paiement a été effectué. Le document est en cours de traitement, et vous serez notifié lorsqu’il sera prêt ou s’il doit être révisé.',
  'successPayment.description2': 'Veuillez visiter le Support depuis la page de votre compte si vous ne recevez pas de notification.',
  'trackingControl.title.DYN.pass': 'Suivi de la demande',
  'trackingControl.title.DYN.zwevisa': 'Suivre cette demande',
  'trackingControl.enroll': 'Inscription prête',
  'trackingControl.treatment': 'En cours de traitement',
  'modifyData': 'Modifier les données',
  'stopModifyData': 'Arrêter de modifier les données',
  'trackingControl.revision': 'Demande de révision',
  'askRevisionProcess': 'Le traitement est en cours',
  'askRevision': 'Révision demandée',
  'seeRevision': 'Voir la révision',
  'askOrdinaryPassport': 'Demande de document ordinaire',
  'askPassport': 'Demander un document',
  'askVisa': 'Demander un visa',
  'actualRevision': 'Révision de la demande',
  'askRevision.description': 'Pour traiter votre demande de passeport ordinaire, veuillez nous envoyer une copie de votre certificat de résidence actuel.',
  'peopleNew.title.DYN.pass': 'Nouvelles personnes',
  'peopleNew.title.DYN.zwevisa': 'Nouvelle personne',
  'peopleFound.title.DYN.pass': 'Personne trouvée',
  'peopleFound.title.DYN.zwevisa': 'Personne trouvée',
  'peopleFound.description.DYN.pass': 'La personne correspondant aux informations que vous avez saisies a été ajoutée à la liste des personnes enregistrées.',
  'peopleFound.description.DYN.zwevisa': 'La personne correspondant aux informations que vous avez saisies a été ajoutée à la liste des personnes enregistrées.',
  'payment.commandHistory': 'Historique des paiements',
  'menu.placesPerm': 'Lieux et autorisations',
  'block.message': 'Souhaitez-vous bloquer cet utilisateur ?',
  'menu.addPlace': 'Ajouter un lieu',
  'label.numberAccount': 'N° de compte',
  'place.sheet.open': 'Ouvrir la fiche du lieu',
  'place.delete': 'Supprimer le lien avec ce lieu',
  'place.update.success': 'Le lieu a été mis à jour avec succès.',
  'place.new': 'Nouveau lieu',
  'place.create': 'Créer un lieu',
  'place.add.ask': 'Souhaitez-vous lier ce lieu à cet utilisateur ?',
  'place.add.ok': 'Vous avez lié ce lieu à cet utilisateur avec succès.',
  'place.delete.link': 'Supprimer le lien avec ce lieu',
  'place.delete.link.ask': 'Êtes-vous sûr de vouloir délier ce lieu ?',
  'place.delete.link.done': 'Vous avez supprimé le lien avec le lieu avec succès.',
  'user.new': 'Nouvel utilisateur',
  'user.create': 'Créer l’utilisateur',
  'user.created': 'La création du nouvel utilisateur a été réussie. Ce compte peut maintenant être utilisé.',
  'user.sheet.open': 'Ouvrir la fiche de l’utilisateur',
  'payment.sheet.open': 'Ouvrir la fiche de paiement',
  'err.createplace': 'Impossible de créer. L’ID existe déjà.',
  'place.parent': 'Lieu parent',
  'dropdown.sorting': 'Trier',
  'dropdown.qr': 'Scanner un code QR',
  'dropdown.pass': 'Scanner un document',
  'err.noReader': 'Le lecteur n’est pas connecté. Veuillez connecter le lecteur pour continuer.',
  'err.noCard': 'Aucun document biométrique valide détecté dans le lecteur. Cela peut être dû à l’utilisation d’un document non biométrique, à la présentation d’une copie non autorisée ou contrefaite, ou à un dysfonctionnement de l’antenne de lecture.',
  'err.noConnect': 'Ce document ne répond pas aux protocoles standard de l’ICAO. Cela peut être dû à l’utilisation d’un document non biométrique, à la présentation d’une copie non autorisée ou contrefaite, ou à un dysfonctionnement de l’antenne de lecture.',
  'err.noBAC': 'Le canal sécurisé vers le document n’a pas pu être établi, est-il authentique ? Vérifiez ce document manuellement et interrogez le titulaire.',
  'err.alteredDoc': 'Les champs du document n’ont pas pu être lus, est-il authentique ? Vérifiez ce document manuellement et interrogez le titulaire.',
  'err.revokedDocSigner': 'Le signataire de ce document a été révoqué par son autorité émettrice. Ce document pourrait avoir été signé de manière frauduleuse. Interrogez le titulaire de manière approfondie à ce sujet.',
  'err.unverifiedDoc': 'La validité du document n’a pas pu être vérifiée, erreur dans le protocole d’authentification, cela pourrait être un signe d’un document émis hors du pays selon la liste maître de l’ICAO, un document contrefait, ou un dysfonctionnement de l’antenne de lecture.',
  'err.noPassiveAuthentication': 'La validité du document n’a pas pu être vérifiée, erreur dans le protocole d’authentification, un document contrefait ou un dysfonctionnement de l’antenne de lecture.',
  'err.noResult': 'Aucun fichier trouvé',
  'dropdown.user': 'Utilisateur',
  'dropdown.meansPayment': 'Types de paiements',
  'payment.new.DYN.pass': 'Nouveau paiement',
  'payment.new.DYN.zwevisa': 'Nouvelle sortie sans visa',
  'payment.create': 'Créer un paiement',
  'payment.created.register': 'Paiement enregistré',
  'payment.created': 'Votre paiement a été enregistré et est maintenant visible dans la liste des paiements.',
  'payment.created.qr': 'Un email contenant le code QR généré a été envoyé à l’adresse email fournie.',
  'text.status': 'Statut',
  'phone.number': 'Numéro de téléphone',
  'idcard.number': 'Numéro de carte d’identité',
  'form.userCreator': 'Créé par l’utilisateur',
  'form.userRefund': 'Remboursé par l’utilisateur',
  'form.userConsumed': 'Consommé par l’utilisateur',
  'PRICEUP': 'Prix 0-9',
  'PRICEDOWN': 'Prix 9-0',
  'RECENT': 'Le plus récent',
  'LATEST': 'Le plus ancien',
  'STATUSUP': 'Statut a-z',
  'STATUSDOWN': 'Statut z-a',
  'payment.success': 'Votre paiement a été enregistré',
  'err.meansPayment.required': 'Méthode de paiement requise',
  'payment.consume.ask.DYN.pass': 'Voulez-vous vraiment consommer ce paiement ?',
  'payment.consume.ask.DYN.zwevisa': 'Voulez-vous vraiment enregistrer/sortir cette demande de visa ?',
  'payment.consume.done.DYN.pass': 'Votre paiement a été consommé avec succès',
  'payment.consume.done.DYN.zwevisa': 'Votre demande de visa a été enregistrée/sortie avec succès',
  'payment.consume.refuse.DYN.zwevisa': 'Cette demande de visa a été refusée avec succès.',
  'checkinOut.refuse': 'Voulez-vous vraiment refuser cette demande ?',
  'payment.duplicate.ask': 'Voulez-vous vraiment dupliquer ce paiement ?',
  'payment.duplicate.done': 'Votre paiement a été dupliqué avec succès',
  'payment.refund.ask': 'Voulez-vous vraiment rembourser ce paiement ?',
  'payment.refund.done': 'Votre paiement a été remboursé avec succès',
  'payment.refund.exist': 'Ce paiement a déjà été remboursé',
  'payment.paid.error': 'Votre paiement doit avoir le statut "Complet"',
  'unlink.place.error': 'Vous devez avoir au moins un lieu actif pour délier celui-ci. Veuillez ajouter un autre lieu d’abord',
  'block.done': 'Le compte a été bloqué',
  'block.unblock': 'Le compte a été débloqué',
  'dailyOtp.txt': 'Code de sécurité quotidien',
  'place-add.add': 'Ajout du lieu',
  'permission.title': 'Autorisation requise : Caméra',
  'qr.see': 'Voir le code QR',
  'qr.desc.DYN.pass': 'Voici le code QR pour le paiement. Il apparaîtra lors d’une impression et sera envoyé à l’adresse email saisie lors de l’inscription.',
  'qr.desc.DYN.zwevisa': 'Voici le code QR pour la demande. Il apparaîtra lors d’une impression et sera envoyé à l’adresse email saisie lors de l’inscription.',
  'global.print.a4': 'Imprimer le document A4',
  'dashboard.place.verif': 'Si vous n’avez pas entré de lieu, veuillez le faire',
  'uptodate': 'Vous êtes à jour',
  'block.yourself': 'Vous ne pouvez pas vous bloquer vous-même',
  'lastPlace.delete': 'Le dernier lieu de ce compte est sur le point d’être supprimé. Cela rendra ce compte inutilisable. Continuer ?',
  'place.add.done': 'Ce lieu a été ajouté à cet utilisateur avec succès',
  'notallow.payment': 'Vous n’avez pas les droits pour créer un paiement',
  'no.connection': 'Pour accéder à cette page, vous devez être connecté à Internet. Veuillez vous reconnecter',
  'place.delete.success': 'Ce lieu a été supprimé.',
  'wrong.current': 'Vous avez saisi un mot de passe actuel incorrect',
  'no.place': 'Aucun lieu pour ce compte.',
  'no.payment': 'Aucun paiement ne correspond au filtre actuel',
  'email.error': 'Un email similaire existe déjà',
  'email.notexist': 'L’email n’existe pas',
  'one.payment': 'Vous devez avoir au moins un paiement créé et/ou filtré.',
  'err.client.extended.uuidUsed': 'Ce paiement a déjà été consommé',
  'no.activecamera': 'Aucune caméra active trouvée',
  'no.accessPermission': 'Ce compte n’a pas d’autorisation d’accès',
  'download.success': 'Votre fichier a été téléchargé dans le dossier Téléchargements',
  'print.name': 'Imprimante',
  'print.success': 'Votre ticket a été imprimé',
  'print.need': 'Vous devez d’abord vous connecter à une imprimante pour pouvoir imprimer',
  'printer.connect': 'Vous êtes maintenant connecté à l’imprimante :',
  'permission.desc': 'Le service que vous souhaitez utiliser nécessite une autorisation pour utiliser la caméra de votre appareil',
  'err.personnalNR.required': 'Numéro personnel requis',
  'err.passportNR.required': 'Numéro de passeport requis',
  'createPeople.ask.DYN.pass': 'Voulez-vous vraiment créer cette personne ?',
  'createPeople.ask.DYN.zwevisa': 'Voulez-vous vraiment créer cette nouvelle personne ?',
  'createPeopleDuplicate.ask.DYN.zwevisa': 'Il semble qu\'une personne portant le même nom existe déjà dans vos personnes enregistrées. Voulez-vous quand même créer cette personne ?',
  'createPeople.done.DYN.pass': 'La personne a été créée',
  'createPeople.done.DYN.zwevisa': 'La personne a été créée',
  'deletePerson.DYN.pass': 'Supprimer cette personne',
  'deletePerson.DYN.zwevisa': 'Supprimer cette personne',
  'deletePerson.ask.DYN.pass': 'Êtes-vous sûr de vouloir supprimer cette personne ?',
  'deletePerson.ask.DYN.zwevisa': 'Êtes-vous sûr de vouloir supprimer cette personne ?',
  'deletePerson.valid.DYN.pass': 'La personne a été supprimée',
  'deletePerson.valid.DYN.zwevisa': 'La personne a été supprimée',
  'personModification.ask.DYN.pass': 'Êtes-vous sûr de vouloir modifier les données de cette personne ?',
  'personModification.ask.DYN.zwevisa': 'Êtes-vous sûr de vouloir modifier les données de cette personne ?',
  'personModification.valid.DYN.pass': 'Les données de la personne ont été modifiées',
  'personModification.valid.DYN.zwevisa': 'Les données de la personne ont été modifiées',
  'noPayment': 'Aucun paiement',
  'selfie.desc': 'Une photo est requise pour la demande de document, assurez-vous d\'être bien éclairé et de tenir votre téléphone stable pour éviter une image floue. Veillez également à ce que tous les détails de votre visage soient clairement visibles sur la photo.',
  'selfie.take': 'Prendre la photo',
  'ZWEVISA.title': 'Visa',
  'EMBASSYPASS.title': 'Passeport d\'ambassade',
  'PREENROLL.title': 'Pré-inscription',
  'pay.temp': 'Paiement actuellement indisponible, veuillez choisir la pré-inscription',
  'OK': 'Demande acceptée. Prêt pour l\'inscription',
  'OK_MODIFIED': 'Demande acceptée. Traitement en cours.',
  'DYN.NOK': 'Demande refusée',
  'NOK_TO_MODIFY': 'Demande refusée. Action nécessaire',
  'PROCESSING': 'En cours de traitement',
  'form.merchantReference': 'Référence commerçant',
  'form.pan': 'Numéro de carte',
  'err.MerchantReference.required': 'Référence commerçant requise',
  'err.PAN.required': 'Numéro de carte requis',
  'err.ExpiryDate.required': 'Date d\'expiration requise',
  'WIP': 'Travail en cours',
  'PAYMENT_CREATED': 'Paiement créé',
  'PAYMENT_VALIDATED': 'Paiement validé',
  'menu.requests.DYN.zwevisa': 'Demandes',
  'menu.createRequests.DYN.zwevisa': 'Nouvelle demande',
  'menu.requests.DYN.pass': 'Requêtes',
  'menu.createRequests.DYN.pass': 'Nouvelle requête',
  'no.data': 'Aucune donnée ne correspond au filtre actuel',
  'dropdown.requestState': 'Statut de la demande',
  'dropdown.paymentState': 'Statut du paiement',
  'one.request': 'Vous devez avoir au moins une demande visible.',
  'request_state.SUBMITTED': 'Soumise',
  'request_state.CONFIRMED': 'Confirmée',
  'request_state.TREATED': 'Traitée',
  'request_state.TRANSFERRED': 'Transférée',
  'request_state.CANCELLED': 'Annulée',
  'request_state.REFUSED': 'Refusée',
  'request_state.UNUSABLE': 'Inutilisable',
  'request_state.NULL': 'Non payée',
  'request_state.NONE': '-- Aucun filtre --',
  'getPayment.error': 'Une erreur est survenue lors de la récupération du paiement.',
  'adminPaymentResult.tilte': 'Résultat du paiement',
  'ask.disconnect': 'Êtes-vous sûr de vouloir vous déconnecter ?',
  'global.yes': 'Oui',
  'zwevisa.type.holiday': 'Vacances',
  'zwevisa.type.business': 'Affaires',
  'zwevisa.type.conferencing': 'Conférence',
  'zwevisa.type.transit': 'Transit',
  'zwevisa.type.student': 'Études',
  'zwevisa.entry.single': 'Entrée unique',
  'zwevisa.entry.double': 'Double entrée',
  'zwevisa.entry.kaza': 'Visa Kaza Univisa',
  'applicant.gender.male': 'Homme',
  'applicant.gender.female': 'Femme',
  'applicant.gender.other': 'Autre',
  'marital.status.single': 'Célibataire',
  'marital.status.married': 'Marié(e)',
  'marital.status.separated': 'Séparé(e)',
  'marital.status.divorced': 'Divorcé(e)',
  'marital.status.widowed': 'Veuf/Veuve',
  'form.search': 'Rechercher',
  'role.customer': 'Client',
  'role.partner_registrer': 'Partenaire registraire',
  'request_state.PROPOSED': 'En attente de paiement',
  'request_state.PAID': 'Complet',
  'request_state.USED': 'Fermé',
  'request_state.DUPLICATED': 'Dupliqué',
  'request_state.REFUNDED': 'Remboursé',
  'request_state.EXPIRED': 'Refusé',
  'request_state.PAID_NOT_CONFIRMED': 'En attente de révision',
  'request_state.DELAY_PAY_NOT_CONFIRMED': 'Payer plus tard - Non confirmé',
  'request_state.DELAY_PAY': 'Prêt à payer à l\'arrivée',
  'payment_status.DYN.zwevisa.0': 'En attente de paiement',
  'payment_status.DYN.zwevisa.1': 'Prêt pour l\'enregistrement',
  'payment_status.DYN.zwevisa.6': 'En attente de révision',
  'payment_status.DYN.zwevisa.2.9': 'En attente de supervision',
  'payment_status.DYN.zwevisa.2.10': 'Supervisé',
  'payment_status.desc.DYN.zwevisa.2.10': 'La demande a été examinée par un superviseur et est prête à être traitée par l\'agent de contrôle des frontières.',
  'payment_status.desc.DYN.zwevisa.2.9': 'La demande est en attente de supervision. Un superviseur peut devoir examiner la demande avant qu\'elle ne puisse être traitée par l\'agent de contrôle des frontières.',
  'payment_status.DYN.zwevisa.7': 'En attente de correction',
  'payment_status.DYN.zwevisa.8': 'Payer plus tard - Non confirmé',
  'payment_status.DYN.zwevisa.9': 'Prêt à payer à l\'arrivée',
  'payment_status.DYN.zwevisa.9.entry': 'Prêt pour l\'enregistrement',
  'payment_status.DYN.zwevisa.9.exit': 'Prêt pour la sortie',
  'payment_status.DYN.zwevisa.2': 'Sortie effectuée',
  'payment_status.DYN.zwevisa.3': 'Dupliqué',
  'payment_status.DYN.zwevisa.4': 'Remboursé',
  'payment_status.DYN.zwevisa.5': 'Refusé',
  'payment_status.extension.DYN.zwevisa.5': 'Extension refusée',
  'payment_status.DYN.zwevisa.5.blacklisted': 'Refusé - Liste noire',
  'payment_status.DYN.pass.0': 'Proposé',
  'payment_status.DYN.pass.1': 'Payé',
  'payment_status.DYN.pass.6': 'Non confirmé',
  'payment_status.DYN.pass.2': 'Utilisé',
  'payment_status.DYN.pass.3': 'Dupliqué',
  'payment_status.DYN.pass.4': 'Remboursé',
  'payment_status.DYN.pass.5': 'Expiré',
  'treatmentDone': 'Traitement terminé. Révision demandée',
  'sendRevision': 'Révision envoyée',
  'enrolReady': 'Demande acceptée. Prêt pour l\'inscription',
  'err.personalNr.required': 'Numéro personnel requis',
  'err.passportNr.required': 'Numéro de passeport requis',
  'createPeople.success.DYN.pass': 'Personne créée avec succès',
  'createPeople.success.DYN.zwevisa': 'Personne créée avec succès',
  'request.0': 'Demande acceptée. Prêt pour l\'inscription',
  'request.1': 'Demande acceptée. Traitement en cours',
  'request.2': 'Demande refusée',
  'request.3': 'Demande refusée. Action requise',
  'request.4': 'Traitement en cours',
  'adminRequestResult.title.DYN.pass': 'Demande sélectionnée - {paymentID}',
  'adminRequestResult.title.DYN.zwevisa': 'Demande sélectionnée - {paymentID}',
  'form.requestState': 'Statut de la demande',
  'form.paymentType': 'Type de paiement',
  'form.paymentState': 'Statut du paiement',
  'payment.type.PHYSICAL': 'Physique',
  'payment.type.PAYPAL': 'PayPal',
  'payment.type.WU': 'Western Union',
  'payment.type.MNTA': 'CBZ',
  'payment.type.online': 'En ligne',
  'payment.type.offline': 'Hors ligne',
  'request.state.DYN.pass': 'Statut de la requête',
  'request.state.DYN.zwevisa': 'Statut de la demande',
  'payment.continue': 'Continuer le paiement',
  'form.birthDate': 'Date de naissance',
  'form.birthCountry': 'Pays de naissance',
  'form.placeOfBirth': 'Ville de naissance',
  'form.residenceAdress': 'Adresse de résidence',
  'form.marialStatus': 'État civil',
  'form.dateOfIssue': 'Date de délivrance du passeport',
  'form.dateOfExpiry': 'Date d\'expiration du passeport',
  'form.placeOfIssue': 'Lieu de délivrance',
  'form.departureDate': 'Date de départ',
  'form.arrivalDate': 'Date d\'arrivée',
  'form.referencePreviousVisa': 'Référence du visa précédent',
  'form.passportNumber': 'Numéro de passeport',
  'form.passportType': 'Type de passeport',
  'form.hostName': 'Nom de l\'hôte',
  'form.purposeOfVisit': 'Raison de la visite',
  'form.previousConvictionsHome': 'Condamnations antérieures dans le pays d\'origine',
  'form.spouse': 'Conjoint(e)',
  'form.SpousePlaceOfBirth': 'Lieu de naissance du conjoint(e)',
  'form.spouse.birthDay': 'Date de naissance du conjoint(e)',
  'form.spousePassportNumber': 'Numéro de passeport du conjoint(e)',
  'form.spousePlaceOfBirth': 'Lieu de naissance du conjoint(e)',
  'form.dependants': 'Personnes à charge',
  'form.dependantFullName': 'Nom complet de la personne à charge',
  'form.dependantPassportNumber': 'Numéro de passeport de la personne à charge',
  'form.dependantPlaceOfBirth': 'Lieu de naissance de la personne à charge',
  'form.dependantBirthDay': 'Date de naissance de la personne à charge',
  'global.approve': 'Approuver',
  'global.refuse': 'Refuser',
  'global.suspend': 'Demander une révision',
  'global.unprocess': 'Annuler le traitement',
  'personal.data': 'Données personnelles',
  'contact.data': 'Données de contact',
  'divers.data.DYN.zwevisa': 'Détails du voyage',
  'divers.data.DYN.pass': 'Détails de la demande',
  'dependant.data': 'Données des personnes à charge',
  'spouse.data': 'Données du conjoint(e)',
  'paymentState.0': 'En attente de paiement',
  'paymentState.1': 'Enregistrement/Sortie',
  'paymentState.2': 'Fermé',
  'paymentState.3': 'Dupliqué',
  'paymentState.4': 'Remboursé',
  'paymentState.5': 'Refusé',
  'paymentState.6': 'Payé - Non confirmé',
  'payment.refuse.ask.DYN.pass': 'Voulez-vous vraiment <b>REFUSER</b> ce paiement ?',
  'payment.refuse.ask.DYN.zwevisa': 'Voulez-vous vraiment <b>REFUSER</b> cette demande de visa ?',
  'payment.refuse.ask.extension.DYN.zwevisa': 'Voulez-vous vraiment <b>REFUSER</b> cette demande d\'extension ?',
  'payment.approve.ask.DYN.pass': 'Voulez-vous vraiment <b>APPROUVER</b> ce paiement ?',
  'payment.approve.ask.DYN.zwevisa': 'Voulez-vous vraiment <b>APPROUVER</b> cette demande de visa ?',
  'payment.approve.ask.extension.DYN.zwevisa': 'Voulez-vous vraiment <b>APPROUVER</b> cette demande d\'extension ?',
  'payment.review.approve.ask.DYN.zwevisa': 'Voulez-vous vraiment examiner cette demande de visa comme <b>RECOMMANDÉE</b> ?',
  'payment.review.approve.success.DYN.zwevisa': 'Cette demande de visa a été examinée comme <b>RECOMMANDÉE</b>.',
  'payment.review.approve.success.extension.DYN.zwevisa': 'Cette demande d\'extension a été examinée comme <b>RECOMMANDÉE</b>.',
  'payment.review.refuse.ask.DYN.zwevisa': 'Voulez-vous vraiment examiner cette demande de visa comme <b>NON RECOMMANDÉE</b> ?',
  'payment.review.refuse.ask.extension.DYN.zwevisa': 'Voulez-vous vraiment examiner cette demande d\'extension comme <b>NON RECOMMANDÉE</b> ?',
  'payment.review.refuse.success.DYN.zwevisa': 'Cette demande de visa a été examinée comme <b>NON RECOMMANDÉE</b>.',
  'payment.review.refuse.success.extension.DYN.zwevisa': 'Cette demande d\'extension a été examinée comme <b>NON RECOMMANDÉE</b>.',
  'payment.review.approve.extension.ask.DYN.zwevisa': 'Voulez-vous vraiment <b>APPROUVER</b> cette demande d\'extension ?',
  'payment.validate.ask.DYN.pass': 'Voulez-vous vraiment <b>APPROUVER</b> ce paiement ?',
  'payment.validate.ask.DYN.zwevisa': 'Voulez-vous vraiment <b>APPROUVER</b> cette demande de visa ?',
  'payment.validate.extension.ask.DYN.zwevisa': 'Voulez-vous vraiment <b>APPROUVER</b> cette demande d\'extension ?',
  'payment.validate.review.ask.DYN.pass': 'Voulez-vous vraiment <b>APPROUVER</b> ce paiement ? Une demande de révision sera envoyée au prochain réviseur',
  'payment.validate.review.ask.DYN.zwevisa': 'Voulez-vous vraiment <b>RECOMMANDER</b> cette demande de visa ? Une demande de révision sera envoyée au prochain réviseur',
  'payment.refuse.success.DYN.pass': 'Paiement <b>REFUSÉ</b> avec succès.',
  'payment.refuse.success.DYN.zwevisa': 'Demande de visa <b>REFUSÉE</b> avec succès.',
  'payment.refuse.success.extension.DYN.zwevisa': 'Demande d\'extension <b>REFUSÉE</b> avec succès.',
  'payment.approve.success.DYN.pass': 'Paiement <b>APPROUVÉ</b> avec succès.',
  'payment.approve.success.DYN.zwevisa': 'Demande de visa <b>APPROUVÉE</b> avec succès. Le client sera notifié par email.',
  'payment.approve.success.extension.DYN.zwevisa': 'Demande d\'extension <b>APPROUVÉE</b> avec succès.',
  'payment.suspend.success.DYN.pass': 'Paiement <b>SUSPENDU</b> avec succès. Une demande de révision a été envoyée au client.',
  'payment.suspend.success.DYN.zwevisa': 'Demande de visa <b>SUSPENDUE</b> avec succès. Une demande de révision a été envoyée au client.',
  'payment.validate.success.DYN.pass': 'Paiement <b>APPROUVÉ</b> avec succès.',
  'payment.validate.success.DYN.zwevisa': 'Demande de visa <b>APPROUVÉE</b> avec succès.',
  'error.payment.refuse.error.DYN.pass': 'Une erreur est survenue lors du refus du paiement.',
  'error.payment.refuse.error.DYN.zwevisa': 'Une erreur est survenue lors du refus de la demande de visa.',
  'error.payment.suspend.error.DYN.pass': 'Une erreur est survenue lors de la suspension du paiement.',
  'error.payment.suspend.error.DYN.zwevisa': 'Une erreur est survenue lors de la suspension de la demande de visa.',
  'error.payment.validate.error.DYN.pass': 'Une erreur est survenue lors de l\'approbation du paiement.',
  'error.payment.validate.error.DYN.zwevisa': 'Une erreur est survenue lors de l\'approbation de la demande de visa.',
  'global.no': 'Non',
  'global.Yes': 'Oui',
  'global.No': 'Non',
  'zwevisa.type.holiday_visa': 'Vacances',
  'photo.entryContry': 'Photo du pays d\'entrée',
  'documents': 'Documents',
  'photo.passport': 'Photo du passeport',
  'data.biographic': 'Page biographique du passeport',
  'residence.proof': 'Preuve de résidence',
  'address.destination': 'Adresse de destination',
  'previous.visa': 'Visa précédent',
  'invite.letter': 'Lettre d\'invitation',
  'residential.status': 'Statut de résidence',
  'biometric.data': 'Données biométriques',
  'hostInvitation.letter': 'Lettre d\'invitation de l\'hôte',
  'proof.residenceHost': 'Preuve de résidence de l\'hôte',
  'hostResidential.status': 'Statut de résidence de l\'hôte',
  'reasonOfVisit.proof': 'Preuve de la raison de la visite dans le pays de destination',
  'inviteBusiness.letter': 'Lettre commerciale de l\'entreprise invitante',
  'business.letter': 'Lettre commerciale',
  'business.profile': 'Profil professionnel du voyageur',
  'application.letter': 'Lettre de demande de visa',
  'acceptanceFromScool.letter': 'Lettre de confirmation',
  'date.creation': 'Date de création',
  'document.Passport': 'Passeport',
  'completeName': 'Nom complet',
  'price': 'Prix',
  'global.retry': 'Réessayer',
  'err.loginWeak': 'Problème de connexion, veuillez réessayer',
  'URGENTPASS.title': 'Passeport urgent',
  'refusalReason': 'Raison du refus',
  'error.payment.noRefuseReason': 'Veuillez saisir un message.',
  'error.payment.noSuspendReason': 'Veuillez saisir une raison de suspension. Cette raison sera envoyée au client.',
  'refusal.desc.DYN.pass': 'Ce paiement a été refusé.',
  'refusal.desc.DYN.zwevisa': 'Cette demande de visa a été refusée.',
  'refusal.desc.extension.DYN.zwevisa': 'Cette demande d\'extension a été refusée.',
  'refusal.desc.DYN.zwevisa.blacklisted': 'Cette demande de visa a été refusée et mise sur liste noire.',
  'approval.desc.DYN.pass': 'Ce paiement a été approuvé.',
  'approval.desc.DYN.zwevisa': 'Cette demande de visa a été approuvée.',
  'approval.desc.extension.DYN.zwevisa': 'Cette demande d\'extension a été approuvée.',
  'review.approval.desc.DYN.zwevisa': 'Cette demande de visa répond aux exigences.',
  'review.approval.desc.extension.DYN.zwevisa': 'Cette demande d\'extension répond aux exigences.',
  'review.refusal.desc.DYN.zwevisa': 'Cette demande de visa ne répond pas aux exigences.',
  'review.refusal.desc.extension.DYN.zwevisa': 'Cette demande d\'extension ne répond pas aux exigences.',
  'err.getUsers: client.extended.notActiveToken': 'Votre session a expiré. Veuillez vous reconnecter.',
  'forgetPassword.success': 'Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter.',
  'VISA_DOUBLE.title': 'Visa double entrée',
  'VISA_KAZA.title': 'Visa Kaza',
  'history': 'Historique',
  'noPaymentFilter': 'Aucune demande en cours',
  'menu.request': 'Demandes',
  'menu.tracking': 'Suivi',
  'menu.people.DYN.pass': 'Personnes',
  'menu.people.DYN.zwevisa': 'Personnes enregistrées',
  'DYN.AGO': 'Angola',
  'DYN.ABW': 'Aruba',
  'DYN.ATG': 'Antigua & Barbuda',
  'DYN.BHS': 'Bahamas',
  'DYN.BRB': 'Barbade',
  'DYN.BLZ': 'Belize',
  'DYN.BWA': 'Botswana',
  'DYN.CYM': 'Îles Caïmans',
  'DYN.CYP': 'Chypre',
  'DYN.FJI': 'Fidji',
  'DYN.COD': 'République démocratique du Congo',
  'DYN.GHA': 'Ghana',
  'DYN.GRD': 'Grenade',
  'DYN.HKG': 'Région administrative spéciale de Hong Kong (Chine)',
  'DYN.KEN': 'Kenya',
  'DYN.KIR': 'Kiribati',
  'DYN.JAM': 'Jamaïque',
  'DYN.LDW': 'Îles Sous-le-Vent',
  'DYN.LSO': 'Lesotho',
  'DYN.MDG': 'Madagascar',
  'DYN.MWI': 'Malawi',
  'DYN.MYS': 'Malaisie',
  'DYN.MDV': 'Maldives',
  'DYN.MLT': 'Malte',
  'DYN.MUS': 'Maurice',
  'DYN.MSR': 'Montserrat',
  'DYN.MOZ': 'Mozambique',
  'DYN.NAM': 'Namibie',
  'DYN.NRU': 'Nauru',
  'DYN.LCA': 'Sainte-Lucie',
  'DYN.VCT': 'Saint-Vincent-et-les-Grenadines',
  'DYN.WSM': 'Samoa occidentales',
  'DYN.SYC': 'Seychelles',
  'DYN.SGP': 'Singapour',
  'DYN.SLB': 'Îles Salomon',
  'DYN.ZAF': 'Afrique du Sud',
  'DYN.KNA': 'Saint-Christophe-et-Niévès',
  'DYN.SWZ': 'Swaziland',
  'DYN.TON': 'Tonga',
  'DYN.TTO': 'Trinité-et-Tobago',
  'DYN.TCA': 'Îles Turques-et-Caïques',
  'DYN.TUV': 'Tuvalu',
  'DYN.UGA': 'Ouganda',
  'DYN.TZA': 'République-Unie de Tanzanie',
  'DYN.VUT': 'Vanuatu',
  'DYN.VAT': 'Vatican',
  'DYN.VEN': 'Venezuela (République bolivarienne du)',
  'DYN.USA': 'États-Unis d\'Amérique',
  'DYN.URY': 'Uruguay',
  'DYN.UZB': 'Ouzbékistan',
  'DYN.UKR': 'Ukraine',
  'DYN.ARE': 'Émirats arabes unis',
  'DYN.GBR': 'Royaume-Uni de Grande-Bretagne et d\'Irlande du Nord',
  'DYN.TUR': 'Turquie',
  'DYN.TKM': 'Turkménistan',
  'DYN.SWE': 'Suède',
  'DYN.CHE': 'Suisse',
  'DYN.TJK': 'Tadjikistan',
  'DYN.KAZ': 'Kazakhstan',
  'DYN.SUR': 'Suriname',
  'DYN.ESP': 'Espagne',
  'DYN.SVK': 'Slovaquie',
  'DYN.SVN': 'Slovénie',
  'DYN.SEN': 'Sénégal',
  'DYN.SRB': 'Serbie',
  'DYN.SMR': 'Saint-Marin',
  'DYN.STP': 'Sao Tomé-et-Principe',
  'DYN.NLD': 'Pays-Bas',
  'DYN.NCL': 'Nouvelle-Calédonie',
  'DYN.NZL': 'Nouvelle-Zélande',
  'DYN.NIC': 'Nicaragua',
  'DYN.MEX': 'Mexique',
  'DYN.FSM': 'Micronésie, États fédérés de',
  'DYN.MDA': 'Moldavie',
  'DYN.MCO': 'Monaco',
  'DYN.MHL': 'Îles Marshall',
  'DYN.LVA': 'Lettonie',
  'DYN.NFK': 'Île Norfolk',
  'DYN.MNP': 'Îles Mariannes du Nord',
  'DYN.NOR': 'Norvège',
  'DYN.PLW': 'Palaos',
  'DYN.PSE': 'Palestine',
  'DYN.PAN': 'Panama',
  'DYN.PNG': 'Papouasie-Nouvelle-Guinée',
  'DYN.PRY': 'Paraguay',
  'DYN.PER': 'Pérou',
  'DYN.POL': 'Pologne',
  'DYN.PRT': 'Portugal',
  'DYN.PRI': 'Porto Rico',
  'DYN.REU': 'Réunion',
  'DYN.ROU': 'Roumanie',
  'DYN.RUS': 'Fédération de Russie',
  'DYN.RWA': 'Rwanda',
  'DYN.LIE': 'Liechtenstein',
  'DYN.LTU': 'Lituanie',
  'DYN.LUX': 'Luxembourg',
  'DYN.MAC': 'Région administrative spéciale de Macao (Chine)',
  'DYN.JPN': 'Japon',
  'DYN.KWT': 'Koweït',
  'DYN.KGZ': 'Kirghizistan',
  'DYN.KOR': 'Corée du Sud',
  'DYN.GUY': 'Guyane',
  'DYN.HTI': 'Haïti',
  'DYN.HND': 'Honduras',
  'DYN.IRL': 'Irlande',
  'DYN.ISR': 'Israël',
  'DYN.ITA': 'Italie',
  'DYN.HUN': 'Hongrie',
  'DYN.ISL': 'Islande',
  'DYN.IND': 'Inde',
  'DYN.IDN': 'Indonésie',
  'DYN.IRN': 'Iran',
  'DYN.GUM': 'Guam',
  'DYN.GTM': 'Guatemala',
  'DYN.GIB': 'Gibraltar',
  'DYN.GRC': 'Grèce',
  'DYN.GEO': 'Géorgie',
  'DYN.DEU': 'Allemagne',
  'DYN.DNK': 'Danemark',
  'DYN.EST': 'Estonie',
  'DYN.ETH': 'Éthiopie',
  'DYN.FIN': 'Finlande',
  'DYN.FRA': 'France',
  'DYN.GUF': 'Guyane française',
  'DYN.PYF': 'Polynésie française',
  'DYN.ATF': 'Terres australes et antarctiques françaises',
  'DYN.CZE': 'République tchèque',
  'DYN.COM': 'Comores',
  'DYN.DOM': 'République dominicaine',
  'DYN.ECU': 'Équateur',
  'DYN.EGY': 'Égypte',
  'DYN.SLV': 'Salvador',
  'DYN.GNQ': 'Guinée équatoriale',
  'DYN.CHL': 'Chili',
  'DYN.CHN': 'Chine',
  'DYN.CHN_NT': 'Chine (non touristique)',
  'DYN.COK': 'Îles Cook',
  'DYN.CRI': 'Costa Rica',
  'DYN.HRV': 'Croatie',
  'DYN.CUB': 'Cuba',
  'DYN.BDI': 'Burundi',
  'DYN.CAN': 'Canada',
  'DYN.CPV': 'Cap-Vert',
  'DYN.BRA': 'Brésil',
  'DYN.VGB': 'Îles Vierges britanniques',
  'DYN.BRN': 'Brunéi Darussalam',
  'DYN.BGR': 'Bulgarie',
  'DYN.BMU': 'Bermudes',
  'DYN.BTN': 'Bhoutan',
  'DYN.BIH': 'Bosnie-Herzégovine',
  'DYN.BLR': 'Bélarus',
  'DYN.BEL': 'Belgique',
  'DYN.BHR': 'Bahreïn',
  'DYN.ARG': 'Argentine',
  'DYN.ARM': 'Arménie',
  'DYN.AUS': 'Australie',
  'DYN.AUT': 'Autriche',
  'DYN.AZE': 'Azerbaïdjan',
  'DYN.ALB': 'Albanie',
  'DYN.DZA': 'Algérie',
  'DYN.AIA': 'Anguilla',
  'DYN.BGD': 'Bangladesh',
  'DYN.BEN': 'Bénin',
  'DYN.AFG': 'Afghanistan',
  'DYN.BOL': 'Bolivie',
  'DYN.BFA': 'Burkina Faso',
  'DYN.KHM': 'Cambodge',
  'DYN.CMR': 'Cameroun',
  'DYN.CIV': 'Côte d\'Ivoire',
  'DYN.CAF': 'République centrafricaine',
  'DYN.TCD': 'Tchad',
  'DYN.COL': 'Colombie',
  'DYN.ERI': 'Érythrée',
  'DYN.COG': 'Congo-Brazzaville',
  'DYN.PRK': 'République populaire démocratique de Corée',
  'DYN.GAB': 'Gabon',
  'DYN.GMB': 'Gambie',
  'DYN.DJI': 'Djibouti',
  'DYN.GIN': 'Guinée',
  'DYN.GNB': 'Guinée-Bissau',
  'DYN.IRQ': 'Irak',
  'DYN.KOS': 'Kosovo',
  'DYN.LAO': 'République démocratique populaire lao',
  'DYN.JOR': 'Jordanie',
  'DYN.LBR': 'Liberia',
  'DYN.LBY': 'Jamahiriya arabe libyenne',
  'DYN.MLI': 'Mali',
  'DYN.QAT': 'Qatar',
  'DYN.REF': 'Réfugié',
  'DYN.PHL': 'Philippines',
  'DYN.OMN': 'Oman',
  'DYN.PAK': 'Pakistan',
  'DYN.LBN': 'Liban',
  'DYN.MRT': 'Mauritanie',
  'DYN.MNG': 'Mongolie',
  'DYN.MNE': 'Monténégro',
  'DYN.NER': 'Niger',
  'DYN.NGA': 'Nigéria',
  'DYN.MMR': 'Myanmar',
  'DYN.MAR': 'Maroc',
  'DYN.NPL': 'Népal',
  'DYN.SAU': 'Arabie saoudite',
  'DYN.SLE': 'Sierra Leone',
  'DYN.SOM': 'Somalie',
  'DYN.LKA': 'Sri Lanka',
  'DYN.SSD': 'Soudan du Sud',
  'DYN.SDN': 'Soudan',
  'DYN.THA': 'Thaïlande',
  'DYN.MKD': 'Ex-République yougoslave de Macédoine',
  'DYN.TGO': 'Togo',
  'DYN.SYR': 'République arabe syrienne',
  'DYN.TWN': 'Taïwan',
  'DYN.TUN': 'Tunisie',
  'DYN.VNM': 'Viet Nam',
  'DYN.YEM': 'Yémen',
  'DYN.TLS': 'Timor-Leste',
  'DYN.AND': 'Andorre',
  'DYN.VIR': 'Îles Vierges (États-Unis)',
  'DYN.XKX': 'Kosovo',
  'DYN.ZWE': 'Zimbabwe',
  'DYN.ZMB': 'Zambie',
  'DYN.ASM': 'Samoa américaines',
  'DYN.DMA': 'Dominique',
  'DYN.XXA': 'Apatride',
  'DYN.XXB': 'Réfugié (convention de Genève)',
  'DYN.XXC': 'Réfugié (Autre)',
  'DYN.UNA': 'Agence des Nations Unies',
  'DYN.UNO': 'Organisation des Nations Unies',
  'err.Occupation.pattern': 'L\'occupation doit être dans un format correct',
  'err.PreviousConvictionsHome.pattern': 'Les condamnations antérieures dans le pays d\'origine doivent être dans un format correct',
  'err.PlaceOfBirth.pattern': 'Le lieu de naissance doit avoir un format correct',
  'PAYMENT_USED': 'Paiement clôturé',
  'document.type.DYN.pass': 'Type de document',
  'document.type.DYN.zwevisa': 'Type de demande',
  'filterPayment.error': 'Une erreur est survenue lors du filtrage des demandes. Voulez-vous réessayer?',
  'filterPaymentNoFirst.error': 'Une erreur est survenue lors de la récupération des demandes. Voulez-vous réessayer?',
  'dependantIndex': 'Dépendant {index}',
  'form.spouseFullName': 'Nom complet du conjoint',
  'qrDesc.valid': 'Présentez ce code QR au point de contrôle des visas lors de l\'entrée ou de la sortie du pays. Vous ne pouvez pas voyager sans un code QR valide.',
  'qrDesc.invalid': 'Cette demande de visa est actuellement invalide. Vous ne pouvez pas voyager sans une demande de visa valide. Veuillez contacter le bureau des visas pour plus d\'informations.',
  'err.pay': 'Une erreur est survenue pendant le paiement. Réessayez.',
  'payment.noEvents': 'Cette demande ne contient actuellement aucun traitement lié aux voyages (utilisations de la demande).',
  'global.cancel': 'Annuler',
  'end.suspend': 'Terminer la révision',
  'visa_application': 'Exigences pour la demande de visa',
  'trips.title': 'Suivre les demandes',
  'trips.desc': 'Demande(s)',
  'BatchId': 'Référence de la demande',
  'newBatchId': 'Ajouter',
  'err.BatchId.required': 'Référence de demande requise',
  'newBatchId.desc': 'Veuillez entrer un nom pour ce voyage.',
  'ask.newBatchId': 'Veuillez créer une nouvelle référence de demande.',
  'personLinkType.DYN.0': 'Inconnu',
  'personLinkType.DYN.1': 'Enfant',
  'personLinkType.DYN.2': 'Parent',
  'personLinkType.DYN.3': 'Conjoint',
  'personLinkType.DYN.4': 'Autre',
  'personLinkType.DYN.5': 'Vous-même',
  'createPeople.error.DYN.pass': 'Une erreur est survenue lors de la création de la personne.',
  'createPeople.error.DYN.zwevisa': 'Une erreur est survenue lors de la création de la personne.',
  'batchIds.desc': 'Demandes liées à la référence {trip} :',
  'trip.name': 'Référence de la demande',
  'visaIntro.validFrom': 'Date de début',
  'visaIntro.validUntil': 'Date d\'expiration',
  'visaIntro.usageCount': 'Utilisation(s) autorisée(s)',
  'client.extended.passportNumberAlreadyExists': 'Ce numéro de passeport est déjà utilisé. Veuillez en saisir un autre.',
  'client.extended.alreadyDecided': 'Cette demande de visa a déjà été examinée par vous. Vous ne pouvez pas l\'examiner à nouveau.',
  'client.extended.outOfUsage': 'Cette demande est <b>hors de sa période de validité</b>',
  'global.review': 'Réviser',
  'ask.review.0': 'Sélectionnez votre évaluation pour cette demande de visa.',
  'ask.review.extension.0': 'Sélectionnez votre évaluation pour cette demande d\'extension.',
  'ask.review.1': 'Sélectionnez votre recommandation pour cette demande de visa.',
  'ask.review.2': 'Sélectionnez votre décision finale pour cette demande de visa.',
  'error.review.noChoice': 'Veuillez sélectionner un état d\'examen pour continuer.',
  'all.reviews': 'Tous les avis sur cette demande de visa',
  'all.reviews.extension': 'Tous les avis sur cette demande d\'extension',
  'validate.desc': 'Cette demande de visa a été approuvée le {date} par l\'utilisateur',
  'validate.extension.desc': 'Cette demande d\'extension a été approuvée le {date} par l\'utilisateur',
  'refusal.review.desc': 'Cette demande de visa a été refusée le {date} par l\'utilisateur',
  'refusal.review.extension.desc': 'Cette demande d\'extension a été refusée le {date} par l\'utilisateur',
  'refusal.review.desc.blacklisted': 'Cette demande de visa a été refusée et mise sur liste noire le {date} par l\'utilisateur',
  'global.revision': 'Révision',
  'revison.cancel': 'Annuler la révision',
  'approve.text': 'Cette demande a été approuvée.',
  'refuse.text': 'Cette demande de visa a été refusée.',
  'pending.review.desc': 'Une correction de cette demande de visa a été demandée au demandeur le {date} par l\'utilisateur',
  'global.reason': 'Raison',
  'pending.desc': 'Cette demande de visa est en attente de correction par le client.',
  'success.onArrival': 'Votre demande a été soumise avec succès. Vous pouvez voir le code QR de cette demande en haut. Présentez ce code QR au point de contrôle des visas lors de l\'entrée/sortie du pays. Vous ne pouvez pas voyager sans une demande de visa valide.',
  'successRequest.title': 'Demande soumise avec succès',
  'successRequest.title.kiosk': 'Demande soumise avec succès',
  'amountDue': 'Prix : ',
  'amountDue.total.now': 'Total dû maintenant : ',
  'amountDue.total.later': 'Total dû au guichet : ',
  'noPrint.mobile': 'L\'impression de page n\'est pas prise en charge sur les appareils mobiles',
  'err.PassportNumber.required': 'Numéro de document requis (Passeport, Permis de Résident)',
  'err.FundsAvailable.pattern': 'Les fonds disponibles à l\'entrée doivent avoir un format correct',
  'onArrival.reset': 'Commencer un nouveau processus',
  'onArrival.reset.ask': 'Voulez-vous vraiment commencer un nouveau processus?',
  'stillHere': 'Êtes-vous toujours là ? Ce processus se réinitialisera dans 20 secondes.',
  'stillHere.successPayment': 'Êtes-vous toujours là ?',
  'print.request': 'Imprimer la demande',
  'EXPIRED.globalExplain': 'Cette demande a été refusée. Elle ne respecte pas nos normes.',
  'payment_status.desc.DYN.7': 'Cette demande de visa est en attente. Elle est actuellement en attente de correction par le client.',
  'payment_status.DYN.zwevisa.6.review': 'En attente de révision - {reviewCount}/2',
  'payment_status.desc.DYN.zwevisa.6.review': 'Cette demande de visa est en attente de révision. Elle a déjà été révisée {reviewCount} fois.',
  'payment_status.DYN.zwevisa.6.finalReview': 'En attente de la révision finale',
  'payment_status.desc.DYN.zwevisa.6.finalReview': 'Cette demande de visa a été révisée et est actuellement en attente de la révision finale.',
  'payment_status.desc.DYN.zwevisa.0': 'Cette demande est en attente de validation de paiement auprès de l\'institution de paiement. Si le paiement n\'a pas encore été effectué, il peut être complété maintenant.',
  'payment_status.desc.DYN.zwevisa.7': 'Cette demande de visa est en attente de correction par le client.',
  'payment_status.desc.DYN.zwevisa.5': 'Cette demande de visa a été refusée.',
  'payment_status.desc.extension.DYN.zwevisa.5': 'Cette demande d\'extension a été refusée.',
  'payment_status.desc.DYN.zwevisa.5.blacklisted': 'Cette demande de visa a été refusée et la personne a été mise sur liste noire.',
  'payment_status.desc.DYN.zwevisa.5.checkIn': 'Cette demande a été refusée lors de l\'enregistrement au guichet.',
  'payment_status.desc.DYN.zwevisa.5.checkIn.reason': 'Cette demande a été refusée lors de l\'enregistrement au guichet. <p><b>Raison</b> : <br/> {reason}</p>',
  'payment_status.DYN.zwevisa.5.checkIn': 'Refusé à l\'enregistrement',
  'payment_status.desc.DYN.zwevisa.5.checkOut': 'Cette demande a été refusée lors du départ au guichet.',
  'payment_status.DYN.zwevisa.5.checkOut': 'Refusé à la sortie',
  'payment_status.desc.DYN.zwevisa.9': 'Cette demande de visa est prête à être payée au guichet. L\'enregistrement peut être effectué après le paiement.',
  'payment_status.desc.DYN.zwevisa.9.entry': 'Cette demande est prête à être enregistrée au guichet.',
  'payment_status.desc.DYN.zwevisa.9.exit': 'Cette demande est prête à être vérifiée pour la sortie et nécessite un paiement pour le laisser-passer frontalier délivré au guichet.',
  'payment_status.DYN.zwevisa.2.out': 'Prêt à sortir',
  'payment_status.DYN.zwevisa.2.outFinish': 'Fermé',
  'payment_status.DYN.zwevisa.2.closed': 'Fermé',
  'payment_status.desc.DYN.zwevisa.2.closed': 'Cette demande a été clôturée.',
  'payment_status.desc.DYN.zwevisa.2.zweexit': 'Cette demande est prête à être vérifiée pour la sortie au guichet.',
  'payment_status.DYN.zwevisa.2.entryFinish': 'Enregistré',
  'payment_status.DYN.zwevisa.2.in': 'Prêt à entrer',
  'payment_status.desc.DYN.zwevisa.2.out': 'Cette demande a été enregistrée à l\'entrée. L\'étape suivante est la sortie au guichet.',
  'payment_status.desc.DYN.zwevisa.2.outFinish': 'Cette demande a été validée à la sortie. Le client peut maintenant voyager.',
  'payment_status.desc.DYN.zwevisa.2.entryFinish': 'Cette demande a été validée à l\'entrée. Le client peut maintenant entrer dans le pays.',
  'payment_status.desc.DYN.zwevisa.2.in': 'Cette demande est en attente d\'enregistrement au guichet.',
  'ask.isArrivalPaid': 'Le paiement suivant doit être effectué maintenant dans l\'une des devises suivantes :',
  'ask.isArrivalPaid.exit': 'Le laissez-passer frontalier doit être payé maintenant dans l\'une des devises suivantes :',
  'ask.isArrivalPaid.confirm': 'Confirmez-vous que le paiement a été effectué ? Si vous confirmez, veuillez entrer la référence de paiement, le mode de paiement et la devise utilisée. Vous pouvez également modifier la date maximale de séjour (vous ne pouvez que la réduire).',
  'ask.isArrivalPaid.confirm.exit': 'Confirmez-vous que le paiement a été effectué ? Si vous confirmez, veuillez entrer la référence de paiement et la devise utilisée.',
  'ask.checkoutExpired.confirm': 'Confirmez-vous que le paiement a été effectué ? Si vous confirmez, veuillez entrer la référence de paiement et la devise utilisée.',
  'no_trips': 'Vous n\'avez aucun voyage.',
  'err.canActivate': 'Une erreur s\'est produite',
  'form.passportNumber.dateOfIssue': 'Date de délivrance du passeport',
  'form.passportNumber.dateOfExpiry': 'Date d\'expiration du passeport',
  'form.passportNumber.placeOfIssue': 'Lieu de délivrance du passeport',
  'nextOfKin.data': 'Contact d\'urgence / Proche',
  'form.otherLinkType': 'Précisez le lien de parenté',
  'err.passportNr.pattern': 'Le numéro de passeport doit avoir un format correct',
  'err.otherLinkType.required': 'Vous devez spécifier le type de lien de parenté',
  'err.otherLinkType.pattern': 'Le type de lien de parenté doit avoir un format correct',
  'camera.problem.manual': 'Vous êtes sur le point de valider cette demande de visa sans photo du client. Voulez-vous vraiment continuer ?',
  'camera.problem.button': 'Problème avec la caméra ?',
  'global.noFilter': '--Pas de filtre--',
  'zweentry': 'Entrée sans visa',
  'zweentry.title': 'Entrée sans visa',
  'zweentry.description': 'Les voyageurs de certains pays peuvent entrer sans obtenir un visa au préalable, généralement pour des séjours de courte durée.',
  'free.charge': 'Gratuit',
  'camera.init.error': 'Une erreur est survenue lors de l\'initialisation de la caméra. Voulez-vous valider cette demande sans photo du client ?',
  'error.payment.noPaymentReference': 'Veuillez entrer une référence de paiement, un mode de paiement et la devise utilisée.',
  'paymentReference.ask': 'Référence du paiement',
  'paymentReference.askExpired': 'Référence du paiement pour la surtaxe de dépassement de séjour',
  'session.expired': 'Votre session a expiré. Veuillez vous reconnecter.',
  'error.approveRefuse.DYN.zwevisa': 'Une erreur s\'est produite lors de l\'approbation ou du refus de la demande de visa.',
  'error.approveRefuse.DYN.pass': 'Une erreur s\'est produite lors de l\'approbation ou du refus du paiement.',
  'phone.pattern': ' (ex : +263771234567)',
  'global.receipt.id': 'ID de la demande : {paymentId}',
  'global.preview': 'Aperçu',
  'ask.place.updatePermissions': 'Voulez-vous vraiment mettre à jour les autorisations de ce lieu?',
  'place.updatePermissions.success': 'Les autorisations du lieu ont été mises à jour avec succès.',
  'err.place.updatePermissions': 'Une erreur s\'est produite lors de la mise à jour des autorisations du lieu.',
  'err.seniority.required': 'L\'ancienneté doit être un nombre entre 1 et 3.',
  'err.seniority.pattern': 'L\'ancienneté doit être un nombre entre 1 et 3.',
  'global.urgent': 'Urgent',
  'form.usageAfter': 'Valide à partir de',
  'form.usageUntil': 'Valide jusqu\'à',
  'form.localUsageUntil': 'Date maximale de fin de séjour',
  'id.payment': 'ID de paiement',
  'internalReference': 'Référence HQ',
  'payment_status.DYN.zwevisa.6.0': 'En attente de révision 0/2',
  'payment_status.DYN.zwevisa.6.1': 'En attente de révision 1/2',
  'payment_status.DYN.zwevisa.6.2': 'En attente de la révision finale',
  'payment_status.DYN.zwevisa.2.0': 'Enregistré',
  'payment_status.DYN.zwevisa.2.4': 'Prêt à sortir',
  'payment_status.DYN.zwevisa.2.5': 'Fermé',
  'payment_status.DYN.zwevisa.2.6': 'Refusé à l\'enregistrement',
  'payment_status.DYN.zwevisa.2.7': 'Refusé à la sortie',
  'payment_status.DYN.zwevisa.2.8': 'Fermé',
  'internalReference.create': 'Ajouter une référence HQ',
  'internalReference.edit': 'Modifier la référence HQ',
  'internalReference.ask.edit': 'Veuillez entrer la nouvelle référence HQ.',
  'internalReference.ask.create': 'Veuillez entrer la référence HQ.',
  'internalReference.done.create': 'La référence HQ a été ajoutée avec succès.',
  'internalReference.done.edit': 'La référence HQ a été modifiée avec succès.',
  'error.internalReference': 'Une erreur s\'est produite lors de l\'ajout ou de la modification de la référence HQ.',
  'error.paymentUntil.expired': 'Cette demande est au-delà de sa période de validité. Vous pouvez procéder au processus de sortie pour le client; cependant, veuillez noter qu\'une surtaxe de 100 USD est requise en raison de l\'état expiré de la demande de visa. Le paiement doit être effectué immédiatement dans l\'une des devises suivantes :',
  'internalReference.desc': 'Ajouter une référence HQ à cette demande (par exemple, 140_2024).',
  'visaApplication.id': 'ID de la demande de visa',
  'titlePrint': 'Demande de visa </br> <b>{paymentID}</b>',
  'titlePrint.extension': 'Demande d\'extension </br> <b>{paymentID}</b>',
  'visa.data': 'Données du visa',
  'validated': 'Valide',
  'globalStatus.awaitingConfirmation': 'En attente de confirmation',
  'globalStatus.awaitingVerification': 'En attente de correction',
  'globalStatus.refused': 'Refusé',
  'globalStatus.valid': 'Valide',
  'globalStatus.draft': 'En attente de paiement',
  'PENDING.globalExplain': 'Cette demande est en attente de correction. Veuillez vérifier les informations et les corriger.',
  'PAID_NOT_CONFIRMED.globalExplain': 'Cette demande est en attente de confirmation par nos services.',
  'PROPOSED.globalExplain': 'Cette demande est en attente de paiement. Si vous n\'avez pas encore validé le paiement, vous pouvez le faire maintenant.',
  'usageValidityConditions': 'Les autorités de l\'immigration se réservent le droit d\'annuler le visa à tout moment si le titulaire du visa ne respecte pas les conditions associées.',
  'afterWithoutUntil': 'Vous êtes autorisé à entrer à partir du {dateAfter} jusqu\'au {deadlineDate}.',
  'afterWithoutUntilAndMillis': 'Vous êtes autorisé à voyager à partir du {dateAfter}.',
  'afterAndUntil': 'Vous êtes autorisé à voyager du {dateAfter} jusqu\'au {dateUntil}.',
  'photo.current': 'Photo actuelle',
  'photo.scanned': 'Photo scannée à partir du passeport',
  'createdAt': 'Date de création',
  'all.infos': 'Informations sur cette demande',
  'afterRefused': 'Dates de voyage déclarées : {dateAfter}.',
  'DYN.paymentError.desc.success': `<p>Votre commande a été enregistrée avec succès. Si une partie de cette demande nécessite une approbation de nos équipes, vous serez informé une fois ce processus terminé. Sinon, sachez que nous avons reçu votre demande.</p>
  <p><b>Merci d'avoir choisi nos services.</b></p>`,
  'successPayment.kiosk': '<p>Votre demande a été enregistrée avec succès. Vous pouvez maintenant vous rendre au guichet pour vous enregistrer.</p><p><b>Merci d\'avoir choisi nos services.</b></p>',
  'sinceOneWeek': 'Veuillez noter que cette demande de visa est en attente de révision depuis plus d\'une semaine.',
  'client.extended.onlyAdminDecide': 'Cette demande de visa a déjà été révisée par un travailleur du même niveau d\'ancienneté que vous. Vous ne pouvez pas la réviser à nouveau.',
  'error.paymentUntil.before': 'La date est trop tôt. Cette demande sera valide à partir du {dateAfter}.',
  'applicant.gender.FEMALE': 'Féminin',
  'applicant.gender.MALE': 'Masculin',
  'entry.consume': 'Arrivées',
  'exit.consume': 'Départs',
  'scan.noConsumeMode': 'En tant qu\'officier frontalier, vous êtes chargé de gérer les entrées et les sorties. Veuillez sélectionner l\'option correspondant à votre tâche actuelle : traitement des arrivées ou gestion des départs.',
  'scan.kiosk': 'Aucun enregistrement en statut "Prêt à entrer" pour une entrée n\'a été trouvé pour cette personne. Veuillez demander à la personne de créer une nouvelle demande au kiosque.',
  'scan.office': 'Aucun enregistrement en statut "Prêt à entrer" pour une entrée n\'a été trouvé pour cette personne. La nationalité de cette personne est de catégorie C, ce qui signifie qu\'elle a besoin d\'un visa. Veuillez escorter cette personne au bureau pour obtenir un visa urgent ou pour lui refuser l\'entrée.',
  'scan.noVisaFound': 'Aucun enregistrement en statut "Prêt à sortir" pour une sortie n\'a été trouvé pour cette personne. Voulez-vous en créer un prêt à sortir ?',
  'zweexit': 'Sortie sans visa',
  'err.scanPassport': 'Une erreur est survenue lors de la numérisation du passeport. Veuillez vérifier qu\'il est correctement inséré et réessayer. Si le problème persiste, redémarrez l\'application ou contactez le support.',
  'exit.register': 'Enregistrer une sortie',
  'entry.register': 'Enregistrer une entrée',
  'exit.create': 'Créer une sortie sans visa',
  'exit.ask': 'Êtes-vous sûr de vouloir enregistrer une sortie sans visa pour cette personne ?',
  'exit.success': 'La sortie sans visa a été enregistrée avec succès. Vous pouvez maintenant valider la sortie de la personne.',
  'border': 'Officier à la frontière',
  'global.signout': 'Déconnexion',
  'search.manually': 'Rechercher une demande',
  'scan.applicationQr': 'Scanner le code QR de la demande',
  'err.client.extended.maxUsageCountReached': 'Le nombre maximum d\'utilisations pour cette demande a été atteint.',
  'form.firstName.scanned': 'Prénom(s) scanné(s)',
  'form.lastName.scanned': 'Nom de famille scanné',
  'form.documentNumber.scanned': 'Numéro de passeport scanné',
  'form.gender.scanned': 'Genre scanné',
  'form.birthDate.scanned': 'Date de naissance scannée',
  'form.nationality.scanned': 'Nationalité scannée',
  'scanned.passport': 'Passeport scanné',
  'application.data': 'Données de la demande',
  'no.passportPhoto': 'Pas de photo',
  'scanned.list.checkIn': 'Voici les demandes trouvées associées à votre numéro de passeport',
  'overstay': 'Dépassement de séjour',
  'method.payment': 'Méthode de paiement',
  'review.desc': 'Approuver/Refuser',
  'approval.usageUntil.DYN.zwevisa': 'Changer la date d\'expiration (optionnel)',
  'scanned.list': 'Liste des demandes trouvées',
  'err.EmergencyPhone.pattern': 'Le numéro de téléphone d\'urgence doit avoir un format correct',
  'visa.blacklist': 'Cette demande de visa a été mise sur liste noire. La personne n\'est pas autorisée à entrer dans le pays.',
  'scan.noEntryFound': 'Aucun enregistrement en statut "Prêt à entrer" pour une entrée n\'a été trouvé pour cette personne. Voulez-vous en créer un prêt à entrer ?',
  'err.EmergencyEmail.pattern': 'L\'e-mail de contact d\'urgence doit avoir un format correct',
  'global.syncing': 'Synchronisation...',
  'payment.consume.error.DYN.zwevisa': 'Une erreur s\'est produite lors du traitement de la demande.',
  'tooltip.Qr': 'Vous pouvez scanner directement le code QR associé à une demande pour obtenir des informations détaillées sur cette demande.',
  'tooltip.arrivals': 'Cochez cette case pour gérer les arrivées en tant qu\'officier frontalier.',
  'tooltip.departures': 'Cochez cette case pour gérer les départs en tant qu\'officier frontalier.',
  'tooltip.searchManually': 'Recherchez une demande spécifique en utilisant divers critères.',
  'tooltip.signout': 'Déconnectez-vous de l\'application.',
  'tooltip.my_account': 'Accédez aux paramètres de votre compte.',
  'tooltip.dashboard': 'Accédez au tableau de bord.',
  'tooltip.createRequests': 'Créer une nouvelle demande.',
  'tooltip.trips': 'Consultez le statut de vos demandes et imprimez le document lié si approuvé.',
  'tooltip.tracking': 'Suivez vos demandes.',
  'tooltip.people': 'Gérez les personnes associées à votre compte.',
  'tooltip.users': 'Gérez les utilisateurs de l\'application.',
  'tooltip.requests': 'Gérez les demandes de l\'application.',
  'tooltip.places': 'Gérez les lieux de l\'application.',
  'tooltip.role': 'Votre rôle et nom dans l\'application.',
  'tooltip.exitRegister': 'Enregistrez manuellement une sortie sans visa pour une personne.',
  'tooltip.entryRegister': 'Enregistrez manuellement une entrée sans visa pour une personne.',
  'tooltip.support': 'Contactez le support pour toute assistance.',
  'tooltip.chooseLang': 'Changer la langue de l\'application. Actuellement, seule l\'anglais est disponible.',
  'tooltip.changePassword': 'Changer votre mot de passe.',
  'tooltip.search': 'Filtrer les demandes en fonction de leur nom, numéro de passeport, ID de paiement ou référence.',
  'tooltip.internalReference': 'Filtrer les demandes en fonction de leur référence interne.',
  'tooltip.internalReferenceNoFilter': 'Cliquez sur une référence interne, si disponible, pour être redirigé vers la référence liée.',
  'tooltip.state': 'Filtrer les demandes en fonction de leur statut.',
  'tooltip.createdDate': 'Filtrer les demandes en fonction de leur date de création en utilisant les dates de début et de fin.',
  'tooltip.createdDateSince': 'Filtrer les demandes en fonction de leur date de création.',
  'tooltip.overstay': 'Filtrer les demandes en fonction de leur dépassement de séjour dans le pays.',
  'tooltip.clearAllFilters': 'Effacer tous les filtres appliqués aux demandes.',
  'tooltip.filterLength': 'Le nombre de demandes correspondant aux filtres appliqués.',
  'tooltip.downloadCsv': 'Télécharger les demandes au format CSV.',
  'tooltip.tablePassportTitle': 'Trier les demandes en fonction du numéro de passeport.',
  'tooltip.tablePassport': 'Le numéro de passeport associé à la demande',
  'tooltip.tableInternalReferenceTitle': 'Trier les demandes en fonction de la référence interne.',
  'tooltip.tableInternalReference': 'La référence interne associée à la demande',
  'tooltip.tableBatchIdTitle': 'Trier les demandes en fonction de la référence de la demande.',
  'tooltip.tableBatchId': 'La référence de la demande associée à la demande',
  'tooltip.tableCreationDateTitle': 'Trier les demandes en fonction de la date de création.',
  'tooltip.tableCreationDate': 'La date de création de la demande',
  'tooltip.tableStateTitle': 'Trier les demandes en fonction du statut.',
  'tooltip.tableState': 'Le statut de la demande',
  'tooltip.tableCompleteNameTitle': 'Trier les demandes en fonction du nom complet.',
  'tooltip.tableCompleteName': 'Le nom complet de la personne associée à la demande',
  'tooltip.tablePriceTitle': 'Trier les demandes en fonction du prix.',
  'tooltip.tablePrice': 'Le prix de la demande',
  'tooltip.tableUsageAfterTitle': 'Trier les demandes par date de début.',
  'tooltip.tableUsageAfter': 'La date de début de la demande',
  'tooltip.tableUsageUntilTitle': 'Trier les demandes par date d\'expiration.',
  'tooltip.tableUsageUntil': 'La date d\'expiration de la demande',
  'tooltip.tableIdTitle': 'Trier les demandes en fonction de l\'ID.',
  'tooltip.tableId': 'L\'ID de la demande',
  'tooltip.tablePerPage': 'Modifier le nombre de demandes affichées par page.',
  'tooltip.tableNumberOfPage': 'Contrôlez la pagination et le nombre de demandes affichées par page pour faciliter la navigation.',
  'tooltip.history': 'L\'historique des paiements de vos demandes.',
  'tooltip.addPerson': 'Ajouter une nouvelle personne liée à votre compte.',
  'tooltip.listQr': 'Voir toutes les demandes filtrées et leur code QR.',
  'tooltip.urgent': 'Cela signifie que la demande est marquée comme urgente et doit être traitée dès que possible.',
  'tooltip.operationId': 'Le type de demande.',
  'tooltip.purposeOfVisit': 'Le motif de la visite pour cette demande.',
  'tooltip.amount': 'Le prix de cette demande.',
  'tooltip.paymentType': 'Le type de paiement pour cette demande de visa.',
  'tooltip.paymentReference': 'La référence du paiement pour cette demande de visa.',
  'tooltip.review': 'Examinez cette demande de visa en tant qu\'approuvée ou refusée et ajoutez un commentaire.',
  'tooltip.revision': 'Demandez au client de corriger la demande de visa.',
  'tooltip.approve': 'Approuvez cette demande de visa en tant que décision finale. Vous pouvez également ajouter un commentaire. Si le terme "blacklisté" est présent, la personne sera mise sur liste noire.',
  'tooltip.refuse': 'Refusez cette demande de visa en tant que décision finale. Vous pouvez également ajouter un commentaire.',
  'tooltip.qrSee': 'Voir le code QR de cette demande et imprimer le document qui y est lié.',
  'tooltip.internalEdit': 'Modifier la référence interne de cette demande de visa.',
  'tooltip.internalAdd': 'Ajouter une référence interne à cette demande.',
  'tooltip.printApplication': 'Imprimer la demande avec toutes les informations.',
  'tooltip.allReviews': 'Tous les avis sur cette demande de visa, avec la date et l\'utilisateur ayant effectué l\'examen.',
  'tooltip.version': 'La version actuelle de l\'application.',
  'tooltip.consumeProfile.IN': 'La photo capturée au point d\'entrée.',
  'tooltip.consumeNoProfile.IN': 'Aucune photo capturée au point d\'entrée.',
  'tooltip.consumeTime.IN': 'La date et l\'heure du point d\'entrée.',
  'tooltip.consumePlace.IN': 'Le lieu du point d\'entrée.',
  'tooltip.consumeProfile.OUT': 'La photo capturée au point de sortie.',
  'tooltip.consumeNoProfile.OUT': 'Aucune photo capturée au point de sortie.',
  'tooltip.consumeTime.OUT': 'La date et l\'heure du point de sortie.',
  'tooltip.consumePlace.OUT': 'Le lieu du point de sortie.',
  'tooltip.passportComparison': 'Informations scannées à partir du passeport. Cela vous permet de comparer les informations avec celles actuelles de la demande.',
  'tooltip.classicComparison': 'Informations actuelles de la demande. Cela vous permet de comparer les informations avec celles scannées à partir du passeport.',
  'tooltip.trackingValid': 'Le statut de votre demande. Ici, la demande est valide. Cela signifie que vous pouvez voyager avec cette demande en fonction des dates de validité.',
  'tooltip.trackingPending': 'Le statut de votre demande. Ici, la demande est en attente de correction. Cela signifie que vous devez corriger certaines informations avant que la demande ne puisse être approuvée.',
  'tooltip.trackingRefused': 'Le statut de votre demande. Ici, la demande est refusée. Cela signifie que vous ne pouvez pas voyager avec cette demande.',
  'tooltip.trackingAwaitingConfirmation': 'Le statut de votre demande. Ici, la demande est en attente de confirmation par nos services.',
  'tooltip.trackingApplication': 'Demande liée à cette référence de demande',
  'tooltip.trackingApplicationReference': 'Référence de demande de ces demandes',
  'tooltip.allTrackingInfo': 'Toutes les informations sur cette demande',
  'tooltip.trackingPrint': 'Imprimer le document lié à cette demande',
  'tooltip.docFirstName': 'Entrez le prénom de la personne tel qu\'il apparaît sur le passeport.',
  'tooltip.docLastName': 'Entrez le nom de famille de la personne tel qu\'il apparaît sur le passeport.',
  'tooltip.docPassportNumber': 'Entrez le numéro de passeport de la personne.',
  'tooltip.docLinkType': 'Sélectionnez le type de relation que vous avez avec la personne.',
  'tooltip.docLinkTypeOther': 'Précisez le type de relation que vous avez avec la personne.',
  'tooltip.applicationReferenceRequest': 'La référence de demande de la demande. Elle est unique pour chaque demande, et elle est générée automatiquement.',
  'tooltip.portEntryRequest': 'Le point d\'entrée de la demande. C\'est le lieu où la personne entrera dans le pays.',
  'tooltip.otherName': 'Entrez le deuxième prénom du demandeur tel qu\'il apparaît sur le passeport.',
  'tooltip.spouseCheckbox': 'Cochez cette case si le demandeur a un conjoint et remplissez les informations ci-dessous.',
  'tooltip.hostName': 'Le nom de la personne/organisation qui accueillera le demandeur.',
  'tooltip.scanPassport': 'Scannez le passeport du demandeur pour trouver les demandes liées à ce passeport. Si aucune demande n\'est trouvée, vous pouvez en créer une nouvelle.',
  'tooltip.passportScanned': 'Les informations scannées à partir du passeport.',
  'tooltip.scannedCard': 'Demandes similaires trouvées avec les informations scannées.',
  'scan.error': 'Une erreur est survenue lors de la numérisation du document.',
  'tooltip.webcamProblems': 'Si la caméra ne fonctionne pas correctement, vous pouvez toujours valider l\'entrée ou la sortie de la demande sans photo.',
  'tooltip.checkOut': 'Valider la sortie de la personne du pays.',
  'tooltip.checkIn': 'Valider l\'entrée de la personne dans le pays.',
  'tooltip.consumeRefuse': 'Refuser l\'entrée ou la sortie de la personne.',
  'tooltip.finalRefuse': 'La décision finale de la demande. La personne ne pourra pas entrer dans le pays.',
  'tooltip.finalApprove': 'La décision finale de la demande. La personne pourra entrer ou sortir du pays.',
  'tooltip.finalPending': 'La décision finale de la demande. La personne ne pourra pas entrer dans le pays tant que la demande n\'est pas corrigée et approuvée.',
  'tooltip.finalRefuseBlacklist': 'La décision finale de la demande. La personne ne pourra pas entrer dans le pays et est mise sur liste noire.',
  'ask.updateScannedPassport': 'Voulez-vous mettre à jour les informations de la demande avec les informations scannées ?',
  'done.updateScannedPassport': 'Les informations de la demande ont été mises à jour avec succès.',
  'error.updateScannedPassport': 'Une erreur est survenue lors de la mise à jour des informations de la demande.',
  'photo.entryCountry.IN': 'Photo à l\'enregistrement',
  'photo.entryCountry.OUT': 'Photo à la sortie',
  'err.noPassiveAuthenticationCSCA': 'Le document est valide, mais n\'a pas pu être vérifié auprès de son autorité émettrice.',
  'err.OtherName.pattern': 'Le deuxième prénom doit uniquement contenir des lettres non accentuées (majuscule ou minuscule), des espaces ou des traits d\'union, exactement tel qu\'il apparaît sur le passeport.',
  'err.FirstName.pattern': 'Le prénom doit uniquement contenir des lettres non accentuées (majuscule ou minuscule), des espaces ou des traits d\'union, exactement tel qu\'il apparaît sur le passeport.',
  'err.LastName.pattern': 'Le nom de famille doit uniquement contenir des lettres non accentuées (majuscule ou minuscule), des espaces ou des traits d\'union, exactement tel qu\'il apparaît sur le passeport.',
  'err.PassportNumber.pattern': 'Le numéro de passeport doit uniquement contenir des lettres non accentuées (majuscule ou minuscule), des chiffres, des espaces ou des traits d\'union, exactement tel qu\'il apparaît sur le passeport.',
  'err.passport.expired': 'La validité du passeport a expiré.',
  'externalIndexedData': 'Plusieurs passeports trouvés',
  'client.extended.chargedBack': 'Cette demande de visa a fait l\'objet d\'un remboursement. La personne peut toujours procéder à la sortie, mais elle doit rembourser les frais d\'entrée en espèces uniquement.',
  'ask.chargedBack.confirm': 'Confirmez-vous que la personne a remboursé les frais d\'entrée ? Si vous confirmez, veuillez entrer la référence de paiement et la devise utilisée.',
  'error.payment.noReferenceChargedBack': 'Veuillez entrer une référence de paiement et la devise utilisée.',
  'client.extended.entryChargedBack': 'Cette demande de visa a fait l\'objet d\'un remboursement. La personne ne peut pas procéder à l\'enregistrement et doit créer une nouvelle entrée.',
  'tooltip.syncing': 'Le nombre de processus de demande actuellement en cours de synchronisation.',
  'adChoice': 'Connexion avec l\'annuaire actif',
  'form.username': 'Nom d\'utilisateur',
  'err.username.required': 'Le nom d\'utilisateur est requis',
  'err.username.minlength': 'Le nom d\'utilisateur doit comporter au moins 4 caractères',
  'external.down': 'Un service dont dépend notre application ne fonctionne pas actuellement. Veuillez réessayer plus tard.',
  'global.connection.server': 'Service indisponible. Désolé pour la gêne occasionnée, veuillez réessayer plus tard.',
  'global.connection.timeout': 'Délai dépassé lors du téléchargement de votre demande. Veuillez vérifier votre connexion Internet et réessayer.',
  'global.connection.problem': 'Erreur lors du téléchargement de votre demande. Veuillez vérifier que vous n\'utilisez pas de VPN, de pare-feux, que votre connexion Internet est stable et réessayez.',
  'err.passport.issuedSoon': 'Le passeport a été délivré récemment (moins d\'un mois).',
  'global.reload': 'Recharger',
  'error.passportScan': 'CertBytes(b64) : <pre>{certBytes}</pre>',
  'glpi.err.noPassiveAuthenticationCSCA': 'Le hachage du certificat du passeport n\'a pas été trouvé dans la liste principale de l\'OACI, ce qui entraîne une erreur \'err.noPassiveAuthenticationCSCA\'.',
  'glpi.err.unverifiedDoc': 'Échec de la récupération des octets du certificat pour l\'authentification passive ; cela entraîne une erreur \'err.unverifiedDoc\'.',
  'glpi.err.noPassiveAuthentication': 'Échec de l\'authentification passive ; cela entraîne une erreur \'err.noPassiveAuthentication\'.',
  'glpi.err.noConnect': 'Échec de l\'établissement de la connexion avec la puce du passeport, ce qui entraîne une erreur \'err.noConnect\'.',
  'glpi.err.noBAC': 'BAC n\'a pas pu s\'établir avec les informations MRZ fournies, déclenchant une erreur \'err.noBAC\'.',
  'glpi.err.alteredDoc': 'Les hachages des groupes de données (DGS) sont incorrects pour une ou plusieurs entrées, ce qui entraîne une erreur \'err.alteredDoc\'.',
  'glpi.err.revokedDocSigner': 'Le numéro de série du certificat du signataire du document figure sur la liste de révocation des certificats (CRL), ce qui entraîne une erreur \'err.revokedDocSigner\'.',
  'watchlistCollapse': '<p>Listes de surveillance trouvées - <span class="watchlistCategories">{categories}</span></p>',
  'watchlistResponse.level': 'Niveau d\'alerte pour cette correspondance',
  'match.type': 'Type de correspondance',
  'match.hair': 'Cheveux',
  'match.eyes': 'Yeux',
  'match.height': 'Taille',
  'match.weight': 'Poids',
  'match.race': 'Race',
  'global.category': 'Catégorie',
  'watchlist.alertLevel': 'Niveau d\'alerte de la liste de surveillance',
  'watchlist.match': 'Correspondance {numberMatch} | <span class="watchlistCategories">{matchCategory}</span>',
  'Nationalities': 'Nationalité {index}',
  'global.score': 'Score',
  'watchlist.name': 'Nom de la liste de surveillance',
  'alias': 'Alias {index}',
  'document.number': 'Numéro du document {index}',
  'document.issuer': 'Émetteur du document {index}',
  'document.type': 'Type de document {index}',
  'dateOfBirth': 'Date de naissance {index}',
  'watchlist.marks': 'Marques',
  'watchlist.image.link': 'Lien vers l\'image trouvée {index}',
  'image.match': 'Image correspondante {index}',
  'tooltip.watchlist': 'Les informations de la liste de surveillance trouvées pour cette personne.',
  'watchList.category': 'Catégorie de la liste de surveillance',
  'err.LastName.maxlength': 'Le nom de famille doit contenir moins de 50 caractères',
  'err.FirstName.maxlength': 'Le prénom doit contenir moins de 50 caractères',
  'err.OtherName.maxlength': 'Le deuxième prénom doit contenir moins de 50 caractères',
  'err.PlaceOfBirthCity.maxlength': 'La ville de naissance doit contenir moins de 50 caractères',
  'err.Phone.maxlength': 'Le numéro de téléphone doit contenir moins de 20 caractères',
  'err.Occupation.maxlength': 'La profession doit contenir moins de 50 caractères',
  'err.HostName.maxlength': 'Le nom de l\'hôte doit contenir moins de 50 caractères',
  'err.EmergencyLastName.maxlength': 'Le nom de famille du contact d\'urgence doit contenir moins de 50 caractères',
  'err.EmergencyFirstName.maxlength': 'Le prénom du contact d\'urgence doit contenir moins de 50 caractères',
  'err.EmergencyPhone.maxlength': 'Le numéro de téléphone du contact d\'urgence doit contenir moins de 20 caractères',
  'err.PlaceofIssue.maxlength': 'Le lieu de délivrance doit contenir moins de 50 caractères',
  'err.SpouseFullName.maxlength': 'Le nom du conjoint doit contenir moins de 50 caractères',
  'err.SpousePassportNumber.maxlength': 'Le numéro de passeport du conjoint doit contenir moins de 20 caractères',
  'err.SpousePlaceOfBirth.maxlength': 'Le lieu de naissance du conjoint doit contenir moins de 50 caractères',
  'err.PassportNumber.maxlength': 'Le numéro de passeport doit contenir moins de 20 caractères',
  'err.PreviousConvictionsHome.maxlength': 'Les condamnations antérieures dans le pays d\'origine doivent contenir moins de 50 caractères',
  'err.FundsAvailable.maxlength': 'Les fonds disponibles à l\'entrée doivent contenir moins de 50 caractères',
  'err.firstName.maxlength': 'Le prénom doit contenir moins de 50 caractères',
  'err.lastName.maxlength': 'Le nom de famille doit contenir moins de 50 caractères',
  'err.passportNr.maxlength': 'Le numéro de passeport doit contenir moins de 20 caractères',
  'err.otherLinkType.maxlength': 'Le type de lien de parenté doit contenir moins de 50 caractères',
  'err.id.maxlength': 'L\'ID externe doit contenir moins de 50 caractères',
  'err.name.maxlength': 'Le nom doit contenir moins de 50 caractères',
  'err.password2.maxlength': 'Le mot de passe doit comporter entre 6 et 20 caractères',
  'err.email.maxlength': 'L\'e-mail doit contenir moins de 50 caractères',
  'err.userId.maxlength': 'L\'ID utilisateur doit contenir moins de 50 caractères',
  'err.phoneNumbersCsv.required': 'Le numéro de téléphone est requis',
  'err.idParent.required': 'L\'ID parent est requis',
  'err.phoneNumbersCsv.maxlength': 'Le numéro de téléphone doit contenir moins de 20 caractères',
  'watchlist.level': 'Niveau d\'alerte',
  'ask.fingerprint': 'Voulez-vous prendre les empreintes digitales de la personne ?',
  'scan.fingerprint.desc': 'Lorsque vous êtes prêt, cliquez sur le bouton ci-dessous pour commencer le processus de prise des empreintes digitales de la personne. La personne doit placer son index sur le scanner lorsque la lumière rouge de l\'appareil d\'empreintes digitales s\'allume, comme indiqué par l\'officier.',
  'scan.fingerprint.noResponse': 'Aucune réponse du scanner d\'empreintes digitales. La personne a-t-elle placé son doigt sur le scanner ? L\'appareil est-il correctement connecté ?',
  'scan.fingerprint': 'Empreinte digitale',
  'fingerprint.scan.start': 'Scanner l\'empreinte digitale',
  'scan.fingerprint.success': 'Les empreintes digitales ont été scannées avec succès. La qualité de l\'empreinte scannée est de {quality}/100. Vous pouvez réessayer le processus si la qualité n\'est pas suffisante.',
  'fingerprint.notAvailable': 'Il semble que le scanner d\'empreintes digitales ne soit pas disponible, ou que les pilotes ne soient pas installés. Vous pouvez toujours procéder à l\'enregistrement sans les empreintes digitales.',
  'skip': 'Ignorer',
  'global.checkOut.fingerprint': 'Sortir avec empreinte digitale',
  'global.checkIn.fingerprint': 'Entrer avec empreinte digitale',
  'tooltip.checkOut.fingerprint': 'Sortir la personne du pays avec l\'empreinte digitale.',
  'tooltip.checkIn.fingerprint': 'Entrer la personne dans le pays avec l\'empreinte digitale.',
  'paymentError.title': 'Problème avec votre commande',
  'DYN.paymentError.desc.fail': 'Malheureusement, le paiement a échoué. Veuillez réessayer.',
  'DYN.paymentError.desc.error': 'Malheureusement, le traitement du paiement a renvoyé une erreur. Veuillez nous contacter.',
  'DYN.paymentError.desc.later': 'Malheureusement, le traitement du paiement a été temporairement refusé. Veuillez réessayer.',
  'tooltip.applicationId': 'L\'ID de la demande.',
  'client.extended.missingResource': 'La ressource demandée est actuellement indisponible. Veuillez réessayer plus tard.',
  'skip.valid': 'Étapes examinées automatiquement ignorées',
  'extension.data': 'Données de l\'extension',
  'attestantAddress': 'Adresse de l\'attestant',
  'attestantEmail': 'E-mail de l\'attestant',
  'attestantID': 'ID de l\'attestant',
  'attestantName': 'Nom de l\'attestant',
  'attestantPhone': 'Téléphone de l\'attestant',
  'attestantRelation': 'Relation avec l\'attestant',
  'daysRequested': 'Nombre de jours demandés',
  'tooltip.visaApplicationReference': 'Aller à la demande de visa liée à cette demande d\'extension',
  'extend.visaReference': 'Demande liée à cette demande d\'extension',
  'data.copyOfEntry': 'Copie de l\'entrée',
  'data.departureTicket': 'Billet de départ',
  'data.attestantDocument': 'Document de l\'attestant',
  'data.affidavitCustody': 'Déclaration sous serment de garde',
  'reasonForExtention': 'Raison de l\'extension',
  'final.ZWEVISAEXTAB': 'Cette demande d\'extension doit être approuvée par l\'officier.',
  'zwevisaExtValid': 'Extension validée',
  'zwevisaExtValid.desc': 'Cette demande d\'extension a été validée par l\'officier.',
  'zwevisaExtC.review.state': 'En attente de révision',
  'zwevisaExtC.review.desc': 'Cette demande d\'extension est en attente de révision.',
  'zwevisaExtC.finalReview.desc': 'Cette demande d\'extension a été révisée et est actuellement en attente de révision finale.',
  'zwevisaExtC.finalReview.state': 'En attente de révision finale',
  'err.AttestantName.maxlength': 'Le nom de l\'attestant doit contenir moins de 50 caractères',
  'tooltip.tableLocalUsageUntilTitle': 'Trier la demande en fonction de la date maximale de fin de séjour.',
  'tooltip.tableLocalUsageUntil': 'La date d\'expiration de la demande fait référence à la date maximale de fin de séjour.',
  'ask.usageUntil.confirm': 'Vous pouvez ici modifier la date maximale de fin de séjour. Vous ne pouvez que réduire la date.',
  'usageUntil.ask': 'Modifier la date maximale de fin de séjour (optionnel)',
  'error.bad.localUsageUntil': 'La date maximale de fin de séjour doit être inférieure à la date d\'expiration actuelle et postérieure à aujourd\'hui.',
  'no.limit': 'Pas de limite',
  'to.determined': 'À déterminer',
  'none': 'Aucun',
  'change.password.ad': 'Ayant un compte dans l\'annuaire actif, vous ne pouvez pas changer votre mot de passe ici. Pour plus d\'informations, veuillez contacter le support.',
  'noticePrint.title': 'Informations de l\'avis au visiteur imprimées pour le client',
  'reportingPost': 'Poste de rapport',
  'noticePrint.matTooltip': 'Informations de l\'avis au visiteur imprimées pour le client lors du processus d\'enregistrement',
  'err.paragraph.pattern': 'Le champ doit contenir une seule lettre non accentuée.',
  'ask.print': 'Souhaitez-vous imprimer un avis de visite ou un avis provisoire de restriction ?',
  'notice.confirm': 'Êtes-vous sûr des informations figurant sur l\'avis ?',
  'camera.printNotice': 'Imprimer l\'avis',
  'global.refusePrint': 'Imprimer et soumettre',
  'global.nextPrint': 'Imprimer et continuer',
  'err.periodFrom.periodFromGreaterThanPeriodTo': 'La période de début doit être antérieure à la période de fin.',
  'err.externalId.maxlength': 'L\'ID externe doit contenir moins de 60 caractères',
  'payment_status.DYN.pass.9': 'Payer plus tard - Confirmé',
  'payment.reject.success': 'Le paiement a été refusé avec succès.',
  'payment.reject_reason': 'Raison du refus',
  'err.fileSize': 'La taille du fichier est trop grande. La taille maximale est de : {size}',
  'scan.birthCertificate': 'Télécharger l\'acte de naissance',
  'err.scanBirthCertificate.required': 'L\'acte de naissance est requis',
  'document.zafembpass': 'Passeport de l\'ambassade ZAF',
  'preenroll': 'Préinscription',
  'screen': 'Dépistage',
  'pass': 'Passeport',
  'zafembpass': 'Passeport de l\'ambassade ZAF',
  'document.pass': 'Passeport',
  'payment.validate.success': 'Le paiement a été validé avec succès.',
  'document.screen': 'Dépistage',
  'payment.consume.print.done.DYN.zwevisa': 'La demande de visa a été validée à l\'entrée/sortie avec succès. Le reçu sera imprimé.',
  'payment.ask.print': 'Le reçu a-t-il été imprimé ?',
  'global.no.retry': 'Non (réessayer)',
  'print.receipt': 'Imprimer le reçu',
  'tooltip.printReceipt': 'Imprimer le(s) reçu(s) de la demande',
  'ask.printReceipt': 'Voulez-vous imprimer le reçu daté du <b>{date}</b> ?',
  'done.printReceipt': 'Le reçu a été imprimé avec succès.',
  'error.printReceipt': 'Une erreur s\'est produite lors de l\'impression du reçu. Veuillez réessayer.',
  'ask.selectReceipt': 'Veuillez sélectionner le reçu que vous souhaitez imprimer.',
  'error.noReceiptSelected': 'Veuillez sélectionner un reçu à imprimer.',
  'operation.OUT': 'Sortie',
  'operation.IN': 'Entrée',
  'noChoice.selectedReceipt': 'Veuillez sélectionner un reçu à imprimer.',
  'select.receipt': 'Sélectionnez le reçu',
  'receiptFor': 'Reçu Pour',
  'receiptFor.alt': 'Reçu pour',
  'paymentType': 'Type de paiement',
  'totalAmount.alt': 'Montant total',
  'totalAmount': 'Montant total',
  'totalAmountCurrency': 'Total dans la monnaie payée',
  'electronic.receipt': 'Reçu',
  'date': 'Date',
  'client': 'Client',
  'reference': 'Référence',
  'agentID': 'ID de l\'agent',
  'desc': 'Description',
  'details': 'Détails',
  'client.extended.minUsageNotMet': 'La date de validité doit être au moins un jour après la date de début.',
  'err.BirthDay.minDate': 'La date de naissance doit être inférieure à 150 ans.',
  'err.SpouseBirthDay.minDate': 'La date de naissance du conjoint doit être inférieure à 150 ans.',
  'payment.assigned.warning': 'Cette demande est actuellement en cours d\'utilisation active par un autre agent (Agent : {officerId}) il y a moins de 24 heures. Voulez-vous toujours ouvrir cette demande ?',
  'lock': 'Verrouiller',
  'tooltip.tableLockTitle': 'Utilisation active par l\'agent(s)',
  'tooltip.tableLock.me': 'Vous êtes actuellement l\'agent actif sur cette demande',
  'tooltip.tableLock.other': 'Cette demande est actuellement en cours d\'utilisation active par l\'agent {officer}',
  'client.extended.uuidNotPaid': 'Le paiement n\'a pas encore été validé. Veuillez valider le paiement avant de continuer.',
  'form.operationComments': 'Justifier l\'opération',
  'err.operationComments.required': 'La justification de l\'opération est requise',
  'err.operationComments.maxlength': 'La justification de l\'opération doit contenir moins de 500 caractères',
  'err.DateOfExpiry.maxDate': 'La date d\'expiration doit être inférieure à 10 ans à partir d\'aujourd\'hui.',
  'err.ArrivalDate.maxDate': 'La date d\'arrivée doit être inférieure à 150 ans.',
  'err.DepartureDate.maxDate': 'La date de départ doit être inférieure à 150 ans.',
  'err.periodFrom.minDate': 'La période de début doit être après aujourd\'hui.',
  'err.periodFrom.maxDate': 'La période de début doit être inférieure à 150 ans.',
  'err.periodTo.minDate': 'La période de fin doit être après aujourd\'hui.',
  'err.periodTo.maxDate': 'La période de fin doit être inférieure à 150 ans.',
  'err.reportOn.minDate': 'Le rapport doit être après aujourd\'hui.',
  'err.reportOn.maxDate': 'Le rapport doit être inférieur à 150 ans.',
  'err.PlaceofIssue.required': 'Le lieu de délivrance est requis',
  'err.borderPass.required': 'Le laissez-passer frontalier est requis',
  'exit.borderPass.success': 'La sortie sans visa a été enregistrée avec succès. Vous serez redirigé vers les détails de la demande.',
  'borderPass.emit': 'Laissez-passer frontalier émis',
  'tooltip.borderPass': 'Le laissez-passer frontalier est un document qui permet à une personne de traverser la frontière. Il est utilisé lorsque la personne ne possède pas de passeport.',
  'zweexit.title': 'Sortie sans visa',
  'borderPass.title': 'Laissez-passer frontalier',
  'print.borderPass': 'Imprimer le laissez-passer frontalier',
  'print.borderPass.ask': 'Le laissez-passer frontalier a-t-il été imprimé ?',
  'err.borderPassNumber.maxlength': 'Le numéro du laissez-passer frontalier doit contenir moins de 20 caractères',
  'err.issueDate.afterExpiryDate': 'La date de délivrance doit être antérieure à la date d\'expiration.',
  'err.BirthDay.afterExpiryDate': 'La date de naissance doit être antérieure à la date d\'expiration.',
  'err.BirthDay.afterIssueDate': 'La date de naissance doit être antérieure à la date de délivrance.',
  'apiPnrCollapse': 'PNR trouvé',
  'apipnrResponse.match': 'Correspondance {numberMatch}',
  'match.name': 'Nom',
  'match.score.name': 'Score du nom',
  'match.flightCarrier': 'Compagnie aérienne',
  'match.flightNumber': 'Numéro de vol',
  'match.documentType': 'Type de document',
  'match.documentNumber': 'Numéro de document',
  'match.documentIssuer': 'Délivreur du document',
  'match.timeType': 'Type d\'événement',
  'match.timeDate': 'Date de l\'événement',
  'match.timeScope': 'Portée de l\'événement',
  'match.nationality': 'Nationalité',
  'match.gender': 'Genre',
  'match.uuid': 'UUID',
  'match.travelerUUID': 'UUID du voyageur',
  'tooltip.apiPnr': 'Les informations PNR trouvées pour cette personne.',
  'match.documentIssued': 'Date de délivrance du document',
  'match.documentExpiration': 'Date d\'expiration du document',
  'match.documentScore': 'Score du document',
  'match.timeScore': 'Score de l\'événement',
  'match.flightOriginDomestic': 'Origine domestique du vol',
  'match.flightOriginIcao': 'OACI de l\'origine du vol',
  'match.flightOriginLat': 'Latitude de l\'origine du vol',
  'match.flightOriginLng': 'Longitude de l\'origine du vol',
  'match.flightOriginIata': 'IATA de l\'origine du vol',
  'match.flightOriginCity': 'Ville de l\'origine du vol',
  'match.flightOriginState': 'État de l\'origine du vol',
  'match.flightOriginCountry': 'Pays de l\'origine du vol',
  'match.flightOriginContinent': 'Continent de l\'origine du vol',
  'match.flightOriginName': 'Nom de l\'origine du vol',
  'match.flightOriginTimezone': 'Fuseau horaire de l\'origine du vol',
  'match.flightDestinationDomestic': 'Destination domestique du vol',
  'match.flightDestinationIcao': 'OACI de la destination du vol',
  'match.flightDestinationLat': 'Latitude de la destination du vol',
  'match.flightDestinationLng': 'Longitude de la destination du vol',
  'match.flightDestinationIata': 'IATA de la destination du vol',
  'match.flightDestinationCity': 'Ville de la destination du vol',
  'match.flightDestinationState': 'État de la destination du vol',
  'match.flightDestinationCountry': 'Pays de la destination du vol',
  'match.flightDestinationContinent': 'Continent de la destination du vol',
  'match.flightDestinationName': 'Nom de la destination du vol',
  'match.flightDestinationTimezone': 'Fuseau horaire de la destination du vol',
  'match.localDepartureDate': 'Date de départ locale',
  'match.localDepartureTime': 'Heure de départ locale',
  'match.localDepartureTotalMinutes': 'Minutes totales de départ local',
  'match.localDepartureDay': 'Jour de départ local',
  'match.localDepartureZone': 'Zone de départ locale',
  'match.airportDepartureDate': 'Date de départ de l\'aéroport',
  'match.airportDepartureTime': 'Heure de départ de l\'aéroport',
  'match.airportDepartureTotalMinutes': 'Minutes totales de départ de l\'aéroport',
  'match.airportDepartureDay': 'Jour de départ de l\'aéroport',
  'match.airportDepartureZone': 'Zone de départ de l\'aéroport',
  'match.utcDepartureDate': 'Date de départ UTC',
  'match.utcDepartureTime': 'Heure de départ UTC',
  'match.utcDepartureTotalMinutes': 'Minutes totales de départ UTC',
  'match.utcDepartureDay': 'Jour de départ UTC',
  'match.utcDepartureZone': 'Zone de départ UTC',
  'match.originalDeparture': 'Départ d\'origine',
  'match.flightDepartureUtcstr': 'Départ de vol UTC',
  'match.localArrivalDate': 'Date d\'arrivée locale',
  'match.localArrivalTime': 'Heure d\'arrivée locale',
  'match.localArrivalTotalMinutes': 'Minutes totales d\'arrivée locale',
  'match.localArrivalDay': 'Jour d\'arrivée locale',
  'match.localArrivalZone': 'Zone d\'arrivée locale',
  'match.airportArrivalDate': 'Date d\'arrivée de l\'aéroport',
  'match.airportArrivalTime': 'Heure d\'arrivée de l\'aéroport',
  'match.airportArrivalTotalMinutes': 'Minutes totales d\'arrivée de l\'aéroport',
  'match.airportArrivalDay': 'Jour d\'arrivée de l\'aéroport',
  'match.airportArrivalZone': 'Zone d\'arrivée de l\'aéroport',
  'match.utcArrivalDate': 'Date d\'arrivée UTC',
  'match.utcArrivalTime': 'Heure d\'arrivée UTC',
  'match.utcArrivalTotalMinutes': 'Minutes totales d\'arrivée UTC',
  'match.utcArrivalDay': 'Jour d\'arrivée UTC',
  'match.utcArrivalZone': 'Zone d\'arrivée UTC',
  'match.originalArrival': 'Arrivée d\'origine',
  'match.flightArrivalUtcstr': 'Arrivée de vol UTC',
  'match.flightBound': 'Vol lié',
  'print.receipt.select': 'Le reçu sera imprimé.',
  'ask.notice': 'Imprimer un avis au visiteur ou un avis de restriction provisoire',
  'tooltip.checkIn.notice': 'Enregistrer l\'entrée de la personne dans le pays et imprimer l\'avis au visiteur ou un avis de restriction provisoire.',
  'global.checkIn.notice': 'Enregistrement avec un avis',
  'event.downgrade': 'Cette demande a été rétrogradée de <b>{previousPayment}</b> à <b>{newPayment}</b> le {date} par l\'utilisateur <b>{userId}</b>.',
  'form.numberPeople': 'Nombre de personnes voyageant avec le demandeur',
  'feedbackForm': 'Formulaire de retour',
  'form.totalSpend': 'Dépense totale durant le séjour',
  'countryPermanentResidence': 'Pays de résidence permanente',
  'countryDestination': 'Pays de destination',
  'form.feedbackComments': 'Commentaires supplémentaires',
  'feedback.submit': 'Soumettre le retour',
  'err.fundsSpend.required': 'La dépense totale durant le séjour est requise',
  'err.fundsCurrency.required': 'La devise de la dépense est requise',
  'err.countryResidence.required': 'Le pays de résidence permanente est requis',
  'err.countryDestination.required': 'Le pays de destination est requis',
  'err.feedbackComment.required': 'Les commentaires supplémentaires sont requis',
  'feedback.submitted': 'Le retour a été soumis avec succès.',
  'start_feedback': 'Donner un retour'
};
