import {ModelInterface} from './entity';
import {UserPlace} from './place';
import {Currency} from './request';

export enum UserPlatformRole {
  CUSTOMER = 0,
  WORKER = 1,
  ORBIS_CONSUMER = 2,
  ADMIN = 3,
  PARTNER_REGISTRER = 4,
  KIOSK = 5
}

export const UserRoles = [
  {name: 'customer', value: 0},
  {name: 'worker', value: 1},
  {name: 'orbis_consumer', value: 2},
  {name: 'admin', value: 3},
  {name: 'partner_registrer', value: 4},
  {name: 'kiosk', value: 5}
];
export enum UserPermission {
  ALLOW_CREATE = 'allowCreate',
  ALLOW_CONSUME = 'allowConsume',
  ALLOW_CONTROL = 'allowControl',
  ALLOW_ADMIN = 'allowAdmin',
}
export const enum UserType {
  ONE_SHOT = 0,
  RECURRENT = 1
}

export interface User extends ModelInterface<number> {
  userId: string;
  role: UserPlatformRole;
  lastLogin: string;
  lastUseApproximation: string;
  loginCount: number;
  accountName: string;
  passwordHash: string;
  password?: string;
  salt: string;
  iatNotBefore: string;
  lockedAt: string;
  name: string;
  confirmedAt: string;
  lastConnectIp: string;
  type: UserType;
  typeRecurrent: string;
  fingerBytes?: string;
  links: UserPlace[];
  server: ServerInfo;
  finger?: boolean;
  rates: {date?: string, base?: string, rates?: {[id: string]: number}};
}

export enum DocumentType {
  SCREEN = 'screen',
  PASS = 'pass',
  URGENTPASS = 'urgentpass',
  EMBASSYPASS = 'embassypass',
  PREENROLL = 'preenroll',
  ZWEVISA_DOUBLE = 'zwevisa_double',
  ZWEVISA_MULTIPLE = 'zwevisa_multiple',
  ZWEVISA_KAZA = 'zwevisa_kaza',
  ZWEVISA = 'zwevisa',
  ZWEVISAARR = 'zwevisaarr',
  ZWEVISAARR_DOUBLE = 'zwevisaarr_double',
  ZWEVISAARR_KAZA = 'zwevisaarr_kaza',
  ZWEVISAARR_MULTIPLE = 'zwevisaarr_multiple',
  ZWEENTRY = 'zweentry',
  ZWEEXIT = 'zweexit',
  ZWEPROVRESPERMIT = 'zweprovrespermit',
  ZWEVISAEXTC = 'zwevisaextc',
  ZWEVISAEXTAB = 'zwevisaextab',
  ZWEFEEDBACK = 'zwefeedback',
  BORDER_PASS = 'borderPass'
}

export interface Document {
  type: DocumentType;
  id: string;
  amountCents: number;
  amountCentsDyn: string;
  currency: Currency;
  active: boolean;
  schema: string;
  autoConfirm: string;
  manualConfirmRounds: number;
  autoTransfer: 'orbis' | undefined;
  customer: boolean;
  kiosk: boolean;
  indexedFields: string[];
  metadataPrecedence: boolean;
  multiUsage: boolean;
  usageCount: number;
  isUsage: string;
  usageUntilFromNowMillis: string;
  localUsageUntilFromNowMillis: string;
  paidOnline: boolean;
  onConsume?: 'visaextension1' | 'visaextension2';
  valueRange: number;
}

export interface ServerInfo {
  testMode: boolean;
  selfRegister: boolean;
  languages: string[];
  domain: string;
  dailyOtpRequired: boolean;
  emailConfig: {
    copyrightName: string,
    address: string,
    email: string,
    phone: string,
    imageLogo: string
  };
  jwtCertificate: string;
  regDays: number;
  serverPhones: string[];
  country: string;
  paymentRequests: Document[];
  maxDailyPayments: number;
  mnta: {
    host: string,
    ips: string[],
    applicationId: string
  };
}

export interface UserTable {
  id: string;
  userId: string;
  name: string;
  role: UserPlatformRole;
  email: string;
  creationDate: string;
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other'
}

export enum MaritalStatus {
  MARRIED = 'married',
  SINGLE = 'single',
  SEPARATED = 'separated',
  DIVORCED = 'divorced',
  WIDOWED = 'widowed'
}
