<div id="layout" class="f_col">
  <app-view-title [bgImage]="api.env.theme.header_background">
    {{'participants.title' | translate}} - {{cart.currentApplication.travelName}}
  </app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_scroll-wrapper_l resp_c-50">
      <app-view-deco-image imageUrl="assets-projects/images/image_people.svg"></app-view-deco-image>
      <app-paragraph class="mb_10" innerhtml="{{'participants.desc' | translate : {participantText: (api.listPersons.length ? 'participant.ready' : 'participant.missing') | translate} }}"></app-paragraph>
      <div class="f_col gap_10 mb_40" *ngIf="show">
        <ng-container *ngFor="let person of api.listPersons; let i = index">
          <app-checkbox-element [id]="'person_'+i"
                                leftIcon="user"
                                [matTooltip]="person.disabledBecauseReview ? ('tooltip.disabledBecauseReview' | translate) : ''"
                                [ngClass]="{'disabledCheckboxElement': person.disabledBecauseReview}"
                                [disableClick]="person.disabledBecauseReview"
                                [text]="person?.firstName + ' ' + person?.lastName"
                                [checked]="personIsSelected(person)"
                                (click)="click($event, person)"
                                (onChange)="change($event.checked, person)"></app-checkbox-element>
        </ng-container>
        <app-slide-element leftIcon="address-book" (btnClicked)="openDialogNewPerson()">
          {{('people.addPerson.DYN.'+api.env.type) | translate}}
        </app-slide-element>
      </div>
      <div class="resp_minSize_l mb_20 mt_20 flex gap_10">
        <app-button size="xsm" color="transparent" border="true" class="fullWidth" (btnClicked)="nav.to('travel-name')">
          {{'global.back' | translate}}
        </app-button>
        <app-button size="xsm" [disabled]="selected.length < 1" type="button" class="fullWidth" (btnClicked)="next()">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
  <div class="f_align_end mb_20 resp_maxSize_l">
    <app-back-btn class="mr_10" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <app-back-btn [navTo]="{route: 'travel-name'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button type="button" [disabled]="selected.length < 1" class="fullWidth" (btnClicked)="next()">
        {{'global.continue' | translate}}
      </app-button>
    </div>
  </div>
</div>
