<div id="layout" class="f_col">
  <app-view-title fromRoute="pre-enroll"
                  [chooseLangBtn]="false">{{'trips.title' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_scroll-wrapper_l resp_c-50">
      <app-view-deco-image imageUrl="assets-projects/images/image_documents.svg"></app-view-deco-image>
      <div class="flex f_col gap_10">

      </div>

      <app-paragraph textAlign="center" *ngIf="request.allBatchIds?.length === 0">
        {{'no_trips' | translate}}
      </app-paragraph>

      <div *ngIf="request.allBatchIds?.length > 0">
        <app-paragraph class="mt_10 mb_10"><b>{{'trips.desc' | translate}}</b></app-paragraph>
        <div class="flex f_col gap_10">
          <app-desc-card
            *ngFor="let app of request.allMyApplications"
            type="1"
            [smSizeL]="true"
            [leftIcon]="request.getHighestSeverityTheme(app).icon"
            [leftIconColor]="request.getHighestSeverityTheme(app).iconColor"
            descL="<div class='f_col gap_10'><b>{{request.getHighestSeverityTheme(app).state}}</b><span>{{request.getHasPendingPayment(app.requests) ? '' : ('#'+app.batchId)}}</span></div>"
            descR1="{{'count' | translate}}: <b>{{app.requests.length}}</b>"
            [descR2]="('last_update' | translate) +' '+ (request.getLatestModificationDate(app) | date: 'dd/MM/yyyy HH:mm')"
            (btnClicked)="app.requests.length > 1 ? nav.to('tracking-control', undefined, {queryParams: {batchId: app.batchId}}) : nav.to('request-account/'+app.requests[0].id) "></app-desc-card>
        </div>
      </div>

      <div class="resp_minSize_l mt_20 flex gap_10">
        <app-button size="xsm" color="transparent" border="true" class="fullWidth" (btnClicked)="nav.to('dashboard')">
          {{'global.back' | translate}}
        </app-button>
      </div>

    </div>
  </div>
  <div class="f_align_end mb_20 resp_maxSize_l">
    <app-back-btn class="resp_maxSize_l" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button color="transparent" border="true" formId="form" class="resp_maxSize_l fullWidth" (btnClicked)="nav.to('dashboard')">{{'global.back' | translate}}</app-button>
    </div>
  </div>
</div>
