<div id="layout" class="f_col">
  <app-view-title fromRoute="document-management" [chooseLangBtn]="false">{{'dashboard.my_account' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
        <app-view-deco-image imageUrl="assets-projects/images/image_profile.svg"></app-view-deco-image>
        <div>
          <app-slide-element *ngIf="!api.userRole.isKiosk" leftIcon="exchange" [disabled]="!api.isOnline" [colorRight]="!api.isOnline && '#f51d2a'" [rightIcon]="!api.isOnline ? 'wifi' : 'arrow-right'" (click)="changePassword()" class="mb_10" [matTooltip]="api.isZwevisa ? ('tooltip.changePassword' | translate) : ''">
            {{'global.changePassword' | translate}}
          </app-slide-element>
          <app-slide-element *ngIf="api.isElectronApp" (click)="nav.to('print-settings')" class="mb_10" [matTooltip]="api.isZwevisa ? ('tooltip.printSettings' | translate) : ''" leftIcon="print">
            {{'printSettings' | translate}}
          </app-slide-element>
          <app-slide-element *ngIf="!api.userRole.isKiosk && !api.userRole.isCustomer" leftIcon="keyboard" [rightIcon]="'arrow-right'" (click)="nav.to('shortcuts')" class="mb_10" [matTooltip]="api.isZwevisa ? ('tooltip.changeShortcuts' | translate) : ''">
            {{'global.changeShortcuts' | translate}}
          </app-slide-element>
          <div class="mb_10">
            <app-checkbox-element
            *ngIf="api.isElectronApp"
            class="fullWidth"
            [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.printThermal' | translate) : ''"
                            (onChange)="togglePrintSetting($event.checked)"
                            [formGrp]="form"
                            [formCtrlName]="'printThermal'"
                            [id]="'printThermal'"
                            [text]="'printThermal'"></app-checkbox-element>
          </div>
          <app-slide-element *ngIf="api.isElectronApp && (api.userRole.isWorker && api.hasPerm(UserPermission.ALLOW_ADMIN) || api.userRole.isAdmin)" leftIcon="fingerprint" class="mb_10" (click)="fingerprint.openFingerprint()">{{(api.userInfo.finger ? 'changeFingerprint' : 'addFingerprint') | translate}}</app-slide-element>
          <app-slide-element *ngIf="api.userRole.isCustomer" leftIcon="history" [disabled]="!api.isOnline" [colorRight]="!api.isOnline && '#f51d2a'" [rightIcon]="!api.isOnline ? 'wifi' : 'arrow-right'" (btnClicked)="history()" class="mb_10" [matTooltip]="api.isZwevisa ? ('tooltip.history' | translate) : ''">
            {{'payment.commandHistory' | translate}}
          </app-slide-element>
          <app-slide-element *ngIf="(api.userRole.isWorker || api.userRole.isAdmin)" leftIcon="cloud-arrow-up" class="mb_10" (click)="queue.openImportDialog()">{{'queue.import' | translate}}</app-slide-element>
          <app-slide-element *ngIf="api.env.supportedLanguages.length > 1" leftIcon="language" (btnClicked)="nav.to('choose-lang', undefined, {state:{fromRoute:'my-account'}})" class="mb_10" [matTooltip]="api.isZwevisa ? ('tooltip.chooseLang' | translate) : ''">
            {{'choose-lang.title' | translate}}
          </app-slide-element>
          <app-slide-element leftIcon="envelope" (btnClicked)="api.openSupport()" [matTooltip]="api.isZwevisa ? ('tooltip.support' | translate) : ''">
            {{'global.support' | translate}}
          </app-slide-element>
          <div class="mt_20 flex items-center f_grow gap_10 f_align_c resp_minSize_l" [matTooltip]="api.isZwevisa ? ('tooltip.signout' | translate) : ''">
            <app-button size="sm" color="red" formId="form" class="myAccount-disconnect" (btnClicked)="disconnect()">{{'global.disconnect' | translate}}</app-button>
          </div>
        </div>
    </div>
  </div>
  <div class="mb_20 resp_maxSize_l flex gap_10">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
      <app-button color="red" formId="form" class="fullWidth" (btnClicked)="disconnect()">{{'global.disconnect' | translate}}</app-button>
  </div>
</div>
