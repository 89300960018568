<div id="layout" class="home f_col">
  <app-btn-choose-lang *ngIf="api.env.supportedLanguages.length > 1"  fromRoute="home" toRoute="choose-lang" bgColor="var(--slideElementBg)" color="var(--slideElementIconLClr)" [absolute]="true"></app-btn-choose-lang>
  <div class="f_col f_center f_grow f_align_c">
    <div class="f_col resp_c-50 f_align_c">
      @if((api.getStoredItem('isBMS') || api.userRole?.isWorker) && api.env.type === DocumentType.ZWEVISA) {
        <img id="home_logo" ngSrc="assets-projects/bms.svg" priority width="200" height="200" alt="">
      } @else {
        <img id="home_logo" [ngSrc]="api.env.theme.logo" priority width="200" height="200" alt="">
      }
      <div class="mt_40 resp_minSize_l">
        <app-button size="sm" (btnClicked)="nav.to('sign-up')">{{'createAccount' | translate}}</app-button>
        <div class="mt_10 fullWidth">
          <app-button size="sm"  class="fullWidth" (btnClicked)="nav.to('sign-in')" color="transparent" border="true">{{'alreadyAccount' | translate}}</app-button>
        </div>
        <div class="mt_10 fullWidth">
          <app-button size="xsm" (btnClicked)="api.openSupport()" color="transparent">{{'global.contact_support' | translate}}</app-button>
        </div>
        <div class="fullWidth">
          <app-button size="xsm" (btnClicked)="nav.to('scan-qr')" color="transparent">{{'global.scan_qr' | translate}}</app-button>
        </div>
      </div>
    </div>
  </div>
  <div class="f_col mb_20 resp_maxSize_l">
    <app-button (btnClicked)="nav.to('sign-up')">{{'createAccount' | translate}}</app-button>
    <div class="mt_10 fullWidth">
      <app-button  class="fullWidth" (btnClicked)="nav.to('sign-in')" color="transparent" border="true">{{'alreadyAccount' | translate}}</app-button>
    </div>
    <div class="mt_10 fullWidth">
      <app-button size="sm" class="fullWidth" (btnClicked)="api.openSupport()" color="transparent">{{'global.contact_support' | translate}}</app-button>
    </div>
    <div class="fullWidth">
      <app-button size="sm" class="fullWidth" (btnClicked)="nav.to('scan-qr')" color="transparent">{{'global.scan_qr' | translate}}</app-button>
    </div>
  </div>
</div>
