import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../shared/services/api.service';
import {DialogsService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {Person} from '../../../shared/models/person';
import {CartService} from '../../../shared/services/cart.service';
import {DialogNewPersonComponent} from '../../../components/dialogs/dialog-new-person/dialog-new-person.component';
import {Direction} from '../../../shared/models/forms';
import {RequestService} from 'src/app/shared/services/request.service';

@Component({
  selector: 'app-2.participants',
  templateUrl: './participants.component.html',
  styleUrl: './participants.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ParticipantsComponent {

  selected: Person[] = [];
  show = true;

  constructor(public api: ApiService,
    public nav: NavigateService,
    public cart: CartService,
    private lang: LangService,
    private loader: LoaderService,
    private dialog: DialogsService,
    private request: RequestService) {
    this.selected = this.cart.currentApplication?.requests?.map(r => r.person) || [];
    if(!this.cart.currentApplication?.travelName) {
      if(this.cart.currentApplication?.isRevision) {
        this.nav.to('dashboard');
      } else if(this.api.userRole.isKiosk) {
        this.nav.to('standby-kiosk');
      } else {
        this.nav.to('travel-name');
      }
      return;
    }
  }

  ngOnInit() {
    this.api.listPersons = this.request.getPersonsWithReviewStatus(this.api.listPersons);
  }

  click(event: any, person: Person) {
    if(this.cart.currentApplication.requests?.some(r => r.person.id === person.id && r.nationality)) {
      event.preventDefault();
      this.loader.loading(true, {type: 'warn', message: 'participant.hasData', btnLabel: 'global.confirm'}).then((done  ) => {
        if(done) {
          this.show = false;
          this.change(false, person);
          this.selected = this.cart.currentApplication.requests?.map(r => r.person) || [];
          setTimeout(() => {
            this.show = true;
          });
        }
      });
    }
  }

  change(checked: boolean, person: Person) {
    if(checked) {
      this.selected.push(person);
      this.cart.currentApplication.currentPerson = person;
    } else {
      this.selected = this.selected?.filter(p => p.id !== person.id);
      if(this.cart.currentApplication.currentPerson?.id === person.id) {
        this.cart.currentApplication.currentPerson = this.selected[0] || undefined;
      }
    }
    this.cart.updateParticipants(this.selected);
    this.cart.saveCurrentApplication(false);
  }

  personIsSelected(person: Person): boolean {
    return this.selected?.some(r => r.id === person.id);
  }

  next() {
    if(this.cart.currentRequest?.latestRouteVisited) {
      this.nav.to(this.cart.currentRequest.latestRouteVisited);
    } else {
      this.nav.to('passport-type', undefined, {queryParams: {direction: Direction.NEXT}});
    }
    const msg = this.lang.transform('application.current_person') + ' <b>' + this.cart.currentApplication.currentPerson.firstName + ' ' + this.cart.currentApplication.currentPerson.lastName[0]+'.</b>';
    this.loader.loading(true, {type: 'info', custom: {innerHtml: msg}});
  }

  openDialogNewPerson() {
    this.dialog.openDialog(DialogNewPersonComponent, 'md');
  }
}
