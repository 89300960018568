import {EventEmitter, Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {Store} from '../models/store';
import {
  AllDocumentsPrintType,
  PrintableDocuments,
  PrintSettings,
  PrintSettingsDetails
} from '../models/print';
import {ApiService} from './api.service';
import {LoaderService} from 'ngx-satoris';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  printDocumentsOptions: PrintSettings;
  printerList: any;
  printLoaded: EventEmitter<AllDocumentsPrintType> = new EventEmitter<AllDocumentsPrintType>();

  constructor(private storage : StorageService,
    private loader: LoaderService,
    private api: ApiService) {
    if(this.api.isElectronApp) {
      this.storage.getFromStorage(Store.PRINT_STORE, 'settings').then((res: PrintSettings) => {
        this.printDocumentsOptions = res;
      });
      this.detectPrinterList();
    }
  }

  standardPrint() {
    window.print();
  }

  print<T>(docName : AllDocumentsPrintType, data?: T) {
    this.loader.loading(true);
    this.reset();
    setTimeout(() => {
      if(data) this.getDoc(docName).data = data;
      this.getDoc(docName).active = true;
    }, 1000);
  }

  readyToPrint(docName: AllDocumentsPrintType) {
    this.loader.loading(false);
    this.printLoaded.emit(docName);
    if(this.api.isElectronApp && this.printDocumentsOptions?.[docName]) {
      const printerFromList = this.printerList.find((printer: any) => printer.value === this.printDocumentsOptions[docName].defaultPrinter);
      const isPrinterOffline = printerFromList?.name.includes('(Offline)');
      if(isPrinterOffline) {
        window.print();
      } else {
        return electron.print(this.printDocumentsOptions[docName] as PrintSettingsDetails);
      }
    } else {
      window.print();
    }
  }

  detectPrinterList() {
    if(this.api.isElectronApp) {
      window.electron.getPrinters().then((res: {name: string, displayName: string, status: number, isDefault: boolean}[]) => {
        this.printerList = res.map(printer => {
          const isOffline = printer.status === 0x00000080;
          return {
            name: printer.displayName + (isOffline ? ' (Offline)' : ''),
            value: printer.name
          };
        });
      });
    }
  }

  getDoc(docName: AllDocumentsPrintType) {
    return PrintableDocuments.find((doc) => doc.name === docName);
  }

  private reset() {
    PrintableDocuments.forEach((doc) => {
      doc.active = false;
      doc.data = undefined;
    });
  }
}
