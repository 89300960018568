import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BluetoothService {
  active: boolean;
  done: boolean;
  doneMessage: string;
  error: boolean;
  errorMessage: string;
  BTdevices: any[];
  BTConnectedDevice: string;
  hasBackground: boolean;

  constructor() { }
}
