/* tslint:disable */
export const en_strings = {
  'error.updatePassport': 'An error occurred while updating the application. Please try again.',
  'open.visaReference': 'Open linked application',
  'print.extension': 'Print extension',
  'documents.load': 'Load all documents',
  'done.closeApplication': 'The application has been successfully closed. Would you like to create a new application based on this one\'s data?',
  'ask.closeApplication': 'This action can be used when a traveler can provide proof that an operation was completed offline, allowing this record to be considered resolved and discarded. Please confirm that you wish to close this request. This action is final and cannot be undone.',
  'approval.daysGranted.limit': 'The number of days granted cannot exceed the requested {days} days.',
  'approval.daysGranted.DYN.zwevisa': 'Nr. of days granted',
  'print.alreadyPrinted': 'The documents of this application have last been printed on the {date} by the user {user}. Would you like to print them again?',
  'tooltip.printEvents': 'A list of all print events of this application',
  'printEventsCollapse': 'All print events of this application',
  'transactionReference.alt': 'Transaction Reference',
  'paymentType.alt': 'Payment tyPe',
  'changeGender.ask': 'The gender on the application must be changed to either Male or Female.',
  'print.askMultiple': 'Would you like to include all related documents in the printout?',
  'application.id': 'Application ID',
  'printSticker': 'Print visa sticker',
  'print.receiptA5': 'Print A5 receipt',
  'correctionFields': 'Following fields were corrected:',
  'corrected.desc': 'This visa application has been corrected by the applicant on the {date}.',
  'recentlyCorrected': 'Corrected',
  'visaValidFrom': 'Entry valid from',
  'visaValidUntil': 'Entry valid until',
  'online': 'Online',
  'offline': 'Offline',
  'receiptNr': 'Receipt number',
  'issuingOfficer.alt': 'ISsuing officer',
  'placeOfIssue': 'Place of issue',
  'fileRefNr': 'File reference number',
  'tooltip.hasBeenCorrected': 'This application has been corrected by the applicant.',
  'tooltip.extendStay': 'Extend the stay of the applicant',
  'printFoldable': 'Print foldable',
  'printApprovalLetter': 'Print approval letter',
  'operation.REFUSEOUT': 'Refused check-out',
  'events': 'Events',
  'tooltip.events': 'These are the events details. Some may have additional information than others depending on the event type and the operation that was performed.',
  'form.paymentReference': 'Payment reference',
  'error.closeApplication': 'An error occurred while closing the application. Please try again.',
  'closeApplication': 'Mark as closed',
  'tooltip.closeApplication': 'Mark the application as closed. This action is irreversible.',
  'tooltip.tableNationalityTitle': 'Nationality of the applicant',
  'understand': 'I understand',
  'ExtraDocument1': 'Extra document #1',
  'ExtraDocument2': 'Extra document #2',
  'ExtraDocument3': 'Extra document #3',
  'ExtraDocument4': 'Extra document #4',
  'ExtraDocument5': 'Extra document #5',
  'paperSize': 'Paper width',
  'global.changePrinterSize': 'Change receipt paper width',
  'change_lang': 'Change language',
  'queue.export.desc': 'Would you like to export the current queue to a JSON file. <b>Your local queue will be deleted after the export</b>. Are you sure you want to proceed?',
  'queue.export': 'Export to JSON',
  'queue.import': 'Import queue from JSON',
  'nationality': 'Nationality',
  'gender': 'Gender',
  'HQReference': 'HQ Reference',
  'vendorName': 'Vendor name',
  'vendorAddress': 'Vendor address',
  'vendorEmail': 'Vendor email',
  'idRequest': 'ID of the application',
  'serialNumber': 'Serial number',
  'global.resetDefaults': 'Reset to default',
  'shortcuts.desc': 'You can set your own keyboard shortcuts below. Click on the key you want to set a new shortcut for and press the desired key on your keyboard.',
  'press.key': 'Press any key to set shortcut...',
  'dashboard.keyboardShortcuts': 'Keyboard shortcuts',
  'tooltip.changeShortcuts': 'Change the keyboard shortcuts',
  'global.changeShortcuts': 'Change shortcuts',
  'retake_picture': 'Retake picture',
  'error.faceCheck': 'An error occurred while processing the face match. Please try again.',
  'faceCheck.see_score': 'Face match',
  'recommendOrNot': 'Recommend or not',
  'recommended': 'Recommended',
  'not_recommended': 'Not recommended',
  'error.downgrade': 'An error occurred while downgrading the request. Please try again.',
  'done.downgrade': 'The request has been successfully downgraded to a Single Entry Visa.',
  'request.downgrade': 'Downgrade',
  'err.externalCode.pattern': 'External code (ICAO) must have a correct format: 4 uppercase letters',
  'request.extendStay': 'Extend stay',
  'ResidencePermitNumber': 'Residence Permit number',
  'start_continue': 'Resume payment',
  'error.payment.noInternalReference': 'An HQ reference is required for this application to be reviewed. Please wait for the reference to be provided.',
  'err.Occupation.required': 'Occupation is required',
  'err.HomeAddress.required': 'Home address is required',
  'err.PassportCountry.required': 'Passport country is required',
  'request.revision': 'Would you like to activate the Autoskip? This feature will automatically skip the reviewed steps that don\'t require your input.',
  'notice.BORDER_PASS': 'Border Pass',
  'form.borderPassNumber': 'Border pass number',
  'form.dateOfBirth': 'Date of birth',
  'form.issueDate': 'Issue date',
  'form.authorizedCrossingPoints': 'Authorized crossing points',
  'form.issuedBy': 'Issued by',
  'err.borderPassNumber.required': 'Border pass number is required',
  'err.dateOfBirth.required': 'Date of birth is required',
  'err.expiryDate.required': 'Expiry date is required',
  'err.authorizedCrossingPoints.required': 'Authorized crossing points are required',
  'err.issuedBy.required': 'Issued by is required',
  'err.issueDate.required': 'Issue date is required',
  'form.externalCode': 'External code (ICAO)',
  'err.invoke': 'Error',
  'ask.pendingRequest': 'You have an awaiting correction. Would you like to open it now?',
  'entry.confirmation': 'Are you sure that all the information provided is correct? By clicking "Confirm", you will submit the application.',
  'free': 'Free',
  'tooltip.prepareLandingVisa': 'Prepare visa',
  'last_update': 'Last update',
  'count': 'Count',
  'tooltip.linkedExtensions': 'This is a stay extension. Click to view the details of it.',
  'extension': 'Extension',
  'client.extended.badPaymentMetadata': 'The payment metadata is invalid. Please try again or contact support if the issue persists.',
  'DaysRequested': 'Days requested',
  'payment.cancelled': 'The payment has been cancelled. Please try again if you wish to proceed. You will be redirected to the previous page.',
  'payment.declined': 'The payment has been declined. Please check your payment details and availability of funds. You will be redirected to the previous page.',
  'extension.ref': 'Stay extension Ref.',
  'extensions.desc': 'Stay extension(s) awaiting review',
  'extend': 'Extend',
  'err.SpouseBirthDay.maxDate': 'Spouse birth date must be before today',
  'CheckboxVisaExtendFees': 'I confirm that the information provided is true and accurate and I agree to the stay extension fees, terms, and privacy policy.',
  'err.AttestantPhone.pattern': 'Phone number must have a correct format, for example: +263771234567',
  'err.AttestantEmail.email': 'Attestant email must have a correct format',
  'err.AttestantDocument.required': 'Attestant document is required',
  'err.AffidavitCustody.required': 'Affidavit of custody is required',
  'err.CopyOfEntry.required': 'Copy of entry is required',
  'err.DepartureTicket.required': 'Departure ticket is required',
  'AttestantDocument': 'Attestant document',
  'AffidavitCustody': 'Affidavit of custody',
  'CopyOfEntry': 'Copy of entry',
  'DepartureTicket': 'Departure ticket',
  'DYN.Self': 'Self',
  'DYN.Spouse/Dependent': 'Spouse/Dependent',
  'DYN.Employee/Employer': 'Employee/Employer',
  'DYN.Co-Director': 'Co-Director',
  'DYN.Lawyer': 'Lawyer',
  'VisaReference': 'Visa reference',
  'ReasonForExtension': 'Reason for extension',
  'AttestantID': 'Attestant ID',
  'AttestantName': 'Attestant name',
  'AttestantPhone': 'Attestant phone',
  'AttestantEmail': 'Attestant email',
  'AttestantAddress': 'Attestant address',
  'AttestantRelation': 'Attestant relation',
  'err.VisaReference.required': 'Visa reference is required',
  'err.ReasonForExtension.required': 'Reason for extension is required',
  'err.AttestantID.required': 'Attestant ID is required',
  'err.AttestantName.required': 'Attestant name is required',
  'err.AttestantPhone.required': 'Attestant phone is required',
  'err.AttestantEmail.required': 'Attestant email is required',
  'err.AttestantAddress.required': 'Attestant address is required',
  'err.AttestantRelation.required': 'Attestant relation is required',
  'start_extend': 'Extend the stay',
  'visaExtend.title': 'Stay extension',
  'days': 'days',
  'day': 'day',
  'err.Email.email': 'Email must have a correct format',
  'err.EmergencyEmail.email': 'Emergency contact email must have a correct format',
  'kiosk.standby.text1': 'Start by filling in your declaration in just a few clicks on this tablet or online.',
  'kiosk.standbyDesc.text1': 'Before you begin, please note the following information :',
  'kiosk.standbyDesc.li1': 'You will be filling out an electronic form that collects your personal data and trip details.',
  'kiosk.standbyDesc.li2': 'All information provided must be accurate and match your travel documents (passport, visa, etc.).',
  'kiosk.standbyDesc.li3': 'After completing the form, you can proceed directly to the immigration counter with your identification document(s).',
  'kiosk.standbyDesc.li5': 'If you have any difficulties, feel free to ask staff at the border post for assistance.',
  'kiosk.standbyDesc.text2': 'Click "Start" when you are ready to complete your entry declaration.',
  'kiosk.success.title': 'Application Successfully Submitted',
  'kiosk.success.text1': 'You can now proceed to the immigration counter with your travel documents. An officer will conduct the final checks and validate your entry into the country.',
  'kiosk.success.text2': 'If you have any questions or difficulties, feel free to ask for assistance from the staff available.',
  'confirm.revision': 'Confirm revision',
  'print.again': 'No, print again',
  'print.isDone': 'Has the notice been successfully printed? Please make sure to provide the printed notice to the applicant.',
  'err.otherMissingDocument.required': 'Other missing document is required',
  'err.reportAt.required': 'Report at is required',
  'err.reportOn.required': 'Report on is required',
  'err.reportInterval.required': 'Report interval is required',
  'err.address.required': 'Address is required',
  'err.periodFrom.required': 'Period from is required',
  'err.periodTo.required': 'Period to is required',
  'form.hostPhone': 'Host phone',
  'err.hostPhone.required': 'Host phone is required',
  'transit': 'Transit',
  'conditionalEntry': 'Conditional entry',
  'departure': 'Departure',
  'err.host.required': 'Host is required',
  'err.purpose.required': 'Purpose is required',
  'err.fullNames.required': 'Full name is required',
  'err.officer.required': 'Officer is required',
  'err.paragraph.required': 'Paragraph is required',
  'err.dateOfRefusal.required': 'Date of refusal is required',
  'form.paragraph': 'Paragraph',
  'form.dateOfRefusal': 'Date of refusal',
  'form.purpose': 'Purpose',
  'form.host': 'Host',
  'form.officer': 'Officer',
  'form.reportAt': 'Report at',
  'form.reportOn': 'Report on',
  'form.reportInterval': 'Report interval',
  'notice.desc.RESTRICTION': 'The Provisional Restriction Notice informs the applicant of the document(s) required to complete the process. You may select the missing document(s) that the applicant must provide within a specified period.',
  'notice.REFUSAL': 'Refused Entry Notice',
  'notice.desc.REFUSAL': 'The applicant is refused entry into the country.',
  'notice.desc.REPORTING': 'The notice to Visitor allows the applicant to enter/leave the country but may be subject to further reporting. You may select missing document(s) that the applicant must provide within a specified period.',
  'err.hqReference.required': 'HQ reference is required',
  'err.stationReference.required': 'Station reference is required',
  'err.reportLocation.required': 'Report location is required',
  'err.daysUntilReport.required': 'Days until report is required',
  'err.businessAddress.required': 'Business address is required',
  'err.residentialAddress.required': 'Residential address is required',
  'form.stationReference': 'Station reference',
  'form.fullNames': 'Full name',
  'form.reportLocation': 'Report location',
  'form.daysUntilReport': 'Days until report',
  'form.otherMissingDocument': 'Other missing document',
  'form.residentialAddress': 'Residential address',
  'form.businessAddress': 'Business address',
  'form.hqReference': 'HQ reference',
  'nationalPassport': 'National passport',
  'residentPermit': 'Residence Permit',
  'proofOfInvestment': 'Proof of investment',
  'evidenceOfMeans': 'Evidence of means',
  'evidenceOfResidentialStatus': 'Evidence of residential status',
  'studentScholarPermit': 'Student/scholar permit',
  'radiologicalCertificate': 'Radiological certificate',
  'temporaryPermit': 'Temporary permit',
  'evidenceOfEmployment': 'Evidence of employment',
  'temporaryEmploymentPermit': 'Temporary employment permit',
  'fullAddressOfDestination': 'Full address of destination',
  'appealOutcome': 'Appeal outcome',
  'request.notice': 'Select a notice to print',
  'notice.title': 'Available notices to print',
  'notice.desc': 'Please select a notice from the list below. After filling in the required fields, you can print the notice for the applicant.',
  'notice.RESTRICTION': 'Provisional Restriction Notice',
  'notice.REPORTING': 'Notice to Visitor',
  'notice.RESTRICTION.desc': 'The applicant must provide missing document(s) within a specified period.',
  'notice.REFUSAL.desc': 'The applicant is refused entry into the country.',
  'notice.REPORTING.desc': 'The applicant is allowed entry but may be subject to further reporting.',
  'passportType.kioskWarning': 'The selected nationality is under Category C regime. Please proceed to an immigration officer right now.',
  'free.charge.total': 'Total is free of charge',
  'err.entryPoints': 'An error occurred while loading the planned ports of entry',
  'err.PreviousConvictionsHomeAsk.required': 'Please answer the question about previous convictions in your home country',
  'summary.your_meta': 'Your data (click to open)',
  'summary.payOnline': 'I prefer to pay online now rather than at the officer\'s desk upon arrival.',
  'summary.payOnline.desc': 'Selecting the option below takes you to the payment page. If you choose not to pay online, you\'ll pay the visa fees at the officer\'s desk upon arrival for "Visa on arrival" type(s) of visa.',
  'summary.meta': '\'s data (click to open)',
  'EmergencyLastName': 'Emergency contact last name',
  'EmergencyFirstName': 'Emergency contact first name',
  'EmergencyEmail': 'Emergency contact email',
  'EmergencyPhone': 'Emergency contact phone',
  'err.EmergencyLastName.required': 'Emergency contact last name is required',
  'err.EmergencyFirstName.required': 'Emergency contact first name is required',
  'err.EmergencyEmail.required': 'Emergency contact email is required',
  'err.EmergencyPhone.required': 'Emergency contact phone is required',
  'person.name': 'Selected person\'s name',
  'DYN.identity-card': 'Identity card',
  'DYN.resident-permit': 'Temporary Residence permit',
  'DYN.identity-card.desc': 'An identity card is a government-issued document used for domestic identification purposes.',
  'DYN.resident-permit.desc': 'A Temporary Residence Permit is an official document issued by a government allowing a foreign national to reside in a country for a specified period.',
  'err.visaFeesAndTerms.required': 'Please accept the visa fees and the terms of use and privacy policy',
  'err.NonOrdinaryPassMission.required': 'Reason for the official visit is required',
  'NonOrdinaryPassMission': 'Reason for the official visit',
  'err.PreviousConvictionsHome.required': 'Previous conviction(s) in home country is required',
  'PreviousConvictionsHomeAsk': 'Have you, or any of your dependants, been convicted of any crime in your home country?',
  'PlaceOfBirthCountry': 'Country of birth',
  'PlaceOfBirthCity': 'City of birth',
  'OtherName': 'Other name(s)',
  'travelName': 'Application reference',
  'plannedEntry': 'Planned port of entry',
  'summary.title': 'Summary',
  'urgent': 'Urgent',
  'visaType': 'Type of document',
  'passportType': 'Type of identification',
  'err.plannedEntry.required': 'Planned port of entry is required',
  'understood': 'I agree',
  'passportType.acknowledge': 'By selecting this passport type, you acknowledges that failure to comply with the conditions of use may result in denial of entry.',
  'form.travelName.already': '<b>This application has already been submitted with the application reference below. If you want to change the reference, please start a new application.</b>',
  'application.current_person': 'You are currently editing the application for',
  'DYN.USD': '$ USD',
  'DYN.EUR': '€ EUR',
  'DYN.ZWL': 'ZWL',
  'DYN.GBP': '£ GBP',
  'DYN.BWP': 'BWP',
  'participants.edit': 'Edit the applicants',
  'request.existSaved': 'A saved application has been found, would you like to load its data? (If you click "no", the application will be deleted and you will start a new one)',
  'participant.hasData': 'You have already entered data for this participant. If you continue, the data will be lost. Are you sure you want to continue?',
  'participants.title': 'Applicant(s)',
  'participants.desc': 'Please select yourself and/or the person(s) that will be part of this application.</br><b>The payment at the end will be made for all the selected persons.</b></br></br>You may add/remove a person or edit their application at any time by clicking on the name that will appear above the form.</br></br>{participantText}',
  'participant.ready': 'When you are ready, click on "Continue".',
  'participant.missing': 'Before continuing, please create at least one person.',
  'travelName.title': 'Application reference',
  'form.travelName': 'Application reference',
  'form.travelName.desc': 'The application reference will be used to identify your application more easily.',
  'err.travelName.required': 'Application reference is required',
  'nationality.title': 'Nationality',
  'nationality.desc': 'Please select your nationality. The list of available document types will vary depending this choice.',
  'passportType.title': 'Document type',
  'passportType.desc': 'Please select the type of document you are using for this application.',
  'visaType.title': 'Available entry options',
  'visaType.desc': 'Please select the entry option you wish to apply for. This list varies depending on your nationality and type of passport.',
  'urgent.title': 'Is this urgent?',
  'urgent.desc': 'If this visa application is <b>urgent</b>, select this option. The processing time will be shorter, but an <b>additional fee</b> will be charged for this service.</br></br>Please note that the processing time may vary depending on the type of visa and the country of application.',
  'urgent.checkbox': 'I want my visa application to be processed as a matter of urgency and I understand that an additional fee will be charged for this service.',
  'visa.paidOnArrival.title': 'Visa paid on arrival',
  'visa.paidOnArrival.desc': 'Allows eligible travelers to obtain a visa upon arrival at the destination\'s port of entry, simplifying the entry process for short-term visits.',
  'visa.paidOnline.title': 'Visa paid online',
  'visa.paidOnline.desc': 'Allows for one or more entry into the country within a specified period of one (1) month, requiring a new visa for subsequent entries.',
  'dashboard.zweentry': 'Declaration of entry',
  'PassportType': 'Passport type',
  'PassportCountry': 'Country emitting passport',
  'form.passportCountry': 'Country emitting passport',
  'DYN.ordinary': 'Ordinary Passport',
  'DYN.diplomatic': 'Diplomatic Passport',
  'DYN.official': 'Official Passport',
  'DYN.service': 'Service Passport',
  'DYN.refugee': 'Refugee Passport',
  'DYN.ordinary.desc': 'An ordinary passport is the most common type of passport, issued to citizens for general travel purposes.',
  'DYN.diplomatic.desc': 'A diplomatic passport is issued to diplomats and consuls for official travel and residence abroad.',
  'DYN.refugee.desc': 'A refugee passport is issued by a country to people it recognizes as refugees for the purpose of travelling abroad.',
  'DYN.official.desc': 'An official passport is issued to government employees for official travel and residence abroad.',
  'DYN.service.desc': 'A service passport is issued to government employees for official travel and residence abroad.',
  'DYN.marine': 'Marine Passport',
  'DYN.marine.desc': 'A marine passport is issued to sailors and crew members for travel and residence abroad.',
  'DYN.laissez-passer': 'Laissez-passer',
  'DYN.laissez-passer.desc': 'A laissez-passer is a travel document issued by the United Nations or its agencies for official travel and residence abroad.',
  'err.PassportType.required': 'Passport type is required',
  'Urgent': 'This is an urgent request. I understand that the processing time will be shorter, but the fees will be higher.',
  'photo.passport.scanned': 'Passport scanned now',
  'review.nr.final': 'Final review',
  'review.nr': 'Review nr. ',
  'err.ArrivalDate.maxDateMilliTo': 'A period of at least six (6) months between the date of expiry of your passport and the date of your arrival in the country is required. Please renew this passport before applying for a visa.',
  'err.DateOfExpiry.maxDateMilliTo': 'Your passport expires less than six (6) months from the date of the arrival in the country. Please renew this passport before applying for a visa.',
  'place.update.error': 'An error occurred while updating the place',
  'dashboard.kiosk.start': 'Start process',
  'form.role.kiosk': 'Kiosk',
  'currency_desc': 'Please select the amount and currency of the funds available at the time of entry:',
  'FundsAvailable': 'Amount',
  'FundsAvailableCurrency': 'Currency',
  'err.FundsAvailable.required': 'Funds available at time of entry is required',
  'err.FundsAvailableCurrency.required': ' Funds available at time of entry currency is required',
  'emergency_contact_text': 'In case of emergency, please provide the contact details of a person who can be reached.',
  'emergency_contact': 'Emergency contact / Next of kin',
  'err.BirthDay.mustBeEqualOrBefore': 'Birth date must be equal or before date of issue of the passport',
  'err.DateOfIssue.mustBeEqualOrAfter': 'Date of issue must be equal or after date of birth',
  'internet.lost': 'Internet connection lost.</br> Please check your connection.',
  'err.DateOfExpiry.maxDateMilliFromToday': 'Your passport expires less than six (6) months from today. Please renew this passport before applying for a visa.',
  'err.DepartureDate.maxDateMilliFrom': 'The departure date is too far in the future from the arrival date. Please refer to the time limit of the visa.',
  'requirements': 'Requirements',
  'err.file.size': 'File size is too big. Maximum size is: ',
  'revision.success': 'Your request update has been successfully submitted. You will receive an email with the QR code as soon as it is approved.',
  'PAYMENT_UPDATED': 'Request has been updated',
  'revision.free': 'Free revision',
  'err.Email.pattern': 'Email must have a correct format',
  'err.Phone.pattern': 'Phone number must have a correct format, for example: +263771234567',
  'revision.start': 'We have taken care to fill in the fields with the information you provided in the initial request, except for the fields that are causing problems. Please check the information and correct it. Would you like to continue?',
  'file_uploaded': 'File already uploaded and stored',
  'revision.person_not_found': 'The applicant attached to this request could not be found.',
  'refusal.desc2': 'The following fields pose an issue:',
  'start_correction': 'Start correction',
  'PAYMENT_SUSPEND_CHANGE': 'Request suspended, changes requested.',
  'payment.selectMode': 'Please select the fields that pose an issue. You can select multiple fields.',
  'suspendReason.desc': 'Please write a brief description of the revision request. This description will be sent to the requester along with the selected problem fields.',
  'refusalReason.incomplete_or_missing': 'Incomplete or missing information in the form.',
  'refusalReason.inconsistencies_or_contradictions': 'Inconsistenacies or contradictions in the provided information.',
  'refusalReason.incorrect_misleading_info': 'Incorrect, misleading, or fraudulent information.',
  'refusalReason.missing_or_illegible_documents': 'Required documents not provided, incomplete, or illegible.',
  'refusalReason.documents_not_compliant': 'Documents not compliant with requirements (quality, validity).',
  'refusalReason.non_compliance_visa_requirements': 'Non-compliance with specific visa requirements.',
  'refusalReason.terms_conditions_not_accepted': 'Terms and conditions of the visa not accepted.',
  'refusalReason.payment_issues': 'Non-payment of visa fees or payment process failure.',
  'refusalReason.problems_with_personal_details': 'Issues related to age, nationality, or marital status of the applicant.',
  'refusalReason.criminal_history_travel_issues': 'Criminal history or travel history issues.',
  'refusalReason.inadequate_purpose_of_visit': 'Inadequate or non-compliant purpose of visit for the visa type.',
  'refusalReason.security_background_concerns': 'Concerns during security or background checks.',
  'refusalReason.doubts_about_authenticity': 'Doubts about the authenticity of the information or documents provided.',
  'refusalReason.legitimacy_concerns': 'Issues identified concerning the legitimacy of the stay or invitation.',
  'refusalReason.form_entry_or_technical_issues': 'Form entry errors or technical issues in the application process.',
  'refusalReason.electronic_submission_difficulties': 'Difficulties related to the electronic submission of documents.',
  'refusalReason.host_country_capacity_or_restrictions': 'Host country capacity exceeded or current restrictions.',
  'refusalReason.exceptional_diplomatic_political_considerations': 'Exceptional diplomatic or political considerations.',
  'refusalReason.exceptional_circumstances_humanitarian_reasons': 'Exceptional circumstances or humanitarian reasons for refusal.',
  'refusalReason.other': 'Other, please specify.',
  'err.BirthDay.maxDate': 'Birth date must be before today',
  'err.DepartureDate.minDate': 'Departure date must be after today',
  'err.ArrivalDate.minDate': 'Arrival date must be after today',
  'err.DateOfExpiry.minDate': 'Date of expiry must be after today',
  'err.DateOfIssue.maxDate': 'Date of issue must be before today',
  'err.ArrivalDate.mustBeEqualOrBefore': 'Arrival date must be equal or before departure date',
  'err.DepartureDate.mustBeEqualOrAfter': 'Departure date must be equal or after arrival date',
  'qr.request_desc': 'This QR Code acts as a digital pass for visa processing. The government reserves the right to approve or deny entry at the time of visa control.',
  'PAYMENT_REFUSED': 'Payment refused',
  'request_state.PENDING': 'Pending',
  'document.Screening': 'Screening',
  'deletePerson.error.DYN.pass': 'An error occurred while deleting the person. You cannot delete a person who has already paid for a document.',
  'deletePerson.error': 'An error occurred while deleting the person. You cannot delete a person who already has a submitted application.',
  'miscellaneousTrip': 'Travel details',
  'miscellaneous_text': 'Please enter the following information truthfully',
  'err.SpouseBirthDay.max': 'Invalid spouse birth date',
  'err.ArrivalDate.min': 'Invalid arrival date',
  'err.DepartureDate.min': 'Invalid departure date',
  'err.DateOfIssue.max': 'Invalid date of issue',
  'err.BirthDay.max': 'Invalid birth date',
  'err.dependants_date_of_birth.max': 'Invalid dependant\'s date of birth',
  'err.DateOfExpiry.min': 'Invalid expiration date',
  'dependants': 'Dependants',
  'err.PassportPhoto.required': 'Recent face picture is required',
  'err.PassportScan.required': 'Passport scan is required',
  'err.ProofOfResidenceHost.required': 'Proof of residence host/booking is required',
  'err.BusinessLetter.required': 'Business letter is required',
  'err.BusinessProfile.required': 'Business profile is required',
  'err.InvitationLetterAndBusinessProfile.required': 'Invitation letter and business profile is required',
  'err.SpouseFullName.required': 'Spouse name is required',
  'err.SpousePassportNumber.required': 'Spouse passport number is required',
  'err.SpouseBirthDay.required': 'Spouse date of birth is required',
  'err.SpousePlaceOfBirth.required': 'Spouse place of birth is required',
  'err.ArrivalDate.required': 'Arrival date is required',
  'err.DepartureDate.required': 'Departure date is required',
  'err.DateOfIssue.required': 'Date of issue is required',
  'err.DateOfExpiry.required': 'Date of expiry is required',
  'err.Email.required': 'Email is required',
  'err.Phone.required': 'Phone number is required',
  'err.HostName.required': 'Host name is required',
  'err.LastName.required': 'Last name is required',
  'err.FirstName.required': 'First name(s) is required',
  'err.BirthDay.required': 'Date of birth is required',
  'err.PlaceOfBirthCity.required': 'City of birth is required',
  'err.PlaceOfBirthCountry.required': 'Country of birth is required',
  'err.Gender.required': 'Gender is required',
  'err.MaritalStatus.required': 'Marital status is required',
  'err.Nationality.required': 'Nationality is required',
  'err.PurposeOfVisit.required': 'Purpose of visit is required',
  'male': 'Male',
  'female': 'Female',
  'other': 'Other',
  'single': 'Single',
  'married': 'Married',
  'separated': 'Separated',
  'divorced': 'Divorced',
  'widowed': 'Widowed',
  'select_reason_first': 'Please select the reason for visit on the previous page to see the list of required documents.',
  'visaIntro.title': 'Application',
  'askDocument': 'Start the application',
  'cart.load': 'A saved application has been found, would you like to load its data? (If you click "no", the application will be deleted)',
  'cart.load.otherConfig': 'A saved application has been found, but the document you selected is from a different type of visa form. Would you like to continue and delete the previous application?',
  'cart.keep': 'Would you like to save your request and continue later ?',
  'err.email.pattern': 'Email must have a correct format',
  'err.phone.pattern': 'Phone number must have a correct format, for example: +263 77 123 4567',
  'remove': 'Remove',
  'dependant_add': 'Add dependant',
  'dependant': 'Dependant',
  'err.dependants_name.required': 'Name of dependant is required',
  'err.dependants_passport_number.required': 'Passport number of dependant is required',
  'err.dependants_date_of_birth.required': 'Date of birth of dependant is required',
  'err.dependants_place_of_birth.required': 'Place of birth of dependant is required',
  'upload_file': 'Upload file (< {size})',
  'PassportPhoto': 'Recent face picture',
  'PassportScan': 'Passport scan',
  'ProofOfResidenceHost': 'Proof of residence host/booking',
  'AddressAtDestination': 'Address at destination',
  'HostInvitationLetter': 'Host invitation letter',
  'ProofOfResidence': 'Proof of residence',
  'ApplicationLetter': 'Application letter',
  'ResidentialStatusOfHost': 'Residential status of host',
  'ReasonOfVisitProof': 'Reason of visit proof',
  'BusinessLetter': 'Business letter',
  'BusinessProfile': 'Business profile',
  'InvitationLetterAndBusinessProfile': 'Invitation letter and business profile',
  'InvitationLetter': 'Invitation letter',
  'LetterOfAcceptanceFromSchool': 'Confirmation letter',
  'err.AddressAtDestination.required': 'Address at destination is required',
  'err.HostInvitationLetter.required': 'Host invitation letter is required',
  'err.ProofOfResidence.required': 'Proof of residence is required',
  'err.ApplicationLetter.required': 'Application letter is required',
  'err.ResidentialStatusOfHost.required': 'Residential status of host is required',
  'err.ReasonOfVisitProof.required': 'Reason of visit proof is required',
  'err.LetterOfAcceptanceFromSchool.required': 'Confirmation letter is required',
  'err.InvitationLetter.required': 'Invitation letter is required',
  'DYN.male': 'Male',
  'DYN.female': 'Female',
  'DYN.other': 'Other',
  'DYN.single': 'Single',
  'DYN.married': 'Married',
  'DYN.separated': 'Separated',
  'DYN.divorced': 'Divorced',
  'DYN.widowed': 'Widowed',
  'passport.valid': 'The scanned document does not appear to have been tampered with or revoked and is signed by the issuing country.',
  'travel_information_title': 'Caution',
  'zwevisa_application_title': 'Before proceeding, ensure that',
  'zwevisa_application_text': '<ol><li><b>1. </b>Your information is accurate and truthful</li><li><b>2. </b>You have a MasterCard or Visa 3D Secure card and sufficient funds to pay the visa fees.</li><li><b>3. </b>By making your payment and submitting this application, you also attest that all entered information is correct and accurate. Falsifying information for visa acquisition is illegal and subject to penalty upon conviction.</li></ol>',
  'visaPrice.desc': 'You will be required to make this payment by Visa or MasterCard at the end of the application. This payment is non-refundable.',
  'visaPrice': 'Visa Price',
  'required': 'Required',
  'DYN.holiday_visa': 'Holiday',
  'DYN.live_here': 'Long-term Resident',
  'DYN.business_visa': 'Business',
  'DYN.conferencing_visa': 'Conference',
  'DYN.transit_visa': 'Transit',
  'DYN.student_visa': 'Study',
  'paypal': 'PayPal',
  'document': 'Document',
  'Dependants': 'Dependants',
  'dependants_name': 'Name',
  'dependants_passport_number': 'Passport number',
  'dependants_date_of_birth': 'Date of birth',
  'dependants_place_of_birth': 'Place of birth',
  'Spouse': 'Spouse',
  'SpouseFullName': 'Spouse name',
  'SpousePassportNumber': 'Spouse passport number',
  'SpouseBirthDay': 'Spouse date of birth',
  'SpousePlaceOfBirth': 'Spouse place of birth',
  'ArrivalDate': 'Expected arrival date',
  'DepartureDate': 'Expected departure date',
  'ReferencePreviousVisa': 'Reference of previous visa',
  'PreviousConvictionsHome': 'Nature of the conviction(s) in the home country',
  'travel_information': 'Travel information',
  'DateOfIssue': 'Date of issue',
  'DateOfExpiry': 'Date of expiry',
  'PlaceofIssue': 'Place of issue',
  'Email': 'Email',
  'Phone': 'Phone Number',
  'Occupation': 'Occupation',
  'HomeAddress': 'Current residential address',
  'HostName': 'Host Name',
  'form.seniority': 'Seniority grade',
  'LastName': 'Last name',
  'FirstName': 'First name(s)',
  'BirthDay': 'Date of birth',
  'PlaceOfBirth': 'Place of birth',
  'Gender': 'Gender',
  'MaritalStatus': 'Marital status',
  'CheckboxVisaFees': 'I confirm that the information provided is true and accurate and I agree to the visa fees, terms, and privacy policy.',
  'PassportNumber': 'Passport number',
  'Nationality': 'Nationality',
  'PurposeOfVisit': 'Purpose of visit',
  'VisaType': 'Visa type',
  'ResidenceProof': 'Residence proof',
  'DestinationAddress': 'Destination address',
  'visitor_details': 'Applicant details',
  'personal_details': 'Personal details',
  'contact_details': 'Contact details',
  'miscellaneous_details': 'Travel details',
  'spouse_details': 'Spouse details',
  'payment': 'Payment',
  'dashboard.documents.DYN.pass': 'Document request',
  'global.scan_qr': 'Verify QR code',
  'qr.nosuch': 'Loading... QR code not found in system yet. Verify internet connection.',
  'qr.faked': 'Server response is QR code does not exist. It is highly likely a fake!',
  'qr.quicklook': 'Key information',
  'global.toPayments.DYN.pass': 'View payments',
  'dashboard.documents.DYN.zwevisa': 'Visa application',
  'global.toPayments.DYN.zwevisa': 'View applications',
  'form.createPlaceId': 'Place ID',
  'payment.seeDuplicata': 'Open the duplicate',
  'payment.duplicate.open': 'Open the new payment ?',
  'ONE_DAY': '1 day',
  'THREE_DAYS': '3 days',
  'ONE_WEEK': '1 week',
  'TWO_WEEKS': '2 weeks',
  'ONE_MONTH': '1 month',
  'TWO_MONTHS': '2 months',
  'SIX_MONTHS': '6 months',
  'ONE_YEAR': '1 year',
  'dropdown.since': 'Since',
  'payments.hasFilter': 'Filter is active',
  'payments.clear': 'Reset',
  'payments.clearFilters': 'Clear all filters',
  'place_created.open': 'Open the place info page',
  'place_created.desc': 'The new place has been created and is now usable.',
  'payment_status.DYN.NONE': '-- No filter --',
  'dropdown.state': 'Status',
  'payment.create_noExternalId': 'The payment is registered, however it requires the validation of a supervisor before it can be consumed. An email will be sent to you as soon as the payment is accepted or refused.',
  'payment.qrList.title': 'List of QR payments',
  'see.qr_list': 'See the list of QR codes',
  'form.role.partner_registrer': 'Partner register',
  'form.reason': 'Reason',
  'err.reason.required': 'Reason is required',
  'global.reject': 'Reject',
  'payment.validate_desc': 'Are you sure you wish to validate this payment ?',
  'payment.reject_desc': 'Are you sure you wish to reject this payment ? Don\'t forget to add a reason to it',
  'qr.title': 'QR code',
  'payments.count.DYN.pass': 'Number of payments: ',
  'payments.count.DYN.zwevisa': 'Number of application: ',
  'dashboard': 'Dashboard',
  'dashboard.document': 'Visa application',
  'dashboard.follow_up': 'Application follow-up',
  'dashboard.people.DYN.pass': 'People',
  'dashboard.people.DYN.zwevisa': 'Saved people',
  'dashboard.my_account': 'My account',
  'form.refunded_date': 'Refunded date',
  'form.enter_message': 'Enter message',
  'form.end_date': 'End date',
  'form.start_date': 'Start date',
  'form.search_anything': 'Search anything',
  'form.invalid_end_date': 'Invalid end date',
  'form.invalid_start_date': 'Invalid start date',
  'form.id': 'ID',
  'form.enter_date_range': 'Enter a date range',
  'payments.scan': 'Scan QR',
  'print.qr.desc': 'This is a unique and secured QR code, can only be as many times as specified. Do not scratch in order to keep it usable.',
  'print.qr.title': 'QR code',
  'print.informations': 'Informations',
  'print.notice.folding': 'Folding instructions',
  'print.notice.desc2': 'In case of any question relevant to this document, please contact the support by phone or email.',
  'print.notice.desc1': 'Keep this document away from water or flames.',
  'print.notice.title': 'Notice',
  'client.extended.openSuchPayment': 'A payment is already active under this user',
  'form.choose_role': 'Choose the role',
  'create_card_payment.fail_title': 'Payment in process',
  'create_card_payment.fail_text': 'This might take a moment. You\'ll get notified by email once completed. The payment and QR code will be available in your Payments.',
  'err.externalId.required': 'Payment reference is required',
  'err.placeExternalId.required': 'External ID is required',
  'form.externalId': 'Payment reference',
  'form.userExternalId': 'External ID',
  'global.confirm': 'Confirm',
  'global.submit': 'Submit',
  'global.create': 'Create',
  'global.back': 'Back',
  'global.close': 'Close',
  'global.contact_support': 'Contact support',
  'global.previous': 'Previous',
  'global.next': 'Next',
  'global.accept': 'Accept',
  'global.under_dev': 'Under development',
  'global.logout': 'Disconnect',
  'global.support': 'Support',
  'global.consume': 'Consume',
  'global.validate': 'Validate',
  'global.refund': 'Refund',
  'global.duplicate': 'Duplicate',
  'global.delete': 'Delete',
  'global.see_more': 'See more',
  'global.update': 'Update',
  'global.modify': 'Modify',
  'global.download': 'Download',
  'global.personal': 'Personal',
  'global.print': 'Print',
  'global.print_c7': 'Print ticket',
  'global.print_a4': 'Print certificate',
  'global.checkIn': 'Check-in',
  'global.checkOut': 'Check-out',
  'choose-lang.title': 'Language preference',
  'choose-lang.desc': 'Please choose the language which will be used for this application.',
  'global.all': 'All',
  'global.personalNum': 'Personal N°',
  'global.passportNum': 'Passport N°',
  'global.not_available': 'Not available',
  'global.show_qr': 'QR code',
  'global.show_qr_signature': 'QR code (signed)',
  'global.no_results_for': 'No results found for',
  'global.delete_sure': 'This action is irreversible, are you sure you wish to delete this ?',
  'global.add_new': 'Add new',
  'global.add': 'Add',
  'global.permissions': 'Permissions',
  'global.send': 'Send',
  'global.block': 'Block',
  'global.blocked': 'Bloqued',
  'global.unblock': 'Unblock',
  'global.daily_otp': 'Daily code',
  'global.refresh': 'Refresh',
  'global.connect': 'Connect',
  'global.disconnect': 'Disconnect',
  'form.email': 'Email',
  'form.name': 'Name',
  'form.firstName': 'First name(s)',
  'form.lastName': 'Last name',
  'form.password': 'Password',
  'form.password2': 'Retype password',
  'form.linkType': 'What is this person\'s link to you ?',
  'form.userId': 'User ID',
  'form.parentId': 'Parent ID',
  'form.amount': 'Amount',
  'form.currency': 'Currency',
  'form.metadata': 'Metadata',
  'form.buyingOption': 'Bought document',
  'form.place': 'Place',
  'form.terms': 'I agree to the Terms of Service and Privacy Policy',
  'form.role': 'Role',
  'form.no_data': 'No data matching the filter',
  'form.filter': 'Filters',
  'form.phoneNumbers': 'Phone numbers',
  'form.connectUAPattern': 'User agent pattern',
  'form.connectIPRange': 'IP range',
  'form.state': 'State',
  'form.type': 'Type',
  'form.operation': 'Operation',
  'form.created_date': 'Creation date',
  'form.created_place': 'Creation place',
  'form.consumed_place': 'Consumed place',
  'form.refunded_place': 'Refunded place',
  'form.email_code': 'Code from email',
  'form.addToPlace': 'Place ID',
  'form.placeToAdd': 'Place to add',
  'form.message': 'Message',
  'form.phone': 'Phone number',
  'form.worker': 'Employee ID',
  'form.camera': 'Camera',
  'form.comment': 'Optional comment',
  'form.password_old': 'Current password',
  'form.password_new': 'New password',
  'form.password_new2': 'Retype new password',
  'form.idCard': 'ID card nr.',
  'form.type.CASH': 'Cash',
  'form.type.CARD': 'Card',
  'form.currency.EUR': 'Euro',
  'form.currency.USD': 'US Dollar',
  'form.currency.ZAR': 'Rand',
  'form.role.customer': 'Customer',
  'form.role.worker': 'Worker',
  'form.confirmed': 'Confirmed?',
  'yes': 'Yes',
  'no': 'No',
  'form.borderPass': 'Emit border pass',
  'form.role.orbis_consumer': 'Orbis consumer',
  'form.role.admin': 'Admin',
  'form.choose_currency': 'Choose a currency',
  'form.choose_place': 'Choose a place',
  'form.choose_type': 'Choose a type',
  'form.choose_buyingOption': 'Choose a document',
  'form.choose_camera': 'Choose camera',
  'form.perm1': 'Create QR code',
  'form.perm2.DYN.pass': 'Consume QR code',
  'form.perm2.DYN.zwevisa': 'Consume a visa application',
  'form.perm3.DYN.pass': 'Duplicate / Refund QR code & Z of sub places',
  'form.perm3.DYN.zwevisa': 'Review a visa application',
  'form.perm4.DYN.pass': 'Create / Manage users & Create sub places',
  'form.perm4.DYN.zwevisa': 'Create/manage places, users and visa applications',
  'done.password_changed': 'Password changed',
  'done.user_created': 'User has been created',
  'done.place_created': 'Place has been created',
  'done.account_created': 'Account has been created, check your emails for validation',
  'client.extended.clientNotAllowed': 'Your account is currently not allowed on this place. Please check your connection settings.',
  'err.submit': 'Error at form submition',
  'err.email.required': 'Email is required',
  'err.email.email': 'Email must have a correct format: email@mail.com',
  'err.password.required': 'Password is required',
  'err.password.minlength': 'Password must have between 6 and 20 characters',
  'err.password.maxlength': 'Password must have between 6 and 20 characters',
  'err.password2.required': 'Password retype is required',
  'err.password2.mustMatch': 'Passwords do not match',
  'err.role.required': 'Role is required',
  'err.name.required': 'Name is required',
  'err.firstName.required': 'First name(s) is required',
  'err.lastName.required': 'Last name is required',
  'err.terms.required': 'Terms and conditions must be accepted',
  'err.amount.required': 'Amount is required',
  'err.currency.required': 'Currency is required',
  'err.metadata.required': 'Metadata is required',
  'err.place.required': 'Place is required',
  'err.noSmsPerm': 'SMS right has not been granted',
  'err.noSmsReceive': 'Cannot bind to SMS lecture',
  'err.code.required': 'Code from email is required',
  'err.buyingOption.required': 'Buying option is required',
  'err.addToPlace.required': 'Place ID is required',
  'err.phone.required': 'Phone number is required',
  'err.message.required': 'Message is required',
  'err.text.maxlength': 'Text is too long',
  'err.old.required': 'Current password is required',
  'err.idCard.required': 'ID card nr. is required',
  'helps.showProceed': 'Show proceed',
  'helps.showScan': 'Show scan document',
  'helps.captureFingerprint': 'Capture fingerprint',
  'payment_status.DYN.undefined': 'Current state - not an operation',
  'client.extended.missingResourceLogin': 'Unable to connect, wrong credentials',
  'client.extended.linkedAccountExists': 'Account with this email already exists',
  'client.extended.attachedPayments': 'You cannot modify this person, he/she has already a created application linked to it.',
  'client.extended.badPayload': 'Bad information in request',
  'client.extended.placeNotAllowed': 'Place is not allowed',
  'client.extended.locked': 'Account is locked',
  'client.extended.clientBadOtp': 'Bad daily code',
  'time': 'Time',
  'place': 'Place',
  'home.first': 'Your account is now validated and you can log in.',
  'payOnline': 'Pay online',
  'login.sign_in': 'Sign in',
  'login.sign_up': 'Register',
  'login.forgot_password': 'Forgot password ?',
  'photo.entryCountry': 'Picture at check-in/out',
  'photo.unavailable': 'Picture is unavailable',
  'global.entry': 'Entry',
  'global.exit': 'Exit',
  'menu.create_user': 'User +',
  'menu.create_place': 'Place +',
  'menu.create_payment': 'Payment +',
  'menu.users': 'Users',
  'menu.places': 'Places',
  'menu.payments': 'Payments',
  'menu.about': 'About',
  'menu.contact': 'Contact',
  'create_user.title': 'Create a user',
  'create_payment.title': 'Create a payment',
  'create_place.title': 'Create a place',
  'users.title': 'Users database',
  'payments.title': 'Payments database',
  'payments.employee_graph': 'Payments per employee chart',
  'payments.open_scan': 'Scan QR code',
  'payments.download_csv': 'Download CSV',
  'create_card_payment.title': 'Online payment',
  'create_card_payment.success_title': 'Payment received !',
  'create_card_payment.success_text': 'Your payment has successfully been received. Your QR code has been sent to you by email and is also accessible by clicking on the button below.',
  'create_card_payment.no_data': 'This payment is no longer available here, check your emails to retrieve the QR code or click on Payments from the menu',
  'places.title': 'List of places',
  'forgot_password.title': 'Reset password',
  'forgot_password.title_new': 'Set new password',
  'forgot_password.sent': 'Confirmation sent !',
  'forgot_password.sent_text': 'A confirmation email has been sent with a link to reset your password.',
  'forgot_password.done': 'Password has been reset',
  'forgot_password.done_text': 'You have successfully reset your password. You may now sign in.',
  'welcome.first': 'Your account is validated!',
  'welcome.title_1.DYN.pass': 'Hello there !',
  'welcome.text_1.DYN.pass': 'Thanks for joining {appName}. We are proud to provide the best way to convert your payment into a secured and unique QR code.',
  'welcome.text_1.DYN.zwevisa': 'Thanks for joining {appName}. We are proud to provide the best way to apply for a visa online.',
  'welcome.text_1.1.DYN.pass': 'Let’s learn some more about us, shall we ?',
  'welcome.text_1.1.DYN.zwevisa': 'Let’s learn some more about us, shall we ?',
  'welcome.title_2.DYN.pass': 'What is {appName} ?',
  'welcome.text_2.DYN.pass': '{appName} lets you submit a payment online in exchange for a secured and unique QR code.',
  'welcome.text_2.DYN.zwevisa': '{appName} lets you apply for a visa online and receive it by email as well as a secured and unique QR code containing all the information needed for your visa.',
  'welcome.text_2.1.DYN.pass': 'This code is then usable to pay an official document such as a document, id card, etc.. at your local administration.',
  'welcome.text_2.1.DYN.zwevisa': 'This code is then usable as a proof of your approved visa application at the border.',
  'welcome.title_3': 'As a worker',
  'welcome.text_3.DYN.pass': 'With {appName}, a customer can come to you to pay for an official document. You can register the payment by card or by cash.',
  'welcome.text_3.DYN.zwevisa': 'With {appName}, you can check in and out people at the border. You can also approve or refuse visa applications depending on the situation and your permissions.',
  'welcome.text_3.1.DYN.pass': 'A secured and unique QR code is then generated and ready to print for your customer.',
  'welcome.text_3.1.DYN.zwevisa': 'You can also manage the users and places of your organization.',
  'welcome.title_4.DYN.pass': 'Even better',
  'welcome.text_4.DYN.pass': 'You can even register payments_old offline. We are confident in our safe and reliable way to generate our QR codes.',
  'welcome.text_4.DYN.zwevisa': 'You can even manage the visa applications offline. We are confident in our safe and reliable way to generate our QR codes.',
  'welcome.text_4.1.DYN.pass': 'As soon as you get back online everything will be synced up again.',
  'welcome.text_4.1.DYN.zwevisa': 'As soon as you get back online everything will be synced up again.',
  'welcome.title_5.DYN.pass': 'Enjoy !',
  'welcome.text_5.DYN.pass': 'Thanks for using {appName} and feel free to send us some feedback using the Contact button on the homepage.',
  'payment_status.DYN.zwevisa.PROPOSED': 'Pending payment',
  'payment_status.DYN.zwevisa.PAID': 'Ready to check-in',
  'payment_status.DYN.zwevisa.PAID_NOT_CONFIRMED': 'Awaiting review',
  'payment_status.DYN.zwevisa.USED': 'Checked out',
  'payment_status.DYN.zwevisa.DUPLICATED': 'Duplicated',
  'payment_status.DYN.zwevisa.REFUNDED': 'Refunded',
  'payment_status.DYN.zwevisa.EXPIRED': 'Refused',
  'payment_status.DYN.zwevisa.PENDING': 'Awaiting correction',
  'payment_status.DYN.zwevisa.DELAY_PAY_NOT_CONFIRMED': 'Pay later - Not Confirmed',
  'payment_status.DYN.zwevisa.DELAY_PAY_NOT_CONFIRMED.FREE': 'Free - Not Confirmed',
  'payment_status.DYN.zwevisa.DELAY_PAY': 'Ready to pay on arrival',
  'payment_status.DYN.pass.PROPOSED': 'Proposed',
  'payment_status.DYN.pass.PAID': 'Paid',
  'payment_status.DYN.pass.PAID_NOT_CONFIRMED': 'Unconfirmed',
  'payment_status.DYN.pass.USED': 'Used',
  'payment_status.DYN.pass.DUPLICATED': 'Duplicated',
  'payment_status.DYN.pass.REFUNDED': 'Refunded',
  'payment_status.DYN.pass.EXPIRED': 'Expired',
  'payments.make_bundle': 'Make bundle',
  'payments.validate': 'Confirm',
  'passportPage.askPassport': 'Passport application',
  'documentContinue.title': 'Continue shopping',
  'documentContinue.stop': 'Redo your purchase',
  'documentContinue.desc1': 'You have already started the application process for a document.',
  'documentContinue.desc2': 'Would you like to continue your purchase?',
  'payment.history': 'Payments commandHistory',
  'permission.no_place_found': 'You don\'t have a new place available for this user',
  'bt.available_devices': 'Available bluetooth devices',
  'bt.no_available_devices': 'No available bluetooth devices detected',
  'emailSend.title': 'Email verification',
  'emailSend.description': 'In order to validate your account, you must click on the link contained in a validation email sent to your email address within 24 hours.',
  'emailValid.title': 'Account validated',
  'emailValid.description': 'Your {appName} account has been validated successfully. You Can now access your personal space by logging in with your credentials.',
  'documentManagement.title': 'Visa application',
  'passportPage.select': 'Select the people who wants to apply for a passports.',
  'passportPage.choosePerson': 'Select the applicant(s)',
  'preenroll.title': 'Pre-enroll',
  'preenroll.description': 'Pre-enrollment allows you to start the first step before finalizing your document document. Then you just have to go to an approved center to validate it.',
  'passportEmbassy.title': 'Embassy document',
  'passportEmbassy.description': 'An Embassy Passport is a document issued by a sovereign State to its diplomats for their business travels, as well as to accompanying members.',
  'zwevisa.title': 'Visa',
  'passwordSend.title': 'Confirmation sent',
  'passwordSend.description': 'You have received a confirmation email with a link to reset your password. You have 24 hours to click on it.',
  'forgotPassword.title': 'Forgot password',
  'forgotPassword.description': 'To reset your password, enter your account email below.',
  'forgotPassword.description.electron': 'To reset your password, enter your account email below. If you are trying to recover a password for an <b>active directory</b>  account, please contact the support.',
  'passwordNew.title': 'New password',
  'modifiedPassword.title': 'Changed password',
  'modifiedPassword.description': 'The password has been changed. Please log in again.',
  'people.title.DYN.pass': 'People',
  'people.title.DYN.zwevisa': 'Saved people',
  'people.addPerson.DYN.pass': 'Add a person',
  'people.addPerson.DYN.zwevisa': 'Create a new person',
  'meansPayment.title': 'Means of payment',
  'onlinePayment.title': 'PayPal payment',
  'onlinePayment.description': 'Finalize your payment by paying online with your credit card.',
  'onlinePayment.pay': 'Pay',
  'qrPayment.title': 'Payment by QR code',
  'qrPayment.description': 'To complete your payment, scan a valid QR code.',
  'qrPayment.scan': 'Scan the QR code',
  'processPayment.description': 'To make a payment, enter your credit card information..',
  'form.cardNumber': 'Card number',
  'form.expireDate': 'Expiration date',
  'successPayment.title': 'Order Confirmation',
  'successPayment.description1': 'This payment has been made. The document is being processed, and you will be notified when it is ready or if it needs to be revised.',
  'successPayment.description2': 'Please visit the Support from your account page if you don\'t receive a notification.',
  'trackingControl.title.DYN.pass': 'Request follow-up',
  'trackingControl.title.DYN.zwevisa': 'Track this application',
  'trackingControl.enroll': 'Enrollment ready',
  'trackingControl.treatment': 'Processing',
  'modifyData': 'Modify datas',
  'stopModifyData': 'Stop modify datas',
  'trackingControl.revision': 'Revision request',
  'askRevisionProcess': 'Treatment is ongoing',
  'askRevision': 'Revision requested',
  'seeRevision': 'See the revision',
  'askOrdinaryPassport': 'Application for an ordinary document',
  'askPassport': 'Ask a document',
  'askVisa': 'Ask a visa',
  'actualRevision': 'Review of the request',
  'askRevision.description': 'To handle your request for an Ordinary Passport, please send us a copy of your current residence certificate.',
  'peopleNew.title.DYN.pass': 'New people',
  'peopleNew.title.DYN.zwevisa': 'New person',
  'peopleFound.title.DYN.pass': 'Person found',
  'peopleFound.title.DYN.zwevisa': 'Person found',
  'peopleFound.description.DYN.pass': 'The person corresponding to the information you entered has been added to the list of saved people.',
  'peopleFound.description.DYN.zwevisa': 'The person corresponding to the information you entered has been added to the list of saved people.',
  'payment.commandHistory': 'Payment history',
  'menu.placesPerm': 'Places and permissions',
  'block.message': 'Do you want to block this user ?',
  'menu.addPlace': 'Add a place',
  'label.numberAccount': 'N° account',
  'place.sheet.open': 'Open the place\'s sheet',
  'place.delete': 'Delete the link with this place',
  'place.update.success': 'The place has been successfully updated.',
  'place.new': 'New place',
  'place.create': 'Create place',
  'place.add.ask': 'Do you want to link this location to this user?',
  'place.add.ok': 'You have successfully linked this place to this user.',
  'place.delete.link': 'Delete the link with this place',
  'place.delete.link.ask': 'Are you sure you want to unlink this place?',
  'place.delete.link.done': 'You have successfully removed the link with the place.',
  'user.new': 'New user',
  'user.create': 'Create the user',
  'user.created': 'Creating the new user has been successful. This account can now be used.',
  'user.sheet.open': 'Open the user\'s sheet',
  'payment.sheet.open': 'Open the payment\'s sheet',
  'err.createplace': 'Unable to create. ID already exists.',
  'place.parent': 'Parent place',
  'dropdown.sorting': 'Sort',
  'dropdown.qr': 'Scan a QR code',
  'dropdown.pass': 'Scan a document',
  'info.testingReader': 'Checking reader presence...',
  'info.testingReaderCard': 'Testing if reader has a chipped card...',
  'info.readingMrz': 'Reading MRZ...',
  'info.connecting': 'Connecting to chip...',
  'info.testingBAC': 'Performing Basic Access Control...',
  'info.readingCertificate': 'Reading signing certificate...',
  'info.doingPA': 'Performing Passive Authentication...',
  'info.readingDG': 'Reading data groups...',
  'info.disconnect': 'Disconnecting...',
  'info.convertingImage': 'Converting image read from document...',
  'info.searchingPayment': 'Searching for application in servers...',
  'info.readingPayment': 'Reading detailed information from server...',
  'err.noReader': 'The reader is not connected. Please connect the reader to continue.',
  'err.noCard': 'No valid biometric document detected in the reader. This may be due to the use of a non-biometric document, the presentation of an unauthorized copy or a counterfeit, or a malfunction of the reading antenna.',
  'err.noConnect': 'This document does not answer to the ICAO standard protocols. This may be due to the use of a non-biometric document, the presentation of an unauthorized copy or a counterfeit, or a malfunction of the reading antenna.',
  'err.noBAC': 'Secure channel to the document could not be established, is it genuine? Verify this document extensively manually and question the holder.',
  'err.alteredDoc': 'Document fields could not be read, is it genuine? Verify this document extensively manually and question the holder.',
  'err.revokedDocSigner': 'The signer of this document has been revoked by its issuing authority. Maybe this document was signed fraudulently. Question the holder extensively about this.',
  'err.unverifiedDoc': 'Document validity could not be verified, error in the authentication protocol, this could be a sign of a document emitted outside of the country following ICAO masterlist, a counterfeit document, or a malfunction of the reading antenna.',
  'err.noPassiveAuthentication': 'Document validity could not be verified, error in the authentication protocol, a counterfeit document, or a malfunction of the reading antenna.',
  'err.noResult': 'No file found',
  'dropdown.user': 'User',
  'dropdown.meansPayment': 'Types of payments',
  'payment.new.DYN.pass': 'New payment',
  'payment.new.DYN.zwevisa': 'New exit without visa',
  'payment.create': 'Create a payment',
  'payment.created.register': 'Registered payment',
  'payment.created': 'Your payment has been saved and is now visible in the list of payments.',
  'payment.created.qr': 'An email containing the generated QR code has been sent to the email address provided.',
  'text.status': 'Status',
  'phone.number': 'Phone number',
  'idcard.number': 'Identity card number',
  'form.userCreator': 'Created by the user',
  'form.userRefund': 'Reimbursed by user',
  'form.userConsumed': 'Consumed by the user',
  'PRICEUP': 'Price 0-9',
  'PRICEDOWN': 'Price 9-0',
  'RECENT': 'Most recent',
  'LATEST': 'Older',
  'STATUSUP': 'Status a-z',
  'STATUSDOWN': 'Status z-a',
  'payment.success': 'Your payment has been registered',
  'err.meansPayment.required': 'Payment method required',
  'payment.consume.ask.DYN.pass': 'Do you really want to consume this payment ?',
  'payment.consume.ask.DYN.zwevisa': 'Do you really want to check-in/out this visa application ?',
  'payment.consume.done.DYN.pass': 'Your payment has been successfully consumed',
  'payment.consume.done.DYN.zwevisa': 'Your visa application has been successfully checked-in/out',
  'payment.consume.refuse.DYN.zwevisa': 'This visa application has been successfully refused.',
  'checkinOut.refuse': 'Do you really want to refuse this application ?',
  'payment.duplicate.ask': 'Do you really want to duplicate this payment ?',
  'payment.duplicate.done': 'Your payment has been successfully duplicated',
  'payment.refund.ask': 'Do you really want to refund this payment ?',
  'payment.refund.done': 'Your payment has been successfully refunded',
  'payment.refund.exist': 'This payment has already been refunded',
  'payment.paid.error': 'Your payment need to be on the status \'Complete\'',
  'unlink.place.error': 'You must have at least one active place in order to unlink this one. Please add another place first',
  'block.done': 'The account has been blocked',
  'block.unblock': 'The account has been unblocked',
  'dailyOtp.txt': 'Daily security code ',
  'place-add.add': 'Adding place',
  'permission.title': 'Permission required: Camera',
  'qr.see': 'See the QR code',
  'qr.desc.DYN.pass': 'Here is the QR code for the payment. It will appear during a printout and will be sent to the email address entered during registration.',
  'qr.desc.DYN.zwevisa': 'Here is the QR code for the application. It will appear during a printout and will be sent to the email address entered during registration.',
  'global.print.a4': 'Print the A4 document',
  'dashboard.place.verif': 'If you have not entered a location, please do so',
  'uptodate': 'You\'re up to date',
  'block.yourself': 'You can\'t block yourself',
  'lastPlace.delete': 'The last place in this account is about to be deleted. This will render this account unusable. Continue?',
  'place.add.done': 'This place has been added to this user successfully',
  'notallow.payment': 'You do not have the rights to create a payment',
  'no.connection': 'To access this page, you must be connected to the internet. Please log in again',
  'place.delete.success': 'This place has been deleted.',
  'wrong.current': 'You have entered a wrong current password',
  'no.place': 'No place for this account.',
  'no.payment': 'No payments match the current filter',
  'email.error': 'Similar email already exist',
  'email.notexist': 'Email does not exist',
  'one.payment': 'You must have at least one or more payments created and/or filtered.',
  'err.client.extended.uuidUsed': 'This payment has already been consumed',
  'no.activecamera': 'No active camera found',
  'no.accessPermission': 'This account has no access permission',
  'download.success': 'Your file has been downloaded to the Download folder',
  'print.name': 'Printer',
  'print.success': 'Your ticket has been printed',
  'print.need': 'You must first connect to a printer to be able to print',
  'printer.connect': 'You are now connected to the printer:',
  'permission.desc': 'The service you want to use requires permission to use your device\'s camera',
  'err.personnalNR.required': 'Personal number required',
  'err.passportNR.required': 'Passport number required',
  'createPeople.ask.DYN.pass': 'Do you really want to create this person?',
  'createPeople.ask.DYN.zwevisa': 'Do you really want to create this new person?',
  'createPeopleDuplicate.ask.DYN.zwevisa': 'It appears that a person with the same name already exists in your registered people. Do you still want to create this person?',
  'createPeople.done.DYN.pass': 'The person has been created',
  'createPeople.done.DYN.zwevisa': 'The person has been created',
  'deletePerson.DYN.pass': 'Delete this person',
  'deletePerson.DYN.zwevisa': 'Delete this person',
  'deletePerson.ask.DYN.pass': 'Are you sure you want to delete this person?',
  'deletePerson.ask.DYN.zwevisa': 'Are you sure you want to delete this person?',
  'deletePerson.valid.DYN.pass': 'The person has been deleted',
  'deletePerson.valid.DYN.zwevisa': 'The person has been deleted',
  'personModification.ask.DYN.pass': 'Are you sure you want to change this person\'s data?',
  'personModification.ask.DYN.zwevisa': 'Are you sure you want to change this person\'s data?',
  'personModification.valid.DYN.pass': 'The person\'s data has been modified',
  'personModification.valid.DYN.zwevisa': 'The person\'s data has been modified',
  'noPayment': 'No payment',
  'selfie.desc': 'A photo is required for the document request, please ensure you are in good light and hold your phone steady to avoid a blurry image. Also, make sure that all the details of your face are clearly visible in the photo.',
  'selfie.take': 'Take the picture',
  'ZWEVISA.title': 'Visa',
  'EMBASSYPASS.title': 'Embassy passport',
  'PREENROLL.title': 'Pre-enrollment',
  'pay.temp': 'Payment currently unavailable, please choose pre-enrollment',
  'OK': 'Request accepted. Ready for enrollment',
  'OK_MODIFIED': 'Request accepted. Ongoing treatment.',
  'DYN.NOK': 'Request refused',
  'NOK_TO_MODIFY': 'Request denied. Action needed',
  'PROCESSING': 'Being processed',
  'form.merchantReference': 'Merchant reference',
  'form.pan': 'Card number',
  'err.MerchantReference.required': 'Merchant reference required',
  'err.PAN.required': 'Card number required',
  'err.ExpiryDate.required': 'Expiry date required',
  'WIP': 'Work in progress',
  'PAYMENT_CREATED': 'Payment created',
  'PAYMENT_VALIDATED': 'Payment validated',
  'menu.requests.DYN.zwevisa': 'Applications',
  'menu.createRequests.DYN.zwevisa': 'New application',
  'menu.requests.DYN.pass': 'Requests',
  'menu.createRequests.DYN.pass': 'New request',
  'no.data': 'No data matches the current filter',
  'dropdown.requestState': 'Request status',
  'dropdown.paymentState': 'Payment status',
  'one.request': 'You must have at least one visible request.',
  'request_state.SUBMITTED': 'Submitted',
  'request_state.CONFIRMED': 'Confirmed',
  'request_state.TREATED': 'Processed',
  'request_state.TRANSFERRED': 'Transferred',
  'request_state.CANCELLED': 'Cancelled',
  'request_state.REFUSED': 'Refused',
  'request_state.UNUSABLE': 'Unusable',
  'request_state.NULL': 'Unpaid',
  'request_state.NONE': '-- No filter --',
  'getPayment.error': 'An error occurred while retrieving the payment.',
  'adminPaymentResult.tilte': 'Payment result',
  'ask.disconnect': 'Are you sure you want to disconnect?',
  'global.yes': 'Yes',
  'zwevisa.type.holiday': 'Holiday',
  'zwevisa.type.business': 'Business',
  'zwevisa.type.conferencing': 'Conferencing',
  'zwevisa.type.transit': 'Transit',
  'zwevisa.type.student': 'Study',
  'zwevisa.entry.single': 'Single entry',
  'zwevisa.entry.double': 'Double entry',
  'zwevisa.entry.kaza': 'Kaza Univisa',
  'applicant.gender.male': 'Male',
  'applicant.gender.female': 'Female',
  'applicant.gender.other': 'Other',
  'marital.status.single': 'Single',
  'marital.status.married': 'Married',
  'marital.status.separated': 'Separated',
  'marital.status.divorced': 'Divorced',
  'marital.status.widowed': 'Widowed',
  'form.search': 'Search',
  'role.customer': 'Customer',
  'role.partner_registrer': 'Partner Registrar',
  'request_state.PROPOSED': 'Pending payment',
  'request_state.PAID': 'Complete',
  'request_state.USED': 'Closed',
  'request_state.DUPLICATED': 'Duplicated',
  'request_state.REFUNDED': 'Refunded',
  'request_state.EXPIRED': 'Refused',
  'request_state.PAID_NOT_CONFIRMED': 'Awaiting review',
  'request_state.DELAY_PAY_NOT_CONFIRMED': 'Pay later - Not Confirmed',
  'request_state.DELAY_PAY': 'Ready to pay on arrival',
  'payment_status.DYN.zwevisa.0': 'Pending payment',
  'payment_status.DYN.zwevisa.1': 'Ready to check-in',
  'payment_status.DYN.zwevisa.6': 'Awaiting review',
  'payment_status.DYN.zwevisa.2.9': 'Awaiting supervision',
  'payment_status.DYN.zwevisa.2.10': 'Supervised',
  'payment_status.desc.DYN.zwevisa.2.10': 'The application has been reviewed by a supervisor and is ready for processing by the border control officer.',
  'payment_status.desc.DYN.zwevisa.2.9': 'The application is awaiting supervision. A supervisor may need to review the application before it can be processed by the border control officer.',
  'payment_status.DYN.zwevisa.7': 'Awaiting correction',
  'payment_status.DYN.zwevisa.8': 'Awaiting review & Pay later',
  'payment_status.DYN.zwevisa.9': 'Ready to pay on arrival',
  'payment_status.DYN.zwevisa.9.entry': 'Ready to check',
  'payment_status.DYN.zwevisa.9.exit': 'Ready to check-out',
  'payment_status.DYN.zwevisa.2': 'Checked out',
  'payment_status.DYN.zwevisa.3': 'Duplicated',
  'payment_status.DYN.zwevisa.4': 'Refunded',
  'payment_status.DYN.zwevisa.5': 'Refused',
  'payment_status.extension.DYN.zwevisa.5': 'Extension refused',
  'payment_status.DYN.zwevisa.5.blacklisted': 'Refused - Blacklisted',
  'payment_status.DYN.pass.0': 'Proposed',
  'payment_status.DYN.pass.1': 'Paid',
  'payment_status.DYN.pass.6': 'Unconfirmed',
  'payment_status.DYN.pass.2': 'Used',
  'payment_status.DYN.pass.3': 'Duplicated',
  'payment_status.DYN.pass.4': 'Refunded',
  'payment_status.DYN.pass.5': 'Expired',
  'treatmentDone': 'Treatment completed. Revision requested',
  'sendRevision': 'Revision sent',
  'enrolReady': 'Application accepted. Ready for enrollment',
  'err.personalNr.required': 'Personal number required',
  'err.passportNr.required': 'Passport number required',
  'createPeople.success.DYN.pass': 'Person successfully created',
  'createPeople.success.DYN.zwevisa': 'Person successfully created',
  'request.0': 'Application accepted. Ready for enrollment',
  'request.1': 'Application accepted. Processing underway',
  'request.2': 'Application denied',
  'request.3': 'Application denied. Action required',
  'request.4': 'Processing underway',
  'adminRequestResult.title.DYN.pass': 'Selected request - {paymentID}',
  'adminRequestResult.title.DYN.zwevisa': 'Selected application - {paymentID}',
  'form.requestState': 'Request status',
  'form.paymentType': 'Payment type',
  'form.paymentState': 'Payment status',
  'payment.type.PHYSICAL': 'Physical',
  'payment.type.PAYPAL': 'PayPal',
  'payment.type.WU': 'Western Union',
  'payment.type.MNTA': 'CBZ',
  'payment.type.online': 'Online',
  'payment.type.offline': 'Offline',
  'request.state.DYN.pass': 'Request status',
  'request.state.DYN.zwevisa': 'Application status',
  'payment.continue': 'Continue payment',
  'form.birthDate': 'Date of birth',
  'form.birthCountry': 'Country of birth',
  'form.placeOfBirth': 'City of birth',
  'form.residenceAdress': 'Residence address',
  'form.marialStatus': 'Marital status',
  'form.dateOfIssue': 'Pasport date of issue',
  'form.dateOfExpiry': 'Pasport expiration date',
  'form.placeOfIssue': 'Place of issue',
  'form.departureDate': 'Departure date',
  'form.arrivalDate': 'Arrival date',
  'form.referencePreviousVisa': 'Previous visa reference',
  'form.passportNumber': 'Passport number',
  'form.passportType': 'Passport type',
  'form.hostName': 'Host name',
  'form.purposeOfVisit': 'Purpose of visit',
  'form.previousConvictionsHome': 'Previous convictions in home country',
  'form.spouse': 'Spouse',
  'form.SpousePlaceOfBirth': 'Spouse\'s place of birth',
  'form.spouse.birthDay': 'Spouse\'s date of birth',
  'form.spousePassportNumber': 'Spouse\'s passport number',
  'form.spousePlaceOfBirth': 'Spouse\'s place of birth',
  'form.dependants': 'Dependants',
  'form.dependantFullName': 'Dependant\'s full name',
  'form.dependantPassportNumber': 'Dependant\'s passport number',
  'form.dependantPlaceOfBirth': 'Dependant\'s place of birth',
  'form.dependantBirthDay': 'Dependant\'s date of birth',
  'global.approve': 'Approve',
  'global.refuse': 'Refuse',
  'global.suspend': 'Ask for revision',
  'global.unprocess': 'Unprocess',
  'personal.data': 'Personal data',
  'contact.data': 'Contact data',
  'divers.data.DYN.zwevisa': 'Travel details',
  'divers.data.DYN.pass': 'Application details',
  'dependant.data': 'Dependants',
  'spouse.data': 'Spouse data',
  'paymentState.0': 'Pending payment',
  'paymentState.1': 'Check in/out',
  'paymentState.2': 'Closed',
  'paymentState.3': 'Duplicated',
  'paymentState.4': 'Refunded',
  'paymentState.5': 'Denied',
  'paymentState.6': 'Paid - Not Confirmed',
  'payment.refuse.ask.DYN.pass': 'Do you really want to <b>REFUSE</b> this payment?',
  'payment.refuse.ask.DYN.zwevisa': 'Do you really want to <b>REFUSE</b> this visa application?',
  'payment.refuse.ask.extension.DYN.zwevisa': 'Do you really want to <b>REFUSE</b> this extension request?',
  'payment.approve.ask.DYN.pass': 'Do you really want to <b>APPROVE</b> this payment?',
  'payment.approve.ask.DYN.zwevisa': 'Do you really want to <b>APPROVE</b> this visa application ?',
  'payment.approve.ask.extension.DYN.zwevisa': 'Do you really want to <b>APPROVE</b> this extension request ?',
  'payment.review.approve.ask.DYN.zwevisa': 'Do you really want to review this visa application as <b>RECOMMENDED</b>?',
  'payment.review.approve.success.DYN.zwevisa': 'This visa application has been reviewed as <b>RECOMMENDED</b>.',
  'payment.review.approve.success.extension.DYN.zwevisa': 'This extension request has been reviewed as <b>RECOMMENDED</b>.',
  'payment.review.refuse.ask.DYN.zwevisa': 'Do you really want to review this visa application as <b>NOT RECOMMENDED</b>?',
  'payment.review.refuse.ask.extension.DYN.zwevisa': 'Do you really want to review this extension request as <b>NOT RECOMMENDED</b>?',
  'payment.review.refuse.success.DYN.zwevisa': 'This visa application has been reviewed as <b>NOT RECOMMENDED</b>.',
  'payment.review.refuse.success.extension.DYN.zwevisa': 'This extension request has been reviewed as <b>NOT RECOMMENDED</b>.',
  'payment.review.approve.extension.ask.DYN.zwevisa': 'Do you really want to <b>APPROVE</b> this extension request ?',
  'payment.validate.ask.DYN.pass': 'Do you really want to <b>APPROVE</b> this payment?',
  'payment.validate.ask.DYN.zwevisa': 'Do you really want to <b>APPROVE</b> this visa application?',
  'payment.validate.extension.ask.DYN.zwevisa': 'Do you really want to <b>APPROVE</b> this extension request?',
  'payment.validate.review.ask.DYN.pass': 'Do you really want to <b>APPROVE</b> this payment? A revision request will be sent to the next reviewer',
  'payment.validate.review.ask.DYN.zwevisa': 'Do you really want to <b>RECOMMEND</b> this visa application? A revision request will be sent to the next reviewer',
  'payment.refuse.success.DYN.pass': 'Payment successfully <b>REFUSED</b>.',
  'payment.refuse.success.DYN.zwevisa': 'Visa application successfully <b>REFUSED</b>.',
  'payment.refuse.success.extension.DYN.zwevisa': 'Extension request successfully <b>REFUSED</b>.',
  'payment.approve.success.DYN.pass': 'Payment successfully <b>APPROVED</b>.',
  'payment.approve.success.DYN.zwevisa': 'Visa application successfully <b>APPROVED</b>. The customer will be notified by email.',
  'payment.approve.success.extension.DYN.zwevisa': 'Extension request successfully <b>APPROVED</b>.',
  'payment.suspend.success.DYN.pass': 'Payment successfully <b>SUSPENDED</b>. A revision request has been sent to the customer.',
  'payment.suspend.success.DYN.zwevisa': 'Visa application successfully <b>SUSPENDED</b>. A revision request has been sent to the customer.',
  'payment.validate.success.DYN.pass': 'Payment successfully <b>APPROVED</b>.',
  'payment.validate.success.DYN.zwevisa': 'Visa application successfully <b>APPROVED</b>.',
  'error.payment.refuse.error.DYN.pass': 'An error occurred while refusing the payment.',
  'error.payment.refuse.error.DYN.zwevisa': 'An error occurred while refusing the visa application.',
  'error.payment.suspend.error.DYN.pass': 'An error occurred while suspending the payment.',
  'error.payment.suspend.error.DYN.zwevisa': 'An error occurred while suspending the visa application.',
  'error.payment.validate.error.DYN.pass': 'An error occurred while approving the payment.',
  'error.payment.validate.error.DYN.zwevisa': 'An error occurred while approving the visa application.',
  'global.no': 'No',
  'global.Yes': 'Yes',
  'global.No': 'No',
  'zwevisa.type.holiday_visa': 'Holiday',
  'photo.entryContry': 'Entry country photo',
  'documents': 'Documents',
  'photo.passport': 'Passport photo',
  'data.biographic': 'Passport biographic page',
  'residence.proof': 'Residence proof',
  'address.destination': 'Destination address',
  'previous.visa': 'Previous visa',
  'invite.letter': 'Invitation letter',
  'residential.status': 'Residential status',
  'biometric.data': 'Biometric data',
  'hostInvitation.letter': 'Host\'s invitation letter',
  'proof.residenceHost': 'Host\'s residence proof',
  'hostResidential.status': 'Host\'s residential status',
  'reasonOfVisit.proof': 'Proof of reason for visit to destination country',
  'inviteBusiness.letter': 'Sending company\'s business letter',
  'business.letter': 'Business letter',
  'business.profile': 'Traveler\'s professional profile',
  'application.letter': 'Visa application letter',
  'acceptanceFromScool.letter': 'Confirmation letter',
  'date.creation': 'Creation date',
  'document.Passport': 'Passport',
  'completeName': 'Full name',
  'price': 'Price',
  'global.retry': 'Retry',
  'err.loginWeak': 'Connection problem, please try again',
  'URGENTPASS.title': 'Urgent Passport',
  'refusalReason': 'Refusal reason',
  'error.payment.noRefuseReason': 'Please enter a message.',
  'error.payment.noSuspendReason': 'Please enter a suspension reason. This reason will be sent to the customer.',
  'refusal.desc.DYN.pass': 'This payment has been refused.',
  'refusal.desc.DYN.zwevisa': 'This visa application has been refused.',
  'refusal.desc.extension.DYN.zwevisa': 'This extension request has been refused.',
  'refusal.desc.DYN.zwevisa.blacklisted': 'This visa application has been refused and blacklisted.',
  'approval.desc.DYN.pass': 'This payment has been approved.',
  'approval.desc.DYN.zwevisa': 'This visa application has been approved.',
  'approval.desc.extension.DYN.zwevisa': 'This extension request has been approved.',
  'review.approval.desc.DYN.zwevisa': 'This visa application meets the requirements.',
  'review.approval.desc.extension.DYN.zwevisa': 'This extension request meets the requirements.',
  'review.refusal.desc.DYN.zwevisa': 'This visa application does not meet the requirements.',
  'review.refusal.desc.extension.DYN.zwevisa': 'This extension request does not meet the requirements.',
  'err.getUsers: client.extended.notActiveToken': 'Your session has expired. Please log in again.',
  'forgetPassword.success': 'Your password has been reset successfully. You can now log in.',
  'VISA_DOUBLE.title': 'Double entry visa',
  'VISA_KAZA.title': 'Visa Kaza',
  'history': 'History',
  'noPaymentFilter': 'No requests in progress',
  'menu.request': 'Requests',
  'menu.tracking': 'Tracking',
  'menu.people.DYN.pass': 'People',
  'menu.people.DYN.zwevisa': 'Saved people',
  'DYN.AGO': 'Angola',
  'DYN.ABW': 'Aruba',
  'DYN.ATG': 'Antigua & Barbuda',
  'DYN.BHS': 'Bahamas',
  'DYN.BRB': 'Barbados',
  'DYN.BLZ': 'Belize',
  'DYN.BWA': 'Botswana',
  'DYN.CYM': 'Cayman Islands',
  'DYN.CYP': 'Cyprus',
  'DYN.FJI': 'Fiji',
  'DYN.COD': 'Democratic Republic of the Congo',
  'DYN.GHA': 'Ghana',
  'DYN.GRD': 'Grenada',
  'DYN.HKG': 'Hong Kong Special Administrative Region (China)',
  'DYN.KEN': 'Kenya',
  'DYN.KIR': 'Kiribati',
  'DYN.JAM': 'Jamaica',
  'DYN.LDW': 'Leeward Islands',
  'DYN.LSO': 'Lesotho',
  'DYN.MDG': 'Madagascar',
  'DYN.MWI': 'Malawi',
  'DYN.MYS': 'Malaysia',
  'DYN.MDV': 'Maldives',
  'DYN.MLT': 'Malta',
  'DYN.MUS': 'Mauritius',
  'DYN.MSR': 'Montserrat',
  'DYN.MOZ': 'Mozambique',
  'DYN.NAM': 'Namibia',
  'DYN.NRU': 'Nauru',
  'DYN.LCA': 'Saint Lucia',
  'DYN.VCT': 'Saint Vincent and the Grenadines',
  'DYN.WSM': 'Western Samoa',
  'DYN.SYC': 'Seychelles',
  'DYN.SGP': 'Singapore',
  'DYN.SLB': 'Solomon Islands',
  'DYN.ZAF': 'South Africa',
  'DYN.KNA': 'Saint Kitts and Nevis',
  'DYN.SWZ': 'Swaziland',
  'DYN.TON': 'Tonga',
  'DYN.TTO': 'Trinidad and Tobago',
  'DYN.TCA': 'Turks and Caicos Islands',
  'DYN.TUV': 'Tuvalu',
  'DYN.UGA': 'Uganda',
  'DYN.TZA': 'United Republic of Tanzania',
  'DYN.VUT': 'Vanuatu',
  'DYN.VAT': 'Vatican',
  'DYN.VEN': 'Venezuela (Bolivarian Republic of)',
  'DYN.USA': 'United States of America',
  'DYN.URY': 'Uruguay',
  'DYN.UZB': 'Uzbekistan',
  'DYN.UKR': 'Ukraine',
  'DYN.ARE': 'United Arab Emirates',
  'DYN.GBR': 'United Kingdom of Great Britain and Northern Ireland',
  'DYN.TUR': 'Turkey',
  'DYN.TKM': 'Turkmenistan',
  'DYN.SWE': 'Sweden',
  'DYN.CHE': 'Switzerland',
  'DYN.TJK': 'Tajikistan',
  'DYN.KAZ': 'Kazakhstan',
  'DYN.SUR': 'Suriname',
  'DYN.ESP': 'Spain',
  'DYN.SVK': 'Slovakia',
  'DYN.SVN': 'Slovenia',
  'DYN.SEN': 'Senegal',
  'DYN.SRB': 'Serbia',
  'DYN.SMR': 'San Marino',
  'DYN.STP': 'Sao Tome and Principe',
  'DYN.NLD': 'Netherlands',
  'DYN.NCL': 'New Caledonia',
  'DYN.NZL': 'New Zealand',
  'DYN.NIC': 'Nicaragua',
  'DYN.MEX': 'Mexico',
  'DYN.FSM': 'Micronesia, Federated States of',
  'DYN.MDA': 'Moldova',
  'DYN.MCO': 'Monaco',
  'DYN.MHL': 'Marshall Islands',
  'DYN.LVA': 'Latvia',
  'DYN.NFK': 'Norfolk Island',
  'DYN.MNP': 'Northern Mariana Islands',
  'DYN.NOR': 'Norway',
  'DYN.PLW': 'Palau',
  'DYN.PSE': 'Palestine',
  'DYN.PAN': 'Panama',
  'DYN.PNG': 'Papua New Guinea',
  'DYN.PRY': 'Paraguay',
  'DYN.PER': 'Peru',
  'DYN.POL': 'Poland',
  'DYN.PRT': 'Portugal',
  'DYN.PRI': 'Puerto Rico',
  'DYN.REU': 'Réunion',
  'DYN.ROU': 'Romania',
  'DYN.RUS': 'Russian Federation',
  'DYN.RWA': 'Rwanda',
  'DYN.LIE': 'Liechtenstein',
  'DYN.LTU': 'Lithuania',
  'DYN.LUX': 'Luxembourg',
  'DYN.MAC': 'Macau Special Administrative Region (China)',
  'DYN.JPN': 'Japan',
  'DYN.KWT': 'Kuwait',
  'DYN.KGZ': 'Kyrgyzstan',
  'DYN.KOR': 'South Korea',
  'DYN.GUY': 'Guyana',
  'DYN.HTI': 'Haiti',
  'DYN.HND': 'Honduras',
  'DYN.IRL': 'Ireland',
  'DYN.ISR': 'Israel',
  'DYN.ITA': 'Italy',
  'DYN.HUN': 'Hungary',
  'DYN.ISL': 'Iceland',
  'DYN.IND': 'India',
  'DYN.IDN': 'Indonesia',
  'DYN.IRN': 'Iran',
  'DYN.GUM': 'Guam',
  'DYN.GTM': 'Guatemala',
  'DYN.GIB': 'Gibraltar',
  'DYN.GRC': 'Greece',
  'DYN.GEO': 'Georgia',
  'DYN.DEU': 'Germany',
  'DYN.DNK': 'Denmark',
  'DYN.EST': 'Estonia',
  'DYN.ETH': 'Ethiopia',
  'DYN.FIN': 'Finland',
  'DYN.FRA': 'France',
  'DYN.GUF': 'French Guiana',
  'DYN.PYF': 'French Polynesia',
  'DYN.ATF': 'French Southern Territories',
  'DYN.CZE': 'Czech Republic',
  'DYN.COM': 'Comoros',
  'DYN.DOM': 'Dominican Republic',
  'DYN.ECU': 'Ecuador',
  'DYN.EGY': 'Egypt',
  'DYN.SLV': 'El Salvador',
  'DYN.GNQ': 'Equatorial Guinea',
  'DYN.CHL': 'Chile',
  'DYN.CHN': 'China',
  'DYN.CHN_NT': 'China (non-touristic)',
  'DYN.COK': 'Cook Islands',
  'DYN.CRI': 'Costa Rica',
  'DYN.HRV': 'Croatia',
  'DYN.CUB': 'Cuba',
  'DYN.BDI': 'Burundi',
  'DYN.CAN': 'Canada',
  'DYN.CPV': 'Cape Verde',
  'DYN.BRA': 'Brazil',
  'DYN.VGB': 'British Virgin Islands',
  'DYN.BRN': 'Brunei Darussalam',
  'DYN.BGR': 'Bulgaria',
  'DYN.BMU': 'Bermuda',
  'DYN.BTN': 'Bhutan',
  'DYN.BIH': 'Bosnia and Herzegovina',
  'DYN.BLR': 'Belarus',
  'DYN.BEL': 'Belgium',
  'DYN.BHR': 'Bahrain',
  'DYN.ARG': 'Argentina',
  'DYN.ARM': 'Armenia',
  'DYN.AUS': 'Australia',
  'DYN.AUT': 'Austria',
  'DYN.AZE': 'Azerbaijan',
  'DYN.ALB': 'Albania',
  'DYN.DZA': 'Algeria',
  'DYN.AIA': 'Anguilla',
  'DYN.BGD': 'Bangladesh',
  'DYN.BEN': 'Benin',
  'DYN.AFG': 'Afghanistan',
  'DYN.BOL': 'Bolivia',
  'DYN.BFA': 'Burkina Faso',
  'DYN.KHM': 'Cambodia',
  'DYN.CMR': 'Cameroon',
  'DYN.CIV': "Côte d'Ivoire",
  'DYN.CAF': 'Central African Republic',
  'DYN.TCD': 'Chad',
  'DYN.COL': 'Colombia',
  'DYN.ERI': 'Eritrea',
  'DYN.COG': 'Congo-Brazzaville',
  'DYN.PRK': 'Democratic People\'s Republic of Korea',
  'DYN.GAB': 'Gabon',
  'DYN.GMB': 'Gambia',
  'DYN.DJI': 'Djibouti',
  'DYN.GIN': 'Guinea-Conakry',
  'DYN.GNB': 'Guinea-Bissau',
  'DYN.IRQ': 'Iraq',
  'DYN.KOS': 'Kosovo',
  'DYN.LAO': 'Lao People\'s Democratic Republic',
  'DYN.JOR': 'Jordan',
  'DYN.LBR': 'Liberia',
  'DYN.LBY': 'Libyan Arab Jamahiriya',
  'DYN.MLI': 'Mali',
  'DYN.QAT': 'Qatar',
  'DYN.REF': 'Refugee',
  'DYN.PHL': 'Philippines',
  'DYN.OMN': 'Oman',
  'DYN.PAK': 'Pakistan',
  'DYN.LBN': 'Lebanon',
  'DYN.MRT': 'Mauritania',
  'DYN.MNG': 'Mongolia',
  'DYN.MNE': 'Montenegro',
  'DYN.NER': 'Niger',
  'DYN.NGA': 'Nigeria',
  'DYN.MMR': 'Myanmar',
  'DYN.MAR': 'Morocco',
  'DYN.NPL': 'Nepal',
  'DYN.SAU': 'Saudi Arabia',
  'DYN.SLE': 'Sierra Leone',
  'DYN.SOM': 'Somalia',
  'DYN.LKA': 'Sri Lanka',
  'DYN.SSD': 'South Sudan',
  'DYN.SDN': 'Sudan',
  'DYN.THA': 'Thailand',
  'DYN.MKD': 'Former Yugoslav Republic of Macedonia',
  'DYN.TGO': 'Togo',
  'DYN.SYR': 'Syrian Arab Republic',
  'DYN.TWN': 'Taiwan',
  'DYN.TUN': 'Tunisia',
  'DYN.VNM': 'Vietnam',
  'DYN.YEM': 'Yemen',
  'DYN.TLS': 'Timor-Leste',
  'DYN.AND': 'Andorra',
  'DYN.VIR': 'Virgin Islands (U.S.)',
  'DYN.XKX': 'Kosovo',
  'DYN.ZWE': 'Zimbabwe',
  'DYN.ZMB': 'Zambia',
  'DYN.ASM': 'American Samoa',
  'DYN.DMA': 'Dominica',
  'DYN.XXA': 'Stateless',
  'DYN.XXB': 'Refugee (Geneva convention)',
  'DYN.XXC': 'Refugee (Other)',
  'DYN.UNA': 'United Nations Agency',
  'DYN.UNO': 'United Nations Organization',
  'err.Occupation.pattern': 'Occupation must have a correct format',
  'err.PreviousConvictionsHome.pattern': 'Previous convictions in home country must have a correct format',
  'err.PlaceOfBirth.pattern': 'Place of birth must have a correct format',
  'PAYMENT_USED': 'Payment closed',
  'document.type.DYN.pass': 'Document type',
  'document.type.DYN.zwevisa': 'Application type',
  'filterPayment.error': 'An error occurred while filtering requests. Would you like to try again?',
  'filterPaymentNoFirst.error': 'An error occurred while retrieving requests. Would you like to try again?',
  'dependantIndex': 'Dependant {index}',
  'form.spouseFullName': 'Spouse\'s Full Name',
  'qrDesc.valid': 'Present this QR code at the visa checkpoint when entering/leaving the country. You cannot travel without a valid QR code first.',
  'qrDesc.invalid': 'This visa application is currently invalid. You cannot travel without a valid visa application. Please contact the visa office for more information.',
  'err.pay': 'An error occurred during payment. Try again.',
  'payment.noEvents': 'This application currently does not include any travel-related processing (usages of the application).',
  'global.cancel': 'Cancel',
  'end.suspend': 'Finish the revision',
  'visa_application': 'Visa Application Requirements',
  'trips.title': 'Track applications',
  'trips.desc': 'Application(s)',
  'BatchId': 'Application reference',
  'newBatchId': 'Add',
  'err.BatchId.required': 'Application reference required',
  'newBatchId.desc': 'Please enter a name for this travel.',
  'ask.newBatchId': 'Please create a new Application reference.',
  'personLinkType.DYN.0': 'Unknown',
  'personLinkType.DYN.1': 'Child',
  'personLinkType.DYN.2': 'Parent',
  'personLinkType.DYN.3': 'Spouse',
  'personLinkType.DYN.4': 'Other',
  'personLinkType.DYN.5': 'Self',
  'createPeople.error.DYN.pass': 'An error occurred while creating the person.',
  'createPeople.error.DYN.zwevisa': 'An error occurred while creating the person.',
  'batchIds.desc': 'Applications linked to the reference {trip} :',
  'trip.name': 'Application reference',
  'visaIntro.validFrom': 'Start date',
  'visaIntro.validUntil': 'Expiry date',
  'visaIntro.usageCount': 'Permitted usage(s)',
  'client.extended.passportNumberAlreadyExists': 'This passport number is already used. Please enter another one.',
  'client.extended.alreadyDecided': 'This visa application has already been reviewed by you. You cannot review it again.',
  'client.extended.outOfUsage': 'This application is <b>out of its validity period</b>',
  'global.review': 'Review',
  'ask.review.0': 'Select your assessment for this visa application.',
  'ask.review.extension.0': 'Select your assessment for this extension request.',
  'ask.review.1': 'Select your recommendation for this visa application.',
  'ask.review.2': 'Select your final decision for this visa application.',
  'error.review.noChoice': 'Please select a review state in order to continue.',
  'all.reviews': 'All reviews of this visa application',
  'all.reviews.extension': 'All reviews of this extension request',
  'validate.desc': 'This visa application has been reviewed as approved the {date} by the user',
  'printEvent.desc': 'This visa application has been printed with all of its documents the {date} by the user',
  'validate.extension.desc': 'This extension request has been reviewed as approved the {date} by the user',
  'refusal.review.desc': 'This visa application has been reviewed as refused the {date} by the user',
  'refusal.review.extension.desc': 'This extension request has been reviewed as refused the {date} by the user',
  'refusal.review.desc.blacklisted': 'This visa application has been reviewed as refused and blacklisted the {date} by the user',
  'global.revision': 'Revision',
  'revison.cancel': 'Cancel the revision',
  'approve.text': 'This application has been approved.',
  'refuse.text': 'This visa application has been refused.',
  'pending.review.desc': 'A correction of this visa application has been requested to the applicant on the {date} by the user',
  'global.reason': 'Reason',
  'pending.desc': 'This visa application is currently awaiting correction from the customer.',
  'success.onArrival': 'Your request has been successfully submitted. You can see the qr code of this application at the top. Please present this QR code at the visa checkpoint when entering/leaving the country. You cannot travel without a valid visa application first.',
  'successRequest.title': 'Request successfully submitted',
  'successRequest.title.kiosk': 'Application successfully submitted',
  'amountDue': 'Price: ',
  'amountDue.total.now': 'Total due now: ',
  'amountDue.total.later': 'Total due at the officer desk: ',
  'noPrint.mobile': 'Page printing is not supported on mobile devices',
  'err.PassportNumber.required': 'Document number required (Passport, Resident Permit)',
  'err.FundsAvailable.pattern': 'Funds available at time of entry must have a correct format',
  'onArrival.reset': 'Start a new process',
  'onArrival.reset.ask': 'Do you really want to start a new process?',
  'stillHere': 'Are you still there? This process will reset itself in 20 seconds.',
  'stillHere.successPayment': 'Are you still there?',
  'print.request': 'Print the application',
  'EXPIRED.globalExplain': 'This request has been refused. It does not comply with our standards.',
  'payment_status.desc.DYN.7': 'This visa application has been reviewed as pending. It is currently awaiting correction from the customer.',
  'payment_status.DYN.zwevisa.6.review': 'Awaiting review - {reviewCount}/2',
  'payment_status.DYN.zwevisa.8.review': 'Awaiting review',
  'payment_status.desc.DYN.zwevisa.6.review': 'This visa application is currently awaiting review. It has already been reviewed {reviewCount} time(s).',
  'payment_status.desc.DYN.zwevisa.8.review': 'This visa application is currently awaiting supervisor review.',
  'payment_status.DYN.zwevisa.6.finalReview': 'Awaiting final review',
  'payment_status.desc.DYN.zwevisa.6.finalReview': 'This visa application has been reviewed and is currently awaiting final review.',
  'payment_status.desc.DYN.zwevisa.0': 'This application is currently pending payment validation at the payment institution. If the payment has not yet been made, it can be completed now.',
  'payment_status.desc.DYN.zwevisa.7': 'This visa application is awaiting correction from the customer.',
  'payment_status.desc.DYN.zwevisa.5': 'This visa application has been refused.',
  'payment_status.desc.extension.DYN.zwevisa.5': 'This extension request has been refused.',
  'payment_status.desc.DYN.zwevisa.5.blacklisted': 'This visa application has been refused and the person has been blacklisted.',
  'payment_status.desc.DYN.zwevisa.5.checkIn': 'This application was refused during the check-in at the officer\'s desk.',
  'payment_status.desc.DYN.zwevisa.5.checkIn.reason': 'This application was refused during the check-in at the officer\'s desk. <p><b>Reason</b>: <br/> {reason}</p>',
  'payment_status.DYN.zwevisa.5.checkIn': 'Refused at check-in',
  'payment_status.desc.DYN.zwevisa.5.checkOut': 'This application was refused during the check-out at the officer\'s desk.',
  'payment_status.DYN.zwevisa.5.checkOut': 'Refused at check-out',
  'payment_status.desc.DYN.zwevisa.9': 'This visa application is ready to pay at the officer desk. The check-in can be completed after the payment.',
  'payment_status.desc.DYN.zwevisa.9.entry': 'This application is ready to check-in at the officer desk.',
  'payment_status.desc.DYN.zwevisa.9.exit': 'This application is ready for check-out and requires payment for the border pass issued at the officer\'s desk.',
  'payment_status.DYN.zwevisa.2.out': 'Ready to check-out',
  'payment_status.DYN.zwevisa.2.outFinish': 'Checked out',
  'payment_status.DYN.zwevisa.2.closed': 'Closed',
  'payment_status.desc.DYN.zwevisa.2.closed': 'This application has been closed.',
  'payment_status.desc.DYN.zwevisa.2.zweexit': 'This application is ready to check-out at the officer desk.',
  'payment_status.DYN.zwevisa.2.entryFinish': 'Checked in',
  'payment_status.DYN.zwevisa.2.in': 'Ready to check-in',
  'payment_status.desc.DYN.zwevisa.2.out': 'This application has been checked in. The next step is to check out at the officer desk.',
  'payment_status.desc.DYN.zwevisa.2.outFinish': 'This application has been checked out. The customer can now travel.',
  'payment_status.desc.DYN.zwevisa.2.entryFinish': 'This application has been checked in. The customer can now enter into the country.',
  'payment_status.desc.DYN.zwevisa.2.in': 'This application awaits check-in at the officer desk.',
  'ask.isArrivalPaid': 'The following payment must be paid now in any of the following currencies:',
  'ask.isArrivalPaid.exit': 'The border pass must be paid now in any of the following currencies:',
  'ask.isArrivalPaid.confirm': 'Do you confirm that the payment has been made? If you confirm, please enter the payment reference, the payment method and the currency used. You can also change the maximum end date of stay (You can only lower the date).',
  'ask.isArrivalPaid.confirm.exit': 'Do you confirm that the payment has been made? If you confirm, please enter the payment reference and the currency used.',
  'ask.checkoutExpired.confirm': 'Do you confirm that the payment has been made? If you confirm, please enter the payment reference and the currency used.',
  'no_trips': 'You don\'t have any travels.',
  'err.canActivate': 'An error occured',
  'form.passportNumber.dateOfIssue': 'Date of issue of the passport',
  'form.passportNumber.dateOfExpiry': 'Date of expiry of the passport',
  'form.passportNumber.placeOfIssue': 'Place of issue of the passport',
  'nextOfKin.data': 'Emergency contact / Next of kin',
  'form.otherLinkType': 'Specify the relation link type',
  'err.passportNr.pattern': 'Passport number must have a correct format',
  'err.otherLinkType.required': 'You must specify the relation link type',
  'err.otherLinkType.pattern': 'The relation link type must have a correct format',
  'camera.problem.manual': 'You are about to check in/out this visa application without a picture of the customer. Are you sure you want to continue?',
  'camera.problem.button': 'Problem with the camera?',
  'global.noFilter': '--No filter--',
  'zweentry': 'Entry without Visa',
  'zweentry.title': 'Entry without Visa',
  'zweentry.description': 'Travelers from select countries can enter without obtaining a visa beforehand, typically for short stays.',
  'free.charge': 'Free of charge',
  'camera.init.error': 'An error occurred while initializing the camera. Do you want to check in/out this application without a picture of the customer?',
  'error.payment.noPaymentReference': 'Please enter a payment reference, a payment method and the currency used.',
  'paymentReference.ask': 'Reference of the payment',
  'paymentReference.askExpired': 'Reference of the payment for the overstay fee',
  'session.expired': 'Your session has expired. Please log in again.',
  'error.approveRefuse.DYN.zwevisa': 'An error occurred while approving or refusing the visa application.',
  'error.approveRefuse.DYN.pass': 'An error occurred while approving or refusing the payment.',
  'phone.pattern': ' (ie: +263771234567)',
  'global.receipt.id': 'ID of the application : {paymentId}',
  'global.preview': 'Preview',
  'ask.place.updatePermissions': 'Are you sure you want to update the permissions of this place?',
  'place.updatePermissions.success': 'The permissions of the place have been successfully updated.',
  'err.place.updatePermissions': 'An error occurred while updating the permissions of the place.',
  'err.seniority.required': 'Seniority must be a number between 1 and 3.',
  'err.seniority.pattern': 'Seniority must be a number between 1 and 3.',
  'global.urgent': 'Urgent',
  'form.usageAfter': 'Valid from',
  'form.usageUntil': 'Valid until',
  'form.localUsageUntil': 'Maximum end date of stay',
  'id.payment': 'Payment ID',
  'internalReference': 'HQ reference',
  'payment_status.DYN.zwevisa.6.0': 'Awaiting online review 0/2',
  'payment_status.DYN.zwevisa.6.1': 'Awaiting online review 1/2',
  'payment_status.DYN.zwevisa.6.2': 'Awaiting online final review',
  'payment_status.DYN.zwevisa.8.0': 'Awaiting landing review',
  'payment_status.DYN.zwevisa.2.0': 'Checked in',
  'payment_status.DYN.zwevisa.2.4': 'Ready to check-out',
  'payment_status.DYN.zwevisa.2.5': 'Checked out',
  'payment_status.DYN.zwevisa.2.6': 'Refused at check-in',
  'payment_status.DYN.zwevisa.2.7': 'Refused at check-out',
  'payment_status.DYN.zwevisa.2.8': 'Closed',
  'internalReference.create': 'Add an HQ reference',
  'internalReference.edit': 'Edit the HQ reference',
  'internalReference.ask.edit': 'Please enter the new HQ reference.',
  'internalReference.ask.create': 'Please enter the HQ reference.',
  'internalReference.done.create': 'The HQ reference has been successfully added.',
  'internalReference.done.edit': 'The HQ reference has been successfully edited.',
  'error.internalReference': 'An error occurred while adding/edditing the HQ reference.',
  'error.paymentUntil.expired': 'This application is beyond its validity period. You may proceed with the checkout process for the customer; however, please be advised that a fee of USD 100 is required due to the expired status of the visa application. The payment must be made immediately in any of the following currencies: ',
  'internalReference.desc': 'Add an HQ reference to this application (e.g., 140_2024).',
  'visaApplication.id': 'ID of the visa application',
  'titlePrint': 'Visa application </br> <b>{paymentID}</b>',
  'titlePrint.extension': 'Extension request </br> <b>{paymentID}</b>',
  'visa.data': 'Visa data',
  'validated': 'Valid',
  'globalStatus.awaitingConfirmation': 'Awaiting confirmation',
  'globalStatus.awaitingVerification': 'Awaiting correction',
  'globalStatus.refused': 'Refused',
  'globalStatus.valid': 'Valid',
  'globalStatus.draft': 'Pending payment',
  'PENDING.globalExplain': 'This request is awaiting correction. Please check the information and correct it.',
  'PAID_NOT_CONFIRMED.globalExplain': 'This request is awaiting confirmation by our services.',
  'PROPOSED.globalExplain': 'This request is pending payment. If you have not validated payment yet, you may do so now.',
  'usageValidityConditions': 'Immigration authorities reserve the right to cancel the visa at any time should the visa holder fail to comply with the associated conditions.',
  'afterWithoutUntil': 'You are cleared to enter from {dateAfter} until {deadlineDate}.',
  'afterWithoutUntilAndMillis': 'You are cleared to travel from {dateAfter}.',
  'afterAndUntil': 'You are cleared to travel from {dateAfter} until {dateUntil}.',
  'photo.current': 'Current picture',
  'photo.scanned': 'Picture scanned from passport',
  'createdAt': 'Date of creation',
  'all.infos': 'Informations about this application',
  'afterRefused': 'Declared travel dates : {dateAfter}.',
  'DYN.paymentError.desc.success': `<p>Your order has been successfully recorded. If any part of this application requires approval from our teams, you will be notified once this process is completed. Otherwise, please know that we have received your request.</p>
  <p><b>Thank you for choosing our services.</b></p>`,
  'successPayment.kiosk': '<p>Your application has been successfully recorded. You can now proceed to the officer\'s desk to check in.</p><p><b>Thank you for choosing our services.</b></p>',
  'sinceOneWeek': 'Please note than this visa application has been in awaiting review status for more than one week.',
  'client.extended.onlyAdminDecide': 'This visa application has already been reviewed by a worker with the same seniority as you. You cannot review it again.',
  'error.paymentUntil.before': 'The date is too early. This application will be valid from {dateAfter}.',
  'applicant.gender.FEMALE': 'Female',
  'applicant.gender.MALE': 'Male',
  'entry.consume': 'Arrivals',
  'exit.consume': 'Departures',
  'scan.noConsumeMode': 'As a border officer, you are tasked with managing both entries and exits. Please select the option corresponding to your current task: processing arrivals or managing departures.',
  'scan.kiosk': 'No record in "Ready to check-in" status for an entry has been found for this person. Please ask the person to create a new application at the kiosk.',
  'scan.office': 'No record in "Ready to check-in" status for an entry has been found for this person. This person\'s nationality is of category C, which means this person requires a visa. Please escort this person to the office to either get an urgent visa or be denied entry.',
  'scan.noVisaFound': 'No record in "Ready to check-out" status for an exit has been found for this person. Would you like to create one ready to check out ?',
  'zweexit': 'Exit without visa',
  'err.scanPassport': 'An error occurred while scanning the passport. Please make sure it is correctly inserted and try again. If the problem persists, please restart the application or contact the support.',
  'exit.register': 'Record an exit',
  'entry.register': 'Record an entry',
  'exit.create': 'Create an exit without visa',
  'exit.ask': 'Are you sure you want to register an exit without visa for this person?',
  'exit.success': 'The exit without visa has been successfully registered. You can now check out the person.',
  'border': 'Officer at border',
  'global.signout': 'Sign out',
  'search.manually': 'Search application',
  'scan.applicationQr': 'Scan application Qr',
  'err.client.extended.maxUsageCountReached': 'The maximum number of usages for this application has been reached.',
  'form.firstName.scanned': 'Scanned first name(s)',
  'form.lastName.scanned': 'Scanned last name',
  'form.documentNumber.scanned': 'Scanned passport number',
  'form.gender.scanned': 'Scanned Gender',
  'form.birthDate.scanned': 'Scanned date of birth',
  'form.nationality.scanned': 'Scanned nationality',
  'scanned.passport': 'Scanned passport',
  'application.data': 'Application data',
  'no.passportPhoto': 'No photo',
  'scanned.list.checkIn': 'Here are the applications found associated with your passport number',
  'overstay': 'Overstay',
  'method.payment': 'Payment method',
  'review.desc': 'Approve/Refuse',
  'approval.usageUntil.DYN.zwevisa': 'Change expiry date (optional)',
  'scanned.list': 'List of found applications',
  'err.EmergencyPhone.pattern': 'Emergency phone number must have a correct format',
  'visa.blacklist': 'This visa application has been blacklisted. The person is not allowed to enter the country.',
  'scan.noEntryFound': 'No record in "Ready to check-in" status for an entry has been found for this person. Would you like to create one ready to check in ?',
  'err.EmergencyEmail.pattern': 'Emergency contact email must have a correct format',
  'global.syncing': 'Synchronizing...',
  'payment.consume.error.DYN.zwevisa': 'An error occurred while processing the application.',
  'tooltip.Qr': 'You can directly scan the QR code associated with an application to find detailed information about that application.',
  'tooltip.arrivals': 'Check this checkbox to manage arrivals as a border officer.',
  'tooltip.departures': 'Check this checkbox to manage departures as a border officer.',
  'tooltip.searchManually': 'Search for a specific application using various criteria.',
  'tooltip.signout': 'Sign out of the application.',
  'tooltip.my_account': 'Access your account settings.',
  'tooltip.dashboard': 'Access the dashboard.',
  'tooltip.createRequests': 'Create a new application.',
  'tooltip.trips': 'View the status of your applications and print the linked document if approved.',
  'tooltip.tracking': 'Track your applications.',
  'tooltip.people': 'Manage the persons associated with your account.',
  'tooltip.users': 'Manage the users of the application.',
  'tooltip.requests': 'Manage the application requests.',
  'tooltip.places': 'Manage the places of the application.',
  'tooltip.role': 'Your role and name within the application.',
  'tooltip.exitRegister': 'Manually register an exit without a visa for a person.',
  'tooltip.entryRegister': 'Manually register an entry without a visa for a person.',
  'tooltip.support': 'Contact support for assistance with any issue.',
  'tooltip.chooseLang': 'Change the application language. Currently, only English is available.',
  'tooltip.changePassword': 'Change your password.',
  'tooltip.search': 'Filter the applications based on their name, passport number, payment ID, or reference.',
  'tooltip.internalReference': 'Filter the applications based on their internal reference.',
  'tooltip.internalReferenceNoFilter': 'Click on an internal reference, if available, to be redirected to the linked reference.',
  'tooltip.state': 'Filter the applications based on their status.',
  'tooltip.createdDate': 'Filter the applications based on their creation date using the from and to dates.',
  'tooltip.createdDateSince': 'Filter the applications based on their creation date.',
  'tooltip.overstay': 'Filter applications based on whether they have exceeded their stay duration in the country.',
  'tooltip.clearAllFilters': 'Clear all the filters applied to the applications.',
  'tooltip.filterLength': 'The number of applications that match the applied filters.',
  'tooltip.downloadCsv': 'Download the applications in a CSV format.',
  'tooltip.tablePassportTitle': 'Sort the applications based on the passport number.',
  'tooltip.tablePassport': 'The passport number associated with the application',
  'tooltip.tableInternalReferenceTitle': 'Sort the applications based on the internal reference.',
  'tooltip.tableInternalReference': 'The internal reference associated with the application',
  'tooltip.tableBatchIdTitle': 'Sort the applications based on the application reference.',
  'tooltip.tableBatchId': 'The application reference associated with the application',
  'tooltip.tableCreationDateTitle': 'Sort the applications based on the creation date.',
  'tooltip.tableCreationDate': 'The creation date of the application',
  'tooltip.tableStateTitle': 'Sort the applications based on the status.',
  'tooltip.tableState': 'The status of the application',
  'tooltip.tableCompleteNameTitle': 'Sort the applications based on the complete name.',
  'tooltip.tableCompleteName': 'The complete name of the person associated with the application',
  'tooltip.tablePriceTitle': 'Sort the applications based on the price.',
  'tooltip.tablePrice': 'The price of the application',
  'tooltip.tableUsageAfterTitle': 'Sort applications by their start date.',
  'tooltip.tableUsageAfter': 'The application start date',
  'tooltip.tableUsageUntilTitle': 'Sort applications by their expiration date.',
  'tooltip.tableUsageUntil': 'The expiration date of the application',
  'tooltip.tableIdTitle': 'Sort the applications based on the ID.',
  'tooltip.tableId': 'The ID of the application',
  'tooltip.tablePerPage': 'Change the number of applications displayed per page.',
  'tooltip.tableNumberOfPage': 'Control the pagination and number of applications displayed per page for easier navigation.',
  'tooltip.history': 'The payment history of your applications.',
  'tooltip.addPerson': 'Add a new person linked to your account.',
  'tooltip.listQr': 'See all the applications filtered and their QR code.',
  'tooltip.urgent': 'It means that the application is marked as urgent and should be treated as soon as possible.',
  'tooltip.operationId': 'The type of application.',
  'tooltip.purposeOfVisit': 'The purpose of the visit for this application.',
  'tooltip.amount': 'The price of this application.',
  'tooltip.paymentType': 'The type of payment for this visa application.',
  'tooltip.paymentReference': 'The reference of the payment for this visa application.',
  'tooltip.review': 'Review this visa application as approved or refused and add a comment.',
  'tooltip.revision': 'Ask the customer to correct the visa application.',
  'tooltip.approve': 'Approve this visa application as a final decision. You can also add a comment. If the term "blacklisted" is present, the person will be blacklisted.',
  'tooltip.refuse': 'Refuse this visa application as a final decision. You can also add a comment.',
  'tooltip.qrSee': 'See the QR code of this application and print the document linked to it.',
  'tooltip.internalEdit': 'Edit the internal reference of this visa application.',
  'tooltip.internalAdd': 'Add an internal reference to this application.',
  'tooltip.printApplication': 'Print the application with all the informations.',
  'tooltip.allReviews': 'All the reviews of this visa application, with the date and the user who made the review.',
  'tooltip.version': 'The current version of the application.',
  'tooltip.consumeProfile.IN': 'The picture captured at the entry point.',
  'tooltip.consumeNoProfile.IN': 'No picture captured at the entry point.',
  'tooltip.consumeTime.IN': 'The date and time of the entry point.',
  'tooltip.consumePlace.IN': 'The place of the entry point.',
  'tooltip.consumeProfile.OUT': 'The picture captured at the exit point.',
  'tooltip.consumeNoProfile.OUT': 'No picture captured at the exit point.',
  'tooltip.consumeTime.OUT': 'The date and time of the exit point.',
  'tooltip.consumePlace.OUT': 'The place of the exit point.',
  'tooltip.passportComparison': 'Informations scanned from the passport. It allow you to compare the informations with the actual informations of the application.',
  'tooltip.classicComparison': 'Actual informations of the application. It allow you to compare the informations with the scanned informations from the passport.',
  'tooltip.trackingValid': 'The status of your application. Here, the application is valid. It means that you can travel with this application based on the dates of validity.',
  'tooltip.trackingPending': 'The status of your application. Here, the application is awaiting corrections. It means that you have to correct some informations before the application can be approved.',
  'tooltip.trackingRefused': 'The status of your application. Here, the application is refused. It means that you cannot travel with this application.',
  'tooltip.trackingAwaitingConfirmation': 'The status of your application. Here, the application is awaiting confirmation by our services.',
  'tooltip.trackingApplication': 'Application linked to this application reference',
  'tooltip.trackingApplicationReference': 'Application reference of theses applications',
  'tooltip.allTrackingInfo': 'All the informations about this application',
  'tooltip.trackingPrint': 'Print the document linked to this application',
  'tooltip.docFirstName': 'Enter the first name of the person as it appears on the passport.',
  'tooltip.docLastName': 'Enter the last name of the person as it appears on the passport.',
  'tooltip.docPassportNumber': 'Enter the passport number of the person.',
  'tooltip.docLinkType': 'Select the type of relation you have with the person.',
  'tooltip.docLinkTypeOther': 'Precise the type of relation you have with the person.',
  'tooltip.applicationReferenceRequest': 'The application reference of the request. It is unique for each request, and it is generated automatically.',
  'tooltip.portEntryRequest': 'The port of entry of the request. It is the place where the person will enter the country.',
  'tooltip.otherName': 'Enter the other name of the applicant as it appears on the passport.',
  'tooltip.spouseCheckbox': 'Check this box if the applicant have a spouse, and fill the informations below.',
  'tooltip.hostName': 'The name of the person/organisation who will host the applicant.',
  'tooltip.scanPassport': 'Scan the passport of the applicant to find application linked to this passport. If no application is found, you can create a new one.',
  'tooltip.passportScanned': 'The informations scanned from the passport.',
  'tooltip.scannedCard': 'Similar applications found with the scanned informations.',
  'scan.error': 'An error occured during the scan of the document.',
  'tooltip.webcamProblems': 'If the camera doesn\'t work correctly, you can still check in or out the application without a picture.',
  'tooltip.checkOut': 'Check-out the person of the country.',
  'tooltip.checkIn': 'Check-in the person in the country.',
  'tooltip.consumeRefuse': 'Refuse the entry or exit of the person.',
  'tooltip.finalRefuse': 'The final decision of the application. The person will not be able to enter the country.',
  'tooltip.finalApprove': 'The final decision of the application. The person will be able to enter or leave the country.',
  'tooltip.finalPending': 'The final decision of the application. The person will not be able to enter the country until the application is corrected and approved.',
  'tooltip.finalRefuseBlacklist': 'The final decision of the application. The person will not be able to enter the country and is blacklisted.',
  'ask.updateScannedPassport': 'Do you want to update the informations of the application with the scanned informations?',
  'done.updateScannedPassport': 'The informations of the application have been successfully updated.',
  'error.updateScannedPassport': 'An error occurred while updating the informations of the application.',
  'photo.entryCountry.IN': 'Picture at check-in',
  'photo.entryCountry.OUT': 'Picture at check-out',
  'err.noPassiveAuthenticationCSCA': 'The document is valid, but could not be verified against its issuing authority.',
  'err.OtherName.pattern': 'Other name must only contain unaccented letters (either lowercase or uppercase), spaces, or hyphens, exactly as it appears on the passport.',
  'err.FirstName.pattern': 'First name must only contain unaccented letters (either lowercase or uppercase), spaces, or hyphens, exactly as it appears on the passport.',
  'err.LastName.pattern': 'Last name must only contain unaccented letters (either lowercase or uppercase), spaces, or hyphens, exactly as it appears on the passport.',
  'err.PassportNumber.pattern': 'Passport number must only contain unaccented letters (either lowercase or uppercase), numbers, spaces, or hyphens, exactly as it appears on the passport.',
  'err.passport.expired': 'The validity of the passport has expired.',
  'externalIndexedData': 'Multiple passports found',
  'client.extended.chargedBack': 'This visa application has been charged back. The individual can still proceed with checkout, but they are required to repay the entry fee in cash only.',
  'ask.chargedBack.confirm': 'Do you confirm that the person has repaid the entry fee? If you confirm, please enter the payment reference and the currency used.',
  'error.payment.noReferenceChargedBack': 'Please enter a payment reference and the currency used.',
  'client.extended.entryChargedBack': 'This visa application has been charged back. The individual cannot proceed with check-in and must create a new entry.',
  'tooltip.syncing': 'The number of application processes currently undergoing synchronization.',
  'adChoice': 'Connection with active directory',
  'form.username': 'Username',
  'err.username.required': 'Username is required',
  'err.username.minlength': 'Username must be at least 4 characters long',
  'external.down': 'A service our application depends on is not currently working. Please try again later.',
  'global.connection.server': 'Service unavailable. Sorry for the inconvenience, please retry later.',
  'global.connection.timeout': 'Timeout uploading your request. Please check your internet connection and try again.',
  'global.connection.problem': 'Error uploading your request. Please check that you do not use VPNs, firewalls, that your internet connection is stable and try again.',
  'err.passport.issuedSoon': 'The passport has been issued recently (less than a month ago).',
  'global.reload': 'Reload',
  'error.passportScan': 'CertBytes(b64): <pre>{certBytes}</pre>',
  'glpi.err.noPassiveAuthenticationCSCA': 'The passport\'s certificate hash could not be found in the ICAO master list, resulting in a \'err.noPassiveAuthenticationCSCA\' error.',
  'glpi.err.unverifiedDoc': 'Failed to retrieve the certificate bytes for passive authentication; this results in an \'err.unverifiedDoc\' error.',
  'glpi.err.noPassiveAuthentication': 'Failed to perform passive authentication; this results in an \'err.noPassiveAuthentication\' error.',
  'glpi.err.noConnect': 'Failed to establish a connection with the passport chip, resulting in an \'err.noConnect\' error.',
  'glpi.err.noBAC': 'BAC failed to establish with the provided MRZ information, triggering an \'err.noBAC\' error.',
  'glpi.err.alteredDoc': 'Data Group hashes (DGS) are incorrect for one or more entries, leading to an \'err.alteredDoc\' error.',
  'glpi.err.revokedDocSigner': 'Document signer\'s certificate serial number is found on the Certificate Revocation List (CRL), resulting in an \'err.revokedDocSigner\' error.',
  'watchlistCollapse': '<p>Watchlists found - <span class="watchlistCategories">{categories}</span></p>',
  'watchlistResponse.level': 'Alert level for this correspondence',
  'match.type': 'Match type',
  'match.hair': 'Hair',
  'match.eyes': 'Eyes',
  'match.height': 'Height',
  'match.weight': 'Weight',
  'match.race': 'Race',
  'global.category': 'Category',
  'watchlist.alertLevel': 'Alert level of the watchlist',
  'watchlist.match': 'Match {numberMatch} | <span class="watchlistCategories">{matchCategory}</span>',
  'Nationalities': 'Nationality {index}',
  'global.score': 'Score',
  'watchlist.name': 'Name of the watchlist',
  'alias': 'Alias {index}',
  'document.number': 'Number of document {index}',
  'document.issuer': 'Issuer of document {index}',
  'document.type': 'Type of document {index}',
  'dateOfBirth': 'Date of birth {index}',
  'watchlist.marks': 'Marks',
  'watchlist.image.link': 'Link to the image found {index}',
  'image.match': 'Image matched {index}',
  'tooltip.watchlist': 'The watchlist information found for this person.',
  'watchList.category': 'Category of the watchlist',
  'err.LastName.maxlength': 'Last name must be less than 50 characters long',
  'err.FirstName.maxlength': 'First name must be less than 50 characters long',
  'err.OtherName.maxlength': 'Other name must be less than 50 characters long',
  'err.PlaceOfBirthCity.maxlength': 'City of birth must be less than 50 characters long',
  'err.Phone.maxlength': 'Phone number must be less than 20 characters long',
  'err.Occupation.maxlength': 'Occupation must be less than 50 characters long',
  'err.HostName.maxlength': 'Host name must be less than 50 characters long',
  'err.EmergencyLastName.maxlength': 'Emergency contact last name must be less than 50 characters long',
  'err.EmergencyFirstName.maxlength': 'Emergency contact first name must be less than 50 characters long',
  'err.EmergencyPhone.maxlength': 'Emergency contact phone number must be less than 20 characters long',
  'err.PlaceofIssue.maxlength': 'Place of issue must be less than 50 characters long',
  'err.SpouseFullName.maxlength': 'Spouse name must be less than 50 characters long',
  'err.SpousePassportNumber.maxlength': 'Spouse passport number must be less than 20 characters long',
  'err.SpousePlaceOfBirth.maxlength': 'Spouse place of birth must be less than 50 characters long',
  'err.PassportNumber.maxlength': 'Passport number must be less than 20 characters long',
  'err.PreviousConvictionsHome.maxlength': 'Previous conviction(s) in home country must be less than 50 characters long',
  'err.FundsAvailable.maxlength': 'Funds available at time of entry must be less than 50 characters long',
  'err.firstName.maxlength': 'First name must be less than 50 characters long',
  'err.lastName.maxlength': 'Last name must be less than 50 characters long',
  'err.passportNr.maxlength': 'Passport number must be less than 20 characters long',
  'err.otherLinkType.maxlength': 'The relation link type must be less than 50 characters long',
  'err.id.maxlength': 'External ID must be less than 50 characters long',
  'err.name.maxlength': 'Name must be less than 50 characters long',
  'err.password2.maxlength': 'Password must have between 6 and 20 characters',
  'err.email.maxlength': 'Email must be less than 50 characters long',
  'err.userId.maxlength': 'User ID must be less than 50 characters long',
  'err.phoneNumbersCsv.required': 'Phone number is required',
  'err.idParent.required': 'Parent ID is required',
  'err.phoneNumbersCsv.maxlength': 'Phone number must be less than 20 characters long',
  'watchlist.level': 'Level of alert',
  'ask.fingerprint': 'Do you want to take the fingerprints of the person?',
  'scan.fingerprint.desc': 'When ready, click on the button below to start the process of taking the fingerprint of the person. The person must place their index finger on the scanner when the red light of the fingerprint device turns on, as indicated by the officer.',
  'scan.fingerprint.noResponse': 'No response from the fingerprint scanner. Has the person placed their finger on the scanner? Is the device properly connected?',
  'scan.fingerprint': 'Fingerprint',
  'fingerprint.scan.start': 'Scan fingerprint',
  'scan.fingerprint.success': 'The fingerprints have been successfully scanned. The quality of the fingerprint scanned is {quality}/100. You can retry the process if the quality is not sufficient.',
  'fingerprint.notAvailable': 'It seems that the fingerprint scanner is not available, or the drivers are not installed. You can still proceed with the check-in without the fingerprints.',
  'skip': 'Skip',
  'global.checkOut.fingerprint': 'Check-out with fingerprint',
  'global.checkIn.fingerprint': 'Check-in with fingerprint',
  'tooltip.checkOut.fingerprint': 'Check-out the person of the country with the fingerprint.',
  'tooltip.checkIn.fingerprint': 'Check-in the person in the country with the fingerprint.',
  'paymentError.title': 'Problem with your order',
  'DYN.paymentError.desc.fail': 'Unfortunately, the payment has failed. Please try again.',
  'DYN.paymentError.desc.error': 'Unfortunately, the payment processing has returned an error. Please contact us',
  'DYN.paymentError.desc.later': 'Unfortunately, the payment processing was temporarily declined. Please try again.',
  'tooltip.applicationId': 'The ID of the application.',
  'client.extended.missingResource': 'The resource you requested is currently unavailable. Please try again later.',
  'skip.valid': 'Autoskip reviewed steps',
  'extension.data': 'Extension data',
  'attestantAddress': 'Address of the attestant',
  'attestantEmail': 'Email of the attestant',
  'attestantID': 'ID of the attestant',
  'attestantName': 'Name of the attestant',
  'attestantPhone': 'Phone of the attestant',
  'attestantRelation': 'Relation with the attestant',
  'daysRequested': 'Number of days requested',
  'tooltip.visaApplicationReference': 'Go to the visa application linked to this extension request',
  'extend.visaReference': 'Application linked to this extension request',
  'data.copyOfEntry': 'Copy of the entry',
  'data.departureTicket': 'Departure ticket',
  'data.attestantDocument': 'Document of the attestant',
  'data.affidavitCustody': 'Custody Affidavit',
  'reasonForExtention': 'Reason for extension',
  'final.ZWEVISAEXTAB': 'This extension request need to be approved by the officer.',
  'zwevisaExtValid': 'Extension validated',
  'zwevisaExtValid.desc': 'This extension request has been validated by the officer.',
  'zwevisaExtC.review.state': 'Awaiting review',
  'zwevisaExtC.review.desc': 'This extension request is awaiting review.',
  'zwevisaExtC.finalReview.desc': 'This extension request has been reviewed and is currently awaiting final review.',
  'zwevisaExtC.finalReview.state': 'Awaiting final review',
  'onlyFinalReview': 'This application is awaiting final review.',
  'err.AttestantName.maxlength': 'Attestant name must be less than 50 characters long',
  'tooltip.tableLocalUsageUntilTitle': 'Sort the application based on the maximum end date of stay.',
  'tooltip.tableLocalUsageUntil': 'The expiration date of the application refers to the maximum end date of stay.',
  'ask.usageUntil.confirm': 'Here you can change the maximum end date of stay. You can only lower the date.',
  'ask.prepareLandingVisa': 'You can create a visa to be validated and paid offline. The visa is subject to one approval and once approved, is valid for the individual subject to collection of his payment at the port of entry.',
  'ask.prepareLandingVisa.ok': 'The visa is successfully registered for approval',
  'ask.prepareLandingVisa.nok': 'Error submitting information for the visa',
  'usageUntil.ask': 'Change maximum end of stay (optional)',
  'error.bad.localUsageUntil': 'The maximum end date of stay must be lower than the current expiration date and after today.',
  'no.limit': 'No limit',
  'to.determined': 'To be determined',
  'none': 'None',
  'change.password.ad': 'Having an active directory account, you are not able to change your password here. For more information, please contact the support.',
  'noticePrint.title': 'Informations of the notice to Visitor printed to the customer',
  'reportingPost': 'Reporting post',
  'noticePrint.matTooltip': 'Informations of the notice to Visitor printed to the customer during the check-in process',
  'err.paragraph.pattern': 'The field must contain only a single unaccented letter.',
  'ask.print': 'Do you want to print a notice to visitor or a provisional restriction notice?',
  'notice.confirm': 'Are you sure about the informations on the notice ?',
  'camera.printNotice': 'Print the notice',
  'global.refusePrint': 'Print and submit',
  'global.nextPrint': 'Print and continue',
  'err.periodFrom.periodFromGreaterThanPeriodTo': 'The period from must be before the period to.',
  'err.externalId.maxlength': 'External ID must be less than 60 characters long',
  'payment_status.DYN.pass.9': 'Pay Later - Confirmed',
  'payment.reject.success': 'The payment has been successfully rejected.',
  'payment.reject_reason': 'Reason for rejection',
  'err.fileSize': 'File size is too big. Maximum size is: {size}',
  'scan.birthCertificate': 'Upload the birth certificate',
  'err.scanBirthCertificate.required': 'The birth certificate is required',
  'document.zafembpass': 'ZAF embassy passport',
  'preenroll': 'Pre-enroll',
  'screen': 'Screening',
  'pass': 'Passeport',
  'zafembpass': 'ZAF embassy passport',
  'document.pass': 'Passport',
  'payment.validate.success': 'The payment has been successfully validated.',
  'document.screen': 'Screening',
  'payment.consume.print.done.DYN.zwevisa': 'The visa application has been successfully checked-in/out. The receipt will be printed.',
  'payment.ask.print': 'Is the receipt printed?',
  'global.no.retry': 'No (retry)',
  'print.receipt': 'Print the receipt',
  'tooltip.printReceipt': 'Print the receipt(s) of the application',
  'ask.printReceipt': 'Do you want to print the receipt dated <b>{date}</b> ?',
  'done.printReceipt': 'The receipt has been successfully printed.',
  'error.printReceipt': 'An error occurred while printing the receipt. Please try again.',
  'ask.selectReceipt': 'Please select the receipt you want to print.',
  'error.noReceiptSelected': 'Please select a receipt to print.',
  'operation.OUT': 'Check-out',
  'operation.IN': 'Check-in',
  'noChoice.selectedReceipt': 'Please select a receipt to print.',
  'select.receipt': 'Select the receipt',
  'receiptFor': 'ReCeipt For',
  'receiptFor.alt': 'ReCeipt for',
  'paymentType': 'Payment type',
  'totalAmount.alt': 'Total amount',
  'totalAmount': 'Total amount',
  'totalAmountCurrency': 'Total in paid currency',
  'electronic.receipt': 'Receipt',
  'date': 'Date',
  'client': 'Client',
  'reference': 'Reference',
  'agentID': 'Agent ID',
  'desc': 'Description',
  'details': 'Details',
  'client.extended.minUsageNotMet': 'The valid until must be at least 1 day after the valid from.',
  'err.BirthDay.minDate': 'The date of birth must be less than 150 years ago.',
  'err.SpouseBirthDay.minDate': 'The date of birth of the spouse must be less than 150 years ago.',
  'payment.assigned.warning': 'This application is currently in active usage by another officer (Officer: {officerId}) less than 24 hours ago. Do you still want to open this application ?',
  'lock': 'Lock',
  'tooltip.tableLockTitle': 'Active usage by officer(s)',
  'tooltip.tableLock.me': 'You are currently the active officer of this application',
  'tooltip.tableLock.other': 'This application is currently in active usage by the officer {officer}',
  'client.extended.uuidNotPaid': 'The payment has not been validated yet. Please validate the payment before proceeding.',
  'form.operationComments': 'Justify the operation',
  'err.operationComments.required': 'Justify the operation is required',
  'err.operationComments.maxlength': 'Justify the operation must be less than 500 characters long',
  'err.DateOfExpiry.maxDate': 'The date of expiry must be less than 10 years from now.',
  'err.ArrivalDate.maxDate': 'The arrival date must be less than 150 years from now.',
  'err.DepartureDate.maxDate': 'The departure date must be less than 150 years from now.',
  'err.periodFrom.minDate': 'The period from must be after today.',
  'err.periodFrom.maxDate': 'The period from must be less than 150 years from now.',
  'err.periodTo.minDate': 'The period to must be after today.',
  'err.periodTo.maxDate': 'The period to must be less than 150 years from now.',
  'err.reportOn.minDate': 'The report on must be after today.',
  'err.reportOn.maxDate': 'The report on must be less than 150 years from now.',
  'err.PlaceofIssue.required': 'Place of issue is required',
  'err.borderPass.required': 'The border pass is required',
  'exit.borderPass.success': 'The exit without visa has been successfully registered. You will be redirected to the request details.',
  'borderPass.emit': 'Border pass emitted',
  'tooltip.borderPass': 'The border pass is a document that allows a person to cross the border. It is used when the person does not have a passport.',
  'zweexit.title': 'Exit without visa',
  'borderPass.title': 'Border pass',
  'print.borderPass': 'Print the border pass',
  'print.borderPass.ask': 'Is the border pass printed?',
  'err.borderPassNumber.maxlength': 'The border pass number must be less than 20 characters long',
  'err.issueDate.afterExpiryDate': 'The issue date must be before the expiry date.',
  'err.BirthDay.afterExpiryDate': 'The birth date must be before the expiry date.',
  'err.BirthDay.afterIssueDate': 'The birth date must be before the issue date.',
  'apiPnrCollapse': 'PNR found',
  'apipnrResponse.match': 'Match {numberMatch}',
  'match.name': 'Name',
  'match.score.name': 'Score of the name',
  'match.flightCarrier': 'Flight carrier',
  'match.flightNumber': 'Flight number',
  'match.documentType': 'Type of document',
  'match.documentNumber': 'Number of document',
  'match.documentIssuer': 'Issuer of document',
  'match.timeType': 'Event type',
  'match.timeDate': 'Date of the event',
  'match.timeScope': 'Scope of the event',
  'match.nationality': 'Nationality',
  'match.gender': 'Gender',
  'match.uuid': 'UUID',
  'match.travelerUUID': 'Traveler UUID',
  'tooltip.apiPnr': 'The PNR information found for this person.',
  'match.documentIssued': 'Date of issue of the document',
  'match.documentExpiration': 'Date of expiration of the document',
  'match.documentScore': 'Score of the document',
  'match.timeScore': 'Score of the event',
  'match.flightOriginDomestic': 'Domestic origin of the flight',
  'match.flightOriginIcao': 'ICAO of the flight origin',
  'match.flightOriginLat': 'Latitude of the flight origin',
  'match.flightOriginLng': 'Longitude of the flight origin',
  'match.flightOriginIata': 'IATA of the flight origin',
  'match.flightOriginCity': 'City of the flight origin',
  'match.flightOriginState': 'State of the flight origin',
  'match.flightOriginCountry': 'Country of the flight origin',
  'match.flightOriginContinent': 'Continent of the flight origin',
  'match.flightOriginName': 'Name of the flight origin',
  'match.flightOriginTimezone': 'Timezone of the flight origin',
  'match.flightDestinationDomestic': 'Domestic destination of the flight',
  'match.flightDestinationIcao': 'ICAO of the flight destination',
  'match.flightDestinationLat': 'Latitude of the flight destination',
  'match.flightDestinationLng': 'Longitude of the flight destination',
  'match.flightDestinationIata': 'IATA of the flight destination',
  'match.flightDestinationCity': 'City of the flight destination',
  'match.flightDestinationState': 'State of the flight destination',
  'match.flightDestinationCountry': 'Country of the flight destination',
  'match.flightDestinationContinent': 'Continent of the flight destination',
  'match.flightDestinationName': 'Name of the flight destination',
  'match.flightDestinationTimezone': 'Timezone of the flight destination',
  'match.localDepartureDate': 'Local departure date',
  'match.localDepartureTime': 'Local departure time',
  'match.localDepartureTotalMinutes': 'Local departure total minutes',
  'match.localDepartureDay': 'Local departure day',
  'match.localDepartureZone': 'Local departure zone',
  'match.airportDepartureDate': 'Airport departure date',
  'match.airportDepartureTime': 'Airport departure time',
  'match.airportDepartureTotalMinutes': 'Airport departure total minutes',
  'match.airportDepartureDay': 'Airport departure day',
  'match.airportDepartureZone': 'Airport departure zone',
  'match.utcDepartureDate': 'UTC departure date',
  'match.utcDepartureTime': 'UTC departure time',
  'match.utcDepartureTotalMinutes': 'UTC departure total minutes',
  'match.utcDepartureDay': 'UTC departure day',
  'match.utcDepartureZone': 'UTC departure zone',
  'match.originalDeparture': 'Original departure',
  'match.flightDepartureUtcstr': 'Flight departure UTC',
  'match.localArrivalDate': 'Local arrival date',
  'match.localArrivalTime': 'Local arrival time',
  'match.localArrivalTotalMinutes': 'Local arrival total minutes',
  'match.localArrivalDay': 'Local arrival day',
  'match.localArrivalZone': 'Local arrival zone',
  'match.airportArrivalDate': 'Airport arrival date',
  'match.airportArrivalTime': 'Airport arrival time',
  'match.airportArrivalTotalMinutes': 'Airport arrival total minutes',
  'match.airportArrivalDay': 'Airport arrival day',
  'match.airportArrivalZone': 'Airport arrival zone',
  'match.utcArrivalDate': 'UTC arrival date',
  'match.utcArrivalTime': 'UTC arrival time',
  'match.utcArrivalTotalMinutes': 'UTC arrival total minutes',
  'match.utcArrivalDay': 'UTC arrival day',
  'match.utcArrivalZone': 'UTC arrival zone',
  'match.originalArrival': 'Original arrival',
  'match.flightArrivalUtcstr': 'Flight arrival UTC',
  'match.flightBound': 'Flight bound',
  'print.receipt.select': 'The receipt will be printed.',
  'ask.notice': 'Print a notice to visitor or a provisional restriction notice',
  'tooltip.checkIn.notice': 'Check-in the person in the country and print the notice to visitor or a provisional restriction notice.',
  'global.checkIn.notice': 'Check-in with a notice',
  'event.downgrade': 'This application has been downgraded from <b>{previousPayment}</b> to <b>{newPayment}</b> the {date} by the user <b>{userId}</b>.',
  'form.numberPeople': 'Number of people travelling with the applicant',
  'feedbackForm': 'Feedback form',
  'form.totalSpend': 'Total spend during the stay',
  'countryPermanentResidence': 'Country of permanent residence',
  'countryDestination': 'Country of destination',
  'form.feedbackComments': 'Additional comments',
  'feedback.submit': 'Submit feedback',
  'err.fundsSpend.required': 'Total spend during the stay is required',
  'err.fundsCurrency.required': 'Currency of the spend is required',
  'err.countryResidence.required': 'Country of permanent residence is required',
  'err.countryDestination.required': 'Country of destination is required',
  'err.feedbackComment.required': 'Additional comments are required',
  'feedback.submitted': 'The feedback has been successfully submitted.',
  'start_feedback': 'Give a feedback'
};
