<div id="print">
  <div class="print_content">
    <div class="flex f_between mt_30">
      <div>
        <span><b>Ministry of Home Affairs<br>Immigration Department</b></span>
      </div>
      <span><b>Border Pass</b></span>
    </div>
    <div class="text_center">
      <h3>GOVERNMENT OF ZIMBABWE<br>IMMIGRATION DEPARTMENT</h3>
      <h2>** BORDER PASS **</h2>
    </div>
    <div class="mt_10">
      <p>Border Pass Number: <b>{{data.borderPassNumber}}</b></p>
      <p>Full Name: <b>{{data.fullNames}}</b></p>
      <p>Date of Birth: <b>{{data.dateOfBirth | date: 'dd/MM/yyyy'}}</b></p>
      <p>Residential Address: <b>{{data.residentialAddress}}</b></p>
    </div>
    <div class="mt_10">
      <p>Issue Date: <b>{{data.issueDate | date: 'dd/MM/yyyy'}}</b></p>
      <p>Expiry Date: <b>{{data.expiryDate | date: 'dd/MM/yyyy'}}</b></p>
    </div>
    <div class="mt_10">
      <p>Reason for Crossing: <b>{{data.reason}}</b></p>
      <p>Authorized Crossing Points: <b>{{data.authorizedCrossingPoints}}</b></p>
    </div>
    <div class="mt_10">
      <p>Authority's Signature:</p>
      <div class="flex f_between mt_10">
        <p>_________________________</p>
        <p>Official Seal:</p>
      </div>
    </div>
    <div class="mt_10">
      <p><b>Terms of Use:</b></p>
      <ul class="ml_20">
        <li>This border pass is valid only for crossings at the authorized points.</li>
        <li>Any misuse of this document will result in penalties.</li>
        <li>This document must be presented at each border crossing.</li>
      </ul>
    </div>
    <div class="mt_10">
      <div class="flex f_between mt_10">
        <p>Place: <b>{{data.place}}</b></p>
        <div class="text_end">
          <p><i>Immigration Officer</i></p>
          <p><b>{{sync.userDisplay(data.officer)}} #{{data.officer}}</b></p>
        </div>
        <qrcode *ngIf="data.qrCode" [qrdata]="data.qrCode" elementType="img" [margin]="0"></qrcode>
      </div>
      <div class="mt_10">
        <p>I hereby acknowledge receipt of this notice.</p>
      </div>
    </div>
  </div>
</div>
