<div class="loader_page">
  <div id="offlineLoader" *ngIf="!api.isOnline">
    <fa-icon icon="wifi"></fa-icon>
    <span innerHTML="{{'internet.lost' | translate}}"></span>
  </div>
  <div class="main-container">
    <app-nav class="resp_minSize_l" *ngIf="(api.userRole?.isCustomer && api.userInfo) || (!api.userRole?.isCustomer && api.userInfo && api.userPlaceId)"></app-nav>
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>
<span [matTooltip]="'tooltip.version' | translate" id="appVersion" (click)="electron?.dev()">{{api.env.type}} {{api.env.production ? 'PROD' : 'DEV'}} - {{api.env.appVersion.join('.')}}
  <strong *ngIf="api.status">{{api.status | translate}}</strong>
</span>
<app-print-master></app-print-master>
