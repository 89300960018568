<div id="layout" class="sign_in f_col">
  <app-view-title fromRoute="pre-enroll" [chooseLangBtn]="false">{{(isPaymentError ? 'paymentError.title' :  'successPayment.title') | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
        <app-view-deco-image class="mt_20 mb_20" [imageUrl]="isPaymentError ? 'assets-projects/images/image_warn.svg' : 'assets-projects/images/image_done.svg'"></app-view-deco-image>
        <app-paragraph class="mt_20" [innerhtml]="(isPaymentError ? 'DYN.paymentError.desc.' + isPaymentError : 'DYN.paymentError.desc.success') | translate"></app-paragraph>
        <app-paragraph class="mt_20" *ngIf="longError">Error: {{longError}}</app-paragraph>
        <div class="btns-container">
          <div class="fullWidth flex f_center">
            <div class="menuButton-container flex f_center">
              <div class="ml_10 fullWidth flex f_col f_align_c">
                <app-button [initStartDebounce]="false" [showRemainingDebounceTime]="false" [debounceTimeInMilliseconds]="10000" size="xsm" color="transparent" border="true" formId="form" class="resp_minSize_l fullWidth" (btnClicked)="navClose()">{{'global.close' | translate}}</app-button>
              </div>
            </div>
          </div>
        </div>
    </div>

  </div>
  <div class="mb_20">
    <app-button color="transparent" border="true" formId="form" class=" resp_maxSize_l fullWidth" (btnClicked)="nav.to('dashboard')">{{'global.back' | translate}}</app-button>
  </div>
</div>
