<div id="print-receipt-a5">
  <img class="print-receipt_bg hide_info" src="./assets-projects/print_bg.jpg" alt="">
  <div class="invoice-container">
    <table><tbody><tr><td style="width: 20%;"></td><td style="width: 60%;">
      <div class="print_content hide_info2" style="margin-top: 100px;">
        <table class="receipt-table">
          <thead>
          <tr class="hide_info2">
            <th>{{'desc' | translate}}</th>
            <th class="right-align">{{'details' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td innerHtml="{{'receiptFor.alt' | translate}}:"></td>
            <td class="right-align">{{data.buyDocument + '.title' | translate}}</td>
          </tr>
          <tr>
            <td>{{'date' | translate}}:</td>
            <td class="right-align">{{format(data.datePrint, 'dd/MM/yyyy HH:mm')}}</td>
          </tr>
          <tr>
            <td>{{'client' | translate}}:</td>
            <td class="right-align">{{data.nameClient}}</td>
          </tr>

          <tr>
            <td>{{'nationality' | translate}}:</td>
            <td class="right-align">{{('DYN.' + data.nationality) | translate}}</td>
          </tr>
          <tr>
            <td>{{'gender' | translate}}:</td>
            <td class="right-align">{{data.gender}}</td>
          </tr>
          <tr>
            <td>{{'HQReference' | translate}}:</td>
            <td class="right-align">{{data.HQReference}}</td>
          </tr>
          <tr>
            <td>{{'trip.name' | translate}}:</td>
            <td class="right-align">{{data.idRequest}}</td>
          </tr>
          <tr>
            <td innerHtml="{{'transactionReference.alt' | translate}}:"></td>
            <td class="right-align">{{data.referencePrint}}</td>
          </tr>
          <tr>
            <td innerHtml="{{'issuingOfficer.alt' | translate}}:"></td>
            <td class="right-align">{{sync.userDisplay(data.agentId)}} #{{data.agentId}}</td>
          </tr>
          <tr>
            <td>{{'placeOfIssue' | translate}}:</td>
            <td class="right-align">{{data.placeId}}</td>
          </tr>
          <tr>
            <td innerHtml="{{'paymentType.alt' | translate}}:"></td>
            <td class="right-align">{{('form.type.' + data.typePayment) | translate}} {{(data.onlinePayment === 'online' ? '|' + ('online' | translate) : '')}}</td>
          </tr>
          <tr>
            <td innerHtml="{{'totalAmount.alt' | translate}}"></td>
            <td class="right-align">{{(data.amount / 100).toFixed(2)}} {{data.currency}}</td>
          </tr>
          <ng-container *ngIf="data.currency !== 'USD'">
            <tr>
              <td>{{'totalAmountCurrency' | translate}}:</td>
              <td
                class="right-align">{{request.convertCurrency('USD', data.amount / 100, data.currency).amount}} {{data.currency}}
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
      </td><td style="width: 15%;">
      <div class="qr-code mt_20 hide_info2" style="margin-top: -60px; margin-left: 30px;">
        <qrcode *ngIf="data.qrCode" class="qr-print" [qrdata]="data.qrCode" [width]="140" errorCorrectionLevel="M" elementType="svg"></qrcode>
      </div>
    </td><td style="width: 5%;"></td></tr></tbody></table>
  </div>
</div>
