<div id="layout" class="f_col">
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l" [class.fullHeight]="!api.userRole.isCustomer">
      @if(api.userRole.isWorker && api.env.type === DocumentType.ZWEVISA) {
        <app-view-deco-image class="mt_40 resp_maxSize_l" imageUrl="assets-projects/bms.svg"></app-view-deco-image>
      } @else {
        <app-view-deco-image maxWidth="200px" *ngIf="api.userRole.isCustomer || api.env.type === DocumentType.PASS" class="mt_40" [class.resp_minSize_l]="api.env.type === DocumentType.PASS" [imageUrl]="api.env.theme.logo"></app-view-deco-image>
        <app-view-deco-image maxWidth="200px" *ngIf="!api.userRole.isCustomer && !api.userRole.isKiosk" class="mt_40 resp_maxSize_l" [imageUrl]="api.env.theme.logo"></app-view-deco-image>
      }
      <div class="f_center resp_maxSize_l" *ngIf="api.userInfo" [class.mt_20]="api.env.type === DocumentType.ZWEVISA">
        <app-snackbar *ngIf="!api.userRole.isCustomer && !api.userRole.isKiosk">{{api.userInfo.name + ' | ' + (('form.role.'+userRoles[api.userInfo?.role] | lowercase) | translate)}}</app-snackbar>
        <app-snackbar *ngIf="api.userRole.isCustomer">{{api.userInfo.name}}</app-snackbar>
      </div>
      <div class="mt_30 fullHeight" [class.fullHeight-noMobile]="api.userRole.isWorker">
        <ng-container *ngIf="api.userRole?.isCustomer">
          <app-slide-element leftIcon="passport" class="mt_10" (btnClicked)="cart.startApplication()" [matTooltip]="api.isZwevisa ? ('tooltip.createRequests' | translate) : ''">{{('menu.createRequests.DYN.'+api.env.type) | translate}}</app-slide-element>
          <app-slide-element leftIcon="arrow-right-arrow-left" class="mt_10" (btnClicked)="nav.to(environment.type === DocumentType.PASS ? 'tracking-control' : 'trips')"  [matTooltip]="api.isZwevisa ? ('tooltip.trips' | translate) : ''">{{(environment.type === DocumentType.PASS ? 'dashboard.follow_up' : 'trips.title') | translate}}</app-slide-element>
          <app-slide-element leftIcon="people-roof" class="mt_10" (btnClicked)="nav.to('people')" [matTooltip]="api.isZwevisa ? ('tooltip.people' | translate) : ''">{{'dashboard.people.DYN.' + api.env.type | translate}}</app-slide-element>
        </ng-container>
        <ng-container *ngIf="!api.userRole?.isCustomer && api.env.type === DocumentType.PASS">
          <app-slide-element *ngIf="api.hasPerm(api.perm.ALLOW_ADMIN)" leftIcon="user" [disabled]="!api.isOnline" [colorRight]="!api.isOnline ? '#f51d2a' : ''" [rightIcon]="!api.isOnline ? 'wifi' : 'arrow-right'" (btnClicked)="!api.isOnline ? offlineLoader() : nav.to('users')">{{'menu.users' | translate}}</app-slide-element>
          <app-slide-element *ngIf="api.hasPerm(api.perm.ALLOW_CREATE)" [disabled]="!api.isOnline" [colorRight]="!api.isOnline ? '#f51d2a' : ''" [rightIcon]="!api.isOnline ? 'wifi' : 'arrow-right'"  leftIcon="database" class="mt_10" (btnClicked)="!api.isOnline ? offlineLoader() :nav.to('admin-request')">{{api.userRole?.isCustomer ? 'menu.createRequests.DYN.'+api.env.type : 'menu.requests.DYN.'+api.env.type | translate}}</app-slide-element>
          <app-slide-element *ngIf="api.hasPerm(api.perm.ALLOW_ADMIN)" [disabled]="!api.isOnline" leftIcon="location-dot" [colorRight]="!api.isOnline ? '#f51d2a' : ''" [rightIcon]="!api.isOnline ? 'wifi' : 'arrow-right'" class="mt_10" (btnClicked)="!api.isOnline ? offlineLoader() : nav.to('places')">{{'menu.places' | translate}}</app-slide-element>
          <app-slide-element *ngIf="api.hasPerm(api.perm.ALLOW_CREATE) && environment.type === DocumentType.PASS" [disabled]="!api.isOnline" leftIcon="money-check-dollar" [colorRight]="!api.isOnline && '#f51d2a'" [rightIcon]="!api.isOnline ? 'wifi' : 'arrow-right'" class="mt_10" (btnClicked)="!api.isOnline ? offlineLoader() : nav.to('admin-request-create')">{{'create_payment.title' | translate}}</app-slide-element>
        </ng-container>
        <div *ngIf="!api.userRole?.isCustomer && api.env.type === DocumentType.ZWEVISA" class="menu-container">
          <div *ngIf="api.userRole.isWorker && api.hasPerm(api.perm.ALLOW_CONSUME)" class="flex gap_10 f_col fullWidth">
            <div class="flex gap_10 mb_20 fullWidth">
              <app-checkbox-element
              class="fullWidth"
              [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.arrivals' | translate) : ''"
                              (onChange)="scanDocument.changeConsume(ConsumeMode.ENTRY, true, form)"
                              [formGrp]="form"
                              [formCtrlName]="'entryConsume'"
                              [id]="'entry'"
                              [text]="'entry.consume'"></app-checkbox-element>
              <app-checkbox-element
              class="fullWidth"
              [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.transit' | translate) : ''"
                              (onChange)="scanDocument.changeConsume(ConsumeMode.TRANSIT, true, form)"
                              [formGrp]="form"
                              [formCtrlName]="'transitConsume'"
                              [id]="'transit'"
                              [text]="'transit.consume'"></app-checkbox-element>
              <app-checkbox-element
              class="fullWidth"
              [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.departures' | translate) : ''"
                              (onChange)="scanDocument.changeConsume(ConsumeMode.EXIT, true, form)"
                              [formGrp]="form"
                              [formCtrlName]="'exitConsume'"
                              [id]="'exit'"
                              [text]="'exit.consume'"></app-checkbox-element>
            </div>
          </div>
          <app-divider class="mb_10 fullWidth" orientation="horizontal" *ngIf="api.userRole.isWorker && api.hasPerm(api.perm.ALLOW_CONSUME)"></app-divider>
          <div class="dashboardBtn-container f_center f_col f_align_c" *ngIf="api.userRole.isKiosk">
            <app-view-deco-image *ngIf="api.userRole.isKiosk" maxWidth="220px" imageUrl="assets-projects/logo-kiosk.svg" margin="none"></app-view-deco-image>
            <ng-container>
              <div class="dasbhboard-btn cursor-pointer mb_40" (click)="cart.startApplication(true)">
                <fa-icon icon="play"></fa-icon>
                {{'dashboard.kiosk.start' | translate}}</div>
            </ng-container>
          </div>
          <div class="dashboardBtn-container" *ngIf="!api.userRole.isKiosk">

            <ng-container *ngIf="previousIdApplication">
              <div class="dasbhboard-btn cursor-pointer" [shortcut]="ShortcutAction.BACKSPACE" (click)="nav.to('admin-request-select/' + previousIdApplication, undefined, {queryParams: {from: 'dashboard'}})" [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.goLatest' | translate) : ''">
                <fa-icon icon="backward-step"></fa-icon>
                {{'global.goLatest' | translate}}</div>
            </ng-container>

            <ng-container *ngIf="scanDocument.consumeMode === ConsumeMode.EXIT && api.hasPerm(api.perm.ALLOW_CONSUME) && api.hasPerm(api.perm.ALLOW_CREATE)">
              <div class="dasbhboard-btn cursor-pointer" shortcut [shortcutNumList]="true" (click)="nav.to('admin-request-create', undefined, {queryParams: {fromRoute: 'dashboard'}})" [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.exitRegister' | translate) : ''">
                <fa-icon icon="arrow-right-from-bracket"></fa-icon>
                {{'exit.register' | translate}}</div>
            </ng-container>
            <ng-container *ngIf="scanDocument.consumeMode === ConsumeMode.TRANSIT && api.hasPerm(api.perm.ALLOW_CONSUME) && api.hasPerm(api.perm.ALLOW_CREATE)">
              <div class="dasbhboard-btn cursor-pointer" shortcut [shortcutNumList]="true" (click)="cart.startApplication(true)" [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.transitRegister' | translate) : ''">
                <fa-icon icon="arrows-turn-to-dots"></fa-icon>
                {{'transit.register' | translate}}</div>
            </ng-container>
            <ng-container *ngIf="scanDocument.consumeMode === ConsumeMode.ENTRY && api.hasPerm(api.perm.ALLOW_CONSUME) && api.hasPerm(api.perm.ALLOW_CREATE)">
              <div class="dasbhboard-btn cursor-pointer" shortcut [shortcutNumList]="true" (click)="cart.startApplication(true)" [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.entryRegister' | translate) : ''">
                <fa-icon icon="arrow-right-to-bracket"></fa-icon>
                {{'entry.register' | translate}}</div>
            </ng-container>

            <ng-container>
              <div class="dasbhboard-btn cursor-pointer" shortcut [shortcutNumList]="true" (click)="clickScan()" [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.Qr' | translate) : ''">
                <fa-icon icon="qrcode"></fa-icon>
                {{'scan.applicationQr' | translate}}</div>
            </ng-container>

            <ng-container>
              <div class="dasbhboard-btn cursor-pointer" *ngIf="api.hasPerm(api.perm.ALLOW_CREATE) && !api.hasPerm(api.perm.ALLOW_CONSUME)" shortcut [shortcutNumList]="true" (click)="prepareLandingVisa()" [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.prepareLandingVisa' | translate) : ''">
                <fa-icon icon="circle-check"></fa-icon>
                {{'tooltip.prepareLandingVisa' | translate}}</div>
            </ng-container>

            <ng-container>
              <div class="dasbhboard-btn cursor-pointer" shortcut [shortcutNumList]="true" (click)="nav.to('admin-request')" [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.searchManually' | translate) : ''">
                <fa-icon icon="magnifying-glass"></fa-icon>
                {{'search.manually' | translate}}</div>
            </ng-container>

            <ng-container *ngIf="api.hasPerm(api.perm.ALLOW_CONSUME) && api.isElectronApp && scanDocument.consumeMode">
              <div class="dasbhboard-btn cursor-pointer" [shortcut]="ShortcutAction.ENTER" (click)="scanDocument.scanPassport()" [matTooltip]="api.isZwevisa ? ('tooltip.scanPassport' | translate) : ''">
                <fa-icon icon="passport"></fa-icon>
                {{'dropdown.pass' | translate}}</div>
            </ng-container>

            <ng-container *ngIf="api.hasPerm(api.perm.ALLOW_ADMIN)">
              <div class="dasbhboard-btn cursor-pointer" shortcut [shortcutNumList]="true" (click)="nav.to('users')">
                <fa-icon icon="users"></fa-icon>
                {{'menu.users' | translate}}</div>
            </ng-container>

            <ng-container *ngIf="!api.userRole?.isKiosk">
              <div class="dasbhboard-btn cursor-pointer" shortcut [shortcutNumList]="true" (click)="nav.to('my-account')" [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.my_account' | translate) : ''">
                <fa-icon icon="gear"></fa-icon>
                {{'dashboard.my_account' | translate}}</div>
            </ng-container>

            <ng-container>
              <div class="dasbhboard-btn cursor-pointer" (click)="disconnect()" shortcut [shortcutNumList]="true" [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.signout' | translate) : ''">
                <fa-icon class="icon-disconnect" icon="user-large-slash"></fa-icon>
                {{'global.signout' | translate}}</div>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="!api.userRole?.isKiosk && (api.userRole.isCustomer || api.env.type === DocumentType.PASS)">
          <app-slide-element leftIcon="gear" class="mt_10" (btnClicked)="nav.to('my-account')" [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.my_account' | translate) : ''">{{'dashboard.my_account' | translate}}</app-slide-element>
        </ng-container>
        <div class="resp_minSize_l mt_20" *ngIf="api.hasPerm(api.perm.ALLOW_CREATE) && api.env.type === DocumentType.PASS">
          <app-button size="sm" class="fullWidth" icon="qrcode" (btnClicked)="clickScan()">{{'payments.scan' | translate}}</app-button>
        </div>
      </div>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 flex f_center resp_maxSize_l" *ngIf="api.hasPerm(api.perm.ALLOW_CREATE) && api.env.type === DocumentType.PASS">
    <div class="ml_10">
      <app-button class="fullWidth" icon="qrcode" (btnClicked)="clickScan()">{{'payments.scan' | translate}}</app-button>
    </div>
  </div>
</div>
