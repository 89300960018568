import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators} from '@angular/forms';
import {FormService, LangService, LoaderService, NgxSatorisModule} from 'ngx-satoris';
import {FormField, VisaFormName, VisaFN, FormAction, VisaFormConfig} from '../../shared/models/forms';
import {FormError} from 'ngx-satoris/lib/shared/models/forms';
import {CartService} from '../../shared/services/cart.service';
import {endOfDay, format, isAfter, startOfDay} from 'date-fns';
import {compressFile} from '../../shared/utils/compressImage';
import {ApiService} from 'src/app/shared/services/api.service';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [CommonModule, FormsModule, NgxSatorisModule, ReactiveFormsModule, MatTooltip],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FormComponent {
  @Input() formName: VisaFormName;
  @Input() classNames: string;
  @Input() showConfirm: boolean;
  @Input() config: VisaFormConfig;
  @Input() personId: number;
  @Input() submitOnStart: boolean;
  @Output() submit: EventEmitter<{form: FormGroup, byPass: boolean}> = new EventEmitter();
  @Output() actionBtn = new EventEmitter<FormAction>();

  form: FormGroup;
  submitted: boolean;
  FormAction= FormAction;
  formLoaded: boolean;

  constructor(public forms: FormService,
    private formBuilder: FormBuilder,
    private cart: CartService,
    private loader: LoaderService,
    private lang: LangService,
    public api: ApiService) {}

  ngOnInit() {
    this.personId = this.cart.currentRequest.person.id;
    this.config[this.formName].visited = true;
    this.form = this.formBuilder.group({});
    if(this.config[this.formName].fields) {
      this.generateForm();
    }
    if(this.submitOnStart) {
      this.submitForm();
    }
  }

  ngOnDestroy() {
    this.saveForm();
  }

  generateForm() {
    this.config[this.formName].fields.forEach((field: FormField) => {
      const originalValidators: any[] = this.cart.getOriginalForm()[this.formName].fields.find((x: { name: string; }) => x.name === field.name)?.validators;
      const fieldValidators: ValidatorFn[] = [];
      field.validators?.forEach((_validator: string | ValidatorFn) => {
        if(typeof _validator === 'string' && _validator in Validators) {
          fieldValidators.push(Validators[_validator as keyof typeof Validators] as ValidatorFn);
        }
      });

      const allValidators: ValidatorFn[] = !field.onlyCustomValidators && originalValidators && originalValidators.length ? [...originalValidators, ...fieldValidators] : fieldValidators;

      let defaultValue;
      switch(field.type) {
      case 'checkbox':
        defaultValue = !!field.value || false;
        break;
      case 'file':
        defaultValue = '';
        break;
      default:
        defaultValue = field.value || '';
      }
      this.form.addControl(field.name, this.formBuilder.control({value: defaultValue, disabled: field.disabled}, allValidators && allValidators[0] ? allValidators : []));
      if(field.dependants) {
        field.dependants.forEach((dependant: FormField[] ) => {
          dependant.forEach((dependantField: FormField) => {
            this.form.addControl(dependantField.name, this.formBuilder.control(dependantField.value || '', [Validators.required]));
          });
        });
      }
    });

    this.formLoaded = true;
    if(this.config[this.formName].fields.find((field: FormField) => field.value)) {
      // Focus on first field if it has a value
      this.focusBlur();
    }

    if(this.config[this.formName].errors) {
      this.submitted = true;
    }

    setTimeout(() => {
      this.checkSameRow();
    });
  }

  submitForm(save = true, emit = true) {
    this.submitted = true;
    if(this.getFormErrors().length < 1) {
      this.submitted = false;
      this.config[this.formName].submitted = true;
      emit && this.submit.emit({form: this.form, byPass: this.formName === VisaFormName.DOCUMENT});
      save && this.saveForm();
    } else if(!this.form.valid && document.querySelector('.input_error')) {
      document.querySelector('.input_error').scrollIntoView({behavior: 'smooth'});
      setTimeout(() => {
        (document.querySelector('.scroll_wrapper') as any).scrollBy(0, -24, 'smooth');
        (document.querySelector('.input_error') as any)?.focus();
      }, 500);
    }
  }

  saveForm(getErrors = true) {
    this.config[this.formName].fields?.forEach((field: FormField) => {
      if(field.type !== 'file') {
        this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).value = this.form.get(field.name).value;
      }
      //if field has dependants
      if(this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).dependants) {
        this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).dependants.forEach((dependant: FormField[]) => {
          dependant.forEach((dependantField: FormField) => {
            dependantField.value = this.form.get(dependantField.name).value;
            dependantField.validators = ['required'];
          });
        });
      }
    });
    if(getErrors) this.config[this.formName].errors = this.getFormErrors();
    if(this.cart.currentApplication?.requests.length > 0) {
      const i = this.cart.currentApplication.requests.findIndex(request => request.person.id === this.personId);
      this.cart.currentApplication.requests[i].formConfig = this.config;
      this.cart.saveCurrentApplication(false);
    }
  }

  checkIfFormControl(field: FormField, _index?: number) {
    if(field.ifFormControl) {
      return this.form.controls[field.ifFormControl]?.value === true || this.form.controls[field.ifFormControl]?.value === 'true';
    } else if(field.ifFormControlHasNotValue) {
      return this.form.controls[field.ifFormControlHasNotValue.control]?.value && this.form.controls[field.ifFormControlHasNotValue.control]?.value !== field.ifFormControlHasNotValue.value;
    } else if(field.ifFormControlHasValue) {
      return field.ifFormControlHasValue.value ? (this.form.controls[field.ifFormControlHasValue.control]?.value && this.form.controls[field.ifFormControlHasValue.control]?.value === field.ifFormControlHasValue.value) : !!this.form.controls[field.ifFormControlHasValue.control]?.value;
    } else {
      return true;
    }
  }

  addDependant(field: FormField, index: number) {
    if(!field.dependants) return;
    //push new dependant
    const dependant: FormField[] = [
      {name: 'dependants_name_'+(index+1), type: 'text', ifFormControl: VisaFN.DEPENDANTS, validators: [Validators.required]},
      {name: 'dependants_passport_number_'+(index+1), type: 'text', ifFormControl: VisaFN.DEPENDANTS, validators: [Validators.required]},
      {name: 'dependants_date_of_birth_'+(index+1), type: 'date', ifFormControl: VisaFN.DEPENDANTS, validators: [Validators.required], dateValidators: {maxDate: format(endOfDay(new Date()), 'yyyy-MM-dd')}},
      {name: 'dependants_place_of_birth_'+(index+1), type: 'text', ifFormControl: VisaFN.DEPENDANTS, validators: [Validators.required]}
    ];
    dependant.forEach((dependantField: FormField) => {
      this.form.addControl(dependantField.name, this.formBuilder.control('', [Validators.required]));
    });
    field.dependants.push(dependant);
  }

  removeDependant(field: FormField, index: number) {
    if(!field.dependants) return;
    if(index === 0 && field.dependants.length === 1) {
      this.form.controls[field.name]?.setValue(false);
      this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).value = false;
    } else {
      this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).dependants[index].forEach((dependantField: FormField) => {
        this.form.removeControl(dependantField.name);
      } );
      this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).dependants.splice(index, 1);
    }
  }

  getFormErrors(): FormError[] {
    if(this.formName === VisaFormName.DEPENDANTS) {
      const errors: FormError[] = this.forms.getFormErrors(this.form);
      errors.forEach((error: FormError) => {
        error.key = error.key.replace(/_\d/g, '');
      });
      return errors;
    } else if(this.formName === VisaFormName.DOCUMENT) {
      //check if value is not empty in saved config
      const errorsFC: string[] = [];
      this.config[this.formName].fields.forEach((field: FormField) => {
        if(!field.value && field.validators?.includes('required')) {
          errorsFC.push(field.name);
        }
      });
      return this.forms.getFormErrors(this.form, errorsFC);
    } else {
      //check if field type date and if errors from date validators
      this.config[this.formName].fields.forEach((field: FormField) => {
        let errors = 0;
        if(field.type === 'date') {
          if(field.dateValidators) {
            if(field.dateValidators.mustBeEqualOrBefore) {
              const targetDate = this.form.get(field.dateValidators.mustBeEqualOrBefore.control)?.value ? startOfDay(new Date(this.form.get(field.dateValidators.mustBeEqualOrBefore.control)?.value)) : startOfDay(new Date(this.config[field.dateValidators.mustBeEqualOrBefore.form].fields.find((formField: FormField) => formField.name === field.dateValidators.mustBeEqualOrBefore.control).value));
              const inputDate = startOfDay(new Date(this.form.get(field.name).value));
              if(isAfter(inputDate, targetDate) && inputDate !== targetDate) {
                errors++;
                //add error mustBeEqualOrBefore
                this.form.get(field.name).setErrors({...this.form.get(field.name).errors, mustBeEqualOrBefore: true});
              }
            }
            if(field.dateValidators.mustBeEqualOrAfter) {
              const targetDate = this.form.get(field.dateValidators.mustBeEqualOrAfter.control)?.value ? startOfDay(new Date(this.form.get(field.dateValidators.mustBeEqualOrAfter.control)?.value)) : startOfDay(new Date(this.config[field.dateValidators.mustBeEqualOrAfter.form].fields.find((formField: FormField) => formField.name === field.dateValidators.mustBeEqualOrAfter.control).value));
              const inputDate = startOfDay(new Date(this.form.get(field.name).value));
              if(isAfter(targetDate, inputDate) && inputDate !== targetDate) {
                errors++;
                //add error mustBeEqualOrAfter
                this.form.get(field.name).setErrors({...this.form.get(field.name).errors, mustBeEqualOrAfter: true});
              }
            }
            if(field.dateValidators.minDate) {
              const inputDate = startOfDay(new Date(this.form.get(field.name).value));
              if(isAfter(startOfDay(new Date(field.dateValidators.minDate)), inputDate)) {
                errors++;
                //add error minDate
                this.form.get(field.name).setErrors({...this.form.get(field.name).errors, minDate: true});
              }
            }
            if(field.dateValidators.maxDate) {
              const inputDate = startOfDay(new Date(this.form.get(field.name).value));
              if(isAfter(inputDate, startOfDay(new Date(field.dateValidators.maxDate)))) {
                errors++;
                //add error maxDate
                this.form.get(field.name).setErrors({...this.form.get(field.name).errors, maxDate: true});
              }
            }
            if(field.dateValidators.maxDateMilliFromToday) {
              const inputDate = startOfDay(new Date(this.form.get(field.name).value));
              const milliseconds: number = field.dateValidators.maxDateMilliFromToday;
              //if difference between inputDate and today is less than milliseconds then error
              if((startOfDay(inputDate).getTime() - startOfDay(new Date()).getTime()) < milliseconds) {
                errors++;
                //add error maxDateMilliFromToday
                this.form.get(field.name).setErrors({...this.form.get(field.name).errors, maxDateMilliFromToday: true});
              }
            }
            if(field.dateValidators.maxDateMilliFrom) {
              const inputDate = startOfDay(new Date(this.form.get(field.name).value));
              const milliseconds: number = field.dateValidators.maxDateMilliFrom.milliseconds;
              const targetDate = this.form.get(field.dateValidators.maxDateMilliFrom.control)?.value ? startOfDay(new Date(this.form.get(field.dateValidators.maxDateMilliFrom.control)?.value)) : startOfDay(new Date(this.config[field.dateValidators.maxDateMilliFrom.form].fields.find((formField: FormField) => formField.name === field.dateValidators.maxDateMilliFrom.control).value));
              //if difference between inputDate and targetDate is greater than milliseconds then error
              if((inputDate.getTime() - targetDate.getTime()) > milliseconds) {
                errors++;
                //add error maxDateMilliFrom
                this.form.get(field.name).setErrors({...this.form.get(field.name).errors, maxDateMilliFrom: true});
              }
            }
            if(field.dateValidators.maxDateMilliTo) {
              const inputDate = startOfDay(new Date(this.form.get(field.name).value));
              const milliseconds: number = field.dateValidators.maxDateMilliTo.milliseconds;
              const targetDate = this.form.get(field.dateValidators.maxDateMilliTo.control)?.value ? startOfDay(new Date(this.form.get(field.dateValidators.maxDateMilliTo.control)?.value)) : startOfDay(new Date(this.config[field.dateValidators.maxDateMilliTo.form].fields.find((formField: FormField) => formField.name === field.dateValidators.maxDateMilliTo.control).value));
              //if difference between inputDate and targetDate is greater than milliseconds then error
              if(inputDate.getTime() > targetDate.getTime() ? (inputDate.getTime() - targetDate.getTime()) < milliseconds : (targetDate.getTime() - inputDate.getTime()) < milliseconds) {
                errors++;
                //add error maxDateMilliFrom
                this.form.get(field.name).setErrors({...this.form.get(field.name).errors, maxDateMilliTo: true});
              }
            }
          }
        }
        if(field.mustBeDifferentThan) {
          if(field.mustBeDifferentThan.array) {
            const inputValue = this.form.get(field.name).value;
            const array = field.mustBeDifferentThan.array;
            if(array.includes(inputValue)) {
              errors++;
              //add error mustBeDifferentThan
              this.form.get(field.name).setErrors({...this.form.get(field.name).errors, mustBeDifferentThan: true});
            }
          }
        }
        if(field.maxLength) {
          if(this.form.get(field.name).value?.length > field.maxLength) {
            errors++;
            //add error maxLength
            this.form.get(field.name).setErrors({...this.form.get(field.name).errors, maxlength: true});
          }
        }
        if(field.ifFormControl) {
          if(this.form.controls[field.ifFormControl]?.value === true || this.form.controls[field.ifFormControl]?.value === 'true') {
            if(field?.requiredIfFormControl && !this.form.get(field.name).value) {
              errors++;
              this.form.get(field.name).setErrors({...this.form.get(field.name).errors, required: true});
            }
          } else {
            this.form.get(field.name).setErrors(null);
            this.form.get(field.name).updateValueAndValidity();
          }
        }
        if(field.ifFormControlHasNotValue) {
          if(this.form.controls[field.ifFormControlHasNotValue.control]?.value && this.form.controls[field.ifFormControlHasNotValue.control]?.value !== field.ifFormControlHasNotValue.value) {
            if(field?.requiredIfFormControl && !this.form.get(field.name).value) {
              errors++;
              this.form.get(field.name).setErrors({...this.form.get(field.name).errors, required: true});
            }
          } else {
            this.form.get(field.name).setErrors(null);
            this.form.get(field.name).updateValueAndValidity();
          }
        }
        if(field.ifFormControlHasValue) {
          if(this.form.controls[field.ifFormControlHasValue.control]?.value && this.form.controls[field.ifFormControlHasValue.control]?.value === field.ifFormControlHasValue.value) {
            if(field?.requiredIfFormControl && !this.form.get(field.name).value) {
              errors++;
              this.form.get(field.name).setErrors({...this.form.get(field.name).errors, required: true});
            }
          } else {
            this.form.get(field.name).setErrors(null);
            this.form.get(field.name).updateValueAndValidity();
          }
        }
        if(this.cart.currentApplication.isRevision && this.config[this.formName].errors.find((error: FormError) => error.key === field.name)) {
          if(!this.form.controls[field.name].value) {
            errors++;
            //this.config[this.formName].errors = this.config[this.formName].errors.filter((error: FormError) => error.key !== field.name);
            this.form.get(field.name).setErrors({...this.form.get(field.name).errors, required: true});
            this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).validators = ['required'];
          }
        }
        //if no errors remove errors
        if(errors === 0 || field.disabled) {
          this.form.get(field.name).setErrors(null);
          this.form.get(field.name).updateValueAndValidity();
        }
      });
      this.form.updateValueAndValidity();
      return this.forms.getFormErrors(this.form);
    }
  }

  private focusBlur() {
    setTimeout(() => {
      document.querySelector('input')?.focus();
      document.querySelector('input')?.blur();
    });
  }

  removeUnderscoreNumber(name: string) {
    return name.replace(/_\d/g, '');
  }

  outputInput($event: any, field: FormField) {
    if($event[0] instanceof File || $event?.files?.[0] instanceof File) {
      const file: File = $event[0] || $event.files[0];
      //if type of file is pdf and size is less than 0.5MB
      if(file.type === 'application/pdf') {
        if((file.size / 1024 / 1024) <= 0.5) {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).fileName = file.name;
            this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).value = event.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.loader.loading(true, {type: 'error', message: this.lang.transform('err.file.size') + ' 1MB'});
        }
      } else {
        this.loader.loading(true);
        compressFile(file, 0.5).then((b64: string) => {
          this.loader.loading(false);
          this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).fileName = file.name;
          this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).value = b64;
        }).catch((err: any) => {
          this.loader.loading(true, {type: 'error', message: err});
        });
      }
    }
  }

  fieldRequired(field: FormField) {
    return this.form.get(field.name).errors?.required;
  }

  resetFileField(field: FormField) {
    this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).value = '';
    this.config[this.formName].fields.find((formField: FormField) => formField.name === field.name).fileName = '';
  }

  visualFile(event: any) {
    this.config[this.formName].fields;
    this.loader.loading(true, {
      hideHeader: true,
      fullPage: false,
      custom: {
        innerHtml: `<div class="custom-popup">
          <img src="${event}" alt="popup image" />
        </div>`
      }
    });
  }

  doAction(action: FormAction, event?: any) {
    if(action === FormAction.SUBMIT) {
      this.submitForm(false, false);
      this.saveForm();
    } else if(action === FormAction.FOCUS_BLUR) {
      this.focusBlur();
    } else if(action === FormAction.SAVE) {
      this.saveForm(false);
    } else if(action === FormAction.UPLOAD_FILE) {
      if(event?.error?.message === 'err.fileSize') {
        this.loader.loading(true, {type: 'error', message: this.lang.transform('err.file.size') + ' 1MB'});
      }
    }
  }

  checkSameRow() {
    let sameRowFieldsNames: string[] = [];
    sameRowFieldsNames = this.config[this.formName].fields.filter((formField: FormField) => formField.sameRow).map((formField: FormField) => formField.name);
    if(sameRowFieldsNames.length < 2) return;
    const div1 = document.createElement('div');
    const div2 = document.createElement('div');
    div1.classList.add('f_col', 'fullWidth');
    div2.classList.add('flex', 'gap_10', 'fullWidth', 'mt_24');
    const elements: any[] = [];
    document.getElementById('wrap_'+sameRowFieldsNames[0])?.parentNode.insertBefore(div1, document.getElementById('wrap_'+sameRowFieldsNames[0]));
    sameRowFieldsNames.forEach((name: string) => {
      if(document.getElementById('wrap_'+name)) {
        elements.push(document.getElementById('wrap_'+name));
        document.getElementById('wrap_'+name).remove();
      }
    });
    elements.forEach((element: any) => {
      div2.appendChild(element);
    });
    const p = document.createElement('div');
    p.innerHTML = this.lang.transform(this.config[this.formName].fields.find((formField: FormField) => formField.name === sameRowFieldsNames[0]).sameRow + '_desc');
    div1.appendChild(p);
    div1.appendChild(div2);
  }

  getFileSize(fileSize: number): string {
    return fileSize/1000 >= 1000 ? (fileSize / 1000 / 1000) + 'Mb' : fileSize/1000 + 'Kb';
  }
}
