export enum Store {
  CART_STORE = 'cart',
  SYNC_STORE = 'sync',
  REVISION_STORE = 'revision',
  EXTEND_STORE = 'extend',
  QUEUE_STORE = 'queue',
  PAYMENT_STORE = 'payment',
  PLACE_STORE = 'place',
  PRINT_STORE = 'print',
}
