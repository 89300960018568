import {Component, Input, NgZone, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {DevicesService, LangService, NavigateService, ShortcutAction} from 'ngx-satoris';
import {DocumentType, UserPermission, UserPlatformRole} from '../../shared/models/user';
import {ApiService} from '../../shared/services/api.service';
import {CartService} from '../../shared/services/cart.service';
import {ScanDocumentService} from 'src/app/shared/services/scan-document.service';
import {enumStringToArrayObject} from 'src/app/shared/utils/enumToArrayObject';
import {ConsumeMode} from 'src/app/shared/models/request';
import {QueueService} from '../../shared/services/queue.service';
declare const window: any;
const permissions = window.cordova?.plugins?.permissions;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavComponent {

  @Input() data: {label: string, route?: string, perm?: UserPermission, guard?: boolean, tip?: string, action?: () => void}[] = [
    {
      label: 'dashboard',
      route: '/dashboard',
      guard: true,
      tip: 'tooltip.dashboard'
    },
    {
      label: 'menu.createRequests.DYN.'+this.api.env.type,
      guard: this.api.userRole?.isCustomer,
      action: () => {
        this.cart.startApplication();
      },
      tip: 'tooltip.createRequests'
    },
    {
      label: 'trips.title',
      route: '/trips',
      guard: this.api.userRole?.isCustomer && this.api.env.type === DocumentType.ZWEVISA,
      tip: 'tooltip.trips'
    },
    {
      label: 'menu.tracking',
      route: '/tracking-control',
      guard: this.api.userRole?.isCustomer && this.api.env.type === DocumentType.PASS,
      tip: 'tooltip.tracking'
    },
    {
      label: 'menu.people.DYN.' + this.api.env.type,
      route: '/people',
      guard: this.api.userRole?.isCustomer,
      tip: 'tooltip.people'
    },
    {
      label: 'menu.users',
      route: '/users',
      perm: this.api.perm.ALLOW_ADMIN,
      guard: !this.api.userRole?.isCustomer,
      tip: 'tooltip.users'
    },
    {
      label: 'menu.requests.DYN.'+this.api.env.type,
      route: '/admin-request',
      perm: this.api.perm.ALLOW_CREATE,
      guard: !this.api.userRole?.isCustomer,
      tip: 'tooltip.searchManually'
    },
    {
      label: 'menu.places',
      route: '/places',
      perm: this.api.perm.ALLOW_ADMIN,
      guard: !this.api.userRole?.isCustomer,
      tip: 'tooltip.places'
    },
    {
      label: 'dashboard.my_account',
      route: '/my-account',
      guard: true,
      tip: 'tooltip.my_account'
    }
  ];

  menuConsumeMode: {name: string, value: string | null}[] = [];
  userRoles = UserPlatformRole;
  UserPermission = UserPermission;
  progressUploadValues: number[] = [];
  progressDownloadValues: number[] = [];

  constructor(public nav: NavigateService,
              public router: Router,
              public api: ApiService,
              public scanDocument: ScanDocumentService,
              public cart: CartService,
              public queue: QueueService,
              private deviceService: DevicesService,
              private zone: NgZone,
              private lang: LangService) {
    this.api.progressUploadMap$.subscribe(() => {
      this.progressUploadValues = this.api.getProgressValues(true, 1);
    });
    this.api.progressDownloadMap$.subscribe(() => {
      this.progressDownloadValues = this.api.getProgressValues(false, 1);
    });

    this.menuConsumeMode = enumStringToArrayObject(ConsumeMode).map((item) => ({
      ...item,
      name: this.lang.transform(item.name.toLowerCase() + '.consume')
    }));
  }

  clickScan() {
    if(this.deviceService.isDevices('cordova')) {
      permissions.checkPermission(permissions.CAMERA, (status: any) =>{
        if(status.hasPermission) {
          this.zone.run(() => {
            this.nav.to('scan-qr');
          });
        } else {
          this.zone.run(() => {
            this.nav.to('page-permission');
          });
        }
      });
    } else {
      this.nav.to('scan-qr');
    }
  }

  scanPassport() {
    this.nav.to('dashboard');
    this.scanDocument.scanPassport();
  }

  protected readonly DocumentType = DocumentType;
  protected readonly ShortcutAction = ShortcutAction;
}
