import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {SyncService} from '../../shared/services/sync.service';
import {PrintNoticeRestrictionData} from '../../shared/models/print';
import {AbstractPrintComponent} from '../abstract-print/abstract-print.component';

@Component({
  selector: 'app-print-notice-restriction',
  templateUrl: './print-notice-restriction.component.html',
  styleUrl: './print-notice-restriction.component.scss'
})
export class PrintNoticeRestrictionComponent extends AbstractPrintComponent {
  @Input() data: PrintNoticeRestrictionData;
  @Output() init: EventEmitter<any> = new EventEmitter<any>();

  constructor(public sync: SyncService, elRef: ElementRef) {
    super(elRef);
  }
}
