import {
  DataGroup,
  NoticeType,
  PrintBorderpassData,
  PrintReceiptData,
  PrintStickerData,
  RequestWithDuplicata
} from './request';
import {FormGroup} from '@angular/forms';

export enum DocsPrintType {
  APPROVAL_LETTER = 'APPROVAL_LETTER',
  RECEIPT = 'RECEIPT',
  STICKER = 'STICKER',
  BORDER_PASS = 'BORDER_PASS',
  APPLICATION = 'APPLICATION',
}

export type AllDocumentsPrintType = DocsPrintType | NoticeType;

export enum PaperSize {
  A4 = 'A4',
  A3 = 'A3',
  Letter = 'Letter',
  Thermal = 'Thermal',
}

export interface PrintSettingsDetails {
  defaultPrinter: string;
  colorMode: boolean;
  paperSize: PaperSize;
}

export interface PrintSettings {
  id: string; //ID used for storage
  [DocsPrintType.APPLICATION]?: PrintSettingsDetails;
  [DocsPrintType.APPROVAL_LETTER]?: PrintSettingsDetails;
  [DocsPrintType.RECEIPT]?: PrintSettingsDetails;
  [DocsPrintType.STICKER]?: PrintSettingsDetails;
  [DocsPrintType.BORDER_PASS]?: PrintSettingsDetails;
  [NoticeType.RESTRICTION]?: PrintSettingsDetails;
  [NoticeType.REPORTING]?: PrintSettingsDetails;
  [NoticeType.REFUSAL]?: PrintSettingsDetails;
}

export interface PrintApplicationData {
  title: string;
  dataList: DataGroup[]
}

export interface PrintNoticeData {
  form: FormGroup;
  qrCode: string;
}
export interface PrintNoticeRestrictionData extends PrintNoticeData {
  documents: string[];
}

export const PrintableDocuments: {name: AllDocumentsPrintType, icon: string, active: boolean, data: any}[] = [
  {name: DocsPrintType.APPLICATION, icon: 'file', active: false, data: {} as PrintApplicationData},
  {name: DocsPrintType.APPROVAL_LETTER, icon: 'file', active: false, data: {} as RequestWithDuplicata},
  {name: DocsPrintType.RECEIPT, icon: 'receipt', active: false, data: {} as PrintReceiptData},
  {name: DocsPrintType.STICKER, icon: 'sticky-note', active: false, data: {} as PrintStickerData},
  {name: DocsPrintType.BORDER_PASS, icon: 'passport', active: false, data: {} as PrintBorderpassData},
  {name: NoticeType.RESTRICTION, icon: 'file', active: false, data: {} as PrintNoticeRestrictionData},
  {name: NoticeType.REPORTING, icon: 'file', active: false, data: {} as PrintNoticeData},
  {name: NoticeType.REFUSAL, icon: 'file', active: false, data: {} as PrintNoticeData}
];

