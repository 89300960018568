<div id="layout" class="request_account f_col" *ngIf="currentRequest">
  <app-view-title fromRoute="pre-enroll" [chooseLangBtn]="false">
    {{((request.isExtension(currentRequest) && currentRequest.operationId) || ((currentRequest.metadata.firstName || currentRequest.serialized.FirstName) || (currentRequest.metadata.lastName || currentRequest.serialized.LastName))) ? (request.isExtension(currentRequest) ? (currentRequest.operationId | translate) : (currentRequest.metadata.firstName || currentRequest.serialized.FirstName) + ' ' + (currentRequest.metadata.lastName || currentRequest.serialized.LastName)) : ''}}
  </app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
      <div class="flex f_col">
        <div class="mt_10 mb_10">
          <app-snackbar type="success" class="f_center" [icon]="request.getHighestSeverityTheme(undefined, currentRequest).icon" [customColorIcon]="request.getHighestSeverityTheme(undefined, currentRequest).iconColor">
            {{request.getHighestSeverityTheme(undefined, currentRequest).state}}
          </app-snackbar>
        </div>

        <app-paragraph textAlign="center"
                       *ngIf="currentRequest.usageAfter && !currentRequest.usageUntil && !request.isSubjectToUntil(currentRequest) && (currentRequest.state === RequestState.PAID || currentRequest.state === RequestState.DELAY_PAY || currentRequest.state === RequestState.USED)"
                       class="fw_bold mb_10 fs-h6"
                       [matTooltip]="api.isZwevisa ? ('tooltip.trackingUsageAfter' | translate) : ''">
          {{'afterWithoutUntilAndMillis' | translate : {dateAfter: currentRequest.usageAfter | date: 'dd/MM/yyyy'} }}
        </app-paragraph>

        <app-paragraph textAlign="center"
                       *ngIf="currentRequest.usageAfter && !currentRequest.usageUntil && request.isSubjectToUntil(currentRequest) && (currentRequest.state === RequestState.PAID || currentRequest.state === RequestState.DELAY_PAY || currentRequest.state === RequestState.USED)"
                       class="fw_bold mb_10 fs-h6">
          {{'afterWithoutUntil' | translate : {
          dateAfter: currentRequest.usageAfter | date: 'dd/MM/yyyy',
          deadlineDate: calculateDeadlineDate(currentRequest.usageAfter, request.isSubjectToUntil(currentRequest)) | date: 'dd/MM/yyyy' }
          }}
        </app-paragraph>

        <app-paragraph textAlign="center"
                       *ngIf="currentRequest.usageAfter && currentRequest.usageUntil && (currentRequest.state === RequestState.PAID || currentRequest.state === RequestState.DELAY_PAY || currentRequest.state === RequestState.USED)"
                       class="fw_bold mb_10 fs-h6">
          {{'afterAndUntil' | translate : {
          dateAfter: currentRequest.usageAfter | date: 'dd/MM/yyyy',
          dateUntil: currentRequest.usageUntil | date: 'dd/MM/yyyy' }
          }}
        </app-paragraph>

        <app-paragraph textAlign="center"
                       *ngIf="currentRequest.usageAfter && (currentRequest.state === RequestState.EXPIRED || currentRequest.state === RequestState.PENDING)"
                       class="fw_bold mb_10 fs-h6">
          {{'afterRefused' | translate : {dateAfter: currentRequest.usageAfter | date: 'dd/MM/yyyy'} }}
        </app-paragraph>

        <div class="mb_10" *ngIf="showWarning(currentRequest)">
          <div class="warning-container">
            <div class="flex">
              <fa-icon class="mr_20" size="xl" [icon]="'triangle-exclamation'"></fa-icon>
              <app-paragraph class="refusal-desc" [innerhtml]="'summary.warning_validity' | translate"></app-paragraph>
            </div>
          </div>
        </div>

        <ng-container *ngIf="currentRequest.state === RequestState.PAID || currentRequest.state === RequestState.DELAY_PAY">
          <div class="descRequest-container descRequest-container-VALID">
            <fa-icon size="xl" icon="info"></fa-icon>
            <div class="f_col">
              <app-paragraph class="ml_10 request-text">{{'qrDesc.valid' | translate}}</app-paragraph>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentRequest.state === RequestState.EXPIRED || currentRequest.state === RequestState.PENDING">
          <div [class]="currentRequest.state + '-container mb_10'">
            <div class="f_col">
              <app-paragraph class="refusal-desc">{{RequestState[currentRequest.state] + '.globalExplain' | translate}}</app-paragraph>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentRequest.refusalReason">
          <div class="refusal-container">
            <fa-icon size="xl" icon="triangle-exclamation"></fa-icon>
            <div class="f_col">
              <app-paragraph *ngIf="currentRequest.state === RequestState.EXPIRED"
                             class="refusal-desc">{{('refusal.desc.DYN.' + api.env.type) | translate}}
              </app-paragraph>
              <app-paragraph class="ml_10 refusal-text">{{'"' + currentRequest.refusalReason + '"'}}</app-paragraph>
            </div>
          </div>
        </ng-container>

        <div class="mb_10">
          <div class="qrcode mt_20" *ngIf="currentRequest.state === RequestState.PAID || currentRequest.state === RequestState.DELAY_PAY || currentRequest.state === RequestState.USED">
            <qrcode *ngIf="currentRequest.qrCode" class="f_center" [qrdata]="currentRequest.qrCode" [width]="300" [errorCorrectionLevel]="'M'"
                    [allowEmptyString]="true"></qrcode>
            <app-paragraph class="mt_10" textAlign="center"><strong>{{'qr.request_desc' | translate}}</strong>
            </app-paragraph>
          </div>
        </div>

        <app-collapse class="mt_10" title="{{'all.infos' | translate}}" leftIcon="info-circle" leftIconColor="var(--clr-primary-lt)" [expanded]="currentRequest.state !== RequestState.PAID && currentRequest.state !== RequestState.DELAY_PAY && currentRequest.state !== RequestState.USED"
                      [matTooltip]="api.isZwevisa ? ('tooltip.allTrackingInfo' | translate) : ''">
          <app-snackbar type="info" class="f_center mb_10" [icon]="request.getStatusTheme(currentRequest).icon" [customColorIcon]="request.getStatusTheme(currentRequest).iconColor">
            {{request.getStatusTheme(currentRequest).state}}
          </app-snackbar>
          <div class="grid-container">
            <ng-container *ngFor="let item of [
              {condition: currentRequest?.batchId && currentRequest?.state !== RequestState.PROPOSED, label: 'trip.name', value: currentRequest.batchId},
              {condition: currentRequest?.createdAt, label: 'createdAt', value: currentRequest.createdAt | date: 'dd/MM/yyyy'},
              {condition: currentRequest?.operationId, label: 'document.type.DYN.' + api.env.type, value: (currentRequest.operationId) | translate},
              {condition: currentRequest?.metadata?.passportNumber, label: 'PassportNumber', value: currentRequest?.metadata?.passportNumber},
              {condition: true, label: 'form.amount', value: getAmount(currentRequest?.currency, currentRequest?.amountCents)},
              {condition: currentRequest?.amountCents, label: 'form.paymentType', value: (currentRequest?.externalId ? 'payment.type.online' : 'payment.type.offline') | translate},
              {condition: currentRequest?.usageAfter, label: 'visaValidFrom', value: currentRequest.usageAfter | date: 'dd/MM/yyyy'},
              {condition: true, label: 'visaValidUntil', value: (!currentRequest.usageUntil && request.isSubjectToUntil(currentRequest) ? request.isSubjectToUntil(currentRequest) : currentRequest?.usageUntil) | date: 'dd/MM/yyyy'},
              {condition: currentRequest.localUsageUntil && currentRequest.state === RequestState.USED, label: 'form.localUsageUntil', value: currentRequest.localUsageUntil | date: 'dd/MM/yyyy'},
              {condition: currentRequest?.id, label: 'application.id', value: currentRequest.id},
              {condition: currentRequest?.externalId && currentRequest?.state !== RequestState.PROPOSED, label: 'form.externalId', value: currentRequest.externalId}
            ]">
              <div *ngIf="item.condition" class="f_col gap_5 mb_5">
                <span class="ml_10">{{item.label | translate}}</span>
                <app-slide-element [noPointerEvent]="true" [noWrap]="true" rightIcon="none">{{item.value}}</app-slide-element>
              </div>
            </ng-container>
          </div>
        </app-collapse>

        <ng-container *ngIf="currentRequest.extensions?.length">
          <app-collapse *ngFor="let ext of currentRequest.extensions"
                        class="mt_10"
                        title="{{'extension' | translate}} - {{request.getStatusTheme(ext)?.state + ' - ' + ((getAmount(ext?.currency, ext?.amountCents, true, false) === 0 ? 'free' : '') | translate)}} ({{ext.createdAt | date: 'dd/MM/yyyy'}})"
                        [leftIcon]="request.getStatusTheme(ext)?.icon"
                        [leftIconColor]="request.getStatusTheme(ext)?.iconColor"
                        [class.awaiting_bg]="ext.state === RequestState.PENDING"
                        [expanded]="false"
                        [matTooltip]="'tooltip.linkedExtensions' | translate">
            <app-snackbar type="success" class="f_center mb_10"
                          [icon]="request.getStatusTheme(ext).icon" [customColorIcon]="request.getStatusTheme(ext).iconColor">
              {{request.getStatusTheme(ext).state + ' - ' + ((getAmount(ext?.currency, ext?.amountCents, true, false) === 0 ? 'free' : '') | translate)}}
            </app-snackbar>
            <ng-container *ngIf="ext.state === RequestState.EXPIRED || ext.state === RequestState.PENDING">
              <div [class]="ext.state + '-container mb_10'">
                <div class="f_col">
                  <app-paragraph class="refusal-desc">{{RequestState[ext.state] + '.globalExplain' | translate}}</app-paragraph>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="ext.refusalReason">
              <div class="refusal-container">
                <fa-icon size="xl" icon="triangle-exclamation"></fa-icon>
                <div class="f_col">
                  <app-paragraph *ngIf="ext.state === RequestState.EXPIRED"
                                 class="refusal-desc">{{('refusal.desc.DYN.' + api.env.type) | translate}}
                  </app-paragraph>
                  <app-paragraph class="ml_10 refusal-text">{{'"' + ext.refusalReason + '"'}}</app-paragraph>
                </div>
              </div>
            </ng-container>

            <div class="grid-container">
              <ng-container *ngFor="let item of [
                {condition: ext?.id, label: 'form.id', value: ext.id},
                {condition: ext?.createdAt, label: 'createdAt', value: ext.createdAt | date: 'dd/MM/yyyy'},
                {condition: ext?.operationId, label: 'document.type.DYN.' + api.env.type, value: (ext.operationId) | translate},
                {condition: ext?.metadata?.passportNumber, label: 'PassportNumber', value: ext?.metadata?.passportNumber},
                {condition: true, label: 'form.amount', value: getAmount(ext?.currency, ext?.amountCents)},
                {condition: ext?.amountCents, label: 'form.paymentType', value: (ext?.externalId ? 'payment.type.online' : 'payment.type.offline') | translate},
                {condition: ext?.usageAfter, label: 'form.usageAfter', value: ext.usageAfter | date: 'dd/MM/yyyy'},
                {condition: true, label: 'form.usageUntil', value: (!ext.usageUntil && request.isSubjectToUntil(currentRequest) ? request.isSubjectToUntil(currentRequest) : ext?.usageUntil) | date: 'dd/MM/yyyy'},
                {condition: ext?.serialized.DaysRequested, label: 'DaysRequested', value: ext.serialized.DaysRequested}
              ]">
                <div *ngIf="item.condition" class="f_col gap_5 mb_5">
                  <span class="ml_10">{{item.label | translate}}</span>
                  <app-slide-element [noPointerEvent]="true" [noWrap]="true" rightIcon="none">{{item.value}}</app-slide-element>
                </div>
              </ng-container>
            </div>
            <div class="fullWidth mt_10" *ngIf="ext?.state === RequestState.PENDING">
              <app-button size="xsm" class="resp_minSize_l fullWidth" (btnClicked)="startProcess(false, ext)">{{'start_correction' | translate}}</app-button>
            </div>
          </app-collapse>
        </ng-container>
      </div>
      <div class="mt_20 fullWidth resp_minSize_l">
        <div *ngIf="currentRequest.state === RequestState.PAID || currentRequest.state === RequestState.DELAY_PAY || currentRequest.state === RequestState.USED" [matTooltip]="api.isZwevisa ? ('tooltip.trackingPrint' | translate) : ''">
          <app-button size="xsm" class="fullWidth" (btnClicked)="printApprovalLetter()">{{'printApprovalLetter' | translate}} <fa-icon class="ml_5" icon="envelope-open-text"></fa-icon></app-button>
        </div>
      </div>
      <div class="mt_10 fullWidth flex f_align_c gap_10">
        <app-button size="xsm" color="transparent" border="true" formId="form" class="resp_minSize_l fullWidth" (btnClicked)="request.getBatch(currentRequest.batchId).requests.length > 1 ? nav.to('tracking-control/', undefined, {queryParams:{batchId: currentRequest.batchId}}) : nav.to('trips')">{{'global.back' | translate}}</app-button>
        <div class="print-contain" *ngIf="currentRequest.state === RequestState.PENDING">
          <app-button size="xsm"  formId="form" class="resp_minSize_l fullWidth" (btnClicked)="startProcess(false)">{{'start_correction' | translate}}</app-button>
        </div>
        <div class="print-contain" *ngIf="currentRequest.state === RequestState.USED && request.isSubjectToUntil(currentRequest) && request.hasNoAwaitingExtension(currentRequest) && request.getStatusTheme(currentRequest).actionState === ActionState.READY_CHECKOUT">
          <app-button size="xsm"  formId="form" class="resp_minSize_l fullWidth" (btnClicked)="request.startExtend(currentRequest)">{{'start_extend' | translate}}</app-button>
        </div>
        <!--
        <div class="print-contain" *ngIf="currentRequest.state === RequestState.PROPOSED && currentRequest.subState === RequestSubState.NONE">
          <app-button size="xsm" class="resp_minSize_l fullWidth" [debounceTimeInMilliseconds]="5000" (click)="startProcess(true)">{{'start_continue' | translate}}</app-button>
        </div>
        -->
      </div>
    </div>
  </div>
  <div class="flex gap_10 f_align_end mb_20 resp_maxSize_l">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <app-button color="transparent" border="true" formId="form" class="fullWidth"
                (btnClicked)="request.getBatch(currentRequest.batchId).requests.length > 1 ? nav.to('tracking-control/', undefined, {queryParams:{batchId: currentRequest.batchId}}) : nav.to('trips')">{{ 'global.back' | translate }}
    </app-button>
    <app-back-btn *ngIf="currentRequest.state === RequestState.PAID || currentRequest.state === RequestState.DELAY_PAY || currentRequest.state === RequestState.USED" icon="envelope-open-text" (btnClicked)="printApprovalLetter()"></app-back-btn>
    <app-back-btn *ngIf="currentRequest.state === RequestState.USED && request.isSubjectToUntil(currentRequest) && request.hasNoAwaitingExtension(currentRequest) && request.getStatusTheme(currentRequest).actionState === ActionState.READY_CHECKOUT" icon="calendar-plus" (btnClicked)="request.startExtend(currentRequest)"></app-back-btn>
    <app-back-btn *ngIf="currentRequest.state === RequestState.PENDING" icon="pen-to-square" (btnClicked)="startProcess(false)"></app-back-btn>
    <app-back-btn *ngIf="currentRequest.state === RequestState.PROPOSED && currentRequest.subState === RequestSubState.NONE" [debounceTimeInMilliseconds]="5000" icon="play" (btnClicked)="startProcess(true)"></app-back-btn>
  </div>
</div>
