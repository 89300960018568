<div id="layout" class="sign_in f_col">
  <app-view-title class="mb_30" fromRoute="pre-enroll" [chooseLangBtn]="false">{{'user.new' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper resp_row">
    <div class="resp_l-50 resp_scroll-wrapper_l">
      <app-view-deco-image imageUrl="assets-projects/images/image_new_person.svg"></app-view-deco-image>
    </div>
    <div class="resp_r-50 resp_scroll-wrapper_l">
      <form id="form" [formGroup]="form" class="pt_20" [class.mb_30]="submitted">
        <app-input class="mb_30" icon="at" type="email" formCtrlName="email" [formGrp]="form" label="{{'form.email' | translate}}"></app-input>
        <app-input class="mb_30" icon="user" type="text" formCtrlName="name" [formGrp]="form" label="{{'form.name' | translate}}"></app-input>
        <app-input class="mb_30" icon="key" type="password" formCtrlName="password" [formGrp]="form" label="{{'form.password' | translate}}"></app-input>
        <app-input class="mb_30" icon="key" type="password" formCtrlName="password2" [formGrp]="form" label="{{'form.password2' | translate}}"></app-input>
        <app-input class="mb_30" icon="briefcase" type="select" formCtrlName="role" [formGrp]="form" label="{{'form.role' | translate}}" [selectOptions]="roles"></app-input>
        <app-input class="mb_30" icon="hashtag" type="text" formCtrlName="id" [formGrp]="form" label="{{'form.userExternalId' | translate}}"></app-input>
        <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
      </form>
      <app-button formId="form" class="fullWidth resp_maxSize_l" (btnClicked)="createUser()">{{'user.create' | translate}}</app-button>
      <app-button size="sm" formId="form" class="fullWidth resp_minSize_l" (btnClicked)="createUser()">{{'user.create' | translate}}</app-button>
      <app-button size="sm" color="transparent" border="true" formId="form" class="fullWidth resp_minSize_l mt_4" (btnClicked)="goToPreviousUrl()">{{'global.back' | translate}}</app-button>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 resp_maxSize_l">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button color="transparent" border="true" formId="form" class="fullWidth" (btnClicked)="goToPreviousUrl()">{{'global.back' | translate}}</app-button>
    </div>
  </div>
</div>
