import {Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {QrService} from '../../shared/services/qr.service';
import {environment} from 'src/environments/environment';
import {DocumentType} from 'src/app/shared/models/user';
import {RequestState, RequestSubState, RequestWithDuplicata} from 'src/app/shared/models/request';
import {RequestService} from 'src/app/shared/services/request.service';
import {SyncService} from '../../shared/services/sync.service';
import {AbstractPrintComponent} from '../abstract-print/abstract-print.component';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrintComponent extends AbstractPrintComponent{
  @Input() data: RequestWithDuplicata;
  @Output() init: EventEmitter<any> = new EventEmitter<void>();

  isValid = false;
  constructor(public api: ApiService,
              public qr: QrService,
              public sync: SyncService,
              public request: RequestService,
              elRef: ElementRef) {
    super(elRef);
  }

  ngOnInit() {
    this.isValid = this.data && (this.data.state === RequestState.PAID || this.data.state === RequestState.USED || this.data.state === RequestState.DELAY_PAY);
  }

  getUsageCount(operationId: string) {
    return this.api.userInfo?.server.paymentRequests.find(r => r.id === operationId)?.usageCount;
  }

  protected readonly environment = environment;
  protected readonly DocumentType = DocumentType;
  protected readonly RequestSubState = RequestSubState;
}
