import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {PrintBorderpassData} from 'src/app/shared/models/request';
import {QrService} from 'src/app/shared/services/qr.service';
import {SyncService} from '../../shared/services/sync.service';
import {AbstractPrintComponent} from '../abstract-print/abstract-print.component';

@Component({
  selector: 'app-print-notice-border-pass',
  templateUrl: './print-notice-border-pass.component.html',
  styleUrl: './print-notice-border-pass.component.scss'
})
export class PrintNoticeBorderPassComponent extends AbstractPrintComponent {
  @Input() data: PrintBorderpassData;
  @Output() init: EventEmitter<any> = new EventEmitter<any>();
  constructor(public qr: QrService, public sync: SyncService, elRef: ElementRef) {
    super(elRef);
  }
}
