/* tslint:disable */
export const de_overrides_strings = {
  'summary.warning_validity_list': '<b>WICHTIGER HINWEIS:</b> Bitte beachten Sie, dass die Gültigkeit der elektronischen Reisegenehmigung für die folgende(n) Person(en) ab dem Ausstellungsdatum beginnt, <b><u>NICHT</u></b> am Datum der Einreise nach Simbabwe: <b>{names}</b>.<br><br>Die erlaubte Aufenthaltsdauer wird <b>von den Einwanderungsbehörden am Einreiseort bei der Ankunft bestimmt (in der Regel 30 Tage)</b>. Bitte planen Sie Ihre Reise entsprechend.',
  'summary.warning_validity': '<b>WICHTIGER HINWEIS:</b> Bitte beachten Sie, dass die Gültigkeit der elektronischen Reisegenehmigung für dieses Visum ab dem Ausstellungsdatum beginnt, <b><u>NICHT</u></b> am Datum der Einreise nach Simbabwe. <b>Die erlaubte Aufenthaltsdauer wird von den Einwanderungsbehörden am Einreiseort bei der Ankunft bestimmt (in der Regel 30 Tage)</b>. Bitte planen Sie Ihre Reise entsprechend.',
  'UsageUntil': 'Gültig bis',
  'updateField.desc': 'Bitte geben Sie die aktualisierten Informationen für das folgende Feld an: <b>{field}</b>',
  'global.checkOut.withoutCamera': 'Abmeldung ohne Kamera',
  'global.checkIn.withoutCamera': 'Anmeldung ohne Kamera',
  'tooltip.checkInWithoutCamera': 'Person ohne Verwendung der Kamera einchecken',
  'global.checkOut.withCamera': 'Abmeldung mit Kamera',
  'supervision.zweentry': 'Einreise, die die Genehmigung eines Aufsehers erfordert',
  'DYN.permanent-resident-permit': 'Daueraufenthaltserlaubnis',
  'DYN.permanent-resident-permit.desc': 'Eine Daueraufenthaltserlaubnis erlaubt einen unbefristeten Aufenthalt in Simbabwe. Sie wird in der Regel Personen ausgestellt, die sich über einen längeren Zeitraum im Land aufgehalten haben und die Zulassungskriterien erfüllen.',
  'changeFingerprint': 'Fingerabdruck ändern',
  'scan.supervisor.timeout': 'Möchten Sie den Supervisor-Scan überspringen?',
  'supervision.manualCatC': 'Manuelle Eingabe für ein Land der Kategorie C',
  'supervision.blacklist': 'Schwarz gelisteter Antragsteller',
  'supervision.specifiedNationals': 'Land, das eine Aufsicht erfordert',
  'ask.downgrade': 'Sind Sie sicher, dass Sie diesen Antrag auf ein Einfachvisum herabstufen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  'checkin': 'Einchecken',
  'checkout': 'Auschecken',
  'scan.addFingerprint.error': 'Beim Hinzufügen des Fingerabdrucks ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'scan.supervisor.wrongFingerprint': 'Der Fingerabdruck stimmt nicht mit dem im System gespeicherten Fingerabdruck des Supervisors überein. Bitte versuchen Sie es erneut.',
  'scan.now': 'Jetzt scannen',
  'scan.fingerprintSupervisor.desc': 'Wenn Sie bereit sind, legen Sie als Supervisor Ihren Finger auf den Scanner und klicken Sie auf <b>Jetzt scannen</b>, um die folgende Aktion fortzusetzen:</br><b>{actionText}</b>',
  'scan.supervisor.noFingerprint': 'Es wurden keine Fingerabdruckdaten für diesen Supervisor-Kontonamen gefunden',
  'scan.supervisor.noAccountName': 'Kein Supervisor-Kontoname angegeben',
  'supervisorAccountName': 'Supervisor-Kontoname',
  'scan.supervisor.desc': 'Bitte geben Sie den Kontonamen des Supervisors ein, um die folgende Aktion fortzusetzen:</br><b>{actionText}</b>',
  'addFingerprint': 'Fingerabdruck hinzufügen',
  'all.supervisions': 'Alle Aufsichtsbewertungen',
  'tooltip.allSupervisions': 'Eine Liste aller eingereichten Bewertungen zur Aufsicht des Antrags.',
  'refusalReason.doSupervisor.desc.DYN.zwevisa': 'Bitte geben Sie eine kurze Beschreibung des <b>ABLEHNUNGSGRUNDES</b> an. Diese Nachricht wird an den Grenzbeamten gesendet.',
  'approveOrRefuse': 'Genehmigen oder ablehnen',
  'request.do_supervisor_review': 'Überprüfung durch Supervisor',
  'request.do_supervisor_review.desc': 'Ein Supervisor kann den Antrag überprüfen, um sicherzustellen, dass die bereitgestellten Informationen korrekt sind und der Antrag den Vorschriften des Landes entspricht.',
  'request.askSupervisorReview': 'Ihre Überprüfung wurde erfolgreich eingereicht.',
  'refusalReason.reviewSupervisor.desc.DYN.zwevisa': 'Bitte geben Sie eine kurze Beschreibung der Überprüfung als <b>NICHT EMPFOHLEN</b> an. Die Überprüfung wird zur Genehmigung an den Supervisor gesendet.',
  'request.askSupervisorReview.error': 'Beim Überprüfen des Antrags ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'askSupervisorReview': 'Was ist Ihre Empfehlung? Ihre Überprüfung wird zur Genehmigung an den Supervisor gesendet.',
  'doSupervisorReview': 'Möchten Sie diesen Antrag genehmigen oder ablehnen? Basierend auf Ihrer Entscheidung kann der Grenzbeamte den Antragsteller einchecken oder nicht.',
  'request.ask_supervisor_review': 'Überprüfung an Supervisor senden',
  'request.supervisor_review': 'Sie können eine Überprüfung durch einen Supervisor für diesen Antrag anfordern. Der Supervisor wird den Antrag überprüfen, und Sie können dann den Antragsprozess fortsetzen.',
  'place.countriesSelected': 'Wählen Sie die Länder aus, die eine beaufsichtigte Einreise erfordern',
  'visa.catC.ordinary': 'Dieser Antrag gilt für die Einreise ohne Visum für Personen mit einer <b>Nationalität der Kategorie C</b> und einem <b>gewöhnlichen Reisepass</b>. Die Einreise darf dem Antragsteller nur <b>VERWEIGERT</b> werden.',
  'err.AddressZimbabwe.required': 'Adresse in Simbabwe erforderlich',
  'zwevisaextc.title': 'Aufenthaltsverlängerung für Kategorie C',
  'zwevisaextab.title': 'Aufenthaltsverlängerung für Kategorie A oder B',
  'DYN.payment_text.zwevisaextc': 'Die Aufenthaltsverlängerung für Kategorie C ermöglicht es Ihnen, Ihren Aufenthalt in Simbabwe zu verlängern.',
  'DYN.payment_text.zwevisaextab': 'Die Aufenthaltsverlängerung für Kategorie A oder B ermöglicht es Ihnen, Ihren Aufenthalt in Simbabwe zu verlängern.',
  'extend.start': 'Ein Antrag auf Aufenthaltsverlängerung kann eingereicht werden, um einen längeren Aufenthalt in Simbabwe zu ermöglichen. Während des Verlängerungsprozesses müssen Sie möglicherweise zusätzliche Informationen oder Dokumente bereitstellen. Bitte wählen Sie auf der nächsten Seite die Anzahl der Tage aus, um die Sie Ihr Visum verlängern möchten.',
  'visaExtend.desc': 'Sie können Ihr Visum für einen längeren Aufenthalt in Simbabwe verlängern. Bitte wählen Sie die Anzahl der Tage, um die Sie Ihr Visum verlängern möchten.',
  'kiosk.standby.title': 'BEREIT, SIMBABWE ZU ENTDECKEN?',
  'kiosk.standbyDesc.title': 'Willkommen am Einreisemeldekiosk von Simbabwe',
  'kiosk.standbyDesc.li4': 'Ein Einwanderungsbeamter wird dann mit der endgültigen Abfertigung fortfahren und Ihre Einreise nach Simbabwe bestätigen.',
  'kiosk.standbyDesc.text3': 'Wir wünschen Ihnen einen angenehmen Aufenthalt in Simbabwe!',
  'kiosk.success.text3': 'Wir wünschen Ihnen einen angenehmen Aufenthalt und wunderbare Entdeckungen in Simbabwe!',
  'kiosk.qr.url': 'https://www.evisa.gov.zw/',
  'PARX_SUB3_SEC12': 'Absatz X Unterabschnitt 3 Abschnitt 12',
  'PARB_SEC17': 'Absatz B Abschnitt 17',
  'SUB1_SEC18': 'Unterabschnitt 1 Abschnitt 18',
  'PARX_SUB1_SEC14': 'Absatz X Unterabschnitt 1 Abschnitt 14',
  'SUBPARIII_PARF_SUB1_SEC14': 'Unterabsatz III Absatz F Unterabschnitt 1 Abschnitt 14',
  'err.addressZimbabwe.required': 'Adresse in Simbabwe erforderlich',
  'PreviousConvictionsZimAsk': 'Wurden Sie oder einer Ihrer Angehörigen jemals in Simbabwe für ein Verbrechen verurteilt?',
  'err.PreviousConvictionsZimAsk.required': 'Bitte beantworten Sie die Frage zu früheren Verurteilungen in Simbabwe',
  'err.PreviousConvictionsZim.required': 'Frühere Verurteilung(en) in Simbabwe erforderlich',
  'form.plannedEntry': 'Welcher ist der geplante Einreisehafen nach Simbabwe?',
  'form.plannedEntry.desc': 'Bitte wählen Sie den Einreisehafen aus, an dem Sie nach Simbabwe einreisen möchten.',
  'zwevisa.DYN.desc': 'Erlaubt eine einmalige Einreise in das Land innerhalb eines festgelegten Zeitraums von einem (1) Monat, wobei für spätere Einreisen ein neues Visum erforderlich ist.',
  'zwevisa.DYN.long_desc': 'Dieses Visum erlaubt eine einmalige Einreise in das Land innerhalb eines festgelegten Zeitraums von einem (1) Monat.</br>Es eignet sich für Reisende, die einen einzigen Besuch in Simbabwe innerhalb eines kurzen Zeitraums planen.</br>Nach der ersten Einreise ist für weitere Besuche ein neues Visum erforderlich, was es für diejenigen weniger praktisch macht, die mehrere Besuche innerhalb eines kurzen Zeitraums planen.',
  'zwevisa_double.DYN.desc': 'Ermöglicht zwei separate Einreisen in das Land innerhalb eines festgelegten Zeitraums von drei (3) Monaten und ist ideal für Reisende, die mehrere Besuche geplant haben.',
  'zwevisa_double.DYN.long_desc': 'Das Doppelvisum erlaubt zwei separate Einreisen in das Land innerhalb eines festgelegten Zeitraums von drei (3) Monaten.</br>Dieses Visum ist ideal für Reisende, die mehrere Besuche geplant haben, wie zum Beispiel Geschäftsreisende oder Besucher von Veranstaltungen in Simbabwe.</br>Es bietet mehr Flexibilität als das Einzelvisum, erfordert jedoch weiterhin ein neues Visum für zusätzliche Besuche über die zwei erlaubten Einreisen hinaus.',
  'zwevisa_multiple.DYN.desc': 'Gewährt dem Inhaber mehrere Einreisen in das Zielland über einen festgelegten Zeitraum von sechs (6) Monaten, was häufiges Reisen zu Geschäftszwecken oder für Tourismus erleichtert.',
  'zwevisa_multiple.DYN.long_desc': 'Dieses Visum gewährt dem Inhaber mehrere Einreisen in das Zielland über einen festgelegten Zeitraum von sechs (6) Monaten.</br>Es eignet sich für Vielreisende, die Simbabwe mehrmals zu Geschäftszwecken oder für Tourismus besuchen müssen.</br>Das Visum für mehrfache Einreisen bietet die größte Flexibilität unter den genannten Visumstypen und ermöglicht unbegrenzte Einreisen innerhalb des festgelegten Zeitraums.',
  'zwevisa_kaza.DYN.desc': 'Ein spezielles Visum, das die Einreise in mehrere Länder innerhalb des Kavango-Zambezi-Transfrontier-Naturschutzgebiets (KAZA TFCA) für einen Zeitraum von einem (1) Monat ermöglicht und den regionalen Tourismus und die Naturschutzbemühungen fördert.',
  'zwevisa_kaza.DYN.long_desc': 'Das KAZA UNIVISA ist ein spezielles Visum, das die Einreise in mehrere Länder innerhalb des Kavango-Zambezi-Transfrontier-Naturschutzgebiets (KAZA TFCA) für einen Zeitraum von einem (1) Monat ermöglicht.</br>Es fördert den regionalen Tourismus und die Naturschutzbemühungen, indem es Reisen zwischen den teilnehmenden Ländern erleichtert, darunter <b>Simbabwe</b>, <b>Sambia</b> und <b>Botswana</b>.</br>Das KAZA UNIVISA ist für 30 Tage gültig und ermöglicht mehrere Einreisen in das KAZA TFCA.',
  'zwevisaarr.DYN.desc': 'Ermöglicht es berechtigten Reisenden, bei der Ankunft am Einreiseort des Ziellandes ein Visum zu erhalten, und vereinfacht so den Einreiseprozess für kurzfristige Besuche. Dieses Visum hat eine Gültigkeitsdauer von einem (1) Monat.',
  'zwevisaarr.DYN.long_desc': 'Dieses Visum ermöglicht es berechtigten Reisenden, bei der Ankunft am Einreiseort des Ziellandes ein Visum zu erhalten, und vereinfacht so den Einreiseprozess für kurzfristige Besuche.</br>Es eignet sich für Reisende, die nicht die Zeit oder Ressourcen haben, im Voraus ein Visum zu beantragen.</br>Das Visum bei der Ankunft ist in der Regel für einen kurzen Zeitraum gültig, wie etwa 30 Tage, und kann Einschränkungen hinsichtlich der erlaubten Einreisen haben. Dieses Visum hat eine Gültigkeitsdauer von einem (1) Monat.',
  'zwevisaarr_double.DYN.desc': 'Ermöglicht es berechtigten Reisenden, bei der Ankunft ein Visum zu erhalten, das zwei Einreisen erlaubt und Flexibilität für mehrere Besuche bietet. Dieses Visum ist innerhalb eines festgelegten Zeitraums von drei (3) Monaten gültig.',
  'zwevisaarr_double.DYN.long_desc': 'Das Doppelvisum bei Ankunft ermöglicht es berechtigten Reisenden, bei der Ankunft ein Visum zu erhalten, das zwei Einreisen erlaubt.</br>Es bietet Flexibilität für Reisende, die mehrere Besuche innerhalb der Gültigkeitsdauer des Visums planen.</br>Dieser Visumtyp eignet sich für Geschäftsreisende oder Touristen, die Simbabwe und andere Nachbarländer besuchen möchten. Dieses Visum ist innerhalb eines festgelegten Zeitraums von drei (3) Monaten gültig.',
  'zwevisaarr_multiple.DYN.desc': 'Gewährt berechtigten Reisenden ein Visum, das mehrere Einreisen bei Ankunft am Einreiseort des Ziellandes ermöglicht und den Bedürfnissen häufiger Besucher gerecht wird. Dieses Visum ist innerhalb eines festgelegten Zeitraums von sechs (6) Monaten gültig.',
  'zwevisaarr_multiple.DYN.long_desc': 'Dieses Visum gewährt berechtigten Reisenden ein Visum, das mehrere Einreisen bei Ankunft am Einreiseort des Ziellandes ermöglicht.</br>Es erfüllt die Bedürfnisse häufiger Besucher, die Simbabwe mehrmals innerhalb eines kurzen Zeitraums besuchen müssen.</br>Das Visum für mehrfache Einreisen bei Ankunft bietet die größte Flexibilität unter den genannten Visumstypen und ermöglicht unbegrenzte Einreisen innerhalb des festgelegten Zeitraums. Dieses Visum ist innerhalb eines festgelegten Zeitraums von sechs (6) Monaten gültig.',
  'zwevisaarr_kaza.DYN.desc': 'Eine Variante des KAZA Univisa, das bei der Ankunft am Einreiseort des Ziellandes ausgestellt wird und Komfort für Reisende bietet, die mehrere Länder innerhalb des KAZA TFCA erkunden. Dieses Visum hat eine Gültigkeitsdauer von einem (1) Monat.',
  'zwevisaarr_kaza.DYN.long_desc': 'Diese Variante des KAZA UNIVISA wird bei der Ankunft am Einreiseort des Ziellandes ausgestellt.</br>Sie bietet Komfort für Reisende, die mehrere Länder innerhalb des KAZA TFCA erkunden, darunter <b>Simbabwe</b>, <b>Sambia</b> und <b>Botswana</b>.</br>Das KAZA UNIVISA bei Ankunft ist für einen (1) Monat gültig und ermöglicht mehrere Einreisen in das KAZA TFCA.',
  'zweentry.DYN.desc': 'Simbabwische Staatsbürger sowie Reisende aus ausgewählten Ländern können ohne vorherige Visumbesorgung einreisen.',
  'zweentry.DYN.long_desc': 'Simbabwische Staatsbürger sowie Reisende aus ausgewählten Ländern können ohne vorherige Visumbesorgung einreisen.</br>Dieser Visumtyp eignet sich für kurzfristige Besuche und touristische Zwecke.</br>Er ermöglicht es Reisenden, ohne Visumantragsverfahren in das Land einzureisen und vereinfacht die Einreiseverfahren.</br></br>Es ist jedoch wichtig zu beachten, dass die Einreise ohne Visum keine mehrmaligen Einreisen oder verlängerte Aufenthalte über den festgelegten Besuchszeitraum hinaus erlaubt.',
  'zwevisa_visitor_details_text': '<div class="mb_40">Wenn Sie Staatsangehöriger eines Landes sind, das in <a href="https://www.evisa.gov.zw/faq.html" target="_blank"><b>Kategorie A (klicken zum Öffnen)</b></a> aufgeführt ist, benötigen Sie kein Visum, um nach Simbabwe zu reisen.</div>',
  'zwevisa.description': 'Das Visum für eine einmalige Einreise ermöglicht eine einzige Einreise nach Simbabwe für einen Zeitraum von einem (1) Monat. Nach der Nutzung ist eine erneute Einreise ohne die Beantragung eines neuen Visums nicht gestattet.',
  'zwevisa_double.description': 'Das Visum für zwei Einreisen erlaubt zwei Einreisen nach Simbabwe innerhalb eines Zeitraums von drei (3) Monaten ab dem Ausstellungsdatum.',
  'zwevisa_multiple.description': 'Das Visum für mehrfache Einreisen erlaubt unbegrenzte Einreisen nach Simbabwe innerhalb eines Zeitraums von sechs (6) Monaten ab dem Ausstellungsdatum.',
  'zwevisaarr_multiple.description': 'Das Visum für mehrfache Einreisen bei Ankunft erlaubt unbegrenzte Einreisen nach Simbabwe innerhalb eines Zeitraums von sechs (6) Monaten ab dem Ausstellungsdatum. Dieses Visum wird direkt bei Ankunft am Einreiseort ausgestellt und bietet eine praktische und effiziente Lösung für Reisende, die mehrere kurzfristige Besuche planen.',
  'zwevisa_kaza.description': 'Das KAZA UNIVISA ist ein einzigartiges Reisedokument, das Ihre Erkundung des Kavango-Zambezi-Transfrontier-Naturschutzgebiets (KAZA TFCA) verbessert. Es umfasst Sambia, Simbabwe, Botswana, Namibia und Angola und erleichtert das Reisen zwischen diesen vielfältigen Regionen, sodass Sie die reichen kulturellen und natürlichen Wunder, die sie bieten, nahtlos erleben können.',
  'zwevisaarr_multiple': 'Visum für mehrfache Einreisen bei Ankunft',
  'zwevisaarr.description': 'Das Visum für eine einmalige Einreise bei Ankunft ist ein einfaches Reisedokument, das es berechtigten Besuchern ermöglicht, bei ihrer Ankunft am Einreiseort ihres Ziels ein Visum zu beantragen und zu erhalten. Es zeichnet sich durch schnelle Bearbeitung, direkte Gebührenzahlung und breite Berechtigung aus und bietet eine praktische und flexible Option für kurzfristige Aufenthalte. Reisende sollten die spezifischen Anforderungen für ihr Reiseziel überprüfen, da die Berechtigung je nach Land variieren kann.',
  'zwevisaarr.title': 'Visum für eine einmalige Einreise bei Ankunft',
  'zwevisaarr_double.title': 'Visum für zwei Einreisen bei Ankunft',
  'zwevisaarr_kaza.title': 'KAZA-Visum bei Ankunft',
  'zwevisaarr_multiple.title': 'Visum für mehrfache Einreisen bei Ankunft',
  'zwevisa': 'Visum für eine einmalige Einreise',
  'zwevisa_double': 'Visum für zwei Einreisen',
  'zwevisa_multiple': 'Visum für mehrfache Einreisen',
  'zwevisa_kaza': 'KAZA Univisa',
  'zwevisa_double.title': 'Visum für zwei Einreisen',
  'zwevisa_multiple.title': 'Visum für mehrfache Einreisen',
  'zwevisa_kaza.title': 'KAZA Univisa',
  'zwevisaarr': 'Visum für eine einmalige Einreise bei Ankunft',
  'zwevisaarr_double': 'Visum für zwei Einreisen bei Ankunft',
  'zwevisaarr_kaza': 'KAZA Univisa bei Ankunft',
  'PreviousZimbabweanVisa': 'Vorheriges Simbabwisches Visum',
  'err.PreviousZimbabweanVisa.required': 'Vorheriges Simbabwisches Visum ist erforderlich',
  'visaPriceArrival.desc': 'Dies ist der Preis, den Sie bei Ihrer Ankunft in Simbabwe zahlen müssen. Diese Zahlung ist nicht erstattungsfähig.',
  'document_text': 'Bitte geben Sie die folgenden Informationen wahrheitsgemäß und wie sie in Ihrem Reisepass oder anderen rechtlichen Dokumenten erscheinen an. Das Einwanderungsamt (Simbabwe) übernimmt keine Verantwortung für falsche Informationen oder daraus resultierende Unannehmlichkeiten. Die akzeptierten Dokumentformate sind JPEG, PNG oder PDF und dürfen nicht größer als 1 MB sein.',
  'PreviousConvictionsZim': 'Art der Verurteilung(en) in Simbabwe',
  'travel_information_text': 'Bitte geben Sie die folgenden Informationen wahrheitsgemäß und wie sie in Ihrem Reisepass oder anderen rechtlichen Dokumenten erscheinen an. Das Einwanderungsamt (Simbabwe) übernimmt keine Verantwortung für falsche Informationen oder daraus resultierende Unannehmlichkeiten.',
  'AddressZimbabwe': 'Adresse in Simbabwe',
  'form.addressZimbabwe': 'Gastadresse',
  'form.previousConvictionsZim': 'Frühere Verurteilungen in Simbabwe',
  'proof.residence.zim': 'Wohnsitznachweis in Simbabwe',
  'err.PreviousConvictionsZim.pattern': 'Frühere Verurteilungen in Simbabwe müssen das korrekte Format haben',
  'DYN.payment_text.zwevisa': '<p>Dieser Antrag gilt für ein <b>Visum für eine einmalige Einreise</b>.</p> <p>Das Visum für eine einmalige Einreise erlaubt eine einmalige Einreise nach Simbabwe für einen Zeitraum von einem (1) Monat. Nach der Nutzung ist eine erneute Einreise ohne Beantragung eines neuen Visums nicht gestattet.</p> <div><p class="mb_2"><b>Bitte lesen Sie dies sorgfältig durch:</b></p> <p>Sie können nicht reisen, bis Sie die Genehmigung des Visumantrags erhalten haben. Der Antrag, den Sie jetzt einreichen und bezahlen, muss noch den Verwaltungsprüfungsprozess durchlaufen. Sie erhalten Benachrichtigungen per E-Mail, sobald dieser Antrag genehmigt, überarbeitet oder abgelehnt wird.</p></div> <b>Das Einwanderungsamt von Simbabwe behält sich das Recht vor, Ihren Visumtyp während der Bearbeitung nach eigenem Ermessen zu ändern. Dies kann die Kosten Ihres Visums beeinflussen. Die tatsächlichen Visumkosten werden in der Genehmigungsantwort angegeben. Nach erfolgter Zahlung ist keine Änderung mehr möglich.</b></p>',
  'DYN.payment_text.zwevisa_double': '<p>Dieser Antrag gilt für ein <b>Visum für zwei Einreisen</b>.</p> <p>Das Visum für zwei Einreisen erlaubt zwei Einreisen nach Simbabwe innerhalb eines Zeitraums von drei (3) Monaten ab dem Ausstellungsdatum.</p> <div><p class="mb_2"><b>Bitte lesen Sie dies sorgfältig durch:</b></p> <p>Sie können nicht reisen, bis Sie die Genehmigung des Visumantrags erhalten haben. Der Antrag, den Sie jetzt einreichen und bezahlen, muss noch den Verwaltungsprüfungsprozess durchlaufen. Sie erhalten Benachrichtigungen per E-Mail, sobald dieser Antrag genehmigt, überarbeitet oder abgelehnt wird.</p></div> <b>Das Einwanderungsamt von Simbabwe behält sich das Recht vor, Ihren Visumtyp während der Bearbeitung nach eigenem Ermessen zu ändern. Dies kann die Kosten Ihres Visums beeinflussen. Die tatsächlichen Visumkosten werden in der Genehmigungsantwort angegeben. Nach erfolgter Zahlung ist keine Änderung mehr möglich.</b></p>',
  'DYN.payment_text.zwevisa_kaza': '<p>Dieser Antrag gilt für ein <b>KAZA Univisa</b>.</p> <p>Das KAZA UNIVISA ist ein einzigartiges Reisedokument, das Ihre Erkundung des Kavango-Zambezi-Transfrontier-Naturschutzgebiets (KAZA TFCA) für einen Zeitraum von einem (1) Monat verbessert. Es umfasst Sambia, Simbabwe, Botswana, Namibia und Angola und erleichtert das Reisen zwischen diesen vielfältigen Regionen, sodass Sie die reichen kulturellen und natürlichen Wunder, die sie bieten, nahtlos erleben können.</p> <div><p class="mb_2"><b>Bitte lesen Sie dies sorgfältig durch:</b></p> <p>Sie können nicht reisen, bis Sie die Genehmigung des Visumantrags erhalten haben. Der Antrag, den Sie jetzt einreichen und bezahlen, muss noch den Verwaltungsprüfungsprozess durchlaufen. Sie erhalten Benachrichtigungen per E-Mail, sobald dieser Antrag genehmigt, überarbeitet oder abgelehnt wird.</p></div> <b>Das Einwanderungsamt von Simbabwe behält sich das Recht vor, Ihren Visumtyp während der Bearbeitung nach eigenem Ermessen zu ändern. Dies kann die Kosten Ihres Visums beeinflussen. Die tatsächlichen Visumkosten werden in der Genehmigungsantwort angegeben. Nach erfolgter Zahlung ist keine Änderung mehr möglich.</b></p>',
  'DYN.payment_text.zwevisa_multiple': '<p>Dieser Antrag gilt für ein <b>Visum für mehrfache Einreisen</b>.</p> <p>Das Visum für mehrfache Einreisen erlaubt unbegrenzte Einreisen nach Simbabwe innerhalb eines Zeitraums von sechs (6) Monaten ab dem Ausstellungsdatum.</p> <div><p class="mb_2"><b>Bitte lesen Sie dies sorgfältig durch:</b></p> <p>Sie können nicht reisen, bis Sie die Genehmigung des Visumantrags erhalten haben. Der Antrag, den Sie jetzt einreichen und bezahlen, muss noch den Verwaltungsprüfungsprozess durchlaufen. Sie erhalten Benachrichtigungen per E-Mail, sobald dieser Antrag genehmigt, überarbeitet oder abgelehnt wird.</p></div> <b>Das Einwanderungsamt von Simbabwe behält sich das Recht vor, Ihren Visumtyp während der Bearbeitung nach eigenem Ermessen zu ändern. Dies kann die Kosten Ihres Visums beeinflussen. Die tatsächlichen Visumkosten werden in der Genehmigungsantwort angegeben. Nach erfolgter Zahlung ist keine Änderung mehr möglich.</b></p>',
  'DYN.payment_text.zwevisaarr': '<p>Dieser Antrag gilt für ein <b>Visum für eine einmalige Einreise bei Ankunft</b>.</p> <p>Das Visum für eine einmalige Einreise bei Ankunft ist ein einfaches Reisedokument, das es berechtigten Besuchern ermöglicht, bei ihrer Ankunft am Einreiseort ihres Ziels ein Visum zu beantragen und zu erhalten. Es zeichnet sich durch schnelle Bearbeitung, direkte Gebührenzahlung und breite Berechtigung aus und bietet eine praktische und flexible Option für kurzfristige Aufenthalte. Reisende sollten die spezifischen Anforderungen für ihr Reiseziel überprüfen, da die Berechtigung je nach Land variieren kann.</p><b>Das Einwanderungsamt von Simbabwe behält sich das Recht vor, Ihren Visumtyp während der Bearbeitung nach eigenem Ermessen zu ändern. Dies kann die Kosten Ihres Visums beeinflussen. Die tatsächlichen Visumkosten werden in der Genehmigungsantwort angegeben. Nach erfolgter Zahlung ist keine Änderung mehr möglich.</b></p>',
  'DYN.payment_text.zwevisaarr_double': '<p>Dieser Antrag gilt für ein <b>Visum für zwei Einreisen</b>.</p> <p>Das Visum für zwei Einreisen erlaubt zwei Einreisen in das Land innerhalb eines festgelegten Zeitraums, in der Regel drei (3) Monate ab dem Ausstellungsdatum. Es ist für diejenigen gedacht, die zweimal für kurzfristige Aufenthalte in das Land einreisen möchten. Dieses Visum bietet die Bequemlichkeit, bei der Ankunft am Einreiseort Ihres Ziels zu beantragen, mit schneller Bearbeitung und direkter Gebührenzahlung. Die Berechtigung und Anforderungen können je nach Land variieren. Reisende sollten spezifische Details für ihr Reiseziel überprüfen.</p><b>Das Einwanderungsamt behält sich das Recht vor, Ihren Visumtyp während der Bearbeitung zu ändern. Dies kann die Visumkosten beeinflussen. Die endgültigen Kosten werden in Ihrer Genehmigungsantwort angegeben. Nach erfolgter Zahlung sind keine Änderungen mehr möglich.</b></p>',
  'DYN.payment_text.zwevisaarr_kaza': '<p>Dieser Antrag gilt für ein <b>KAZA-Visum bei Ankunft</b>.</p> <p>Das KAZA-Visum bei Ankunft erleichtert die Einreise in das Kavango-Zambezi-Transfrontier-Naturschutzgebiet, das Sambia, Simbabwe, Botswana, Namibia und Angola für einen Zeitraum von einem (1) Monat umfasst. Es ermöglicht berechtigten Reisenden, bei der Ankunft am Einreiseort ihres Ziels ein Visum direkt zu beantragen und zu erhalten. Dieses Visum ist bekannt für seine schnelle Bearbeitung, direkte Gebührenzahlung und breite Berechtigung, was es zu einer flexiblen Option für diejenigen macht, die die reichen kulturellen und natürlichen Landschaften der KAZA-Region für kurzfristige Aufenthalte erkunden möchten. Reisende sollten sicherstellen, dass sie die spezifischen Anforderungen für jedes Land innerhalb der KAZA TFCA erfüllen.</p><b>Die KAZA-Einwanderungsbehörden behalten sich das Recht vor, Ihren Visumtyp während der Bearbeitung zu ändern, was die Visumgebühr beeinflussen könnte. Die tatsächlichen Visumkosten werden in Ihrer Genehmigungsbenachrichtigung angegeben. Nach der Zahlung sind keine Änderungen mehr möglich.</b></p>',
  'DYN.payment_text.zwevisaarr_multiple': '<p>Dieser Antrag gilt für ein <b>Visum für mehrfache Einreisen bei Ankunft</b>.</p> <p>Das Visum für mehrfache Einreisen bei Ankunft erlaubt unbegrenzte Einreisen in das Zielland innerhalb eines Zeitraums von sechs (6) Monaten ab dem Ausstellungsdatum. Es zeichnet sich durch schnelle Bearbeitung und direkte Gebührenzahlung aus und bietet eine flexible Option für Reisende, die häufigen Zugang für kurzfristige Aufenthalte benötigen.</p><b>Das Einwanderungsamt behält sich das Recht vor, Ihren Visumtyp während der Bearbeitung nach eigenem Ermessen zu ändern. Dies kann die Kosten Ihres Visums beeinflussen. Die tatsächlichen Visumkosten werden in der Genehmigungsantwort angegeben. Nach erfolgter Zahlung sind keine Änderungen mehr möglich.</b></p>',
  'DYN.payment_text.zweentry': '<p>Dieser Antrag gilt für eine <b>Einreise ohne Visum</b>.</p> <p>Die Einreise ohne Visum ermöglicht es Reisenden aus bestimmten Ländern, ohne vorherige Visumbesorgung in das Land einzureisen, typischerweise für kurzfristige Aufenthalte.</p> <div><p class="mb_2"></p>',
  'zwevisaarr_double.description': 'Das Doppelvisum bei Ankunft erlaubt es Reisenden, innerhalb eines Zeitraums von drei Monaten ab dem Ausstellungsdatum zweimal in ein Land einzureisen. Dieses Visum wird direkt bei der Ankunft am Einreiseort ausgestellt und bietet eine schnelle und praktische Option für diejenigen, die planen, zweimal für kurzfristige Aufenthalte in das Land einzureisen. Es ist wichtig, dass Reisende die spezifischen Anforderungen und Zulassungskriterien für das Zielland überprüfen.',
  'zwevisaarr_kaza.description': 'Das KAZA-Visum bei Ankunft ermöglicht eine direkte Ausstellung am Einreiseort innerhalb des Kavango-Zambezi-Transfrontier-Naturschutzgebiets, das Sambia, Simbabwe, Botswana, Namibia und Angola umfasst. Dieser vereinfachte Prozess ermöglicht es Reisenden, ihr Visum bei Ankunft für kurzfristige Besuche effizient zu erhalten und die vielfältigen Attraktionen der Region zu erkunden. Es ist wichtig, dass Reisende die spezifischen Einreisebestimmungen für jedes Land innerhalb der KAZA TFCA überprüfen.',
  'print.desc.part1': 'Der Besitz einer elektronischen Reisegenehmigung (eTA) garantiert keine Einreise in die Republik Simbabwe. Die Zulassung wird von den Einwanderungsbeamten am Einreiseort bestimmt.<br><span class="mt_10">Es ist eine Straftat, ohne die erforderliche Genehmigung in Simbabwe Geschäfte zu machen oder zu arbeiten. Bitte legen Sie Ihren Reisepass und diese eTA auf Verlangen den Beamten der Fluggesellschaft oder den Einwanderungsbeamten vor.</span>',
  'tooltip.addressZimbabwe': 'Wenn der Antragsteller eine Adresse in Simbabwe hat, geben Sie sie bitte hier ein.',
  'err.PreviousConvictionsZim.maxlength': 'Frühere Verurteilungen in Simbabwe dürfen nicht länger als 50 Zeichen sein',
  'zweprovrespermit': 'Vorläufige Aufenthaltserlaubnis',
  'zwevisaextc': 'Aufenthaltsverlängerung für Kategorie C',
  'zwevisaextab': 'Aufenthaltsverlängerung für Kategorie A oder B',
  'zwefeedback': 'Feedback-Formular zum Aufenthalt',
  'zwevisa_visitor_details_title': 'Fällt Ihre Nationalität unter das Kategorie-A-Regime?',
  'refusalReason.desc.DYN.zwevisa': '<p><b>Bitte beachten Sie, dass die Person, die mit diesem Visumantrag in Verbindung steht, auf die schwarze Liste gesetzt wird, falls der Begriff "schwarze Liste" verwendet wird.</b></p><p>Bitte geben Sie eine kurze Beschreibung des Ablehnungsgrundes an.</p>',
  'refusalReason.desc.extension.DYN.zwevisa': '<p>Bitte geben Sie eine kurze Beschreibung des Ablehnungsgrundes für diesen Verlängerungsantrag an.</p>',
  'refusalReason.review.desc.DYN.zwevisa': 'Bitte geben Sie eine kurze Beschreibung der Überprüfung als <b>NICHT EMPFOHLEN</b> an. Diese Beschreibung wird an den nächsten Prüfer gesendet.',
  'approveReason.review.desc.DYN.zwevisa': 'Bitte geben Sie eine kurze Beschreibung der Überprüfung als <b>EMPFOHLEN</b> an. Diese Beschreibung wird an den nächsten Prüfer gesendet.',
  'deletePerson.error.DYN.zwevisa': 'Beim Löschen der Person ist ein Fehler aufgetreten. Sie können keine Person löschen, die bereits einen eingereichten Antrag hat.',
  'DYN.zwevisaarr': 'Visum für eine einmalige Einreise bei Ankunft',
  'notice.desc.BORDER_PASS': 'Ein Grenzpass ist ein offizielles Dokument, das von der Regierung von Simbabwe ausgestellt wird und es Einzelpersonen erlaubt, bestimmte Grenzpunkte legal zu überqueren.',
  'scan.visaCreated.exit': 'Der Visumantrag wurde erfolgreich erstellt. Sie können die Person jetzt auschecken.',
  'scan.visaCreated.entry': 'Der Visumantrag wurde erfolgreich erstellt. Sie können die Person jetzt einchecken.',
  'forceComparison.show': 'Vergleich mit gescanntem Dokument anzeigen',
  'forceComparison.hide': 'Vergleich mit gescanntem Dokument ausblenden',
  'tooltip.forceComparison': 'Vergleichen Sie die Informationen zwischen dem gescannten Dokument und dem Antrag.',
  'noScannedComparison': 'Keine übereinstimmenden Daten im Antrag gefunden',
  'transfertFromPassport': 'Informationen vom gescannten Dokument übertragen.',
  'tooltip.transfertFromPassport': 'Die Informationen zwischen dem Reisepass und dem gescannten Dokument sind unterschiedlich. Sie können die Informationen vom gescannten Dokument in den Antrag übertragen.',
  'CompanionsSize': 'Anzahl der Reisebegleiter',
  'transit.consume': 'Transit',
  'tooltip.scanPassport': 'Den Reisepass der Person scannen',
  'passport.scan': 'Reisepass scannen',
  'tooltip.transitRegister': 'Den Transit der Person registrieren',
  'transit.register': 'Einen Transit registrieren',
  'global.goLatest': 'Den zuletzt besuchten Antrag öffnen',
  'tooltip.goLatest': 'Sie können zum vorherigen Antrag zurückkehren, den Sie besucht haben',
  'scan.choice': 'Modus auswählen',
  'form.ad': 'E-Mail/Benutzername',
  'printerOffline': 'Der Thermodrucker ist offline. Bitte überprüfen Sie die USB-Verbindung mit dem Gerät und versuchen Sie es erneut. Möchten Sie das Dokument dennoch mit einem normalen Drucker drucken?',
  'printerNotFound': 'Der Thermodrucker wird in Ihrer Geräteliste nicht erkannt. Bitte überprüfen Sie die Verbindung und stellen Sie sicher, dass die Treiber installiert sind. Möchten Sie das Dokument dennoch mit einem normalen Drucker drucken?',
  'datePrinted': 'Druckdatum',
  'printThermal': 'Mit Thermodrucker drucken',
  'tooltip.printThermal': 'Das Dokument mit einem Thermodrucker drucken oder deaktivieren, um es mit einem normalen Drucker zu drucken',
  'application.overStay': 'Der Antrag ist <b>überzogen</b>',
  'watchlistFound': 'Der Antrag <b>stimmt mit der Beobachtungsliste überein</b>',
  'apipnrFound': 'Der Antrag <b>stimmt mit der API/PNR-Liste überein</b>',
  'payAttention': 'Ich werde darauf achten',
  'deportedMode': 'Als abgeschoben markieren',
  'tooltip.deportedMode': 'Die Person als abgeschoben markieren. Wenn Sie die Person als abgeschoben markieren, wird sie bei der Einreise als abgeschoben im System geführt.',
  'deportedComment': 'Grund für den Abschiebestatus',
  'payment.inReturned': 'Dieser Antrag wurde mit <b>Abschiebestatus</b> am Schalter des Beamten eingecheckt.',
  'reasonAdd': '<p><b>Grund</b>: <br/> {reason}</p>',
  'global.deported': 'Abgeschoben',
  'photo.entryCountry.INRETURNED': 'Foto bei der Einreise mit Abschiebestatus',
  'print.notices': 'Hinweise drucken',
  'ask.noticePrint': 'Möchten Sie den Hinweis vom {date} drucken?',
  'select.notice': 'Wählen Sie den zu druckenden Hinweis aus',
  'ask.selectNotice': 'Bitte wählen Sie den Hinweis aus, den Sie drucken möchten.',
  'noChoice.selectedNotice': 'Kein Hinweis ausgewählt',
  'tooltip.printNotices': 'Drucken Sie die Hinweise, die der Beamte während des Eincheckens oder des Ablehnungsprozesses ausgedruckt hat',
  'ask.revertConsume': 'Sind Sie sicher, dass Sie dieses Ereignis stornieren möchten?',
  'cancelled': 'Storniert',
  'tooltip.revertConsume': 'Sie können dieses Ereignis für diesen Antrag stornieren',
  'done.revertConsume': 'Das Ereignis wurde erfolgreich storniert',
  'revert.consume': 'Grund der Stornierung',
  'error.revertConsume': 'Beim Stornieren des Ereignisses ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'outUsage.AB': 'Dieser Antrag ist <b>außerhalb seiner Gültigkeitsdauer</b>. Bitte leiten Sie den Antragsteller zum Kiosk weiter oder helfen Sie ihm bei der erneuten Antragstellung.',
  'outUsage.C': 'Dieser Antrag ist <b>außerhalb seiner Gültigkeitsdauer</b>. Bitte schließen Sie diesen Antrag, erstellen Sie dann einen neuen Eintrag für den Antragsteller und fahren Sie mit der Ablehnung fort.',
  'print.date': 'Druckdatum',
  'print.id': 'Verknüpft mit Antrags-ID',
  'internalReference.badPattern': 'Die interne Referenz muss dem Format "Nummer_Jahr" folgen (z. B. 140_2024).',
  'showFullDatas': 'Alle Dokumente anzeigen',
  'hideFullDatas': 'Alle Dokumente ausblenden',
  'tooltip.showFullDatas': 'Zeigen oder verbergen Sie alle Dokumente des Antrags',
  'watchlist.null': '<b>{system}-System konnte nicht überprüft werden</b>',
  'watchlist': 'Beobachtungsliste',
  'apipnr': 'API-PNR',
  'global.refuseNoCam': 'Ablehnen ohne Kamera',
  'global.refuseIn': 'Ablehnen beim Check-in',
  'global.refuseOut': 'Ablehnen beim Check-out',
  'photo.entryCountry.REFUSEIN': 'Foto beim Check-in mit Ablehnungsstatus',
  'photo.entryCountry.REFUSEOUT': 'Foto beim Check-out mit Ablehnungsstatus',
  'global.refuseFingerPrint': 'Ablehnen mit Fingerabdruck',
  'tooltip.refuseFingerPrint': 'Person mit Fingerabdruck ablehnen',
  'tooltip.consumeRefuseNoCam': 'Person ohne Kamera ablehnen',
  'ask.refuseCamera': 'Mit Kamera ablehnen',
  'tooltip.disabledBecauseReview': 'Diese Person ist derzeit mit einer Anwendung verknüpft, die von einem Vorgesetzten überprüft wird. Um eine neue Anwendung für diese Person zu erstellen, müssen Sie die Überprüfung abwarten.',
  'client.extended.noValidPerson': 'Es wurde keine Person gefunden, die mit dieser Zahlung verbunden ist. Bitte überprüfen Sie Ihre gespeicherten Personen und versuchen Sie es erneut.',
  'zwevisaarr.arrivalPrint': 'Einreisevisum für eine Einreise, bei Ankunft zu zahlen',
  'zwevisaarr_double.arrivalPrint': 'Doppeltes Einreisevisum, bei Ankunft zu zahlen',
  'zwevisaarr_multiple.arrivalPrint': 'Mehrfaches Einreisevisum, bei Ankunft zu zahlen',
  'zwevisaarr_kaza.arrivalPrint': 'KAZA Univisa, bei Ankunft zu zahlen',
  'transfertHKG': 'Diese Anwendung als Hongkong-Anwendung markieren',
  'tooltip.transfertHKG': 'Sie können diese Anwendung als Hongkong-Anwendung markieren, basierend auf einem gescannten chinesischen Pass',
  'ask.updateForHKG': 'Sind Sie sicher, dass Sie diese Anwendung in eine Hongkong-Anwendung aktualisieren möchten?',
  'done.updateForHKG': 'Die Anwendung wurde erfolgreich in eine Hongkong-Anwendung aktualisiert',
  'error.updateForHKG': 'Beim Aktualisieren der Anwendung in eine Hongkong-Anwendung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
};