<div id="print">
  <div class="print_content">
    <div class="flex f_between mt_30">
      <span>GARSU PASAULIS</span>
      <span>Form L.F.18</span>
    </div>
    <div class="flex f_between">
      <span><b>Section 43, 45 of the Regulations</b></span>
      <div class="text_end">
        <span>Headquarters reference: <b>{{data.form.controls.hqReference.value}}</b></span><br>
        <span>Station reference: <b>{{data.form.controls.stationReference.value}}</b></span>
      </div>
    </div>
    <div class="text_center mt_20">
      <h3>GOVERNMENT OF ZIMBABWE<br>
        IMMIGRATION ACT [CHAPTER 4:02]</h3>
      <h2><b>PROVISIONAL RESTRICTION NOTICE</b></h2>
    </div>
    <div>
      <span>To (full names): <b>{{data.form.controls.fullNames.value}}</b></span><br>
      <span>TAKE NOTICE that, in terms of section 43/45 of the Immigration Regulations, you are required to report to the Immigration Officer at: <b>{{data.form.controls.reportLocation.value}}</b> within <b>{{data.form.controls.daysUntilReport.value}}</b> days of the date hereof for the purpose of producing: </span>
    </div>
    <div class="mt_20 flex gap_30">
      <ul [ngClass]="{'multi-column': data.documents.length > 6}">
        <li *ngFor="let doc of data.documents; let i = index">{{'('+(i+1)+') '}} <b>{{doc === 'Other' ? (doc +': '+ data.form.controls.otherMissingDocument.value) : doc}}</b></li>
      </ul>
    </div>
    <div class="mt_30 flex ml_20">
      <p class="text_center"><i>(Immigration Officer to delete or<br>complete as appropriate)</i></p>
    </div>
    <div class="flex f_between">
      <div class="text_center" [ngStyle]="{'width': '250px', 'height': '160px', 'border': '2px solid black', 'padding-top': '10px'}">
        <span>Date Stamp</span>
      </div>
      <div class="text_end">
        <p>Immigration Officer: <b>{{sync.userDisplay(data.form.controls.officer.value)}} #{{data.form.controls.officer.value}}</b></p>
        <p class="mt_20">I acknowledge receipt of this notice</p>
      </div>
    </div>
    <div class="flex f_between mt_30">
      <h3><i><b>WARNING</b></i></h3>
      <span>Failure to comply with the terms of this notice may result in cancellation<br>and/ or renders the holder liable to be dealt with as a prohibited person.</span>
    </div>
    <div class="flex mt_30 gap_30">
      <span class="text_center">Addresses<br>in<br>Zimbabwe</span>
      <div class="ml_30">
        <p>Residential: <b>{{data.form.controls.residentialAddress.value}}</b></p>
        <p>Business: <b>{{data.form.controls.businessAddress.value}}</b></p>
        <p>Mobile No.: <b>{{data.form.controls.phone.value}}</b></p>
        <p>Email: <b>{{data.form.controls.email.value}}</b></p>
      </div>
    </div>
    <qrcode *ngIf="data.qrCode" elementType="img" [qrdata]="data.qrCode" [margin]="0"></qrcode>
  </div>
</div>
