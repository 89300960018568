import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import {ActivatedRoute} from '@angular/router';
import {Person, PersonLinkType} from '../../../shared/models/person';
import {environment} from 'src/environments/environment';
import {DocumentType} from 'src/app/shared/models/user';
import {CartService} from '../../../shared/services/cart.service';
import {RequestService} from 'src/app/shared/services/request.service';

@Component({
  selector: 'app-people-data',
  templateUrl: './people-data.component.html',
  styleUrls: ['./people-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PeopleDataComponent implements OnInit{

  userId = Number(this.route.snapshot.paramMap.get('userId'));
  PersonLinkType = PersonLinkType;
  actualPerson: Person;

  constructor(public nav: NavigateService,
              public api: ApiService,
              public cart: CartService,
              private loader: LoaderService,
              private lang: LangService,
              private route: ActivatedRoute,
              private request: RequestService) {
  }

  ngOnInit() {
    const reviewPersons = this.request.getPersonsWithReviewStatus(this.api.listPersons);
    this.actualPerson = reviewPersons.find((person: Person) => Number(person.id) === this.userId);
  }

  deletePerson() {
    this.loader.loading(true, {type: 'warn', message: this.lang.transform('deletePerson.ask.DYN.' + this.api.env.type), btnLabel: this.lang.transform('global.confirm')}).then((res: boolean) => {
      if(res) {
        this.loader.loading(true);
        this.api.deletePerson(this.userId).then(() => {
          this.loader.loading(false);
          this.loader.loading(true, {type: 'valid', message: this.lang.transform('deletePerson.valid.DYN.' + this.api.env.type)}).then(() => {
            this.api.persons().then((res: Person[]) => {
              this.api.listPersons = this.request.getPersonsWithReviewStatus(res);
              this.nav.to('people');
            });
          });
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: this.lang.transform('deletePerson.error.DYN.' + this.api.env.type)});
        });
      }
    });
  }
  protected readonly environment = environment;
  protected readonly DocumentType = DocumentType;
}
