import {Component, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NavigateService, FormService, LoaderService, LangService} from 'ngx-satoris';
import {PaperSize, PrintSettingsDetails, AllDocumentsPrintType} from 'src/app/shared/models/print';
import {Store} from 'src/app/shared/models/store';
import {ApiService} from 'src/app/shared/services/api.service';
import {PrintService} from 'src/app/shared/services/print.service';
import {StorageService} from 'src/app/shared/services/storage.service';
import {enumStringToArrayObject} from 'src/app/shared/utils/enumToArrayObject';

@Component({
  selector: 'app-print-settings',
  templateUrl: './print-settings.component.html',
  styleUrl: './print-settings.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PrintSettingsComponent {

  constructor(public nav: NavigateService,
    public forms: FormService,
    private formBuilder: FormBuilder,
    public api: ApiService,
    private print: PrintService,
    private storage: StorageService,
    private loader: LoaderService,
    private lang: LangService) {
    console.log('print options', this.print.printDocumentsOptions);
  }

  form: FormGroup;
  submitted: boolean;
  printDocumentSelected : AllDocumentsPrintType | null = null;
  paperSizeSelectOptions = enumStringToArrayObject(PaperSize);

  setForm(documentName: AllDocumentsPrintType) {
    this.form = this.formBuilder.group({
      defaultPrinter: [this.print.printDocumentsOptions?.[documentName]?.defaultPrinter ? this.print.printDocumentsOptions?.[documentName]?.defaultPrinter : ''],
      colorMode: [this.print.printDocumentsOptions?.[documentName]?.colorMode ? this.print.printDocumentsOptions?.[documentName]?.colorMode : false],
      paperSize: [this.print.printDocumentsOptions?.[documentName]?.paperSize ? this.print.printDocumentsOptions?.[documentName]?.paperSize : PaperSize.A4]
    });
  }

  submitForm(documentName: AllDocumentsPrintType) {
    this.submitted = true;
    const existingPrintSettings = this.print.printDocumentsOptions || {id: 'settings'};
    const updatedDocumentSettings : PrintSettingsDetails = {
      defaultPrinter: this.form.get('defaultPrinter')?.value,
      colorMode: this.form.get('colorMode')?.value,
      paperSize: this.form.get('paperSize')?.value
    };
    const updatedPrintSettings = {
      ...existingPrintSettings,
      [documentName]: updatedDocumentSettings
    };
    this.storage.saveToStorage(Store.PRINT_STORE, updatedPrintSettings).then(() => {
      this.loader.loading(true, {type: 'valid', custom: {innerHtml: this.lang.transform('print.settings.success', {documentName: this.lang.transform('DYN.' + documentName)})}
      }).then(() => {
        this.printDocumentSelected = null;
        this.print.printDocumentsOptions = updatedPrintSettings;
      });
    });
  }

  goBack() {
    this.printDocumentSelected ? this.printDocumentSelected = null : this.nav.to('my-account');
  }
}
