<div class="scanner-container layout f_col pl_10 pr_10">
  <div id="scanner" class="loader_scan" [class.scanner-hidden]="!started">
    <div #scanner_wrapper class="scanner_wrapper">
      <ngx-scanner-qrcode #scanner #action="scanner" [config]="config" (event)="onCodeResult($event, action)"></ngx-scanner-qrcode>
      <div class="scanner_bottom">
        <fa-icon icon="camera-rotate" (click)="nextCamera()" class="scanner_camera"></fa-icon>
      </div>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 fullWidth resp_maxSize_l">
    <app-back-btn *ngIf="api.userInfo" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button color="transparent" border="true" formId="form" class="fullWidth"
        (btnClicked)="nav.goBack()">{{'global.back' | translate}}</app-button>
    </div>
  </div>
</div>
