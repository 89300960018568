import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  DialogFormInput,
  DialogsService,
  faSpellCheck,
  LangService,
  LoaderService,
  NavigateService,
  ShortcutAction
} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import {addDays, addMilliseconds, format, isBefore, parse, parseISO} from 'date-fns';
import {Place} from '../../../shared/models/place';
import {isJsonParsable} from 'src/app/shared/utils/parseMetadata';
import {DocumentType, Gender, UserPermission} from 'src/app/shared/models/user';
import {
  ActionState,
  ComsumePaymentData,
  ConsumeMode,
  NoticeType,
  ConsumeRequestData,
  CountryCat,
  CurrencySign,
  DataGroup,
  OperationType,
  PassFields,
  PassFieldsData,
  PassportType,
  PrintApprovalLetterData,
  PrintBorderpassData,
  PrintReceiptData,
  PrintStickerData,
  PurposeOfVisit,
  RequestCashType,
  RequestState,
  RequestSubState,
  RequestWithDuplicata,
  WatchlistMatch
} from 'src/app/shared/models/request';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from 'src/environments/environment';
import {VisaFN} from 'src/app/shared/models/forms';
import {PlatformEvent, PlatformEventType} from 'src/app/shared/models/information';
import {QrService} from 'src/app/shared/services/qr.service';
import {CartService} from 'src/app/shared/services/cart.service';
import {RequestService} from 'src/app/shared/services/request.service';
import {ScanDocumentService} from 'src/app/shared/services/scan-document.service';
import {cleanDate, convertToEpoch, convertToYYMMDD} from 'src/app/shared/utils/date';
import {Subscription} from 'rxjs';
import * as uuid from 'uuid';
import {isBase64, isPDF, mergeDocuments} from 'src/app/shared/utils/file';
import {FingerprintService} from '../../../shared/services/fingerprint.service';
import {DialogDocViewerComponent} from '../../../components/dialogs/dialog-doc-viewer/dialog-doc-viewer.component';
import {QueueService} from '../../../shared/services/queue.service';
import {SyncService} from '../../../shared/services/sync.service';
import {enGB} from 'date-fns/locale';
import {DialogEditPassportComponent} from 'src/app/components/dialogs/dialog-edit-passport/dialog-edit-passport.component';
import {PaymentService} from '../../../shared/services/payment.service';
import {PlacesService} from '../../../shared/services/places.service';
import {isEmptyObject} from 'src/app/shared/utils/object';
import {PrintService} from 'src/app/shared/services/print.service';
import {
  AllDocumentsPrintType, DocsPrintType,
  PrintApplicationData,
  PrintNoticeData,
  PrintNoticeRestrictionData
} from 'src/app/shared/models/print';
import {Store} from 'src/app/shared/models/store';
import {StorageService} from 'src/app/shared/services/storage.service';

declare const window: any;

@Component({
  selector: 'app-admin-request-select',
  templateUrl: './admin-request-select.component.html',
  styleUrls: ['./admin-request-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminRequestSelectComponent implements OnDestroy {
  paymentId: string;
  current: RequestWithDuplicata;
  fromRoute: string;
  placeConsumed: Place;
  PlatformEventType = PlatformEventType;
  syncQueueSubscription: Subscription;
  searchParams = {};
  referencePaymentOnarrival: string; //TODO check if still needed
  printBorderPassData: PrintBorderpassData;
  eventCheckout: ConsumeRequestData;
  form: FormGroup;
  checkDraftTimeout: NodeJS.Timeout;
  finishCallPayment = false;
  seniorityLevel: null | number;
  currentPrintReceiptDatas: PrintReceiptData;
  isSyncing = false;
  cleanDate = cleanDate;
  isJsonParsable = isJsonParsable;
  printReceiptData: PrintReceiptData[] = [];
  OperationType = OperationType;
  ConsumePaymentNoticeType = NoticeType;
  selectedOperation = 0;
  printVisaApplication: DataGroup[] = [];
  checkRefusal: OperationType;
  RequestSubState = RequestSubState;
  status = RequestState;
  UserPermission = UserPermission;
  selectMode: boolean;
  selectedFields: VisaFN[] = [];
  printDocument: {
    title: string;
    img: string;
    id: string;
    date: string;
  } = {
      title: '',
      img: '',
      id: '',
      date: ''
    };
  approvalLetterData: PrintApprovalLetterData;
  referenceToPrint = '';
  showDocuments: { nameDocument: string, document: any }[] = [];
  visualizerIndex = '';
  metadataDocuments: { nameDocument: string, document: any, title?: string, icon?: string, customClass?: string, previewDirect?: string, checkComparison?: boolean}[] = [];
  isCatAB = false;
  isPDF = isPDF;
  existingNotices: PlatformEvent[];
  formNotice: FormGroup;
  foundByScan = false;
  deportedMode = false;
  currentPlace: Place;
  workerConsumeEntryExit = false;
  otherStateAction = false;

  paymentSubscription: Subscription;
  routeParamSubscription: Subscription;
  routeQuerySubscription: Subscription;
  changeConsumeSubscription : Subscription;
  backupPingSubscription: Subscription;
  printSubscription: Subscription;

  constructor(public nav: NavigateService,
              public api: ApiService,
              public qr: QrService,
              public cart: CartService,
              public request: RequestService,
              public queue: QueueService,
              public scanDocument: ScanDocumentService,
              public sync: SyncService,
              public payment: PaymentService,
              private fingerprint: FingerprintService,
              private route: ActivatedRoute,
              private loader: LoaderService,
              private formBuilder: FormBuilder,
              private dialogs: DialogsService,
              private dialog: DialogsService,
              private lang: LangService,
              private router: Router,
              private places: PlacesService,
              private print: PrintService,
              private storage: StorageService) {
    this.workerConsumeEntryExit = this.api.userRole.isWorker && this.api.hasPerm(UserPermission.ALLOW_CONSUME) && (this.scanDocument.consumeMode === ConsumeMode.ENTRY || this.scanDocument.consumeMode === ConsumeMode.EXIT || this.scanDocument.consumeMode === ConsumeMode.TRANSIT);

    this.places.getPlace(this.api.userPlaceId).then(place => {
      this.currentPlace = place;
    });

    this.seniorityLevel = this.api.checkSeniority();
    let registerScanSession = false;
    this.routeQuerySubscription =this.route.queryParams.subscribe(params => {
      const {from, registerSession, foundByScan, ...search} = params;
      registerScanSession = registerSession;
      this.searchParams = search;
      this.fromRoute = from;
      this.foundByScan = foundByScan === 'true';
    });

    this.routeParamSubscription = this.route.paramMap.subscribe(params => {
      this.paymentId = params.get('paymentId');
      window.sessionStorage.setItem('previousIdApplication', this.paymentId);
      if(registerScanSession && this.scanDocument.scanned) {
        this.scanDocument.scanDatas.id = this.paymentId;
        this.scanDocument.registerScanDatasToSession();
        this.scanDocument.scanned = false;
      }
      this.getPayment();
    });

    if(this.api.userRole.isWorker && this.api.hasPerm(UserPermission.ALLOW_CONSUME)) {
      this.syncQueueSubscription = this.cart.syncQueue$.subscribe((data) => {
        if(this.current?.id === data?.paymentId && data) {
          this.getPayment();
        }
      });
    }

    this.paymentSubscription = this.payment.current$.subscribe((payment) => {
      if(!payment || this.current && this.current.id !== payment.id) this.current = undefined;
      if(payment?.state === RequestState.PROPOSED) payment.allProcessed = false;

      this.current = payment;

      if(this.checkDraftTimeout) clearInterval(this.checkDraftTimeout);

      if(this.current) {
        this.loader.loading(false);
        this.reloadOnDraft(this.current);
        this.paymentAdditional();

      } else {
        this.loader.loading(true);
      }
    });

    this.backupPingSubscription = this.api.pingChange$.subscribe((pong) => {
      if(pong === true) this.getPayment();
    });
  }

  ngOnInit() {
    this.setForm();
  }

  ngOnDestroy() {
    if(this.routeParamSubscription) this.routeParamSubscription.unsubscribe();
    if(this.routeQuerySubscription) this.routeQuerySubscription.unsubscribe();
    if(this.paymentSubscription) this.paymentSubscription.unsubscribe();
    if(this.checkDraftTimeout) clearInterval(this.checkDraftTimeout);
    if(this.syncQueueSubscription) this.syncQueueSubscription.unsubscribe();
    if(this.backupPingSubscription) this.backupPingSubscription.unsubscribe();
    if(this.changeConsumeSubscription) this.changeConsumeSubscription.unsubscribe();
    if(this.printSubscription) this.printSubscription.unsubscribe();
  }

  updateField(field: VisaFN, inputType: 'text' | 'number' | 'password' | 'email' | 'select' | 'tel' | 'date' | 'pin' | 'datetime-local' | 'textarea' | 'file', value: any) {
    if(!this.canUpdateField()) return;
    const dialogInput: DialogFormInput[] = [{
      formCtrlName: field,
      type: inputType,
      label: field,
      value: inputType === 'date' ? format(new Date(value), 'yyyy-MM-dd') : value,
      validator: [Validators.required]
    }];
    this.loader.loading(true, {type: 'info', btnLabel: 'global.update', custom: {icon: 'pen-to-square', innerHtml: this.lang.transform('updateField.desc', {field: this.lang.transform(field)})}, dialogInput}).then((res: any) => {
      if(res?.[field] && res?.[field] !== value) {
        let newValue = res[field];
        if(inputType === 'date') {
          newValue = new Date(newValue);
        }
        const serializedDatas = JSON.stringify({[field]: newValue});
        this.loader.loading(true);
        this.api.updatePayment(this.current.id,
          undefined, undefined,
          field !== VisaFN.USAGE_AFTER && field !== VisaFN.USAGE_UNTIL ? serializedDatas : undefined,
          undefined, undefined, undefined, undefined,
          field === VisaFN.USAGE_AFTER ? newValue : undefined,
          field === VisaFN.USAGE_UNTIL ? newValue : undefined).then(() => {

          this.getPayment();
          this.loader.loading(false);
        }).catch((err) => {
          this.loader.loading(true, {type: 'error', message: err});
        });
      }
    });
  }

  canUpdateField(): boolean {
    return this.api.userRole.isWorker &&
      this.api.hasPerm(UserPermission.ALLOW_CONTROL) &&
      this.seniorityLevel && this.seniorityLevel >= 2 && this.current.state !== RequestState.USED;
  }

  isZweentryOrdinaryCatC() {
    return this.current.metadata && this.current && this.current.metadata.PassportCountry !== 'ZWE' && this.current.state === RequestState.PAID && this.request.CatC.some(n => n.value === this.current.metadata.Nationality) && this.current.operationId === DocumentType.ZWEENTRY && this.current.metadata.PassportType === PassportType.ORDINARY;
  }

  checkIfSpecifiedNationals(): boolean {
    const currentPlace = this.api.userPlaces?.find(place => place.id === this.api.userPlaceId);
    return currentPlace && currentPlace.specifiedNationals.includes(this.scanDocument.scanDatas?.fields?.nationality || this.current.metadata?.Nationality);
  }

  paymentAdditional() {
    this.isSyncing = this.cart.syncQueue.some(item => item.paymentId === this.paymentId) || this.queue.requestInQueue(this.paymentId);
    this.isCatAB = this.current.countryCat === CountryCat.A || this.current.countryCat === CountryCat.B;
    this.detectConsumeMode();
    this.changeConsumeSubscription = this.scanDocument.consumeMode$.subscribe(() => {
      this.detectConsumeMode();
    });

    if(this.scanDocument.scanned && this.scanDocument.scanDatas && this.current.metadata?.PassportNumber && this.current.metadata?.Nationality) {
      const {nationality, issuingState} = this.scanDocument.scanDatas.fields || {};
      this.current.canBeDeported = this.scanDocument.isZweRefugee(nationality, issuingState);
      this.current.showComparison = this.scanDocument.scanDatas.fields?.documentNumber === this.current.metadata?.PassportNumber && this.scanDocument.scanDatas.fields?.nationality === this.current.metadata?.Nationality;
    } else {
      this.current.showComparison = this.api.hasPerm(UserPermission.ALLOW_CONSUME) || this.api.hasPerm(UserPermission.ALLOW_ADMIN);
    }

    this.printVisaApplication = [];
    this.preparePrintVisa();

    if(this.current.allProcessed) {
      this.qr.setCurrentQrData(undefined, this.current);
      this.preparePrintPersonalData();
      this.prepareContactDataPrint();
      this.preparePrintDiversVisa();
      this.preparePrintNextOfKin();
      this.preparePrintExtension();
      this.preparePrintSpouse();
      this.preparePrintReceipt();
      this.preparePrintBorderPass();
      this.preparePrintNotices();
      this.prepareVisualDocuments();

      const warningMessage = this.getWarningMessage();
      if(warningMessage) {
        this.loader.loading(true, {type: 'warn', hideCloseBtn: true, btnLabel: 'payAttention', custom: {innerHtml: warningMessage}}).then(() => {
          this.checkScannedGender();
        });
      } else if(this.current.isBlacklisted) {
        this.loader.loading(true, {type: 'warn', message: 'visa.blacklist'}).then(() => {
          this.checkScannedGender();
        });
      } else if(this.isZweentryOrdinaryCatC()) {
        this.loader.loading(true, {type: 'warn', custom: {innerHtml: this.lang.transform('visa.catC.ordinary')}}).then(() => {
          this.checkScannedGender();
        });
      } else {
        this.checkScannedGender();
      }
    }
  }

  rejectOrValidatePayment(refuse: boolean) {
    const dialogReasonInput = [{
      formCtrlName: 'reason',
      type: 'textarea',
      label: 'payment.reject_reason',
      maxLength: 200,
      validator: refuse ? [Validators.required] : []
    }] as DialogFormInput[];

    if(refuse) {
      this.loader.loading(true, {type: 'warn', message: 'payment.reject_desc', dialogInput: dialogReasonInput}).then((res: any) => {
        if(res?.reason) {
          this.loader.loading(true);
          this.api.validatePayment(this.current.id, this.api.userPlaceId, refuse, res.reason).then(() => {
            this.nav.to('admin-request');
          }).catch((err) => {
            this.loader.loading(true, {type: 'error', message: err});
          });
        }
      });
    } else {
      this.loader.loading(true, {type: 'info', message: 'payment.validate_desc', btnLabel: 'global.confirm'}).then((done) => {
        if(done) {
          this.loader.loading(true);
          this.api.validatePayment(this.current.id, this.api.userPlaceId, refuse, '').then(() => {
            this.nav.to('admin-request');
          }).catch((err) => {
            this.loader.loading(true, {type: 'error', message: err});
          });
        }
      });
    }
  }

  private roundPrice(price: number): number {
    if(price < 100) {
      return Math.round(price / 5) * 5;
    } else {
      return Math.round(price / 10) * 10;
    }
  }

  private getRatesText(customAmount?: number) {
    const serverRates = this.api.userInfo.rates.rates;
    const authorizedCurrencies = this.api.env.supportedCurrencies;
    const availableRates: {currency: string, price: number}[] = Object.keys(serverRates).filter(currency => authorizedCurrencies.includes(currency)).map(currency => (
      {currency, price: this.roundPrice(((customAmount || this.current.amountCents) / 100) * serverRates[currency])}
    ));
    return '<b></br></br>'+availableRates.map(rate => `<h2>${rate.currency} ${rate.price}</h2>`).join('')+'</b>';
  }

  consumePayment(refuse = false, useFingerprint = false, useNoticePrint = false, supervisor?: string, passSupervision = false, skipCamera = false, skipCameraDialogDone = false, deportedComment?: string, forceOperation?: OperationType) {
    const checkoutIsNextOrForce = this.current.checkoutIsNext || forceOperation === OperationType.OUT;
    if(skipCamera && !skipCameraDialogDone && !refuse) {
      const dialogInput = this.deportedMode ? [{
        formCtrlName: 'deportedComment',
        type: 'textarea',
        label: 'deportedComment',
        maxLength: 1000
      }] as DialogFormInput[] : [];
      this.loader.loading(true, {type: 'info', message: 'payment.consume.ask.DYN.' + this.api.env.type, btnLabel: 'global.confirm', dialogInput}).then((done: any) => {
        if(done) {
          this.consumePayment(refuse, useFingerprint, useNoticePrint, supervisor, passSupervision, skipCamera, true, done?.deportedComment);
        }
      });
      return;
    }

    if(!passSupervision && (this.current.isBlacklisted || (this.checkIfSpecifiedNationals() && this.current.metadata.PassportType === PassportType.ORDINARY))) {
      let supervisionAction = '';
      if(this.current.isBlacklisted) supervisionAction = 'supervision.blacklist';
      if(this.checkIfSpecifiedNationals()) supervisionAction = 'supervision.specifiedNationals';
      this.fingerprint.supervision(supervisionAction).then((supervisorAccountName: string) => {
        this.consumePayment(refuse, useFingerprint, useNoticePrint, supervisorAccountName, true);
      });
      return;
    }

    const cleanupSession = () => {
      window.sessionStorage.removeItem('noticePrint');
      window.sessionStorage.removeItem('fingerprint');
    };
    const consumeAction = (fingerprint = false) => {
      const navToFaceCheck = (paymentReference? : string, cashType?: RequestCashType, currency?: string, localUsageUntil?: Date) => {
        cleanupSession();
        this.nav.to(fingerprint ? 'admin-scan-fingerprint' : 'admin-request-face-check', undefined, {queryParams: {
          from: this.fromRoute,
          paymentId: this.current.id,
          userPlaceID: this.api.userPlaceId,
          amount: (checkoutIsNextOrForce && this.isLocalUsageUntilError()) ? this.request.priceOverstay : this.current.amountCents,
          nameClient: (this.current.metadata.FirstName && this.current.metadata.LastName) ? (this.current.metadata.FirstName + ' ' + this.current.metadata.LastName) : '',
          placeID: this.current.created_place_id,
          buyDocument: this.current.operationId,
          createdAt: this.current.createdAt,
          occupationMedia: this.current.metadata?.Occupation?.toLowerCase()?.includes('media'),
          provisionalPermit: this.current.operationId === DocumentType.ZWEPROVRESPERMIT,
          fromNoticePrint: false,
          borderPass: this.current.metadata.BorderPass ? this.current.metadata.BorderPass : undefined,
          fromScanFingerprint: fingerprint,
          operation: forceOperation ? forceOperation : ((this.deportedMode && !refuse) ? OperationType.INRETURNED : (this.current.checkoutIsNext || this.current.operationId === DocumentType.ZWEEXIT) ? refuse ? OperationType.REFUSEOUT : OperationType.OUT : refuse ? OperationType.REFUSEIN : OperationType.IN),
          paymentReference,
          paymentIsNotDocument: (checkoutIsNextOrForce && this.isLocalUsageUntilError()) ? 'Yes' : 'No',
          cashType,
          currency,
          localUsageUntil,
          purposeOfVisit: (this.current.metadata.Documents && this.current.metadata.Documents.PurposeOfVisit) ? this.current.metadata.Documents.PurposeOfVisit : undefined,
          skipCamera: skipCamera,
          refuseFingerPrint: useFingerprint && refuse,
          nationality: this.current.metadata.Nationality,
          gender: this.current.metadata.Gender,
          HQReference: this.current.internalReference,
          vendorName: this.api.userInfo.server.emailConfig.copyrightName,
          vendorAddress: this.api.userInfo.server.emailConfig.address,
          vendorEmail: this.api.userInfo.server.emailConfig.email,
          foundByScan: this.foundByScan,
          forceOperation: forceOperation,
          deportedComment: this.deportedMode ? (deportedComment ? deportedComment : skipCamera ? '' : undefined) : undefined,
          ...this.searchParams
        }});
      };
      const navToNoticePrint = (paymentReference?: string, cashType?: RequestCashType, currency?: string, notice?: NoticeType, localUsageUntil?: Date) => {
        cleanupSession();
        this.nav.to('admin-request-notice/' + this.current.id, undefined, {queryParams: {
          from: 'admin-request-select/' + this.current.id,
          fromRoute: this.fromRoute,
          paymentId: this.current.id,
          userPlaceID: this.api.userPlaceId,
          placeID: this.current.created_place_id,
          buyDocument: this.current.operationId,
          createdAt: this.current.createdAt,
          notice: notice,
          amount: (checkoutIsNextOrForce && this.isLocalUsageUntilError()) ? this.request.priceOverstay : this.current.amountCents,
          nameClient: (this.current.metadata.FirstName && this.current.metadata?.LastName) ? (this.current.metadata.FirstName + ' ' + this.current.metadata.LastName) : '',
          fromScanFingerprint: fingerprint,
          fromNoticePrint: true,
          borderPass: this.current.metadata.BorderPass ? this.current.metadata.BorderPass : undefined,
          operation: forceOperation ? forceOperation : ((this.deportedMode && !refuse) ? OperationType.INRETURNED : (this.current.checkoutIsNext || this.current.operationId === DocumentType.ZWEEXIT) ? refuse ? OperationType.REFUSEOUT : OperationType.OUT : refuse ? OperationType.REFUSEIN : OperationType.IN),
          paymentReference,
          paymentIsNotDocument: (checkoutIsNextOrForce && this.isLocalUsageUntilError()) ? 'Yes' : 'No',
          cashType,
          currency,
          localUsageUntil,
          skipCamera: skipCamera,
          purposeOfVisit: (this.current.metadata.Documents && this.current.metadata.Documents.PurposeOfVisit) ? this.current.metadata.Documents.PurposeOfVisit : undefined,
          nationality: this.current.metadata.Nationality,
          gender: this.current.metadata.Gender,
          HQReference: this.current.internalReference,
          vendorName: this.api.userInfo.server.emailConfig.copyrightName,
          vendorAddress: this.api.userInfo.server.emailConfig.address,
          vendorEmail: this.api.userInfo.server.emailConfig.email,
          forceOperation: forceOperation,
          foundByScan: this.foundByScan,
          ...this.searchParams
        }});
      };
      if(environment.type === DocumentType.ZWEVISA) {
        const consume = () => {
          if(refuse) {
            this.loader.loading(true, {type: 'warn', message: 'checkinOut.refuse', btnLabel: 'global.confirm'}).then((done) =>{
              if(done) {
                if(useFingerprint) {
                  navToFaceCheck();
                } else if(((this.request.getStatusTheme(this.current).actionState === ActionState.READY_CHECKIN ||
                  this.request.getStatusTheme(this.current).actionState === ActionState.READY_CHECK)) && this.current.operationId !== DocumentType.ZWEEXIT) {
                  navToNoticePrint(undefined, undefined, undefined, NoticeType.REFUSAL);
                } else {
                  navToFaceCheck();
                }
              }
            });
          } else {
            if(this.current.state === RequestState.DELAY_PAY) {
              if(this.current.operationId === DocumentType.ZWEENTRY && !(this.current.metadata.Documents && this.current.metadata.Documents.PurposeOfVisit === PurposeOfVisit.TRANSIT_VISA)) {
                if(useFingerprint || (this.request.getStatusTheme(this.current).actionState !== ActionState.READY_CHECKIN && this.request.getStatusTheme(this.current).actionState !== ActionState.READY_CHECK)) {
                  navToFaceCheck();
                } else {
                  if(this.current.metadata?.Occupation?.toLowerCase()?.includes('media')) {
                    navToNoticePrint(undefined, undefined, undefined, NoticeType.REPORTING);
                  } else {
                    if(useNoticePrint) {
                      navToNoticePrint();
                    } else {
                      navToFaceCheck();
                    }
                  }
                }
              } else if(this.current.operationId === DocumentType.ZWEPROVRESPERMIT) {
                navToNoticePrint(undefined, undefined, undefined, NoticeType.RESTRICTION);
              } else {
                const dialogInput = [
                  {
                    formCtrlName: 'paymentReference',
                    type: 'text',
                    label: 'paymentReference.ask',
                    maxLength: 60,
                    value: uuid.v1()
                  },
                  {
                    formCtrlName: 'currency',
                    type: 'select',
                    label: 'form.currency',
                    selectOptions: this.api.env.supportedCurrencies
                  }
                ] as DialogFormInput[];

                if(this.current.operationId !== DocumentType.ZWEEXIT) {
                  const cashTypeInput = {
                    formCtrlName: 'cashType',
                    type: 'select',
                    label: 'method.payment',
                    selectOptions: [
                      {name: 'form.type.CASH', value: RequestCashType.CASH},
                      {name: 'form.type.CARD', value: RequestCashType.CARD}
                    ]
                  };

                  const localUsageUntilInput = {
                    formCtrlName: 'localUsageUntil',
                    type: 'date',
                    label: 'usageUntil.ask',
                    value: this.scanDocument.scanDatas?.fields?.expirationDate && isBefore(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), addMilliseconds(new Date(), +this.current.localUsageUntilfromServer)) ? format(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), 'yyyy-MM-dd') : format(addMilliseconds(new Date(), +this.current.localUsageUntilfromServer), 'yyyy-MM-dd'),
                    date: {
                      dateShowPickerOnClick: true,
                      minDate: format(new Date() > addDays(new Date(this.current.usageAfter), 1) ? new Date() : addDays(new Date(this.current.usageAfter), 1), 'yyyy-MM-dd'),
                      maxDate: this.scanDocument.scanDatas?.fields?.expirationDate && isBefore(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), addMilliseconds(new Date(), +this.current.localUsageUntilfromServer)) ? format(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), 'yyyy-MM-dd') : format(addMilliseconds(new Date(), +this.current.localUsageUntilfromServer), 'yyyy-MM-dd'),
                      autoMinMaxDate: true
                    }
                  };
                  dialogInput.push(cashTypeInput as DialogFormInput);
                  dialogInput.push(localUsageUntilInput as DialogFormInput);
                }
                if(forceOperation === OperationType.OUT) {
                  return this.fingerprint.supervision('otherState.delayPay').then(() => {
                    navToFaceCheck();
                  });
                }
                this.loader.loading(true, {type: 'valid', btnLabel: 'global.confirm', custom: {innerHtml: (this.current.operationId === DocumentType.ZWEEXIT ? this.lang.transform('ask.isArrivalPaid.exit') : this.lang.transform('ask.isArrivalPaid')) + this.getRatesText(), icon: 'money-bill-wave'}}).then(done => {
                  if(done) {
                    this.loader.loading(true, {type: 'question', message: (this.current.operationId === DocumentType.ZWEEXIT ? 'ask.isArrivalPaid.confirm.exit' : 'ask.isArrivalPaid.confirm'), btnLabel: 'yes', custom: {closeBtnLabel: 'no'}, dialogInput}).then((res: any) => {
                      if(res && res.paymentReference && (res.cashType || this.current.operationId === DocumentType.ZWEEXIT) && res.currency) {
                        if(res.localUsageUntil && (new Date(res.localUsageUntil) < new Date() || new Date(res.localUsageUntil) > addMilliseconds(new Date(), +this.current.localUsageUntilfromServer))) {
                          return this.loader.loading(true, {type: 'error', message: 'error.bad.localUsageUntil'});
                        }
                        if(useFingerprint || (this.request.getStatusTheme(this.current).actionState !== ActionState.READY_CHECKIN && this.request.getStatusTheme(this.current).actionState !== ActionState.READY_CHECK && (this.current.metadata.BorderPass && this.current.metadata.BorderPass === 'No'))) {
                          navToFaceCheck(res.paymentReference, res.cashType, res.currency, res.localUsageUntil);
                        } else {
                          if(this.current.operationId === DocumentType.ZWEPROVRESPERMIT) {
                            navToNoticePrint(undefined, undefined, undefined, NoticeType.RESTRICTION, res.localUsageUntil);
                          } else if(this.current.metadata?.Occupation?.toLowerCase()?.includes('media')) {
                            navToNoticePrint(res.paymentReference, res.cashType, res.currency, NoticeType.REPORTING, res.localUsageUntil);
                          } else if(this.current.operationId === DocumentType.ZWEEXIT) {
                            const printBorderPass = () => {
                              this.printDoc(DocsPrintType.BORDER_PASS);

                              this.printSubscription = this.print.printLoaded.subscribe((loaded) => {
                                if(loaded) {
                                  this.loader.loading(true, {type: 'question', message: 'print.borderPass.ask', btnLabel: 'global.yes', custom: {closeBtnLabel: 'global.no.retry'}}).then((done: boolean) => {
                                    if(done) {
                                      if(useNoticePrint) {
                                        navToNoticePrint(res.paymentReference, RequestCashType.CASH, res.currency, NoticeType.REPORTING, res.localUsageUntil);
                                      } else {
                                        navToFaceCheck(res.paymentReference, RequestCashType.CASH, res.currency, res.localUsageUntil);
                                      }
                                    } else {
                                      this.printSubscription.unsubscribe();
                                      return printBorderPass();
                                    }
                                  });
                                }
                              });
                            };
                            return printBorderPass();
                          } else {
                            if(useNoticePrint) {
                              navToNoticePrint(res.paymentReference, res.cashType, res.currency, undefined, res.localUsageUntil);
                            } else {
                              navToFaceCheck(res.paymentReference, res.cashType, res.currency, res.localUsageUntil);
                            }
                          }
                        }
                      } else {
                        this.loader.loading(true, {type: 'error', message: 'error.payment.noPaymentReference'});
                      }
                    });
                  }
                });
              }
            } else {
              if(this.current?.chargedBackAt) {
                if(checkoutIsNextOrForce) {
                  this.loader.loading(true, {type: 'warn', message: 'client.extended.chargedBack'}).then(() => {
                    const dialogInput = [{
                      formCtrlName: 'paymentReferenceChargedBack',
                      type: 'text',
                      label: 'paymentReference.ask',
                      maxLength: 60,
                      value: uuid.v1()
                    }, {
                      formCtrlName: 'currency',
                      type: 'select',
                      label: 'form.currency',
                      selectOptions: this.api.env.supportedCurrencies
                    }] as DialogFormInput[];
                    this.loader.loading(true, {type: 'info', message: 'ask.chargedBack.confirm', btnLabel: 'global.confirm', custom: {closeBtnLabel: 'no'}, dialogInput}).then((res: any) => {
                      if(res?.paymentReferenceChargedBack && res?.currency) {
                        if(useFingerprint || (this.request.getStatusTheme(this.current).actionState !== ActionState.READY_CHECKIN && this.request.getStatusTheme(this.current).actionState !== ActionState.READY_CHECK && (forceOperation && forceOperation !== OperationType.IN))) {
                          navToFaceCheck(res.paymentReferenceChargedBack, RequestCashType.CASH, res.currency);
                        } else {
                          if(this.current.operationId === DocumentType.ZWEPROVRESPERMIT) {
                            navToNoticePrint(undefined, undefined, undefined, NoticeType.RESTRICTION);
                          } else if(this.current.metadata?.Occupation?.toLowerCase()?.includes('media')) {
                            navToNoticePrint(res.paymentReference, res.cashType, res.currency, NoticeType.REPORTING);
                          } else {
                            if(useNoticePrint) {
                              navToNoticePrint(res.paymentReferenceChargedBack, RequestCashType.CASH, res.currency);
                            } else {
                              navToFaceCheck(res.paymentReferenceChargedBack, RequestCashType.CASH, res.currency);
                            }
                          }
                        }
                      } else {
                        this.loader.loading(true, {type: 'error', message: 'error.payment.noReferenceChargedBack'});
                      }
                    });
                  });
                } else {
                  this.loader.loading(true, {type: 'warn', message: 'client.extended.entryChargedBack'});
                }
              } else {
                const leave = (navToNotice = false) => {
                  if(checkoutIsNextOrForce || !this.current.isDeclaration) {
                    if(navToNotice) {
                      navToNoticePrint(undefined, undefined, undefined, NoticeType.REPORTING);
                    } else {
                      navToFaceCheck();
                    }
                  } else {
                    const dialogInput = [{
                      formCtrlName: 'localUsageUntil',
                      type: 'date',
                      label: 'usageUntil.ask',
                      value: this.scanDocument.scanDatas?.fields?.expirationDate && isBefore(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), addMilliseconds(new Date(), +this.current.localUsageUntilfromServer)) ? format(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), 'yyyy-MM-dd') : format(addMilliseconds(new Date(), +this.current.localUsageUntilfromServer), 'yyyy-MM-dd'),
                      date: {
                        dateShowPickerOnClick: true,
                        minDate: format(new Date() > addDays(new Date(this.current.usageAfter), 1) ? new Date() : addDays(new Date(this.current.usageAfter), 1), 'yyyy-MM-dd'),
                        maxDate: this.scanDocument.scanDatas?.fields?.expirationDate && isBefore(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), addMilliseconds(new Date(), +this.current.localUsageUntilfromServer)) ? format(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), 'yyyy-MM-dd') : format(addMilliseconds(new Date(), +this.current.localUsageUntilfromServer), 'yyyy-MM-dd'),
                        autoMinMaxDate: true
                      }
                    }] as DialogFormInput[];
                    this.loader.loading(true, {type: 'info', message: 'ask.usageUntil.confirm', btnLabel: 'global.confirm', dialogInput}).then((res: any) => {
                      if(res?.localUsageUntil) {
                        if(new Date(res?.localUsageUntil) < new Date() || new Date(res?.localUsageUntil) > addMilliseconds(new Date(), +this.current.localUsageUntilfromServer)) {
                          return this.loader.loading(true, {type: 'error', message: 'error.bad.localUsageUntil'});
                        } else {
                          navToNotice ? navToNoticePrint(undefined, undefined, undefined, undefined, res.localUsageUntil) :  navToFaceCheck(undefined, undefined, undefined, res.localUsageUntil);
                        }
                      }
                    });
                  }
                };
                if(useFingerprint || (this.request.getStatusTheme(this.current).actionState !== ActionState.READY_CHECKIN && this.request.getStatusTheme(this.current).actionState !== ActionState.READY_CHECK && (forceOperation && forceOperation !== OperationType.IN))) {
                  leave(useNoticePrint);
                } else {
                  if(this.current.operationId === DocumentType.ZWEPROVRESPERMIT) {
                    navToNoticePrint(undefined, undefined, undefined, NoticeType.RESTRICTION);
                  } else if(this.current.metadata?.Occupation?.toLowerCase()?.includes('media')) {
                    navToNoticePrint(undefined, undefined, undefined, NoticeType.REPORTING);
                  } else {
                    if(useNoticePrint) {
                      if(this.current.isDeclaration) {
                        leave(true);
                      } else {
                        navToNoticePrint(undefined, undefined, undefined, this.current.operationId === DocumentType.ZWEEXIT ?  NoticeType.REPORTING : undefined);
                      }
                    } else {
                      leave();
                    }
                  }
                }
              }
            }
          }
        };
        if(!this.current.usageUntilValid) {
          if(this.request.getStatusTheme(this.current).actionState === ActionState.READY_CHECKIN || this.request.getStatusTheme(this.current).actionState === ActionState.READY_CHECK || forceOperation === OperationType.IN) {
            return this.loader.loading(true, {type: 'warn', custom: {innerHtml: this.lang.transform(this.isCatAB ? 'outUsage.AB' : 'outUsage.C')}});
          }
        }
        if(!this.current.localUsageUntilValid && (!(this.request.getStatusTheme(this.current).actionState === ActionState.READY_CHECKIN) && !(this.request.getStatusTheme(this.current).actionState === ActionState.READY_CHECK)) && !refuse) {
          const dialogInput = [{
            formCtrlName: 'paymentReference',
            type: 'text',
            label: 'paymentReference.askExpired',
            maxLength: 60,
            value: uuid.v1()
          },
          {
            formCtrlName: 'currency',
            type: 'select',
            label: 'form.currency',
            selectOptions: this.api.env.supportedCurrencies
          }] as DialogFormInput[];
          if(forceOperation === OperationType.IN) {
            return this.fingerprint.supervision('otherState.overStay').then(() => {
              navToFaceCheck();
            });
          }
          this.loader.loading(true, {type: 'warn', btnLabel: 'global.confirm', custom: {innerHtml: this.lang.transform('error.paymentUntil.expired') + this.getRatesText(this.request.priceOverstay)}}).then((done) => {
            if(done) {
              this.loader.loading(true, {type: 'info', message: 'ask.checkoutExpired.confirm', btnLabel: 'global.confirm', custom: {closeBtnLabel: 'no'}, dialogInput}).then((res: any) =>{
                if(res?.paymentReference && res?.currency) {
                  if(useNoticePrint) {
                    navToNoticePrint(res.paymentReference, RequestCashType.CASH, res.currency, NoticeType.REPORTING);
                  } else {
                    navToFaceCheck(res.paymentReference, RequestCashType.CASH, res.currency);
                  }
                } else {
                  this.loader.loading(true, {type: 'error', message: 'error.payment.noPaymentReference'});
                }
              });
            }
          });
        } else {
          if(!this.current.usageAfterValid) {
            this.loader.loading(true, {type: 'warn', message: this.lang.transform('error.paymentUntil.before', {dateAfter: format(this.current.usageAfter, 'dd/MM/yyyy HH:mm')} )});
          } else {
            consume();
          }
        }
      } else {
        this.loader.loading(true, {type: 'info', message: this.lang.transform('payment.consume.ask.DYN.' + this.api.env.type), btnLabel: this.lang.transform('global.confirm')}).then((done) =>{
          if(done) {
            this.loader.loading(true);
            const consumeData: ComsumePaymentData = this.current.operationId === DocumentType.PREENROLL ? {SupervisorAccountName: supervisor} : undefined;
            this.api.consumePayment(this.current.id, this.api.userPlaceId, new Date(), this.current.created_place_id, new Date(this.current.createdAt), undefined, consumeData).then(() => {
              this.loader.loading(false);
              this.loader.loading(true, {type: 'valid', message: this.lang.transform('payment.consume.done.DYN.' + this.api.env.type)}).then(() =>{
                this.getPayment();
              });
            }).catch(err => {
              this.loader.loading(true, {type: 'error', message: this.lang.translateError(err, 'client.extended.uuidUsed', 'err.client.extended.uuidUsed')});
            });
          }
        });
      }
    };

    if(this.api.isElectronApp && useFingerprint) {
      if(electron.addon.hasSystemFingerprint()) {
        consumeAction(true);
      } else {
        this.loader.loading(true, {type: 'warn', message: this.lang.transform('fingerprint.notAvailable')}).then(() => {
          consumeAction();
        });
      }
    } else {
      consumeAction();
    }
  }

  goToPreviousUrl() {
    if(this.fromRoute === 'scanQr') {
      this.nav.to('scan-qr');
    } else {
      this.router.navigateByUrl(localStorage.getItem('previous_url'));
    }
  }

  formatDate(date: string) {
    return format(parseISO(date), 'HH:mm dd/MM/yyyy');
  }

  toggleSelectMode(setBool?: boolean) {
    if(setBool !== undefined) {
      this.selectMode = setBool;
    } else {
      this.selectMode = !this.selectMode;
    }
    if(this.selectMode) {
      this.loader.loading(true, {type: 'info', message: this.lang.transform('payment.selectMode')});
    } else {
      this.selectedFields = [];
    }
  }

  suspendPayment() {
    if(!this.selectedFields.length) {
      this.toggleSelectMode(true);
      return;
    }
    const dialogInput = [{
      formCtrlName: 'refusalReason',
      type: 'textarea',
      label: 'refusal.desc.DYN.' + this.api.env.type,
      maxLength: 200
    }] as DialogFormInput[];

    this.loader.loading(true, {type: 'warn', message: 'suspendReason.desc', dialogInput: dialogInput, shortcut: {btn: {action: ' ' as ShortcutAction}}}).then((res: any) => {
      if(res?.refusalReason) {
        this.loader.loading(true);
        return this.api.suspendPayment(this.current?.id, true, res.refusalReason, this.selectedFields, this.api.userPlaceId).then(() => {
          this.loader.loading(false);
          this.nav.to('admin-request');
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: ('error.payment.suspend.error.DYN.' + this.api.env.type)});
        });
      } else {
        this.loader.loading(true, {type: 'error', message: 'error.payment.noSuspendReason'});
      }
    });
  }

  validateOrRefusePayment(refuse = false, review = false) {
    if(this.api.env.type === DocumentType.PASS) {
      return this.rejectOrValidatePayment(refuse);
    }
    this.toggleSelectMode(false);
    const dialogInput: DialogFormInput[] = [
      {
        formCtrlName: 'refusalReason',
        type: 'textarea',
        label: (review ?
          (refuse ?
            (this.current.isExtension ? 'review.refusal.desc.extension.DYN.' : 'review.refusal.desc.DYN.')
            : (this.current.isExtension ? 'review.approval.desc.extension.DYN.' : 'review.approval.desc.DYN.'))
          :  refuse ?
            (this.current.isExtension ? 'refusal.desc.extension.DYN.' : 'refusal.desc.DYN.')
            : (this.current.isExtension ? 'approval.desc.extension.DYN.' : 'approval.desc.DYN.')) + this.api.env.type,
        maxLength: 200,
        validator: refuse ? [Validators.required] : []
      }
    ];

    if(!this.current.isExtension && !review && !refuse) {
      dialogInput.push({
        formCtrlName: 'usageUntil',
        type: 'date',
        label: 'approval.usageUntil.DYN.' + this.api.env.type,
        value: format(new Date(this.current.usageUntil), 'yyyy-MM-dd'),
        date: {
          dateShowPickerOnClick: true,
          minDate: format(addDays(new Date(this.current.usageAfter), 1), 'yyyy-MM-dd'),
          maxDate: format(new Date(this.current.usageUntil), 'yyyy-MM-dd'),
          autoMinMaxDate: true
        }
      });
    }

    if(this.current.isExtension && !refuse && this.current.metadata.DaysRequested) {
      dialogInput.push({
        formCtrlName: 'daysGranted',
        type: 'number',
        label: 'approval.daysGranted.DYN.' + this.api.env.type,
        value: this.current.metadata.DaysRequested
      });
    }

    const loadingMessage = review ? (refuse ? 'refusalReason.review.desc.DYN.' : 'approveReason.review.desc.DYN.') : refuse ? (this.current.isExtension ? 'refusalReason.desc.extension.DYN.' : 'refusalReason.desc.DYN.') : (this.current.isExtension ? 'payment.validate.extension.ask.DYN.' : 'payment.validate.ask.DYN.');
    this.loader.loading(true, {type: 'info', dialogInput: dialogInput, custom: {innerHtml: this.lang.transform(loadingMessage + this.api.env.type)}}).then((res: any) => {
      if(this.current.isExtension && !refuse && this.current.metadata.DaysRequested && res?.daysGranted > this.current.metadata.DaysRequested) {
        return this.loader.loading(true, {type: 'error', message: this.lang.transform('approval.daysGranted.limit', {days: this.current.metadata.DaysRequested})});
      }

      if(new Date(res?.usageUntil) < new Date(addDays(this.current.usageAfter, 1))) {
        return this.loader.loading(true, {type: 'error', message: 'client.extended.minUsageNotMet'});
      }
      if((refuse && res?.refusalReason) || !refuse) {
        const confirmationMessage = refuse
          ? (this.current.isExtension ? 'payment.review.refuse.ask.extension.DYN.' : 'payment.review.refuse.ask.DYN.')
          : (this.current.isExtension ? 'payment.review.approve.extension.ask.DYN.' : 'payment.review.approve.ask.DYN.');

        if(review) {
          this.loader.loading(true, {
            type: 'info',
            custom: {innerHtml: this.lang.transform(confirmationMessage + this.api.env.type)},
            btnLabel: this.lang.transform('global.confirm')
          }).then((done) => {
            if(done) {
              this.validatePaymentAndNavigate(this.current?.id, this.api.userPlaceId, refuse, res.refusalReason, res?.usageUntil, res?.daysGranted);
            }
          });
        } else {
          this.validatePaymentAndNavigate(this.current?.id, this.api.userPlaceId, refuse, res.refusalReason, res?.usageUntil, res?.daysGranted);
        }
      } else {
        this.loader.loading(true, {type: 'error', custom: {innerHtml: this.lang.transform('error.payment.noRefuseReason')}});
      }
    });
  }

  validatePaymentAndNavigate(paymentId: string, userPlaceId: string, refuse: boolean, refusalReason: string, usageUntil?: Date, daysGranted?: number) {
    this.loader.loading(true);
    this.api.validatePayment(paymentId, userPlaceId, refuse, refusalReason, usageUntil ? new Date(usageUntil) : undefined).then(() => {
      if(this.current.isExtension && !refuse) {
        this.api.consumePayment(paymentId, userPlaceId, new Date(), this.current.created_place_id, new Date(this.current.createdAt), undefined, {DaysGranted: Number(daysGranted)}).then(() => {
          setTimeout(() => {
            this.storage.removeFromStorage(Store.PAYMENT_STORE, this.paymentId);
            this.nav.to(this.current.metadata.VisaReference ? 'admin-request-select/' + this.current.metadata.VisaReference : 'admin-request');
          }, 1000);
        }).catch((err) => {
          this.loader.loading(true, {type: 'error', message: err});
        });
      } else {
        this.nav.to('admin-request');
      }
    }).catch((err) => {
      this.loader.loading(true, {
        type: 'error',
        custom: {innerHtml: this.lang.transform(err || ('error.approveRefuse.DYN.' + this.api.env.type))}
      });
    });
  }

  visualFile(file?:string, name?: string) {
    this.showDocuments = file ? [{nameDocument: name, document: file, title: name}] : this.metadataDocuments;
    this.visualizerIndex = name;
    if(this.showDocuments.length) this.openDialogDocViewer();
  }

  openDialogDocViewer() {
    this.dialogs.openDialog(DialogDocViewerComponent, 'full', {
      documentsInput: this.showDocuments,
      beginIndex: this.visualizerIndex
    }, false, undefined, true).then(() => {
      this.showDocuments = [];
      this.visualizerIndex = '';
    });
  }

  duplicatePayment() {
    if(this.current?.state === 1) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('payment.duplicate.ask'), btnLabel: this.lang.transform('global.confirm')}).then((done) =>{
        if(done) {
          this.loader.loading(true);
          this.api.duplicatePayment(this.current.id, this.api.userPlaceId).then((res: any) => {
            this.loader.loading(false);
            this.loader.loading(true, {type: 'info', message: this.lang.transform('payment.duplicate.done'), btnLabel: this.lang.transform('payment.duplicate.open')}).then((done) => {
              if(done) {
                this.nav.to('admin-request-select/' + res.result);
              } else {
                this.getPayment();
              }
            });
          }).catch((err) => {
            this.loader.loading(true, {type: 'error', message: err});
          });
        }
      });
    } else {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('payment.paid.error')});
    }
  }

  refundPayment() {
    if(this.current?.state === 1) {
      if(!this.current.refunded_place_id) {
        if(this.api.userRole.isCustomer) {
          this.loader.loading(true, {type: 'warn', message: this.lang.transform('payment.refund.ask'), btnLabel: this.lang.transform('global.confirm')}).then((done) =>{
            if(done) {
              this.loader.loading(true);
              this.api.selfRefundPayment(this.current.id).then(() => {
                this.loader.loading(false);
                this.loader.loading(true, {type: 'info', message: this.lang.transform('payment.refund.done')}).then(() =>{
                  this.getPayment();
                });
              }).catch((err) => {
                this.loader.loading(true, {type: 'error', message: err});
              });
            }
          });
        } else {
          this.loader.loading(true, {type: 'warn', message: this.lang.transform('payment.refund.ask'), btnLabel: this.lang.transform('global.confirm')}).then((done) =>{
            if(done) {
              this.loader.loading(true);
              this.api.refundPayment(this.current.id, this.api.userPlaceId).then(() => {
                this.loader.loading(false);
                this.loader.loading(true, {type: 'info', message: this.lang.transform('payment.refund.done')}).then(() =>{
                  this.nav.to('admin-request');
                });
              }).catch((err) => {
                this.loader.loading(true, {type: 'error', message: err});
              });
            }
          });
        }
      } else {
        this.loader.loading(true, {type: 'error', message: this.lang.transform('payment.refund.exist')});
      }
    } else {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('payment.paid.error')});
    }
  }

  goToDuplicata() {
    this.nav.to('admin-request-select/' + this.current?.duplicatas[0].id);
  }

  getAmount(currency: keyof typeof CurrencySign, ammount: number, millis = true) : string{
    return CurrencySign[currency] + (millis ? ammount / 100 : ammount);
  }

  getPaymentType(currency: keyof typeof RequestCashType) : string{
    return RequestCashType[currency];
  }

  setForm() {
    this.form = this.formBuilder.group({
      deportedMode: ['', []]
    });
  }
  protected readonly environment = environment;
  protected readonly DocumentType = DocumentType;
  protected readonly VisaFN = VisaFN;

  checkSelect($event: Event, control: VisaFN, noPointer?: boolean) {
    noPointer && $event.preventDefault();
    $event.stopPropagation();
    this.selectedFields = ($event.target as any).checked ? [...this.selectedFields, control] : this.selectedFields.filter(c => c !== control);
  }

  canBeValidated(approveRefuseButtons= false) {
    const eventOfPayment = this.current.events.length;
    if(approveRefuseButtons) {
      if(this.api.hasPerm(UserPermission.ALLOW_ADMIN)) {
        if(this.current.state === RequestState.DELAY_PAY || this.current.state === RequestState.PAID) {
          return true;
        }
        return eventOfPayment > 2;
      } else {
        return false;
      }
    } else {
      if(this.api.hasPerm(UserPermission.ALLOW_ADMIN)) {
        return this.current.actualManualConfirmRounds > eventOfPayment && !approveRefuseButtons;
      } else {
        if(this.api.hasPerm(UserPermission.ALLOW_CONTROL)) {
          if(this.current.events.find(payment => payment.actor_id === this.api.userInfo.id)) {
            return false;
          } else {
            return this.current.actualManualConfirmRounds > eventOfPayment;
          }
        } else {
          return false;
        }
      }
    }
  }

  reviewPayment() {
    if(!this.current?.internalReference && !this.current.isExtension) {
      this.loader.loading(true, {type: 'warn', message: 'error.payment.noInternalReference'});
      return;
    }

    const dialogInput = [{
      formCtrlName: 'approveRefuse',
      type: 'select',
      label: 'recommendOrNot',
      selectOptions: [{
        name: 'recommended',
        value: 0
      },
      {
        name: 'not_recommended',
        value: 1
      }]
    }] as DialogFormInput[];
    this.loader.loading(true, {type: 'question', custom: {innerHtml: this.lang.transform((this.current.isExtension ? 'ask.review.extension.' : 'ask.review.') + this.current.subState)}, buttonType: 'button', dialogInput: dialogInput}).then((res: any) => {
      if(res) {
        if(!res?.approveRefuse) {
          this.loader.loading(true, {type: 'error', message: 'error.review.noChoice'});
        } else {
          if(+res?.approveRefuse === 0) {
            this.validateOrRefusePayment(undefined, true);
          } else if(+res?.approveRefuse === 1) {
            this.validateOrRefusePayment(true, true);
          }
        }
      }
    });
  }

  JSONparse(data: string) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return false;
    }
  }

  isEvent() {
    return this.current?.events.some(event => event.type === PlatformEventType.PAYMENT_VALIDATED ||
      event.type === PlatformEventType.PAYMENT_REFUSED ||
      (event.type === PlatformEventType.PAYMENT_UPDATED && isJsonParsable(event.context)?.newOperation) ||
      (event.type === PlatformEventType.PAYMENT_UPDATED && !event.place_id) ||
      event.type === PlatformEventType.PAYMENT_SUSPEND_CHANGE);
  }

  isSupervisionEvent() {
    return this.current?.events.some(event => event.type === PlatformEventType.PAYMENT_USED && event.consumeData?.Operation);
  }

  printDoc(docName: AllDocumentsPrintType) {
    switch(docName) {
    case DocsPrintType.APPLICATION:
      const applicationData: PrintApplicationData = {
        title: this.lang.transform(((this.current.operationId === DocumentType.ZWEVISAEXTAB || this.current.operationId === DocumentType.ZWEVISAEXTC) ? 'titlePrint.extension' :'titlePrint'), {paymentID: this.current.id}),
        dataList: this.printVisaApplication
      };
      const printAll = () => {
        this.loader.loading(true);
        mergeDocuments(this.metadataDocuments)
          .then((mergedPdfBytes) => {
            const blob = new Blob([mergedPdfBytes], {type: 'application/pdf'});
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
            this.api.lockPayment(this.current.id, undefined, true, '', this.api.userPlaceId).then().catch((err) => console.log(err));
            this.print.print<PrintApplicationData>(DocsPrintType.APPLICATION, applicationData);
            this.loader.loading(false);
          }).catch(() => {
            this.loader.loading(false);
          });
      };

      if(this.metadataDocuments.length) {
        this.loader.loading(true, {
          type: 'info',
          message: 'print.askMultiple',
          btnLabel: 'yes',
          custom: {icon: 'print', closeBtnLabel: 'no'}
        }).then((done: boolean) => {
          if(done) {
            if(!this.api.isElectronApp && this.getPrintEvents().length) {
              const lastPrint: PlatformEvent = this.getPrintEvents().sort((a, b) => a.updatedOn.localeCompare(b.updatedOn)).pop();
              this.loader.loading(true, {type: 'warn', message: this.lang.transform('print.alreadyPrinted', {date: format(lastPrint.updatedOn, 'dd/MM/yyyy HH:mm'), user: this.sync.userDisplay(lastPrint.actor_id)+' #'+lastPrint.actor_id}), btnLabel: 'yes', custom: {closeBtnLabel: 'no'}}).then((done: boolean) => {
                if(done) {
                  printAll();
                } else {
                  this.print.print<PrintApplicationData>(DocsPrintType.APPLICATION, applicationData);
                }
              });
            } else {
              printAll();
            }
          } else {
            this.print.print<PrintApplicationData>(DocsPrintType.APPLICATION, applicationData);
          }
        });
      } else {
        this.print.print<PrintApplicationData>(DocsPrintType.APPLICATION, applicationData);
      }
      break;

    case DocsPrintType.APPROVAL_LETTER:
      this.print.print<RequestWithDuplicata>(DocsPrintType.APPROVAL_LETTER, this.current);
      break;

    case DocsPrintType.RECEIPT:
      const print = (receipt: PrintReceiptData) => {
        const receiptData: PrintReceiptData = {...receipt, qrCode: this.current.qrCode};
        const printReceiptThermal = () => this.request.printReceipt(receipt).then(() => {
          this.loader.loading(false);
        }).catch(([error]: [string]) => {
          this.loader.loading(true, {type: 'warn', message: error, btnLabel: 'global.print'}).then((done) => done && this.print.print<PrintReceiptData>(DocsPrintType.RECEIPT, receiptData));
        });
        const storedValue = isJsonParsable(localStorage.getItem('printThermal'));
        const printThermal = storedValue !== null ? storedValue : true;
        if(this.api.isElectronApp && printThermal) {
          return printReceiptThermal();
        } else {
          this.print.print<PrintReceiptData>(DocsPrintType.RECEIPT, receiptData);
        }
      };
      if(this.printReceiptData.length > 0) {
        if(this.printReceiptData.length === 1) {
          const receipt = this.printReceiptData[0];
          this.loader.loading(true, {
            type: 'info',
            btnLabel: 'global.yes',
            custom: {
              innerHtml: this.lang.transform('ask.printReceipt', {date: format(receipt.datePrint, 'dd/MM/yyyy HH:mm')}),
              icon: 'print'
            }
          }).then((res: boolean) => {
            if(res) return print(receipt);
          });
        } else {
          const dialogInput = [
            {
              formCtrlName: 'selectedReceipt',
              type: 'select',
              label: 'select.receipt',
              selectOptions: this.printReceiptData.map((receipt, index) => ({
                name: `${this.lang.transform('operation.' + receipt.operation)} - ${format(receipt.datePrint, 'dd/MM/yyyy HH:mm')}`,
                value: index
              }))
            }
          ] as DialogFormInput[];
          this.loader.loading(true, {
            type: 'question',
            message: 'ask.selectReceipt',
            btnLabel: 'global.confirm',
            custom: {
              closeBtnLabel: 'global.cancel',
              icon: 'print'
            },
            dialogInput
          }).then((res: any) => {
            if(res?.selectedReceipt) {
              const selectedReceipt = this.printReceiptData[res.selectedReceipt];
              print(selectedReceipt);
            } else if(res === undefined) {
              this.loader.loading(true, {type: 'error', message: 'noChoice.selectedReceipt'});
            }
          });
        }
      }
      break;

    case DocsPrintType.STICKER:
      const stickerData: PrintStickerData = {
        name: (this.current.metadata?.FirstName + ' ' + this.current.metadata?.LastName).toUpperCase(),
        passportNumber: this.current.metadata?.PassportNumber?.toUpperCase(),
        dateOfEntry: this.current.usageAfter ? format(new Date(this.current.usageAfter), 'dd MMM yyyy', {locale: enGB}).toUpperCase() : '',
        status: this.lang.transform('request.state.DYN.' + this.api.env.type).toUpperCase(),
        until: this.current.localUsageUntil ? format(new Date(this.current.localUsageUntil), 'dd MMM yyyy', {locale: enGB}).toUpperCase() : '',
        place: this.currentPlace.longName.toUpperCase(),
        qrCode: this.current.qrCode
      } as PrintStickerData;
      electron.printLabel(`CT~~CD,~CC^~CT~
^XA
~TA000
~JSN
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR4,4
~SD22
^JUS
^LRN
^CI27
^PA0,1,1,0
^XZ
^XA
^MMT
^PW320
^LL320
^LS0
^FT81,15^A0N,14,15^FH\\^CI28^FDREPUBLIC OF ZIMBABWE^FS^CI27
^FT53,31^A0N,14,15^FH\\^CI28^FDZIMBABWE IMMIGRATION SERVICE^FS^CI27
^FT81,47^A0N,14,15^FH\\^CI28^FD[ ENTRY CONFIRMATION ]^FS^CI27
^FO10,52^GB297,0,2^FS
^FT10,69^A0N,14,15^FH\\^CI28^FDNAME^FS^CI27
^FT10,86^A0N,17,18^FH\\^CI28^FD${stickerData.name}^FS^CI27
^FT10,103^A0N,17,18^FH\\^CI28^FD${' '}^FS^CI27
^FT10,121^A0N,14,15^FH\\^CI28^FDPASSPORT NO^FS^CI27
^FT10,139^A0N,17,18^FH\\^CI28^FD${stickerData.passportNumber}^FS^CI27
^FT10,160^A0N,14,15^FH\\^CI28^FDDATE OF ENTRY^FS^CI27
^FT10,176^A0N,17,18^FH\\^CI28^FD${stickerData.dateOfEntry}^FS^CI27
^FT10,198^A0N,14,15^FH\\^CI28^FDSTATUS^FS^CI27
^FT10,216^A0N,17,18^FH\\^CI28^FD${stickerData.status}^FS^CI27
^FT10,232^A0N,17,18^FH\\^CI28^FD${' '}^FS^CI27
^FT10,248^A0N,14,15^FH\\^CI28^FDUNTIL^FS^CI27
^FT10,265^A0N,17,18^FH\\^CI28^FD${stickerData.until}^FS^CI27
^FT10,296^A0N,17,18^FH\\^CI28^FD${stickerData.place}^FS^CI27
^FT145,325^BQN,2,5
^FH\\^FDLA,${this.current.qrCode}^FS
^PQ1,0,1,Y
^XZ
`);
      break;

    case DocsPrintType.BORDER_PASS:
      this.print.print<PrintBorderpassData>(DocsPrintType.BORDER_PASS, this.printBorderPassData);
      break;
    }
  }

  getReviewNr(event: PlatformEvent) {
    let events = this.current?.events.filter(e => e.type === PlatformEventType.PAYMENT_VALIDATED || e.type === PlatformEventType.PAYMENT_REFUSED);
    if(events && event.type === PlatformEventType.PAYMENT_VALIDATED || event.type === PlatformEventType.PAYMENT_REFUSED) {
      events.sort((a, b) => a.updatedOn.localeCompare(b.updatedOn));
      if(events.length > 2) {
        if(event.id === events[events.length - 1].id) {
          return this.lang.transform('review.nr.final') + ': ';
        } else {
          events = events.slice(0, 2);
          return events.indexOf(event) > -1 ? this.lang.transform('review.nr') + (events.indexOf(event) + 1)+ ': ' : '';
        }
      } else {
        return this.lang.transform('review.nr') + (events.indexOf(event) + 1)+ ': ';
      }
    }
  }
  goBack() {
    if(this.fromRoute !== 'admin-request' && !this.fromRoute?.includes('admin-request-select')) {
      this.fromRoute ? this.nav.to(this.fromRoute) : this.nav.to('admin-request');
    } else {
      this.nav.to('admin-request', undefined, {queryParams: this.searchParams});
    }

  }

  canBeReviewed() {
    if(this.api.hasPerm(UserPermission.ALLOW_CONTROL) && (this.current.state === RequestState.PAID_NOT_CONFIRMED || this.current.state === RequestState.DELAY_PAY_NOT_CONFIRMED)) {
      if(this.current.operationId === DocumentType.ZWEVISAEXTAB) {
        return false;
      }
      if(this.current.events.find(payment => (this.seniorityLevel !== 3 && payment.actor_id === this.api.userInfo.id) && (payment.type === PlatformEventType.PAYMENT_VALIDATED || payment.type === PlatformEventType.PAYMENT_REFUSED))) {
        return false;
      } else {
        const subState = this.current.subState;
        if(subState === RequestSubState.REVISIONS_0 && (this.seniorityLevel === 1 || this.seniorityLevel === 3)) {
          return true;
        } else if((subState === RequestSubState.REVISIONS_1 && this.current.operationId !== DocumentType.ZWEVISAEXTC) && (this.seniorityLevel === 2 || this.seniorityLevel === 3)) {
          return true;
        }
        return false;
      }
    }
  }

  canBeAcceptedRefused(refuse = false) {
    if(this.api.hasPerm(UserPermission.ALLOW_CONTROL) && this.seniorityLevel === 3 && !this.selectMode) {
      const hasAlreadyDoneAction = this.current.events.find(payment => (this.seniorityLevel !== 3 &&  payment.actor_id === this.api.userInfo.id) && (payment.type === PlatformEventType.PAYMENT_VALIDATED || payment.type === PlatformEventType.PAYMENT_REFUSED || payment.type === PlatformEventType.PAYMENT_CREATED));
      if(this.current.state === RequestState.PAID && this.current.operationId !== DocumentType.ZWEEXIT) {
        return refuse ? !hasAlreadyDoneAction : false;
      } else if(this.current.state === RequestState.EXPIRED) {
        return refuse ? false : !hasAlreadyDoneAction;
      } else {
        if(this.current.state === RequestState.PAID_NOT_CONFIRMED || this.current.state === RequestState.DELAY_PAY_NOT_CONFIRMED) {
          if(this.current.operationId === DocumentType.ZWEVISAEXTAB || (this.current.operationId === DocumentType.ZWEVISAEXTC && this.current.subState === RequestSubState.REVISIONS_1)) {
            return true;
          }
          if(hasAlreadyDoneAction) {
            return false;
          }
          const subState = this.current.subState;
          return subState === RequestSubState.REVISIONS_2;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  canBeSuspended() {
    if(this.api.hasPerm(UserPermission.ALLOW_CONTROL)) {
      return this.current.state === RequestState.PAID_NOT_CONFIRMED || this.current.state === RequestState.DELAY_PAY_NOT_CONFIRMED;
    } else {
      return false;
    }
  }

  canBeConsumed(refused = false) {
    if(this.current.subState === RequestSubState.CLOSED) {
      return false;
    }
    if(this.isSyncing) {
      return false;
    }
    if(this.current.operationId === DocumentType.ZWEVISAEXTAB  || this.current.operationId === DocumentType.ZWEVISAEXTC ) {
      return false;
    }
    const hasPermissionAndValidState = this.api.hasPerm(UserPermission.ALLOW_CONSUME) &&
      (this.current.state === RequestState.DELAY_PAY || this.current.state === RequestState.PAID ||
        this.current?.state === RequestState.USED);
    if(this.current.subState === RequestSubState.REFUSED_IN || (this.current.subState === RequestSubState.REFUSED_OUT && refused)) {
      return false;
    }
    const isValidUsageCount = this.current?.usageCount <= this.current.document?.usageCount;
    const isNotExceedUsage = !(this.current?.usageCount >= this.current.document?.usageCount && this.current.isUsage);
    if(hasPermissionAndValidState && isValidUsageCount && isNotExceedUsage) {
      if(this.current.subState === RequestSubState.CHECKED_IN  && !this.current.document.multiUsage) {
        return false;
      }
      const eventOfPayment = this.current.events.length;
      return this.current.actualManualConfirmRounds <= eventOfPayment;
    }
    return false;
  }

  actionPrint(title: string, image: string) {
    this.printDocument.title = this.lang.transform(title);
    this.printDocument.date = format(new Date(), 'dd/MM/yyyy HH:mm');
    this.printDocument.id = this.current.id;
    this.printDocument.img = image;
    setTimeout(() => {
      window.print();
    }, 300);
    window.onafterprint = () => {
      this.printDocument.title = '';
      this.printDocument.img = '';
      this.printDocument.date = '';
      this.printDocument.id = '';
    };
  }

  canInternalReference() {
    return this.api.userRole.isAdmin || (this.api.userRole.isWorker && this.seniorityLevel === 3 && !this.selectMode && !this.current.isExtension && (this.current.state === RequestState.PAID_NOT_CONFIRMED || this.current.state === RequestState.DELAY_PAY_NOT_CONFIRMED || this.current.state === RequestState.PAID || this.current.state === RequestState.PENDING || this.current.state === RequestState.DELAY_PAY)) && this.current.state !== RequestState.PENDING;
  }

  preparePrintVisa() {
    const data: any = [];
    if(this.current?.operationId) {
      data.push([this.lang.transform('document.type.DYN.zwevisa'), this.lang.transform(this.current.operationId)]);
    }
    if(this.current?.usageAfter) {
      data.push([this.lang.transform('form.usageAfter'), format(this.current.usageAfter, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.current?.localUsageUntil) {
      data.push([this.lang.transform('form.localUsageUntil'), format(this.current.localUsageUntil, 'dd/MM/yyyy')]);
    }
    if(this.current?.amountCents) {
      data.push([this.lang.transform('form.amount'), this.getAmount(this.current.currency as any, this.current.amountCents)]);
    }
    if(this.current?.amountCents) {
      data.push([this.lang.transform('form.paymentType'), this.lang.transform(this.current.externalId ? 'payment.type.online' : 'payment.type.offline')]);
    }
    if(this.current?.state || this.current?.state === 0) {
      data.push([this.lang.transform('request.state.DYN.' + this.api.env.type), this.request.getStatusTheme(this.current).state]);
    }
    if(this.current?.batchId) {
      data.push([this.lang.transform('BatchId'), this.current.batchId]);
    }
    if(this.referencePaymentOnarrival) {
      data.push([this.lang.transform('paymentReference.ask'), this.referencePaymentOnarrival]);
    }

    if(this.current.metadata?.Urgent) {
      data.push([this.lang.transform('global.urgent'), this.current.metadata?.Urgent]);
    }
    if(this.current.metadata?.buyingOption && this.current.metadata?.buyingOption !== '{}' && this.current.metadata?.buyingOption !== 'Embassy fee') {
      data.push(['Buying Option', this.lang.transform('document.' + this.current.metadata.buyingOption)]);
    }
    if(this.current.metadata?.Documents?.PurposeOfVisit) {
      data.push([this.lang.transform('form.purposeOfVisit'), this.lang.transform('DYN.' + this.current.metadata.Documents.PurposeOfVisit)]);
    }
    const generalDataGroup: DataGroup = {
      subtitle: this.lang.transform('application.data'),
      data: data
    };
    if(data.length > 0) {
      this.printVisaApplication = [...this.printVisaApplication, generalDataGroup];
    }
  }

  preparePrintPersonalData() {
    const personalDataPrint: any = [];
    if(this.current.metadata?.FirstName || this.current.metadata?.firstName) {
      personalDataPrint.push([this.lang.transform('form.firstName'), this.current.metadata.FirstName || this.current.metadata.firstName]);
    }
    if(this.current.metadata?.LastName || this.current.metadata.lastName) {
      personalDataPrint.push([this.lang.transform('form.lastName'), this.current.metadata.LastName || this.current.metadata.lastName]);
    }
    if(this.current.metadata?.BirthDay) {
      personalDataPrint.push([this.lang.transform('form.birthDate'), format(this.current.metadata.BirthDay, 'dd/MM/yyyy')]);
    }
    if(this.current.metadata?.PassportNumber) {
      personalDataPrint.push([this.lang.transform('form.passportNumber'), this.current.metadata.PassportNumber]);
    }
    if(this.current.metadata?.DateOfIssue) {
      personalDataPrint.push([this.lang.transform('form.passportNumber.dateOfIssue'), format(this.current.metadata.DateOfIssue, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.current.metadata?.DateOfExpiry) {
      personalDataPrint.push([this.lang.transform('form.passportNumber.dateOfExpiry'), format(this.current.metadata.DateOfExpiry, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.current.metadata?.PlaceofIssue) {
      personalDataPrint.push([this.lang.transform('form.passportNumber.placeOfIssue'), this.current.metadata.PlaceofIssue]);
    }
    if(this.current.metadata?.PlaceOfBirth) {
      personalDataPrint.push([this.lang.transform('form.placeOfBirth'), this.current.metadata.PlaceOfBirth]);
    }
    if(this.current.metadata?.Gender) {
      personalDataPrint.push([this.lang.transform('form.gender'), this.lang.transform('applicant.gender.' + this.current.metadata.Gender)]);
    }
    if(this.current.metadata?.MaritalStatus) {
      personalDataPrint.push([this.lang.transform('form.marialStatus'), this.lang.transform('marital.status.' + this.current.metadata.MaritalStatus)]);
    }
    if(this.current.metadata?.Occupation) {
      personalDataPrint.push([this.lang.transform('form.profession'), this.current.metadata.Occupation]);
    }
    if(this.current.metadata?.Nationality) {
      personalDataPrint.push([this.lang.transform('form.nationality'), this.lang.transform('DYN.' + this.current.metadata.Nationality)]);
    }
    if(this.current.metadata?.idCard) {
      personalDataPrint.push([this.lang.transform('idcard.number'), this.current.metadata.idCard]);
    }

    const personalDataGroup: DataGroup = {
      subtitle: this.lang.transform('personal.data'),
      data: personalDataPrint
    };

    if(personalDataPrint.length > 0) {
      this.printVisaApplication = [...this.printVisaApplication, personalDataGroup];
    }
  }


  prepareContactDataPrint() {
    const contactDataPrint: any = [];
    if(this.current.metadata?.Email || this.current.metadata?.email) {
      contactDataPrint.push([this.lang.transform('form.email'), this.current.metadata.Email || this.current.metadata.email]);
    }
    if(this.current.metadata?.Phone || this.current.metadata?.phone) {
      contactDataPrint.push([this.lang.transform('form.phone'), this.current.metadata.Phone || this.current.metadata.phone]);
    }
    if(this.current.metadata?.AddressZimbabwe) {
      contactDataPrint.push([this.lang.transform('form.addressZimbabwe'), this.current.metadata.AddressZimbabwe]);
    }
    if(this.current.metadata?.HomeAddress) {
      contactDataPrint.push([this.lang.transform('form.residenceAdress'), this.current.metadata.HomeAddress]);
    }
    if(this.current.metadata?.HostName) {
      contactDataPrint.push([this.lang.transform('form.hostName'), this.current.metadata.HostName]);
    }
    const contactDataGroup: DataGroup = {
      subtitle: this.lang.transform('contact.data'),
      data: contactDataPrint
    };
    if(contactDataPrint.length > 0) {
      this.printVisaApplication= [...this.printVisaApplication, contactDataGroup];
    }

  }

  preparePrintDiversVisa() {
    const diverseDataPrint: any = [];
    if(this.current.metadata?.ArrivalDate) {
      diverseDataPrint.push([this.lang.transform('form.arrivalDate'), format(this.current.metadata.ArrivalDate, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.current.metadata?.DepartureDate) {
      diverseDataPrint.push([this.lang.transform('form.departureDate'), format(this.current.metadata.DepartureDate, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.current.metadata?.ReferencePreviousVisa) {
      diverseDataPrint.push([this.lang.transform('form.referencePreviousVisa'), this.current.metadata.ReferencePreviousVisa]);
    }
    if(this.current.metadata?.PreviousConvictionsZim) {
      diverseDataPrint.push([this.lang.transform('form.previousConvictionsZim'), this.current.metadata.PreviousConvictionsZim]);
    }
    if(this.current.metadata?.PreviousConvictionsHome) {
      diverseDataPrint.push([this.lang.transform('form.previousConvictionsHome'), this.current.metadata.PreviousConvictionsHome]);
    }
    if(this.current.metadata?.PurposeOfVisit) {
      diverseDataPrint.push([this.lang.transform('form.purposeOfVisit'), this.current.metadata.PurposeOfVisit]);
    }
    if(this.current?.createdAt) {
      diverseDataPrint.push([this.lang.transform('date.creation'), format(this.current.createdAt, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.current?.externalId) {
      diverseDataPrint.push([this.lang.transform('form.externalId'), this.current.externalId]);
    }
    if(this.currentPlace) {
      diverseDataPrint.push([this.lang.transform('form.created_place'), this.currentPlace.longName + ' [' + this.currentPlace.id + ']']);
    }
    if(this.placeConsumed) {
      diverseDataPrint.push([this.lang.transform('form.consumed_place'), this.placeConsumed.longName]);
    }
    if(this.current.metadata?.worker) {
      diverseDataPrint.push([this.lang.transform('form.userCreator'), this.sync.userDisplay(this.current.metadata.worker) +' #'+this.current.metadata.worker]);
    }
    if(this.current?.refunded_by_user__id) {
      diverseDataPrint.push([this.lang.transform('form.userRefund'), this.sync.userDisplay(this.current.refunded_by_user__id) +' #'+this.current.refunded_by_user__id]);
    }
    if(this.current?.used_by_user__id) {
      diverseDataPrint.push([this.lang.transform('form.userConsumed'), this.sync.userDisplay(this.current.used_by_user__id) +' #'+this.current.used_by_user__id]);
    }
    if(this.current.metadata?.FundsAvailable && this.current.metadata?.FundsAvailableCurrency) {
      const funds = this.current.metadata.FundsAvailable + ' ' + this.current.metadata.FundsAvailableCurrency + (this.current.metadata.FundsAvailableCurrency !== 'USD' ? (' ' + '(' + this.request.convertCurrency(this.current.metadata?.FundsAvailableCurrency, this.current.metadata?.FundsAvailable, 'USD')?.amount + ' ' + this.request.convertCurrency(this.current.metadata.FundsAvailableCurrency, this.current.metadata.FundsAvailable, 'USD')?.currency +')') : '');
      diverseDataPrint.push([this.lang.transform('FundsAvailable'), funds]);
    }
    const diverseDataGroup = {
      subtitle: this.lang.transform('divers.data.DYN.' + this.api.env.type),
      data: diverseDataPrint
    };
    if(diverseDataPrint.length > 0) {
      this.printVisaApplication = [...this.printVisaApplication, diverseDataGroup];
    }
  }

  preparePrintNextOfKin() {
    if(this.current.metadata.nextOfKin) {
      const nextOfKinData: any = [];
      const nextOfKin = this.JSONparse(this.current.metadata.NextOfKin);
      if(nextOfKin?.firstname) {
        nextOfKinData.push([this.lang.transform('form.firstName'), nextOfKin.firstname]);
      }
      if(nextOfKin?.lastname) {
        nextOfKinData.push([this.lang.transform('form.lastName'), nextOfKin.lastname]);
      }
      if(nextOfKin?.phone) {
        nextOfKinData.push([this.lang.transform('form.phone'), nextOfKin.phone]);
      }
      if(nextOfKin?.email) {
        nextOfKinData.push([this.lang.transform('form.email'), nextOfKin.email]);
      }
      const nextOfKinGroup = {
        subtitle: this.lang.transform('nextOfKin.data'),
        data: nextOfKinData
      };
      if(nextOfKinData.length > 0) {
        this.printVisaApplication = [...this.printVisaApplication, nextOfKinGroup];
      }
    }
  }

  preparePrintSpouse() {
    const spouseDataPrint: any = [];
    if(this.current.metadata?.SpouseFullName) {
      spouseDataPrint.push([this.lang.transform('form.spouseFullName'), this.current.metadata.SpouseFullName]);
    }
    if(this.current.metadata?.SpousePassportNumber) {
      spouseDataPrint.push([this.lang.transform('form.spousePassportNumber'), this.current.metadata.SpousePassportNumber]);
    }
    if(this.current.metadata?.SpouseBirthDay) {
      spouseDataPrint.push([this.lang.transform('form.spouse.birthDay'), format(this.current.metadata.SpouseBirthDay, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.current.metadata?.SpousePlaceOfBirth) {
      spouseDataPrint.push([this.lang.transform('form.spousePlaceOfBirth'), this.current.metadata.SpousePlaceOfBirth]);
    }
    const spouseDataGroup = {
      subtitle: this.lang.transform('spouse.data'),
      data: spouseDataPrint
    };
    if(spouseDataPrint.length > 0) {
      this.printVisaApplication = [...this.printVisaApplication, spouseDataGroup];
    }
  }

  preparePrintExtension() {
    const extensionDataPrint: any = [];
    if(this.current.metadata?.ExtensionReason) {
      extensionDataPrint.push([this.lang.transform('form.extensionReason'), this.current.metadata.ExtensionReason]);
    }
    if(this.current.metadata?.ExtensionDate) {
      extensionDataPrint.push([this.lang.transform('form.extensionDate'), format(this.current.metadata.ExtensionDate, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.current.metadata?.AttestantAddress) {
      extensionDataPrint.push([this.lang.transform('attestantAddress'), this.current.metadata.AttestantAddress]);
    }
    if(this.current.metadata?.ReasonForExtension) {
      extensionDataPrint.push([this.lang.transform('reasonForExtention'), this.current.metadata.ReasonForExtension]);
    }
    if(this.current.metadata?.AttestantEmail) {
      extensionDataPrint.push([this.lang.transform('attestantEmail'), this.current.metadata.AttestantEmail]);
    }
    if(this.current.metadata?.AttestantID) {
      extensionDataPrint.push([this.lang.transform('attestantID'), this.current.metadata.AttestantID]);
    }
    if(this.current.metadata?.AttestantName) {
      extensionDataPrint.push([this.lang.transform('attestantName'), this.current.metadata.AttestantName]);
    }
    if(this.current.metadata?.AttestantPhone) {
      extensionDataPrint.push([this.lang.transform('attestantPhone'), this.current.metadata.AttestantPhone]);
    }
    if(this.current.metadata?.AttestantRelation) {
      extensionDataPrint.push([this.lang.transform('attestantRelation'), this.current.metadata.AttestantRelation]);
    }
    if(this.current.metadata?.DaysRequested) {
      extensionDataPrint.push([this.lang.transform('daysRequested'), this.current.metadata.DaysRequested]);
    }

    const extensionDataGroup = {
      subtitle: this.lang.transform('extension.data'),
      data: extensionDataPrint
    };
    if(extensionDataPrint.length > 0) {
      this.printVisaApplication = [...this.printVisaApplication, extensionDataGroup];
    }
  }

  isSameData(scannedData: string, visaData: string): boolean {
    const scannedDate = new Date(scannedData);
    const visaDate = new Date(visaData);
    if(!isNaN(scannedDate.getTime()) && !isNaN(visaDate.getTime())) {
      return scannedDate.getTime() === visaDate.getTime();
    } else {
      return scannedData.toLowerCase() === visaData.toLowerCase();
    }
  }

  hasDifferences() {
    if(!this.scanDocument?.scanDatas || !this.current.metadata) {
      return false;
    }
    const scanFields = this.scanDocument.scanDatas.fields;
    const meta = this.current.metadata;
    const fieldsToCompare = [
      {scan: scanFields.firstName, meta: meta.FirstName || meta.firstName},
      {scan: scanFields.lastName, meta: meta.LastName || meta.lastName},
      {scan: scanFields.sex, meta: meta.Gender},
      {scan: format(convertToEpoch(scanFields.birthDate, false, true), 'dd/MM/yyyy'), meta: meta.BirthDay && format(meta.BirthDay, 'dd/MM/yyyy')},
      {scan: format(convertToEpoch(scanFields.expirationDate), 'dd/MM/yyyy'), meta: meta.DateOfExpiry && format(meta.DateOfExpiry, 'dd/MM/yyyy')},
      {scan: scanFields.nationality, meta: meta.Nationality}
    ];
    return fieldsToCompare.some(field => field.scan !== field.meta);
  }

  getFormattedExternalIndexedData(): string {
    return this.current?.externalIndexedData.replace(/\|/g, ', ');
  }

  captureFingerprint() {
    if(electron.addon.hasSystemFingerprint()) if(electron.addon.scanFingerprint()) electron.beep();
  }

  openGate() {
    electron.step('go', true, [
      this.scanDocument?.scanDatas ? this.scanDocument.scanDatas.fields.firstName + ' ' + this.scanDocument.scanDatas.fields.lastName : 'person',
      this.scanDocument?.documentPhoto ? this.scanDocument.documentPhoto : '::0'
    ]);
  }

  closeGate() {
    electron.step('scan', true, []);
  }

  navToNotice(queryParams?: any) {
    if(!this.current.usageAfterValid) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('error.paymentUntil.before', {dateAfter: format(this.current.usageAfter, 'dd/MM/yyyy HH:mm')} )});
    } else {
      this.nav.to('admin-request-notice/' + this.paymentId, undefined, {queryParams});
    }
  }

  getCategoriesElement(): string {
    if(!this.current?.watchlistsResponse?.matches.length) {
      return '';
    }
    return this.current?.watchlistsResponse?.matches
      .filter(match => match.watchlist.category)
      .map(match => match.watchlist.category)
      .join(', ');
  }

  handleClickLink(event: MouseEvent, url: string) {
    event.stopPropagation();
    window.open(url, '_blank');
  }

  preparePrintReceipt() {
    if(this.current && this.current.events) {
      const adaptEvents = [...this.current.events];
      const receiptEvents = adaptEvents.filter(event => {
        if(event && event.consumeData) {
          return event.consumeData.PaymentReference !== undefined;
        }
        return false;
      }).map(event => {
        const newEvent = {...event};
        return {
          referencePrint: newEvent.consumeData.PaymentReference,
          datePrint: new Date(newEvent.updatedOn),
          nameClient: (this.current.metadata ? (this.current.metadata.FirstName + ' ' + this.current.metadata.LastName) : ''),
          amount: (newEvent.consumeData.PaymentIsNotDocument  === 'Yes' && newEvent.consumeData.PaymentAmountCents) ? newEvent.consumeData.PaymentAmountCents : this.current.amountCents,
          currency: newEvent.consumeData.PaymentCurrency,
          agentId: newEvent.actor_id,
          typePayment: newEvent.consumeData.PaymentMode,
          onlinePayment: this.current.externalId ? 'online' : 'offline',
          placeId: newEvent.place_id,
          buyDocument: this.current.metadata.BorderPass === 'Yes' ? DocumentType.BORDER_PASS : this.current.operationId as DocumentType,
          operation: newEvent.consumeData.Operation,
          idRequest: this.current.id,
          nationality: this.current.metadata.Nationality,
          gender: this.current.metadata.Gender,
          HQReference: this.current.internalReference,
          vendorName: this.api.userInfo.server.emailConfig.copyrightName,
          vendorAddress: this.api.userInfo.server.emailConfig.address,
          vendorEmail: this.api.userInfo.server.emailConfig.email,
          serialNumber: newEvent.consumeData.PlaceSerial,
          placePhone: this.currentPlace?.phoneNumbersCsv || ''
        } as PrintReceiptData;
      });
      this.printReceiptData = receiptEvents;
    }
  }

  preparePrintBorderPass() {
    const eventPayBorderPass = this.current.events.find(event => event.consumeData && event.consumeData.Operation === OperationType.OUT && this.current.metadata.BorderPass === 'Yes');
    this.printBorderPassData = {
      borderPassNumber: this.current.metadata.BorderPassNumber,
      fullNames: this.current.metadata.FirstName + ' ' + this.current.metadata.LastName,
      dateOfBirth: this.current.metadata.BirthDay,
      residentialAddress: this.current.metadata.ResidentialAddress,
      issueDate: this.current.metadata.IssueDate,
      expiryDate: this.current.metadata.ExpiryDate,
      reason: this.current.metadata.Reason,
      authorizedCrossingPoints: this.current.metadata.AuthorizedCrossingPoints,
      place: eventPayBorderPass ? eventPayBorderPass.place.longName : this.api.userPlaces?.find(place => place.id === this.api.userPlaceId).longName,
      officer: eventPayBorderPass ? eventPayBorderPass.actor_id: this.api.userInfo.id,
      qrCode: this.current.qrCode
    };
  }

  prepareVisualDocuments() {
    const arrayDocuments: {nameDocument: string, document: any, title?: string, icon?: string, customClass?: string, previewDirect?: string, checkComparison?: boolean}[] =
      (this.current.metadata.Documents && !isEmptyObject(this.current.metadata.Documents))
        ? Object.entries(this.current.metadata.Documents)
          .map(([nameDocument, document]) => ({nameDocument, document}))
          .filter(doc => isBase64(doc.document as string) || isPDF(doc.document as string))
        : [];

    if(arrayDocuments && arrayDocuments.length) {
      arrayDocuments.forEach(doc => {
        switch(doc.nameDocument) {
        case VisaFN.PASSPORT_PHOTO:
          doc.customClass = 'doc doc-dk doc-previewDirect';
          doc.previewDirect = 'photo.current';
          doc.title = 'photo.current';
          break;
        case VisaFN.PASSPORT_SCAN:
          doc.icon = 'passport';
          doc.title = 'data.biographic';
          break;
        case VisaFN.PROOF_OF_RESIDENCE:
          doc.icon = 'house';
          doc.title = 'residence.proof';
          break;
        case VisaFN.COPY_OF_ENTRY:
          doc.icon = 'passport';
          doc.title = 'data.copyOfEntry';
          break;
        case VisaFN.DEPARTURE_TICKET:
          doc.icon = 'passport';
          doc.title = 'data.departureTicket';
          break;
        case VisaFN.ATTESTANT_DOCUMENT:
          doc.icon = 'passport';
          doc.title = 'data.attestantDocument';
          break;
        case VisaFN.AFFIDAVIT_CUSTODY:
          doc.icon = 'passport';
          doc.title = 'data.affidavitCustody';
          break;
        case VisaFN.REASON_OF_VISIT_PROOF:
          doc.icon = 'file-line';
          doc.title = 'reasonOfVisit.proof';
          break;
        case VisaFN.ADDRESS_AT_DESTINATION:
          doc.icon = 'location-dot';
          doc.title = 'address.destination';
          break;
        case VisaFN.PREVIOUS_ZIMBABWEAN_VISA:
          doc.icon = 'file-lines';
          doc.title = 'previous.visa';
          break;
        case VisaFN.INVITATION_LETTER_AND_BUSINESS_PROFILE:
          doc.icon = 'envelope-open-text';
          doc.title = 'inviteBusiness.letter';
          break;
        case VisaFN.BUSINESS_PROFILE:
          doc.icon = 'user';
          doc.title = 'business.profile';
          break;
        case VisaFN.APPLICATION_LETTER:
          doc.icon = 'envelope-open-text';
          doc.title = 'application.letter';
          break;
        case VisaFN.LETTER_OF_ACCEPTANCE_FROM_SCHOOL:
          doc.icon = 'envelope-open-text';
          doc.title = 'acceptanceFromScool.letter';
          break;
        case VisaFN.BUSINESS_LETTER:
          doc.icon = 'envelope-open-text';
          doc.title = 'business.letter';
          break;
        case VisaFN.HOST_INVITATION_LETTER:
          doc.icon = 'envelope-open-text';
          doc.title = 'hostInvitation.letter';
          break;
        case VisaFN.PROOF_OF_RESIDENCE_HOST:
          doc.icon = 'home';
          doc.title = 'proof.residenceHost';
          break;
        case VisaFN.RESIDENTIAL_STATUS_OF_HOST:
          doc.icon = 'id-card';
          doc.title = 'hostResidential.status';
          break;
        case VisaFN.INVITATION_LETTER:
          doc.icon = 'envelope-open-text';
          doc.title = 'invite.letter';
          break;
        case VisaFN.EXTRA_DOCUMENT_1:
          doc.icon = 'file-image';
          doc.title = 'ExtraDocument1';
          break;
        case VisaFN.EXTRA_DOCUMENT_2:
          doc.icon = 'file-image';
          doc.title = 'ExtraDocument2';
          break;
        case VisaFN.EXTRA_DOCUMENT_3:
          doc.icon = 'file-image';
          doc.title = 'ExtraDocument3';
          break;
        case VisaFN.EXTRA_DOCUMENT_4:
          doc.icon = 'file-image';
          doc.title = 'ExtraDocument4';
          break;
        case VisaFN.EXTRA_DOCUMENT_5:
          doc.icon = 'file-image';
          doc.title = 'ExtraDocument5';
          break;
        default:
          doc.icon = 'file';
          doc.title = 'document';
          break;
        }
      });
    }

    if(this.scanDocument?.documentPhoto && this.current.showComparison) {
      arrayDocuments.unshift({
        nameDocument: VisaFN.PASSPORT_SCAN,
        document: this.scanDocument.documentPhoto,
        title: 'photo.scanned',
        customClass: 'doc doc-dk doc-previewDirect',
        previewDirect: 'photo.scanned',
        checkComparison: true
      });
    }
    this.metadataDocuments = arrayDocuments;
  }

  isLocalUsageUntilError() {
    return this.current?.localUsageUntil && !this.current.localUsageUntilValid;
  }

  downgrade() {
    this.loader.loading(true, {type: 'warn', message: 'ask.downgrade', btnLabel: 'global.yes', custom: {icon: 'circle-down'}}).then((done: boolean) => {
      if(done) {
        this.loader.loading(true);
        this.api.updatePayment(this.current.id, undefined, undefined, undefined, undefined, undefined, undefined, DocumentType.ZWEVISA).then(() => {
          this.loader.loading(true, {type: 'valid', message: 'done.downgrade'}).then(() => {
            this.getPayment();
          });
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: 'error.downgrade'});
        });
      }
    });
  }

  canPrintBorderPass() {
    return this.api.isZwevisa && this.printBorderPassData && this.current.operationId === DocumentType.ZWEEXIT && this.current.subState === RequestSubState.CLOSED && this.current.events.find(event => event.consumeData && event.consumeData.Operation !== OperationType.REFUSEOUT  && event.consumeData.Operation !== OperationType.REFUSEIN) && (this.current.metadata.BorderPass && this.current.metadata.BorderPass === 'Yes');
  }

  getWarningMessage(): string {
    const messages = [];
    const readyApplication = this.request.getStatusTheme(this.current).actionState === ActionState.READY_CHECKIN ||
      this.request.getStatusTheme(this.current).actionState === ActionState.READY_CHECKOUT ||
      this.request.getStatusTheme(this.current).actionState === ActionState.READY_CHECK;
    if(!this.current.usageUntilValid && readyApplication) {
      messages.push(this.lang.transform(this.isCatAB ? 'outUsage.AB' : 'outUsage.C'));
    }
    if(!this.current.localUsageUntilValid && this.request.getStatusTheme(this.current).actionState === ActionState.READY_CHECKOUT) {
      messages.push(this.lang.transform('application.overStay'));
    }
    if(this.current?.watchlistsResponse?.matches?.length) {
      messages.push(this.lang.transform('watchlistFound'));
    }
    if(this.current?.apipnrResponse?.matches?.length) {
      messages.push(this.lang.transform('apipnrFound'));
    }
    return (this.current.state !== RequestState.PROPOSED && this.api.env.type === DocumentType.ZWEVISA) ? messages.join(' & ') : '';
  }

  changeDeportedMode(event: boolean) {
    this.deportedMode = event;
  }

  toggleShowComparison() {
    this.current.showComparison = !this.current.showComparison;
    if(this.current.showComparison) {
      const hasComparison = this.metadataDocuments.some(doc => doc.checkComparison);
      if(!hasComparison && this.scanDocument?.documentPhoto) {
        this.metadataDocuments.unshift({
          nameDocument: VisaFN.PASSPORT_SCAN,
          document: this.scanDocument.documentPhoto,
          title: 'photo.scanned',
          customClass: 'doc doc-dk doc-previewDirect',
          previewDirect: 'photo.scanned',
          checkComparison: true
        });
      }
    } else {
      this.metadataDocuments = this.metadataDocuments.filter(doc => !doc.checkComparison);
    }
  }

  preparePrintNotices() {
    const notices = this.current?.events?.filter(event => event.consumeData?.Documents?.some((document: any) => document.Type === NoticeType.RESTRICTION ||
      document.Type === NoticeType.REPORTING ||
      document.Type === NoticeType.REFUSAL)) || [];
    if(!notices.length) {
      return;
    }
    this.existingNotices = notices;
  }

  printNotices() {
    const longName = this.current.metadata?.FirstName || this.current.metadata?.LastName ? (this.current.metadata?.FirstName || '') + (this.current.metadata?.LastName ? ' ' + this.current.metadata?.LastName : '') : '';

    const noticePrintAction = (event: PlatformEvent) => {
      const documentsConsumeData = event.consumeData.Documents[0];
      const restrictionFields = documentsConsumeData?.RestrictionFields;
      const reportingFields = documentsConsumeData?.ReportingFields;
      const refusalFields = documentsConsumeData?.RefusalFields;
      switch(documentsConsumeData.Type) {
      case NoticeType.REPORTING:
        const reportingForm = this.formBuilder.group({
          hqReference: [this.current.internalReference || ''],
          stationReference: [event.place.longName +' ['+ event.place.id +']' || ''],
          purpose: [reportingFields?.Purpose || ''],
          fullNames: [longName],
          host: [reportingFields?.Host || ''],
          phone: [reportingFields?.Phone || ''],
          hostPhone: [reportingFields?.HostPhone || ''],
          addressZimbabwe: [reportingFields?.AddressZimbabwe || ''],
          email: [reportingFields?.Email],
          address: [reportingFields?.Address || ''],
          periodFrom: [reportingFields?.PeriodFrom || ''],
          periodTo: [reportingFields?.PeriodTo || ''],
          officer: [event.actor_id],
          reportAt: [reportingFields?.ReportingPost || ''],
          reportOn: [reportingFields?.ReportOn ? reportingFields?.ReportOn : ''],
          reportInterval: [reportingFields?.ReportAtIntervalDays || '']
        });
        const reportingData: PrintNoticeData = {
          form: reportingForm,
          qrCode: this.current.qrCode
        };
        this.print.print<PrintNoticeData>(NoticeType.REPORTING, reportingData);
        break;
      case NoticeType.RESTRICTION:
        const restrictionForm = this.formBuilder.group({
          hqReference: [this.current.internalReference || ''],
          stationReference: [event.place.longName +' ['+ event.place.id +']' || ''],
          fullNames: [longName],
          reportLocation: [restrictionFields?.ReportingPost || ''],
          daysUntilReport: [restrictionFields?.GracePeriodDays || ''],
          otherMissingDocument: [restrictionFields?.DocumentToProduceOther || ''],
          residentialAddress: [restrictionFields?.ResidentialAddress || ''],
          businessAddress: [restrictionFields?.BusinessAddress || ''],
          phone: [restrictionFields?.Phone || ''],
          email: [restrictionFields?.Email || ''],
          officer: [event.actor_id]
        });
        const restrictionData: PrintNoticeRestrictionData = {
          form: restrictionForm,
          qrCode: this.current.qrCode,
          documents: documentsConsumeData.RestrictionFields.DocumentToProduce
        };
        this.print.print<PrintNoticeData>(NoticeType.RESTRICTION, restrictionData);
        break;
      case NoticeType.REFUSAL:
        const refusalForm = this.formBuilder.group({
          fullNames: [longName],
          reportLocation: [event.place.longName +' ['+ event.place.id +']' || ''],
          paragraph: [refusalFields?.Paragraph || ''],
          reason: [refusalFields?.Reason || ''],
          dateOfRefusal: [format(event.updatedOn, 'yyyy-MM-dd')],
          place: [event.place.longName +' ['+ event.place.id +']' || ''],
          officer: [event.actor_id],
          operationComments: [event?.consumeData?.OperationComments || '']
        });
        const refusalData: PrintNoticeData = {
          form: refusalForm,
          qrCode: this.current.qrCode
        };
        this.print.print<PrintNoticeData>(NoticeType.REFUSAL, refusalData);
      }
    };

    if(this.existingNotices?.length === 1) {
      this.loader.loading(true, {
        type: 'question',
        message: this.lang.transform('ask.noticePrint', {date: format(this.existingNotices[0].updatedOn, 'dd/MM/yyyy')}),
        btnLabel: 'global.yes',
        custom: {icon: 'print'}
      }).then((res: boolean) => {
        if(res) {
          noticePrintAction(this.existingNotices[0]);
        }
      });
    } else if(this.existingNotices?.length > 1) {
      const dialogInput = [
        {
          formCtrlName: 'selectedNotice',
          type: 'select',
          label: 'select.notice',
          selectOptions: this.existingNotices.map((notice: PlatformEvent, index: number) => ({
            name: `${format(notice.updatedOn, 'dd/MM/yyyy')} - ${this.lang.transform(`notice.${notice.consumeData.Documents[0].Type}`)}`,
            value: index
          }))
        }
      ] as DialogFormInput[];
      this.loader.loading(true, {
        type: 'question',
        message: 'ask.selectNotice',
        btnLabel: 'global.confirm',
        custom: {
          closeBtnLabel: 'global.cancel',
          icon: 'print'
        },
        dialogInput
      }).then((res: any) => {
        if(res?.selectedNotice) {
          const selectedNotice = this.existingNotices[res.selectedNotice];
          noticePrintAction(selectedNotice);
        }
      });
    }
  }

  markAsClosed() {
    this.loader.loading(true, {type: 'warn', message: 'ask.closeApplication', btnLabel: 'global.confirm'}).then((r: boolean) => {
      if(r) {
        this.fingerprint.supervision('closeApplication').then(() => {
          this.loader.loading(true);
          this.api.changePayment(this.current.id, RequestState.USED, RequestSubState.CLOSED).then(() => {
            this.loader.loading(true, {type: 'question', message: 'done.closeApplication', btnLabel: 'yes'}).then((done) => {
              if(done) {
                this.scanDocument.scanned = true;
                this.scanDocument.scanDatas = {fields: {
                  nationality: this.current.metadata.Nationality || '',
                  lastName: this.current.metadata.LastName || '',
                  firstName: this.current.metadata.FirstName || '',
                  sex: this.current.metadata.Gender || '',
                  documentNumber: this.current.metadata.PassportNumber || '',
                  expirationDate: this.current.metadata.DateOfExpiry || '',
                  birthDate: format(this.current.metadata.BirthDay, 'yyMMdd') || ''
                }} as any;
                this.cart.startApplication(true);
              } else {
                this.getPayment();
              }
            });
          }).catch(() => {
            this.loader.loading(true, {type: 'error', message: 'error.closeApplication'});
          });
        });
      }
    });
  }

  revertConsume(event: PlatformEvent) {
    const dialogReasonInput = [{
      formCtrlName: 'revertReason',
      type: 'textarea',
      label: 'revert.consume',
      maxLength: 200
    }] as DialogFormInput[];
    this.loader.loading(true, {type: 'warn', message: 'ask.revertConsume', btnLabel: 'global.yes', dialogInput: dialogReasonInput, custom: {icon: 'undo'}}).then((done: any) => {
      if(done) {
        this.loader.loading(true);
        return this.api.voidConsumePayment(this.current.id, event.id, done.revertReason).then(() => this.loader.loading(true, {type: 'valid', message: 'done.revertConsume'}).then(() => {
          this.getPayment();
        })).catch(() => this.loader.loading(true, {type: 'error', message: 'error.revertConsume'}));
      }
    });
  }

  /*getCancelledEvent(eventId: string): any {
    return this.cancelEvent.find(e => +e.id === +eventId) || null;
  }*/

  isLatestUpdatedEvent(event: PlatformEvent): boolean {
    const listOfUpdates = this.current.events.filter(e => e.type === PlatformEventType.PAYMENT_SUSPEND_CHANGE || (e.type === PlatformEventType.PAYMENT_UPDATED && !e.place_id));
    const sorted = listOfUpdates.sort((a, b) => new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime());
    return sorted[0].id === event.id && this.current.state !== RequestState.PENDING;
  }

  internalReference(edit = false) {
    const HQ_REFERENCE_PATTERN = /^\d+_\d{4}$/;
    const dialogInput = [{
      formCtrlName: 'internalReference',
      type: 'textarea',
      value: this.current.internalReference || '',
      label: 'internalReference.desc',
      maxLength: 40
    }] as DialogFormInput[];
    this.loader.loading(true, {type: 'info', message: this.current.internalReference ? 'internalReference.ask.edit' : 'internalReference.ask.create', dialogInput}).then((res: any) => {
      if(res?.internalReference) {
        res.internalReference = res.internalReference.split(/[_/]/, 2).map((p: string, i: number) => {
          if(i === 1 && p.length < 4) {
            const y = parseInt(p, 10);
            p = (y > 70 ? '19' : '20') + p;
          }
          return p;
        }).join('_');
        if(!HQ_REFERENCE_PATTERN.test(res.internalReference)) {
          this.loader.loading(true, {type: 'warn', message: 'internalReference.badPattern'});
          return;
        }
        return this.api.updatePayment(this.current.id, undefined, undefined, undefined, undefined, undefined, res.internalReference).then(() => {
          this.getPayment();
          this.loader.loading(true, {type: 'valid', message: edit ? 'internalReference.done.edit' : 'internalReference.done.create'});
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: 'error.internalReference'});
        });
      }
    });
  }

  private checkScannedGender() {
    if(this.current.state !== RequestState.USED && this.scanDocument?.scanDatas && this.current.metadata.Gender && this.current.metadata.Gender === Gender.OTHER) {
      const dialogInput = [{
        formCtrlName: 'gender',
        type: 'select',
        label: 'form.gender',
        selectOptions: [
          {name: 'male', value: Gender.MALE},
          {name: 'female', value: Gender.FEMALE}
        ]
      }] as DialogFormInput[];
      setTimeout(() => {
        this.loader.loading(true, {type: 'info', message: 'changeGender.ask', dialogInput, btnLabel: 'global.confirm', custom: {icon: 'venus-mars'}}).then((res: any) => {
          if(res?.gender) {
            const serializedDatas = JSON.stringify({[VisaFN.GENDER]: res.gender});
            this.loader.loading(true);
            this.api.updatePayment(this.current.id,
              undefined, undefined, serializedDatas, undefined,
              undefined, undefined, undefined, undefined, undefined).then(() => {
              this.getPayment();
              this.loader.loading(false);
            }).catch(() => {
              this.loader.loading(true, {type: 'error', message: 'global.error'});
            });
          }
        });
      }, 500);
    }
  }

  getPrintEvents(): PlatformEvent[] {
    return this.current?.events.filter(event => event.type === PlatformEventType.PAYMENT_PRINTED);
  }

  openInternalReference() {
    if(this.current?.internalReference) window.open(this.api.env.referenceUri + this.current.internalReference, '_new');
  }

  openWatchListWindow(match: WatchlistMatch) {
    match?.item?.link && window.open(match?.item?.link, '_new');
  }

  private reloadOnDraft(current: RequestWithDuplicata) {
    this.checkDraftTimeout = setTimeout(() => {
      if(current.state === RequestState.PROPOSED) this.getPayment(false, current.id);
      clearTimeout(this.checkDraftTimeout);
    }, 5000);
  }


  updateScannedPassport() {
    this.loader.loading(true, {type: 'info', message: 'ask.updateScannedPassport', btnLabel: 'global.yes'}).then((res: any) => {
      if(res) {
        this.loader.loading(true);
        this.scanDocument.transferFromPassportData(this.current.id).then(() => {
          this.current.allProcessed = false;
          this.getPayment();
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: 'error.updateScannedPassport'});
        });
      }
    });
  }

  updateForHKG() {
    this.loader.loading(true, {type: 'info', message: 'ask.updateForHKG', btnLabel: 'global.yes'}).then((res: any) => {
      if(res) {
        this.loader.loading(true);
        const fieldsToUpdate = {[PassFields.NATIONALITY]: 'HKG'};
        return this.scanDocument.transferFromPassportData(this.current.id, fieldsToUpdate, true).then(() => {
          this.current.allProcessed = false;
          this.getPayment();
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: 'error.updateForHKG'});
        });
      }
    });
  }

  openDialogEditPassport() {
    this.fingerprint.supervision('supervision.updateManuallyPassport').then(() => {
      const data = {
        [PassFields.FIRST_NAME]: this.current.metadata?.FirstName || '',
        [PassFields.LAST_NAME]: this.current.metadata?.LastName || '',
        [PassFields.BIRTH_DATE]: this.current.metadata?.BirthDay || '',
        [PassFields.DOCUMENT_NUMBER]: this.current.metadata?.PassportNumber || '',
        [PassFields.SEX]: this.current.metadata?.Gender || '',
        [PassFields.NATIONALITY]: this.current.metadata?.Nationality || '',
        [PassFields.EXPIRATION_DATE]: this.current.metadata?.DateOfExpiry || ''
      };
      return this.dialog.openDialog(DialogEditPassportComponent, 'md', data).then((updatedData: PassFieldsData) => {
        if(!isEmptyObject(updatedData)) {
          return this.loader.loading(true, {type: 'question', message: 'ask.updateManuallyPassport', btnLabel: 'global.yes'}).then((res: boolean) => {
            if(res) {
              this.loader.loading(true);
              if(updatedData.birthDate) {
                updatedData.birthDate = convertToYYMMDD(new Date(updatedData.birthDate));
              }
              if(updatedData.expirationDate) {
                updatedData.expirationDate = convertToYYMMDD(new Date(updatedData.expirationDate));
              }
              return this.scanDocument.transferFromPassportData(this.current.id, updatedData, true).then(() => {
                this.current.allProcessed = false;
                this.getPayment();
              }).catch(() => {
                this.loader.loading(true, {type: 'error', message: 'error.updatePassport'});
              });
            }
          });
        }
      });
    });
  }

  transferOtherState() {
    this.loader.loading(true, {type: 'info', message: this.lang.transform('ask.transferOtherState', {transfertMode: this.lang.transform(this.scanDocument?.consumeMode === ConsumeMode.ENTRY ? 'payment_status.DYN.zwevisa.1' : 'payment_status.DYN.zwevisa.9.exit')} ), btnLabel: 'global.yes'}).then((res: any) => {
      if(res) {
        const forcedTransfert = this.scanDocument?.consumeMode === ConsumeMode.ENTRY ? OperationType.IN : OperationType.OUT;
        this.consumePayment(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, forcedTransfert);
      }
    });
  }

  detectConsumeMode() {
    const isEntry = this.scanDocument?.consumeMode === ConsumeMode.ENTRY;
    const isExit = this.scanDocument?.consumeMode === ConsumeMode.EXIT;
    const actionState = this.request.getStatusTheme(this.current).actionState;
    const isReadyCheckoutOrZweeExit = actionState === ActionState.READY_CHECKOUT || actionState === ActionState.READY_ZWEEXIT;
    const isReadyCheckinOrCheck = actionState === ActionState.READY_CHECKIN || actionState === ActionState.READY_CHECK;
    const hasUsageLeft = this.current.checkoutIsNext ? this.current.usageCount < this.current.document?.usageCount : true;
    const isNotZweOperation = this.current.operationId !== DocumentType.ZWEEXIT && this.current.operationId !== DocumentType.ZWEENTRY;
    const isNotTransitVisa = this.current.metadata?.Documents?.PurposeOfVisit !== PurposeOfVisit.TRANSIT_VISA;
    this.otherStateAction = ((isEntry && isReadyCheckoutOrZweeExit) || (isExit && isReadyCheckinOrCheck)) && hasUsageLeft && isNotZweOperation && isNotTransitVisa && !this.current.isExtension;
  }

  openBatchView(id: string) {
    this.toggleSelectMode(false);
    this.metadataDocuments = [];
    this.isSyncing = false;
    this.isCatAB = false;
    this.qr.deleteCurrentQrData();
    this.printVisaApplication = [];
    this.payment.current$.next(undefined);
    this.nav.to('admin-request-select/' + id);
  }

  loadFullMetadata() {
    this.getPayment(true, this.paymentId, true);
  }

  canHaveDocuments(): boolean {
    return this.current.countryCat === CountryCat.C && this.current.metadata.PassportType === PassportType.ORDINARY && !this.current.offline;
  }

  hasMetadataDocuments(): boolean {
    return this.metadataDocuments?.filter(doc => doc.nameDocument !== VisaFN.PASSPORT_SCAN).length > 0;
  }

  private getPayment(loader = false, id = this.paymentId, fullMetadata?: boolean) {
    this.payment.getPayment(id, loader, {
      history: true,
      watchlist: this.api.hasPerm(UserPermission.ALLOW_CONSUME) || this.api.hasPerm(UserPermission.ALLOW_ADMIN),
      apipnr: this.api.hasPerm(UserPermission.ALLOW_CONSUME) || this.api.hasPerm(UserPermission.ALLOW_ADMIN),
      inbound: this.api.hasPerm(UserPermission.ALLOW_CONSUME) || this.api.hasPerm(UserPermission.ALLOW_ADMIN),
      fullMetadata: fullMetadata || this.api.hasPerm(UserPermission.ALLOW_CONTROL) || this.api.hasPerm(UserPermission.ALLOW_ADMIN)
    }, true);
  }

  protected readonly RequestState = RequestState;
  protected readonly convertToEpoch = convertToEpoch;
  protected readonly ShortcutAction = ShortcutAction;
  protected readonly ActionState = ActionState;
  protected readonly faSpellCheck = faSpellCheck;
  protected readonly DocsPrintType = DocsPrintType;
  protected readonly ConsumeMode = ConsumeMode;
}
