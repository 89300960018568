import {Injectable} from '@angular/core';
import {DialogFormInput, LangService, LoaderService} from 'ngx-satoris';
import {ApiService} from './api.service';
import {Validators} from '@angular/forms';
import {SyncService} from './sync.service';
import {User} from '../models/user';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class FingerprintService {

  qualityFingerPrint: number | undefined = undefined;
  fingerB64: string | undefined = undefined;
  badQuality: boolean;
  minimalTolerance = 40;

  constructor(private loader: LoaderService,
    private lang: LangService,
    private sync: SyncService,
    private api: ApiService) {
  }

  supervision(actionText: string): Promise<string> {
    return new Promise((resolve) => {
      const dialogInput = [{
        formCtrlName: 'supervisorAccountName',
        type: this.sync.supervisors.length ? 'select' : 'text',
        label: 'supervisorAccountName',
        validator: [Validators.required],
        selectOptions: this.sync.supervisors.length ? this.sync.supervisors.map((supervisor: User) => ({name: supervisor.accountName, value: supervisor.accountName})) :  undefined,
        value: localStorage.getItem('bms:lastSupervisor') || ''
      }] as DialogFormInput[];

      const start = (accountName?: string) => {
        if(accountName) {
          dialogInput[0].value = accountName;
        }
        this.loader.loading(true, {dialogInput: dialogInput, buttonType: 'submit', type: 'info', btnLabel: 'global.confirm', custom: {icon: 'shield-dog', innerHtml: this.lang.transform('scan.supervisor.desc', {actionText: this.lang.transform(actionText)})}}).then((res: any) => {
          if(res?.supervisorAccountName) {
            this.loader.loading(true);
            this.api.userFingerprint(res.supervisorAccountName).then((foundFinger: any) => {
              if(!foundFinger || !foundFinger.result) {
                this.loader.loading(true, {type: 'error', message: 'scan.supervisor.noFingerprint', btnLabel: 'global.retry'}).then((done: boolean) => {
                  if(done) {
                    start(res.supervisorAccountName);
                  }
                });
              } else {
                //save supervisor account name for next time
                window.localStorage.setItem('bms:lastSupervisor', res.supervisorAccountName);
                //b64 to array of numbers
                const fingerBytes = atob(foundFinger.result).split('').map((char) => char.charCodeAt(0));
                this.loader.loading(true, {type: 'info', btnLabel: 'scan.now', custom: {icon: 'fingerprint', innerHtml: this.lang.transform('scan.fingerprintSupervisor.desc', {actionText: this.lang.transform(actionText)})}}).then((done: boolean) => {
                  if(done) {
                    if(this.scanFingerprint(fingerBytes)) {
                      resolve(res.supervisorAccountName);
                    } else {
                      this.loader.loading(true, {type: 'error', message: 'scan.supervisor.wrongFingerprint', btnLabel: 'global.retry', custom: {icon: 'fingerprint'}}).then((done: boolean) => {
                        if(done) {
                          start(res.supervisorAccountName);
                        }
                      });
                    }
                  } else {
                    this.loader.loading(false);
                  }
                });
              }

            }).catch(() => {
              this.loader.loading(false);
            });
          } else if(res) {
            this.loader.loading(true, {type: 'error', message: 'scan.supervisor.noAccountName', btnLabel: 'global.retry'}).then((done: boolean) => {
              if(done) {
                start();
              }
            });
          }
        });
      };
      start();
    });
  }

  openFingerprint() {
    this.loader.loading(true, {type: 'valid', message: this.lang.transform('scan.fingerprint.desc'), btnLabel: 'global.start', custom: {icon: 'fingerprint'}}).then((done: boolean) => {
      if(done) {
        this.scanFingerprint();
      }
    });
  }

  scanFingerprint(testBytes?: number[]) {
    this.fingerB64 = undefined;
    this.qualityFingerPrint = undefined;
    this.badQuality = undefined;

    const fingerprintScan = electron.addon.scanFingerprint();
    const arrayScanned = fingerprintScan[0];
    const qualityScanned = fingerprintScan[1];
    if(qualityScanned > 100 || qualityScanned <= 0 || !arrayScanned.length) {
      this.loader.loading(true, {type: 'error', message: 'scan.fingerprint.noResponse'});
    } else {
      const fingerbytes: Uint8Array = new Uint8Array(arrayScanned);
      let binaryString = '';
      for(let i = 0; i < fingerbytes.length; i++) {
        binaryString += String.fromCharCode(fingerbytes[i]);
      }

      this.fingerB64 = btoa(binaryString);
      this.qualityFingerPrint = qualityScanned;
      this.badQuality = this.qualityFingerPrint < this.minimalTolerance;

      if(testBytes) {
        return electron.addon.checkFingerprint(testBytes);
      }
      this.loader.loading(true, {type: 'info', btnLabel: 'global.confirm', custom: {innerHtml: this.lang.transform('scan.fingerprint.success', {quality: this.qualityFingerPrint})}}).then((done: boolean) => {
        if(done) {
          this.confirmFingerprint();
        }
      });
    }
  }

  confirmFingerprint() {
    this.loader.loading(true);
    this.api.setPassword(undefined, this.fingerB64).then(() => {
      this.loader.loading(false);
    }).catch(() => {
      this.loader.loading(true, {type: 'error', message: 'scan.addFingerprint.error'});
    });
  }
}
