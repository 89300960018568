/* tslint:disable */
export const cn_overrides_strings = {
  'summary.warning_validity_list': '<b>重要通知：</b> 请注意，以下人员的电子旅行授权的有效期从签发日期开始，<b><u>而非</u></b>进入津巴布韦的日期：<b>{names}</b>。<br><br>允许的停留时间将由<b>抵达时入境口岸的移民官员决定（通常为30天）</b>。请据此安排您的行程。',
  'summary.warning_validity': '<b>重要通知：</b> 请注意，本签证的电子旅行授权有效期从签发日期开始，<b><u>而非</u></b>进入津巴布韦的日期。<b>允许的停留时间将由抵达时入境口岸的移民官员决定（通常为30天）</b>。请据此安排您的行程。',
  'UsageUntil': '有效期至',
  'updateField.desc': '请提供以下字段的更新信息：<b>{field}</b>',
  'global.checkOut.withoutCamera': '无摄像头退房',
  'global.checkIn.withoutCamera': '无摄像头入住',
  'tooltip.checkInWithoutCamera': '无需使用摄像头登记该人员',
  'global.checkOut.withCamera': '有摄像头退房',
  'supervision.zweentry': '需要主管批准的入境',
  'DYN.permanent-resident-permit': '永久居留许可',
  'DYN.permanent-resident-permit.desc': '永久居留许可允许在津巴布韦无限期居住。通常签发给在该国长期居住并符合条件的个人。',
  'changeFingerprint': '更改指纹',
  'scan.supervisor.timeout': '您是否要绕过主管指纹扫描？',
  'supervision.manualCatC': 'C类国家的手动输入',
  'supervision.blacklist': '黑名单申请人',
  'supervision.specifiedNationals': '需要监督的国家',
  'ask.downgrade': '您确定要将此申请降级为单次入境签证吗？此操作无法撤销。',
  'checkin': '入住',
  'checkout': '退房',
  'scan.addFingerprint.error': '添加指纹时发生错误。请再试一次。',
  'scan.supervisor.wrongFingerprint': '指纹与系统中存储的主管指纹不匹配。请再试一次。',
  'scan.now': '立即扫描',
  'scan.fingerprintSupervisor.desc': '准备好时，作为主管，将手指放在扫描仪上，然后点击<b>立即扫描</b>以继续执行以下操作：</br><b>{actionText}</b>',
  'scan.supervisor.noFingerprint': '未找到此主管账号名的指纹数据',
  'scan.supervisor.noAccountName': '未提供主管账号名',
  'supervisorAccountName': '主管账号名',
  'scan.supervisor.desc': '请输入主管的账号名以继续执行以下操作：</br><b>{actionText}</b>',
  'addFingerprint': '添加指纹',
  'all.supervisions': '所有监督审查',
  'tooltip.allSupervisions': '所有提交的监督申请审查列表。',
  'refusalReason.doSupervisor.desc.DYN.zwevisa': '请提供简要描述<b>拒绝</b>的原因。此信息将发送给边境官员。',
  'approveOrRefuse': '批准或拒绝',
  'request.do_supervisor_review': '主管审查',
  'request.do_supervisor_review.desc': '主管可以审查申请，以确保提供的信息准确无误，并符合该国的规定。',
  'request.askSupervisorReview': '您的审查已成功提交。',
  'refusalReason.reviewSupervisor.desc.DYN.zwevisa': '请简要描述审查的<b>不推荐</b>理由。审查将提交给主管批准。',
  'request.askSupervisorReview.error': '审查申请时出错。请再试一次。',
  'askSupervisorReview': '您的建议是什么？您的审查将提交给主管批准。',
  'doSupervisorReview': '您是否要批准或拒绝此申请？根据您的决定，边境官员将能够检查申请人是否可以入境。',
  'request.ask_supervisor_review': '提交主管审查',
  'request.supervisor_review': '您可以请求主管审查此申请。主管将审查申请，您可以继续进行申请处理。',
  'place.countriesSelected': '选择需要监督入境的国家',
  'visa.catC.ordinary': '此申请适用于持有<b>C类</b>国籍和<b>普通护照</b>的个人的入境。入境只能<b>被拒绝</b>给申请人。',
  'err.AddressZimbabwe.required': '需要提供津巴布韦地址',
  'zwevisaextc.title': 'C类签证停留延期',
  'zwevisaextab.title': 'A或B类签证停留延期',
  'DYN.payment_text.zwevisaextc': 'C类签证延期允许您延长在津巴布韦的停留时间。',
  'DYN.payment_text.zwevisaextab': 'A或B类签证延期允许您延长在津巴布韦的停留时间。',
  'extend.start': '可以提交延期申请以延长在津巴布韦的停留时间。在延期过程中，您可能需要提供额外的信息或文件。请在下一页选择您希望延期的天数。',
  'visaExtend.desc': '您可以延长在津巴布韦的签证。请选择您希望延长签证的天数。',
  'kiosk.standby.title': '准备好探索津巴布韦了吗？',
  'kiosk.standbyDesc.title': '欢迎来到津巴布韦入境申报自助服务终端',
  'kiosk.standbyDesc.li4': '移民官员随后将进行最终审查并确认您入境津巴布韦。',
  'kiosk.standbyDesc.text3': '祝您在津巴布韦度过愉快的时光！',
  'kiosk.success.text3': '祝您享受愉快的旅程并在津巴布韦进行美妙的探索！',
  'kiosk.qr.url': 'https://www.evisa.gov.zw/',
  'PARX_SUB3_SEC12': '第X段 第3小节 第12条',
  'PARB_SEC17': '第B段 第17条',
  'SUB1_SEC18': '第1小节 第18条',
  'PARX_SUB1_SEC14': '第X段 第1小节 第14条',
  'SUBPARIII_PARF_SUB1_SEC14': '第III小段 第F段 第1小节 第14条',
  'err.addressZimbabwe.required': '需要提供津巴布韦地址',
  'PreviousConvictionsZimAsk': '您或您的受抚养人是否曾在津巴布韦被定罪？',
  'err.PreviousConvictionsZimAsk.required': '请回答关于在津巴布韦之前是否有定罪的问题',
  'err.PreviousConvictionsZim.required': '需要提供津巴布韦的犯罪记录',
  'form.plannedEntry': '您计划从哪个入境口岸进入津巴布韦？',
  'form.plannedEntry.desc': '请选择您计划进入津巴布韦的入境口岸。',
  'zwevisa.DYN.desc': '允许在指定的一(1)个月内进入该国一次，需要新签证才能再次入境。',
  'zwevisa.DYN.long_desc': '此签证允许在指定的一(1)个月内进入该国一次。</br>适合计划在短时间内访问津巴布韦的旅客。</br>初次入境后，需要新的签证才能再次入境，对于需要在短时间内进行多次访问的人来说不太方便。',
  'zwevisa_double.DYN.desc': '允许在指定的三(3)个月内两次进入该国，非常适合计划多次访问的旅客。',
  'zwevisa_double.DYN.long_desc': '双次入境签证允许在指定的三(3)个月内两次进入该国。</br>这种类型的签证非常适合计划多次访问的旅客，例如进行商务活动或参加津巴布韦活动的人。</br>它比单次入境签证提供了更多灵活性，但仍需为超过两次入境的额外访问申请新签证。',
  'zwevisa_multiple.DYN.desc': '在指定的六(6)个月期间，允许持有人多次进入目的地国家，为频繁的商务或旅游旅行提供便利。',
  'zwevisa_multiple.DYN.long_desc': '此签证允许持有人在指定的六(6)个月期间多次进入目的地国家。</br>适合需要多次访问津巴布韦的商务或旅游目的地的频繁旅行者。</br>多次入境签证在提到的签证类型中提供了最大的灵活性，允许在指定期间内不限次数入境。',
  'zwevisa_kaza.DYN.desc': '允许在卡万戈-赞比西跨界保护区(KAZA TFCA)内的多个国家入境的特殊签证，有效期为一个(1)月，促进区域旅游和保护工作。',
  'zwevisa_kaza.DYN.long_desc': 'KAZA UNIVISA是允许在卡万戈-赞比西跨界保护区(KAZA TFCA)内的多个国家入境的特殊签证，有效期为一个(1)月。</br>它通过促进区域旅游和保护工作，简化了在参与国家之间的旅行，包括<b>津巴布韦</b>、<b>赞比亚</b>和<b>博茨瓦纳</b>。</br>KAZA UNIVISA的有效期为30天，允许多次进入KAZA TFCA。',
  'zwevisaarr.DYN.desc': '允许符合条件的旅客在抵达目的地的入境口岸时获得签证，简化了短期访问的入境流程。此签证的有效期为一个(1)月。',
  'zwevisaarr.DYN.long_desc': '此签证允许符合条件的旅客在抵达目的地的入境口岸时获得签证，简化了短期访问的入境流程。</br>适合没有时间或资源提前申请签证的旅客。</br>抵达签证通常在短期内有效，例如30天，并可能对允许的入境次数有限制。此签证的有效期为一个(1)月。',
  'zwevisaarr_double.DYN.desc': '允许符合条件的旅客在抵达目的地的入境口岸时获得允许两次入境的签证，为多次访问提供灵活性。此签证的有效期为三(3)个月。',
  'zwevisaarr_double.DYN.long_desc': '双次入境抵达签证允许符合条件的旅客在抵达目的地的入境口岸时获得允许两次入境的签证。</br>它为需要在签证有效期内进行多次访问的旅客提供灵活性。</br>这种签证适合计划访问津巴布韦及其他邻国的商务旅客或游客。此签证的有效期为三(3)个月。',
  'zwevisaarr_multiple.DYN.desc': '允许符合条件的旅客在抵达目的地的入境口岸时获得多次入境签证，适合频繁访客。此签证的有效期为六(6)个月。',
  'zwevisaarr_multiple.DYN.long_desc': '此签证允许符合条件的旅客在抵达目的地的入境口岸时获得多次入境签证。</br>它适用于需要在短时间内多次访问津巴布韦的频繁访客。</br>多次入境抵达签证是所提到的签证类型中最灵活的，允许在指定期间内不限次数入境。此签证的有效期为六(6)个月。',
  'zwevisaarr_kaza.DYN.desc': 'KAZA Univisa的变体，在抵达目的地的入境口岸时获得，为探索KAZA TFCA多个国家的旅客提供便利。此签证的有效期为一个(1)月。',
  'zwevisaarr_kaza.DYN.long_desc': 'KAZA UNIVISA的这种变体是在抵达目的地的入境口岸时获得。</br>它为探索KAZA TFCA多个国家的旅客提供便利，包括<b>津巴布韦</b>、<b>赞比亚</b>和<b>博茨瓦纳</b>。</br>KAZA UNIVISA抵达签证的有效期为一个(1)月，允许多次进入KAZA TFCA。',
  'zweentry.DYN.desc': '津巴布韦公民及某些国家的旅客无需提前申请签证即可入境。',
  'zweentry.DYN.long_desc': '津巴布韦公民及某些国家的旅客无需提前申请签证即可入境。</br>这种类型的签证适合短期访问和旅游目的。</br>它允许旅客无需申请签证即可入境该国，简化了入境程序。</br></br>但请注意，免签入境不允许多次入境或延长停留超过指定访问期限。',
  'zwevisa_visitor_details_text': '<div class="mb_40">如果您的国籍在<a href="https://www.evisa.gov.zw/faq.html" target="_blank"><b>A类国家名单中（点击打开）</b></a>，您不需要签证即可前往津巴布韦。</div>',
  'zwevisa.description': '单次入境签证允许在一个(1)月内单次进入津巴布韦。使用后，如果没有新签证，将无法重新入境。',
  'zwevisa_double.description': '双次入境签证允许在签发后三(3)个月内两次进入津巴布韦。',
  'zwevisa_multiple.description': '多次入境签证允许在签发后六(6)个月内不限次数进入津巴布韦。',
  'zwevisaarr_multiple.description': '多次入境抵达签证允许在签发后六(6)个月内不限次数进入津巴布韦。此签证直接在抵达时获得，为计划多次短期访问的旅客提供方便高效的解决方案。',
  'zwevisa_kaza.description': 'KAZA UNIVISA是一种独特的旅行证件，可增强您对卡万戈-赞比西跨界保护区(KAZA TFCA)的探索。涵盖赞比亚、津巴布韦、博茨瓦纳、纳米比亚和安哥拉，此签证简化了在这些多样化区域之间的旅行，让您无缝体验它们丰富的文化和自然奇观。',
  'zwevisaarr_multiple': '多次入境抵达签证',
  'zwevisaarr.description': '单次入境抵达签证是一种简便的旅行证件，允许符合条件的访客在抵达目的地的入境口岸时申请并获得签证。它的特点是处理迅速、直接缴费和广泛的适用性，提供了短期停留的便利和灵活选择。旅客应检查其目的地的具体要求，因为适用条件可能因国家而异。',
  'zwevisaarr.title': '单次入境抵达签证',
  'zwevisaarr_double.title': '双次入境抵达签证',
  'zwevisaarr_kaza.title': 'KAZA抵达签证',
  'zwevisaarr_multiple.title': '多次入境抵达签证',
  'zwevisa': '单次入境签证',
  'zwevisa_double': '双次入境签证',
  'zwevisa_multiple': '多次入境签证',
  'zwevisa_kaza': 'KAZA Univisa',
  'zwevisa_double.title': '双次入境签证',
  'zwevisa_multiple.title': '多次入境签证',
  'zwevisa_kaza.title': 'KAZA Univisa',
  'zwevisaarr': '单次入境抵达签证',
  'zwevisaarr_double': '双次入境抵达签证',
  'zwevisaarr_kaza': 'KAZA抵达签证',
  'PreviousZimbabweanVisa': '之前的津巴布韦签证',
  'err.PreviousZimbabweanVisa.required': '需要提供之前的津巴布韦签证',
  'visaPriceArrival.desc': '这是您在抵达津巴布韦时需支付的费用。此费用不予退还。',
  'document_text': '请如实提供以下信息，并确保与您的护照或其他法律文件上的信息一致。津巴布韦移民局不对任何虚假信息或因此产生的不便负责。接受的文件格式为JPEG、PNG或PDF，且文件大小应小于1MB。',
  'PreviousConvictionsZim': '津巴布韦的犯罪记录',
  'travel_information_text': '请如实提供以下信息，并确保与您的护照或其他法律文件上的信息一致。津巴布韦移民局不对任何虚假信息或因此产生的不便负责。',
  'AddressZimbabwe': '津巴布韦地址',
  'form.addressZimbabwe': '居住地址',
  'form.previousConvictionsZim': '津巴布韦的犯罪记录',
  'proof.residence.zim': '津巴布韦居住证明',
  'err.PreviousConvictionsZim.pattern': '津巴布韦的犯罪记录必须符合正确格式',
  'DYN.payment_text.zwevisa': '<p>此申请为<b>单次入境签证</b>。</p> <p>单次入境签证允许在一个(1)月内单次进入津巴布韦。使用后，如果没有新签证，将无法重新入境。</p> <div><p class="mb_2"><b>请仔细阅读：</b></p> <p>您在收到签证申请批准之前不得出行。您现在提交并支付的申请仍需通过行政控制流程。申请批准、修订请求或拒绝时，您将通过电子邮件收到通知。</p></div> <b>津巴布韦移民局保留在处理过程中自行更改您的签证类型的权利。这可能会影响您的签证费用。实际费用将在批准回复中说明。付款完成后不得更改。</b></p>',
  'DYN.payment_text.zwevisa_double': '<p>此申请为<b>双次入境签证</b>。</p> <p>双次入境签证允许在签发后三(3)个月内两次进入津巴布韦。</p> <div><p class="mb_2"><b>请仔细阅读：</b></p> <p>您在收到签证申请批准之前不得出行。您现在提交并支付的申请仍需通过行政控制流程。申请批准、修订请求或拒绝时，您将通过电子邮件收到通知。</p></div> <b>津巴布韦移民局保留在处理过程中自行更改您的签证类型的权利。这可能会影响您的签证费用。实际费用将在批准回复中说明。付款完成后不得更改。</b></p>',
  'DYN.payment_text.zwevisa_kaza': '<p>此申请为<b>KAZA Univisa</b>。</p> <p>KAZA UNIVISA是一种独特的旅行证件，可增强您对卡万戈-赞比西跨界保护区(KAZA TFCA)的探索，有效期为一个(1)月。涵盖赞比亚、津巴布韦、博茨瓦纳、纳米比亚和安哥拉，此签证简化了在这些多样化区域之间的旅行，让您无缝体验它们丰富的文化和自然奇观。</p> <div><p class="mb_2"><b>请仔细阅读：</b></p> <p>您在收到签证申请批准之前不得出行。您现在提交并支付的申请仍需通过行政控制流程。申请批准、修订请求或拒绝时，您将通过电子邮件收到通知。</p></div> <b>津巴布韦移民局保留在处理过程中自行更改您的签证类型的权利。这可能会影响您的签证费用。实际费用将在批准回复中说明。付款完成后不得更改。</b></p>',
  'DYN.payment_text.zwevisa_multiple': '<p>此申请为<b>多次入境签证</b>。</p> <p>多次入境签证允许在签发后六(6)个月内不限次数进入津巴布韦。</p> <div><p class="mb_2"><b>请仔细阅读：</b></p> <p>您在收到签证申请批准之前不得出行。您现在提交并支付的申请仍需通过行政控制流程。申请批准、修订请求或拒绝时，您将通过电子邮件收到通知。</p></div> <b>津巴布韦移民局保留在处理过程中自行更改您的签证类型的权利。这可能会影响您的签证费用。实际费用将在批准回复中说明。付款完成后不得更改。</b></p>',
  'DYN.payment_text.zwevisaarr': '<p>此申请为<b>单次入境抵达签证</b>。</p> <p>单次入境抵达签证是一种简便的旅行证件，允许符合条件的访客在抵达目的地的入境口岸时申请并获得签证，有效期为一个(1)月。它的特点是处理迅速、直接缴费和广泛的适用性，提供了短期停留的便利和灵活选择。旅客应检查其目的地的具体要求，因为适用条件可能因国家而异。</p><b>津巴布韦移民局保留在处理过程中自行更改您的签证类型的权利。这可能会影响您的签证费用。实际费用将在批准回复中说明。付款完成后不得更改。</b></p>',
  'DYN.payment_text.zwevisaarr_double': '<p>此申请为<b>双次入境签证</b>。</p> <p>双次入境签证允许在指定的三(3)个月内两次进入该国。该签证适用于短期多次访问，并且可以在抵达入境口岸时快速办理和支付。具体的适用条件可能因国家而异，因此建议旅行者提前确认相关规定。</p><b>津巴布韦移民局保留在处理过程中更改您的签证类型的权利，这可能影响签证费用。实际费用将在批准回复中说明。付款后不允许更改。</b></p>',
  'DYN.payment_text.zwevisaarr_kaza': '<p>此申请为<b>KAZA抵达签证</b>。</p> <p>KAZA抵达签证促进了卡万戈-赞比西跨界保护区(KAZA TFCA)内国家的入境便利，包括赞比亚、津巴布韦、博茨瓦纳、纳米比亚和安哥拉，有效期为一个(1)月。该签证允许合格旅客直接在目的地的入境口岸申请并获得签证，处理快速，费用直接支付，并广泛适用于短期旅行。请确保旅客符合KAZA TFCA内各国的具体要求。</p><b>KAZA移民局保留在处理过程中更改签证类型的权利，这可能影响签证费用。实际费用将在批准通知中说明。付款后不允许更改。</b></p>',
  'DYN.payment_text.zwevisaarr_multiple': '<p>此申请为<b>多次入境抵达签证</b>。</p> <p>多次入境抵达签证允许在签发后六(6)个月内不限次数进入目的地国。该签证处理迅速，费用直接支付，适合需要短期频繁访问的旅客。</p><b>津巴布韦移民局保留在处理过程中更改您的签证类型的权利。这可能会影响签证费用。实际费用将在批准回复中说明。付款后不允许更改。</b></p>',
  'DYN.payment_text.zweentry': '<p>此申请为<b>免签入境</b>。</p> <p>免签入境允许来自特定国家的旅客无需事先获得签证即可进入目的地国，通常用于短期停留。</p> <div><p class="mb_2"></p>',
  'zwevisaarr_double.description': '双次入境抵达签证允许旅客在签发后三个月内两次进入一个国家。该签证直接在入境口岸签发，适用于需要两次短期停留的旅客。旅客应提前确认目的地国的具体要求和资格条件。',
  'zwevisaarr_kaza.description': 'KAZA抵达签证允许在卡万戈-赞比西跨界保护区(KAZA TFCA)内多个国家直接办理签证，包括赞比亚、津巴布韦、博茨瓦纳、纳米比亚和安哥拉。简化的流程让旅客在到达时即可轻松获得签证，探索该地区的丰富景点。旅客应确认每个KAZA TFCA国家的具体入境要求。',
  'print.desc.part1': '持有电子旅行授权(eTA)不保证进入津巴布韦共和国。入境许可将由入境口岸的移民官员决定。<br><span class="mt_10">未经许可在津巴布韦从事任何形式的商业活动或工作是违法的。请在航空公司或移民官员要求时出示您的护照和此电子旅行授权(eTA)。</span>',
  'tooltip.addressZimbabwe': '如果申请人在津巴布韦有地址，请在此输入。',
  'err.PreviousConvictionsZim.maxlength': '津巴布韦的犯罪记录不得超过50个字符',
  'zweprovrespermit': '临时居住许可',
  'zwevisaextc': 'C类签证停留延期',
  'zwevisaextab': 'A或B类签证停留延期',
  'zwefeedback': '停留反馈表',
  'zwevisa_visitor_details_title': '您的国籍是否属于A类制度？',
  'refusalReason.desc.DYN.zwevisa': '<p><b>请注意，如果使用了"黑名单"一词，与此签证申请有关的个人将被列入黑名单。</b></p><p>请简要说明拒绝的原因。</p>',
  'refusalReason.desc.extension.DYN.zwevisa': '<p>请简要说明拒绝延期申请的原因。</p>',
  'refusalReason.review.desc.DYN.zwevisa': '请简要说明审查为<b>不推荐</b>的原因。此描述将发送给下一个审查人。',
  'approveReason.review.desc.DYN.zwevisa': '请简要说明审查为<b>推荐</b>的原因。此描述将发送给下一个审查人。',
  'deletePerson.error.DYN.zwevisa': '删除此人时发生错误。您无法删除已提交申请的人。',
  'DYN.zwevisaarr': '单次入境抵达签证',
  'notice.desc.BORDER_PASS': '边境通行证是津巴布韦政府签发的官方文件，允许个人合法通过指定边境口岸。',
  'scan.visaCreated.exit': '签证申请已成功创建。您现在可以为此人办理退房。',
  'scan.visaCreated.entry': '签证申请已成功创建。您现在可以为此人办理入住。',
  'forceComparison.show': '显示与扫描文档的比较',
  'forceComparison.hide': '隐藏与扫描文档的比较',
  'tooltip.forceComparison': '比较扫描文档与申请中的信息。',
  'noScannedComparison': '申请中未找到匹配的数据',
  'transfertFromPassport': '从扫描文档转移信息。',
  'tooltip.transfertFromPassport': '护照与扫描文档之间的信息不一致。您可以将扫描文档中的信息转移到申请中。',
  'CompanionsSize': '同行人数',
  'transit.consume': '过境',
  'tooltip.scanPassport': '扫描此人的护照',
  'passport.scan': '扫描护照',
  'tooltip.transitRegister': '登记此人的过境',
  'transit.register': '登记过境',
  'global.goLatest': '打开最近访问的申请',
  'tooltip.goLatest': '您可以返回到之前访问的申请',
  'scan.choice': '选择模式',
  'form.ad': '电子邮件/用户名',
  'printerOffline': '热敏打印机离线。请检查设备的USB连接并重试。您是否仍希望使用普通打印机打印文件？',
  'printerNotFound': '设备列表中未检测到热敏打印机。请检查连接并确保已安装驱动程序。您是否仍希望使用普通打印机打印文件？',
  'datePrinted': '打印日期',
  'printThermal': '使用热敏打印机打印',
  'tooltip.printThermal': '使用热敏打印机打印文件，或取消选择以使用普通打印机打印',
  'application.overStay': '申请已<b>逾期</b>',
  'watchlistFound': '申请与<b>监视名单匹配</b>',
  'apipnrFound': '申请与<b>API/PNR名单匹配</b>',
  'payAttention': '我会留意',
  'deportedMode': '标记为已驱逐',
  'tooltip.deportedMode': '将此人标记为已驱逐。如果您将此人标记为已驱逐，则系统将在此人入境时将其视为已驱逐状态。',
  'deportedComment': '驱逐状态原因',
  'payment.inReturned': '此申请在官员处以<b>驱逐状态</b>办理入住。',
  'reasonAdd': '<p><b>原因</b>: <br/> {reason}</p>',
  'global.deported': '已驱逐',
  'photo.entryCountry.INRETURNED': '以驱逐状态入境时的照片',
  'print.notices': '打印通知',
  'ask.noticePrint': '您想打印日期为{date}的通知吗？',
  'select.notice': '选择要打印的通知',
  'ask.selectNotice': '请选择您要打印的通知。',
  'noChoice.selectedNotice': '未选择通知',
  'tooltip.printNotices': '打印官员在办理入住或拒绝过程中打印的通知',
  'ask.revertConsume': '您确定要取消此事件吗？',
  'cancelled': '已取消',
  'tooltip.revertConsume': '您可以取消此申请的此事件',
  'done.revertConsume': '事件已成功取消',
  'revert.consume': '取消原因',
  'error.revertConsume': '取消事件时发生错误。请再试一次。',
  'outUsage.AB': '此申请已<b>超出有效期</b>。请将申请人引导至自助服务终端或协助其重新提交申请。',
  'outUsage.C': '此申请已<b>超出有效期</b>。请关闭此请求，然后为申请人创建新的入境记录并继续拒绝。',
  'print.date': '打印日期',
  'print.id': '链接到申请ID',
  'internalReference.badPattern': '内部参考必须符合格式"编号_年份"（例如，140_2024）。',
  'showFullDatas': '显示所有文件',
  'hideFullDatas': '隐藏所有文件',
  'tooltip.showFullDatas': '显示或隐藏申请中的所有文件',
  'watchlist.null': '<b>{system} 系统无法进行交叉检查</b>',
  'watchlist': '观察名单',
  'apipnr': 'API-PNR',
  'global.refuseNoCam': '拒绝无摄像头',
  'global.refuseIn': '拒绝在登记时',
  'global.refuseOut': '拒绝在离境时',
  'photo.entryCountry.REFUSEIN': '入境登记时的拒绝状态照片',
  'photo.entryCountry.REFUSEOUT': '出境时的拒绝状态照片',
  'global.refuseFingerPrint': '拒绝使用指纹',
  'tooltip.refuseFingerPrint': '用指纹拒绝此人',
  'tooltip.consumeRefuseNoCam': '拒绝不使用摄像头的人',
  'ask.refuseCamera': '拒绝使用摄像头',
  'tooltip.disabledBecauseReview': '此人目前与正在由主管审核的申请相关联。要为此人创建新申请，您必须先等待审核完成。',
  'client.extended.noValidPerson': '未找到与此付款相关的人。请检查您的已保存人员列表并重试。',
  'zwevisaarr.arrivalPrint': '到达时支付的单次入境签证',
  'zwevisaarr_double.arrivalPrint': '到达时支付的双次入境签证',
  'zwevisaarr_multiple.arrivalPrint': '到达时支付的多次入境签证',
  'zwevisaarr_kaza.arrivalPrint': '到达时支付的KAZA Univisa',
  'transfertHKG': '将此申请标记为香港申请',
  'tooltip.transfertHKG': '您可以根据扫描的中国护照将此申请标记为香港申请',
  'ask.updateForHKG': '您确定要将此申请更新为香港申请吗？',
  'done.updateForHKG': '申请已成功更新为香港申请',
  'error.updateForHKG': '更新为香港申请时发生错误。请重试。'
};