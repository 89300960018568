/* tslint:disable */
export const ru_overrides_strings = {
  'summary.warning_validity_list': '<b>ВАЖНОЕ ЗАМЕЧАНИЕ:</b> Обратите внимание, что срок действия электронной авторизации на поездку для следующего(-их) лица(-лиц) начинается с даты выдачи, <b><u>НЕ</u></b> с даты въезда в Зимбабве: <b>{names}</b>.<br><br>Разрешённая продолжительность пребывания будет <b>определена иммиграционными властями на пункте въезда по прибытии (обычно 30 дней)</b>. Пожалуйста, планируйте свою поездку соответственно.',
  'summary.warning_validity': '<b>ВАЖНОЕ ЗАМЕЧАНИЕ:</b> Обратите внимание, что срок действия электронной авторизации на поездку для этой визы начинается с даты выдачи, <b><u>НЕ</u></b> с даты въезда в Зимбабве. <b>Разрешённая продолжительность пребывания будет определена иммиграционными властями на пункте въезда по прибытии (обычно 30 дней)</b>. Пожалуйста, планируйте свою поездку соответственно.',
  'UsageUntil': 'Действительно до',
  'updateField.desc': 'Пожалуйста, предоставьте обновлённую информацию для следующего поля: <b>{field}</b>',
  'global.checkOut.withoutCamera': 'Выезд без камеры',
  'global.checkIn.withoutCamera': 'Въезд без камеры',
  'tooltip.checkInWithoutCamera': 'Зарегистрировать человека без использования камеры',
  'global.checkOut.withCamera': 'Выезд с камерой',
  'supervision.zweentry': 'Въезд, требующий одобрения руководителя',
  'DYN.permanent-resident-permit': 'Постоянное разрешение на проживание',
  'DYN.permanent-resident-permit.desc': 'Постоянное разрешение на проживание позволяет на неопределённый срок проживать в Зимбабве. Оно обычно выдается лицам, которые проживали в стране длительное время и соответствуют критериям.',
  'changeFingerprint': 'Изменить отпечаток пальца',
  'scan.supervisor.timeout': 'Вы хотите пропустить сканирование отпечатка пальца руководителя?',
  'supervision.manualCatC': 'Ручной ввод для страны категории C',
  'supervision.blacklist': 'Заявитель в чёрном списке',
  'supervision.specifiedNationals': 'Страна, требующая наблюдения',
  'ask.downgrade': 'Вы уверены, что хотите понизить этот запрос до визы с одним въездом? Это действие необратимо.',
  'checkin': 'Регистрация',
  'checkout': 'Выезд',
  'scan.addFingerprint.error': 'Произошла ошибка при добавлении отпечатка пальца. Пожалуйста, попробуйте снова.',
  'scan.supervisor.wrongFingerprint': 'Отпечаток пальца не соответствует отпечатку, хранящемуся в системе для этого руководителя. Пожалуйста, попробуйте снова.',
  'scan.now': 'Сканировать сейчас',
  'scan.fingerprintSupervisor.desc': 'Когда будете готовы, как руководитель, поместите палец на сканер и нажмите <b>Сканировать сейчас</b>, чтобы продолжить выполнение следующего действия:</br><b>{actionText}</b>',
  'scan.supervisor.noFingerprint': 'Не найдены данные об отпечатке пальца для указанного имени аккаунта руководителя',
  'scan.supervisor.noAccountName': 'Не указано имя аккаунта руководителя',
  'supervisorAccountName': 'Имя аккаунта руководителя',
  'scan.supervisor.desc': 'Пожалуйста, введите имя аккаунта руководителя для продолжения выполнения следующего действия:</br><b>{actionText}</b>',
  'addFingerprint': 'Добавить отпечаток пальца',
  'all.supervisions': 'Все проверки наблюдения',
  'tooltip.allSupervisions': 'Список всех отзывов, поданных по поводу наблюдения за заявлением.',
  'refusalReason.doSupervisor.desc.DYN.zwevisa': 'Пожалуйста, предоставьте краткое описание причины <b>ОТКАЗА</b>. Это сообщение будет отправлено пограничному офицеру.',
  'approveOrRefuse': 'Одобрить или отказать',
  'request.do_supervisor_review': 'Проверка руководителем',
  'request.do_supervisor_review.desc': 'Руководитель может проверить заявку, чтобы убедиться в правильности предоставленной информации и её соответствии требованиям страны.',
  'request.askSupervisorReview': 'Ваш отзыв успешно отправлен.',
  'refusalReason.reviewSupervisor.desc.DYN.zwevisa': 'Пожалуйста, предоставьте краткое описание проверки как <b>НЕ РЕКОМЕНДУЕТСЯ</b>. Отзыв будет отправлен руководителю для одобрения.',
  'request.askSupervisorReview.error': 'Произошла ошибка при проверке запроса. Пожалуйста, попробуйте снова.',
  'askSupervisorReview': 'Какова ваша рекомендация? Ваш отзыв будет отправлен руководителю для одобрения.',
  'doSupervisorReview': 'Вы хотите одобрить или отклонить это заявление? В зависимости от вашего решения, пограничный офицер сможет или не сможет зарегистрировать заявителя.',
  'request.ask_supervisor_review': 'Запрос на проверку руководителем',
  'request.supervisor_review': 'Вы можете запросить проверку руководителем этого заявления. Руководитель проверит заявку, и вы сможете продолжить процесс.',
  'place.countriesSelected': 'Выберите страны, для которых потребуется наблюдаемая въезд',
  'visa.catC.ordinary': 'Это заявление на въезд без визы для лиц с <b>гражданством категории C</b> и <b>обычным паспортом</b>. Въезд может быть <b>ОТКАЗАН</b> только заявителю.',
  'err.AddressZimbabwe.required': 'Требуется адрес в Зимбабве',
  'zwevisaextc.title': 'Продление пребывания для категории C',
  'zwevisaextab.title': 'Продление пребывания для категории A или B',
  'DYN.payment_text.zwevisaextc': 'Продление пребывания для категории C позволяет продлить ваше пребывание в Зимбабве.',
  'DYN.payment_text.zwevisaextab': 'Продление пребывания для категории A или B позволяет продлить ваше пребывание в Зимбабве.',
  'extend.start': 'Запрос на продление пребывания может быть подан для более длительного пребывания в Зимбабве. В процессе продления вам может потребоваться предоставить дополнительную информацию или документы. Пожалуйста, выберите количество дней, на которое вы хотите продлить визу, на следующей странице.',
  'visaExtend.desc': 'Вы можете продлить свою визу для более длительного пребывания в Зимбабве. Пожалуйста, выберите количество дней, на которое вы хотите продлить визу.',
  'kiosk.standby.title': 'ГОТОВЫ ИССЛЕДОВАТЬ ЗИМБАБВЕ?',
  'kiosk.standbyDesc.title': 'Добро пожаловать в киоск Декларации о Въезде в Зимбабве',
  'kiosk.standbyDesc.li4': 'Иммиграционный офицер проведёт окончательное оформление и подтвердит ваш въезд в Зимбабве.',
  'kiosk.standbyDesc.text3': 'Желаем вам приятного пребывания в Зимбабве!',
  'kiosk.success.text3': 'Желаем вам приятного пребывания и великолепных открытий в Зимбабве!',
  'kiosk.qr.url': 'https://www.evisa.gov.zw/',
  'PARX_SUB3_SEC12': 'Параграф X Подраздел 3 Раздел 12',
  'PARB_SEC17': 'Параграф B Раздел 17',
  'SUB1_SEC18': 'Подраздел 1 Раздел 18',
  'PARX_SUB1_SEC14': 'Параграф X Подраздел 1 Раздел 14',
  'SUBPARIII_PARF_SUB1_SEC14': 'Подпункт III Параграф F Подраздел 1 Раздел 14',
  'err.addressZimbabwe.required': 'Требуется адрес в Зимбабве',
  'PreviousConvictionsZimAsk': 'Были ли вы или кто-либо из ваших спутников осуждены за преступление в Зимбабве?',
  'err.PreviousConvictionsZimAsk.required': 'Пожалуйста, ответьте на вопрос о предыдущих осуждениях в Зимбабве',
  'err.PreviousConvictionsZim.required': 'Требуются данные о предыдущих осуждениях в Зимбабве',
  'form.plannedEntry': 'Через какой пункт въезда вы планируете въехать в Зимбабве?',
  'form.plannedEntry.desc': 'Пожалуйста, выберите планируемый пункт въезда в Зимбабве.',
  'zwevisa.DYN.desc': 'Позволяет одноразовый въезд в страну в течение указанного периода в один (1) месяц, для последующих въездов требуется новая виза.',
  'zwevisa.DYN.long_desc': 'Эта виза позволяет одноразовый въезд в страну в течение указанного периода в один (1) месяц.</br>Подходит для путешественников, планирующих один визит в Зимбабве в течение короткого периода.</br>После первоначального въезда требуется новая виза для последующих визитов, что делает её менее удобной для тех, кому нужно совершить несколько визитов в короткий срок.',
  'zwevisa_double.DYN.desc': 'Позволяет два отдельных въезда в страну в течение указанного периода в три (3) месяца, идеально для путешественников с несколькими запланированными визитами.',
  'zwevisa_double.DYN.long_desc': 'Двойная виза позволяет два отдельных въезда в страну в течение указанного периода в три (3) месяца.</br>Этот тип визы идеально подходит для путешественников, которые планируют несколько визитов, таких как бизнесмены или участники мероприятий в Зимбабве.</br>Она предлагает больше гибкости, чем одноразовая виза, но по-прежнему требует новую визу для дополнительных визитов сверх двух разрешённых въездов.',
  'zwevisa_multiple.DYN.desc': 'Предоставляет владельцу возможность многократного въезда в страну назначения в течение указанного периода в шесть (6) месяцев, облегчая частые поездки с деловыми или туристическими целями.',
  'zwevisa_multiple.DYN.long_desc': 'Эта виза предоставляет владельцу возможность многократного въезда в страну назначения в течение указанного периода в шесть (6) месяцев.</br>Она подходит для частых путешественников, которым нужно посетить Зимбабве несколько раз для деловых или туристических целей.</br>Виза для многократных въездов предлагает наибольшую гибкость среди упомянутых типов виз, позволяя неограниченное количество въездов в течение указанного периода.',
  'zwevisa_kaza.DYN.desc': 'Специальная виза, позволяющая въезд в несколько стран в пределах трансграничного охраняемого района Каванго-Замбези (KAZA TFCA) на срок один (1) месяц, способствующая региональному туризму и усилиям по охране природы.',
  'zwevisa_kaza.DYN.long_desc': 'KAZA UNIVISA - это специальная виза, позволяющая въезд в несколько стран в пределах трансграничного охраняемого района Каванго-Замбези (KAZA TFCA) на срок один (1) месяц.</br>Она способствует региональному туризму и усилиям по охране природы, упрощая поездки между участвующими странами, включая <b>Зимбабве</b>, <b>Замбию</b> и <b>Ботсвану</b>.</br>KAZA UNIVISA действует в течение 30 дней и позволяет многократный въезд в KAZA TFCA.',
  'zwevisaarr.DYN.desc': 'Позволяет лицам, имеющим право, получить визу по прибытии в пункт назначения, упрощая процесс въезда для краткосрочных визитов. Эта виза действительна в течение одного (1) месяца.',
  'zwevisaarr.DYN.long_desc': 'Эта виза позволяет лицам, имеющим право, получить визу по прибытии в пункт назначения, упрощая процесс въезда для краткосрочных визитов.</br>Она подходит для путешественников, у которых нет времени или ресурсов для подачи заявления на визу заранее.</br>Виза по прибытии обычно действительна в течение короткого периода, например, 30 дней, и может иметь ограничения на количество разрешённых въездов. Эта виза действует в течение одного (1) месяца.',
  'zwevisaarr_double.DYN.desc': 'Позволяет лицам, имеющим право, получить визу, позволяющую два въезда по прибытии в пункт назначения, обеспечивая гибкость для нескольких визитов. Эта виза действительна в течение трёх (3) месяцев.',
  'zwevisaarr_double.DYN.long_desc': 'Двойная виза по прибытии позволяет лицам, имеющим право, получить визу, позволяющую два въезда по прибытии в пункт назначения.</br>Она обеспечивает гибкость для путешественников, которым нужно совершить несколько визитов в течение срока действия визы.</br>Этот тип визы подходит для бизнесменов или туристов, планирующих посетить Зимбабве и другие соседние страны. Эта виза действительна в течение трёх (3) месяцев.',
  'zwevisaarr_multiple.DYN.desc': 'Предоставляет лицам, имеющим право, визу, позволяющую многократные въезды по прибытии в пункт назначения, удовлетворяя потребности частых посетителей. Эта виза действительна в течение шести (6) месяцев.',
  'zwevisaarr_multiple.DYN.long_desc': 'Эта виза предоставляет лицам, имеющим право, визу, позволяющую многократные въезды по прибытии в пункт назначения.</br>Она удовлетворяет потребности частых посетителей, которым нужно несколько раз посетить Зимбабве в течение короткого периода.</br>Виза для многократных въездов по прибытии предлагает наибольшую гибкость среди упомянутых типов виз, позволяя неограниченное количество въездов в течение указанного периода. Эта виза действительна в течение шести (6) месяцев.',
  'zwevisaarr_kaza.DYN.desc': 'Вариант KAZA Univisa, получаемый по прибытии в пункт назначения, предлагающий удобство для путешественников, исследующих несколько стран в пределах KAZA TFCA. Эта виза действительна в течение одного (1) месяца.',
  'zwevisaarr_kaza.DYN.long_desc': 'Этот вариант KAZA UNIVISA получается по прибытии в пункт назначения.</br>Он предлагает удобство для путешественников, исследующих несколько стран в пределах KAZA TFCA, включая <b>Зимбабве</b>, <b>Замбию</b> и <b>Ботсвану</b>.</br>KAZA UNIVISA по прибытии действует в течение одного (1) месяца и позволяет многократный въезд в KAZA TFCA.',
  'zweentry.DYN.desc': 'Граждане Зимбабве, а также путешественники из выбранных стран могут въезжать без предварительного получения визы.',
  'zweentry.DYN.long_desc': 'Граждане Зимбабве, а также путешественники из выбранных стран могут въезжать без предварительного получения визы.</br>Этот тип визы подходит для краткосрочных визитов и туристических целей.</br>Он позволяет путешественникам въехать в страну без необходимости подачи заявления на визу, упрощая процедуры въезда.</br></br>Однако важно отметить, что въезд без визы не позволяет многократных въездов или продлённых пребываний за пределами указанного срока.',
  'zwevisa_visitor_details_text': '<div class="mb_40">Если вы являетесь гражданином страны, указанной в <a href="https://www.evisa.gov.zw/faq.html" target="_blank"><b>Категория A (нажмите, чтобы открыть)</b></a>, вам не требуется виза для поездки в Зимбабве.</div>',
  'zwevisa.description': 'Виза для одноразового въезда позволяет один въезд в Зимбабве на срок в один (1) месяц. После использования повторный въезд возможен только с новой визой.',
  'zwevisa_double.description': 'Виза для двух въездов позволяет два въезда в Зимбабве в течение трёх (3) месяцев с даты выдачи.',
  'zwevisa_multiple.description': 'Виза для многократных въездов позволяет неограниченное количество въездов в Зимбабве в течение шести (6) месяцев с даты выдачи.',
  'zwevisaarr_multiple.description': 'Виза для многократных въездов по прибытии позволяет неограниченное количество въездов в Зимбабве в течение шести (6) месяцев с даты выдачи. Эта виза выдается непосредственно по прибытии в пункт назначения, предлагая удобное и эффективное решение для путешественников, планирующих несколько краткосрочных визитов.',
  'zwevisa_kaza.description': 'KAZA UNIVISA - это уникальный проездной документ, который улучшает ваши путешествия по трансграничному охраняемому району Каванго-Замбези (KAZA TFCA). Включая Замбию, Зимбабве, Ботсвану, Намибию и Анголу, эта виза упрощает перемещение между этими разнообразными регионами, позволяя вам беспрепятственно насладиться их богатыми культурными и природными достопримечательностями.',
  'zwevisaarr_multiple': 'Виза для многократных въездов по прибытии',
  'zwevisaarr.description': 'Виза для одноразового въезда по прибытии - это простой проездной документ, позволяющий правомочным посетителям подать заявление на визу и получить её по прибытии в пункт назначения. Она отличается быстрой обработкой, прямой оплатой сборов и широкой правомочностью, предлагая удобный и гибкий вариант для краткосрочного пребывания. Путешественникам следует проверить конкретные требования для своей страны назначения, так как правомочность может различаться в зависимости от страны.',
  'zwevisaarr.title': 'Виза для одноразового въезда по прибытии',
  'zwevisaarr_double.title': 'Виза для двух въездов по прибытии',
  'zwevisaarr_kaza.title': 'KAZA-виза по прибытии',
  'zwevisaarr_multiple.title': 'Виза для многократных въездов по прибытии',
  'zwevisa': 'Виза для одноразового въезда',
  'zwevisa_double': 'Виза для двух въездов',
  'zwevisa_multiple': 'Виза для многократных въездов',
  'zwevisa_kaza': 'KAZA Univisa',
  'zwevisa_double.title': 'Виза для двух въездов',
  'zwevisa_multiple.title': 'Виза для многократных въездов',
  'zwevisa_kaza.title': 'KAZA Univisa',
  'zwevisaarr': 'Виза для одноразового въезда по прибытии',
  'zwevisaarr_double': 'Виза для двух въездов по прибытии',
  'zwevisaarr_kaza': 'KAZA-виза по прибытии',
  'PreviousZimbabweanVisa': 'Предыдущая зимбабвийская виза',
  'err.PreviousZimbabweanVisa.required': 'Требуется предыдущая зимбабвийская виза',
  'visaPriceArrival.desc': 'Это цена, которую вам нужно будет заплатить по прибытии в Зимбабве. Этот платеж не подлежит возврату.',
  'document_text': 'Пожалуйста, предоставьте следующую информацию честно и так, как она указана в вашем паспорте или других официальных документах. Департамент иммиграции (Зимбабве) не несёт ответственности за любые поддельные данные или неудобства, связанные с ними. Допустимые форматы документов: JPEG, PNG или PDF, и размер файла должен быть менее 1 МБ.',
  'PreviousConvictionsZim': 'Сведения о предыдущих осуждениях в Зимбабве',
  'travel_information_text': 'Пожалуйста, предоставьте следующую информацию честно и так, как она указана в вашем паспорте или других официальных документах. Департамент иммиграции (Зимбабве) не несёт ответственности за любые поддельные данные или неудобства, связанные с ними.',
  'AddressZimbabwe': 'Адрес в Зимбабве',
  'form.addressZimbabwe': 'Адрес проживания',
  'form.previousConvictionsZim': 'Сведения о предыдущих осуждениях в Зимбабве',
  'proof.residence.zim': 'Подтверждение места жительства в Зимбабве',
  'err.PreviousConvictionsZim.pattern': 'Сведения о предыдущих осуждениях в Зимбабве должны быть в правильном формате',
  'DYN.payment_text.zwevisa': '<p>Это заявление на <b>визу для одноразового въезда</b>.</p> <p>Виза для одноразового въезда позволяет один въезд в Зимбабве на срок в один (1) месяц. После использования повторный въезд возможен только с новой визой.</p> <div><p class="mb_2"><b>Пожалуйста, прочтите внимательно:</b></p> <p>Вы не можете путешествовать, пока не получите одобрение заявления на визу. Заявление, которое вы подаёте и оплачиваете сейчас, всё ещё должно пройти административную проверку. Вы получите уведомления по электронной почте после одобрения, запроса на исправление или отказа по данному заявлению.</p></div> <b>Департамент иммиграции Зимбабве оставляет за собой право изменить тип вашей визы в процессе рассмотрения заявления по своему усмотрению. Это может повлиять на стоимость визы. Фактическая стоимость визы будет указана в ответе на одобрение. Изменения невозможны после оплаты.</b></p>',
  'DYN.payment_text.zwevisa_double': '<p>Это заявление на <b>визу для двух въездов</b>.</p> <p>Виза для двух въездов позволяет два въезда в Зимбабве в течение трёх (3) месяцев с даты выдачи.</p> <div><p class="mb_2"><b>Пожалуйста, прочтите внимательно:</b></p> <p>Вы не можете путешествовать, пока не получите одобрение заявления на визу. Заявление, которое вы подаёте и оплачиваете сейчас, всё ещё должно пройти административную проверку. Вы получите уведомления по электронной почте после одобрения, запроса на исправление или отказа по данному заявлению.</p></div> <b>Департамент иммиграции Зимбабве оставляет за собой право изменить тип вашей визы в процессе рассмотрения заявления по своему усмотрению. Это может повлиять на стоимость визы. Фактическая стоимость визы будет указана в ответе на одобрение. Изменения невозможны после оплаты.</b></p>',
  'DYN.payment_text.zwevisa_kaza': '<p>Это заявление на <b>KAZA Univisa</b>.</p> <p>KAZA UNIVISA - это уникальный проездной документ, который улучшает ваши путешествия по трансграничному охраняемому району Каванго-Замбези (KAZA TFCA) на срок в один (1) месяц. Включая Замбию, Зимбабве, Ботсвану, Намибию и Анголу, эта виза упрощает перемещение между этими регионами, позволяя вам беспрепятственно насладиться их богатыми культурными и природными достопримечательностями.</p> <div><p class="mb_2"><b>Пожалуйста, прочтите внимательно:</b></p> <p>Вы не можете путешествовать, пока не получите одобрение заявления на визу. Заявление, которое вы подаёте и оплачиваете сейчас, всё ещё должно пройти административную проверку. Вы получите уведомления по электронной почте после одобрения, запроса на исправление или отказа по данному заявлению.</p></div> <b>Департамент иммиграции Зимбабве оставляет за собой право изменить тип вашей визы в процессе рассмотрения по своему усмотрению. Это может повлиять на стоимость визы. Фактическая стоимость визы будет указана в ответе на одобрение. Изменения невозможны после оплаты.</b></p>',
  'DYN.payment_text.zwevisa_multiple': '<p>Это заявление на <b>визу для многократных въездов</b>.</p> <p>Виза для многократных въездов позволяет неограниченное количество въездов в Зимбабве в течение шести (6) месяцев с даты выдачи.</p> <div><p class="mb_2"><b>Пожалуйста, прочтите внимательно:</b></p> <p>Вы не можете путешествовать, пока не получите одобрение заявления на визу. Заявление, которое вы подаёте и оплачиваете сейчас, всё ещё должна пройти административную проверку. Вы получите уведомления по электронной почте после одобрения, запроса на исправление или отказа по данному заявлению.</p></div> <b>Департамент иммиграции Зимбабве оставляет за собой право изменить тип вашей визы в процессе рассмотрения по своему усмотрению. Это может повлиять на стоимость визы. Фактическая стоимость визы будет указана в ответе на одобрение. Изменения невозможны после оплаты.</b></p>',
  'DYN.payment_text.zwevisaarr': '<p>Это заявление на <b>визу для одноразового въезда по прибытии</b>.</p> <p>Виза для одноразового въезда по прибытии - это простой проездной документ, позволяющий правомочным посетителям подать заявление на визу и получить её по прибытии в пункт назначения с действием в течение одного (1) месяца. Она отличается быстрой обработкой, прямой оплатой сборов и широкой правомочностью, предлагая удобный и гибкий вариант для краткосрочного пребывания. Путешественникам следует проверить конкретные требования для своей страны назначения, так как правомочность может различаться в зависимости от страны.</p><b>Департамент иммиграции Зимбабве оставляет за собой право изменить тип вашей визы в процессе рассмотрения по своему усмотрению. Это может повлиять на стоимость визы. Фактическая стоимость визы будет указана в ответе на одобрение. Изменения невозможны после оплаты.</b></p>',
  'DYN.payment_text.zwevisaarr_double': '<p>Это заявление на <b>визу для двух въездов</b>.</p> <p>Виза для двух въездов позволяет два въезда в страну в течение указанного периода, обычно трёх (3) месяцев с даты выдачи. Она предназначена для тех, кто планирует дважды въехать в страну для краткосрочных визитов. Эта виза предлагает удобство подачи заявления по прибытии в пункт назначения, с быстрой обработкой и прямой оплатой сборов. Правомочность и требования могут различаться в зависимости от страны, поэтому путешественникам следует проверить конкретные детали для своей страны назначения.</p><b>Департамент иммиграции оставляет за собой право изменить тип вашей визы в процессе рассмотрения. Это может повлиять на стоимость визы. Окончательная стоимость будет указана в ответе на одобрение. Изменения невозможны после оплаты.</b></p>',
  'DYN.payment_text.zwevisaarr_kaza': '<p>Это заявление на <b>визу KAZA по прибытии</b>.</p> <p>Виза KAZA по прибытии упрощает въезд в трансграничный охраняемый район Каванго-Замбези, включающий Замбию, Зимбабве, Ботсвану, Намибию и Анголу, на срок один (1) месяц. Она позволяет правомочным путешественникам подать заявление на визу и получить её непосредственно по прибытии в пункт назначения. Эта виза известна своей быстрой обработкой, прямой оплатой сборов и широкой правомочностью, делая её гибким вариантом для тех, кто желает исследовать богатые культурные и природные ландшафты региона KAZA для краткосрочного пребывания. Путешественникам следует убедиться, что они соответствуют конкретным требованиям для каждой страны в пределах KAZA TFCA.</p><b>Иммиграционные власти KAZA оставляют за собой право изменить тип вашей визы в процессе рассмотрения, что может повлиять на стоимость визы. Фактическая стоимость визы будет указана в уведомлении об одобрении. Изменения невозможны после оплаты.</b></p>',
  'DYN.payment_text.zwevisaarr_multiple': '<p>Это заявление на <b>визу для многократных въездов по прибытии</b>.</p> <p>Виза для многократных въездов по прибытии позволяет неограниченное количество въездов в страну назначения в течение шести (6) месяцев с даты выдачи. Она отличается быстрой обработкой и прямой оплатой сборов, предлагая гибкий вариант для путешественников, которым требуется частый доступ для краткосрочных визитов.</p><b>Департамент иммиграции оставляет за собой право изменить тип вашей визы в процессе рассмотрения по своему усмотрению. Это может повлиять на стоимость визы. Фактическая стоимость визы будет указана в ответе на одобрение. Изменения невозможны после оплаты.</b></p>',
  'DYN.payment_text.zweentry': '<p>Это заявление на <b>въезд без визы</b>.</p> <p>Въезд без визы позволяет путешественникам из определённых стран въезжать в страну без предварительного получения визы, обычно для краткосрочного пребывания.</p> <div><p class="mb_2"></p>',
  'zwevisaarr_double.description': 'Двойная виза по прибытии позволяет путешественникам два въезда в страну в течение трёх месяцев с даты выдачи. Эта виза выдается непосредственно по прибытии в пункт назначения, предлагая быстрый и удобный вариант для тех, кто планирует два въезда в страну для краткосрочных визитов. Важно, чтобы путешественники проверили конкретные требования и критерии правомочности для страны назначения.',
  'zwevisaarr_kaza.description': 'Виза KAZA по прибытии позволяет получение визы непосредственно по прибытии в пункт назначения в пределах трансграничного охраняемого района Каванго-Замбези, включающего Замбию, Зимбабве, Ботсвану, Намибию и Анголу. Этот упрощённый процесс позволяет путешественникам эффективно получить визу по прибытии для краткосрочных визитов, упрощая исследование разнообразных достопримечательностей региона. Важно, чтобы путешественники проверили конкретные требования для каждой страны в пределах KAZA TFCA.',
  'print.desc.part1': 'Наличие электронной авторизации на поездку (eTA) не гарантирует въезд в Республику Зимбабве. Разрешение будет определяться иммиграционными властями на пункте въезда.<br><span class="mt_10">Ведение бизнеса или работа в Зимбабве без необходимого разрешения является преступлением. Пожалуйста, предъявите ваш паспорт и эту eTA по запросу авиакомпании или иммиграционных властей.</span>',
  'tooltip.addressZimbabwe': 'Если у заявителя есть адрес в Зимбабве, пожалуйста, введите его здесь.',
  'err.PreviousConvictionsZim.maxlength': 'Сведения о предыдущих осуждениях в Зимбабве должны быть не более 50 символов',
  'zweprovrespermit': 'Временное разрешение на проживание',
  'zwevisaextc': 'Продление пребывания для категории C',
  'zwevisaextab': 'Продление пребывания для категории A или B',
  'zwefeedback': 'Форма обратной связи по пребыванию',
  'zwevisa_visitor_details_title': 'Относится ли ваша национальность к режиму категории A?',
  'refusalReason.desc.DYN.zwevisa': '<p><b>Пожалуйста, обратите внимание, что если используется термин "чёрный список", лицо, связанное с этой визовой заявкой, будет внесено в чёрный список.</b></p><p>Пожалуйста, предоставьте краткое описание причины отказа.</p>',
  'refusalReason.desc.extension.DYN.zwevisa': '<p>Пожалуйста, предоставьте краткое описание причины отказа в продлении этого запроса.</p>',
  'refusalReason.review.desc.DYN.zwevisa': 'Пожалуйста, предоставьте краткое описание проверки как <b>НЕ РЕКОМЕНДУЕТСЯ</b>. Это описание будет отправлено следующему проверяющему.',
  'approveReason.review.desc.DYN.zwevisa': 'Пожалуйста, предоставьте краткое описание проверки как <b>РЕКОМЕНДУЕТСЯ</b>. Это описание будет отправлено следующему проверяющему.',
  'deletePerson.error.DYN.zwevisa': 'Произошла ошибка при удалении человека. Вы не можете удалить человека, у которого уже есть поданная заявка.',
  'DYN.zwevisaarr': 'Виза для одноразового въезда по прибытии',
  'notice.desc.BORDER_PASS': 'Пограничный пропуск - это официальный документ, выданный правительством Зимбабве, позволяющий лицам законно пересекать определённые пограничные пункты.',
  'scan.visaCreated.exit': 'Визовое заявление успешно создано. Теперь вы можете зарегистрировать человека на выезд.',
  'scan.visaCreated.entry': 'Визовое заявление успешно создано. Теперь вы можете зарегистрировать человека на въезд.',
  'forceComparison.show': 'Показать сравнение с отсканированным документом',
  'forceComparison.hide': 'Скрыть сравнение с отсканированным документом',
  'tooltip.forceComparison': 'Сравните информацию между отсканированным документом и заявлением.',
  'noScannedComparison': 'Не найдено соответствующих данных в заявлении',
  'transfertFromPassport': 'Перенести информацию из отсканированного документа.',
  'tooltip.transfertFromPassport': 'Информация между паспортом и отсканированным документом различается. Вы можете перенести информацию из отсканированного документа в заявление.',
  'CompanionsSize': 'Количество спутников',
  'transit.consume': 'Транзит',
  'tooltip.scanPassport': 'Сканировать паспорт человека',
  'passport.scan': 'Сканировать паспорт',
  'tooltip.transitRegister': 'Зарегистрировать транзит человека',
  'transit.register': 'Зарегистрировать транзит',
  'global.goLatest': 'Открыть последнее посещённое заявление',
  'tooltip.goLatest': 'Вы можете вернуться к предыдущему заявлению, которое вы посещали',
  'scan.choice': 'Выбрать режим',
  'form.ad': 'Электронная почта/Имя пользователя',
  'printerOffline': 'Термопринтер отключен. Пожалуйста, проверьте подключение USB к устройству и попробуйте снова. Вы всё ещё хотите распечатать документ на обычном принтере?',
  'printerNotFound': 'Термопринтер не обнаружен в вашем списке устройств. Пожалуйста, проверьте подключение и убедитесь, что драйверы установлены. Вы всё ещё хотите распечатать документ на обычном принтере?',
  'datePrinted': 'Дата печати',
  'printThermal': 'Печать на термопринтере',
  'tooltip.printThermal': 'Распечатать документ на термопринтере или снять галочку, чтобы распечатать на обычном принтере',
  'application.overStay': 'Заявление <b>просрочено</b>',
  'watchlistFound': 'Заявление <b>соответствует списку наблюдения</b>',
  'apipnrFound': 'Заявление <b>соответствует списку API/PNR</b>',
  'payAttention': 'Я буду внимателен',
  'deportedMode': 'Отметить как депортированного',
  'tooltip.deportedMode': 'Отметить человека как депортированного. Если вы отметите человека как депортированного, он будет считаться таковым в системе при регистрации въезда.',
  'deportedComment': 'Причина депортации',
  'payment.inReturned': 'Это заявление было зарегистрировано с <b>статусом депортированного</b> на стойке офицера.',
  'reasonAdd': '<p><b>Причина</b>: <br/> {reason}</p>',
  'global.deported': 'Депортирован',
  'photo.entryCountry.INRETURNED': 'Фото при въезде со статусом депортированного',
  'print.notices': 'Печать уведомлений',
  'ask.noticePrint': 'Вы хотите распечатать уведомление, датированное {date}?',
  'select.notice': 'Выберите уведомление для печати',
  'ask.selectNotice': 'Пожалуйста, выберите уведомление, которое хотите распечатать.',
  'noChoice.selectedNotice': 'Уведомление не выбрано',
  'tooltip.printNotices': 'Распечатать уведомления, распечатанные офицером во время регистрации или отказа',
  'ask.revertConsume': 'Вы уверены, что хотите отменить это событие?',
  'cancelled': 'Отменено',
  'tooltip.revertConsume': 'Вы можете отменить это событие для этого заявления',
  'done.revertConsume': 'Событие было успешно отменено',
  'revert.consume': 'Причина отмены',
  'error.revertConsume': 'Произошла ошибка при отмене события. Пожалуйста, попробуйте снова.',
  'outUsage.AB': 'Заявление <b>вышло за пределы срока его действия</b>. Пожалуйста, направьте заявителя к киоску или помогите ему подать заявление повторно.',
  'outUsage.C': 'Заявление <b>вышло за пределы срока его действия</b>. Пожалуйста, закройте это заявление, затем создайте новую запись для заявителя и продолжите отказ.',
  'print.date': 'Дата печати',
  'print.id': 'Связано с ID заявления',
  'internalReference.badPattern': 'Внутренняя ссылка должна соответствовать формату "номер_год" (например, 140_2024).',
  'showFullDatas': 'Показать все документы',
  'hideFullDatas': 'Скрыть все документы',
  'tooltip.showFullDatas': 'Показать или скрыть все документы заявления',
  'watchlist.null': '<b>Система {system} не может быть проверена</b>',
  'watchlist': 'Список наблюдения',
  'apipnr': 'API-PNR',
  'global.refuseNoCam': 'Отказать без камеры',
  'global.refuseIn': 'Отказать на регистрации',
  'global.refuseOut': 'Отказать при выезде',
  'photo.entryCountry.REFUSEIN': 'Фото при регистрации со статусом отказа',
  'photo.entryCountry.REFUSEOUT': 'Фото при выезде со статусом отказа',
  'global.refuseFingerPrint': 'Отказать с использованием отпечатка пальца',
  'tooltip.refuseFingerPrint': 'Отказать человеку с использованием отпечатка пальца',
  'tooltip.consumeRefuseNoCam': 'Отказать человеку без использования камеры',
  'ask.refuseCamera': 'Отказать с камерой',
  'tooltip.disabledBecauseReview': 'Этот человек в данный момент связан с заявкой, которая находится на рассмотрении у руководителя. Чтобы создать новую заявку для этого человека, сначала нужно дождаться окончания проверки.',
  'client.extended.noValidPerson': 'Человек, связанный с этим платежом, не найден. Пожалуйста, проверьте ваш список сохраненных людей и попробуйте снова.',
  'zwevisaarr.arrivalPrint': 'Однократная виза, подлежащая оплате по прибытии',
  'zwevisaarr_double.arrivalPrint': 'Двукратная виза, подлежащая оплате по прибытии',
  'zwevisaarr_multiple.arrivalPrint': 'Многократная виза, подлежащая оплате по прибытии',
  'zwevisaarr_kaza.arrivalPrint': 'УниВиза KAZA, подлежащая оплате по прибытии',
  'transfertHKG': 'Отметить эту заявку как заявку для Гонконга',
  'tooltip.transfertHKG': 'Вы можете отметить эту заявку как заявку для Гонконга на основе отсканированного китайского паспорта',
  'ask.updateForHKG': 'Вы уверены, что хотите обновить эту заявку для Гонконга?',
  'done.updateForHKG': 'Заявка успешно обновлена для Гонконга',
  'error.updateForHKG': 'Произошла ошибка при обновлении заявки для Гонконга. Пожалуйста, попробуйте снова.'
};
