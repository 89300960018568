import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ComsumePaymentData, NoticeType, RequestWithDuplicata} from '../../../shared/models/request';
import {ApiService} from '../../../shared/services/api.service';
import {DocumentType} from '../../../shared/models/user';
import {FormService, LoaderService, NavigateService} from 'ngx-satoris';
import {flattenObject} from '../../../shared/utils/flattenObject';
import {Visa} from '../../../shared/models/local-form';
import {VisaFN} from '../../../shared/models/forms';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {addYears, format, isAfter} from 'date-fns';
import {Place} from '../../../shared/models/place';
import {generateQrOffline} from '../../../shared/utils/generateQr';
import {PrintService} from '../../../shared/services/print.service';
import {AllDocumentsPrintType, PrintNoticeData, PrintNoticeRestrictionData} from '../../../shared/models/print';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-admin-request-notice',
  templateUrl: './admin-request-notice.component.html',
  styleUrl: './admin-request-notice.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AdminRequestNoticeComponent {

  requestId: string;
  currentRequest: RequestWithDuplicata;
  meta: any;
  form: FormGroup;
  fromRoute: string;
  submitted = false;
  isLoading = true;
  currentNotice: NoticeType;
  purposes = ['conditionalEntry', 'departure', 'transit'];
  currentPlace: Place;
  refusalReasonEnum: string[];
  restrictionReportingPostEnum: string[];
  restrictionDocumentToProduceEnum: string[];
  reportingReportingPostEnum: string[];
  query: any;
  backParams: any;
  subscription: any;
  showOtherDocument: boolean;
  showParagraph: boolean;
  qrCode: string;
  validatorDates = {
    minDate: format(new Date(), 'yyyy-MM-dd'),
    maxDate: format(addYears(new Date(), 150), 'yyyy-MM-dd')
  };

  printSubscription: Subscription;

  constructor(public api: ApiService,
    public nav: NavigateService,
    public forms: FormService,
    private formBuilder: FormBuilder,
    private print: PrintService,
    private loader: LoaderService,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.api.schema('zweentry-consume', true).then((res) => {
      this.refusalReasonEnum = res.properties.Documents.items.properties.RefusalFields.properties.Reason.enum;
      this.restrictionReportingPostEnum = res.properties.Documents.items.properties.RestrictionFields.properties.ReportingPost.enum;
      this.restrictionDocumentToProduceEnum = res.properties.Documents.items.properties.RestrictionFields.properties.DocumentToProduce.items.enum;
      this.reportingReportingPostEnum = res.properties.Documents.items.properties.ReportingFields.properties.ReportingPost.enum;
      this.subscription = this.route.paramMap.subscribe(params => {
        this.requestId = params.get('paymentId');
        return this.payment().then(() => this.route.queryParams.subscribe(params => {
          const {createdAt, paymentId, placeID, userPlaceID, operation, from, paymentReference, occupationMedia, fromScanPassport, fromNoticePrint, fromScanFingerprint, cashType, notice, referencePrint, datePrint, nameClient, amount, currency, agentId, typePayment, placeId, buyDocument, idRequest, paymentIsNotDocument, borderPass, purposeOfVisit, skipCamera, refuseFingerPrint, forceOperation, ...rest} = params;
          this.query = {createdAt, paymentId, placeID, userPlaceID, operation, from, paymentReference, occupationMedia, fromNoticePrint, fromScanPassport, cashType, notice, fromScanFingerprint, referencePrint, datePrint, nameClient, amount, currency, agentId, typePayment, placeId, buyDocument, idRequest, paymentIsNotDocument, borderPass, purposeOfVisit, skipCamera, refuseFingerPrint, forceOperation, ...rest};
          this.backParams = this.query.fromScanFingerprint === 'true' ? this.query : {from: this.query.from, ...rest};
          this.fromRoute = params.from || '';
          this.currentNotice = params.notice || null;
          params.notice && this.setForm();
          this.isLoading = false;
        }));
      });
    }).catch(() => {
      this.loader.loading(true, {type: 'error', message: 'global.error'});
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.printSubscription?.unsubscribe();
  }

  setForm() {
    this.submitted = false;
    this.showOtherDocument = false;
    this.showParagraph = false;
    const dateRangeValidator: ValidatorFn = (form: FormGroup): ValidationErrors | null => {
      const startControl = form.get('periodFrom');
      const endControl = form.get('periodTo');
      if(!startControl || !endControl) return null;
      const startDate = new Date(startControl.value);
      const endDate = new Date(endControl.value);
      if(startControl.value && endControl.value && isAfter(startDate, endDate)) {
        startControl.setErrors({...startControl.errors, periodFromGreaterThanPeriodTo: true});
      } else {
        if(startControl.errors) {
          delete startControl.errors.periodFromGreaterThanPeriodTo;
          if(!Object.keys(startControl.errors).length) {
            startControl.setErrors(null);
          }
        }
      }
      return null;
    };
    switch(this.currentNotice) {
    case NoticeType.REPORTING:
      this.form = this.formBuilder.group({
        hqReference: [this.currentRequest.internalReference || ''],
        stationReference: [this.currentPlace.longName +' ['+ this.currentPlace.id +']' || '', Validators.required],
        purpose: ['', Validators.required],
        fullNames: [(this.meta?.FirstName || '') + (this.meta?.LastName ? ' ' + this.meta.LastName : ''), Validators.required],
        host: [''],
        phone: ['', [Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$')]],
        hostPhone: [''],
        addressZimbabwe: [this.meta?.AddressZimbabwe || ''],
        email: ['', [Validators.email]],
        address: [''],
        periodFrom: ['', Validators.required],
        periodTo: ['', Validators.required],
        officer: [(this.api.userInfo.name +' ['+ this.api.userInfo.id) +']' || '', Validators.required],
        reportAt: ['', Validators.required],
        reportOn: ['', Validators.required],
        reportInterval: ['', Validators.required]
      }, {validators: dateRangeValidator});
      if(this.currentPlace) this.form.controls.stationReference.disable();
      if(this.currentRequest.internalReference) this.form.controls.hqReference.disable();
      if(this.meta?.FirstName && this.meta?.LastName) this.form.controls.fullNames.disable();
      if(this.api.userInfo.name) this.form.controls.officer.disable();
      break;
    case NoticeType.RESTRICTION:
      this.form = this.formBuilder.group({
        hqReference: [this.currentRequest.internalReference || ''],
        stationReference: [this.currentPlace.longName +' ['+ this.currentPlace.id +']' || '', Validators.required],
        fullNames: [(this.meta?.FirstName || '') + (this.meta?.LastName ? ' ' + this.meta.LastName : ''), Validators.required],
        reportLocation: ['', Validators.required],
        daysUntilReport: ['', Validators.required],
        otherMissingDocument: [''],
        residentialAddress: [this.meta?.AddressZimbabwe || ''],
        businessAddress: [''],
        phone: ['', [Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$')]],
        email: ['', [Validators.email]],
        officer: [(this.api.userInfo.name +' ['+ this.api.userInfo.id) +']' || '', Validators.required]
      });
      this.restrictionDocumentToProduceEnum.forEach((doc) => {
        this.form.addControl(doc, this.formBuilder.control(''));
      });
      if(this.meta?.FirstName && this.meta?.LastName) this.form.controls.fullNames.disable();
      if(this.currentPlace) this.form.controls.stationReference.disable();
      if(this.currentRequest.internalReference) this.form.controls.hqReference.disable();
      this.form.controls.officer.disable();
      break;
    case NoticeType.REFUSAL:
      this.form = this.formBuilder.group({
        fullNames: [(this.meta?.FirstName || '') + (this.meta?.LastName ? ' ' + this.meta.LastName : ''), Validators.required],
        reportLocation: [this.currentPlace.longName +' ['+ this.currentPlace.id +']' || '', Validators.required],
        paragraph: ['', Validators.pattern('^[a-z]$')],
        reason: ['', Validators.required],
        dateOfRefusal: [format(new Date(), 'yyyy-MM-dd'), Validators.required],
        place: [this.currentPlace.longName +' ['+ this.currentPlace.id +']' || '', Validators.required],
        officer: [(this.api.userInfo.name +' ['+ this.api.userInfo.id) +']' || '', Validators.required],
        operationComments: ['', [Validators.required, Validators.maxLength(500)]]
      });
      if(this.meta?.FirstName && this.meta?.LastName) this.form.controls.fullNames.disable();
      if(this.currentPlace) {
        this.form.controls.reportLocation.disable();
        this.form.controls.place.disable();
      }
      this.form.controls.dateOfRefusal.disable();
      if(this.api.userInfo.name) this.form.controls.officer.disable();
      break;
    }
  }

  submit() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.submitted = false;
      const docs: string[] = this.restrictionDocumentToProduceEnum?.filter((doc) => this.form.value[doc]);
      if(this.form.value.otherMissingDocument?.trim()) docs.push('Other');
      const data = {
        ProfilePicture: undefined,
        Operation: this.currentNotice === NoticeType.REFUSAL ? this.query.operation : undefined,
        OperationComments: this.form.value.operationComments || undefined,
        PaymentReference: undefined,
        PaymentMode: undefined,
        PaymentCurrency: undefined,
        Documents: [{
          Type: this.currentNotice,
          RefusalFields: this.currentNotice === NoticeType.REFUSAL ? {
            Paragraph: this.form.value.paragraph?.trim() || undefined,
            Reason: this.form.value.reason || undefined
          } : undefined,
          RestrictionFields: this.currentNotice === NoticeType.RESTRICTION ? {
            ReportingPost: this.form.value.reportLocation || undefined,
            GracePeriodDays: Number(this.form.value.daysUntilReport) || undefined,
            ResidentialAddress: this.form.value.residentialAddress?.trim() || undefined,
            BusinessAddress: this.form.value.businessAddress?.trim() || undefined,
            Phone: this.form.value.phone?.trim() || undefined,
            Email: this.form.value.email?.trim() || undefined,
            DocumentToProduce: docs || undefined,
            DocumentToProduceOther: this.form.value.otherMissingDocument?.trim() || undefined
          } : undefined,
          ReportingFields: this.currentNotice === NoticeType.REPORTING ? {
            Purpose: this.form.value.purpose || undefined,
            Host: this.form.value.host?.trim() || undefined,
            HostPhone: this.form.value.hostPhone?.trim() || undefined,
            AddressZimbabwe: this.form.value.addressZimbabwe?.trim() || undefined,
            Phone: this.form.value.phone?.trim() || undefined,
            Email: this.form.value.email?.trim() || undefined,
            Address: this.form.value.address?.trim() || undefined,
            ReportingPost: this.form.value.reportAt || undefined,
            ExpectedDeparture: new Date(this.form.value.periodTo)?.getTime() || undefined,
            ReportOn: new Date(this.form.value.reportOn)?.getTime() || undefined,
            ReportAtIntervalDays: Number(this.form.value.reportInterval) || undefined,
            PeriodFrom: new Date(this.form.value.periodFrom)?.getTime() || undefined,
            PeriodTo: new Date(this.form.value.periodTo)?.getTime() || undefined
          } : undefined
        }]
      } as ComsumePaymentData;

      Object.keys(data).forEach((key: keyof ComsumePaymentData) => {
        if(data[key] === undefined) {
          delete data[key];
        }
      });

      const noticeData: PrintNoticeData | PrintNoticeRestrictionData = {
        form: this.form,
        qrCode: this.qrCode,
        documents: this.currentNotice === NoticeType.RESTRICTION ? this.docsToProducePrint() : undefined
      };
      this.print.print(this.currentNotice, noticeData);

      this.printSubscription = this.print.printLoaded.subscribe((loaded: AllDocumentsPrintType) => {
        if(loaded) {
          this.loader.loading(true, {type: 'question', message: 'print.isDone', btnLabel: 'yes', custom: {closeBtnLabel: 'no'}}).then((done: boolean) => {
            if(done) {
              window.sessionStorage.setItem('noticePrint', JSON.stringify(data));
              this.nav.to('admin-request-face-check', undefined, {queryParams: {...this.query, fromNoticePrint: true}});
            } else {
              this.print.print(this.currentNotice, noticeData);
            }
          });
        }
      });
    }
  }

  selectNotice(notice: NoticeType) {
    this.currentNotice = notice;
    this.setForm();
  }

  payment() {
    this.loader.loading(true);
    return this.api.payment(this.requestId, true, true, true, true).then((request: RequestWithDuplicata) => {
      this.currentRequest = request;
      this.currentPlace = this.api.userPlaces.find(place => place.id === this.api.userPlaceId);
      this.qrCode = generateQrOffline(this.currentRequest.id, this.currentPlace.id, this.currentRequest.externalId);
      return this.api.getMetadataPayment(request.serialized).then((res: any) => {
        this.meta = flattenObject(res as Visa);
        if(this.meta.NextOfKin) {
          this.meta.NextOfKin = JSON.parse(this.meta.NextOfKin);
          this.meta[VisaFN.EMERGENCY_LAST_NAME] = this.meta.NextOfKin.lastname;
          this.meta[VisaFN.EMERGENCY_FIRST_NAME] = this.meta.NextOfKin.firstname;
          this.meta[VisaFN.EMERGENCY_EMAIL] = this.meta.NextOfKin.email;
          this.meta[VisaFN.EMERGENCY_PHONE] = this.meta.NextOfKin.phone;
        }
        this.loader.loading(false);
      }).catch(() => {
        this.loader.loading(false);
      });
    }).catch(() => {
      this.loader.loading(false);
    });
  }

  docsToProducePrint(): string[] {
    return this.restrictionDocumentToProduceEnum.filter((doc) => this.form.value[doc]);
  }

  checkIfFormControl(event: any, doc: string, controlName: string) {
    if(doc !== 'Other') return;
    if(event.checked) {
      this.showOtherDocument = true;
      this.form.controls[controlName].addValidators(Validators.required);
      setTimeout(() => {
        document.getElementById(controlName).focus();
      });
    } else {
      this.showOtherDocument = false;
      this.form.controls[controlName].setValue('');
      this.form.controls[controlName].clearValidators();
    }
    this.form.controls[controlName].updateValueAndValidity();
  }

  protected readonly DocumentType = DocumentType;
  protected readonly ConsumePaymentNoticeType = NoticeType;

  checkIfReasonHasParagraph(event: any) {
    if(event.includes('PARX')) {
      this.showParagraph = true;
      this.form.controls.paragraph.setValidators(Validators.required);
      this.form.controls.paragraph.setValidators(Validators.pattern('^[a-z]$'));
    } else {
      this.showParagraph = false;
      this.form.controls.paragraph.setValue('');
      this.form.controls.paragraph.clearValidators();
    }
    this.form.controls.paragraph.updateValueAndValidity();
  }

  goBack() {
    if((this.query.fromScanFingerprint === 'true' && this.currentNotice === null) || this.query.refuseFingerPrint === 'true') {
      this.nav.to('admin-scan-fingerprint', undefined, {queryParams: this.backParams});
    } else if(this.fromRoute && (this.query.notice || this.currentNotice === null)) {
      this.nav.to(this.fromRoute, undefined, {queryParams: this.backParams});
    } else {
      this.currentNotice = null;
    }
  }
}
