import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {Subscription} from 'rxjs';
import {RequestWithDuplicata} from 'src/app/shared/models/request';
import {ApiService} from 'src/app/shared/services/api.service';
import {RequestService} from 'src/app/shared/services/request.service';
import {ScanDocumentService} from 'src/app/shared/services/scan-document.service';
import {convertToEpoch} from 'src/app/shared/utils/date';
import {parseIndexedData} from 'src/app/shared/utils/parseMetadata';

@Component({
  selector: 'app-scanned-list',
  templateUrl: './scanned-list.component.html',
  styleUrl: './scanned-list.component.scss',
  encapsulation: ViewEncapsulation.None
})

export class ScannedListComponent {

  convertToEpoch = convertToEpoch;
  parseIndexedData = parseIndexedData;
  fromRoute = '';
  searchParams = {};
  reset = true;
  private scanSubscription: Subscription;

  constructor(public scanDocument: ScanDocumentService,
    public api: ApiService,
    public request: RequestService,
    public nav: NavigateService,
    private route: ActivatedRoute,
    private loader: LoaderService) {
    this.route.queryParams.subscribe((params: any) => {
      const {fromRoute, ...rest} = params;
      this.fromRoute = fromRoute;
      this.searchParams = rest;
    });
    if(!this.scanDocument.scanDatas || !this.scanDocument.visaDatas) {
      this.nav.to(this?.fromRoute || 'dashboard');
    }
    if(this.scanDocument?.documentPhoto && !this.scanDocument?.documentError) {
      this.scanDocument.documentError = 'passport.valid';
    }
  }

  ngOnInit() {
    this.scanSubscription = this.scanDocument.visaDatas$.subscribe((visaDatas) => {
      if(!visaDatas || !visaDatas.length) {
        this.nav.to('dashboard');
      } 
    });
  }

  ngOnDestroy() {
    this.scanSubscription.unsubscribe();
  }

  navTo(visa: RequestWithDuplicata) {
    const navAction = () => {
      this.reset = false;
      this.nav.to('admin-request-select/' + visa.id, undefined, {queryParams: {from: 'admin-scanned-list'}});
    };
    if(visa.viewOutOfState) {
      this.loader.loading(true, {type: 'warn', message: 'otherState.warning.ask', btnLabel: 'otherState.confirm'}).then((res) => {
        if(res) {
          navAction();
        }
      });
    } else {
      navAction();
    }
  }

  goBack() {
    if(this.fromRoute === 'admin-request') {
      this.nav.to('admin-request', undefined, {queryParams: this.searchParams});
    } else {
      this.nav.to('dasbhoard');
    }
  }
}
