<app-print-data *ngIf="print.getDoc(DocsPrintType.APPLICATION).active"
                [data]="print.getDoc(DocsPrintType.APPLICATION).data"
                (init)="print.readyToPrint(DocsPrintType.APPLICATION)"></app-print-data>

<app-print *ngIf="print.getDoc(DocsPrintType.APPROVAL_LETTER).active"
           [data]="print.getDoc(DocsPrintType.APPROVAL_LETTER).data"
           (init)="print.readyToPrint(DocsPrintType.APPROVAL_LETTER)"></app-print>

<app-print-receipt-a5 *ngIf="print.getDoc(DocsPrintType.RECEIPT).active"
                      [data]="print.getDoc(DocsPrintType.RECEIPT).data"
                      (init)="print.readyToPrint(DocsPrintType.RECEIPT)"></app-print-receipt-a5>

<app-print-notice-border-pass *ngIf="print.getDoc(DocsPrintType.BORDER_PASS).active"
                              [data]="print.getDoc(DocsPrintType.BORDER_PASS).data"
                              (init)="print.readyToPrint(DocsPrintType.BORDER_PASS)"></app-print-notice-border-pass>

<app-print-notice-restriction *ngIf="print.getDoc(NoticeType.RESTRICTION).active"
                              [data]="print.getDoc(NoticeType.RESTRICTION).data"
                              (init)="print.readyToPrint(NoticeType.RESTRICTION)"></app-print-notice-restriction>

<app-print-notice-reporting *ngIf="print.getDoc(NoticeType.REPORTING).active"
                            [data]="print.getDoc(NoticeType.REPORTING).data"
                            (init)="print.readyToPrint(NoticeType.REPORTING)"></app-print-notice-reporting>

<app-print-notice-refusal *ngIf="print.getDoc(NoticeType.REFUSAL).active"
                          [data]="print.getDoc(NoticeType.REFUSAL).data"
                          (init)="print.readyToPrint(NoticeType.REFUSAL)"></app-print-notice-refusal>
