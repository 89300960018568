import {Injectable} from '@angular/core';
import {DialogsService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {Person} from '../models/person';
import {ApiService} from './api.service';
import {DocumentType} from '../models/user';
import {VISA_FORM_CONFIG} from '../models/local-form';
import {Direction, FormField, VisaFN, VisaFormConfig, VisaFormName} from '../models/forms';
import {CartSyncRow, Request, consumeDataCall} from '../models/request';
import {BehaviorSubject} from 'rxjs';
import {VISA_ARRIVAL_FORM_CONFIG} from '../models/arrival-form';
import {DialogParticipantsComponent} from '../../components/dialogs/dialog-participants/dialog-participants.component';
import * as uuid from 'uuid';
import {EXTEND_VISA_FORM_CONFIG} from '../models/VISA-ZIM/extend-form';
import {Validators} from '@angular/forms';
import {Store} from '../models/store';
import {StorageService} from './storage.service';

export type CartScenario = 'document' | 'person' | 'visa' | 'tracking-control';
export type CartState = 'meansPayment' | 'onlinePayment' | 'processPayment' | 'selfie';

declare const window: any;

export interface FormApplication {
  travelName: string,
  plannedEntry: string,
  currentPerson?: Person,
  submitted?: boolean,
  requests?: Request[],
  visaFeesAndTerms: boolean,
  payOnline?: 'true' | 'false',
  isRevision?: boolean,
  isExtend?: boolean,
  isResume?: boolean,
  skipValidRevision?: boolean,
  revisionFields?: VisaFN[],
  requestId?: string,
  visaReference?: string,
}
export interface CartStoreRow {
  id?: number;
  currentApplication?: FormApplication;
}

@Injectable({
  providedIn: 'root'
})
export class CartService {
  originalVisaFormConfig: VisaFormConfig = {...VISA_FORM_CONFIG};
  originalVisaArrivalFormConfig: VisaFormConfig = {...VISA_ARRIVAL_FORM_CONFIG};
  originalExtendVisaFormConfig: VisaFormConfig = {...EXTEND_VISA_FORM_CONFIG};
  syncQueue: consumeDataCall[] = [];
  currentApplication: FormApplication = {travelName: '', plannedEntry: null, currentPerson: null, requests: [], visaFeesAndTerms: false};
  currentApplication$: BehaviorSubject<FormApplication> = new BehaviorSubject(undefined);
  syncQueue$: BehaviorSubject<consumeDataCall> = new BehaviorSubject(undefined);

  constructor(private nav: NavigateService,
    private api: ApiService,
    private dialog: DialogsService,
    private loader: LoaderService,
    private storage: StorageService,
    private lang: LangService) {}


  updateParticipants(participants: Person[]) {
    participants.forEach(p => {
      if(!this.currentApplication.requests) this.currentApplication.requests = [];
      if(!this.currentApplication.requests.some(r => r.person.id === p.id)) {
        this.currentApplication.requests.push({person: p});
      }
    });
    this.currentApplication.requests = this.currentApplication.requests?.filter(r => participants.find(p => p.id === r.person.id));
    this.currentRequest = this.currentApplication.requests.find(r => r.person.id === this.currentApplication.currentPerson.id);
  }

  get currentRequest(): Request {
    return this.currentApplication.requests.find(r => r.person.id === this.currentApplication.currentPerson.id);
  }

  set currentRequest(request: Request) {
    const index = this.currentApplication.requests.findIndex(r => r.person.id === this.currentRequest.person.id);
    if(index > -1) {
      this.currentApplication.requests[index] = request;
    }
  }

  personRequestIsValid(person: Person): boolean {
    const request: Request = this.currentApplication.requests.find(r => r.person.id === person.id);

    if(this.api.userRole.isCustomer && this.requiredDocumentsWithoutValue(person).length > 0) {
      return false;
    }

    const isNationalityInvalid = !request.nationality && !this.currentApplication.isExtend;
    const isPassportTypeInvalid = !request.passportType && !this.currentApplication.isExtend;
    const isVisaTypeInvalid = !request.visaType;
    const isFormConfigInvalid = !request.formConfig;

    if(isNationalityInvalid || isPassportTypeInvalid || isVisaTypeInvalid || isFormConfigInvalid) {
      return false;
    }

    const formConfigs = Object.values(request.formConfig).filter(config => config);
    for(const config of formConfigs) {
      if(!config.submitted || !config.visited || (config.visited && config.errors?.length > 0)) {
        return false;
      }
    }
    return true;
  }

  requiredDocumentsWithoutValue(person: Person): FormField[] {
    return this.currentApplication.requests.filter((request: Request) => request.person.id === person.id).map((request: Request) => request.formConfig && request.formConfig[VisaFormName.DOCUMENT]?.fields.filter((field: FormField) => !field.value && field.validators?.includes(Validators.required.name))).flat().filter((field: FormField) => field);
  }

  allPersonRequestsAreValid() {
    return this.currentApplication.requests.every(r => this.personRequestIsValid(r.person));
  }

  openParticipants() {
    this.dialog.openDialog(DialogParticipantsComponent, 'sm');
  }

  saveCurrentApplication(next = true, fromPrevious = false, id = Store.CART_STORE): Promise<void> {
    return new Promise((resolve) => {
      this.setStorage({currentApplication: this.currentApplication}, fromPrevious, (this.currentApplication?.isRevision || this.currentApplication?.isResume) ? Store.REVISION_STORE : this.currentApplication?.isExtend ? Store.EXTEND_STORE : id ).then(() => {
        localStorage.setItem('lastApplicationType_'+this.api.userInfo.id, (this.currentApplication?.isRevision || this.currentApplication?.isResume) ? Store.REVISION_STORE : this.currentApplication?.isExtend ? Store.EXTEND_STORE : Store.CART_STORE);
        if(next) {
          this.currentApplication$.next(this.currentApplication);
          resolve();
        } else resolve();
      });
    });
  }

  editSyncQueue(data: consumeDataCall, remove = false) {
    if(remove) {
      this.syncQueue = this.syncQueue.filter(d => !(d.paymentId === data.paymentId && d.consumeData.Operation === data.consumeData.Operation));
    } else {
      if(!this.syncQueue.some(d => d.paymentId === data.paymentId && d.consumeData.Operation === data.consumeData.Operation)) {
        this.syncQueue.push(data);
      }
    }
    return new Promise((resolve, reject) => {
      const trans = this.storage.idbstorage.transaction(Store.SYNC_STORE, 'readwrite');
      const objStore = trans?.objectStore?.(Store.SYNC_STORE);
      const request = objStore.put({id: this.api.userInfo.id, data: this.syncQueue});
      request.onsuccess = resolve;
      request.onerror = reject;
    });
  }

  deleteSavedApplication(next = true, id = Store.CART_STORE) {
    const waitPromise = this.removeStorage(id);
    if(next) this.currentApplication$.next(this.currentApplication);
    return waitPromise;
  }

  startApplication(kiosk = false, application?: FormApplication, visaFormConfig?: VisaFormConfig, person?: Person) {
    this.currentApplication = {travelName: '', plannedEntry: null, currentPerson: null, requests: [], visaFeesAndTerms: false};
    if(application) {
      if(application.isRevision || application.isResume) {
        this.deleteSavedApplication(true, Store.REVISION_STORE);
        this.currentApplication = application;
        this.currentApplication.currentPerson = this.currentApplication.requests[0].person;
        this.updateParticipants([this.currentApplication.requests[0].person]);
        this.currentApplication.requests[0].formConfig = visaFormConfig;
        this.saveCurrentApplication(true, false, Store.REVISION_STORE);
        if(application.isRevision) {
          setTimeout(() => {
            this.loader.loading(true, {type: 'info', message: 'request.revision', btnLabel: 'yes', custom: {closeBtnLabel: 'no', icon: 'rocket'}}).then((done: boolean) => {
              this.changeSkip(done);
              application.isExtend ? this.nav.to('visa-extend', undefined, {queryParams: {direction: Direction.NEXT}}) : this.nav.to('passport-type', undefined, {queryParams: {direction: Direction.NEXT}});
            });
          });
          this.currentApplication.skipValidRevision = true;
        } else {
          this.nav.to('summary', undefined, {queryParams: {direction: Direction.NEXT}});
        }
      } else if(application.isExtend) {
        this.deleteSavedApplication(true, Store.EXTEND_STORE);
        this.currentApplication = application;
        this.currentApplication.currentPerson = this.currentApplication.requests[0].person;
        this.updateParticipants([this.currentApplication.requests[0].person]);
        this.currentApplication.requests[0].formConfig = visaFormConfig;
        this.saveCurrentApplication(true, false, Store.EXTEND_STORE);
        this.nav.to('visa-extend');
      }
    } else if(kiosk) {
      this.currentApplication.skipValidRevision = false;
      this.deleteSavedApplication(true, Store.CART_STORE);
      this.currentApplication.travelName = this.getUniqueReference([], 0);
      this.currentApplication.plannedEntry = null;
      const req: Request = {
        person: {
          firstName: '',
          lastName: '',
          nationalNumber: '',
          batchIds: [],
          passportNumber: '',
          id: undefined,
          linkType: null,
          otherLinkType: ''
        }};
      this.currentApplication.currentPerson = req.person;
      this.updateParticipants([req.person]);
      this.saveCurrentApplication(true, false, Store.CART_STORE);
      this.nav.to('passport-type');
    } else {
      this.currentApplication.skipValidRevision = false;
      this.storage.readStorage<CartStoreRow>(Store.CART_STORE).then((row: CartStoreRow) => {
        if(row) {
          this.loader.loading(true, {type: 'question', message: 'request.existSaved', btnLabel: 'yes', custom: {closeBtnLabel: 'no'}}).then((done  ) => {
            if(done) {
              this.loadCurrentApplication(Store.CART_STORE).then(() => {
                if(this.currentRequest?.latestRouteVisited) {
                  this.nav.to(this.currentRequest.latestRouteVisited);
                } else {
                  this.nav.to('travel-name');
                }
              });
            } else {
              this.deleteSavedApplication(true, Store.CART_STORE);
              this.nav.to('travel-name');
              if(person) {
                this.updateParticipants([person]);
              }
            }
          });
        } else {
          this.nav.to('travel-name');
        }
      });
    }
  }

  checkMissingParticipants() {
    if(!this.api.userRole.isKiosk && !this.api.userRole.isWorker && !this.currentApplication.isRevision && !this.currentApplication.isExtend) {
      this.currentApplication.requests = this.currentApplication.requests.filter(r => this.api.listPersons?.find(p => p.id === r.person.id));
      if(!this.api.listPersons?.some(p => p.id === this.currentApplication.currentPerson?.id)) {
        this.currentApplication.currentPerson = this.currentApplication.requests[0]?.person || null;
      }
    }
  }

  setStorage(data: CartStoreRow, fromPrevious = true, store = Store.CART_STORE): Promise<unknown> {
    data.id = this.api.userInfo.id;
    return (fromPrevious ? this.storage.readStorage(store) : Promise.resolve({})).then((row: CartStoreRow) => new Promise((resolve, reject) => {
      const trans = this.storage.idbstorage.transaction(store, 'readwrite');
      const objStore = trans?.objectStore?.(store);
      const request = objStore.put({...row, ...data});
      request.onsuccess = resolve;
      request.onerror = reject;
    }).then(() => this.loadCurrentApplication()));
  }

  loadCurrentApplication(id = Store.CART_STORE) {
    return new Promise((resolve) => {
      this.storage.readStorage<CartStoreRow | consumeDataCall[]>((this.currentApplication?.isRevision || this.currentApplication.isResume) ? Store.REVISION_STORE : this.currentApplication?.isExtend ? Store.EXTEND_STORE : id).then(row => {
        if(!row) {
          this.removeStorage().then(() => resolve(undefined));
        } else {
          this.currentApplication = (row as CartStoreRow).currentApplication;
          this.checkMissingParticipants();
          this.currentRequest = this.currentApplication.requests.find(r => r.person.id === this.currentApplication.currentPerson.id);
          resolve(this.currentRequest);
        }
      });
    });
  }

  removeStorage(id = Store.CART_STORE) {
    return new Promise((resolve, reject) => {
      this.currentApplication = {travelName: '', plannedEntry: null, currentPerson: null, requests: [], submitted: false, visaFeesAndTerms: false};
      this.currentRequest = undefined;
      const trans = this.storage.idbstorage?.transaction?.(id, 'readwrite');
      const objStore = trans?.objectStore?.(id);
      const request = objStore?.clear();
      if(request) {
        request.onsuccess = resolve;
        request.onerror = reject;
      } else {
        resolve(undefined);
      }
    });
  }

  getOriginalForm(docType?: DocumentType): VisaFormConfig {
    switch(docType || this.currentRequest?.visaType?.id) {
    case DocumentType.ZWEVISA:
    case DocumentType.ZWEVISA_DOUBLE:
    case DocumentType.ZWEVISA_KAZA:
    case DocumentType.ZWEVISA_MULTIPLE:
      return this.originalVisaFormConfig;
    case DocumentType.ZWEVISAARR:
    case DocumentType.ZWEVISAARR_DOUBLE:
    case DocumentType.ZWEVISAARR_KAZA:
    case DocumentType.ZWEVISAARR_MULTIPLE:
    case DocumentType.ZWEENTRY:
      return this.originalVisaArrivalFormConfig;
    case DocumentType.ZWEVISAEXTC:
    case DocumentType.ZWEVISAEXTAB:
      return this.originalExtendVisaFormConfig;
    }
  }

  areConfigEqual(config1: VisaFormConfig, config2: VisaFormConfig) {
    const keys1 = Object.keys(config1);
    const keys2 = Object.keys(config2);
    if(keys1.length !== keys2.length) {
      return false;
    }
    return true;
  }

  getUniqueReference(notInArray: string[], limit = 6): string {
    let ref = uuid.v4().toUpperCase();
    if(limit > 0) {
      ref = ref.slice(0, limit);
    }
    if(notInArray.includes(ref)) {
      return this.getUniqueReference(notInArray, limit);
    } else {
      return ref;
    }
  }

  resetKioskRequest() {
    this.loader.loading(true, {
      type: 'warn',
      message: 'onArrival.reset.ask',
      btnLabel: 'yes',
      custom: {closeBtnLabel: 'no'}
    }).then((done: boolean) => {
      if(done) {
        this.startApplication(true);
      }
    });
  }

  callSyncQueue(recall = false) {
    if(this.api.queueIsSyncing && !recall) {
      return;
    } else {
      this.api.queueIsSyncing = true;
      return this.loadSyncQueue().then(data => {
        if(!this.syncQueue.length || !this.api?.userInfo || !data) {
          this.api.queueIsSyncing = false;
          this.syncQueue = [];
          return;
        }
        const retryDelay = 1000;
        return this.api.consumePayment(this.syncQueue[0].paymentId, this.syncQueue[0].consumedPlaceId, this.syncQueue[0].consumedAt, this.syncQueue[0].createdPlaceId, this.syncQueue[0].createdAt, this.syncQueue[0].consumeMessage, this.syncQueue[0].consumeData, this.syncQueue[0].localUsageUntil).then(() => {
          let data = this.syncQueue[0];
          return this.editSyncQueue(this.syncQueue[0], true).then(() => {
            this.syncQueue$.next(data);
            data = null;
            if(this.syncQueue.length) {
              this.callSyncQueue(true);
            } else {
              this.api.queueIsSyncing = false;
              return;
            }
          });
        }).catch((err: any) => {
          const failedData = this.syncQueue[0];
          if(err.includes('client.extended.uuidUsed') || err.includes('client.extended.maxUsageCountReached')) {
            try {
              window.sendTicket('[ERROR Log]: Consume error', `Agent ID : ${this.api.userInfo.id}`, `${this.api.userInfo.name}`, `${this.api.userInfo.accountName}`, this.lang.transform('error.consumeError', {error: err, applicationReference: failedData.paymentId}));
            } catch { /* empty */ }
            return this.editSyncQueue(failedData, true).then(() => new Promise<void>((resolve) => {
              setTimeout(() => {
                resolve(this.callSyncQueue(true));
              }, retryDelay);
            }));
          } else {
            return this.editSyncQueue(failedData, true).then(() => {
              if(failedData?.agentId === this.api.userInfo.id) {
                return this.editSyncQueue(failedData).then(() => new Promise<void>((resolve) => {
                  setTimeout(() => {
                    resolve(this.callSyncQueue(true));
                  }, retryDelay);
                }));
              } else {
                return new Promise<void>((resolve) => {
                  setTimeout(() => {
                    resolve(this.callSyncQueue(true));
                  }, retryDelay);
                });
              }
            });
          }
        });
      }).catch(() => {
        this.api.queueIsSyncing = false;
        this.syncQueue = [];
      });
    }
  }

  loadSyncQueue() {
    return new Promise((resolve) => {
      this.storage.readStorage<CartSyncRow>(Store.SYNC_STORE).then((row: CartSyncRow) => {
        if(row.id !== this.api.userInfo.id) {
          this.syncQueue = [];
          resolve(false);
        }
        this.syncQueue = row?.data as consumeDataCall[] || [];
        resolve(true);
      }).catch(() => {
        this.syncQueue = [];
        resolve(false);
      });
    });
  }

  isFnInRevision(fn: VisaFN, notInRevision = false) {
    if(notInRevision) {
      return this.currentApplication?.isRevision && !this.currentApplication?.revisionFields?.some(f => f === fn);
    } else {
      return this.currentApplication?.isRevision && this.currentApplication?.revisionFields?.some(f => f === fn);
    }
  }

  changeSkip(event: any) {
    this.currentApplication.skipValidRevision = event;
  }
}
