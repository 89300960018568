import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {environment} from 'src/environments/environment';
import {PrintApplicationData} from '../../shared/models/print';
import {AbstractPrintComponent} from '../abstract-print/abstract-print.component';

@Component({
  selector: 'app-print-data',
  templateUrl: './print-data.component.html',
  styleUrl: './print-data.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PrintDataComponent extends AbstractPrintComponent {
    @Input() data: PrintApplicationData;
    @Output() init = new EventEmitter<void>();
    protected readonly environment = environment;
}
