import {Injectable, OnDestroy} from '@angular/core';
import {
  ActionState,
  ConsumeMode, GetPaymentParams,
  PassFields,
  PassportType,
  PurposeOfVisit,
  RequestCashType,
  RequestWithDuplicata
} from '../models/request';
import {DialogButton, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from './api.service';
import {DocumentType, UserPermission} from '../models/user';
import {RequestService} from './request.service';
import parse from 'mrz/lib/parse/parse';
import {checkValidity, convertToEpoch, isDateWithinPeriod} from '../utils/date';
import {CartService} from './cart.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {environment} from 'src/environments/environment';
import {QueueService} from './queue.service';
import {FieldRecords} from 'mrz';
import {Store} from '../models/store';
import {StorageService} from './storage.service';
import {PaymentService} from './payment.service';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class ScanDocumentService implements OnDestroy {

  scanDatas: (ReturnType<typeof parse> & { id?: string });
  visaDatas: RequestWithDuplicata[];
  visaDatas$: BehaviorSubject<RequestWithDuplicata[]> = new BehaviorSubject([]);
  documentError: string;
  documentWarning: string;
  documentPhoto: string;
  consumeMode: ConsumeMode | null = null;
  consumeMode$: BehaviorSubject<ConsumeMode> = new BehaviorSubject(undefined);
  scanned = false;
  isScanning = false;
  private signOutSubscription: Subscription;

  constructor(private loader: LoaderService,
              private lang: LangService,
              private api: ApiService,
              private nav: NavigateService,
              private cart: CartService,
              private request: RequestService,
              private storage: StorageService,
              private payment: PaymentService,
              private queue: QueueService) {
    if(this.isRegistered()) {
      const scanDatas = sessionStorage.getItem('scanDatas');
      const visaDatas = sessionStorage.getItem('visaDatas');
      this.scanDatas = scanDatas ? JSON.parse(scanDatas) : null;
      this.documentError = sessionStorage.getItem('documentError') || '';
      this.documentWarning = sessionStorage.getItem('documentWarning') || '';
      this.documentPhoto = sessionStorage.getItem('documentPhoto') || '';
      this.visaDatas = visaDatas ? JSON.parse(visaDatas) : [];
    }

    this.signOutSubscription = this.api.signOutObservable.subscribe({
      next: () => {
        this.resetScanDatas();
      }
    });
    this.consumeMode = localStorage.getItem('consumeMode') as ConsumeMode;
  }

  ngOnDestroy() {
    this.signOutSubscription.unsubscribe();
  }

  scanPassport() {
    this.resetScanDatas();
    if(this.isScanning) return;
    if(!this.consumeMode) {
      return this.loader.loading(true, {type: 'warn', message: this.lang.transform('scan.noConsumeMode')});
    } else {
      this.isScanning = true;
      this.resetScanDatas();
      this.loader.loading(true);
      electron.step('scanning', false, []);
      return this.scanDocument().then(() => {
        this.api.status = 'info.searchingPayment';
        return this.api.searchPayment(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
          undefined, undefined, undefined, undefined, undefined, this.scanDatas.details.find((field: any) => field.field === 'documentNumber').value, undefined, 20, 'DESC').then(resCall => {
          this.loader.loading(false);
          if(resCall?.result) {
            const reqs: RequestWithDuplicata[] = resCall.result;
            reqs.map((req: RequestWithDuplicata) => this.payment.processOfflineData(req));
            this.saveMultiple(reqs, {history: true, watchlist: false, apipnr: false, inbound: false, fullMetadata: false});
            const nat = this.scanDatas.details.find((field: any) => field.field === 'nationality').value;
            return this.scanPassportAction(reqs.filter(p => p.internalIndexedData.indexOf(nat) > -1) as any, 'dashboard');
          }
        });
      }).finally(() => {
        this.isScanning = false;
        this.api.status = undefined;
        this.loader.loading(false);
      });
    }
  }

  async testDocument() {
    let response;
    for await (response of electronLocal.testDocument(false, true, false)) {
      if(response.length < 2) {
        this.api.status = undefined;
        throw response[0];
      }
      this.api.status = response[0];
    }
    this.api.status = undefined;
    return response;
  }

  scanDocument() {
    let timeoutScan : NodeJS.Timeout;
    const timeoutPromise = new Promise((_, reject) => {
      timeoutScan = setTimeout(() => reject(), 10000);
    });
    return Promise.race([
      this.testDocument(),
      timeoutPromise
    ]).then(result => {
      const [err, photo, _, infos, certBytes] = result as [string, Uint8Array, Uint8Array, ReturnType<typeof parse>, Uint8Array];
      this.scanDatas = infos;
      const cleanName = (name: string) => name.trim().replace(/_/g, ' ').replace(/\s+/g, ' ');
      if(this.scanDatas?.fields?.firstName) this.scanDatas.fields.firstName = cleanName(this.scanDatas.fields.firstName);
      if(this.scanDatas?.fields?.lastName) this.scanDatas.fields.lastName = cleanName(this.scanDatas.fields.lastName);
      this.api.status = 'info.convertingImage';
      return electron.convertJp2Jpg(photo).catch(() => undefined).then(photoUrl => {
        this.api.status = undefined;
        switch(err) {
        case 'err.noPassiveAuthenticationCSCA':
          this.documentWarning = err;
          break;
        default:
          this.documentError = err;
          break;
        }
        const isSoonIssued = this.scanDatas?.fields?.issueDate && isDateWithinPeriod(this.scanDatas.fields.issueDate, '1 month');
        if(isSoonIssued) {
          this.documentWarning = 'err.passport.issuedSoon';
        }
        const isValid = checkValidity(this.scanDatas.fields.expirationDate);
        if(!isValid) {
          if(this.scanDatas?.fields?.nationality === this.api.userInfo.server.country) this.documentWarning = 'err.passport.expired';
          else this.documentError = 'err.passport.expired';
        }
        this.documentPhoto = photoUrl;
        if(this.documentPhoto && !this.documentError) {
          this.documentError = 'passport.valid';
        }

        if(!environment.production) {
          if((this.documentWarning && this.documentWarning === 'err.noPassiveAuthenticationCSCA') || (this.documentError && this.documentError !== 'passport.valid' && this.documentError !== 'err.noCard')) {
            const encodedCertBytes = certBytes ? btoa(String.fromCharCode.apply(null, certBytes)) : undefined;
            window.sendTicket(`[ERROR Scan passport] - Agent ID : ${this.api.userInfo.id}`, this.lang.transform('glpi.' + (this.documentWarning === 'err.noPassiveAuthenticationCSCA' ? this.documentWarning : this.documentError)), `${this.api.userInfo.name}`, `${this.api.userInfo.accountName}`, this.lang.transform('error.passportScan', {certBytes: encodedCertBytes}));
          }
        }

        return {documentPhoto: photoUrl, infos: infos, documentError: this.documentError};
      });
    }).catch(err => {
      const message = err === 'err.noReader' ? err : 'err.scanPassport';
      return this.loader.loading(true, {type: 'warn', message: message, btnLabel: message === 'err.scanPassport' ? 'global.reload' : ''}).then((reload: boolean) => {
        if(reload) {
          return window.location.reload();
        }
        return Promise.reject(err);
      });
    }).finally(() => {
      clearTimeout(timeoutScan);
    });
  }

  resetScanDatas() {
    this.scanDatas = undefined;
    this.documentError = undefined;
    this.documentWarning = undefined;
    this.documentPhoto = undefined;
    this.visaDatas = undefined;
    this.visaDatas$.next(this.visaDatas);
    sessionStorage.removeItem('scanDatas');
    sessionStorage.removeItem('documentError');
    sessionStorage.removeItem('documentWarning');
    sessionStorage.removeItem('documentPhoto');
    sessionStorage.removeItem('visaDatas');
  }

  changeConsume(mode: ConsumeMode, clean = true) {
    if(localStorage.getItem('consumeMode') === mode && clean) {
      localStorage.removeItem('consumeMode');
      this.consumeMode = null;
      this.consumeMode$.next(null);
    } else {
      this.consumeMode = mode;
      this.consumeMode$.next(mode);
      localStorage.setItem('consumeMode', mode);
    }
  }

  /**
   * Action to perform after scanning a passport and getting the datas from the searchPayment API
   * @param datas Datas from the searchPayment API
   * @param from  'dashboard' or 'admin-request' (where the scan was made)
   * @param params Additional params to pass to the next route
   * @returns
   */
  scanPassportAction(datas: RequestWithDuplicata[], from: 'dashboard' | 'admin-request', params?: any) {
    const nationality = this.scanDatas.details.find((field: any) => field.field === 'nationality').value;
    const isNoDeclaration = this.api.env.nationalityNoDeclaration.includes(this.scanDatas.fields.nationality);
    const documentNumber = this.scanDatas.details.find((field: any) => field.field === 'documentNumber').value;
    const isVisaExist = datas.find(request => request.metadata.PassportNumber === documentNumber && request.metadata.Nationality === nationality);
    const zweRefugee = this.isZweRefugee(this.scanDatas.fields.nationality, this.scanDatas.fields.issuingState);
    const register = (type: DocumentType) => this.registerPayment(this.scanDatas, type, zweRefugee).then((createdVisa) => {
      this.scanDatas.id = createdVisa.id;
      this.registerScanDatasToSession();
      // on admin select
      return this.nav.to('admin-request-select/' + createdVisa.id, undefined, {queryParams: {from, foundByScan: true, ...params}});
    }).catch(() => this.loader.loading(true, {type: 'warn', message: this.lang.transform('scan.error')}));
    if(!isVisaExist) {
      // If no visa was found in the database
      if(this.consumeMode === ConsumeMode.ENTRY || this.consumeMode === ConsumeMode.TRANSIT) {
        if(isNoDeclaration || zweRefugee) {
          // In case of the user is a refugee or from a country that doesn't need a declaration, we directly create an entry visa
          return register(DocumentType.ZWEENTRY);
        } else {
          // Start IF1 to create a new entry visa
          return this.loader.loading(true, {type: 'info', message: 'scan.noEntryFound', btnLabel: 'yes', custom: {icon: 'file-circle-question', closeBtnLabel: 'no'}}).then((done) => {
            if(done) {
              this.scanned = true;
              this.cart.startApplication(true);
            } else {
              this.registerScanDatasToSession(false);
              this.scanDatas = sessionStorage.getItem('scanDatas') ? JSON.parse(sessionStorage.getItem('scanDatas')) : null;
            }
          });
        }
      } else {
        if(this.api.hasPerm(UserPermission.ALLOW_CONSUME) && !this.api.hasPerm(UserPermission.ALLOW_CREATE)) {
          return this.loader.loading(true, {type: 'warn', message: 'allowConsume.noCreate'});
        }
        // Same as above but for exit visa
        if(isNoDeclaration) {
          return register(DocumentType.ZWEEXIT);
        } else {
          return this.loader.loading(true, {type: 'info', message: this.lang.transform('scan.noVisaFound'), btnLabel: 'global.confirm'}).then((done) => {
            if(done) {
              return register(DocumentType.ZWEEXIT);
            } else {
              this.registerScanDatasToSession(false);
              this.scanDatas = sessionStorage.getItem('scanDatas') ? JSON.parse(sessionStorage.getItem('scanDatas')) : null;
            }
          });
        }
      }
    } else {
      // We found one or more visa in the database with the same passport number
      const relevantRequests = datas.filter(request => {
        // We filter the requests to keep only the ones that have the same passport number, same nationality, not in the sync queue and with a valid usage date
        const isSameDocumentNumberAndNationality = request.metadata.PassportNumber === documentNumber && request.metadata.Nationality === nationality;
        const isSyncing = this.cart.syncQueue.find(sync => sync.paymentId === request.id) || this.queue.requestInQueue(request.id);
        const isUsageAfterValid = this.request.isUsageDateValid(request.usageAfter, 'usageAfter');
        const isUsageUntilValid = !request.usageUntil || this.request.isUsageDateValid(request.usageUntil, 'usageUntil');
        return isSameDocumentNumberAndNationality && isUsageAfterValid && isUsageUntilValid && !isSyncing;
      });
      // We filter the requests to keep only the ones that are in the correct state
      const relevantIn = this.request.filterRequestsByActionState(relevantRequests, [ActionState.READY_CHECKIN, ActionState.READY_CHECK, ActionState.REFUSED]);
      const relevantOut = this.request.filterRequestsByActionState(relevantRequests, [ActionState.READY_CHECKOUT, ActionState.READY_ZWEEXIT, ActionState.REFUSED]);
      // We filter the requests to keep only the ones that have a usage count lower than the document usage count (for multiple visa entries)
      const relevantOtherStates = relevantIn.concat(relevantOut).filter(request => {
        const relevantDocument = this.api.userInfo.server.paymentRequests.find(d => d.id === request.operationId);
        return (request.operationId !== DocumentType.ZWEEXIT && request.operationId !== DocumentType.ZWEENTRY) && (request.checkoutIsNext ? (request.usageCount < relevantDocument.usageCount) : true);
      });
      const otherRecordsButton = relevantOtherStates.length ? [{
        label: this.lang.transform('review.otherRecords'),
        size: 'sm',
        action: () => {
          // we add this flag to know that the request was found by other states
          relevantOtherStates.forEach(request => request.viewOutOfState = true);
          this.visaDatas = relevantOtherStates;
          this.visaDatas$.next(this.visaDatas);
          this.registerScanDatasToSession();
          this.nav.to('admin-scanned-list', undefined, {queryParams: {fromRoute: from, ...params}});
        }
      }] as DialogButton[] : undefined;
      if(this.consumeMode === ConsumeMode.ENTRY || this.consumeMode === ConsumeMode.TRANSIT) {
        if(relevantIn.length === 1) {
          // If we found only one visa in the database, we directly go to the admin select page
          this.visaDatas = [];
          this.visaDatas$.next(this.visaDatas);
          sessionStorage.removeItem('visaDatas');
          this.scanDatas.id = relevantIn[0].id;
          this.registerScanDatasToSession();
          return this.nav.to('admin-request-select/' + relevantIn[0].id, undefined,  {queryParams: {from: from, foundByScan: true, ...params}});
        } else if(relevantIn.length > 1) {
          const processSingleRequest = (request: RequestWithDuplicata) => {
            this.visaDatas = [];
            this.visaDatas$.next(this.visaDatas);
            sessionStorage.removeItem('visaDatas');
            this.scanDatas.id = request.id;
            this.registerScanDatasToSession();
            return this.nav.to('admin-request-select/' + request.id, undefined, {queryParams: {from, foundByScan: true, ...params}});
          };
          const isReadyCheckin = relevantIn.filter(request => this.request.getStatusTheme(request).actionState === ActionState.READY_CHECKIN);
          const isMultiple = isReadyCheckin.filter(request => (request.operationId === DocumentType.ZWEVISAARR_MULTIPLE || request.operationId === DocumentType.ZWEVISA_MULTIPLE));
          const isDouble = isReadyCheckin.filter(request => request.operationId === DocumentType.ZWEVISAARR_DOUBLE || request.operationId === DocumentType.ZWEVISA_DOUBLE);
          // We prioritize the multiple visa type, then the double visa type and finally the single visa type
          if(isMultiple.length === 1) {
            return processSingleRequest(isMultiple[0]);
          }
          if(isDouble.length === 1) {
            return processSingleRequest(isDouble[0]);
          }
          if(isReadyCheckin.length === 1) {
            return processSingleRequest(isReadyCheckin[0]);
          }
          this.visaDatas = relevantIn;
          this.visaDatas$.next(this.visaDatas);
          this.registerScanDatasToSession();
          return this.nav.to('admin-scanned-list', undefined, {queryParams: {fromRoute: from, ...params}});
        } else {
          // We have found one or more visa in the database but none of them are in the correct state
          if(isNoDeclaration || zweRefugee) {
            return register(DocumentType.ZWEENTRY);
          } else {
            // Ask if the worker want to create a new entry visa, or view other records
            return this.loader.loading(true, {type: 'info', message: `scan.noEntryFound${relevantOtherStates.length ? '.otherRecords' : ''}`, btnLabel: relevantOtherStates.length ? 'createNewOne' : 'yes', additionalButtons: otherRecordsButton, custom: {icon: 'file-circle-question', closeBtnLabel: relevantOtherStates.length ? 'global.close' :'no'}}).then((done) => {
              if(done) {
                this.scanned = true;
                this.cart.startApplication(true);
              } else {
                this.registerScanDatasToSession(false);
                this.scanDatas = sessionStorage.getItem('scanDatas') ? JSON.parse(sessionStorage.getItem('scanDatas')) : null;
              }
            });
          }
        }
      } else {
        // Same as above but for exit visa
        if(relevantOut.length === 1) {
          this.visaDatas = [];
          this.visaDatas$.next(this.visaDatas);
          sessionStorage.setItem('visaDatas', JSON.stringify(this.visaDatas));
          this.scanDatas.id = relevantOut[0].id;
          this.registerScanDatasToSession();
          return this.nav.to('admin-request-select/' + relevantOut[0].id, undefined,  {queryParams: {from, foundByScan: true, ...params}});
        } else if(relevantOut.length > 1) {
          const isMultiple = relevantOut.filter(request => request.operationId === DocumentType.ZWEVISAARR_MULTIPLE || request.operationId === DocumentType.ZWEVISA_MULTIPLE);
          if(isMultiple.length === 1) {
            this.visaDatas = [];
            this.visaDatas$.next(this.visaDatas);
            sessionStorage.setItem('visaDatas', JSON.stringify(this.visaDatas));
            this.scanDatas.id = isMultiple[0].id;
            this.registerScanDatasToSession();
            return this.nav.to('admin-request-select/' + isMultiple[0].id, undefined,  {queryParams: {from, foundByScan: true, ...params}});
          }
          this.visaDatas = relevantOut;
          this.visaDatas$.next(this.visaDatas);
          this.registerScanDatasToSession();
          return this.nav.to('admin-scanned-list', undefined, {queryParams: {fromRoute: from, ...params}});
        } else {
          if(this.api.hasPerm(UserPermission.ALLOW_CONSUME) && !this.api.hasPerm(UserPermission.ALLOW_CREATE)) {
            return this.loader.loading(true, {type: 'warn', message: 'allowConsume.noCreate'});
          }
          if(isNoDeclaration) {
            return register(DocumentType.ZWEEXIT);
          } else {
            return this.loader.loading(true, {type: 'info', message: `scan.noVisaFound${relevantOtherStates.length ? '.otherRecords' : ''}`, additionalButtons: otherRecordsButton, btnLabel: relevantOtherStates.length ? 'createNewOne' : 'yes', custom: {icon: 'file-circle-question'}}).then((done) => {
              if(done) {
                return register(DocumentType.ZWEEXIT);
              } else {
                this.registerScanDatasToSession(false);
                this.scanDatas = sessionStorage.getItem('scanDatas') ? JSON.parse(sessionStorage.getItem('scanDatas')) : null;
              }
            });
          }
        }
      }
    }
  }

  registerPayment(scanDetails: ReturnType<typeof parse>, type: DocumentType, zweRefugee = false) {
    let passportType;
    if(this.scanDatas.fields.documentCode === 'PR' || zweRefugee) {
      passportType = PassportType.REFUGEE;
    } else if(this.scanDatas.fields.documentCode === 'PD') {
      passportType = PassportType.DIPLOMATIC;
    } else if(this.scanDatas.fields.documentCode === 'PS') {
      passportType = PassportType.SERVICE;
    } else {
      passportType = PassportType.ORDINARY;
    }
    if(this.api.isOnline) {
      return this.api.registerCashPayment(undefined,
        type,
        '',
        RequestCashType.CASH,
        this.api.userPlaceId,
        undefined,
        undefined,
        undefined,
        undefined,
        JSON.stringify({
          FirstName: scanDetails.details.find((data: any) => data.field === 'firstName').value,
          PassportType: type === DocumentType.ZWEENTRY ? passportType : undefined,
          LastName: scanDetails.details.find((data: any) => data.field === 'lastName').value,
          BirthDay: convertToEpoch(scanDetails.details.find((data: any) => data.field === 'birthDate').value, false, true),
          Gender: scanDetails.details.find((data: any) => data.field === 'sex').value,
          Nationality: scanDetails.details.find((data: any) => data.field === 'nationality').value,
          PassportNumber: scanDetails.details.find((data: any) => data.field === 'documentNumber').value,
          BorderPass: type === DocumentType.ZWEEXIT ? 'No' : undefined,
          FundsAvailable: 0,
          FundsAvailableCurrency: 'USD',
          Documents: this.consumeMode === ConsumeMode.TRANSIT ? {PurposeOfVisit: PurposeOfVisit.TRANSIT_VISA} : (this.consumeMode === ConsumeMode.ENTRY && zweRefugee) ? {PurposeOfVisit: PurposeOfVisit.LIVE_HERE} : undefined,
          PassportCountry: (this.consumeMode === ConsumeMode.ENTRY && zweRefugee) ? 'ZWE' : undefined
        }));
    }
  }

  registerScanDatasToSession(visa = true) {
    if(this.scanDatas) {
      sessionStorage.setItem('scanDatas', JSON.stringify(this.scanDatas));
    }
    if(this.documentError) {
      sessionStorage.setItem('documentError', this.documentError);
    }
    if(this.documentWarning) {
      sessionStorage.setItem('documentWarning', this.documentWarning);
    }
    if(this.documentPhoto) {
      sessionStorage.setItem('documentPhoto', this.documentPhoto);
    }
    if(!visa) {
      sessionStorage.removeItem('visaDatas');
    } else {
      if(this.visaDatas) {
        sessionStorage.setItem('visaDatas', JSON.stringify(this.visaDatas));
      }
    }
    if(this.documentPhoto && !this.documentError) {
      this.documentError = 'passport.valid';
      sessionStorage.setItem('documentError', this.documentError);
    }
  }

  isRegistered() {
    const scanDatas = sessionStorage.getItem('scanDatas');
    const visaDatas = sessionStorage.getItem('visaDatas');
    if(visaDatas) {
      const parsedVisaDatas = JSON.parse(visaDatas);
      return parsedVisaDatas ? true : false;
    }
    if(scanDatas) {
      const parsedScanDatas = JSON.parse(scanDatas);
      return parsedScanDatas && parsedScanDatas.id ? true : false;
    }
    return false;
  }

  isZweRefugee(nationality: string, issuingState: string) {
    const refugeeNat = ['XXA', 'XXB', 'XXC'];
    return (refugeeNat.includes(nationality) && issuingState === 'ZWE');
  }

  /**
   * Allow to override the scan document details
   * @param overrides The fields to override
   */
  updateScanDocumentDetails(overrides: Partial<FieldRecords>) {
    Object.keys(overrides).forEach((key) => {
      const fieldKey = key as keyof FieldRecords;
      if(this?.scanDatas.fields[fieldKey] !== undefined) {
        this.scanDatas.fields[fieldKey] = overrides[fieldKey]!;
      }
      const detail = this?.scanDatas.details.find(field => field.field === key);
      if(detail) {
        detail.value = overrides[fieldKey]!;
      }
    });
  }

  /**
   * Update the payment with the passport data and update the scan document details (optional)
   * @param paymentId Payment id
   * @param overrides The fields to override
   * @param updateScanDocumentDetails Update the scan document details
   * @returns
   */
  transferFromPassportData(paymentId: string, overrides: Partial<FieldRecords> = this?.scanDatas.fields, updateScanDocumentDetails = false) {
    const {
      [PassFields.BIRTH_DATE]: BirthDay,
      [PassFields.FIRST_NAME]: FirstName,
      [PassFields.LAST_NAME]: LastName,
      [PassFields.NATIONALITY]: Nationality,
      [PassFields.DOCUMENT_NUMBER]: PassportNumber,
      [PassFields.SEX]: Gender,
      [PassFields.EXPIRATION_DATE]: DateOfExpiry
    } = overrides;
    const datas: {[id: string]: any} = {};
    if(BirthDay) datas.BirthDay = convertToEpoch(BirthDay, false, true);
    if(FirstName) datas.FirstName = FirstName;
    if(LastName) datas.LastName = LastName;
    if(Nationality) datas.Nationality = Nationality;
    if(PassportNumber) datas.PassportNumber = PassportNumber;
    if(Gender) datas.Gender = Gender;
    if(DateOfExpiry) datas.DateOfExpiry = convertToEpoch(DateOfExpiry);
    return this.api.updatePayment(paymentId, undefined, undefined, JSON.stringify(datas), undefined, undefined, undefined).then(() => {
      if(updateScanDocumentDetails) {
        this.updateScanDocumentDetails(overrides);
      }
    });
  }

  saveMultiple(requests: RequestWithDuplicata[], callParams: GetPaymentParams): void {
    requests.reduce((prevPromise, req) => prevPromise.finally(() => {
      this.storage.getFromStorage(Store.PAYMENT_STORE, req.id).then((savedFound: RequestWithDuplicata) => {
        if(!savedFound?.savedCallParams || this.payment.hasPropertyChangedToTrue(savedFound.savedCallParams, callParams)) {
          req.savedCallParams = callParams;
          this.storage.saveToStorage(Store.PAYMENT_STORE, req).catch(error => {
            console.error(`Error saving ${req.id}:`, error);
          });
        }
      });
    }),
    Promise.resolve());
  }
}
