<div id="layout" class="f_col">
  <app-view-title fromRoute="pre-enroll" *ngIf="actualPerson?.firstName && actualPerson?.lastName" [chooseLangBtn]="false">{{actualPerson?.firstName + ' ' + actualPerson?.lastName}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
          <div>
            <app-view-deco-image imageUrl="assets-projects/images/image_male.svg"></app-view-deco-image>
            <app-snackbar class="f_center mb_20" type="valid" Icon="check">{{'global.ready' | translate}}</app-snackbar>
          </div>
          <div>
            <app-slide-element [disabled]="actualPerson.disabledBecauseReview" leftIcon="passport" [matTooltip]="actualPerson.disabledBecauseReview ? ('tooltip.disabledBecauseReview' | translate) : ''" (btnClicked)="!actualPerson.disabledBecauseReview && cart.startApplication(undefined, undefined, undefined, actualPerson)" class="mb_10">
              {{(environment.type === DocumentType.PASS ? 'askPassport' : 'askVisa') | translate}}
            </app-slide-element>
            <app-slide-element leftIcon="pen-to-square" (btnClicked)="nav.to('people-modification' + '/' + userId)" class="mb_10">
              {{'modifyData' | translate}}
            </app-slide-element>
            <app-slide-element leftIcon="trash" (btnClicked)="deletePerson()" class="mb_20">
              {{'deletePerson.DYN.' + api.env.type | translate}}
            </app-slide-element>
            <app-divider class="mb_20"></app-divider>
            <span class="ml_10">{{'form.firstName' | translate}}</span>
            <app-slide-element [hoverEffect]="false" rightIcon="user" class="mb_10">
              {{actualPerson?.firstName}}
            </app-slide-element>
            <span class="ml_10">{{'form.lastName' | translate}}</span>
            <app-slide-element [hoverEffect]="false" rightIcon="user" class="mb_10">
              {{actualPerson?.lastName}}
            </app-slide-element>
            <div *ngIf="environment.type === DocumentType.PASS">
              <span  class="ml_10">{{'form.personalNr' | translate}}</span>
              <app-slide-element [hoverEffect]="false" rightIcon="hashtag" class="mb_10">
                {{actualPerson?.nationalNumber}}
              </app-slide-element>
            </div>
            <span  class="ml_10">{{'form.passportNr' | translate}}</span>
            <app-slide-element [hoverEffect]="false" rightIcon="hashtag" class="mb_10">
              {{actualPerson?.passportNumber}}
            </app-slide-element>
            <span class="ml_10">{{'form.linkType' | translate}}</span>
            <app-slide-element [hoverEffect]="false" rightIcon="user" class="mb_10">
              {{actualPerson.linkType === PersonLinkType.OTHER ?  actualPerson.otherLinkType : (('personLinkType.DYN.' + actualPerson?.linkType) | translate)}}
            </app-slide-element>
          </div>
          <div class="btns-container">
            <div class="fullWidth flex f_center">
              <div class="menuButton-container flex f_center">
                <div class="ml_10 fullWidth">
                  <app-button size="xsm" color="transparent" border="true" formId="form" class="resp_minSize_l fullWidth" (btnClicked)="nav.to('people')">{{'global.back' | translate}}</app-button>
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>
  <div class="flex gap_10 mb_20">
    <app-back-btn class="resp_maxSize_l" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <app-button color="transparent" border="true" formId="form" class=" resp_maxSize_l fullWidth" (btnClicked)="nav.to('people')">{{'global.back' | translate}}</app-button>
  </div>
</div>
