import {PDFDocument} from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist';
import {environment} from '../../../environments/environment';
(pdfjsLib as any).GlobalWorkerOptions.workerSrc = `${environment.baseHref}assets/js/pdf.worker.mjs`;

export function mergeDocuments(documents: any[]): Promise<Uint8Array> {
  return PDFDocument.create().then((mergedPdf) => {
    const A4_WIDTH_PT = 595.28;
    const A4_HEIGHT_PT = 841.89;
    const DPI = 200;
    const A4_WIDTH_PX = Math.round((A4_WIDTH_PT / 72) * DPI);
    const A4_HEIGHT_PX = Math.round((A4_HEIGHT_PT / 72) * DPI);
    const delayPrint = 200;

    let promiseChain = Promise.resolve();

    documents.forEach((doc) => {
      promiseChain = promiseChain
        .then(() => delay(delayPrint))
        .then(() => {
          const base64Data = cleanBase64(doc.document);

          if(isPDF(base64Data)) {
            return pdfToImages(base64Data, A4_WIDTH_PX, A4_HEIGHT_PX)
              .then((images) => {
                let imageChain = Promise.resolve();

                images.forEach((imgDataUrl) => {
                  imageChain = imageChain.then(() => {
                    const imageBytes = dataURLtoUint8Array(imgDataUrl);
                    return mergedPdf.embedPng(imageBytes).then((image) => {
                      const {width, height} = image.scale(1);
                      const page = mergedPdf.addPage([A4_WIDTH_PT, A4_HEIGHT_PT]);

                      const scale =
                        Math.min(A4_WIDTH_PT / width, A4_HEIGHT_PT / height) *
                        0.95;
                      const scaledWidth = width * scale;
                      const scaledHeight = height * scale;
                      const x = (A4_WIDTH_PT - scaledWidth) / 2;
                      const y = (A4_HEIGHT_PT - scaledHeight) / 2;

                      page.drawImage(image, {
                        x: x,
                        y: y,
                        width: scaledWidth,
                        height: scaledHeight
                      });
                    });
                  });
                });

                return imageChain;
              })
              .catch(() => Promise.resolve());
          } else if(isImage(base64Data)) {
            return Promise.resolve()
              .then(() => {
                const imageBytes = base64ToUint8Array(base64Data);
                let embedImagePromise;

                if(base64Data.startsWith('/9j/')) {
                  embedImagePromise = mergedPdf.embedJpg(imageBytes);
                } else if(base64Data.startsWith('iVBORw0KGgo')) {
                  embedImagePromise = mergedPdf.embedPng(imageBytes);
                } else {
                  return Promise.resolve();
                }

                return embedImagePromise.then((image) => {
                  const {width, height} = image.scale(1);
                  const page = mergedPdf.addPage([A4_WIDTH_PT, A4_HEIGHT_PT]);

                  const scale = Math.min(A4_WIDTH_PT / width, A4_HEIGHT_PT / height) * 0.95;
                  const scaledWidth = width * scale;
                  const scaledHeight = height * scale;
                  const x = (A4_WIDTH_PT - scaledWidth) / 2;
                  const y = (A4_HEIGHT_PT - scaledHeight) / 2;

                  page.drawImage(image, {
                    x: x,
                    y: y,
                    width: scaledWidth,
                    height: scaledHeight
                  });
                });
              })
              .catch(() => Promise.resolve());
          } else {
            return Promise.resolve();
          }
        });
    });

    return promiseChain.then(() => mergedPdf.save());
  });
}

function pdfToImages(base64Data: string, A4_WIDTH_PX: number, A4_HEIGHT_PX: number): Promise<string[]> {
  const pdfData = atob(base64Data);
  const pdfArray = new Uint8Array(pdfData.length);
  for(let i = 0; i < pdfData.length; i++) {
    pdfArray[i] = pdfData.charCodeAt(i);
  }

  const loadingTask = pdfjsLib.getDocument({data: pdfArray});
  return loadingTask.promise.then((pdf) => {
    const numPages = pdf.numPages;
    const images: string[] = [];
    let pageChain = Promise.resolve();

    for(let pageNum = 1; pageNum <= numPages; pageNum++) {
      pageChain = pageChain.then(() => pdf.getPage(pageNum).then((page) => {
        const viewport = page.getViewport({scale: 1});
        const scale = Math.min(A4_WIDTH_PX / viewport.width, A4_HEIGHT_PX / viewport.height);
        const scaledViewport = page.getViewport({scale: scale});

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d')!;
        canvas.width = Math.round(scaledViewport.width);
        canvas.height = Math.round(scaledViewport.height);

        return page.render({canvasContext: context, viewport: scaledViewport}).promise.then(() => {
          const imageData = canvas.toDataURL('image/png');
          images.push(imageData);
        });
      }));
    }

    return pageChain.then(() => images);
  });
}

function base64ToUint8Array(base64: string): Uint8Array {
  const raw = atob(base64);
  const uint8Array = new Uint8Array(raw.length);
  for(let i = 0; i < raw.length; ++i) {
    uint8Array[i] = raw.charCodeAt(i);
  }
  return uint8Array;
}

function dataURLtoUint8Array(dataUrl: string): Uint8Array {
  const base64 = dataUrl.split(',')[1];
  return base64ToUint8Array(base64);
}

export function cleanBase64(base64String: string): string {
  if(!base64String) {
    throw new Error('Base64 string is null or undefined');
  }
  if(base64String.startsWith('data:')) {
    base64String = base64String.substring(base64String.indexOf(',') + 1);
  }
  base64String = base64String.replace(/\s+/g, '');
  base64String = base64String.replace(/-/g, '+').replace(/_/g, '/');
  const paddingNeeded = 4 - (base64String.length % 4);
  if(paddingNeeded < 4) {
    base64String += '='.repeat(paddingNeeded);
  }
  return base64String;
}

export function isPDF(base64Data: string): boolean {
  if(!base64Data) {
    return false;
  }
  const cleanedData = cleanBase64(base64Data);
  return cleanedData.startsWith('JVBER');
}

export function isImage(base64Data: string): boolean {
  if(!base64Data) {
    return false;
  }
  const cleanedData = cleanBase64(base64Data);
  return cleanedData.startsWith('/9j/') || // JPEG
    cleanedData.startsWith('iVBORw0KGgo'); // PNG
}

export const isBase64 = (document: string | null): boolean => {
  if(!document) return false;
  return document.startsWith('data:image/') && document.includes('base64');
};

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
