import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import * as uuid from 'uuid';
import {
  ChoiceButton,
  DevicesService,
  FormService,
  LangService,
  LoaderService,
  NavigateService,
  ShortcutAction
} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import {SyncService} from '../../../shared/services/sync.service';
import {SmsService} from '../../../shared/services/sms.service';
import {enumToArrayObjectSelect} from '../../../shared/utils/enumToArrayObject';
import {ActivatedRoute, Router} from '@angular/router';
import {QrService} from '../../../shared/services/qr.service';
import {RequestCashType, Request, OperationType} from 'src/app/shared/models/request';
import {DocumentType, Gender} from 'src/app/shared/models/user';
import {RequestService} from 'src/app/shared/services/request.service';
import {format, endOfDay, subYears, isAfter} from 'date-fns';
import {isJsonParsable} from 'src/app/shared/utils/parseMetadata';

@Component({
  selector: 'app-admin-request-create',
  templateUrl: './admin-request-create.component.html',
  styleUrls: ['./admin-request-create.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminRequestCreateComponent implements OnInit {

  form: FormGroup;
  documentType: any;
  paymentCashType: any;
  uploadedCertificate: string;
  choiceButtons?: ChoiceButton[] = [
    {text: 'Yes', value: 'Yes', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: '#6fb76b', textColor: 'var(--clr-lt)', opacity: '1'}},
    {text: 'No', value: 'No', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: 'var(--clr-btn-red)', textColor: 'var(--clr-lt)', opacity: '1'}}
  ];
  isPreenroll = false;
  DocType = DocumentType;
  allNationalities = this.request.getNationalities(true);
  params: {
    fromRoute: 'dashboard' | 'admin-request'
  };
  submitted = false;
  genderOptions: any;
  birthDayValidator = {
    minDate: format(subYears(new Date(), 150), 'yyyy-MM-dd'),
    maxDate: format(endOfDay(new Date()), 'yyyy-MM-dd')
  };
  private borderPassControls = [
    {formCtrlName: 'borderPassNumber', validators: [Validators.required, Validators.maxLength(36)]},
    {formCtrlName: 'residentialAddress', validators: [Validators.required, Validators.maxLength(100)]},
    {formCtrlName: 'issueDate', validators: [Validators.required]},
    {formCtrlName: 'expiryDate', validators: [Validators.required]},
    {formCtrlName: 'reason', validators: [Validators.required, Validators.maxLength(100)]},
    {formCtrlName: 'authorizedCrossingPoints', validators: [Validators.required, Validators.maxLength(100)]}
  ];
  constructor(private formBuilder: FormBuilder,
    public nav: NavigateService,
    public api: ApiService,
    private loader: LoaderService,
    private sync: SyncService,
    private changeDetectorRef: ChangeDetectorRef,
    private sms: SmsService,
    private lang: LangService,
    public forms: FormService,
    private router: Router,
    private qr: QrService,
    private device: DevicesService,
    private request: RequestService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params: any) => {
      this.params = params;
    });
  }

  ngOnInit(): void {
    this.setForm();
    this.paymentCashType = enumToArrayObjectSelect(RequestCashType, 'form.type.');
    this.genderOptions = enumToArrayObjectSelect(Gender, '').filter((item: any) => item.name !== Gender.OTHER);
    this.documentType = this.transformSelect(this.api.userInfo?.server?.paymentRequests);
    this.changeDetectorRef.detectChanges();
  }

  setForm(preenroll = false) {
    if(this.api.env.type === DocumentType.PASS) {
      if(preenroll) {
        this.form = this.formBuilder.group({
          buyingOption: ['', [Validators.required]],
          firstName: ['', [Validators.required]],
          lastName: ['', [Validators.required]],
          meansPayment: ['', [Validators.required]],
          scanBirthCertificate: ['', [Validators.required]],
          email: ['', [Validators.required, Validators.email]],
          externalId: ['', [Validators.maxLength(60)]]
        });
      } else {
        this.form = this.formBuilder.group({
          buyingOption: ['', [Validators.required]],
          firstName: ['', [Validators.required]],
          lastName: ['', [Validators.required]],
          email: ['', [Validators.required, Validators.email]],
          phone: ['', [Validators.required]],
          idCard: ['', [Validators.required]],
          meansPayment: ['', [Validators.required]],
          externalId: ['', [Validators.maxLength(60)]]
        });
      }
    }
    if(this.api.env.type === DocumentType.ZWEVISA) {
      this.form = this.formBuilder.group({
        firstName: ['', [
          Validators.required,
          Validators.pattern('^[A-Za-z \\-]+$'),
          Validators.maxLength(50)]],
        lastName: ['', [
          Validators.required,
          Validators.pattern('^[A-Za-z \\-]+$'),
          Validators.maxLength(50)]],
        BirthDay: ['', Validators.required],
        gender: ['', [Validators.required]],
        nationality: ['', [Validators.required]],
        passportNr: ['', [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9 \\-]+$'),
          Validators.maxLength(20)
        ]]
      });
    }

  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.submitted = false;
      if(this.api.env.type === DocumentType.PASS) {
        if(!this.form.get('externalId').value) {
          this.loader.loading(true, {type: 'info', message: 'payment.create_noExternalId', btnLabel: 'global.confirm'}).then((done) => {
            if(done) {
              this.registerPayment();
            }
          });
        } else {
          this.registerPayment();
        }
      } else {
        this.registerPayment();
      }
    }
  }

  registerPayment() {
    const {id} = this.api.env.type === DocumentType.PASS ?  JSON.parse(this.form.get('buyingOption')?.value) : {id: ''};
    const metadataDatas = this.api.env.type === DocumentType.PASS ? id === DocumentType.PREENROLL ? '' : JSON.stringify({
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,
      buyingOption: id,
      phone: this.form.get('phone').value,
      worker: this.api.userInfo.id,
      idCard: this.form.get('idCard').value,
      email: this.form.get('email').value
    }) : '';
    const serializedData = this.api.env.type === DocumentType.PASS ? id === DocumentType.PREENROLL ? JSON.stringify({
      first_name: this.form.get('firstName').value,
      last_name: this.form.get('lastName').value,
      image: this.uploadedCertificate || ''
    }) : '' : JSON.stringify({
      FirstName: this.form.get('firstName')?.value.toUpperCase() || '',
      LastName: this.form.get('lastName')?.value.toUpperCase() || '',
      BirthDay: new Date(this.form.get('BirthDay')?.value).getTime() || '',
      Gender: this.form.get('gender')?.value || '',
      Nationality: this.form.get('nationality')?.value || '',
      PassportNumber: this.form.get('passportNr')?.value.toUpperCase() || '',
      BorderPass: this.form.get('borderPass')?.value || 'No'
    });
    const register = () => {
      if(this.api.isOnline) {
        const nationality = this.api.env.type === DocumentType.ZWEVISA ? this.form.get('nationality').value : undefined;
        this.loader.loading(true);
        let paymentPromise;
        if(nationality && this.api.env.nationalityNoDeclaration.includes(nationality) && this.form.get('borderPass').value === 'Yes' && this.api.env.type === DocumentType.ZWEVISA) {
          const payment: { request_id: string, person_id: number, serialized: string}[] = [{
            request_id: DocumentType.ZWEEXIT,
            person_id: undefined,
            serialized: JSON.stringify({
              FirstName: this.form.get('firstName')?.value.toUpperCase() || '',
              LastName: this.form.get('lastName')?.value.toUpperCase() || '',
              BirthDay: new Date(this.form.get('BirthDay')?.value).getTime() || '',
              Gender: this.form.get('gender')?.value || '',
              Nationality: this.form.get('nationality')?.value || '',
              PassportNumber: this.form.get('passportNr')?.value.toUpperCase() || '',
              BorderPass: this.form.get('borderPass')?.value || 'No',
              BorderPassNumber: this.form.get('borderPassNumber').value,
              ResidentialAddress: this.form.get('residentialAddress').value,
              IssueDate: new Date(this.form.get('issueDate').value).getTime(),
              ExpiryDate: new Date(this.form.get('expiryDate').value).getTime(),
              Reason: this.form.get('reason').value,
              AuthorizedCrossingPoints: this.form.get('authorizedCrossingPoints').value
            })
          }];
          paymentPromise = this.api.registerMNTAPayment(payment);
        } else {
          paymentPromise = this.api.registerCashPayment(undefined,
            this.api.env.type === DocumentType.PASS ? id : DocumentType.ZWEEXIT,
            metadataDatas,
            this.api.env.type === DocumentType.PASS ? (this.form.get('meansPayment').value ? this.form.get('meansPayment').value : undefined) : RequestCashType.CASH,
            this.api.userPlaceId,
            this.api.env.type === DocumentType.PASS ? (this.form.get('email').value ? this.form.get('email').value : undefined) : undefined,
            this.api.env.type === DocumentType.PASS ? (this.form.get('externalId').value ? this.form.get('externalId').value : undefined) : undefined,
            undefined,
            undefined,
            serializedData);
        }

        return paymentPromise.then((res: any) => {
          if(this.api.env.type === DocumentType.PASS) {
            this.qr.setCurrentQrData(undefined, res as Request);
            this.qr.qrOnlineGenerate(this.api.userPlaceId, res.id, res.externalId, res.signature);
            this.loader.loading(false);
            this.nav.to('admin-request-created/' +  res.id);
          } else {
            this.loader.loading(false);
            const nationality = this.form.get('nationality').value;
            if(this.api.env.nationalityNoDeclaration.includes(nationality) && this.form.get('borderPass').value === 'Yes') {
              this.nav.to('admin-request-select/' + res.ids[0], undefined, {queryParams: {from: this.params.fromRoute}});
            } else {
              this.nav.to('admin-request-face-check/', undefined, {queryParams: {
                paymentId: res.id,
                userPlaceID: this.api.userPlaceId,
                placeID: res.created_place_id,
                paymentIsNotDocument: 'No',
                createdAt: res.createdAt,
                fromScanPassport: true,
                from: this.params.fromRoute,
                operation: OperationType.OUT}});
            }
          }
        }).catch((err) => {
          this.submitted = false;
          this.loader.loading(true, {type: 'error', message: err});
        });
      } else if(this.device.isDevices('cordova')) {
        this.loader.loading(true);
        return this.sms.sendCreateSms(JSON.parse(this.form.get('buyingOption').value).id,
          JSON.stringify({
            firstName: this.form.get('firstName').value,
            lastName: this.form.get('lastName').value,
            buyingOption: JSON.parse(this.form.get('buyingOption').value).type,
            phone: this.form.get('phone').value,
            worker: this.api.userInfo.id,
            idCard: this.form.get('idCard').value,
            email: this.form.get('email').value
          })).then((res: any) => {
          this.form.controls.id.setValue(res.id);
          if(res.signature) {
            this.form.controls.signature.setValue(res.signature);
          }
          this.form.reset();
          this.loader.loading(false);
          this.loader.loading(true, {type: 'info', message: this.lang.transform('payment.success')}).then(() => {
            this.nav.to('admin-request-created');
          });
        }).catch(() => this.createOffline());
      } else {
        return this.createOffline();
      }
    };
    if(this.api.env.type === DocumentType.PASS ) {
      register();
    } else {
      this.loader.loading(true, {type: 'question', message: this.lang.transform('exit.ask'), btnLabel: 'global.confirm'}).then((done: boolean) => {
        if(done) {
          register();
        }
      });
    }
  }

  createOffline() {
    this.loader.loading(false);
    const id = uuid.v4();
    this.qr.setCurrentQrData(this.form, {id: id} as any);
    this.qr.qrOfflineGenerate(this.api.userPlaceId, id, this.form.get('externalId').value);
    const key = Object.keys(this.api.getStoredItem('cache.infos'))[0];
    const item = this.api.getStoredItem('cache.infos')[key];
    this.sync.addOfflineCashPayment(id,
      JSON.parse(this.form.get('buyingOption').value).id,
      JSON.stringify({
        firstName: this.form.get('firstName').value,
        lastName: this.form.get('lastName').value,
        buyingOption: JSON.parse(this.form.get('buyingOption').value).type,
        worker: this.api.userInfo.id ? this.api.userInfo.id : item.id
      }),
      this.form.get('meansPayment').value,
      this.api.userPlaceId,
      this.form.get('externalId').value ? this.form.get('externalId').value : undefined);
    this.form.reset();
    this.loader.loading(true, {type: 'info', message: this.lang.transform('payment.success')}).then(() => {
      this.nav.to('qr/' + id);
    });
  }

  transformSelect(arrayObj: any) {
    const arrayNew : any[]= [];
    arrayObj?.forEach((obj: any) => {
      const {type, id}: any = obj;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      arrayNew.push({name: type + ' : ' + obj.amountCents / 100 +' '+ obj.currency, value: JSON.stringify({type, id})});
    });
    return arrayNew;
  }

  goToPreviousUrl() {
    this.router.navigateByUrl(localStorage.getItem('previous_url'));
  }

  changeSelect(event: any) {
    const parsedValue = isJsonParsable(event.target.value);
    const {id} = parsedValue;
    if(id === DocumentType.PREENROLL && this.api.env.type === DocumentType.PASS) {
      this.isPreenroll = true;
      const currentValues = this.form.value;
      this.setForm(true);
      this.form.patchValue(currentValues);
      this.form.controls.buyingOption.setValue(event.target.value);
    } else {
      if(this.isPreenroll) {
        this.isPreenroll = false;
        this.uploadedCertificate = null;
        const currentValues = this.form.value;
        this.setForm(undefined);
        this.form.patchValue(currentValues);
        this.form.controls.buyingOption.setValue(event.target.value);
      }
    }
  }

  visualFile(event: any) {
    this.loader.loading(true, {
      hideHeader: true,
      fullPage: false,
      custom: {
        innerHtml: `<div class="custom-popup">
          <img src="${event}" alt="popup image" />
        </div>`
      }
    });
  }

  outputInput($event: any) {
    if($event?.error?.message) {
      return this.loader.loading(true, {type: 'error', message: this.lang.transform($event.error.message, {size: '2Mo'})});
    }
    let file: File | null = null;
    if($event[0] instanceof File) {
      file = $event[0];
    } else if($event?.files?.[0] instanceof File) {
      file = $event.files[0];
    }
    if(file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const result = event.target.result;
        this.uploadedCertificate = result;
      };
      reader.readAsDataURL(file);
    }
  }

  resetFile() {
    this.uploadedCertificate = null;
  }

  nationalityChange(event: any) {
    const nationality = event;
    if(this.api.env.nationalityNoDeclaration.includes(nationality)) {
      this.form.addControl('borderPass', this.formBuilder.control('', Validators.required));
    } else {
      if(this.form.contains('borderPass')) {
        this.form.removeControl('borderPass');
      }
      this.borderPassControls.forEach(control => {
        if(this.form.contains(control.formCtrlName)) {
          this.form.removeControl(control.formCtrlName);
        }
      });
    }
  }


  borderPassCheck(event: any) {
    const dateRangeValidator = (): ValidatorFn => (form: FormGroup): ValidationErrors | null => {
      const birthDateControl = form.get('BirthDay');
      const issueDateControl = form.get('issueDate');
      const expiryDateControl = form.get('expiryDate');
      if(!birthDateControl || !issueDateControl || !expiryDateControl) {
        return null;
      }
      const birthDate = birthDateControl.value;
      const issueDate = issueDateControl.value;
      const expiryDate = expiryDateControl.value;
      let hasError = false;
      if(birthDate && issueDate && isAfter(new Date(birthDate), new Date(issueDate))) {
        birthDateControl.setErrors({...birthDateControl.errors, 'afterIssueDate': true});
        hasError = true;
      } else {
        const errors = {...birthDateControl.errors};
        delete errors.afterIssueDate;
        birthDateControl.setErrors(Object.keys(errors).length ? errors : null);
      }
      if(birthDate && expiryDate && isAfter(new Date(birthDate), new Date(expiryDate))) {
        birthDateControl.setErrors({...birthDateControl.errors, 'afterExpiryDate': true});
        hasError = true;
      } else {
        const errors = {...birthDateControl.errors};
        delete errors.afterExpiryDate;
        birthDateControl.setErrors(Object.keys(errors).length ? errors : null);
      }
      if(issueDate && expiryDate && isAfter(new Date(issueDate), new Date(expiryDate))) {
        issueDateControl.setErrors({...issueDateControl.errors, 'afterExpiryDate': true});
        hasError = true;
      } else {
        const errors = {...issueDateControl.errors};
        delete errors.afterExpiryDate;
        issueDateControl.setErrors(Object.keys(errors).length ? errors : null);
      }
      return hasError ? {dateRangeError: true} : null;
    };

    if(event === 'Yes') {
      this.borderPassControls.forEach(control => {
        if(!this.form.contains(control.formCtrlName)) {
          this.form.addControl(control.formCtrlName, this.formBuilder.control('', control.validators));
        }
      });
      this.form.get('borderPassNumber').setValue(uuid.v1());
      this.form.setValidators(dateRangeValidator());
    } else {
      this.borderPassControls.forEach(control => {
        if(this.form.contains(control.formCtrlName)) {
          this.form.removeControl(control.formCtrlName);
        }
      });
      this.form.removeValidators(dateRangeValidator());
    }
  }

  protected readonly ShortcutAction = ShortcutAction;
}
