<form class="f_col {{classNames}}" id="form" [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="true">
  <ng-container *ngFor="let field of config[this.formName].fields">
    <div [id]="'wrap_'+field.name" class="fullWidth" *ngIf="!field.hidden && checkIfFormControl(field)">
      <app-divider *ngIf="field.topDivider"></app-divider>
      <app-paragraph
        *ngIf="field.type === 'file' && !field.hidden && (field.type === 'file' ? !field.disabled : true)">{{ field.name | translate }}
      </app-paragraph>
      <lib-choice-button *ngIf="!field.hidden && field.type === 'choice'"
                         [formGrp]="form"
                         [formCtrlName]="field.name"
                         [disableClick]="field.disabled"
                         [buttons]="field.choiceButtons" size="xsm"
                         [text]="field.name | translate"></lib-choice-button>
      <app-checkbox-element *ngIf="!field.hidden && field.type === 'checkbox'"
                            (onChange)="field.action ? doAction(field.action, $event) : null"
                            [formGrp]="form"
                            [disableClick]="field.disabled"
                            [matTooltip]="(api.isZwevisa && field?.tooltip) ? (field?.tooltip | translate) : ''"
                            [formCtrlName]="field.name"
                            [id]="field.id"
                            [text]="(field.name | translate) + (fieldRequired(field) ? ' *' : '')"></app-checkbox-element>
      <app-input class="mb_0 fullWidth"
                 *ngIf="!field.hidden && field.type !== 'checkbox' && checkIfFormControl(field) && (field.type === 'file' ? !field.disabled : true) && !field.buttonField"
                 [type]="field.type"
                 [formGrp]="form"
                 [matTooltip]="(api.isZwevisa && field?.tooltip) ? (field?.tooltip | translate) : ''"
                 [autocomplete]="field?.noAutocomplete || api.userRole.isKiosk ? false : true"
                 [disabled]="field.disabled"
                 [ngClass]="field?.customClass"
                 [minNumber]="0"
                 [formCtrlName]="field.name"
                 [globalReset]="true"
                 [sizeLimit]="field?.maxFileSize"
                 (visualFile)="visualFile($event, field)"
                 [label]="((field.type === 'file' ? field.fileName ? field.fileName : field.value && !field.fileName ? 'file_uploaded' : 'upload_file' : field.label || field.name) | translate:{size: getFileSize(field.maxFileSize)}) + (fieldRequired(field) ? ' *' : '') + (field?.customExplain ? (' ' + (field.customExplain | translate)) : '')"
                 [inputFileForceName]="field.type === 'file' && field.fileName ? field.fileName : null"
                 [id]="field.id"
                 [customBase64File]="field.type === 'file' && field?.value ? field.value : null"
                 [selectOptions]="field.enum"
                 [rightIcon]="field.type === 'file' && field.icon"
                 [minDate]="field.dateValidators?.minDate ? field.dateValidators.minDate : null"
                 [maxDate]="field.dateValidators?.maxDate ? field.dateValidators.maxDate : null"
                 (output)="outputInput($event, field);field.action ? doAction(field.action, $event) : null"
                 (reset)="resetFileField(field)"
                 [acceptType]="field.acceptType"
                 [icon]="field.icon"></app-input>

      <div *ngIf="field.buttonField" class="gap_10 fullWidth f_align_c">
        <app-input class="mb_0 fullWidth"
                   *ngIf="!field.hidden && field.type !== 'checkbox' && checkIfFormControl(field) && (field.type === 'file' ? !field.disabled : true)"
                   [type]="field.type"
                   [formGrp]="form"
                   [disabled]="field.disabled"
                   [matTooltip]="(api.isZwevisa && field?.tooltip) ? (field?.tooltip | translate) : ''"
                   [ngClass]="field?.customClass"
                   [sizeLimit]="field?.maxFileSize"
                   [autocomplete]="field?.noAutocomplete || api.userRole.isKiosk ? false : true"
                   [formCtrlName]="field.name"
                   [minNumber]="0"
                   [label]="((field.type === 'file' ? field.fileName ? field.fileName : field.value && !field.fileName ? 'file_uploaded' : 'upload_file' : field.name) | translate) + (fieldRequired(field) ? ' *' : '')"
                   [id]="field.id"
                   [selectOptions]="field.enum"
                   [rightIcon]="field.type === 'file' && field.icon"
                   [minDate]="field.dateValidators?.minDate ? field.dateValidators.minDate : null"
                   [maxDate]="field.dateValidators?.maxDate ? field.dateValidators.maxDate : null"
                   (output)="outputInput($event, field);field.action ? doAction(field.action, $event) : null"
                   [acceptType]="field.acceptType"
                   [icon]="field.icon"></app-input>
        <app-button [size]="field.buttonField?.size"
                    (btnClicked)="actionBtn.emit(FormAction.NEWBATCHID)">{{ field.buttonField?.text | translate }}
        </app-button>
      </div>
      <ng-container *ngFor="let dependant of field.dependants; let i = index">
        <div class="flex f_align_c">
          <span class="fs-h6 bold mr_10" *ngIf="checkIfFormControl(dependant[0])">{{ 'dependant' | translate }}
            #{{ i + 1 }}</span>
          <app-button color="warn"
                      size="xsm"
                      *ngIf="checkIfFormControl(dependant[0]) && i > 0"
                      (btnClicked)="removeDependant(field, i)">{{ 'remove' | translate }}
          </app-button>
        </div>
        <ng-container *ngFor="let depField of dependant">
          <app-input class="mb_0"
                     *ngIf="!depField.hidden && depField.type !== 'checkbox' && checkIfFormControl(depField, i)"
                     (output)="field.action ? doAction(field.action, $event) : null"
                     [type]="depField.type"
                     [formGrp]="form"
                     [disabled]="depField.disabled"
                     [formCtrlName]="depField.name"
                     [sizeLimit]="field?.maxFileSize"
                     [autocomplete]="field?.noAutocomplete || api.userRole.isKiosk ? false : true"
                     [minNumber]="0"
                     [id]="depField.id"
                     [label]="((depField.type === 'file' ? 'upload_file' : removeUnderscoreNumber(depField.name) | translate) + ' #' + (i + 1)) + (fieldRequired(depField) ? ' *' : '')"
                     [selectOptions]="depField.enum"
                     [minDate]="depField.dateValidators?.minDate ? depField.dateValidators.minDate : null"
                     [maxDate]="depField.dateValidators?.maxDate ? depField.dateValidators.maxDate : null"
                     [rightIcon]="depField.type === 'file' && field.icon"
                     [icon]="depField.icon"></app-input>
        </ng-container>
        <app-button *ngIf="checkIfFormControl(dependant[0]) && field.dependants.length === i + 1"
                    size="xsm" icon="plus"
                    (btnClicked)="addDependant(field, i)">{{ 'dependant_add' | translate }}
        </app-button>
      </ng-container>
      <app-divider *ngIf="field.bottomDivider"></app-divider>
    </div>
  </ng-container>
  <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="getFormErrors()"></app-errors-list>
  <app-button *ngIf="showConfirm" type="submit" class="fullWidth mt_20"
              formId="form">{{ 'global.confirm' | translate }}
  </app-button>
</form>
