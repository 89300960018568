import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DocumentType, UserPermission, UserPlatformRole} from './shared/models/user';
import {UserUnknownGuardService} from './shared/services/user-unknown-guard.service';
import {UserValidGuardService} from './shared/services/user-valid-guard.service';
import {ChangePasswordComponent} from './views/account/change-password/change-password.component';
import {MyAccountComponent} from './views/account/my-account/my-account.component';
import {ChooseLangComponent} from './views/choose-lang/choose-lang.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {ForgotPasswordComponent} from './views/forgot-password/forgot-password.component';
import {ModifiedPasswordComponent} from './views/forgot-password/modified-password/modified-password.component';
import {SendPasswordComponent} from './views/forgot-password/send-password/send-password.component';
import {HomeComponent} from './views/home/home.component';
import {PagePermissionComponent} from './views/page-permission/page-permission.component';
import {PaymentHistoryComponent} from './views/payment/payment-history/payment-history.component';
import {ReceiptComponent} from './views/payment/receipt/receipt.component';
import {SuccessPaymentComponent} from './views/payment/success-payment/success-payment.component';
import {
  PeopleDataModificationComponent
} from './views/people/people-data-modification/people-data-modification.component';
import {PeopleDataComponent} from './views/people/people-data/people-data.component';
import {PeopleFoundComponent} from './views/people/people-found/people-found.component';
import {PeopleComponent} from './views/people/people.component';
import {PlaceAddComponent} from './views/places/place-add/place-add.component';
import {PlaceCreateComponent} from './views/places/place-create/place-create.component';
import {PlaceCreatedComponent} from './views/places/place-create/place-created/place-created.component';
import {PlacePermissionsComponent} from './views/places/place-permissions/place-permissions.component';
import {PlaceSelectComponent} from './views/places/place-select/place-select.component';
import {PlacesChoiceComponent} from './views/places/places-choice/places-choice.component';
import {PlacesComponent} from './views/places/places.component';
import {PageQrComponent} from './views/qr/page-qr/page-qr.component';
import {ScanQRComponent} from './views/qr/scan-qr/scan-qr.component';
import {SignInComponent} from './views/sign-in/sign-in.component';
import {EmailSentComponent} from './views/sign-up/email-sent/email-sent.component';
import {EmailValidComponent} from './views/sign-up/email-valid/email-valid.component';
import {SignUpComponent} from './views/sign-up/sign-up.component';
import {RequestAccountComponent} from './views/tracking-control/request-account/request-account.component';
import {TrackingControlComponent} from './views/tracking-control/tracking-control.component';
import {UserCreateComponent} from './views/users/user-create/user-create.component';
import {UserCreatedComponent} from './views/users/user/user-created/user-created.component';
import {UserComponent} from './views/users/user/user.component';
import {UsersComponent} from './views/users/users.component';
import {WelcomeEmployeeComponent} from './views/welcome/welcome-employee/welcome-employee.component';
import {WelcomeExplainComponent} from './views/welcome/welcome-explain/welcome-explain.component';
import {WelcomeHelloComponent} from './views/welcome/welcome-hello/welcome-hello.component';
import {WelcomeOfflineComponent} from './views/welcome/welcome-offline/welcome-offline.component';
import {WelcomeThanksComponent} from './views/welcome/welcome-thanks/welcome-thanks.component';
import {AdminRequestComponent} from './views/admin-request/admin-request.component';
import {AdminRequestCreatedComponent} from './views/admin-request/admin-request-created/admin-request-created.component';
import {AdminRequestQrListComponent} from './views/admin-request/admin-request-qr-list/admin-request-qr-list.component';
import {AdminRequestSelectComponent} from './views/admin-request/admin-request-select/admin-request-select.component';
import {AdminRequestFaceCheckComponent} from './views/admin-request/admin-request-face-check/admin-request-face-check.component';
import {AdminRequestCreateComponent} from './views/admin-request/admin-request-create/admin-request-create.component';
import {TripComponent} from './views/trip/trip.component';
import {FormVisaComponent} from './views/form-visa/6.form-visa/form-visa.component';
import {RedirectTravelNameComponent, TravelNameComponent} from './views/form-visa/1.travel-name/travel-name.component';
import {ParticipantsComponent} from './views/form-visa/2.participants/participants.component';
import {PassportTypeComponent} from './views/form-visa/3.passport-type/passport-type.component';
import {VisaTypeComponent} from './views/form-visa/4.visa-type/visa-type.component';
import {UrgentComponent} from './views/form-visa/5.urgent/urgent.component';
import {SummaryComponent} from './views/form-visa/7.summary/summary.component';
import {environment} from 'src/environments/environment';
import {ScannedListComponent} from './views/scanned-list/scanned-list.component';
import {AdminRequestNoticeComponent} from './views/admin-request/admin-request-notice/admin-request-notice.component';
import {StandbyKioskComponent} from './views/standby-kiosk/standby-kiosk.component';
import {StandbyDescKioskComponent} from './views/standby-kiosk/standby-desc-kiosk/standby-desc-kiosk.component';
import {SuccessKioskComponent} from './views/payment/success-kiosk/success-kiosk.component';
import {AdminScanFingerprintComponent} from './views/admin-request/admin-scan-fingerprint/admin-scan-fingerprint.component';
import {VisaExtendComponent} from './views/form-visa/8.visa-extend/visa-extend.component';
import {FeedbackFormComponent} from './components/feedback-form/feedback-form.component';
import {ShortcutsComponent} from './views/account/shortcuts/shortcuts.component';
import {PrintSettingsComponent} from './views/account/print-settings/print-settings.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'start',
    component: RedirectTravelNameComponent
  },
  {
    path: 'track',
    redirectTo: 'trips'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'home'}
  },
  {
    path: 'choose-lang',
    component: ChooseLangComponent,
    data: {animationState: 'chooseLang'}
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'signIn'}
  },
  {
    path: 'sign-in-kiosk',
    redirectTo: '/sign-in?email=' + environment.kioskUsername + '&password=' + environment.password
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'signUp'}
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'forgotPassword'}
  },
  {
    path: 'modified-password',
    component: ModifiedPasswordComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'modifiedPassword'}
  },
  {
    path: 'send-password',
    component: SendPasswordComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'sendPassword'}
  },
  {
    path: 'email-sent',
    component: EmailSentComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'emailSent'}
  },
  {
    path: 'email-valid',
    component: EmailValidComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'emailValid'}
  },
  {
    path: 'my-account',
    component: MyAccountComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER,
        UserPlatformRole.ADMIN,
        UserPlatformRole.WORKER,
        UserPlatformRole.ORBIS_CONSUMER
      ],
      animationState: 'myAccount'
    }
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER,
        UserPlatformRole.ADMIN,
        UserPlatformRole.WORKER,
        UserPlatformRole.ORBIS_CONSUMER
      ],
      animationState: 'changePassword'
    }
  },
  {
    path: 'shortcuts',
    component: ShortcutsComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.ADMIN,
        UserPlatformRole.WORKER],
      animationState: 'shortcuts'
    }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [UserValidGuardService],
    data: {
      animationState: 'dashboard',
      payment: true
    }
  },
  {
    path: 'qr/:paymentId',
    component: PageQrComponent,
    data: {
      animationState: 'pageqr'
    }
  },
  {
    path: 'scan-qr',
    component: ScanQRComponent,
    data: {
      animationState: 'scanqr'
    }
  },
  {
    path: 'user-create',
    component: UserCreateComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_ADMIN],
      animationState: 'userCreate'
    }
  },
  {
    path: 'user-created/:userId',
    component: UserCreatedComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_ADMIN],
      animationState: 'userCreated'
    }
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_ADMIN],
      animationState: 'users'
    }
  },
  {
    path: 'user/:userId',
    component: UserComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_ADMIN],
      animationState: 'user'
    }
  },
  {
    path: 'user/:userId/places-choice',
    component: PlacesChoiceComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_ADMIN],
      animationState: 'placesChoice'
    }
  },
  {
    path: 'user/:userId/place/:placeId',
    component: PlacePermissionsComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_ADMIN],
      animationState: 'placePermissions'
    }
  },
  {
    path: 'user/:userId/places-add',
    component: PlaceAddComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_ADMIN],
      animationState: 'placesAdd'
    }
  },
  {
    path: 'places',
    component: PlacesComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_ADMIN],
      animationState: 'places'
    }},
  {
    path: 'place/:placeId',
    component: PlaceSelectComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_ADMIN],
      animationState: 'placeSelect'
    }},
  {
    path: 'place-create',
    component: PlaceCreateComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_ADMIN],
      animationState: 'placeCreate'
    }},
  {
    path: 'admin-request',
    component: AdminRequestComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      animationState: 'adminRequest'
    }},
  {
    path: 'admin-scan-fingerprint',
    component: AdminScanFingerprintComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      animationState: 'adminScanFingerPrint'
    }},
  {
    path: 'admin-request-face-check',
    component: AdminRequestFaceCheckComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_CONSUME],
      animationState: 'adminRequestFaceCheck'
    }},
  {
    path: 'admin-request-notice/:paymentId',
    component: AdminRequestNoticeComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_CONSUME],
      animationState: 'adminRequestNotice'
    }},
  {
    path: 'page-permission',
    component: PagePermissionComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_ADMIN],
      animationState: 'pagePermission'
    }},
  {
    path: 'admin-request-create',
    component: AdminRequestCreateComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: environment.type === DocumentType.PASS ? [UserPermission.ALLOW_CREATE] : [UserPermission.ALLOW_CONSUME],
      animationState: 'adminRequestCreate'
    }
  },
  {
    path: 'admin-request-select/:paymentId',
    component: AdminRequestSelectComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_CREATE],
      animationState: 'adminRequestSelect'
    }
  },
  {
    path: 'admin-scanned-list',
    component: ScannedListComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER],
      permission: [UserPermission.ALLOW_CONSUME],
      animationState: 'adminScannedList'
    }
  },
  {
    path: 'admin-request-created/:paymentId',
    component: AdminRequestCreatedComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_CREATE],
      animationState: 'adminRequestCreated'
    }},
  {
    path: 'admin-request-qr-list',
    component: AdminRequestQrListComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_CONTROL],
      animationState: 'adminRequestQRList'
    }},
  {
    path: 'place-created/:placeId',
    component: PlaceCreatedComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      permission: [UserPermission.ALLOW_ADMIN],
      animationState: 'placeCreated'
    }},
  {
    path: 'print-settings',
    component: PrintSettingsComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN],
      animationState: 'appPrintSettings'
    }
  },
  {path: 'welcome-hello',
    component: WelcomeHelloComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'welcomeHello'
    }},
  {path: 'welcome-explain',
    component: WelcomeExplainComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'welcomeExplain'
    }},
  {path: 'welcome-employee',
    component: WelcomeEmployeeComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'welcomeEmployee'
    }},
  {path: 'welcome-offline',
    component: WelcomeOfflineComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'welcomeOffline'
    }},
  {path: 'welcome-thanks',
    component: WelcomeThanksComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'welcomeThanks'
    }},
  {
    path: 'people/:userId',
    component: PeopleDataComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'peopleData',
      payment: true
    }
  },
  {
    path: 'request-account/:paymentId',
    component: RequestAccountComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'requestAccount',
      payment: true
    }
  },
  {
    path: 'people-modification/:userId',
    component: PeopleDataModificationComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'peopleDataModification'
    }
  },
  {
    path: 'tracking-control',
    component: TrackingControlComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'trackingControl',
      payment: true
    }
  },
  {
    path: 'payment-commandHistory',
    component: PaymentHistoryComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'paymentHistory',
      payment: true
    }
  },
  {
    path: 'receipt/:paymentId',
    component: ReceiptComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'receipt',
      payment: true
    }
  },
  {
    path: 'payment-success',
    component: SuccessPaymentComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER, UserPlatformRole.WORKER],
      animationState: 'successPayment',
      storage: true
    }
  },
  {
    path: 'travel-name',
    component: TravelNameComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER, UserPlatformRole.KIOSK],
      animationState: 'travelName',
      payment: true,
      storage: true
    }
  },
  {
    path: 'participants',
    component: ParticipantsComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER, UserPlatformRole.KIOSK],
      animationState: 'participants',
      storage: true,
      payment: true
    }
  },
  {
    path: 'passport-type',
    component: PassportTypeComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER, UserPlatformRole.KIOSK, UserPlatformRole.WORKER],
      animationState: 'passportType',
      storage: true
    }
  },
  {
    path: 'visa-type',
    component: VisaTypeComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER, UserPlatformRole.KIOSK, UserPlatformRole.WORKER],
      animationState: 'visaType',
      storage: true
    }
  },
  {
    path: 'urgent',
    component: UrgentComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER, UserPlatformRole.KIOSK, UserPlatformRole.WORKER],
      animationState: 'urgent',
      storage: true
    }
  },
  {
    path: 'form-visa',
    component: FormVisaComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER, UserPlatformRole.KIOSK, UserPlatformRole.WORKER],
      animationState: 'visa',
      storage: true
    }
  },
  {
    path: 'summary',
    component: SummaryComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER, UserPlatformRole.KIOSK, UserPlatformRole.WORKER],
      animationState: 'summary',
      storage: true,
      payment: true
    }
  },
  {
    path: 'visa-extend',
    component: VisaExtendComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER, UserPlatformRole.WORKER],
      animationState: 'visaExtend',
      storage: true
    }
  },
  {
    path: 'people',
    component: PeopleComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'people'
    }
  },
  {
    path: 'trips',
    component: TripComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'trips',
      payment: true
    }
  },
  {
    path: 'people-found/:userId',
    component: PeopleFoundComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'peopleFound'
    }
  },
  {
    path: 'standby-kiosk',
    component: StandbyKioskComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.KIOSK],
      animationState: 'standbyKiosk'
    }
  },
  {
    path: 'feedback-form/:requestId',
    component: FeedbackFormComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.CUSTOMER],
      animationState: 'feedbackForm',
      payment: true
    }
  },
  {
    path: 'standby-desc-kiosk',
    component: StandbyDescKioskComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.KIOSK],
      animationState: 'standbyDescKiosk'
    }
  },
  {
    path: 'success-kiosk',
    component: SuccessKioskComponent,
    canActivate: [UserValidGuardService],
    data: {
      role: [UserPlatformRole.KIOSK],
      animationState: 'successKiosk'
    }
  },
  {path: '**',
    redirectTo: '/',
    pathMatch: 'full'}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'})
  ],
  exports: [
    RouterModule
  ],
  providers: [
    UserUnknownGuardService,
    UserValidGuardService
  ],
  declarations: []
})
export class AppRoutingModule {}
