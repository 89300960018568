/* tslint:disable */
export const de_strings = {
  'transactionReference.alt': 'Transaktionsreferenz',
  'paymentType.alt': 'Zahlungsart',
  'changeGender.ask': 'Das Geschlecht im Antrag muss entweder auf männlich oder weiblich geändert werden.',
  'print.askMultiple': 'Möchten Sie alle zugehörigen Dokumente in den Ausdruck aufnehmen?',
  'application.id': 'Antrags-ID',
  'printSticker': 'Visumaufkleber drucken',
  'print.receiptA5': 'A5-Quittung drucken',
  'correctionFields': 'Folgende Felder wurden korrigiert:',
  'corrected.desc': 'Dieser Visumantrag wurde am {date} vom Antragsteller korrigiert.',
  'recentlyCorrected': 'Korrigiert',
  'visaValidFrom': 'Eintritt gültig ab',
  'visaValidUntil': 'Eintritt gültig bis',
  'online': 'Online',
  'offline': 'Offline',
  'receiptNr': 'Belegnummer',
  'issuingOfficer.alt': 'Ausstellender Beamter',
  'placeOfIssue': 'Ausstellungsort',
  'fileRefNr': 'Aktenreferenznummer',
  'tooltip.hasBeenCorrected': 'Dieser Antrag wurde vom Antragsteller korrigiert.',
  'tooltip.extendStay': 'Verlängern Sie den Aufenthalt des Antragstellers',
  'printFoldable': 'Faltbares Dokument drucken',
  'printApprovalLetter': 'Genehmigungsschreiben drucken',
  'operation.REFUSEOUT': 'Check-out verweigert',
  'events': 'Ereignisse',
  'tooltip.events': 'Dies sind die Ereignisdetails. Einige können je nach Ereignistyp und durchgeführter Operation zusätzliche Informationen enthalten.',
  'form.paymentReference': 'Zahlungsreferenz',
  'error.closeApplication': 'Beim Schließen des Antrags ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'closeApplication': 'Als geschlossen markieren',
  'tooltip.closeApplication': 'Markieren Sie den Antrag als geschlossen. Diese Aktion ist irreversibel.',
  'tooltip.tableNationalityTitle': 'Staatsangehörigkeit des Antragstellers',
  'understand': 'Ich verstehe',
  'ExtraDocument1': 'Zusätzliches Dokument Nr. 1',
  'ExtraDocument2': 'Zusätzliches Dokument Nr. 2',
  'ExtraDocument3': 'Zusätzliches Dokument Nr. 3',
  'ExtraDocument4': 'Zusätzliches Dokument Nr. 4',
  'ExtraDocument5': 'Zusätzliches Dokument Nr. 5',
  'paperSize': 'Papierbreite',
  'global.changePrinterSize': 'Breite des Quittungspapiers ändern',
  'change_lang': 'Sprache ändern',
  'queue.export.desc': 'Möchten Sie die aktuelle Warteschlange in eine JSON-Datei exportieren? <b>Ihre lokale Warteschlange wird nach dem Export gelöscht</b>. Möchten Sie wirklich fortfahren?',
  'queue.export': 'In JSON exportieren',
  'queue.import': 'Warteschlange aus JSON importieren',
  'nationality': 'Staatsangehörigkeit',
  'gender': 'Geschlecht',
  'HQReference': 'Hauptreferenz',
  'vendorName': 'Anbietername',
  'vendorAddress': 'Anbieteradresse',
  'vendorEmail': 'Anbieter-E-Mail',
  'idRequest': 'ID des Antrags',
  'serialNumber': 'Seriennummer',
  'global.resetDefaults': 'Auf Standard zurücksetzen',
  'shortcuts.desc': 'Sie können unten Ihre eigenen Tastenkombinationen festlegen. Klicken Sie auf die Taste, für die Sie eine neue Tastenkombination festlegen möchten, und drücken Sie die gewünschte Taste auf Ihrer Tastatur.',
  'press.key': 'Drücken Sie eine beliebige Taste, um die Verknüpfung festzulegen...',
  'dashboard.keyboardShortcuts': 'Tastenkombinationen',
  'tooltip.changeShortcuts': 'Tastenkombinationen ändern',
  'global.changeShortcuts': 'Verknüpfungen ändern',
  'retake_picture': 'Bild erneut aufnehmen',
  'error.faceCheck': 'Beim Abgleich des Gesichts ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'faceCheck.see_score': 'Gesichtserkennung',
  'recommendOrNot': 'Empfehlen oder nicht empfehlen',
  'recommended': 'Empfohlen',
  'not_recommended': 'Nicht empfohlen',
  'error.downgrade': 'Beim Herabstufen der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'done.downgrade': 'Der Antrag wurde erfolgreich auf ein Einreisevisum herabgestuft.',
  'request.downgrade': 'Herabstufen',
  'err.externalCode.pattern': 'Externer Code (ICAO) muss das richtige Format haben: 4 Großbuchstaben',
  'request.extendStay': 'Aufenthalt verlängern',
  'ResidencePermitNumber': 'Aufenthaltsgenehmigungsnummer',
  'start_continue': 'Zahlung fortsetzen',
  'error.payment.noInternalReference': 'Eine Hauptreferenz ist erforderlich, um diesen Antrag zu prüfen. Bitte warten Sie, bis die Referenz bereitgestellt wird.',
  'err.Occupation.required': 'Beruf ist erforderlich',
  'err.HomeAddress.required': 'Heimatadresse ist erforderlich',
  'err.PassportCountry.required': 'Passland ist erforderlich',
  'request.revision': 'Möchten Sie die Autoskip-Funktion aktivieren? Diese Funktion überspringt automatisch die geprüften Schritte, die keine Eingabe erfordern.',
  'notice.BORDER_PASS': 'Grenzpass',
  'form.borderPassNumber': 'Grenzpassnummer',
  'form.dateOfBirth': 'Geburtsdatum',
  'form.issueDate': 'Ausstellungsdatum',
  'form.authorizedCrossingPoints': 'Autorisierte Grenzübergänge',
  'form.issuedBy': 'Ausgestellt von',
  'err.borderPassNumber.required': 'Grenzpassnummer ist erforderlich',
  'err.dateOfBirth.required': 'Geburtsdatum ist erforderlich',
  'err.expiryDate.required': 'Ablaufdatum ist erforderlich',
  'err.authorizedCrossingPoints.required': 'Autorisierte Grenzübergänge sind erforderlich',
  'err.issuedBy.required': 'Ausgestellt von ist erforderlich',
  'err.issueDate.required': 'Ausstellungsdatum ist erforderlich',
  'form.externalCode': 'Externer Code (ICAO)',
  'err.invoke': 'Fehler',
  'ask.pendingRequest': 'Sie haben eine ausstehende Korrektur. Möchten Sie diese jetzt öffnen?',
  'entry.confirmation': 'Sind Sie sicher, dass alle bereitgestellten Informationen korrekt sind? Durch Klicken auf "Bestätigen" reichen Sie den Antrag ein.',
  'free': 'Kostenlos',
  'last_update': 'Letztes Update',
  'count': 'Anzahl',
  'tooltip.linkedExtensions': 'Dies ist eine Aufenthaltsverlängerung. Klicken Sie, um die Details anzuzeigen.',
  'extension': 'Verlängerung',
  'client.extended.badPaymentMetadata': 'Die Zahlungsmetadaten sind ungültig. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support, wenn das Problem weiterhin besteht.',
  'DaysRequested': 'Angeforderte Tage',
  'payment.cancelled': 'Die Zahlung wurde storniert. Bitte versuchen Sie es erneut, wenn Sie fortfahren möchten. Sie werden zur vorherigen Seite weitergeleitet.',
  'payment.declined': 'Die Zahlung wurde abgelehnt. Bitte überprüfen Sie Ihre Zahlungsdaten und die Verfügbarkeit der Mittel. Sie werden zur vorherigen Seite weitergeleitet.',
  'extension.ref': 'Aufenthaltsverlängerungs-Ref.',
  'extensions.desc': 'Aufenthaltsverlängerung(en) zur Überprüfung ausstehend',
  'extend': 'Verlängern',
  'err.SpouseBirthDay.maxDate': 'Das Geburtsdatum des Ehepartners muss vor dem heutigen Tag liegen',
  'CheckboxVisaExtendFees': 'Ich bestätige, dass die bereitgestellten Informationen wahr und korrekt sind und ich den Gebühren, Bedingungen und der Datenschutzrichtlinie der Aufenthaltsverlängerung zustimme.',
  'err.AttestantPhone.pattern': 'Telefonnummer muss das richtige Format haben, z. B.: +263771234567',
  'err.AttestantEmail.email': 'Attestant-E-Mail muss das richtige Format haben',
  'err.AttestantDocument.required': 'Attestant-Dokument ist erforderlich',
  'err.AffidavitCustody.required': 'Sorgerechtsbescheinigung ist erforderlich',
  'err.CopyOfEntry.required': 'Einreisebeleg ist erforderlich',
  'err.DepartureTicket.required': 'Abflugticket ist erforderlich',
  'AttestantDocument': 'Attestant-Dokument',
  'AffidavitCustody': 'Sorgerechtsbescheinigung',
  'CopyOfEntry': 'Einreisebeleg',
  'DepartureTicket': 'Abflugticket',
  'DYN.Self': 'Selbst',
  'DYN.Spouse/Dependent': 'Ehepartner/Angehöriger',
  'DYN.Employee/Employer': 'Mitarbeiter/Arbeitgeber',
  'DYN.Co-Director': 'Mitdirektor',
  'DYN.Lawyer': 'Rechtsanwalt',
  'VisaReference': 'Visareferenz',
  'ReasonForExtension': 'Grund für die Verlängerung',
  'AttestantID': 'Attestant-ID',
  'AttestantName': 'Name des Attestanten',
  'AttestantPhone': 'Telefonnummer des Attestanten',
  'AttestantEmail': 'E-Mail des Attestanten',
  'AttestantAddress': 'Adresse des Attestanten',
  'AttestantRelation': 'Beziehung des Attestanten',
  'err.VisaReference.required': 'Visareferenz ist erforderlich',
  'err.ReasonForExtension.required': 'Grund für die Verlängerung ist erforderlich',
  'err.AttestantID.required': 'Attestant-ID ist erforderlich',
  'err.AttestantName.required': 'Name des Attestanten ist erforderlich',
  'err.AttestantPhone.required': 'Telefonnummer des Attestanten ist erforderlich',
  'err.AttestantEmail.required': 'E-Mail des Attestanten ist erforderlich',
  'err.AttestantAddress.required': 'Adresse des Attestanten ist erforderlich',
  'err.AttestantRelation.required': 'Beziehung des Attestanten ist erforderlich',
  'start_extend': 'Aufenthalt verlängern',
  'visaExtend.title': 'Aufenthaltsverlängerung',
  'days': 'Tage',
  'day': 'Tag',
  'err.Email.email': 'E-Mail muss das richtige Format haben',
  'err.EmergencyEmail.email': 'E-Mail des Notfallkontakts muss das richtige Format haben',
  'kiosk.standby.text1': 'Beginnen Sie, indem Sie Ihre Erklärung in nur wenigen Klicks auf diesem Tablet oder online ausfüllen.',
  'kiosk.standbyDesc.text1': 'Bitte beachten Sie vor dem Start folgende Informationen:',
  'kiosk.standbyDesc.li1': 'Sie füllen ein elektronisches Formular aus, das Ihre persönlichen Daten und Reisedetails erfasst.',
  'kiosk.standbyDesc.li2': 'Alle bereitgestellten Informationen müssen korrekt sein und mit Ihren Reisedokumenten (Reisepass, Visum usw.) übereinstimmen.',
  'kiosk.standbyDesc.li3': 'Nach dem Ausfüllen des Formulars können Sie direkt mit Ihrem Ausweisdokument zum Einwanderungsschalter gehen.',
  'kiosk.standbyDesc.li5': 'Wenn Sie Schwierigkeiten haben, können Sie sich jederzeit an das Personal am Grenzposten wenden.',
  'kiosk.standbyDesc.text2': 'Klicken Sie auf "Start", wenn Sie bereit sind, Ihre Einreiseerklärung auszufüllen.',
  'kiosk.success.title': 'Antrag erfolgreich eingereicht',
  'kiosk.success.text1': 'Sie können jetzt mit Ihren Reisedokumenten zum Einwanderungsschalter gehen. Ein Beamter wird die endgültigen Kontrollen durchführen und Ihre Einreise in das Land validieren.',
  'kiosk.success.text2': 'Wenn Sie Fragen oder Schwierigkeiten haben, wenden Sie sich bitte an das verfügbare Personal.',
  'confirm.revision': 'Überarbeitung bestätigen',
  'print.again': 'Nein, erneut drucken',
  'print.isDone': 'Wurde die Benachrichtigung erfolgreich gedruckt? Bitte stellen Sie sicher, dass Sie die gedruckte Benachrichtigung dem Antragsteller übergeben.',
  'err.otherMissingDocument.required': 'Ein weiteres fehlendes Dokument ist erforderlich',
  'err.reportAt.required': 'Berichterstattung bei ist erforderlich',
  'err.reportOn.required': 'Berichterstattung am ist erforderlich',
  'err.reportInterval.required': 'Berichtungsintervall ist erforderlich',
  'err.address.required': 'Adresse ist erforderlich',
  'err.periodFrom.required': 'Zeitraum von ist erforderlich',
  'err.periodTo.required': 'Zeitraum bis ist erforderlich',
  'form.hostPhone': 'Telefonnummer des Gastgebers',
  'err.hostPhone.required': 'Telefonnummer des Gastgebers ist erforderlich',
  'transit': 'Transit',
  'conditionalEntry': 'Bedingte Einreise',
  'departure': 'Abreise',
  'err.host.required': 'Gastgeber ist erforderlich',
  'err.purpose.required': 'Zweck ist erforderlich',
  'err.fullNames.required': 'Vollständiger Name ist erforderlich',
  'err.officer.required': 'Beamter ist erforderlich',
  'err.paragraph.required': 'Absatz ist erforderlich',
  'err.dateOfRefusal.required': 'Ablehnungsdatum ist erforderlich',
  'form.paragraph': 'Absatz',
  'form.dateOfRefusal': 'Ablehnungsdatum',
  'form.purpose': 'Zweck',
  'form.host': 'Gastgeber',
  'form.officer': 'Beamter',
  'form.reportAt': 'Berichterstattung bei',
  'form.reportOn': 'Berichterstattung am',
  'form.reportInterval': 'Berichterstattungsintervall',
  'notice.desc.RESTRICTION': 'Die vorläufige Einschränkungsmitteilung informiert den Antragsteller über die Dokumente, die zur Vervollständigung des Verfahrens erforderlich sind. Sie können die fehlenden Dokumente auswählen, die der Antragsteller innerhalb eines bestimmten Zeitraums vorlegen muss.',
  'notice.REFUSAL': 'Einreiseverweigerungsmitteilung',
  'notice.desc.REFUSAL': 'Dem Antragsteller wird die Einreise in das Land verweigert.',
  'notice.desc.REPORTING': 'Die Besuchermitteilung ermöglicht dem Antragsteller die Einreise in das Land, kann jedoch einer weiteren Berichterstattung unterliegen. Sie können fehlende Dokumente auswählen, die der Antragsteller innerhalb eines bestimmten Zeitraums vorlegen muss.',
  'err.hqReference.required': 'Hauptreferenz ist erforderlich',
  'err.stationReference.required': 'Stationsreferenz ist erforderlich',
  'err.reportLocation.required': 'Berichterstattungsort ist erforderlich',
  'err.daysUntilReport.required': 'Tage bis zur Berichterstattung sind erforderlich',
  'err.businessAddress.required': 'Geschäftsadresse ist erforderlich',
  'err.residentialAddress.required': 'Wohnadresse ist erforderlich',
  'form.stationReference': 'Stationsreferenz',
  'form.fullNames': 'Vollständiger Name',
  'form.reportLocation': 'Berichterstattungsort',
  'form.daysUntilReport': 'Tage bis zur Berichterstattung',
  'form.otherMissingDocument': 'Weiteres fehlendes Dokument',
  'form.residentialAddress': 'Wohnadresse',
  'form.businessAddress': 'Geschäftsadresse',
  'form.hqReference': 'Hauptreferenz',
  'nationalPassport': 'Nationalpass',
  'residentPermit': 'Aufenthaltserlaubnis',
  'proofOfInvestment': 'Investitionsnachweis',
  'evidenceOfMeans': 'Nachweis der Mittel',
  'evidenceOfResidentialStatus': 'Nachweis des Wohnstatus',
  'studentScholarPermit': 'Studenten-/Stipendiatenvisum',
  'radiologicalCertificate': 'Radiologisches Zertifikat',
  'temporaryPermit': 'Vorübergehende Aufenthaltserlaubnis',
  'evidenceOfEmployment': 'Beschäftigungsnachweis',
  'temporaryEmploymentPermit': 'Vorübergehende Arbeitserlaubnis',
  'fullAddressOfDestination': 'Vollständige Adresse des Ziels',
  'appealOutcome': 'Ergebnis der Berufung',
  'request.notice': 'Wählen Sie eine Mitteilung zum Drucken aus',
  'notice.title': 'Verfügbare Mitteilungen zum Drucken',
  'notice.desc': 'Bitte wählen Sie eine Mitteilung aus der Liste unten aus. Nachdem Sie die erforderlichen Felder ausgefüllt haben, können Sie die Mitteilung für den Antragsteller drucken.',
  'notice.RESTRICTION': 'Vorläufige Einschränkungsmitteilung',
  'notice.REPORTING': 'Besuchsmitteilung',
  'notice.RESTRICTION.desc': 'Der Antragsteller muss die fehlenden Dokumente innerhalb eines bestimmten Zeitraums vorlegen.',
  'notice.REFUSAL.desc': 'Dem Antragsteller wird die Einreise in das Land verweigert.',
  'notice.REPORTING.desc': 'Dem Antragsteller wird die Einreise gestattet, er kann jedoch einer weiteren Berichterstattung unterliegen.',
  'passportType.kioskWarning': 'Die ausgewählte Staatsangehörigkeit unterliegt dem Regime der Kategorie C. Bitte gehen Sie sofort zu einem Einwanderungsbeamten.',
  'free.charge.total': 'Gesamtbetrag kostenlos',
  'err.entryPoints': 'Beim Laden der geplanten Einreisepunkte ist ein Fehler aufgetreten',
  'err.PreviousConvictionsHomeAsk.required': 'Bitte beantworten Sie die Frage zu früheren Verurteilungen in Ihrem Heimatland',
  'summary.your_meta': 'Ihre Daten (zum Öffnen anklicken)',
  'summary.payOnline': 'Ich zahle lieber jetzt online, als bei der Ankunft am Schalter des Beamten.',
  'summary.payOnline.desc': 'Durch Auswahl der untenstehenden Option gelangen Sie zur Zahlungsseite. Wenn Sie sich entscheiden, nicht online zu zahlen, zahlen Sie die Visagebühren am Schalter des Beamten bei der Ankunft für "Visa on arrival"-Visa-Typen.',
  'summary.meta': '\'s Daten (zum Öffnen anklicken)',
  'EmergencyLastName': 'Nachname des Notfallkontakts',
  'EmergencyFirstName': 'Vorname des Notfallkontakts',
  'EmergencyEmail': 'E-Mail des Notfallkontakts',
  'EmergencyPhone': 'Telefonnummer des Notfallkontakts',
  'err.EmergencyLastName.required': 'Nachname des Notfallkontakts ist erforderlich',
  'err.EmergencyFirstName.required': 'Vorname des Notfallkontakts ist erforderlich',
  'err.EmergencyEmail.required': 'E-Mail des Notfallkontakts ist erforderlich',
  'err.EmergencyPhone.required': 'Telefonnummer des Notfallkontakts ist erforderlich',
  'person.name': 'Name der ausgewählten Person',
  'DYN.identity-card': 'Personalausweis',
  'DYN.resident-permit': 'Vorübergehende Aufenthaltserlaubnis',
  'DYN.identity-card.desc': 'Ein Personalausweis ist ein von der Regierung ausgestelltes Dokument zur Identifizierung im Inland.',
  'DYN.resident-permit.desc': 'Eine vorübergehende Aufenthaltserlaubnis ist ein offizielles Dokument, das von einer Regierung ausgestellt wird und es einem Ausländer erlaubt, für einen bestimmten Zeitraum in einem Land zu leben.',
  'err.visaFeesAndTerms.required': 'Bitte akzeptieren Sie die Visagebühren und die Nutzungsbedingungen sowie die Datenschutzrichtlinie',
  'err.NonOrdinaryPassMission.required': 'Der Grund für den offiziellen Besuch ist erforderlich',
  'NonOrdinaryPassMission': 'Grund für den offiziellen Besuch',
  'err.PreviousConvictionsHome.required': 'Vorherige Verurteilung(en) im Heimatland sind erforderlich',
  'PreviousConvictionsHomeAsk': 'Wurden Sie oder einer Ihrer Angehörigen in Ihrem Heimatland wegen eines Verbrechens verurteilt?',
  'PlaceOfBirthCountry': 'Geburtsland',
  'PlaceOfBirthCity': 'Geburtsstadt',
  'OtherName': 'Andere(r) Name(n)',
  'travelName': 'Antragsreferenz',
  'plannedEntry': 'Geplanter Einreisehafen',
  'summary.title': 'Zusammenfassung',
  'urgent': 'Dringend',
  'visaType': 'Dokumententyp',
  'passportType': 'Art des Ausweises',
  'err.plannedEntry.required': 'Geplanter Einreisehafen ist erforderlich',
  'understood': 'Ich stimme zu',
  'passportType.acknowledge': 'Durch die Auswahl dieses Pass-Typs bestätigen Sie, dass die Nichteinhaltung der Nutzungsbedingungen zur Verweigerung der Einreise führen kann.',
  'form.travelName.already': '<b>Dieser Antrag wurde bereits mit der untenstehenden Antragsreferenz eingereicht. Wenn Sie die Referenz ändern möchten, starten Sie bitte einen neuen Antrag.</b>',
  'application.current_person': 'Sie bearbeiten derzeit den Antrag für',
  'DYN.USD': '$ USD',
  'DYN.EUR': '€ EUR',
  'DYN.ZWL': 'ZWL',
  'DYN.GBP': '£ GBP',
  'DYN.BWP': 'BWP',
  'participants.edit': 'Antragsteller bearbeiten',
  'request.existSaved': 'Ein gespeicherter Antrag wurde gefunden, möchten Sie dessen Daten laden? (Wenn Sie auf "Nein" klicken, wird der Antrag gelöscht und Sie starten einen neuen)',
  'participant.hasData': 'Sie haben bereits Daten für diesen Teilnehmer eingegeben. Wenn Sie fortfahren, gehen die Daten verloren. Sind Sie sicher, dass Sie fortfahren möchten?',
  'participants.title': 'Antragsteller',
  'participants.desc': 'Bitte wählen Sie sich selbst und/oder die Person(en) aus, die Teil dieses Antrags sein werden.</br><b>Die Zahlung am Ende erfolgt für alle ausgewählten Personen.</b></br></br Sie können jederzeit eine Person hinzufügen/entfernen oder ihren Antrag bearbeiten, indem Sie auf den Namen klicken, der über dem Formular angezeigt wird.</br></br>{participantText}',
  'participant.ready': 'Wenn Sie bereit sind, klicken Sie auf "Fortfahren".',
  'participant.missing': 'Bitte erstellen Sie vor dem Fortfahren mindestens eine Person.',
  'travelName.title': 'Antragsreferenz',
  'form.travelName': 'Antragsreferenz',
  'form.travelName.desc': 'Die Antragsreferenz wird verwendet, um Ihren Antrag einfacher zu identifizieren.',
  'err.travelName.required': 'Antragsreferenz ist erforderlich',
  'nationality.title': 'Staatsangehörigkeit',
  'nationality.desc': 'Bitte wählen Sie Ihre Staatsangehörigkeit. Die Liste der verfügbaren Dokumenttypen variiert je nach Auswahl.',
  'passportType.title': 'Dokumententyp',
  'passportType.desc': 'Bitte wählen Sie die Art des Dokuments, das Sie für diesen Antrag verwenden.',
  'visaType.title': 'Verfügbare Einreiseoptionen',
  'visaType.desc': 'Bitte wählen Sie die Einreiseoption, für die Sie sich bewerben möchten. Diese Liste variiert je nach Staatsangehörigkeit und Passart.',
  'urgent.title': 'Ist das dringend?',
  'urgent.desc': 'Wenn dieser Visumantrag <b>dringend</b> ist, wählen Sie diese Option. Die Bearbeitungszeit wird kürzer sein, aber eine <b>zusätzliche Gebühr</b> wird für diesen Service erhoben.</br></brBitte beachten Sie, dass die Bearbeitungszeit je nach Visumstyp und Antragsland variieren kann.',
  'urgent.checkbox': 'Ich möchte, dass mein Visumantrag dringend bearbeitet wird, und ich verstehe, dass für diesen Service eine zusätzliche Gebühr erhoben wird.',
  'visa.paidOnArrival.title': 'Visum bei Ankunft bezahlt',
  'visa.paidOnArrival.desc': 'Ermöglicht es berechtigten Reisenden, ein Visum bei der Ankunft am Einreisehafen zu erhalten, was den Einreiseprozess für Kurzzeitbesuche vereinfacht.',
  'visa.paidOnline.title': 'Visum online bezahlt',
  'visa.paidOnline.desc': 'Ermöglicht eine oder mehrere Einreisen in das Land innerhalb eines bestimmten Zeitraums von einem (1) Monat, wobei für nachfolgende Einreisen ein neues Visum erforderlich ist.',
  'dashboard.zweentry': 'Einreiseerklärung',
  'PassportType': 'Passart',
  'PassportCountry': 'Passausstellungsland',
  'form.passportCountry': 'Passausstellungsland',
  'DYN.ordinary': 'Gewöhnlicher Pass',
  'DYN.diplomatic': 'Diplomatenpass',
  'DYN.official': 'Offizieller Pass',
  'DYN.service': 'Dienstpass',
  'DYN.refugee': 'Flüchtlingspass',
  'DYN.ordinary.desc': 'Ein gewöhnlicher Pass ist die am häufigsten ausgestellte Art von Pass, der für allgemeine Reisezwecke an Bürger ausgegeben wird.',
  'DYN.diplomatic.desc': 'Ein Diplomatenpass wird Diplomaten und Konsuln für offizielle Reisen und Aufenthalte im Ausland ausgestellt.',
  'DYN.refugee.desc': 'Ein Flüchtlingspass wird von einem Land an Personen ausgestellt, die es als Flüchtlinge anerkennt, um ins Ausland zu reisen.',
  'DYN.official.desc': 'Ein offizieller Pass wird Regierungsangestellten für offizielle Reisen und Aufenthalte im Ausland ausgestellt.',
  'DYN.service.desc': 'Ein Dienstpass wird Regierungsangestellten für offizielle Reisen und Aufenthalte im Ausland ausgestellt.',
  'DYN.marine': 'Seemannspass',
  'DYN.marine.desc': 'Ein Seemannspass wird an Seeleute und Besatzungsmitglieder für Reisen und Aufenthalte im Ausland ausgestellt.',
  'DYN.laissez-passer': 'Laissez-passer',
  'DYN.laissez-passer.desc': 'Ein Laissez-passer ist ein von den Vereinten Nationen oder ihren Agenturen ausgestelltes Reisedokument für offizielle Reisen und Aufenthalte im Ausland.',
  'err.PassportType.required': 'Passart ist erforderlich',
  'Urgent': 'Dies ist eine dringende Anfrage. Ich verstehe, dass die Bearbeitungszeit kürzer sein wird, die Gebühren jedoch höher.',
  'photo.passport.scanned': 'Reisepass jetzt gescannt',
  'review.nr.final': 'Endprüfung',
  'review.nr': 'Überprüfung Nr. ',
  'err.ArrivalDate.maxDateMilliTo': 'Es ist ein Zeitraum von mindestens sechs (6) Monaten zwischen dem Ablaufdatum Ihres Reisepasses und Ihrem Ankunftsdatum im Land erforderlich. Bitte erneuern Sie diesen Reisepass, bevor Sie ein Visum beantragen.',
  'err.DateOfExpiry.maxDateMilliTo': 'Ihr Reisepass läuft weniger als sechs (6) Monate vor Ihrem Ankunftsdatum im Land ab. Bitte erneuern Sie diesen Reisepass, bevor Sie ein Visum beantragen.',
  'place.update.error': 'Beim Aktualisieren des Ortes ist ein Fehler aufgetreten',
  'dashboard.kiosk.start': 'Prozess starten',
  'form.role.kiosk': 'Kiosk',
  'currency_desc': 'Bitte wählen Sie den Betrag und die Währung der zum Zeitpunkt der Einreise verfügbaren Mittel aus:',
  'FundsAvailable': 'Betrag',
  'FundsAvailableCurrency': 'Währung',
  'err.FundsAvailable.required': 'Verfügbare Mittel zum Zeitpunkt der Einreise sind erforderlich',
  'err.FundsAvailableCurrency.required': 'Währung der verfügbaren Mittel zum Zeitpunkt der Einreise ist erforderlich',
  'emergency_contact_text': 'Bitte geben Sie im Notfall die Kontaktdaten einer Person an, die erreichbar ist.',
  'emergency_contact': 'Notfallkontakt / Nächster Angehöriger',
  'err.BirthDay.mustBeEqualOrBefore': 'Das Geburtsdatum muss gleich oder vor dem Ausstellungsdatum des Reisepasses liegen',
  'err.DateOfIssue.mustBeEqualOrAfter': 'Das Ausstellungsdatum muss gleich oder nach dem Geburtsdatum liegen',
  'internet.lost': 'Internetverbindung verloren.</br> Bitte überprüfen Sie Ihre Verbindung.',
  'err.DateOfExpiry.maxDateMilliFromToday': 'Ihr Reisepass läuft weniger als sechs (6) Monate ab heute ab. Bitte erneuern Sie diesen Reisepass, bevor Sie ein Visum beantragen.',
  'err.DepartureDate.maxDateMilliFrom': 'Das Abflugdatum liegt zu weit in der Zukunft im Vergleich zum Ankunftsdatum. Bitte beachten Sie die Zeitbegrenzung des Visums.',
  'requirements': 'Anforderungen',
  'err.file.size': 'Dateigröße ist zu groß. Maximale Größe ist: ',
  'revision.success': 'Ihre Aktualisierungsanfrage wurde erfolgreich eingereicht. Sie erhalten eine E-Mail mit dem QR-Code, sobald er genehmigt wurde.',
  'PAYMENT_UPDATED': 'Anfrage wurde aktualisiert',
  'revision.free': 'Kostenlose Überarbeitung',
  'err.Email.pattern': 'E-Mail muss das richtige Format haben',
  'err.Phone.pattern': 'Telefonnummer muss das richtige Format haben, z. B.: +263771234567',
  'revision.start': 'Wir haben uns darum gekümmert, die Felder mit den Informationen auszufüllen, die Sie im ursprünglichen Antrag angegeben haben, mit Ausnahme der Felder, die Probleme verursachen. Bitte überprüfen Sie die Informationen und korrigieren Sie sie. Möchten Sie fortfahren?',
  'file_uploaded': 'Datei bereits hochgeladen und gespeichert',
  'revision.person_not_found': 'Der dem Antrag beigefügte Antragsteller konnte nicht gefunden werden.',
  'refusal.desc2': 'Folgende Felder stellen ein Problem dar:',
  'start_correction': 'Korrektur beginnen',
  'PAYMENT_SUSPEND_CHANGE': 'Anfrage ausgesetzt, Änderungen angefordert.',
  'payment.selectMode': 'Bitte wählen Sie die Felder aus, die ein Problem darstellen. Sie können mehrere Felder auswählen.',
  'suspendReason.desc': 'Bitte schreiben Sie eine kurze Beschreibung der Überarbeitungsanforderung. Diese Beschreibung wird dem Anforderer zusammen mit den ausgewählten Problemfeldern zugesendet.',
  'refusalReason.incomplete_or_missing': 'Unvollständige oder fehlende Informationen im Formular.',
  'refusalReason.inconsistencies_or_contradictions': 'Inkonsistenzen oder Widersprüche in den bereitgestellten Informationen.',
  'refusalReason.incorrect_misleading_info': 'Falsche, irreführende oder betrügerische Informationen.',
  'refusalReason.missing_or_illegible_documents': 'Erforderliche Dokumente nicht bereitgestellt, unvollständig oder unleserlich.',
  'refusalReason.documents_not_compliant': 'Dokumente entsprechen nicht den Anforderungen (Qualität, Gültigkeit).',
  'refusalReason.non_compliance_visa_requirements': 'Nichteinhaltung spezifischer Visumanforderungen.',
  'refusalReason.terms_conditions_not_accepted': 'Bedingungen des Visums nicht akzeptiert.',
  'refusalReason.payment_issues': 'Nichtzahlung der Visagebühren oder Fehler im Zahlungsprozess.',
  'refusalReason.problems_with_personal_details': 'Probleme im Zusammenhang mit Alter, Nationalität oder Familienstand des Antragstellers.',
  'refusalReason.criminal_history_travel_issues': 'Probleme mit der Kriminal- oder Reisegeschichte.',
  'refusalReason.inadequate_purpose_of_visit': 'Unzureichender oder nicht konformer Zweck des Besuchs für den Visumtyp.',
  'refusalReason.security_background_concerns': 'Bedenken bei Sicherheits- oder Hintergrundprüfungen.',
  'refusalReason.doubts_about_authenticity': 'Zweifel an der Echtheit der bereitgestellten Informationen oder Dokumente.',
  'refusalReason.legitimacy_concerns': 'Fragen zur Legitimität des Aufenthalts oder der Einladung.',
  'refusalReason.form_entry_or_technical_issues': 'Formulareingabefehler oder technische Probleme im Antragsprozess.',
  'refusalReason.electronic_submission_difficulties': 'Schwierigkeiten bei der elektronischen Einreichung von Dokumenten.',
  'refusalReason.host_country_capacity_or_restrictions': 'Kapazität des Gastgeberlandes überschritten oder aktuelle Beschränkungen.',
  'refusalReason.exceptional_diplomatic_political_considerations': 'Außergewöhnliche diplomatische oder politische Überlegungen.',
  'refusalReason.exceptional_circumstances_humanitarian_reasons': 'Außergewöhnliche Umstände oder humanitäre Gründe für die Ablehnung.',
  'refusalReason.other': 'Andere, bitte spezifizieren.',
  'err.BirthDay.maxDate': 'Das Geburtsdatum muss vor dem heutigen Tag liegen',
  'err.DepartureDate.minDate': 'Das Abreisedatum muss nach dem heutigen Tag liegen',
  'err.ArrivalDate.minDate': 'Das Ankunftsdatum muss nach dem heutigen Tag liegen',
  'err.DateOfExpiry.minDate': 'Das Ablaufdatum muss nach dem heutigen Tag liegen',
  'err.DateOfIssue.maxDate': 'Das Ausstellungsdatum muss vor dem heutigen Tag liegen',
  'err.ArrivalDate.mustBeEqualOrBefore': 'Das Ankunftsdatum muss gleich oder vor dem Abreisedatum liegen',
  'err.DepartureDate.mustBeEqualOrAfter': 'Das Abreisedatum muss gleich oder nach dem Ankunftsdatum liegen',
  'qr.request_desc': 'Dieser QR-Code dient als digitaler Pass für die Visumbearbeitung. Die Regierung behält sich das Recht vor, die Einreise zum Zeitpunkt der Visumkontrolle zu genehmigen oder abzulehnen.',
  'PAYMENT_REFUSED': 'Zahlung abgelehnt',
  'request_state.PENDING': 'Ausstehend',
  'document.Screening': 'Überprüfung',
  'deletePerson.error.DYN.pass': 'Beim Löschen der Person ist ein Fehler aufgetreten. Sie können keine Person löschen, die bereits für ein Dokument bezahlt hat.',
  'deletePerson.error': 'Beim Löschen der Person ist ein Fehler aufgetreten. Sie können keine Person löschen, die bereits einen eingereichten Antrag hat.',
  'miscellaneousTrip': 'Reisedetails',
  'miscellaneous_text': 'Bitte geben Sie die folgenden Informationen wahrheitsgemäß ein',
  'err.SpouseBirthDay.max': 'Ungültiges Geburtsdatum des Ehepartners',
  'err.ArrivalDate.min': 'Ungültiges Ankunftsdatum',
  'err.DepartureDate.min': 'Ungültiges Abreisedatum',
  'err.DateOfIssue.max': 'Ungültiges Ausstellungsdatum',
  'err.BirthDay.max': 'Ungültiges Geburtsdatum',
  'err.dependants_date_of_birth.max': 'Ungültiges Geburtsdatum des Angehörigen',
  'err.DateOfExpiry.min': 'Ungültiges Ablaufdatum',
  'dependants': 'Angehörige',
  'err.PassportPhoto.required': 'Aktuelles Passfoto ist erforderlich',
  'err.PassportScan.required': 'Reisepassscan ist erforderlich',
  'err.ProofOfResidenceHost.required': 'Nachweis des Wohnorts des Gastgebers/Buchung ist erforderlich',
  'err.BusinessLetter.required': 'Geschäftsbrief ist erforderlich',
  'err.BusinessProfile.required': 'Geschäftsprofil ist erforderlich',
  'err.InvitationLetterAndBusinessProfile.required': 'Einladungsschreiben und Geschäftsprofil sind erforderlich',
  'err.SpouseFullName.required': 'Name des Ehepartners ist erforderlich',
  'err.SpousePassportNumber.required': 'Reisepassnummer des Ehepartners ist erforderlich',
  'err.SpouseBirthDay.required': 'Geburtsdatum des Ehepartners ist erforderlich',
  'err.SpousePlaceOfBirth.required': 'Geburtsort des Ehepartners ist erforderlich',
  'err.ArrivalDate.required': 'Ankunftsdatum ist erforderlich',
  'err.DepartureDate.required': 'Abreisedatum ist erforderlich',
  'err.DateOfIssue.required': 'Ausstellungsdatum ist erforderlich',
  'err.DateOfExpiry.required': 'Ablaufdatum ist erforderlich',
  'err.Email.required': 'E-Mail ist erforderlich',
  'err.Phone.required': 'Telefonnummer ist erforderlich',
  'err.HostName.required': 'Name des Gastgebers ist erforderlich',
  'err.LastName.required': 'Nachname ist erforderlich',
  'err.FirstName.required': 'Vorname(n) ist erforderlich',
  'err.BirthDay.required': 'Geburtsdatum ist erforderlich',
  'err.PlaceOfBirthCity.required': 'Geburtsstadt ist erforderlich',
  'err.PlaceOfBirthCountry.required': 'Geburtsland ist erforderlich',
  'err.Gender.required': 'Geschlecht ist erforderlich',
  'err.MaritalStatus.required': 'Familienstand ist erforderlich',
  'err.Nationality.required': 'Staatsangehörigkeit ist erforderlich',
  'err.PurposeOfVisit.required': 'Zweck des Besuchs ist erforderlich',
  'male': 'Männlich',
  'female': 'Weiblich',
  'other': 'Anderes',
  'single': 'Ledig',
  'married': 'Verheiratet',
  'separated': 'Getrennt',
  'divorced': 'Geschieden',
  'widowed': 'Verwitwet',
  'select_reason_first': 'Bitte wählen Sie auf der vorherigen Seite den Besuchsgrund aus, um die Liste der erforderlichen Dokumente zu sehen.',
  'visaIntro.title': 'Antrag',
  'askDocument': 'Antrag starten',
  'cart.load': 'Ein gespeicherter Antrag wurde gefunden, möchten Sie dessen Daten laden? (Wenn Sie auf "Nein" klicken, wird der Antrag gelöscht)',
  'cart.load.otherConfig': 'Ein gespeicherter Antrag wurde gefunden, aber das ausgewählte Dokument ist von einem anderen Visumstyp. Möchten Sie fortfahren und den vorherigen Antrag löschen?',
  'cart.keep': 'Möchten Sie Ihre Anfrage speichern und später fortfahren?',
  'err.email.pattern': 'E-Mail muss das richtige Format haben',
  'err.phone.pattern': 'Telefonnummer muss das richtige Format haben, z. B.: +263 77 123 4567',
  'remove': 'Entfernen',
  'dependant_add': 'Angehörigen hinzufügen',
  'dependant': 'Angehöriger',
  'err.dependants_name.required': 'Name des Angehörigen ist erforderlich',
  'err.dependants_passport_number.required': 'Reisepassnummer des Angehörigen ist erforderlich',
  'err.dependants_date_of_birth.required': 'Geburtsdatum des Angehörigen ist erforderlich',
  'err.dependants_place_of_birth.required': 'Geburtsort des Angehörigen ist erforderlich',
  'upload_file': 'Datei hochladen (< {size})',
  'PassportPhoto': 'Aktuelles Passfoto',
  'PassportScan': 'Reisepassscan',
  'ProofOfResidenceHost': 'Nachweis des Wohnorts des Gastgebers/Buchung',
  'AddressAtDestination': 'Adresse am Zielort',
  'HostInvitationLetter': 'Einladungsschreiben des Gastgebers',
  'ProofOfResidence': 'Wohnsitznachweis',
  'ApplicationLetter': 'Antragsschreiben',
  'ResidentialStatusOfHost': 'Wohnstatus des Gastgebers',
  'ReasonOfVisitProof': 'Nachweis des Besuchsgrundes',
  'BusinessLetter': 'Geschäftsbrief',
  'BusinessProfile': 'Geschäftsprofil',
  'InvitationLetterAndBusinessProfile': 'Einladungsschreiben und Geschäftsprofil',
  'InvitationLetter': 'Einladungsschreiben',
  'LetterOfAcceptanceFromSchool': 'Bestätigungsschreiben',
  'err.AddressAtDestination.required': 'Adresse am Zielort ist erforderlich',
  'err.HostInvitationLetter.required': 'Einladungsschreiben des Gastgebers ist erforderlich',
  'err.ProofOfResidence.required': 'Wohnsitznachweis ist erforderlich',
  'err.ApplicationLetter.required': 'Antragsschreiben ist erforderlich',
  'err.ResidentialStatusOfHost.required': 'Wohnstatus des Gastgebers ist erforderlich',
  'err.ReasonOfVisitProof.required': 'Nachweis des Besuchsgrundes ist erforderlich',
  'err.LetterOfAcceptanceFromSchool.required': 'Bestätigungsschreiben ist erforderlich',
  'err.InvitationLetter.required': 'Einladungsschreiben ist erforderlich',
  'DYN.male': 'Männlich',
  'DYN.female': 'Weiblich',
  'DYN.other': 'Anderes',
  'DYN.single': 'Ledig',
  'DYN.married': 'Verheiratet',
  'DYN.separated': 'Getrennt',
  'DYN.divorced': 'Geschieden',
  'DYN.widowed': 'Verwitwet',
  'passport.valid': 'Das gescannte Dokument scheint weder manipuliert noch widerrufen worden zu sein und ist vom ausstellenden Land unterzeichnet.',
  'travel_information_title': 'Vorsicht',
  'zwevisa_application_title': 'Bevor Sie fortfahren, stellen Sie sicher, dass',
  'zwevisa_application_text': '<ol><li><b>1. </b>Ihre Informationen korrekt und wahrheitsgemäß sind</li><li><b>2. </b>Sie über eine MasterCard oder Visa 3D Secure-Karte und ausreichend Geldmittel verfügen, um die Visagebühren zu bezahlen.</li><li><b>3. </b>Durch Ihre Zahlung und das Einreichen dieses Antrags bestätigen Sie auch, dass alle eingegebenen Informationen korrekt und wahrheitsgemäß sind. Falschangaben zur Visumerlangung sind illegal und bei Verurteilung strafbar.</li></ol>',
  'visaPrice.desc': 'Sie müssen diese Zahlung am Ende des Antrags mit Visa oder MasterCard vornehmen. Diese Zahlung ist nicht erstattungsfähig.',
  'visaPrice': 'Visumspreis',
  'required': 'Erforderlich',
  'DYN.holiday_visa': 'Urlaub',
  'DYN.live_here': 'Langzeitaufenthalt',
  'DYN.business_visa': 'Geschäftlich',
  'DYN.conferencing_visa': 'Konferenz',
  'DYN.transit_visa': 'Transit',
  'DYN.student_visa': 'Studium',
  'paypal': 'PayPal',
  'document': 'Dokument',
  'Dependants': 'Angehörige',
  'dependants_name': 'Name',
  'dependants_passport_number': 'Reisepassnummer',
  'dependants_date_of_birth': 'Geburtsdatum',
  'dependants_place_of_birth': 'Geburtsort',
  'Spouse': 'Ehepartner',
  'SpouseFullName': 'Name des Ehepartners',
  'SpousePassportNumber': 'Reisepassnummer des Ehepartners',
  'SpouseBirthDay': 'Geburtsdatum des Ehepartners',
  'SpousePlaceOfBirth': 'Geburtsort des Ehepartners',
  'ArrivalDate': 'Voraussichtliches Ankunftsdatum',
  'DepartureDate': 'Voraussichtliches Abreisedatum',
  'ReferencePreviousVisa': 'Referenz des vorherigen Visums',
  'PreviousConvictionsHome': 'Art der Verurteilung(en) im Heimatland',
  'travel_information': 'Reiseinformationen',
  'DateOfIssue': 'Ausstellungsdatum',
  'DateOfExpiry': 'Ablaufdatum',
  'PlaceofIssue': 'Ausstellungsort',
  'Email': 'E-Mail',
  'Phone': 'Telefonnummer',
  'Occupation': 'Beruf',
  'HomeAddress': 'Aktuelle Wohnadresse',
  'HostName': 'Name des Gastgebers',
  'form.seniority': 'Dienstgrad',
  'LastName': 'Nachname',
  'FirstName': 'Vorname(n)',
  'BirthDay': 'Geburtsdatum',
  'PlaceOfBirth': 'Geburtsort',
  'Gender': 'Geschlecht',
  'MaritalStatus': 'Familienstand',
  'CheckboxVisaFees': 'Ich bestätige, dass die bereitgestellten Informationen wahrheitsgemäß und korrekt sind und ich den Visagebühren, den Bedingungen und der Datenschutzrichtlinie zustimme.',
  'PassportNumber': 'Reisepassnummer',
  'Nationality': 'Staatsangehörigkeit',
  'PurposeOfVisit': 'Besuchsgrund',
  'VisaType': 'Visumtyp',
  'ResidenceProof': 'Wohnsitznachweis',
  'DestinationAddress': 'Zieladresse',
  'visitor_details': 'Angaben zum Antragsteller',
  'personal_details': 'Persönliche Angaben',
  'contact_details': 'Kontaktangaben',
  'miscellaneous_details': 'Reisedetails',
  'spouse_details': 'Angaben zum Ehepartner',
  'payment': 'Zahlung',
  'dashboard.documents.DYN.pass': 'Dokumentanforderung',
  'global.scan_qr': 'QR-Code überprüfen',
  'qr.nosuch': 'Laden... QR-Code im System noch nicht gefunden. Überprüfen Sie die Internetverbindung.',
  'qr.faked': 'Serverantwort ist, dass der QR-Code nicht existiert. Es handelt sich wahrscheinlich um eine Fälschung!',
  'qr.quicklook': 'Wichtige Informationen',
  'global.toPayments.DYN.pass': 'Zahlungen anzeigen',
  'dashboard.documents.DYN.zwevisa': 'Visumantrag',
  'global.toPayments.DYN.zwevisa': 'Anträge anzeigen',
  'form.createPlaceId': 'Orts-ID erstellen',
  'payment.seeDuplicata': 'Das Duplikat öffnen',
  'payment.duplicate.open': 'Neue Zahlung öffnen?',
  'ONE_DAY': '1 Tag',
  'THREE_DAYS': '3 Tage',
  'ONE_WEEK': '1 Woche',
  'TWO_WEEKS': '2 Wochen',
  'ONE_MONTH': '1 Monat',
  'TWO_MONTHS': '2 Monate',
  'SIX_MONTHS': '6 Monate',
  'ONE_YEAR': '1 Jahr',
  'dropdown.since': 'Seit',
  'payments.hasFilter': 'Filter ist aktiv',
  'payments.clear': 'Zurücksetzen',
  'payments.clearFilters': 'Alle Filter entfernen',
  'place_created.open': 'Info-Seite des Ortes öffnen',
  'place_created.desc': 'Der neue Ort wurde erstellt und ist nun verwendbar.',
  'payment_status.DYN.NONE': '-- Kein Filter --',
  'dropdown.state': 'Status',
  'payment.create_noExternalId': 'Die Zahlung ist registriert, erfordert jedoch die Genehmigung eines Vorgesetzten, bevor sie verwendet werden kann. Eine E-Mail wird Ihnen zugeschickt, sobald die Zahlung akzeptiert oder abgelehnt wird.',
  'payment.qrList.title': 'Liste der QR-Zahlungen',
  'see.qr_list': 'Liste der QR-Codes anzeigen',
  'form.role.partner_registrer': 'Partner registrieren',
  'form.reason': 'Grund',
  'err.reason.required': 'Grund ist erforderlich',
  'global.reject': 'Ablehnen',
  'payment.validate_desc': 'Sind Sie sicher, dass Sie diese Zahlung validieren möchten?',
  'payment.reject_desc': 'Sind Sie sicher, dass Sie diese Zahlung ablehnen möchten? Vergessen Sie nicht, einen Grund anzugeben.',
  'qr.title': 'QR-Code',
  'payments.count.DYN.pass': 'Anzahl der Zahlungen: ',
  'payments.count.DYN.zwevisa': 'Anzahl der Anträge: ',
  'dashboard': 'Dashboard',
  'dashboard.document': 'Visumantrag',
  'dashboard.follow_up': 'Antragsverfolgung',
  'dashboard.people.DYN.pass': 'Personen',
  'dashboard.people.DYN.zwevisa': 'Gespeicherte Personen',
  'dashboard.my_account': 'Mein Konto',
  'form.refunded_date': 'Erstattungsdatum',
  'form.enter_message': 'Nachricht eingeben',
  'form.end_date': 'Enddatum',
  'form.start_date': 'Startdatum',
  'form.search_anything': 'Alles durchsuchen',
  'form.invalid_end_date': 'Ungültiges Enddatum',
  'form.invalid_start_date': 'Ungültiges Startdatum',
  'form.id': 'ID',
  'form.enter_date_range': 'Datumsbereich eingeben',
  'payments.scan': 'QR scannen',
  'print.qr.desc': 'Dies ist ein einzigartiger und gesicherter QR-Code, der nur so oft verwendet werden kann, wie angegeben. Kratzen Sie ihn nicht ab, um ihn weiterhin nutzbar zu halten.',
  'print.qr.title': 'QR-Code',
  'print.informations': 'Informationen',
  'print.notice.folding': 'Faltanleitung',
  'print.notice.desc2': 'Bei Fragen zu diesem Dokument wenden Sie sich bitte telefonisch oder per E-Mail an den Support.',
  'print.notice.desc1': 'Bewahren Sie dieses Dokument vor Wasser oder Flammen auf.',
  'print.notice.title': 'Hinweis',
  'client.extended.openSuchPayment': 'Es ist bereits eine Zahlung unter diesem Benutzer aktiv',
  'form.choose_role': 'Rolle auswählen',
  'create_card_payment.fail_title': 'Zahlung in Bearbeitung',
  'create_card_payment.fail_text': 'Dies könnte einen Moment dauern. Sie werden per E-Mail benachrichtigt, sobald der Vorgang abgeschlossen ist. Die Zahlung und der QR-Code werden in Ihren Zahlungen verfügbar sein.',
  'err.externalId.required': 'Zahlungsreferenz ist erforderlich',
  'err.placeExternalId.required': 'Externe ID ist erforderlich',
  'form.externalId': 'Zahlungsreferenz',
  'form.userExternalId': 'Externe ID',
  'global.confirm': 'Bestätigen',
  'global.submit': 'Einreichen',
  'global.create': 'Erstellen',
  'global.back': 'Zurück',
  'global.close': 'Schließen',
  'global.contact_support': 'Support kontaktieren',
  'global.previous': 'Vorherige',
  'global.next': 'Nächste',
  'global.accept': 'Akzeptieren',
  'global.under_dev': 'In Entwicklung',
  'global.logout': 'Abmelden',
  'global.support': 'Support',
  'global.consume': 'Verbrauchen',
  'global.validate': 'Validieren',
  'global.refund': 'Erstatten',
  'global.duplicate': 'Duplizieren',
  'global.delete': 'Löschen',
  'global.see_more': 'Mehr sehen',
  'global.update': 'Aktualisieren',
  'global.modify': 'Ändern',
  'global.download': 'Herunterladen',
  'global.personal': 'Persönlich',
  'global.print': 'Drucken',
  'global.print_c7': 'Ticket drucken',
  'global.print_a4': 'Zertifikat drucken',
  'global.checkIn': 'Einchecken',
  'global.checkOut': 'Auschecken',
  'choose-lang.title': 'Spracheinstellung',
  'choose-lang.desc': 'Bitte wählen Sie die Sprache, die für diesen Antrag verwendet werden soll.',
  'global.all': 'Alle',
  'global.personalNum': 'Personal-Nr.',
  'global.passportNum': 'Passnummer',
  'global.not_available': 'Nicht verfügbar',
  'global.show_qr': 'QR-Code anzeigen',
  'global.show_qr_signature': 'QR-Code (signiert) anzeigen',
  'global.no_results_for': 'Keine Ergebnisse für',
  'global.delete_sure': 'Diese Aktion ist irreversibel, sind Sie sicher, dass Sie dies löschen möchten?',
  'global.add_new': 'Neu hinzufügen',
  'global.add': 'Hinzufügen',
  'global.permissions': 'Berechtigungen',
  'global.send': 'Senden',
  'global.block': 'Blockieren',
  'global.blocked': 'Blockiert',
  'global.unblock': 'Entsperren',
  'global.daily_otp': 'Täglicher Code',
  'global.refresh': 'Aktualisieren',
  'global.connect': 'Verbinden',
  'global.disconnect': 'Trennen',
  'form.email': 'E-Mail',
  'form.name': 'Name',
  'form.firstName': 'Vorname(n)',
  'form.lastName': 'Nachname',
  'form.password': 'Passwort',
  'form.password2': 'Passwort erneut eingeben',
  'form.linkType': 'Welche Verbindung hat diese Person zu Ihnen?',
  'form.userId': 'Benutzer-ID',
  'form.parentId': 'Eltern-ID',
  'form.amount': 'Betrag',
  'form.currency': 'Währung',
  'form.metadata': 'Metadaten',
  'form.buyingOption': 'Gekauftes Dokument',
  'form.place': 'Ort',
  'form.terms': 'Ich stimme den Nutzungsbedingungen und der Datenschutzrichtlinie zu',
  'form.role': 'Rolle',
  'form.no_data': 'Keine Daten stimmen mit dem Filter überein',
  'form.filter': 'Filter',
  'form.phoneNumbers': 'Telefonnummern',
  'form.connectUAPattern': 'Benutzeragentenmuster',
  'form.connectIPRange': 'IP-Bereich',
  'form.state': 'Zustand',
  'form.type': 'Typ',
  'form.operation': 'Operation',
  'form.created_date': 'Erstellungsdatum',
  'form.created_place': 'Erstellungsort',
  'form.consumed_place': 'Verbrauchsort',
  'form.refunded_place': 'Erstattungsort',
  'form.email_code': 'Code aus der E-Mail',
  'form.addToPlace': 'Orts-ID',
  'form.placeToAdd': 'Hinzugefügter Ort',
  'form.message': 'Nachricht',
  'form.phone': 'Telefonnummer',
  'form.worker': 'Mitarbeiter-ID',
  'form.camera': 'Kamera',
  'form.comment': 'Optionaler Kommentar',
  'form.password_old': 'Aktuelles Passwort',
  'form.password_new': 'Neues Passwort',
  'form.password_new2': 'Neues Passwort erneut eingeben',
  'form.idCard': 'Personalausweis-Nr.',
  'form.type.CASH': 'Bar',
  'form.type.CARD': 'Karte',
  'form.currency.EUR': 'Euro',
  'form.currency.USD': 'US-Dollar',
  'form.currency.ZAR': 'Rand',
  'form.role.customer': 'Kunde',
  'form.role.worker': 'Mitarbeiter',
  'form.confirmed': 'Bestätigt?',
  'yes': 'Ja',
  'no': 'Nein',
  'form.borderPass': 'Grenzpass ausstellen',
  'form.role.orbis_consumer': 'Orbis-Kunde',
  'form.role.admin': 'Administrator',
  'form.choose_currency': 'Währung auswählen',
  'form.choose_place': 'Ort auswählen',
  'form.choose_type': 'Typ auswählen',
  'form.choose_buyingOption': 'Dokument auswählen',
  'form.choose_camera': 'Kamera auswählen',
  'form.perm1': 'QR-Code erstellen',
  'form.perm2.DYN.pass': 'QR-Code verwenden',
  'form.perm2.DYN.zwevisa': 'Visumantrag verwenden',
  'form.perm3.DYN.pass': 'QR-Code duplizieren/erstatten & Z von Unterplätzen',
  'form.perm3.DYN.zwevisa': 'Visumantrag überprüfen',
  'form.perm4.DYN.pass': 'Benutzer erstellen/verwalten & Unterplätze erstellen',
  'form.perm4.DYN.zwevisa': 'Orte, Benutzer und Visumanträge erstellen/verwalten',
  'done.password_changed': 'Passwort geändert',
  'done.user_created': 'Benutzer wurde erstellt',
  'done.place_created': 'Ort wurde erstellt',
  'done.account_created': 'Konto wurde erstellt, überprüfen Sie Ihre E-Mails zur Bestätigung',
  'client.extended.clientNotAllowed': 'Ihr Konto ist an diesem Ort derzeit nicht zulässig. Bitte überprüfen Sie Ihre Verbindungseinstellungen.',
  'err.submit': 'Fehler beim Einreichen des Formulars',
  'err.email.required': 'E-Mail ist erforderlich',
  'err.email.email': 'E-Mail muss das richtige Format haben: email@mail.com',
  'err.password.required': 'Passwort ist erforderlich',
  'err.password.minlength': 'Das Passwort muss zwischen 6 und 20 Zeichen lang sein',
  'err.password.maxlength': 'Das Passwort muss zwischen 6 und 20 Zeichen lang sein',
  'err.password2.required': 'Passwortwiederholung ist erforderlich',
  'err.password2.mustMatch': 'Passwörter stimmen nicht überein',
  'err.role.required': 'Rolle ist erforderlich',
  'err.name.required': 'Name ist erforderlich',
  'err.firstName.required': 'Vorname(n) ist erforderlich',
  'err.lastName.required': 'Nachname ist erforderlich',
  'err.terms.required': 'Die Bedingungen müssen akzeptiert werden',
  'err.amount.required': 'Betrag ist erforderlich',
  'err.currency.required': 'Währung ist erforderlich',
  'err.metadata.required': 'Metadaten sind erforderlich',
  'err.place.required': 'Ort ist erforderlich',
  'err.noSmsPerm': 'SMS-Recht wurde nicht gewährt',
  'err.noSmsReceive': 'SMS-Lektüre kann nicht gebunden werden',
  'err.code.required': 'Code aus der E-Mail ist erforderlich',
  'err.buyingOption.required': 'Kaufoption ist erforderlich',
  'err.addToPlace.required': 'Orts-ID ist erforderlich',
  'err.phone.required': 'Telefonnummer ist erforderlich',
  'err.message.required': 'Nachricht ist erforderlich',
  'err.text.maxlength': 'Text ist zu lang',
  'err.old.required': 'Aktuelles Passwort ist erforderlich',
  'err.idCard.required': 'Personalausweis-Nr. ist erforderlich',
  'helps.showProceed': 'Vorgang anzeigen',
  'helps.showScan': 'Dokument scannen',
  'helps.captureFingerprint': 'Fingerabdruck erfassen',
  'payment_status.DYN.undefined': 'Aktueller Zustand - keine Operation',
  'client.extended.missingResourceLogin': 'Verbindung konnte nicht hergestellt werden, falsche Anmeldedaten',
  'client.extended.linkedAccountExists': 'Ein Konto mit dieser E-Mail existiert bereits',
  'client.extended.attachedPayments': 'Sie können diese Person nicht ändern, sie hat bereits einen verknüpften Antrag.',
  'client.extended.badPayload': 'Fehlerhafte Informationen in der Anfrage',
  'client.extended.placeNotAllowed': 'Ort nicht erlaubt',
  'client.extended.locked': 'Konto ist gesperrt',
  'client.extended.clientBadOtp': 'Falscher täglicher Code',
  'time': 'Zeit',
  'place': 'Ort',
  'home.first': 'Ihr Konto wurde jetzt bestätigt und Sie können sich anmelden.',
  'payOnline': 'Online bezahlen',
  'login.sign_in': 'Anmelden',
  'login.sign_up': 'Registrieren',
  'login.forgot_password': 'Passwort vergessen?',
  'photo.entryCountry': 'Bild beim Ein-/Auschecken',
  'photo.unavailable': 'Bild nicht verfügbar',
  'global.entry': 'Einreise',
  'global.exit': 'Ausreise',
  'menu.create_user': 'Benutzer +',
  'menu.create_place': 'Ort +',
  'menu.create_payment': 'Zahlung +',
  'menu.users': 'Benutzer',
  'menu.places': 'Orte',
  'menu.payments': 'Zahlungen',
  'menu.about': 'Über',
  'menu.contact': 'Kontakt',
  'create_user.title': 'Benutzer erstellen',
  'create_payment.title': 'Zahlung erstellen',
  'create_place.title': 'Ort erstellen',
  'users.title': 'Benutzerdatenbank',
  'payments.title': 'Zahlungsdatenbank',
  'payments.employee_graph': 'Zahlungen pro Mitarbeiterdiagramm',
  'payments.open_scan': 'QR-Code scannen',
  'payments.download_csv': 'CSV herunterladen',
  'create_card_payment.title': 'Online-Zahlung',
  'create_card_payment.success_title': 'Zahlung erhalten!',
  'create_card_payment.success_text': 'Ihre Zahlung wurde erfolgreich erhalten. Ihr QR-Code wurde Ihnen per E-Mail zugesendet und ist auch über den untenstehenden Button zugänglich.',
  'create_card_payment.no_data': 'Diese Zahlung ist hier nicht mehr verfügbar, überprüfen Sie Ihre E-Mails, um den QR-Code abzurufen, oder klicken Sie im Menü auf Zahlungen.',
  'places.title': 'Liste der Orte',
  'forgot_password.title': 'Passwort zurücksetzen',
  'forgot_password.title_new': 'Neues Passwort setzen',
  'forgot_password.sent': 'Bestätigung gesendet!',
  'forgot_password.sent_text': 'Eine Bestätigungs-E-Mail mit einem Link zum Zurücksetzen Ihres Passworts wurde gesendet.',
  'forgot_password.done': 'Passwort wurde zurückgesetzt',
  'forgot_password.done_text': 'Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich jetzt anmelden.',
  'welcome.first': 'Ihr Konto wurde bestätigt!',
  'welcome.title_1.DYN.pass': 'Hallo!',
  'welcome.text_1.DYN.pass': 'Vielen Dank, dass Sie sich bei {appName} angemeldet haben. Wir sind stolz darauf, Ihnen die beste Möglichkeit zu bieten, Ihre Zahlung in einen sicheren und einzigartigen QR-Code umzuwandeln.',
  'welcome.text_1.DYN.zwevisa': 'Vielen Dank, dass Sie sich bei {appName} angemeldet haben. Wir sind stolz darauf, Ihnen die beste Möglichkeit zu bieten, online ein Visum zu beantragen.',
  'welcome.text_1.1.DYN.pass': 'Lassen Sie uns ein wenig mehr über uns erfahren, oder?',
  'welcome.text_1.1.DYN.zwevisa': 'Lassen Sie uns ein wenig mehr über uns erfahren, oder?',
  'welcome.title_2.DYN.pass': 'Was ist {appName}?',
  'welcome.text_2.DYN.pass': '{appName} ermöglicht es Ihnen, online eine Zahlung einzureichen und im Gegenzug einen sicheren und einzigartigen QR-Code zu erhalten.',
  'welcome.text_2.DYN.zwevisa': '{appName} ermöglicht es Ihnen, online ein Visum zu beantragen und es per E-Mail sowie als sicheren und einzigartigen QR-Code zu erhalten, der alle Informationen enthält, die für Ihr Visum erforderlich sind.',
  'welcome.text_2.1.DYN.pass': 'Dieser Code kann dann verwendet werden, um ein offizielles Dokument wie einen Ausweis oder Ähnliches bei Ihrer örtlichen Behörde zu bezahlen.',
  'welcome.text_2.1.DYN.zwevisa': 'Dieser Code kann dann als Nachweis für Ihren genehmigten Visumantrag an der Grenze verwendet werden.',
  'welcome.title_3': 'Als Mitarbeiter',
  'welcome.text_3.DYN.pass': 'Mit {appName} kann ein Kunde zu Ihnen kommen, um ein offizielles Dokument zu bezahlen. Sie können die Zahlung per Karte oder in bar registrieren.',
  'welcome.text_3.DYN.zwevisa': 'Mit {appName} können Sie Personen an der Grenze ein- und auschecken. Sie können auch Visumanträge je nach Situation und Ihren Berechtigungen genehmigen oder ablehnen.',
  'welcome.text_3.1.DYN.pass': 'Ein sicherer und einzigartiger QR-Code wird dann generiert und ist bereit, für Ihren Kunden gedruckt zu werden.',
  'welcome.text_3.1.DYN.zwevisa': 'Sie können auch die Benutzer und Orte Ihrer Organisation verwalten.',
  'welcome.title_4.DYN.pass': 'Noch besser',
  'welcome.text_4.DYN.pass': 'Sie können sogar Zahlungen offline registrieren. Wir sind zuversichtlich, dass unser sicherer und zuverlässiger Weg zur Generierung unserer QR-Codes Ihnen zusagt.',
  'welcome.text_4.DYN.zwevisa': 'Sie können sogar Visumanträge offline verwalten. Wir sind zuversichtlich, dass unser sicherer und zuverlässiger Weg zur Generierung unserer QR-Codes Ihnen zusagt.',
  'welcome.text_4.1.DYN.pass': 'Sobald Sie wieder online sind, wird alles wieder synchronisiert.',
  'welcome.text_4.1.DYN.zwevisa': 'Sobald Sie wieder online sind, wird alles wieder synchronisiert.',
  'welcome.title_5.DYN.pass': 'Genießen!',
  'welcome.text_5.DYN.pass': 'Vielen Dank, dass Sie {appName} verwenden. Zögern Sie nicht, uns über den Kontakt-Button auf der Startseite Ihr Feedback zu senden.',
  'payment_status.DYN.zwevisa.PROPOSED': 'Zahlung ausstehend',
  'payment_status.DYN.zwevisa.PAID': 'Bereit zum Einchecken',
  'payment_status.DYN.zwevisa.PAID_NOT_CONFIRMED': 'Warten auf Überprüfung',
  'payment_status.DYN.zwevisa.USED': 'Ausgecheckt',
  'payment_status.DYN.zwevisa.DUPLICATED': 'Dupliziert',
  'payment_status.DYN.zwevisa.REFUNDED': 'Erstattet',
  'payment_status.DYN.zwevisa.EXPIRED': 'Abgelehnt',
  'payment_status.DYN.zwevisa.PENDING': 'Korrektur ausstehend',
  'payment_status.DYN.zwevisa.DELAY_PAY_NOT_CONFIRMED': 'Später zahlen - Nicht bestätigt',
  'payment_status.DYN.zwevisa.DELAY_PAY_NOT_CONFIRMED.FREE': 'Kostenlos - Nicht bestätigt',
  'payment_status.DYN.zwevisa.DELAY_PAY': 'Bereit zur Zahlung bei Ankunft',
  'payment_status.DYN.pass.PROPOSED': 'Vorgeschlagen',
  'payment_status.DYN.pass.PAID': 'Bezahlt',
  'payment_status.DYN.pass.PAID_NOT_CONFIRMED': 'Unbestätigt',
  'payment_status.DYN.pass.USED': 'Verwendet',
  'payment_status.DYN.pass.DUPLICATED': 'Dupliziert',
  'payment_status.DYN.pass.REFUNDED': 'Erstattet',
  'payment_status.DYN.pass.EXPIRED': 'Abgelaufen',
  'payments.make_bundle': 'Paket erstellen',
  'payments.validate': 'Bestätigen',
  'passportPage.askPassport': 'Reisepassantrag',
  'documentContinue.title': 'Einkauf fortsetzen',
  'documentContinue.stop': 'Kauf wiederholen',
  'documentContinue.desc1': 'Sie haben bereits den Antragsprozess für ein Dokument begonnen.',
  'documentContinue.desc2': 'Möchten Sie Ihren Einkauf fortsetzen?',
  'payment.history': 'Zahlungsverlauf',
  'permission.no_place_found': 'Kein neuer Ort für diesen Benutzer verfügbar',
  'bt.available_devices': 'Verfügbare Bluetooth-Geräte',
  'bt.no_available_devices': 'Keine verfügbaren Bluetooth-Geräte erkannt',
  'emailSend.title': 'E-Mail-Verifizierung',
  'emailSend.description': 'Um Ihr Konto zu bestätigen, müssen Sie auf den Link klicken, der sich in einer Bestätigungs-E-Mail befindet, die innerhalb von 24 Stunden an Ihre E-Mail-Adresse gesendet wurde.',
  'emailValid.title': 'Konto bestätigt',
  'emailValid.description': 'Ihr {appName}-Konto wurde erfolgreich bestätigt. Sie können nun auf Ihren persönlichen Bereich zugreifen, indem Sie sich mit Ihren Anmeldedaten anmelden.',
  'documentManagement.title': 'Visumantrag',
  'passportPage.select': 'Wählen Sie die Personen aus, die einen Reisepass beantragen möchten.',
  'passportPage.choosePerson': 'Wählen Sie den/die Antragsteller aus',
  'preenroll.title': 'Voranmeldung',
  'preenroll.description': 'Die Voranmeldung ermöglicht es Ihnen, den ersten Schritt zu beginnen, bevor Sie Ihr Dokument abschließen. Danach müssen Sie nur noch zu einem zugelassenen Zentrum gehen, um es zu validieren.',
  'passportEmbassy.title': 'Botschaftsdokument',
  'passportEmbassy.description': 'Ein Botschaftspass ist ein von einem souveränen Staat an seine Diplomaten ausgestelltes Dokument für deren Geschäftsreisen sowie an begleitende Mitglieder.',
  'zwevisa.title': 'Visum',
  'passwordSend.title': 'Bestätigung gesendet',
  'passwordSend.description': 'Sie haben eine Bestätigungs-E-Mail mit einem Link zum Zurücksetzen Ihres Passworts erhalten. Sie haben 24 Stunden Zeit, darauf zu klicken.',
  'forgotPassword.title': 'Passwort vergessen',
  'forgotPassword.description': 'Um Ihr Passwort zurückzusetzen, geben Sie unten die E-Mail-Adresse Ihres Kontos ein.',
  'forgotPassword.description.electron': 'Um Ihr Passwort zurückzusetzen, geben Sie unten die E-Mail-Adresse Ihres Kontos ein. Wenn Sie versuchen, ein Passwort für ein <b>Active Directory</b>-Konto wiederherzustellen, wenden Sie sich bitte an den Support.',
  'passwordNew.title': 'Neues Passwort',
  'modifiedPassword.title': 'Passwort geändert',
  'modifiedPassword.description': 'Das Passwort wurde geändert. Bitte melden Sie sich erneut an.',
  'people.title.DYN.pass': 'Personen',
  'people.title.DYN.zwevisa': 'Gespeicherte Personen',
  'people.addPerson.DYN.pass': 'Person hinzufügen',
  'people.addPerson.DYN.zwevisa': 'Neue Person erstellen',
  'meansPayment.title': 'Zahlungsmethoden',
  'onlinePayment.title': 'PayPal-Zahlung',
  'onlinePayment.description': 'Schließen Sie Ihre Zahlung ab, indem Sie online mit Ihrer Kreditkarte bezahlen.',
  'onlinePayment.pay': 'Bezahlen',
  'qrPayment.title': 'Zahlung per QR-Code',
  'qrPayment.description': 'Um Ihre Zahlung abzuschließen, scannen Sie einen gültigen QR-Code.',
  'qrPayment.scan': 'QR-Code scannen',
  'processPayment.description': 'Um eine Zahlung zu tätigen, geben Sie Ihre Kreditkarteninformationen ein.',
  'form.cardNumber': 'Kartennummer',
  'form.expireDate': 'Ablaufdatum',
  'successPayment.title': 'Bestellbestätigung',
  'successPayment.description1': 'Diese Zahlung wurde durchgeführt. Das Dokument wird bearbeitet, und Sie werden benachrichtigt, wenn es fertig ist oder wenn es überarbeitet werden muss.',
  'successPayment.description2': 'Bitte besuchen Sie den Support auf Ihrer Kontoseite, wenn Sie keine Benachrichtigung erhalten.',
  'trackingControl.title.DYN.pass': 'Antragsverfolgung',
  'trackingControl.title.DYN.zwevisa': 'Diesen Antrag verfolgen',
  'trackingControl.enroll': 'Einschreibung bereit',
  'trackingControl.treatment': 'Bearbeitung',
  'modifyData': 'Daten ändern',
  'stopModifyData': 'Datenänderung stoppen',
  'trackingControl.revision': 'Überarbeitungsanforderung',
  'askRevisionProcess': 'Behandlung läuft',
  'askRevision': 'Überarbeitung angefordert',
  'seeRevision': 'Die Überarbeitung ansehen',
  'askOrdinaryPassport': 'Antrag auf einen gewöhnlichen Reisepass',
  'askPassport': 'Ein Dokument anfordern',
  'askVisa': 'Ein Visum anfordern',
  'actualRevision': 'Überprüfung des Antrags',
  'askRevision.description': 'Um Ihre Anfrage für einen gewöhnlichen Reisepass zu bearbeiten, senden Sie uns bitte eine Kopie Ihrer aktuellen Wohnsitzbescheinigung.',
  'peopleNew.title.DYN.pass': 'Neue Personen',
  'peopleNew.title.DYN.zwevisa': 'Neue Person',
  'peopleFound.title.DYN.pass': 'Person gefunden',
  'peopleFound.title.DYN.zwevisa': 'Person gefunden',
  'peopleFound.description.DYN.pass': 'Die Person, die den eingegebenen Informationen entspricht, wurde der Liste der gespeicherten Personen hinzugefügt.',
  'peopleFound.description.DYN.zwevisa': 'Die Person, die den eingegebenen Informationen entspricht, wurde der Liste der gespeicherten Personen hinzugefügt.',
  'payment.commandHistory': 'Zahlungshistorie',
  'menu.placesPerm': 'Orte und Berechtigungen',
  'block.message': 'Möchten Sie diesen Benutzer blockieren?',
  'menu.addPlace': 'Einen Ort hinzufügen',
  'label.numberAccount': 'Konto-Nr.',
  'place.sheet.open': 'Blatt des Ortes öffnen',
  'place.delete': 'Verknüpfung mit diesem Ort löschen',
  'place.update.success': 'Der Ort wurde erfolgreich aktualisiert.',
  'place.new': 'Neuer Ort',
  'place.create': 'Ort erstellen',
  'place.add.ask': 'Möchten Sie diesen Ort mit diesem Benutzer verknüpfen?',
  'place.add.ok': 'Sie haben diesen Ort erfolgreich mit diesem Benutzer verknüpft.',
  'place.delete.link': 'Verknüpfung mit diesem Ort löschen',
  'place.delete.link.ask': 'Sind Sie sicher, dass Sie die Verknüpfung mit diesem Ort aufheben möchten?',
  'place.delete.link.done': 'Sie haben die Verknüpfung mit dem Ort erfolgreich aufgehoben.',
  'user.new': 'Neuer Benutzer',
  'user.create': 'Benutzer erstellen',
  'user.created': 'Das Erstellen des neuen Benutzers war erfolgreich. Dieses Konto kann nun verwendet werden.',
  'user.sheet.open': 'Blatt des Benutzers öffnen',
  'payment.sheet.open': 'Blatt der Zahlung öffnen',
  'err.createplace': 'Erstellen nicht möglich. ID existiert bereits.',
  'place.parent': 'Übergeordneter Ort',
  'dropdown.sorting': 'Sortieren',
  'dropdown.qr': 'QR-Code scannen',
  'dropdown.pass': 'Dokument scannen',
  'err.noReader': 'Der Leser ist nicht verbunden. Bitte schließen Sie den Leser an, um fortzufahren.',
  'err.noCard': 'Kein gültiges biometrisches Dokument im Leser erkannt. Dies kann an der Verwendung eines nicht biometrischen Dokuments, der Vorlage einer unbefugten Kopie oder Fälschung oder einer Fehlfunktion der Antenne liegen.',
  'err.noConnect': 'Dieses Dokument entspricht nicht den ICAO-Standardprotokollen. Dies kann auf die Verwendung eines nicht biometrischen Dokuments, die Vorlage einer unbefugten Kopie oder Fälschung oder eine Fehlfunktion der Antenne zurückzuführen sein.',
  'err.noBAC': 'Sicherer Kanal zum Dokument konnte nicht hergestellt werden, ist es echt? Überprüfen Sie dieses Dokument gründlich manuell und befragen Sie den Inhaber.',
  'err.alteredDoc': 'Dokumentfelder konnten nicht gelesen werden, ist es echt? Überprüfen Sie dieses Dokument gründlich manuell und befragen Sie den Inhaber.',
  'err.revokedDocSigner': 'Der Unterzeichner dieses Dokuments wurde von der ausstellenden Behörde widerrufen. Möglicherweise wurde dieses Dokument betrügerisch unterzeichnet. Befragen Sie den Inhaber gründlich dazu.',
  'err.unverifiedDoc': 'Dokumentgültigkeit konnte nicht überprüft werden, Fehler im Authentifizierungsprotokoll, dies könnte auf ein außerhalb des Landes nach der ICAO-Masterliste ausgestelltes Dokument, ein gefälschtes Dokument oder eine Fehlfunktion der Antenne hindeuten.',
  'err.noPassiveAuthentication': 'Dokumentgültigkeit konnte nicht überprüft werden, Fehler im Authentifizierungsprotokoll, gefälschtes Dokument oder Fehlfunktion der Antenne.',
  'err.noResult': 'Keine Datei gefunden',
  'dropdown.user': 'Benutzer',
  'dropdown.meansPayment': 'Zahlungsmethoden',
  'payment.new.DYN.pass': 'Neue Zahlung',
  'payment.new.DYN.zwevisa': 'Neue Ausreise ohne Visum',
  'payment.create': 'Zahlung erstellen',
  'payment.created.register': 'Registrierte Zahlung',
  'payment.created': 'Ihre Zahlung wurde gespeichert und ist nun in der Liste der Zahlungen sichtbar.',
  'payment.created.qr': 'Eine E-Mail mit dem generierten QR-Code wurde an die angegebene E-Mail-Adresse gesendet.',
  'text.status': 'Status',
  'phone.number': 'Telefonnummer',
  'idcard.number': 'Personalausweisnummer',
  'form.userCreator': 'Erstellt durch den Benutzer',
  'form.userRefund': 'Erstattet durch den Benutzer',
  'form.userConsumed': 'Verbraucht durch den Benutzer',
  'PRICEUP': 'Preis 0-9',
  'PRICEDOWN': 'Preis 9-0',
  'RECENT': 'Neueste',
  'LATEST': 'Älteste',
  'STATUSUP': 'Status a-z',
  'STATUSDOWN': 'Status z-a',
  'payment.success': 'Ihre Zahlung wurde registriert',
  'err.meansPayment.required': 'Zahlungsmethode erforderlich',
  'payment.consume.ask.DYN.pass': 'Möchten Sie diese Zahlung wirklich verwenden?',
  'payment.consume.ask.DYN.zwevisa': 'Möchten Sie diesen Visumantrag wirklich ein-/auschecken?',
  'payment.consume.done.DYN.pass': 'Ihre Zahlung wurde erfolgreich verwendet',
  'payment.consume.done.DYN.zwevisa': 'Ihr Visumantrag wurde erfolgreich ein-/ausgecheckt',
  'payment.consume.refuse.DYN.zwevisa': 'Dieser Visumantrag wurde erfolgreich abgelehnt.',
  'checkinOut.refuse': 'Möchten Sie diesen Antrag wirklich ablehnen?',
  'payment.duplicate.ask': 'Möchten Sie diese Zahlung wirklich duplizieren?',
  'payment.duplicate.done': 'Ihre Zahlung wurde erfolgreich dupliziert',
  'payment.refund.ask': 'Möchten Sie diese Zahlung wirklich erstatten?',
  'payment.refund.done': 'Ihre Zahlung wurde erfolgreich erstattet',
  'payment.refund.exist': 'Diese Zahlung wurde bereits erstattet',
  'payment.paid.error': 'Ihre Zahlung muss den Status "Abgeschlossen" haben',
  'unlink.place.error': 'Sie müssen mindestens einen aktiven Ort haben, um diesen zu trennen. Bitte fügen Sie zuerst einen anderen Ort hinzu',
  'block.done': 'Das Konto wurde gesperrt',
  'block.unblock': 'Das Konto wurde entsperrt',
  'dailyOtp.txt': 'Täglicher Sicherheitscode ',
  'place-add.add': 'Ort hinzufügen',
  'permission.title': 'Erforderliche Berechtigung: Kamera',
  'qr.see': 'Den QR-Code anzeigen',
  'qr.desc.DYN.pass': 'Hier ist der QR-Code für die Zahlung. Er wird während eines Ausdrucks erscheinen und an die bei der Registrierung eingegebene E-Mail-Adresse gesendet.',
  'qr.desc.DYN.zwevisa': 'Hier ist der QR-Code für den Antrag. Er wird während eines Ausdrucks erscheinen und an die bei der Registrierung eingegebene E-Mail-Adresse gesendet.',
  'global.print.a4': 'Das A4-Dokument drucken',
  'dashboard.place.verif': 'Wenn Sie keinen Ort eingegeben haben, tun Sie dies bitte',
  'uptodate': 'Sie sind auf dem neuesten Stand',
  'block.yourself': 'Sie können sich nicht selbst blockieren',
  'lastPlace.delete': 'Der letzte Ort in diesem Konto wird gelöscht. Dies macht das Konto unbrauchbar. Fortfahren?',
  'place.add.done': 'Dieser Ort wurde erfolgreich zu diesem Benutzer hinzugefügt',
  'notallow.payment': 'Sie haben keine Berechtigung, eine Zahlung zu erstellen',
  'no.connection': 'Um auf diese Seite zuzugreifen, müssen Sie mit dem Internet verbunden sein. Bitte melden Sie sich erneut an',
  'place.delete.success': 'Dieser Ort wurde gelöscht.',
  'wrong.current': 'Sie haben ein falsches aktuelles Passwort eingegeben',
  'no.place': 'Kein Ort für dieses Konto.',
  'no.payment': 'Keine Zahlungen stimmen mit dem aktuellen Filter überein',
  'email.error': 'Ähnliche E-Mail existiert bereits',
  'email.notexist': 'E-Mail existiert nicht',
  'one.payment': 'Sie müssen mindestens eine oder mehrere Zahlungen erstellt und/oder gefiltert haben.',
  'err.client.extended.uuidUsed': 'Diese Zahlung wurde bereits verwendet',
  'no.activecamera': 'Keine aktive Kamera gefunden',
  'no.accessPermission': 'Dieses Konto hat keine Zugriffsberechtigung',
  'download.success': 'Ihre Datei wurde im Download-Ordner heruntergeladen',
  'print.name': 'Drucker',
  'print.success': 'Ihr Ticket wurde gedruckt',
  'print.need': 'Sie müssen zuerst eine Verbindung zu einem Drucker herstellen, um drucken zu können',
  'printer.connect': 'Sie sind jetzt mit dem Drucker verbunden:',
  'permission.desc': 'Der Dienst, den Sie verwenden möchten, erfordert die Erlaubnis zur Nutzung der Kamera Ihres Geräts',
  'err.personnalNR.required': 'Persönliche Nummer erforderlich',
  'err.passportNR.required': 'Passnummer erforderlich',
  'createPeople.ask.DYN.pass': 'Möchten Sie diese Person wirklich erstellen?',
  'createPeople.ask.DYN.zwevisa': 'Möchten Sie diese neue Person wirklich erstellen?',
  'createPeopleDuplicate.ask.DYN.zwevisa': 'Es scheint, dass eine Person mit demselben Namen bereits in Ihrer Liste der registrierten Personen vorhanden ist. Möchten Sie diese Person trotzdem erstellen?',
  'createPeople.done.DYN.pass': 'Die Person wurde erstellt',
  'createPeople.done.DYN.zwevisa': 'Die Person wurde erstellt',
  'deletePerson.DYN.pass': 'Diese Person löschen',
  'deletePerson.DYN.zwevisa': 'Diese Person löschen',
  'deletePerson.ask.DYN.pass': 'Sind Sie sicher, dass Sie diese Person löschen möchten?',
  'deletePerson.ask.DYN.zwevisa': 'Sind Sie sicher, dass Sie diese Person löschen möchten?',
  'deletePerson.valid.DYN.pass': 'Die Person wurde gelöscht',
  'deletePerson.valid.DYN.zwevisa': 'Die Person wurde gelöscht',
  'personModification.ask.DYN.pass': 'Sind Sie sicher, dass Sie die Daten dieser Person ändern möchten?',
  'personModification.ask.DYN.zwevisa': 'Sind Sie sicher, dass Sie die Daten dieser Person ändern möchten?',
  'personModification.valid.DYN.pass': 'Die Daten der Person wurden geändert',
  'personModification.valid.DYN.zwevisa': 'Die Daten der Person wurden geändert',
  'noPayment': 'Keine Zahlung',
  'selfie.desc': 'Ein Foto ist für die Dokumentanforderung erforderlich, stellen Sie sicher, dass Sie bei guter Beleuchtung sind und Ihr Telefon ruhig halten, um ein unscharfes Bild zu vermeiden. Stellen Sie außerdem sicher, dass alle Details Ihres Gesichts auf dem Foto gut sichtbar sind.',
  'selfie.take': 'Das Bild aufnehmen',
  'ZWEVISA.title': 'Visum',
  'EMBASSYPASS.title': 'Botschaftspass',
  'PREENROLL.title': 'Voranmeldung',
  'pay.temp': 'Zahlung derzeit nicht verfügbar, bitte wählen Sie die Voranmeldung',
  'OK': 'Anfrage akzeptiert. Bereit zur Anmeldung',
  'OK_MODIFIED': 'Anfrage akzeptiert. Bearbeitung läuft.',
  'DYN.NOK': 'Anfrage abgelehnt',
  'NOK_TO_MODIFY': 'Antrag abgelehnt. Aktion erforderlich',
  'PROCESSING': 'In Bearbeitung',
  'form.merchantReference': 'Händlerreferenz',
  'form.pan': 'Kartennummer',
  'err.MerchantReference.required': 'Händlerreferenz erforderlich',
  'err.PAN.required': 'Kartennummer erforderlich',
  'err.ExpiryDate.required': 'Ablaufdatum erforderlich',
  'WIP': 'In Arbeit',
  'PAYMENT_CREATED': 'Zahlung erstellt',
  'PAYMENT_VALIDATED': 'Zahlung validiert',
  'menu.requests.DYN.zwevisa': 'Anträge',
  'menu.createRequests.DYN.zwevisa': 'Neuer Antrag',
  'menu.requests.DYN.pass': 'Anfragen',
  'menu.createRequests.DYN.pass': 'Neue Anfrage',
  'no.data': 'Keine Daten entsprechen dem aktuellen Filter',
  'dropdown.requestState': 'Antragsstatus',
  'dropdown.paymentState': 'Zahlungsstatus',
  'one.request': 'Sie müssen mindestens eine sichtbare Anfrage haben.',
  'request_state.SUBMITTED': 'Eingereicht',
  'request_state.CONFIRMED': 'Bestätigt',
  'request_state.TREATED': 'Bearbeitet',
  'request_state.TRANSFERRED': 'Übertragen',
  'request_state.CANCELLED': 'Storniert',
  'request_state.REFUSED': 'Abgelehnt',
  'request_state.UNUSABLE': 'Unbrauchbar',
  'request_state.NULL': 'Unbezahlt',
  'request_state.NONE': '-- Kein Filter --',
  'getPayment.error': 'Beim Abrufen der Zahlung ist ein Fehler aufgetreten.',
  'adminPaymentResult.tilte': 'Zahlungsergebnis',
  'ask.disconnect': 'Sind Sie sicher, dass Sie sich abmelden möchten?',
  'global.yes': 'Ja',
  'zwevisa.type.holiday': 'Urlaub',
  'zwevisa.type.business': 'Geschäftlich',
  'zwevisa.type.conferencing': 'Konferenz',
  'zwevisa.type.transit': 'Transit',
  'zwevisa.type.student': 'Studium',
  'zwevisa.entry.single': 'Einmalige Einreise',
  'zwevisa.entry.double': 'Doppelte Einreise',
  'zwevisa.entry.kaza': 'Kaza Univisa',
  'applicant.gender.male': 'Männlich',
  'applicant.gender.female': 'Weiblich',
  'applicant.gender.other': 'Andere',
  'marital.status.single': 'Ledig',
  'marital.status.married': 'Verheiratet',
  'marital.status.separated': 'Getrennt',
  'marital.status.divorced': 'Geschieden',
  'marital.status.widowed': 'Verwitwet',
  'form.search': 'Suchen',
  'role.customer': 'Kunde',
  'role.partner_registrer': 'Partnerregistrar',
  'request_state.PROPOSED': 'Zahlung ausstehend',
  'request_state.PAID': 'Abgeschlossen',
  'request_state.USED': 'Geschlossen',
  'request_state.DUPLICATED': 'Dupliziert',
  'request_state.REFUNDED': 'Erstattet',
  'request_state.EXPIRED': 'Abgelehnt',
  'request_state.PAID_NOT_CONFIRMED': 'Warten auf Überprüfung',
  'request_state.DELAY_PAY_NOT_CONFIRMED': 'Später bezahlen - Nicht bestätigt',
  'request_state.DELAY_PAY': 'Bereit zur Zahlung bei Ankunft',
  'payment_status.DYN.zwevisa.0': 'Zahlung ausstehend',
  'payment_status.DYN.zwevisa.1': 'Bereit zum Einchecken',
  'payment_status.DYN.zwevisa.6': 'Warten auf Überprüfung',
  'payment_status.DYN.zwevisa.2.9': 'Warten auf Aufsicht',
  'payment_status.DYN.zwevisa.2.10': 'Beaufsichtigt',
  'payment_status.desc.DYN.zwevisa.2.10': 'Der Antrag wurde von einem Aufseher geprüft und ist bereit zur Bearbeitung durch den Grenzkontrollbeamten.',
  'payment_status.desc.DYN.zwevisa.2.9': 'Der Antrag wartet auf Aufsicht. Ein Aufseher muss den Antrag möglicherweise prüfen, bevor er vom Grenzkontrollbeamten bearbeitet werden kann.',
  'payment_status.DYN.zwevisa.7': 'Korrektur ausstehend',
  'payment_status.DYN.zwevisa.8': 'Später bezahlen - Nicht bestätigt',
  'payment_status.DYN.zwevisa.9': 'Bereit zur Zahlung bei Ankunft',
  'payment_status.DYN.zwevisa.9.entry': 'Bereit zum Einchecken',
  'payment_status.DYN.zwevisa.9.exit': 'Bereit zum Auschecken',
  'payment_status.DYN.zwevisa.2': 'Ausgecheckt',
  'payment_status.DYN.zwevisa.3': 'Dupliziert',
  'payment_status.DYN.zwevisa.4': 'Erstattet',
  'payment_status.DYN.zwevisa.5': 'Abgelehnt',
  'payment_status.extension.DYN.zwevisa.5': 'Verlängerung abgelehnt',
  'payment_status.DYN.zwevisa.5.blacklisted': 'Abgelehnt - Auf der schwarzen Liste',
  'payment_status.DYN.pass.0': 'Vorgeschlagen',
  'payment_status.DYN.pass.1': 'Bezahlt',
  'payment_status.DYN.pass.6': 'Unbestätigt',
  'payment_status.DYN.pass.2': 'Verwendet',
  'payment_status.DYN.pass.3': 'Dupliziert',
  'payment_status.DYN.pass.4': 'Erstattet',
  'payment_status.DYN.pass.5': 'Abgelaufen',
  'treatmentDone': 'Bearbeitung abgeschlossen. Überarbeitung angefordert',
  'sendRevision': 'Überarbeitung gesendet',
  'enrolReady': 'Antrag akzeptiert. Bereit zur Anmeldung',
  'err.personalNr.required': 'Persönliche Nummer erforderlich',
  'err.passportNr.required': 'Passnummer erforderlich',
  'createPeople.success.DYN.pass': 'Person erfolgreich erstellt',
  'createPeople.success.DYN.zwevisa': 'Person erfolgreich erstellt',
  'request.0': 'Antrag akzeptiert. Bereit zur Anmeldung',
  'request.1': 'Antrag akzeptiert. Bearbeitung läuft',
  'request.2': 'Antrag abgelehnt',
  'request.3': 'Antrag abgelehnt. Aktion erforderlich',
  'request.4': 'Bearbeitung läuft',
  'adminRequestResult.title.DYN.pass': 'Ausgewählter Antrag - {paymentID}',
  'adminRequestResult.title.DYN.zwevisa': 'Ausgewählter Antrag - {paymentID}',
  'form.requestState': 'Antragsstatus',
  'form.paymentType': 'Zahlungsart',
  'form.paymentState': 'Zahlungsstatus',
  'payment.type.PHYSICAL': 'Physisch',
  'payment.type.PAYPAL': 'PayPal',
  'payment.type.WU': 'Western Union',
  'payment.type.MNTA': 'CBZ',
  'payment.type.online': 'Online',
  'payment.type.offline': 'Offline',
  'request.state.DYN.pass': 'Antragsstatus',
  'request.state.DYN.zwevisa': 'Antragsstatus',
  'payment.continue': 'Zahlung fortsetzen',
  'form.birthDate': 'Geburtsdatum',
  'form.birthCountry': 'Geburtsland',
  'form.placeOfBirth': 'Geburtsort',
  'form.residenceAdress': 'Wohnadresse',
  'form.marialStatus': 'Familienstand',
  'form.dateOfIssue': 'Ausstellungsdatum des Passes',
  'form.dateOfExpiry': 'Ablaufdatum des Passes',
  'form.placeOfIssue': 'Ausstellungsort',
  'form.departureDate': 'Abreisedatum',
  'form.arrivalDate': 'Ankunftsdatum',
  'form.referencePreviousVisa': 'Referenz des vorherigen Visums',
  'form.passportNumber': 'Passnummer',
  'form.passportType': 'Passart',
  'form.hostName': 'Name des Gastgebers',
  'form.purposeOfVisit': 'Reisezweck',
  'form.previousConvictionsHome': 'Vorstrafen im Heimatland',
  'form.spouse': 'Ehepartner',
  'form.SpousePlaceOfBirth': 'Geburtsort des Ehepartners',
  'form.spouse.birthDay': 'Geburtsdatum des Ehepartners',
  'form.spousePassportNumber': 'Passnummer des Ehepartners',
  'form.spousePlaceOfBirth': 'Geburtsort des Ehepartners',
  'form.dependants': 'Abhängige',
  'form.dependantFullName': 'Vollständiger Name des Abhängigen',
  'form.dependantPassportNumber': 'Passnummer des Abhängigen',
  'form.dependantPlaceOfBirth': 'Geburtsort des Abhängigen',
  'form.dependantBirthDay': 'Geburtsdatum des Abhängigen',
  'global.approve': 'Genehmigen',
  'global.refuse': 'Ablehnen',
  'global.suspend': 'Überarbeitung anfordern',
  'global.unprocess': 'Unbearbeitet',
  'personal.data': 'Persönliche Daten',
  'contact.data': 'Kontaktdaten',
  'divers.data.DYN.zwevisa': 'Reisedetails',
  'divers.data.DYN.pass': 'Antragsdetails',
  'dependant.data': 'Abhängige Daten',
  'spouse.data': 'Daten des Ehepartners',
  'paymentState.0': 'Zahlung ausstehend',
  'paymentState.1': 'Ein-/Auschecken',
  'paymentState.2': 'Geschlossen',
  'paymentState.3': 'Dupliziert',
  'paymentState.4': 'Erstattet',
  'paymentState.5': 'Abgelehnt',
  'paymentState.6': 'Bezahlt - Nicht bestätigt',
  'payment.refuse.ask.DYN.pass': 'Möchten Sie diese Zahlung wirklich <b>ABLEHNEN</b>?',
  'payment.refuse.ask.DYN.zwevisa': 'Möchten Sie diesen Visumantrag wirklich <b>ABLEHNEN</b>?',
  'payment.refuse.ask.extension.DYN.zwevisa': 'Möchten Sie diesen Verlängerungsantrag wirklich <b>ABLEHNEN</b>?',
  'payment.approve.ask.DYN.pass': 'Möchten Sie diese Zahlung wirklich <b>GENEHMIGEN</b>?',
  'payment.approve.ask.DYN.zwevisa': 'Möchten Sie diesen Visumantrag wirklich <b>GENEHMIGEN</b>?',
  'payment.approve.ask.extension.DYN.zwevisa': 'Möchten Sie diesen Verlängerungsantrag wirklich <b>GENEHMIGEN</b>?',
  'payment.review.approve.ask.DYN.zwevisa': 'Möchten Sie diesen Visumantrag wirklich als <b>EMPFOHLEN</b> überprüfen?',
  'payment.review.approve.success.DYN.zwevisa': 'Dieser Visumantrag wurde als <b>EMPFOHLEN</b> überprüft.',
  'payment.review.approve.success.extension.DYN.zwevisa': 'Dieser Verlängerungsantrag wurde als <b>EMPFOHLEN</b> überprüft.',
  'payment.review.refuse.ask.DYN.zwevisa': 'Möchten Sie diesen Visumantrag wirklich als <b>NICHT EMPFOHLEN</b> überprüfen?',
  'payment.review.refuse.ask.extension.DYN.zwevisa': 'Möchten Sie diesen Verlängerungsantrag wirklich als <b>NICHT EMPFOHLEN</b> überprüfen?',
  'payment.review.refuse.success.DYN.zwevisa': 'Dieser Visumantrag wurde als <b>NICHT EMPFOHLEN</b> überprüft.',
  'payment.review.refuse.success.extension.DYN.zwevisa': 'Dieser Verlängerungsantrag wurde als <b>NICHT EMPFOHLEN</b> überprüft.',
  'payment.review.approve.extension.ask.DYN.zwevisa': 'Möchten Sie diesen Verlängerungsantrag wirklich <b>GENEHMIGEN</b>?',
  'payment.validate.ask.DYN.pass': 'Möchten Sie diese Zahlung wirklich <b>GENEHMIGEN</b>?',
  'payment.validate.ask.DYN.zwevisa': 'Möchten Sie diesen Visumantrag wirklich <b>GENEHMIGEN</b>?',
  'payment.validate.extension.ask.DYN.zwevisa': 'Möchten Sie diesen Verlängerungsantrag wirklich <b>GENEHMIGEN</b>?',
  'payment.validate.review.ask.DYN.pass': 'Möchten Sie diese Zahlung wirklich <b>GENEHMIGEN</b>? Eine Überarbeitungsanforderung wird an den nächsten Prüfer gesendet.',
  'payment.validate.review.ask.DYN.zwevisa': 'Möchten Sie diesen Visumantrag wirklich <b>EMPFEHLEN</b>? Eine Überarbeitungsanforderung wird an den nächsten Prüfer gesendet.',
  'payment.refuse.success.DYN.pass': 'Zahlung erfolgreich <b>ABGELEHNT</b>.',
  'payment.refuse.success.DYN.zwevisa': 'Visumantrag erfolgreich <b>ABGELEHNT</b>.',
  'payment.refuse.success.extension.DYN.zwevisa': 'Verlängerungsantrag erfolgreich <b>ABGELEHNT</b>.',
  'payment.approve.success.DYN.pass': 'Zahlung erfolgreich <b>GENEHMIGT</b>.',
  'payment.approve.success.DYN.zwevisa': 'Visumantrag erfolgreich <b>GENEHMIGT</b>. Der Kunde wird per E-Mail benachrichtigt.',
  'payment.approve.success.extension.DYN.zwevisa': 'Verlängerungsantrag erfolgreich <b>GENEHMIGT</b>.',
  'payment.suspend.success.DYN.pass': 'Zahlung erfolgreich <b>AUSGESETZT</b>. Eine Überarbeitungsanforderung wurde an den Kunden gesendet.',
  'payment.suspend.success.DYN.zwevisa': 'Visumantrag erfolgreich <b>AUSGESETZT</b>. Eine Überarbeitungsanforderung wurde an den Kunden gesendet.',
  'payment.validate.success.DYN.pass': 'Zahlung erfolgreich <b>GENEHMIGT</b>.',
  'payment.validate.success.DYN.zwevisa': 'Visumantrag erfolgreich <b>GENEHMIGT</b>.',
  'error.payment.refuse.error.DYN.pass': 'Beim Ablehnen der Zahlung ist ein Fehler aufgetreten.',
  'error.payment.refuse.error.DYN.zwevisa': 'Beim Ablehnen des Visumantrags ist ein Fehler aufgetreten.',
  'error.payment.suspend.error.DYN.pass': 'Beim Aussetzen der Zahlung ist ein Fehler aufgetreten.',
  'error.payment.suspend.error.DYN.zwevisa': 'Beim Aussetzen des Visumantrags ist ein Fehler aufgetreten.',
  'error.payment.validate.error.DYN.pass': 'Beim Genehmigen der Zahlung ist ein Fehler aufgetreten.',
  'error.payment.validate.error.DYN.zwevisa': 'Beim Genehmigen des Visumantrags ist ein Fehler aufgetreten.',
  'global.no': 'Nein',
  'global.Yes': 'Ja',
  'global.No': 'Nein',
  'zwevisa.type.holiday_visa': 'Urlaubsvisum',
  'photo.entryContry': 'Einreiseland Foto',
  'documents': 'Dokumente',
  'photo.passport': 'Passfoto',
  'data.biographic': 'Biografische Seite des Passes',
  'residence.proof': 'Wohnsitznachweis',
  'address.destination': 'Zieladresse',
  'previous.visa': 'Vorheriges Visum',
  'invite.letter': 'Einladungsschreiben',
  'residential.status': 'Wohnstatus',
  'biometric.data': 'Biometrische Daten',
  'hostInvitation.letter': 'Einladungsschreiben des Gastgebers',
  'proof.residenceHost': 'Wohnsitznachweis des Gastgebers',
  'hostResidential.status': 'Wohnstatus des Gastgebers',
  'reasonOfVisit.proof': 'Nachweis des Reisezwecks in das Zielland',
  'inviteBusiness.letter': 'Geschäftsbrief des entsendenden Unternehmens',
  'business.letter': 'Geschäftsbrief',
  'business.profile': 'Berufliches Profil des Reisenden',
  'application.letter': 'Visumantragsschreiben',
  'acceptanceFromScool.letter': 'Bestätigungsschreiben',
  'date.creation': 'Erstellungsdatum',
  'document.Passport': 'Pass',
  'completeName': 'Vollständiger Name',
  'price': 'Preis',
  'global.retry': 'Erneut versuchen',
  'err.loginWeak': 'Verbindungsproblem, bitte versuchen Sie es erneut',
  'URGENTPASS.title': 'Dringender Reisepass',
  'refusalReason': 'Ablehnungsgrund',
  'error.payment.noRefuseReason': 'Bitte geben Sie eine Nachricht ein.',
  'error.payment.noSuspendReason': 'Bitte geben Sie einen Aussetzungsgrund ein. Dieser Grund wird an den Kunden gesendet.',
  'refusal.desc.DYN.pass': 'Diese Zahlung wurde abgelehnt.',
  'refusal.desc.DYN.zwevisa': 'Dieser Visumantrag wurde abgelehnt.',
  'refusal.desc.extension.DYN.zwevisa': 'Dieser Verlängerungsantrag wurde abgelehnt.',
  'refusal.desc.DYN.zwevisa.blacklisted': 'Dieser Visumantrag wurde abgelehnt und auf die schwarze Liste gesetzt.',
  'approval.desc.DYN.pass': 'Diese Zahlung wurde genehmigt.',
  'approval.desc.DYN.zwevisa': 'Dieser Visumantrag wurde genehmigt.',
  'approval.desc.extension.DYN.zwevisa': 'Dieser Verlängerungsantrag wurde genehmigt.',
  'review.approval.desc.DYN.zwevisa': 'Dieser Visumantrag erfüllt die Anforderungen.',
  'review.approval.desc.extension.DYN.zwevisa': 'Dieser Verlängerungsantrag erfüllt die Anforderungen.',
  'review.refusal.desc.DYN.zwevisa': 'Dieser Visumantrag erfüllt die Anforderungen nicht.',
  'review.refusal.desc.extension.DYN.zwevisa': 'Dieser Verlängerungsantrag erfüllt die Anforderungen nicht.',
  'err.getUsers: client.extended.notActiveToken': 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
  'forgetPassword.success': 'Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich jetzt anmelden.',
  'VISA_DOUBLE.title': 'Doppeltes Einreisevisum',
  'VISA_KAZA.title': 'Visa Kaza',
  'history': 'Verlauf',
  'noPaymentFilter': 'Keine Anträge in Bearbeitung',
  'menu.request': 'Anfragen',
  'menu.tracking': 'Verfolgung',
  'menu.people.DYN.pass': 'Personen',
  'menu.people.DYN.zwevisa': 'Gespeicherte Personen',
  'DYN.AGO': 'Angola',
  'DYN.ABW': 'Aruba',
  'DYN.ATG': 'Antigua & Barbuda',
  'DYN.BHS': 'Bahamas',
  'DYN.BRB': 'Barbados',
  'DYN.BLZ': 'Belize',
  'DYN.BWA': 'Botswana',
  'DYN.CYM': 'Kaimaninseln',
  'DYN.CYP': 'Zypern',
  'DYN.FJI': 'Fidschi',
  'DYN.COD': 'Demokratische Republik Kongo',
  'DYN.GHA': 'Ghana',
  'DYN.GRD': 'Grenada',
  'DYN.HKG': 'Sonderverwaltungsregion Hongkong (China)',
  'DYN.KEN': 'Kenia',
  'DYN.KIR': 'Kiribati',
  'DYN.JAM': 'Jamaika',
  'DYN.LDW': 'Inseln über dem Winde',
  'DYN.LSO': 'Lesotho',
  'DYN.MDG': 'Madagaskar',
  'DYN.MWI': 'Malawi',
  'DYN.MYS': 'Malaysia',
  'DYN.MDV': 'Malediven',
  'DYN.MLT': 'Malta',
  'DYN.MUS': 'Mauritius',
  'DYN.MSR': 'Montserrat',
  'DYN.MOZ': 'Mosambik',
  'DYN.NAM': 'Namibia',
  'DYN.NRU': 'Nauru',
  'DYN.LCA': 'St. Lucia',
  'DYN.VCT': 'St. Vincent und die Grenadinen',
  'DYN.WSM': 'West-Samoa',
  'DYN.SYC': 'Seychellen',
  'DYN.SGP': 'Singapur',
  'DYN.SLB': 'Salomonen',
  'DYN.ZAF': 'Südafrika',
  'DYN.KNA': 'St. Kitts und Nevis',
  'DYN.SWZ': 'Swasiland',
  'DYN.TON': 'Tonga',
  'DYN.TTO': 'Trinidad und Tobago',
  'DYN.TCA': 'Turks- und Caicosinseln',
  'DYN.TUV': 'Tuvalu',
  'DYN.UGA': 'Uganda',
  'DYN.TZA': 'Vereinigte Republik Tansania',
  'DYN.VUT': 'Vanuatu',
  'DYN.VAT': 'Vatikan',
  'DYN.VEN': 'Venezuela (Bolivarische Republik)',
  'DYN.USA': 'Vereinigte Staaten von Amerika',
  'DYN.URY': 'Uruguay',
  'DYN.UZB': 'Usbekistan',
  'DYN.UKR': 'Ukraine',
  'DYN.ARE': 'Vereinigte Arabische Emirate',
  'DYN.GBR': 'Vereinigtes Königreich Großbritannien und Nordirland',
  'DYN.TUR': 'Türkei',
  'DYN.TKM': 'Turkmenistan',
  'DYN.SWE': 'Schweden',
  'DYN.CHE': 'Schweiz',
  'DYN.TJK': 'Tadschikistan',
  'DYN.KAZ': 'Kasachstan',
  'DYN.SUR': 'Suriname',
  'DYN.ESP': 'Spanien',
  'DYN.SVK': 'Slowakei',
  'DYN.SVN': 'Slowenien',
  'DYN.SEN': 'Senegal',
  'DYN.SRB': 'Serbien',
  'DYN.SMR': 'San Marino',
  'DYN.STP': 'São Tomé und Príncipe',
  'DYN.NLD': 'Niederlande',
  'DYN.NCL': 'Neukaledonien',
  'DYN.NZL': 'Neuseeland',
  'DYN.NIC': 'Nicaragua',
  'DYN.MEX': 'Mexiko',
  'DYN.FSM': 'Mikronesien, Föderierte Staaten von',
  'DYN.MDA': 'Republik Moldau',
  'DYN.MCO': 'Monaco',
  'DYN.MHL': 'Marshallinseln',
  'DYN.LVA': 'Lettland',
  'DYN.NFK': 'Norfolkinsel',
  'DYN.MNP': 'Nördliche Marianen',
  'DYN.NOR': 'Norwegen',
  'DYN.PLW': 'Palau',
  'DYN.PSE': 'Palästina',
  'DYN.PAN': 'Panama',
  'DYN.PNG': 'Papua-Neuguinea',
  'DYN.PRY': 'Paraguay',
  'DYN.PER': 'Peru',
  'DYN.POL': 'Polen',
  'DYN.PRT': 'Portugal',
  'DYN.PRI': 'Puerto Rico',
  'DYN.REU': 'Réunion',
  'DYN.ROU': 'Rumänien',
  'DYN.RUS': 'Russische Föderation',
  'DYN.RWA': 'Ruanda',
  'DYN.LIE': 'Liechtenstein',
  'DYN.LTU': 'Litauen',
  'DYN.LUX': 'Luxemburg',
  'DYN.MAC': 'Sonderverwaltungsregion Macau (China)',
  'DYN.JPN': 'Japan',
  'DYN.KWT': 'Kuwait',
  'DYN.KGZ': 'Kirgisistan',
  'DYN.KOR': 'Südkorea',
  'DYN.GUY': 'Guyana',
  'DYN.HTI': 'Haiti',
  'DYN.HND': 'Honduras',
  'DYN.IRL': 'Irland',
  'DYN.ISR': 'Israel',
  'DYN.ITA': 'Italien',
  'DYN.HUN': 'Ungarn',
  'DYN.ISL': 'Island',
  'DYN.IND': 'Indien',
  'DYN.IDN': 'Indonesien',
  'DYN.IRN': 'Iran',
  'DYN.GUM': 'Guam',
  'DYN.GTM': 'Guatemala',
  'DYN.GIB': 'Gibraltar',
  'DYN.GRC': 'Griechenland',
  'DYN.GEO': 'Georgien',
  'DYN.DEU': 'Deutschland',
  'DYN.DNK': 'Dänemark',
  'DYN.EST': 'Estland',
  'DYN.ETH': 'Äthiopien',
  'DYN.FIN': 'Finnland',
  'DYN.FRA': 'Frankreich',
  'DYN.GUF': 'Französisch-Guayana',
  'DYN.PYF': 'Französisch-Polynesien',
  'DYN.ATF': 'Französische Süd- und Antarktisgebiete',
  'DYN.CZE': 'Tschechische Republik',
  'DYN.COM': 'Komoren',
  'DYN.DOM': 'Dominikanische Republik',
  'DYN.ECU': 'Ecuador',
  'DYN.EGY': 'Ägypten',
  'DYN.SLV': 'El Salvador',
  'DYN.GNQ': 'Äquatorialguinea',
  'DYN.CHL': 'Chile',
  'DYN.CHN': 'China',
  'DYN.CHN_NT': 'China (nicht touristisch)',
  'DYN.COK': 'Cookinseln',
  'DYN.CRI': 'Costa Rica',
  'DYN.HRV': 'Kroatien',
  'DYN.CUB': 'Kuba',
  'DYN.BDI': 'Burundi',
  'DYN.CAN': 'Kanada',
  'DYN.CPV': 'Kap Verde',
  'DYN.BRA': 'Brasilien',
  'DYN.VGB': 'Britische Jungferninseln',
  'DYN.BRN': 'Brunei Darussalam',
  'DYN.BGR': 'Bulgarien',
  'DYN.BMU': 'Bermuda',
  'DYN.BTN': 'Bhutan',
  'DYN.BIH': 'Bosnien und Herzegowina',
  'DYN.BLR': 'Weißrussland',
  'DYN.BEL': 'Belgien',
  'DYN.BHR': 'Bahrain',
  'DYN.ARG': 'Argentinien',
  'DYN.ARM': 'Armenien',
  'DYN.AUS': 'Australien',
  'DYN.AUT': 'Österreich',
  'DYN.AZE': 'Aserbaidschan',
  'DYN.ALB': 'Albanien',
  'DYN.DZA': 'Algerien',
  'DYN.AIA': 'Anguilla',
  'DYN.BGD': 'Bangladesch',
  'DYN.BEN': 'Benin',
  'DYN.AFG': 'Afghanistan',
  'DYN.BOL': 'Bolivien',
  'DYN.BFA': 'Burkina Faso',
  'DYN.KHM': 'Kambodscha',
  'DYN.CMR': 'Kamerun',
  'DYN.CIV': 'Elfenbeinküste',
  'DYN.CAF': 'Zentralafrikanische Republik',
  'DYN.TCD': 'Tschad',
  'DYN.COL': 'Kolumbien',
  'DYN.ERI': 'Eritrea',
  'DYN.COG': 'Kongo-Brazzaville',
  'DYN.PRK': 'Demokratische Volksrepublik Korea',
  'DYN.GAB': 'Gabun',
  'DYN.GMB': 'Gambia',
  'DYN.DJI': 'Dschibuti',
  'DYN.GIN': 'Guinea-Conakry',
  'DYN.GNB': 'Guinea-Bissau',
  'DYN.IRQ': 'Irak',
  'DYN.KOS': 'Kosovo',
  'DYN.LAO': 'Laos',
  'DYN.JOR': 'Jordanien',
  'DYN.LBR': 'Liberia',
  'DYN.LBY': 'Libysch-Arabische Dschamahirija',
  'DYN.MLI': 'Mali',
  'DYN.QAT': 'Katar',
  'DYN.REF': 'Flüchtling',
  'DYN.PHL': 'Philippinen',
  'DYN.OMN': 'Oman',
  'DYN.PAK': 'Pakistan',
  'DYN.LBN': 'Libanon',
  'DYN.MRT': 'Mauretanien',
  'DYN.MNG': 'Mongolei',
  'DYN.MNE': 'Montenegro',
  'DYN.NER': 'Niger',
  'DYN.NGA': 'Nigeria',
  'DYN.MMR': 'Myanmar',
  'DYN.MAR': 'Marokko',
  'DYN.NPL': 'Nepal',
  'DYN.SAU': 'Saudi-Arabien',
  'DYN.SLE': 'Sierra Leone',
  'DYN.SOM': 'Somalia',
  'DYN.LKA': 'Sri Lanka',
  'DYN.SSD': 'Südsudan',
  'DYN.SDN': 'Sudan',
  'DYN.THA': 'Thailand',
  'DYN.MKD': 'Ehemalige jugoslawische Republik Mazedonien',
  'DYN.TGO': 'Togo',
  'DYN.SYR': 'Syrien',
  'DYN.TWN': 'Taiwan',
  'DYN.TUN': 'Tunesien',
  'DYN.VNM': 'Vietnam',
  'DYN.YEM': 'Jemen',
  'DYN.TLS': 'Timor-Leste',
  'DYN.AND': 'Andorra',
  'DYN.VIR': 'Amerikanische Jungferninseln',
  'DYN.XKX': 'Kosovo',
  'DYN.ZWE': 'Simbabwe',
  'DYN.ZMB': 'Sambia',
  'DYN.ASM': 'Amerikanisch-Samoa',
  'DYN.DMA': 'Dominica',
  'DYN.XXA': 'Staatenlos',
  'DYN.XXB': 'Flüchtling (Genfer Konvention)',
  'DYN.XXC': 'Flüchtling (Andere)',
  'DYN.UNA': 'Agentur der Vereinten Nationen',
  'DYN.UNO': 'Organisation der Vereinten Nationen',
  'err.Occupation.pattern': 'Die Beschäftigung muss ein korrektes Format haben',
  'err.PreviousConvictionsHome.pattern': 'Vorstrafen im Heimatland müssen ein korrektes Format haben',
  'err.PlaceOfBirth.pattern': 'Geburtsort muss im korrekten Format vorliegen',
  'PAYMENT_USED': 'Zahlung abgeschlossen',
  'document.type.DYN.pass': 'Dokumentart',
  'document.type.DYN.zwevisa': 'Antragsart',
  'filterPayment.error': 'Beim Filtern der Anfragen ist ein Fehler aufgetreten. Möchten Sie es erneut versuchen?',
  'filterPaymentNoFirst.error': 'Beim Abrufen der Anfragen ist ein Fehler aufgetreten. Möchten Sie es erneut versuchen?',
  'dependantIndex': 'Abhängiger {index}',
  'form.spouseFullName': 'Vollständiger Name des Ehepartners',
  'qrDesc.valid': 'Zeigen Sie diesen QR-Code bei der Visa-Kontrolle beim Ein- oder Ausreisen vor. Sie können nicht ohne einen gültigen QR-Code reisen.',
  'qrDesc.invalid': 'Dieser Visumantrag ist derzeit ungültig. Sie können nicht ohne einen gültigen Visumantrag reisen. Bitte kontaktieren Sie das Visa-Büro für weitere Informationen.',
  'err.pay': 'Während der Zahlung ist ein Fehler aufgetreten. Versuchen Sie es erneut.',
  'payment.noEvents': 'Dieser Antrag beinhaltet derzeit keine reisebezogene Bearbeitung (Verwendungen des Antrags).',
  'global.cancel': 'Abbrechen',
  'end.suspend': 'Überprüfung abschließen',
  'visa_application': 'Visa-Antragsanforderungen',
  'trips.title': 'Anträge verfolgen',
  'trips.desc': 'Antrag/Anträge',
  'BatchId': 'Antragsreferenz',
  'newBatchId': 'Hinzufügen',
  'err.BatchId.required': 'Antragsreferenz erforderlich',
  'newBatchId.desc': 'Bitte geben Sie einen Namen für diese Reise ein.',
  'ask.newBatchId': 'Bitte erstellen Sie eine neue Antragsreferenz.',
  'personLinkType.DYN.0': 'Unbekannt',
  'personLinkType.DYN.1': 'Kind',
  'personLinkType.DYN.2': 'Elternteil',
  'personLinkType.DYN.3': 'Ehepartner',
  'personLinkType.DYN.4': 'Sonstiges',
  'personLinkType.DYN.5': 'Selbst',
  'createPeople.error.DYN.pass': 'Beim Erstellen der Person ist ein Fehler aufgetreten.',
  'createPeople.error.DYN.zwevisa': 'Beim Erstellen der Person ist ein Fehler aufgetreten.',
  'batchIds.desc': 'Anträge, die mit der Referenz {trip} verknüpft sind:',
  'trip.name': 'Antragsreferenz',
  'visaIntro.validFrom': 'Startdatum',
  'visaIntro.validUntil': 'Ablaufdatum',
  'visaIntro.usageCount': 'Erlaubte Verwendung(en)',
  'client.extended.passportNumberAlreadyExists': 'Diese Reisepassnummer wird bereits verwendet. Bitte geben Sie eine andere ein.',
  'client.extended.alreadyDecided': 'Dieser Visumantrag wurde bereits von Ihnen überprüft. Sie können ihn nicht erneut überprüfen.',
  'client.extended.outOfUsage': 'Dieser Antrag befindet sich <b>außerhalb seines Gültigkeitszeitraums</b>',
  'global.review': 'Überprüfung',
  'ask.review.0': 'Wählen Sie Ihre Bewertung für diesen Visumantrag aus.',
  'ask.review.extension.0': 'Wählen Sie Ihre Bewertung für diesen Verlängerungsantrag aus.',
  'ask.review.1': 'Wählen Sie Ihre Empfehlung für diesen Visumantrag aus.',
  'ask.review.2': 'Wählen Sie Ihre endgültige Entscheidung für diesen Visumantrag aus.',
  'error.review.noChoice': 'Bitte wählen Sie einen Überprüfungsstatus, um fortzufahren.',
  'all.reviews': 'Alle Überprüfungen dieses Visumantrags',
  'all.reviews.extension': 'Alle Überprüfungen dieses Verlängerungsantrags',
  'validate.desc': 'Dieser Visumantrag wurde am {date} vom Benutzer als genehmigt bewertet',
  'validate.extension.desc': 'Dieser Verlängerungsantrag wurde am {date} vom Benutzer als genehmigt bewertet',
  'refusal.review.desc': 'Dieser Visumantrag wurde am {date} vom Benutzer als abgelehnt bewertet',
  'refusal.review.extension.desc': 'Dieser Verlängerungsantrag wurde am {date} vom Benutzer als abgelehnt bewertet',
  'refusal.review.desc.blacklisted': 'Dieser Visumantrag wurde am {date} vom Benutzer als abgelehnt und auf die schwarze Liste gesetzt bewertet',
  'global.revision': 'Überarbeitung',
  'revison.cancel': 'Überarbeitung abbrechen',
  'approve.text': 'Dieser Antrag wurde genehmigt.',
  'refuse.text': 'Dieser Visumantrag wurde abgelehnt.',
  'pending.review.desc': 'Eine Korrektur dieses Visumantrags wurde dem Antragsteller am {date} vom Benutzer angefordert',
  'global.reason': 'Grund',
  'pending.desc': 'Dieser Visumantrag wartet derzeit auf eine Korrektur des Kunden.',
  'success.onArrival': 'Ihre Anfrage wurde erfolgreich übermittelt. Sie können den QR-Code dieses Antrags oben sehen. Zeigen Sie diesen QR-Code bei der Visa-Kontrolle beim Ein- oder Ausreisen vor. Sie können nicht ohne einen gültigen Visumantrag reisen.',
  'successRequest.title': 'Antrag erfolgreich übermittelt',
  'successRequest.title.kiosk': 'Antrag erfolgreich übermittelt',
  'amountDue': 'Preis: ',
  'amountDue.total.now': 'Jetzt fälliger Gesamtbetrag: ',
  'amountDue.total.later': 'Gesamtbetrag am Schalter: ',
  'noPrint.mobile': 'Das Drucken von Seiten wird auf Mobilgeräten nicht unterstützt',
  'err.PassportNumber.required': 'Dokumentennummer erforderlich (Reisepass, Aufenthaltserlaubnis)',
  'err.FundsAvailable.pattern': 'Verfügbare Mittel zum Zeitpunkt der Einreise müssen im korrekten Format vorliegen',
  'onArrival.reset': 'Neuen Prozess starten',
  'onArrival.reset.ask': 'Möchten Sie wirklich einen neuen Prozess starten?',
  'stillHere': 'Sind Sie noch da? Dieser Prozess wird sich in 20 Sekunden zurücksetzen.',
  'stillHere.successPayment': 'Sind Sie noch da?',
  'print.request': 'Antrag drucken',
  'EXPIRED.globalExplain': 'Dieser Antrag wurde abgelehnt. Er entspricht nicht unseren Standards.',
  'payment_status.desc.DYN.7': 'Dieser Visumantrag wurde als ausstehend bewertet. Er wartet derzeit auf eine Korrektur des Kunden.',
  'payment_status.DYN.zwevisa.6.review': 'Bewertung ausstehend - {reviewCount}/2',
  'payment_status.desc.DYN.zwevisa.6.review': 'Dieser Visumantrag wartet derzeit auf eine Überprüfung. Er wurde bereits {reviewCount} Mal überprüft.',
  'payment_status.DYN.zwevisa.6.finalReview': 'Endgültige Überprüfung ausstehend',
  'payment_status.desc.DYN.zwevisa.6.finalReview': 'Dieser Visumantrag wurde überprüft und wartet derzeit auf die endgültige Überprüfung.',
  'payment_status.desc.DYN.zwevisa.0': 'Dieser Antrag wartet derzeit auf die Zahlungsgenehmigung durch die Zahlungsstelle. Falls die Zahlung noch nicht erfolgt ist, kann sie jetzt abgeschlossen werden.',
  'payment_status.desc.DYN.zwevisa.7': 'Dieser Visumantrag wartet auf eine Korrektur des Kunden.',
  'payment_status.desc.DYN.zwevisa.5': 'Dieser Visumantrag wurde abgelehnt.',
  'payment_status.desc.extension.DYN.zwevisa.5': 'Dieser Verlängerungsantrag wurde abgelehnt.',
  'payment_status.desc.DYN.zwevisa.5.blacklisted': 'Dieser Visumantrag wurde abgelehnt und die Person wurde auf die schwarze Liste gesetzt.',
  'payment_status.desc.DYN.zwevisa.5.checkIn': 'Dieser Antrag wurde bei der Kontrolle am Schalter abgelehnt.',
  'payment_status.desc.DYN.zwevisa.5.checkIn.reason': 'Dieser Antrag wurde bei der Kontrolle am Schalter abgelehnt. <p><b>Grund</b>: <br/> {reason}</p>',
  'payment_status.DYN.zwevisa.5.checkIn': 'Bei der Kontrolle abgelehnt',
  'payment_status.desc.DYN.zwevisa.5.checkOut': 'Dieser Antrag wurde bei der Ausreise am Schalter abgelehnt.',
  'payment_status.DYN.zwevisa.5.checkOut': 'Bei der Ausreise abgelehnt',
  'payment_status.desc.DYN.zwevisa.9': 'Dieser Visumantrag ist am Schalter zur Zahlung bereit. Der Check-in kann nach der Zahlung abgeschlossen werden.',
  'payment_status.desc.DYN.zwevisa.9.entry': 'Dieser Antrag ist bereit zum Check-in am Schalter.',
  'payment_status.desc.DYN.zwevisa.9.exit': 'Dieser Antrag ist bereit zur Ausreise und erfordert die Zahlung des Grenzübergangspasses am Schalter.',
  'payment_status.DYN.zwevisa.2.out': 'Bereit zur Ausreise',
  'payment_status.DYN.zwevisa.2.outFinish': 'Ausgereist',
  'payment_status.DYN.zwevisa.2.closed': 'Geschlossen',
  'payment_status.desc.DYN.zwevisa.2.closed': 'Dieser Antrag wurde geschlossen.',
  'payment_status.desc.DYN.zwevisa.2.zweexit': 'Dieser Antrag ist bereit zur Ausreise am Schalter.',
  'payment_status.DYN.zwevisa.2.entryFinish': 'Eingecheckt',
  'payment_status.DYN.zwevisa.2.in': 'Bereit zum Check-in',
  'payment_status.desc.DYN.zwevisa.2.out': 'Dieser Antrag wurde eingecheckt. Der nächste Schritt ist der Check-out am Schalter.',
  'payment_status.desc.DYN.zwevisa.2.outFinish': 'Dieser Antrag wurde ausgecheckt. Der Kunde kann jetzt reisen.',
  'payment_status.desc.DYN.zwevisa.2.entryFinish': 'Dieser Antrag wurde eingecheckt. Der Kunde kann jetzt ins Land einreisen.',
  'payment_status.desc.DYN.zwevisa.2.in': 'Dieser Antrag wartet auf den Check-in am Schalter.',
  'ask.isArrivalPaid': 'Die folgende Zahlung muss jetzt in einer der folgenden Währungen bezahlt werden:',
  'ask.isArrivalPaid.exit': 'Der Grenzübergangspass muss jetzt in einer der folgenden Währungen bezahlt werden:',
  'ask.isArrivalPaid.confirm': 'Bestätigen Sie, dass die Zahlung erfolgt ist? Wenn Sie dies bestätigen, geben Sie bitte die Zahlungsreferenz, die Zahlungsmethode und die verwendete Währung ein. Sie können auch das maximale Enddatum des Aufenthalts ändern (Sie können das Datum nur verkürzen).',
  'ask.isArrivalPaid.confirm.exit': 'Bestätigen Sie, dass die Zahlung erfolgt ist? Wenn Sie dies bestätigen, geben Sie bitte die Zahlungsreferenz und die verwendete Währung ein.',
  'ask.checkoutExpired.confirm': 'Bestätigen Sie, dass die Zahlung erfolgt ist? Wenn Sie dies bestätigen, geben Sie bitte die Zahlungsreferenz und die verwendete Währung ein.',
  'no_trips': 'Sie haben keine Reisen.',
  'err.canActivate': 'Ein Fehler ist aufgetreten',
  'form.passportNumber.dateOfIssue': 'Ausstellungsdatum des Reisepasses',
  'form.passportNumber.dateOfExpiry': 'Ablaufdatum des Reisepasses',
  'form.passportNumber.placeOfIssue': 'Ausstellungsort des Reisepasses',
  'nextOfKin.data': 'Notfallkontakt / Nächster Angehöriger',
  'form.otherLinkType': 'Geben Sie den Beziehungstyp an',
  'err.passportNr.pattern': 'Reisepassnummer muss im korrekten Format vorliegen',
  'err.otherLinkType.required': 'Sie müssen den Beziehungstyp angeben',
  'err.otherLinkType.pattern': 'Der Beziehungstyp muss im korrekten Format vorliegen',
  'camera.problem.manual': 'Sie sind dabei, diesen Visumantrag ohne ein Bild des Kunden ein- oder auszuchecken. Möchten Sie wirklich fortfahren?',
  'camera.problem.button': 'Problem mit der Kamera?',
  'global.noFilter': '--Kein Filter--',
  'zweentry': 'Einreise ohne Visum',
  'zweentry.title': 'Einreise ohne Visum',
  'zweentry.description': 'Reisende aus ausgewählten Ländern können ohne vorherige Beantragung eines Visums einreisen, in der Regel für Kurzaufenthalte.',
  'free.charge': 'Kostenlos',
  'camera.init.error': 'Beim Initialisieren der Kamera ist ein Fehler aufgetreten. Möchten Sie diesen Antrag ohne ein Bild des Kunden ein- oder auschecken?',
  'error.payment.noPaymentReference': 'Bitte geben Sie eine Zahlungsreferenz, eine Zahlungsmethode und die verwendete Währung ein.',
  'paymentReference.ask': 'Zahlungsreferenz',
  'paymentReference.askExpired': 'Zahlungsreferenz für die Überziehungsgebühr',
  'session.expired': 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
  'error.approveRefuse.DYN.zwevisa': 'Beim Genehmigen oder Ablehnen des Visumantrags ist ein Fehler aufgetreten.',
  'error.approveRefuse.DYN.pass': 'Beim Genehmigen oder Ablehnen der Zahlung ist ein Fehler aufgetreten.',
  'phone.pattern': ' (z.B.: +263771234567)',
  'global.receipt.id': 'ID des Antrags: {paymentId}',
  'global.preview': 'Vorschau',
  'ask.place.updatePermissions': 'Sind Sie sicher, dass Sie die Berechtigungen dieses Ortes aktualisieren möchten?',
  'place.updatePermissions.success': 'Die Berechtigungen des Ortes wurden erfolgreich aktualisiert.',
  'err.place.updatePermissions': 'Beim Aktualisieren der Berechtigungen des Ortes ist ein Fehler aufgetreten.',
  'err.seniority.required': 'Die Dienstzeit muss eine Zahl zwischen 1 und 3 sein.',
  'err.seniority.pattern': 'Die Dienstzeit muss eine Zahl zwischen 1 und 3 sein.',
  'global.urgent': 'Dringend',
  'form.usageAfter': 'Gültig ab',
  'form.usageUntil': 'Gültig bis',
  'form.localUsageUntil': 'Maximales Enddatum des Aufenthalts',
  'id.payment': 'Zahlungs-ID',
  'internalReference': 'HQ-Referenz',
  'payment_status.DYN.zwevisa.6.0': 'Bewertung ausstehend 0/2',
  'payment_status.DYN.zwevisa.6.1': 'Bewertung ausstehend 1/2',
  'payment_status.DYN.zwevisa.6.2': 'Endgültige Überprüfung ausstehend',
  'payment_status.DYN.zwevisa.2.0': 'Eingecheckt',
  'payment_status.DYN.zwevisa.2.4': 'Bereit zum Auschecken',
  'payment_status.DYN.zwevisa.2.5': 'Ausgecheckt',
  'payment_status.DYN.zwevisa.2.6': 'Bei der Einreise abgelehnt',
  'payment_status.DYN.zwevisa.2.7': 'Bei der Ausreise abgelehnt',
  'payment_status.DYN.zwevisa.2.8': 'Geschlossen',
  'internalReference.create': 'HQ-Referenz hinzufügen',
  'internalReference.edit': 'HQ-Referenz bearbeiten',
  'internalReference.ask.edit': 'Bitte geben Sie die neue HQ-Referenz ein.',
  'internalReference.ask.create': 'Bitte geben Sie die HQ-Referenz ein.',
  'internalReference.done.create': 'Die HQ-Referenz wurde erfolgreich hinzugefügt.',
  'internalReference.done.edit': 'Die HQ-Referenz wurde erfolgreich bearbeitet.',
  'error.internalReference': 'Beim Hinzufügen/Bearbeiten der HQ-Referenz ist ein Fehler aufgetreten.',
  'error.paymentUntil.expired': 'Dieser Antrag befindet sich außerhalb seines Gültigkeitszeitraums. Sie können jedoch mit dem Auschecken des Kunden fortfahren. Bitte beachten Sie, dass aufgrund des abgelaufenen Visumantrags eine Gebühr von 100 USD erforderlich ist. Die Zahlung muss sofort in einer der folgenden Währungen erfolgen:',
  'internalReference.desc': 'Fügen Sie diesem Antrag eine HQ-Referenz hinzu (z.B. 140_2024).',
  'visaApplication.id': 'ID des Visumantrags',
  'titlePrint': 'Visumantrag </br> <b>{paymentID}</b>',
  'titlePrint.extension': 'Verlängerungsantrag </br> <b>{paymentID}</b>',
  'visa.data': 'Visadaten',
  'validated': 'Gültig',
  'globalStatus.awaitingConfirmation': 'Bestätigung ausstehend',
  'globalStatus.awaitingVerification': 'Korrektur ausstehend',
  'globalStatus.refused': 'Abgelehnt',
  'globalStatus.valid': 'Gültig',
  'globalStatus.draft': 'Zahlung ausstehend',
  'PENDING.globalExplain': 'Dieser Antrag wartet auf eine Korrektur. Bitte überprüfen Sie die Informationen und korrigieren Sie sie.',
  'PAID_NOT_CONFIRMED.globalExplain': 'Dieser Antrag wartet auf die Bestätigung durch unsere Dienste.',
  'PROPOSED.globalExplain': 'Dieser Antrag wartet auf die Zahlung. Wenn Sie die Zahlung noch nicht validiert haben, können Sie dies jetzt tun.',
  'usageValidityConditions': 'Die Einwanderungsbehörden behalten sich das Recht vor, das Visum jederzeit zu widerrufen, falls der Visuminhaber die damit verbundenen Bedingungen nicht erfüllt.',
  'afterWithoutUntil': 'Sie dürfen ab {dateAfter} bis {deadlineDate} einreisen.',
  'afterWithoutUntilAndMillis': 'Sie dürfen ab {dateAfter} reisen.',
  'afterAndUntil': 'Sie dürfen ab {dateAfter} bis {dateUntil} reisen.',
  'photo.current': 'Aktuelles Bild',
  'photo.scanned': 'Aus dem Reisepass gescanntes Bild',
  'createdAt': 'Erstellungsdatum',
  'all.infos': 'Informationen zu diesem Antrag',
  'afterRefused': 'Erklärte Reisedaten: {dateAfter}.',
  'DYN.paymentError.desc.success': '<p>Ihre Bestellung wurde erfolgreich aufgenommen. Sollte ein Teil dieses Antrags die Genehmigung durch unser Team erfordern, werden Sie benachrichtigt, sobald dieser Vorgang abgeschlossen ist. Andernfalls wissen Sie bitte, dass wir Ihre Anfrage erhalten haben.</p> <p><b>Vielen Dank, dass Sie sich für unsere Dienste entschieden haben.</b></p>',
  'successPayment.kiosk': '<p>Ihr Antrag wurde erfolgreich aufgenommen. Sie können jetzt zum Schalter des Beamten gehen, um einzuchecken.</p><p><b>Vielen Dank, dass Sie sich für unsere Dienste entschieden haben.</b></p>',
  'sinceOneWeek': 'Bitte beachten Sie, dass dieser Visumantrag seit über einer Woche im Überprüfungsstatus wartet.',
  'client.extended.onlyAdminDecide': 'Dieser Visumantrag wurde bereits von einem Mitarbeiter mit dem gleichen Dienstalter wie Sie überprüft. Sie können ihn nicht erneut überprüfen.',
  'error.paymentUntil.before': 'Das Datum ist zu früh. Dieser Antrag wird ab {dateAfter} gültig sein.',
  'applicant.gender.FEMALE': 'Weiblich',
  'applicant.gender.MALE': 'Männlich',
  'entry.consume': 'Ankünfte',
  'exit.consume': 'Abreisen',
  'scan.noConsumeMode': 'Als Grenzbeamter sind Sie für die Verwaltung von sowohl Einreisen als auch Ausreisen verantwortlich. Bitte wählen Sie die Option, die Ihrer aktuellen Aufgabe entspricht: Ankünfte bearbeiten oder Abreisen verwalten.',
  'scan.kiosk': 'Für diese Person wurde kein Datensatz im Status "Bereit zum Einchecken" für eine Einreise gefunden. Bitte bitten Sie die Person, einen neuen Antrag am Kiosk zu stellen.',
  'scan.office': 'Für diese Person wurde kein Datensatz im Status "Bereit zum Einchecken" für eine Einreise gefunden. Die Nationalität dieser Person gehört zur Kategorie C, was bedeutet, dass diese Person ein Visum benötigt. Bitte begleiten Sie diese Person ins Büro, um entweder ein dringendes Visum zu erhalten oder die Einreise zu verweigern.',
  'scan.noVisaFound': 'Für diese Person wurde kein Datensatz im Status "Bereit zum Auschecken" für eine Ausreise gefunden. Möchten Sie einen neuen Antrag für die Ausreise erstellen?',
  'zweexit': 'Ausreise ohne Visum',
  'err.scanPassport': 'Beim Scannen des Reisepasses ist ein Fehler aufgetreten. Bitte stellen Sie sicher, dass er richtig eingeführt ist, und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, starten Sie die Anwendung neu oder kontaktieren Sie den Support.',
  'exit.register': 'Ausreise registrieren',
  'entry.register': 'Einreise registrieren',
  'exit.create': 'Ausreise ohne Visum erstellen',
  'exit.ask': 'Möchten Sie wirklich eine Ausreise ohne Visum für diese Person registrieren?',
  'exit.success': 'Die Ausreise ohne Visum wurde erfolgreich registriert. Sie können jetzt die Person auschecken.',
  'border': 'Grenzbeamter',
  'global.signout': 'Abmelden',
  'search.manually': 'Antrag suchen',
  'scan.applicationQr': 'Antrag QR scannen',
  'err.client.extended.maxUsageCountReached': 'Die maximale Anzahl der Verwendungen für diesen Antrag wurde erreicht.',
  'form.firstName.scanned': 'Gescannten Vorname(n)',
  'form.lastName.scanned': 'Gescannten Nachname',
  'form.documentNumber.scanned': 'Gescannten Reisepassnummer',
  'form.gender.scanned': 'Gescanntes Geschlecht',
  'form.birthDate.scanned': 'Gescanntes Geburtsdatum',
  'form.nationality.scanned': 'Gescannten Nationalität',
  'scanned.passport': 'Gescannten Reisepass',
  'application.data': 'Antragsdaten',
  'no.passportPhoto': 'Kein Foto',
  'scanned.list.checkIn': 'Hier sind die mit Ihrer Reisepassnummer verknüpften Anträge',
  'overstay': 'Überziehung',
  'method.payment': 'Zahlungsmethode',
  'review.desc': 'Genehmigen/Ablehnen',
  'approval.usageUntil.DYN.zwevisa': 'Ändern Sie das Ablaufdatum (optional)',
  'scanned.list': 'Liste der gefundenen Anträge',
  'err.EmergencyPhone.pattern': 'Notrufnummer muss im korrekten Format vorliegen',
  'visa.blacklist': 'Dieser Visumantrag wurde auf die schwarze Liste gesetzt. Die Person darf nicht in das Land einreisen.',
  'scan.noEntryFound': 'Für diese Person wurde kein Datensatz im Status "Bereit zum Einchecken" für eine Einreise gefunden. Möchten Sie einen neuen Antrag zum Einchecken erstellen?',
  'err.EmergencyEmail.pattern': 'Notfallkontakt-E-Mail muss im korrekten Format vorliegen',
  'global.syncing': 'Synchronisierung...',
  'payment.consume.error.DYN.zwevisa': 'Beim Bearbeiten des Antrags ist ein Fehler aufgetreten.',
  'tooltip.Qr': 'Sie können den mit einem Antrag verknüpften QR-Code direkt scannen, um detaillierte Informationen zu diesem Antrag zu finden.',
  'tooltip.arrivals': 'Aktivieren Sie dieses Kontrollkästchen, um Ankünfte als Grenzbeamter zu verwalten.',
  'tooltip.departures': 'Aktivieren Sie dieses Kontrollkästchen, um Abreisen als Grenzbeamter zu verwalten.',
  'tooltip.searchManually': 'Suchen Sie nach einem bestimmten Antrag anhand verschiedener Kriterien.',
  'tooltip.signout': 'Aus der Anwendung abmelden.',
  'tooltip.my_account': 'Zugriff auf Ihre Kontoeinstellungen.',
  'tooltip.dashboard': 'Zugriff auf das Dashboard.',
  'tooltip.createRequests': 'Einen neuen Antrag erstellen.',
  'tooltip.trips': 'Den Status Ihrer Anträge einsehen und das verknüpfte Dokument bei Genehmigung drucken.',
  'tooltip.tracking': 'Ihre Anträge verfolgen.',
  'tooltip.people': 'Die mit Ihrem Konto verknüpften Personen verwalten.',
  'tooltip.users': 'Die Benutzer der Anwendung verwalten.',
  'tooltip.requests': 'Die Antragsanfragen verwalten.',
  'tooltip.places': 'Die Orte der Anwendung verwalten.',
  'tooltip.role': 'Ihre Rolle und Ihr Name in der Anwendung.',
  'tooltip.exitRegister': 'Manuell eine Ausreise ohne Visum für eine Person registrieren.',
  'tooltip.entryRegister': 'Manuell eine Einreise ohne Visum für eine Person registrieren.',
  'tooltip.support': 'Den Support für Hilfe bei Problemen kontaktieren.',
  'tooltip.chooseLang': 'Die Sprache der Anwendung ändern. Derzeit ist nur Englisch verfügbar.',
  'tooltip.changePassword': 'Ihr Passwort ändern.',
  'tooltip.search': 'Die Anträge basierend auf ihrem Namen, der Reisepassnummer, der Zahlungs-ID oder der Referenz filtern.',
  'tooltip.internalReference': 'Die Anträge basierend auf ihrer internen Referenz filtern.',
  'tooltip.internalReferenceNoFilter': 'Klicken Sie auf eine interne Referenz, falls verfügbar, um zur verknüpften Referenz weitergeleitet zu werden.',
  'tooltip.state': 'Die Anträge basierend auf ihrem Status filtern.',
  'tooltip.createdDate': 'Die Anträge basierend auf ihrem Erstellungsdatum mit den Daten "von" und "bis" filtern.',
  'tooltip.createdDateSince': 'Die Anträge basierend auf ihrem Erstellungsdatum filtern.',
  'tooltip.overstay': 'Anträge basierend darauf filtern, ob sie ihre Aufenthaltsdauer im Land überschritten haben.',
  'tooltip.clearAllFilters': 'Alle auf die Anträge angewendeten Filter löschen.',
  'tooltip.filterLength': 'Die Anzahl der Anträge, die den angewendeten Filtern entsprechen.',
  'tooltip.downloadCsv': 'Die Anträge im CSV-Format herunterladen.',
  'tooltip.tablePassportTitle': 'Die Anträge basierend auf der Reisepassnummer sortieren.',
  'tooltip.tablePassport': 'Die mit dem Antrag verknüpfte Reisepassnummer',
  'tooltip.tableInternalReferenceTitle': 'Die Anträge basierend auf der internen Referenz sortieren.',
  'tooltip.tableInternalReference': 'Die mit dem Antrag verknüpfte interne Referenz',
  'tooltip.tableBatchIdTitle': 'Die Anträge basierend auf der Antragsreferenz sortieren.',
  'tooltip.tableBatchId': 'Die mit dem Antrag verknüpfte Antragsreferenz',
  'tooltip.tableCreationDateTitle': 'Die Anträge basierend auf dem Erstellungsdatum sortieren.',
  'tooltip.tableCreationDate': 'Das Erstellungsdatum des Antrags',
  'tooltip.tableStateTitle': 'Die Anträge basierend auf dem Status sortieren.',
  'tooltip.tableState': 'Der Status des Antrags',
  'tooltip.tableCompleteNameTitle': 'Die Anträge basierend auf dem vollständigen Namen sortieren.',
  'tooltip.tableCompleteName': 'Der mit dem Antrag verknüpfte vollständige Name',
  'tooltip.tablePriceTitle': 'Die Anträge basierend auf dem Preis sortieren.',
  'tooltip.tablePrice': 'Der Preis des Antrags',
  'tooltip.tableUsageAfterTitle': 'Anträge nach ihrem Startdatum sortieren.',
  'tooltip.tableUsageAfter': 'Das Startdatum des Antrags',
  'tooltip.tableUsageUntilTitle': 'Anträge nach ihrem Ablaufdatum sortieren.',
  'tooltip.tableUsageUntil': 'Das Ablaufdatum des Antrags',
  'tooltip.tableIdTitle': 'Die Anträge basierend auf der ID sortieren.',
  'tooltip.tableId': 'Die ID des Antrags',
  'tooltip.tablePerPage': 'Die Anzahl der pro Seite angezeigten Anträge ändern.',
  'tooltip.tableNumberOfPage': 'Die Seitennummerierung und die Anzahl der pro Seite angezeigten Anträge steuern, um die Navigation zu erleichtern.',
  'tooltip.history': 'Die Zahlungshistorie Ihrer Anträge.',
  'tooltip.addPerson': 'Eine neue Person hinzufügen, die mit Ihrem Konto verknüpft ist.',
  'tooltip.listQr': 'Alle gefilterten Anträge und ihren QR-Code anzeigen.',
  'tooltip.urgent': 'Dies bedeutet, dass der Antrag als dringend markiert ist und so bald wie möglich bearbeitet werden sollte.',
  'tooltip.operationId': 'Die Art des Antrags.',
  'tooltip.purposeOfVisit': 'Der Zweck des Besuchs für diesen Antrag.',
  'tooltip.amount': 'Der Preis dieses Antrags.',
  'tooltip.paymentType': 'Die Art der Zahlung für diesen Visumantrag.',
  'tooltip.paymentReference': 'Die Zahlungsreferenz für diesen Visumantrag.',
  'tooltip.review': 'Diesen Visumantrag als genehmigt oder abgelehnt überprüfen und einen Kommentar hinzufügen.',
  'tooltip.revision': 'Den Kunden auffordern, den Visumantrag zu korrigieren.',
  'tooltip.approve': 'Diesen Visumantrag als endgültige Entscheidung genehmigen. Sie können auch einen Kommentar hinzufügen. Wenn der Begriff "schwarze Liste" vorhanden ist, wird die Person auf die schwarze Liste gesetzt.',
  'tooltip.refuse': 'Diesen Visumantrag als endgültige Entscheidung ablehnen. Sie können auch einen Kommentar hinzufügen.',
  'tooltip.qrSee': 'Den QR-Code dieses Antrags anzeigen und das damit verknüpfte Dokument drucken.',
  'tooltip.internalEdit': 'Die interne Referenz dieses Visumantrags bearbeiten.',
  'tooltip.internalAdd': 'Eine interne Referenz zu diesem Antrag hinzufügen.',
  'tooltip.printApplication': 'Den Antrag mit allen Informationen drucken.',
  'tooltip.allReviews': 'Alle Bewertungen dieses Visumantrags mit Datum und Benutzer, der die Bewertung durchgeführt hat.',
  'tooltip.version': 'Die aktuelle Version der Anwendung.',
  'tooltip.consumeProfile.IN': 'Das am Einreiseort aufgenommene Bild.',
  'tooltip.consumeNoProfile.IN': 'Kein am Einreiseort aufgenommenes Bild.',
  'tooltip.consumeTime.IN': 'Datum und Uhrzeit des Einreiseortes.',
  'tooltip.consumePlace.IN': 'Der Einreiseort.',
  'tooltip.consumeProfile.OUT': 'Das am Ausreiseort aufgenommene Bild.',
  'tooltip.consumeNoProfile.OUT': 'Kein am Ausreiseort aufgenommenes Bild.',
  'tooltip.consumeTime.OUT': 'Datum und Uhrzeit des Ausreiseortes.',
  'tooltip.consumePlace.OUT': 'Der Ausreiseort.',
  'tooltip.passportComparison': 'Aus dem Reisepass gescannte Informationen. Sie ermöglichen den Vergleich der Informationen mit den tatsächlichen Informationen des Antrags.',
  'tooltip.classicComparison': 'Aktuelle Informationen des Antrags. Sie ermöglichen den Vergleich der Informationen mit den gescannten Informationen aus dem Reisepass.',
  'tooltip.trackingValid': 'Der Status Ihres Antrags. Hier ist der Antrag gültig. Das bedeutet, dass Sie mit diesem Antrag basierend auf den Gültigkeitsdaten reisen können.',
  'tooltip.trackingPending': 'Der Status Ihres Antrags. Hier wartet der Antrag auf Korrekturen. Das bedeutet, dass Sie einige Informationen korrigieren müssen, bevor der Antrag genehmigt werden kann.',
  'tooltip.trackingRefused': 'Der Status Ihres Antrags. Hier ist der Antrag abgelehnt. Das bedeutet, dass Sie mit diesem Antrag nicht reisen können.',
  'tooltip.trackingAwaitingConfirmation': 'Der Status Ihres Antrags. Hier wartet der Antrag auf die Bestätigung durch unsere Dienste.',
  'tooltip.trackingApplication': 'Antrag, der mit dieser Antragsreferenz verknüpft ist',
  'tooltip.trackingApplicationReference': 'Antragsreferenz dieser Anträge',
  'tooltip.allTrackingInfo': 'Alle Informationen zu diesem Antrag',
  'tooltip.trackingPrint': 'Das mit diesem Antrag verknüpfte Dokument drucken',
  'tooltip.docFirstName': 'Geben Sie den Vornamen der Person ein, wie er im Reisepass erscheint.',
  'tooltip.docLastName': 'Geben Sie den Nachnamen der Person ein, wie er im Reisepass erscheint.',
  'tooltip.docPassportNumber': 'Geben Sie die Reisepassnummer der Person ein.',
  'tooltip.docLinkType': 'Wählen Sie den Beziehungstyp, den Sie zu der Person haben.',
  'tooltip.docLinkTypeOther': 'Geben Sie den Beziehungstyp an, den Sie zu der Person haben.',
  'tooltip.applicationReferenceRequest': 'Die Antragsreferenz der Anfrage. Sie ist für jede Anfrage einzigartig und wird automatisch generiert.',
  'tooltip.portEntryRequest': 'Der Einreisehafen der Anfrage. Es ist der Ort, an dem die Person in das Land einreisen wird.',
  'tooltip.otherName': 'Geben Sie den anderen Namen des Antragstellers ein, wie er im Reisepass erscheint.',
  'tooltip.spouseCheckbox': 'Aktivieren Sie dieses Kontrollkästchen, wenn der Antragsteller einen Ehepartner hat, und füllen Sie die unten stehenden Informationen aus.',
  'tooltip.hostName': 'Der Name der Person/Organisation, die den Antragsteller beherbergen wird.',
  'tooltip.scanPassport': 'Scannen Sie den Reisepass des Antragstellers, um einen Antrag zu finden, der mit diesem Reisepass verknüpft ist. Wenn kein Antrag gefunden wird, können Sie einen neuen erstellen.',
  'tooltip.passportScanned': 'Die aus dem Reisepass gescannten Informationen.',
  'tooltip.scannedCard': 'Ähnliche Anträge, die mit den gescannten Informationen gefunden wurden.',
  'scan.error': 'Beim Scannen des Dokuments ist ein Fehler aufgetreten.',
  'tooltip.webcamProblems': 'Wenn die Kamera nicht richtig funktioniert, können Sie den Antrag dennoch ohne Bild ein- oder auschecken.',
  'tooltip.checkOut': 'Die Person aus dem Land auschecken.',
  'tooltip.checkIn': 'Die Person im Land einchecken.',
  'tooltip.consumeRefuse': 'Die Ein- oder Ausreise der Person verweigern.',
  'tooltip.finalRefuse': 'Die endgültige Entscheidung über den Antrag. Die Person darf nicht in das Land einreisen.',
  'tooltip.finalApprove': 'Die endgültige Entscheidung über den Antrag. Die Person darf in das Land ein- oder ausreisen.',
  'tooltip.finalPending': 'Die endgültige Entscheidung über den Antrag. Die Person darf nicht in das Land einreisen, bis der Antrag korrigiert und genehmigt wurde.',
  'tooltip.finalRefuseBlacklist': 'Die endgültige Entscheidung über den Antrag. Die Person darf nicht in das Land einreisen und wird auf die schwarze Liste gesetzt.',
  'ask.updateScannedPassport': 'Möchten Sie die Informationen des Antrags mit den gescannten Informationen aktualisieren?',
  'done.updateScannedPassport': 'Die Informationen des Antrags wurden erfolgreich aktualisiert.',
  'error.updateScannedPassport': 'Beim Aktualisieren der Informationen des Antrags ist ein Fehler aufgetreten.',
  'photo.entryCountry.IN': 'Bild beim Einchecken',
  'photo.entryCountry.OUT': 'Bild beim Auschecken',
  'err.noPassiveAuthenticationCSCA': 'Das Dokument ist gültig, konnte jedoch nicht von der ausstellenden Behörde überprüft werden.',
  'err.OtherName.pattern': 'Der andere Name darf nur aus nicht akzentuierten Buchstaben (entweder Klein- oder Großbuchstaben), Leerzeichen oder Bindestrichen bestehen, genau wie er im Reisepass erscheint.',
  'err.FirstName.pattern': 'Der Vorname darf nur aus nicht akzentuierten Buchstaben (entweder Klein- oder Großbuchstaben), Leerzeichen oder Bindestrichen bestehen, genau wie er im Reisepass erscheint.',
  'err.LastName.pattern': 'Der Nachname darf nur aus nicht akzentuierten Buchstaben (entweder Klein- oder Großbuchstaben), Leerzeichen oder Bindestrichen bestehen, genau wie er im Reisepass erscheint.',
  'err.PassportNumber.pattern': 'Die Reisepassnummer darf nur aus nicht akzentuierten Buchstaben (entweder Klein- oder Großbuchstaben), Zahlen, Leerzeichen oder Bindestrichen bestehen, genau wie sie im Reisepass erscheint.',
  'err.passport.expired': 'Die Gültigkeit des Reisepasses ist abgelaufen.',
  'externalIndexedData': 'Mehrere Reisepässe gefunden',
  'client.extended.chargedBack': 'Dieser Visumantrag wurde zurückgebucht. Die Person kann dennoch den Check-out durchführen, muss jedoch die Einreisegebühr in bar erneut bezahlen.',
  'ask.chargedBack.confirm': 'Bestätigen Sie, dass die Person die Einreisegebühr erneut bezahlt hat? Wenn Sie dies bestätigen, geben Sie bitte die Zahlungsreferenz und die verwendete Währung ein.',
  'error.payment.noReferenceChargedBack': 'Bitte geben Sie eine Zahlungsreferenz und die verwendete Währung ein.',
  'client.extended.entryChargedBack': 'Dieser Visumantrag wurde zurückgebucht. Die Person kann den Check-in nicht durchführen und muss einen neuen Einreiseantrag erstellen.',
  'tooltip.syncing': 'Die Anzahl der derzeit synchronisierten Antragsprozesse.',
  'adChoice': 'Verbindung mit Active Directory',
  'form.username': 'Benutzername',
  'err.username.required': 'Benutzername ist erforderlich',
  'err.username.minlength': 'Der Benutzername muss mindestens 4 Zeichen lang sein',
  'external.down': 'Ein Dienst, von dem unsere Anwendung abhängt, funktioniert derzeit nicht. Bitte versuchen Sie es später erneut.',
  'global.connection.server': 'Dienst nicht verfügbar. Entschuldigung für die Unannehmlichkeiten, bitte versuchen Sie es später erneut.',
  'global.connection.timeout': 'Zeitüberschreitung beim Hochladen Ihrer Anfrage. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.',
  'global.connection.problem': 'Fehler beim Hochladen Ihrer Anfrage. Bitte stellen Sie sicher, dass Sie keine VPNs oder Firewalls verwenden, dass Ihre Internetverbindung stabil ist, und versuchen Sie es erneut.',
  'err.passport.issuedSoon': 'Der Reisepass wurde kürzlich ausgestellt (vor weniger als einem Monat).',
  'global.reload': 'Neu laden',
  'error.passportScan': 'CertBytes(b64): <pre>{certBytes}</pre>',
  'glpi.err.noPassiveAuthenticationCSCA': 'Der Zertifikats-Hash des Reisepasses konnte nicht in der ICAO-Masterliste gefunden werden, was zu einem \'err.noPassiveAuthenticationCSCA\'-Fehler führt.',
  'glpi.err.unverifiedDoc': 'Fehler beim Abrufen der Zertifikatsbytes für die passive Authentifizierung; dies führt zu einem \'err.unverifiedDoc\'-Fehler.',
  'glpi.err.noPassiveAuthentication': 'Fehler bei der Durchführung der passiven Authentifizierung; dies führt zu einem \'err.noPassiveAuthentication\'-Fehler.',
  'glpi.err.noConnect': 'Es konnte keine Verbindung mit dem Reisepasschip hergestellt werden, was zu einem \'err.noConnect\'-Fehler führt.',
  'glpi.err.noBAC': 'BAC konnte mit den bereitgestellten MRZ-Informationen nicht hergestellt werden, was zu einem \'err.noBAC\'-Fehler führt.',
  'glpi.err.alteredDoc': 'Die Data Group-Hashes (DGS) sind für einen oder mehrere Einträge falsch, was zu einem \'err.alteredDoc\'-Fehler führt.',
  'glpi.err.revokedDocSigner': 'Die Seriennummer des Dokumentenunterzeichnerzertifikats wurde in der Zertifikatsperrliste (CRL) gefunden, was zu einem \'err.revokedDocSigner\'-Fehler führt.',
  'watchlistCollapse': '<p>Watchlists gefunden - <span class="watchlistCategories">{categories}</span></p>',
  'watchlistResponse.level': 'Alarmstufe für diese Übereinstimmung',
  'match.type': 'Übereinstimmungsart',
  'match.hair': 'Haarfarbe',
  'match.eyes': 'Augenfarbe',
  'match.height': 'Größe',
  'match.weight': 'Gewicht',
  'match.race': 'Rasse',
  'global.category': 'Kategorie',
  'watchlist.alertLevel': 'Alarmstufe der Watchlist',
  'watchlist.match': 'Übereinstimmung {numberMatch} | <span class="watchlistCategories">{matchCategory}</span>',
  'Nationalities': 'Nationalität {index}',
  'global.score': 'Punktzahl',
  'watchlist.name': 'Name der Watchlist',
  'alias': 'Alias {index}',
  'document.number': 'Dokumentnummer {index}',
  'document.issuer': 'Aussteller des Dokuments {index}',
  'document.type': 'Dokumentart {index}',
  'dateOfBirth': 'Geburtsdatum {index}',
  'watchlist.marks': 'Merkmale',
  'watchlist.image.link': 'Link zum gefundenen Bild {index}',
  'image.match': 'Übereinstimmendes Bild {index}',
  'tooltip.watchlist': 'Die gefundenen Watchlist-Informationen zu dieser Person.',
  'watchList.category': 'Kategorie der Watchlist',
  'err.LastName.maxlength': 'Nachname darf nicht länger als 50 Zeichen sein',
  'err.FirstName.maxlength': 'Vorname darf nicht länger als 50 Zeichen sein',
  'err.OtherName.maxlength': 'Anderer Name darf nicht länger als 50 Zeichen sein',
  'err.PlaceOfBirthCity.maxlength': 'Geburtsstadt darf nicht länger als 50 Zeichen sein',
  'err.Phone.maxlength': 'Telefonnummer darf nicht länger als 20 Zeichen sein',
  'err.Occupation.maxlength': 'Beruf darf nicht länger als 50 Zeichen sein',
  'err.HostName.maxlength': 'Gastgebername darf nicht länger als 50 Zeichen sein',
  'err.EmergencyLastName.maxlength': 'Nachname des Notfallkontakts darf nicht länger als 50 Zeichen sein',
  'err.EmergencyFirstName.maxlength': 'Vorname des Notfallkontakts darf nicht länger als 50 Zeichen sein',
  'err.EmergencyPhone.maxlength': 'Telefonnummer des Notfallkontakts darf nicht länger als 20 Zeichen sein',
  'err.PlaceofIssue.maxlength': 'Ausstellungsort darf nicht länger als 50 Zeichen sein',
  'err.SpouseFullName.maxlength': 'Name des Ehepartners darf nicht länger als 50 Zeichen sein',
  'err.SpousePassportNumber.maxlength': 'Reisepassnummer des Ehepartners darf nicht länger als 20 Zeichen sein',
  'err.SpousePlaceOfBirth.maxlength': 'Geburtsort des Ehepartners darf nicht länger als 50 Zeichen sein',
  'err.PassportNumber.maxlength': 'Reisepassnummer darf nicht länger als 20 Zeichen sein',
  'err.PreviousConvictionsHome.maxlength': 'Vorherige Verurteilung(en) im Heimatland dürfen nicht länger als 50 Zeichen sein',
  'err.FundsAvailable.maxlength': 'Verfügbare Mittel zum Zeitpunkt der Einreise dürfen nicht länger als 50 Zeichen sein',
  'err.firstName.maxlength': 'Vorname darf nicht länger als 50 Zeichen sein',
  'err.lastName.maxlength': 'Nachname darf nicht länger als 50 Zeichen sein',
  'err.passportNr.maxlength': 'Reisepassnummer darf nicht länger als 20 Zeichen sein',
  'err.otherLinkType.maxlength': 'Der Beziehungstyp darf nicht länger als 50 Zeichen sein',
  'err.id.maxlength': 'Externe ID darf nicht länger als 50 Zeichen sein',
  'err.name.maxlength': 'Name darf nicht länger als 50 Zeichen sein',
  'err.password2.maxlength': 'Das Passwort muss zwischen 6 und 20 Zeichen lang sein',
  'err.email.maxlength': 'E-Mail darf nicht länger als 50 Zeichen sein',
  'err.userId.maxlength': 'Benutzer-ID darf nicht länger als 50 Zeichen sein',
  'err.phoneNumbersCsv.required': 'Telefonnummer ist erforderlich',
  'err.idParent.required': 'Eltern-ID ist erforderlich',
  'err.phoneNumbersCsv.maxlength': 'Telefonnummer darf nicht länger als 20 Zeichen sein',
  'watchlist.level': 'Alarmstufe',
  'ask.fingerprint': 'Möchten Sie die Fingerabdrücke der Person nehmen?',
  'scan.fingerprint.desc': 'Wenn Sie bereit sind, klicken Sie auf die Schaltfläche unten, um den Prozess der Fingerabdrucknahme der Person zu starten. Die Person muss ihren Zeigefinger auf den Scanner legen, wenn das rote Licht des Fingerabdruckgeräts aufleuchtet, wie es der Beamte anzeigt.',
  'scan.fingerprint.noResponse': 'Keine Antwort vom Fingerabdruckscanner. Hat die Person ihren Finger auf den Scanner gelegt? Ist das Gerät ordnungsgemäß angeschlossen?',
  'scan.fingerprint': 'Fingerabdruck',
  'fingerprint.scan.start': 'Fingerabdruck scannen',
  'scan.fingerprint.success': 'Die Fingerabdrücke wurden erfolgreich gescannt. Die Qualität des gescannten Fingerabdrucks beträgt {quality}/100. Sie können den Vorgang wiederholen, wenn die Qualität nicht ausreichend ist.',
  'fingerprint.notAvailable': 'Es scheint, dass der Fingerabdruckscanner nicht verfügbar ist oder die Treiber nicht installiert sind. Sie können den Check-in dennoch ohne die Fingerabdrücke fortsetzen.',
  'skip': 'Überspringen',
  'global.checkOut.fingerprint': 'Check-out mit Fingerabdruck',
  'global.checkIn.fingerprint': 'Check-in mit Fingerabdruck',
  'tooltip.checkOut.fingerprint': 'Die Person mit dem Fingerabdruck aus dem Land auschecken.',
  'tooltip.checkIn.fingerprint': 'Die Person mit dem Fingerabdruck im Land einchecken.',
  'paymentError.title': 'Problem mit Ihrer Bestellung',
  'DYN.paymentError.desc.fail': 'Leider ist die Zahlung fehlgeschlagen. Bitte versuchen Sie es erneut.',
  'DYN.paymentError.desc.error': 'Leider gab es einen Fehler bei der Zahlungsabwicklung. Bitte kontaktieren Sie uns.',
  'DYN.paymentError.desc.later': 'Leider wurde die Zahlungsabwicklung vorübergehend abgelehnt. Bitte versuchen Sie es erneut.',
  'tooltip.applicationId': 'Die ID des Antrags.',
  'client.extended.missingResource': 'Die von Ihnen angeforderte Ressource ist derzeit nicht verfügbar. Bitte versuchen Sie es später erneut.',
  'skip.valid': 'Bereits überprüfte Schritte automatisch überspringen',
  'extension.data': 'Verlängerungsdaten',
  'attestantAddress': 'Adresse des Attestanten',
  'attestantEmail': 'E-Mail des Attestanten',
  'attestantID': 'ID des Attestanten',
  'attestantName': 'Name des Attestanten',
  'attestantPhone': 'Telefonnummer des Attestanten',
  'attestantRelation': 'Beziehung zum Attestanten',
  'daysRequested': 'Anzahl der angeforderten Tage',
  'tooltip.visaApplicationReference': 'Zum mit diesem Verlängerungsantrag verknüpften Visumantrag gehen',
  'extend.visaReference': 'Antrag, der mit diesem Verlängerungsantrag verknüpft ist',
  'data.copyOfEntry': 'Kopie des Eintrags',
  'data.departureTicket': 'Abreiseticket',
  'data.attestantDocument': 'Dokument des Attestanten',
  'data.affidavitCustody': 'Sorgerechtsbescheinigung',
  'reasonForExtention': 'Grund für die Verlängerung',
  'final.ZWEVISAEXTAB': 'Dieser Verlängerungsantrag muss vom Beamten genehmigt werden.',
  'zwevisaExtValid': 'Verlängerung validiert',
  'zwevisaExtValid.desc': 'Dieser Verlängerungsantrag wurde vom Beamten validiert.',
  'zwevisaExtC.review.state': 'Überprüfung ausstehend',
  'zwevisaExtC.review.desc': 'Dieser Verlängerungsantrag wartet auf Überprüfung.',
  'zwevisaExtC.finalReview.desc': 'Dieser Verlängerungsantrag wurde überprüft und wartet derzeit auf die endgültige Überprüfung.',
  'zwevisaExtC.finalReview.state': 'Endgültige Überprüfung ausstehend',
  'err.AttestantName.maxlength': 'Der Name des Attestanten darf nicht länger als 50 Zeichen sein',
  'tooltip.tableLocalUsageUntilTitle': 'Die Anträge basierend auf dem maximalen Enddatum des Aufenthalts sortieren.',
  'tooltip.tableLocalUsageUntil': 'Das Ablaufdatum des Antrags bezieht sich auf das maximale Enddatum des Aufenthalts.',
  'ask.usageUntil.confirm': 'Hier können Sie das maximale Enddatum des Aufenthalts ändern. Sie können das Datum nur verkürzen.',
  'usageUntil.ask': 'Maximales Enddatum des Aufenthalts ändern (optional)',
  'error.bad.localUsageUntil': 'Das maximale Enddatum des Aufenthalts muss vor dem aktuellen Ablaufdatum und nach heute liegen.',
  'no.limit': 'Keine Begrenzung',
  'to.determined': 'Noch festzulegen',
  'none': 'Keines',
  'change.password.ad': 'Da Sie über ein Active Directory-Konto verfügen, können Sie Ihr Passwort hier nicht ändern. Für weitere Informationen wenden Sie sich bitte an den Support.',
  'noticePrint.title': 'Informationen der an den Besucher gedruckten Mitteilung',
  'reportingPost': 'Meldeposten',
  'noticePrint.matTooltip': 'Informationen der an den Besucher während des Check-in-Prozesses gedruckten Mitteilung',
  'err.paragraph.pattern': 'Das Feld darf nur einen einzelnen nicht akzentuierten Buchstaben enthalten.',
  'ask.print': 'Möchten Sie eine Mitteilung an den Besucher oder eine vorläufige Einschränkungsmitteilung drucken?',
  'notice.confirm': 'Sind Sie sicher über die Informationen auf der Mitteilung?',
  'camera.printNotice': 'Die Mitteilung drucken',
  'global.refusePrint': 'Drucken und einreichen',
  'global.nextPrint': 'Drucken und fortfahren',
  'err.periodFrom.periodFromGreaterThanPeriodTo': 'Der Zeitraum "Von" muss vor dem Zeitraum "Bis" liegen.',
  'err.externalId.maxlength': 'Externe ID darf nicht länger als 60 Zeichen sein',
  'payment_status.DYN.pass.9': 'Später bezahlen - Bestätigt',
  'payment.reject.success': 'Die Zahlung wurde erfolgreich abgelehnt.',
  'payment.reject_reason': 'Ablehnungsgrund',
  'err.fileSize': 'Dateigröße ist zu groß. Maximale Größe ist: {size}',
  'scan.birthCertificate': 'Geburtsurkunde hochladen',
  'err.scanBirthCertificate.required': 'Die Geburtsurkunde ist erforderlich',
  'document.zafembpass': 'ZAF Botschaftspass',
  'preenroll': 'Vorregistrierung',
  'screen': 'Screening',
  'pass': 'Reisepass',
  'zafembpass': 'ZAF Botschaftspass',
  'document.pass': 'Reisepass',
  'payment.validate.success': 'Die Zahlung wurde erfolgreich validiert.',
  'document.screen': 'Screening',
  'payment.consume.print.done.DYN.zwevisa': 'Der Visumantrag wurde erfolgreich eingecheckt/ausgecheckt. Die Quittung wird gedruckt.',
  'payment.ask.print': 'Ist die Quittung gedruckt?',
  'global.no.retry': 'Nein (erneut versuchen)',
  'print.receipt': 'Quittung drucken',
  'tooltip.printReceipt': 'Die Quittung(en) des Antrags drucken',
  'ask.printReceipt': 'Möchten Sie die Quittung vom <b>{date}</b> drucken?',
  'done.printReceipt': 'Die Quittung wurde erfolgreich gedruckt.',
  'error.printReceipt': 'Beim Drucken der Quittung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'ask.selectReceipt': 'Bitte wählen Sie die Quittung, die Sie drucken möchten.',
  'error.noReceiptSelected': 'Bitte wählen Sie eine Quittung zum Drucken aus.',
  'operation.OUT': 'Auschecken',
  'operation.IN': 'Einchecken',
  'noChoice.selectedReceipt': 'Bitte wählen Sie eine Quittung zum Drucken aus.',
  'select.receipt': 'Quittung auswählen',
  'receiptFor': 'Quittung für',
  'receiptFor.alt': 'Quittung für',
  'paymentType': 'Zahlungsmethode',
  'totalAmount.alt': 'Gesamtbetrag',
  'totalAmount': 'Gesamtbetrag',
  'totalAmountCurrency': 'Gesamtbetrag in bezahlter Währung',
  'electronic.receipt': 'Quittung',
  'date': 'Datum',
  'client': 'Kunde',
  'reference': 'Referenz',
  'agentID': 'Agenten-ID',
  'desc': 'Beschreibung',
  'details': 'Details',
  'client.extended.minUsageNotMet': 'Das Gültigkeitsdatum bis muss mindestens einen Tag nach dem Gültigkeitsdatum ab liegen.',
  'err.BirthDay.minDate': 'Das Geburtsdatum muss weniger als 150 Jahre zurückliegen.',
  'err.SpouseBirthDay.minDate': 'Das Geburtsdatum des Ehepartners muss weniger als 150 Jahre zurückliegen.',
  'payment.assigned.warning': 'Dieser Antrag wird derzeit von einem anderen Beamten (Beamter: {officerId}) aktiv bearbeitet. Möchten Sie diesen Antrag trotzdem öffnen?',
  'lock': 'Sperren',
  'tooltip.tableLockTitle': 'Aktive Nutzung durch Beamte',
  'tooltip.tableLock.me': 'Sie sind derzeit der aktive Beamte dieses Antrags',
  'tooltip.tableLock.other': 'Dieser Antrag wird derzeit aktiv von Beamten {officer} genutzt',
  'client.extended.uuidNotPaid': 'Die Zahlung wurde noch nicht validiert. Bitte validieren Sie die Zahlung, bevor Sie fortfahren.',
  'form.operationComments': 'Begründung der Operation',
  'err.operationComments.required': 'Die Begründung der Operation ist erforderlich',
  'err.operationComments.maxlength': 'Die Begründung der Operation darf nicht länger als 500 Zeichen sein',
  'err.DateOfExpiry.maxDate': 'Das Ablaufdatum muss weniger als 10 Jahre in der Zukunft liegen.',
  'err.ArrivalDate.maxDate': 'Das Ankunftsdatum muss weniger als 150 Jahre in der Zukunft liegen.',
  'err.DepartureDate.maxDate': 'Das Abreisedatum muss weniger als 150 Jahre in der Zukunft liegen.',
  'err.periodFrom.minDate': 'Das Zeitraum von muss nach dem heutigen Tag liegen.',
  'err.periodFrom.maxDate': 'Das Zeitraum von muss weniger als 150 Jahre in der Zukunft liegen.',
  'err.periodTo.minDate': 'Das Zeitraum bis muss nach dem heutigen Tag liegen.',
  'err.periodTo.maxDate': 'Das Zeitraum bis muss weniger als 150 Jahre in der Zukunft liegen.',
  'err.reportOn.minDate': 'Das Bericht-Datum muss nach dem heutigen Tag liegen.',
  'err.reportOn.maxDate': 'Das Bericht-Datum muss weniger als 150 Jahre in der Zukunft liegen.',
  'err.PlaceofIssue.required': 'Der Ausstellungsort ist erforderlich',
  'err.borderPass.required': 'Der Grenzpass ist erforderlich',
  'exit.borderPass.success': 'Die Ausreise ohne Visum wurde erfolgreich registriert. Sie werden zu den Antragsdetails weitergeleitet.',
  'borderPass.emit': 'Grenzpass ausgestellt',
  'tooltip.borderPass': 'Der Grenzpass ist ein Dokument, das es einer Person ermöglicht, die Grenze zu überqueren. Es wird verwendet, wenn die Person keinen Reisepass besitzt.',
  'zweexit.title': 'Ausreise ohne Visum',
  'borderPass.title': 'Grenzpass',
  'print.borderPass': 'Grenzpass drucken',
  'print.borderPass.ask': 'Ist der Grenzpass gedruckt?',
  'err.borderPassNumber.maxlength': 'Die Grenzpassnummer darf nicht länger als 20 Zeichen sein',
  'err.issueDate.afterExpiryDate': 'Das Ausstellungsdatum muss vor dem Ablaufdatum liegen.',
  'err.BirthDay.afterExpiryDate': 'Das Geburtsdatum muss vor dem Ablaufdatum liegen.',
  'err.BirthDay.afterIssueDate': 'Das Geburtsdatum muss vor dem Ausstellungsdatum liegen.',
  'apiPnrCollapse': 'PNR gefunden',
  'apipnrResponse.match': 'Übereinstimmung {numberMatch}',
  'match.name': 'Name',
  'match.score.name': 'Punktzahl des Namens',
  'match.flightCarrier': 'Fluggesellschaft',
  'match.flightNumber': 'Flugnummer',
  'match.documentType': 'Dokumentart',
  'match.documentNumber': 'Dokumentennummer',
  'match.documentIssuer': 'Aussteller des Dokuments',
  'match.timeType': 'Ereignistyp',
  'match.timeDate': 'Datum des Ereignisses',
  'match.timeScope': 'Umfang des Ereignisses',
  'match.nationality': 'Nationalität',
  'match.gender': 'Geschlecht',
  'match.uuid': 'UUID',
  'match.travelerUUID': 'Reisender UUID',
  'tooltip.apiPnr': 'Die gefundenen PNR-Informationen zu dieser Person.',
  'match.documentIssued': 'Ausstellungsdatum des Dokuments',
  'match.documentExpiration': 'Ablaufdatum des Dokuments',
  'match.documentScore': 'Punktzahl des Dokuments',
  'match.timeScore': 'Punktzahl des Ereignisses',
  'match.flightOriginDomestic': 'Inländischer Abflugort des Fluges',
  'match.flightOriginIcao': 'ICAO des Abflugortes',
  'match.flightOriginLat': 'Breitengrad des Abflugortes',
  'match.flightOriginLng': 'Längengrad des Abflugortes',
  'match.flightOriginIata': 'IATA des Abflugortes',
  'match.flightOriginCity': 'Stadt des Abflugortes',
  'match.flightOriginState': 'Bundesstaat des Abflugortes',
  'match.flightOriginCountry': 'Land des Abflugortes',
  'match.flightOriginContinent': 'Kontinent des Abflugortes',
  'match.flightOriginName': 'Name des Abflugortes',
  'match.flightOriginTimezone': 'Zeitzone des Abflugortes',
  'match.flightDestinationDomestic': 'Inländischer Zielort des Fluges',
  'match.flightDestinationIcao': 'ICAO des Zielortes',
  'match.flightDestinationLat': 'Breitengrad des Zielortes',
  'match.flightDestinationLng': 'Längengrad des Zielortes',
  'match.flightDestinationIata': 'IATA des Zielortes',
  'match.flightDestinationCity': 'Stadt des Zielortes',
  'match.flightDestinationState': 'Bundesstaat des Zielortes',
  'match.flightDestinationCountry': 'Land des Zielortes',
  'match.flightDestinationContinent': 'Kontinent des Zielortes',
  'match.flightDestinationName': 'Name des Zielortes',
  'match.flightDestinationTimezone': 'Zeitzone des Zielortes',
  'match.localDepartureDate': 'Lokales Abflugdatum',
  'match.localDepartureTime': 'Lokale Abflugzeit',
  'match.localDepartureTotalMinutes': 'Lokale Abflugsminuten insgesamt',
  'match.localDepartureDay': 'Lokaler Abflugtag',
  'match.localDepartureZone': 'Lokale Abflugzone',
  'match.airportDepartureDate': 'Flughafen-Abflugdatum',
  'match.airportDepartureTime': 'Flughafen-Abflugzeit',
  'match.airportDepartureTotalMinutes': 'Flughafen-Abflugsminuten insgesamt',
  'match.airportDepartureDay': 'Flughafen-Abflugtag',
  'match.airportDepartureZone': 'Flughafen-Abflugzone',
  'match.utcDepartureDate': 'UTC-Abflugdatum',
  'match.utcDepartureTime': 'UTC-Abflugzeit',
  'match.utcDepartureTotalMinutes': 'UTC-Abflugsminuten insgesamt',
  'match.utcDepartureDay': 'UTC-Abflugtag',
  'match.utcDepartureZone': 'UTC-Abflugzone',
  'match.originalDeparture': 'Ursprünglicher Abflug',
  'match.flightDepartureUtcstr': 'Abflug UTC des Fluges',
  'match.localArrivalDate': 'Lokales Ankunftsdatum',
  'match.localArrivalTime': 'Lokale Ankunftszeit',
  'match.localArrivalTotalMinutes': 'Lokale Ankunftsminuten insgesamt',
  'match.localArrivalDay': 'Lokaler Ankunftstag',
  'match.localArrivalZone': 'Lokale Ankunftszone',
  'match.airportArrivalDate': 'Flughafen-Ankunftsdatum',
  'match.airportArrivalTime': 'Flughafen-Ankunftszeit',
  'match.airportArrivalTotalMinutes': 'Flughafen-Ankunftsminuten insgesamt',
  'match.airportArrivalDay': 'Flughafen-Ankunftstag',
  'match.airportArrivalZone': 'Flughafen-Ankunftszone',
  'match.utcArrivalDate': 'UTC-Ankunftsdatum',
  'match.utcArrivalTime': 'UTC-Ankunftszeit',
  'match.utcArrivalTotalMinutes': 'UTC-Ankunftsminuten insgesamt',
  'match.utcArrivalDay': 'UTC-Ankunftstag',
  'match.utcArrivalZone': 'UTC-Ankunftszone',
  'match.originalArrival': 'Ursprüngliche Ankunft',
  'match.flightArrivalUtcstr': 'Ankunft UTC des Fluges',
  'match.flightBound': 'Flugrichtung',
  'print.receipt.select': 'Die Quittung wird gedruckt.',
  'ask.notice': 'Eine Mitteilung an den Besucher oder eine vorläufige Einschränkungsmitteilung drucken',
  'tooltip.checkIn.notice': 'Checken Sie die Person im Land ein und drucken Sie die Mitteilung an den Besucher oder eine vorläufige Einschränkungsmitteilung.',
  'global.checkIn.notice': 'Check-in mit einer Mitteilung',
  'event.downgrade': 'Dieser Antrag wurde von <b>{previousPayment}</b> auf <b>{newPayment}</b> am {date} vom Benutzer <b>{userId}</b> herabgestuft.',
  'form.numberPeople': 'Anzahl der mit dem Antragsteller reisenden Personen',
  'feedbackForm': 'Feedbackformular',
  'form.totalSpend': 'Gesamtausgaben während des Aufenthalts',
  'countryPermanentResidence': 'Land des ständigen Wohnsitzes',
  'countryDestination': 'Zielland',
  'form.feedbackComments': 'Zusätzliche Kommentare',
  'feedback.submit': 'Feedback absenden',
  'err.fundsSpend.required': 'Gesamtausgaben während des Aufenthalts sind erforderlich',
  'err.fundsCurrency.required': 'Währung der Ausgaben ist erforderlich',
  'err.countryResidence.required': 'Land des ständigen Wohnsitzes ist erforderlich',
  'err.countryDestination.required': 'Zielland ist erforderlich',
  'err.feedbackComment.required': 'Zusätzliche Kommentare sind erforderlich',
  'feedback.submitted': 'Das Feedback wurde erfolgreich eingereicht.',
  'start_feedback': 'Feedback geben',
  // Trads lib
  'demoMode.on': 'Derzeit im Demo-Modus',
  'demoMode.off': 'Demo-Modus aktivieren',
  'alreadyAccount': 'Schon ein Konto?',
  'close': 'Schließen',
  'createAccount': 'Ein Konto erstellen',
  'err.accountValidated': 'Ihr Konto ist bereits bestätigt',
  'err.confirm': 'E-Mail kann nicht bestätigt werden',
  'err.consumeLogin': 'Fehler bei der Anmeldung',
  'err.consumeLoginWeak': 'Anmeldung fehlgeschlagen',
  'err.country.required': 'Land ist erforderlich',
  'err.err': 'Fehler',
  'err.firstName.pattern': 'Vorname stimmt nicht mit dem Muster überein',
  'err.gender.required': 'Geschlecht ist erforderlich',
  'err.info': 'Fehler beim Abrufen der Benutzerdaten',
  'err.lastName.pattern': 'Nachname stimmt nicht mit dem Muster überein',
  'err.middleName.pattern': 'Zweiter Vorname stimmt nicht mit dem Muster überein',
  'err.middleName.required': 'Zweiter Vorname ist erforderlich',
  'err.nationality.pattern': 'Nationalität stimmt nicht mit dem Muster überein',
  'err.nationality.required': 'Nationalität ist erforderlich',
  'err.noEncryptedPDF': 'Dokument kann nicht als PDF gelesen werden oder ist gesichert',
  'err.otp.required': 'Bestätigungscode (OTP 1) ist erforderlich',
  'err.otp2.required': 'Bestätigungscode (OTP 2) ist erforderlich',
  'err.profession.pattern': 'Beruf stimmt nicht mit dem Muster überein',
  'err.profession.required': 'Beruf ist erforderlich',
  'err.rcs.required': 'Firmen-Nr. ist erforderlich',
  'err.rearm': 'Bestätigungs-E-Mail kann nicht erneut gesendet werden',
  'err.register': 'Konto konnte nicht erstellt werden',
  'err.register2FA': '2FA-Registrierung kann nicht gestartet werden',
  'err.requiredField': 'Ein erforderliches Feld ist leer, bitte versuchen Sie es erneut',
  'err.reset': 'Zurücksetzungscode kann nicht angefordert werden',
  'err.resetPassword': 'Passwort kann nicht zurückgesetzt werden',
  'err.street.required': 'Straßenname ist erforderlich',
  'err.streetNumber.required': 'Hausnummer ist erforderlich',
  'err.tenantLang.required': 'Sprache ist erforderlich',
  'err.zip.required': 'Postleitzahl ist erforderlich',
  'form.address': 'Adresse',
  'form.birthPlace': 'Geburtsort',
  'form.canNr': 'CAN-Nr.',
  'form.choose_country': 'Land auswählen',
  'form.choose_gmt': 'Zeitzone auswählen',
  'form.choose_lang': 'Sprache auswählen',
  'form.choose_nationality': 'Nationalität auswählen',
  'form.city': 'Stadt',
  'form.passportNr': 'Passnummer',
  'form.code': 'Wiederherstellungscode',
  'form.connectIpRange': 'IP-Bereich',
  'form.connectUaPattern': 'UA-Muster',
  'form.country': 'Land',
  'form.country.BEL': 'Belgien',
  'form.country.CIV': 'Elfenbeinküste',
  'form.country.LUX': 'Luxemburg',
  'form.country.ZWE': 'Simbabwe',
  'form.dateFrom': 'Von',
  'form.dateTo': 'Bis',
  'form.docNr': 'Dokument-Nr.',
  'form.expiryDate': 'Ablaufdatum',
  'form.gender': 'Geschlecht',
  'form.gender.female': 'Weiblich',
  'form.gender.male': 'Männlich',
  'form.gmt': 'Zeitzone',
  'form.gmtShiftMinutes.DYN-360': '-6 (New York, …)',
  'form.gmtShiftMinutes.DYN-540': '-9 (San Francisco, …)',
  'form.gmtShiftMinutes.DYN0': '+0 (London, …)',
  'form.gmtShiftMinutes.DYN60': '+1 (Luxemburg, Paris, Brüssel, …)',
  'form.height': 'Größe in cm',
  'form.identifier': 'Benutzer-ID',
  'form.lang': 'Sprache',
  'form.lang.EN': 'English',
  'form.lang.FR': 'Français',
  'form.lang.IT': 'Italiano',
  'form.lang.JA': '日本語',
  'form.lang.DE': 'Deutsch',
  'form.lang.CN': '中国人',
  'form.lang.RU': 'Pусский',
  'form.middleName': 'Zweiter Vorname',
  'form.mrz': 'MRZ',
  'form.nationality': 'Nationalität',
  'form.nationality.BEL': 'Belgier',
  'form.nationality.CIV': 'Ivorer',
  'form.nationality.LUX': 'Luxemburger',
  'form.nationality.ZWE': 'Simbabwer',
  'form.personalNr': 'Persönliche Nr.',
  'form.phoneNumber': 'Telefonnummer',
  'form.profession': 'Beruf',
  'form.rcs': 'RCS-Nr.',
  'form.street': 'Straßenname',
  'form.streetNr': 'Hausnummer',
  'form.tenantLang.DYN0': 'Englisch',
  'form.tenantLang.DYN1': 'Französisch',
  'form.tva': 'USt.-Nr.',
  'form.user': 'Benutzer',
  'form.zip': 'Postleitzahl',
  'global.clear': 'Löschen',
  'global.notAccount': 'Noch kein Konto?',
  'global.continue': 'Weiter',
  'global.error': 'Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut',
  'global.more': 'Mehr',
  'global.changePassword': 'Passwort ändern',
  'global.faq': 'FAQ',
  'global.myDatas': 'Meine Daten',
  'global.history': 'Verlauf',
  'global.see': 'Ansehen',
  'global.ddlDoc': 'Dokument herunterladen',
  'global.no_data_available': 'Keine Daten verfügbar',
  'global.not_yet_implemented.desc': 'Diese Seite ist leider noch nicht implementiert',
  'global.not_yet_implemented.title': 'Was für eine Überraschung!',
  'global.save': 'Speichern',
  'global.search': 'Suchen',
  'global.receipt': 'Beleg',
  'global.ddlReceipt': 'Beleg herunterladen',
  'global.fileSend': 'Datei(en) auswählen',
  'global.share': 'Teilen',
  'global.ready': 'Bereit',
  'global.start': 'Starten',
  'infinite': 'Unendlich',
  'login.back_sign_in': 'Zurück zum Einloggen',
  'login.code': 'Code',
  'login.create_account': 'Ein Konto erstellen',
  'login.didReset': 'Passwort geändert, Sie können sich jetzt einloggen',
  'login.email_verification': 'E-Mail-Verifizierung',
  'login.email_verification2': 'Gesendet',
  'login.email_verification_text': 'Vielen Dank für Ihre Registrierung. Um Ihr Konto zu bestätigen und die Registrierung abzuschließen, klicken Sie auf den Link, der an Ihre E-Mail gesendet wurde',
  'login.forInvite': 'Mit dem Einloggen verwenden Sie folgende Einladung: ',
  'login.forgot': 'Passwort vergessen',
  'login.forgot_sent': 'E-Mail',
  'login.forgot_sent2': 'gesendet',
  'login.forgot_sent_text': 'Ihre Anfrage zur Passwortzurücksetzung wurde an die E-Mail-Adresse gesendet',
  'login.has_account': 'Bestehender Benutzer',
  'login.in': 'einloggen',
  'login.invalid': 'Ungültiger Benutzername oder Passwort, bitte versuchen Sie es erneut!',
  'login.inviteDone': 'Einladung in Firma verwendet',
  'login.lost': 'Verloren',
  'login.lost_otp': 'OTP verloren',
  'login.lost_otp_text': 'Wenn Sie keinen Zugriff auf Google Authenticator haben (z.B. verlorenes oder ersetztes Telefon, deinstallierte App), können Sie sich nicht bei DocSeal anmelden. Das ist völlig normal, da es Teil unserer Sicherheitsrichtlinie ist. Klicken Sie unten, um den Support zu kontaktieren, der Ihre Identität überprüft und Ihnen hilft, den vollen Zugriff auf Ihr Konto wiederherzustellen.',
  'login.noInviteDone': 'Einladung konnte nicht verwendet werden, ist sie abgelaufen oder bereits verwendet?',
  'login.otp': 'Bestätigung',
  'login.password': 'Passwort',
  'login.registered': 'Konto registriert, überprüfen Sie Ihre E-Mail vor dem Einloggen!',
  'login.reset': 'Zurücksetzungscode gesendet, überprüfen Sie Ihre Mails',
  'login.setPassword': 'Passwort ändern',
  'login.setPasswordLong': 'Wählen Sie Ihr einfaches Login-Passwort',
  'login.sign': 'Unterschreiben',
  'login.to_sign_in': 'Zurück zum Einloggen',
  'login.up': 'hoch',
  'nan': 'Nicht verfügbar',
  'sign.prepared': 'Dokument wird vorbereitet',
  'signIn.forgotPassword': 'Passwort vergessen?',
  'signIn.title': 'Zugang',
  'signInPro.title': 'Pro-Zugang',
  'signUp.title': 'Konto erstellen',
  'global.reset': 'Zurücksetzen'
  //  
};
