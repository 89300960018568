import {Component, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxSatorisModule} from 'ngx-satoris';

@Component({
  selector: 'app-print-master',
  template: '<div></div>'
})
export class EmptyPrint {}

@NgModule({
  declarations: [
    EmptyPrint
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NgxSatorisModule
  ],
  providers: [
    EmptyPrint
  ],
  exports: [
    EmptyPrint
  ]
})
export class PassModule { }
