/* tslint:disable */
export const ru_strings = {
  'transactionReference.alt': 'Ссылка на транзакцию',
  'paymentType.alt': 'Тип оплаты',
  'changeGender.ask': 'Пол в заявке должен быть изменен на Мужской или Женский.',
  'print.askMultiple': 'Хотите распечатать все связанные документы?',
  'application.id': 'ID заявки',
  'printSticker': 'Распечатать визу',
  'print.receiptA5': 'Распечатать квитанцию A5',
  'correctionFields': 'Следующие поля были исправлены:',
  'corrected.desc': 'Эта заявка на визу была исправлена заявителем {date}.',
  'recentlyCorrected': 'Исправлено',
  'visaValidFrom': 'Виза действует с',
  'visaValidUntil': 'Виза действует до',
  'online': 'Онлайн',
  'offline': 'Офлайн',
  'receiptNr': 'Номер квитанции',
  'issuingOfficer.alt': 'Ответственный офицер',
  'placeOfIssue': 'Место выдачи',
  'fileRefNr': 'Номер ссылки файла',
  'tooltip.hasBeenCorrected': 'Эта заявка была исправлена заявителем.',
  'tooltip.extendStay': 'Продлить пребывание заявителя',
  'printFoldable': 'Распечатать складной документ',
  'printApprovalLetter': 'Распечатать письмо с одобрением',
  'operation.REFUSEOUT': 'Отказ в выезде',
  'events': 'События',
  'tooltip.events': 'Это детали событий. Некоторые могут содержать дополнительную информацию в зависимости от типа события и выполненной операции.',
  'form.paymentReference': 'Ссылка на оплату',
  'error.closeApplication': 'Произошла ошибка при закрытии заявки. Попробуйте еще раз.',
  'closeApplication': 'Отметить как закрытую',
  'tooltip.closeApplication': 'Отметить заявку как закрытую. Это действие необратимо.',
  'tooltip.tableNationalityTitle': 'Национальность заявителя',
  'understand': 'Понимаю',
  'ExtraDocument1': 'Дополнительный документ №1',
  'ExtraDocument2': 'Дополнительный документ №2',
  'ExtraDocument3': 'Дополнительный документ №3',
  'ExtraDocument4': 'Дополнительный документ №4',
  'ExtraDocument5': 'Дополнительный документ №5',
  'paperSize': 'Ширина бумаги',
  'global.changePrinterSize': 'Изменить ширину квитанции',
  'change_lang': 'Изменить язык',
  'queue.export.desc': 'Хотите экспортировать текущую очередь в файл JSON? <b>Ваша локальная очередь будет удалена после экспорта</b>. Вы уверены, что хотите продолжить?',
  'queue.export': 'Экспортировать в JSON',
  'queue.import': 'Импортировать очередь из JSON',
  'nationality': 'Национальность',
  'gender': 'Пол',
  'HQReference': 'Ссылка HQ',
  'vendorName': 'Имя поставщика',
  'vendorAddress': 'Адрес поставщика',
  'vendorEmail': 'Электронная почта поставщика',
  'idRequest': 'ID заявки',
  'serialNumber': 'Серийный номер',
  'global.resetDefaults': 'Сбросить до значений по умолчанию',
  'shortcuts.desc': 'Вы можете задать собственные сочетания клавиш ниже. Нажмите на клавишу, для которой хотите задать новое сочетание, и нажмите нужную клавишу на клавиатуре.',
  'press.key': 'Нажмите любую клавишу, чтобы задать сочетание...',
  'dashboard.keyboardShortcuts': 'Сочетания клавиш',
  'tooltip.changeShortcuts': 'Изменить сочетания клавиш',
  'global.changeShortcuts': 'Изменить сочетания клавиш',
  'retake_picture': 'Переснять фото',
  'error.faceCheck': 'Произошла ошибка при проверке совпадения лица. Попробуйте еще раз.',
  'faceCheck.see_score': 'Проверка лица',
  'recommendOrNot': 'Рекомендовать или нет',
  'recommended': 'Рекомендуется',
  'not_recommended': 'Не рекомендуется',
  'error.downgrade': 'Произошла ошибка при понижении запроса. Попробуйте еще раз.',
  'done.downgrade': 'Запрос успешно понижен до визы на один въезд.',
  'request.downgrade': 'Понизить',
  'err.externalCode.pattern': 'Внешний код (ИКАО) должен содержать 4 заглавные буквы',
  'request.extendStay': 'Продлить пребывание',
  'ResidencePermitNumber': 'Номер разрешения на проживание',
  'start_continue': 'Продолжить оплату',
  'error.payment.noInternalReference': 'Для этого заявления требуется HQ ссылка. Пожалуйста, подождите, пока ссылка будет предоставлена.',
  'err.Occupation.required': 'Профессия обязательна',
  'err.HomeAddress.required': 'Домашний адрес обязателен',
  'err.PassportCountry.required': 'Страна, выдавшая паспорт, обязательна',
  'request.revision': 'Хотите активировать АвтоПропуск? Эта функция автоматически пропускает шаги, которые не требуют вашего ввода.',
  'notice.BORDER_PASS': 'Пограничный пропуск',
  'form.borderPassNumber': 'Номер пограничного пропуска',
  'form.dateOfBirth': 'Дата рождения',
  'form.issueDate': 'Дата выдачи',
  'form.authorizedCrossingPoints': 'Авторизованные пункты пересечения',
  'form.issuedBy': 'Выдано',
  'err.borderPassNumber.required': 'Номер пограничного пропуска обязателен',
  'err.dateOfBirth.required': 'Дата рождения обязательна',
  'err.expiryDate.required': 'Дата окончания обязательна',
  'err.authorizedCrossingPoints.required': 'Авторизованные пункты пересечения обязательны',
  'err.issuedBy.required': 'Выдано обязательно',
  'err.issueDate.required': 'Дата выдачи обязательна',
  'form.externalCode': 'Внешний код (ИКАО)',
  'err.invoke': 'Ошибка',
  'ask.pendingRequest': 'У вас есть ожидающая коррекция. Открыть её сейчас?',
  'entry.confirmation': 'Вы уверены, что вся предоставленная информация верна? Нажимая "Подтвердить", вы отправляете заявку.',
  'free': 'Бесплатно',
  'last_update': 'Последнее обновление',
  'count': 'Количество',
  'tooltip.linkedExtensions': 'Это продление пребывания. Нажмите, чтобы просмотреть детали.',
  'extension': 'Продление',
  'client.extended.badPaymentMetadata': 'Метаданные оплаты неверны. Попробуйте снова или обратитесь в службу поддержки, если проблема повторяется.',
  'DaysRequested': 'Запрошенные дни',
  'payment.cancelled': 'Оплата была отменена. Попробуйте снова, если хотите продолжить. Вы будете перенаправлены на предыдущую страницу.',
  'payment.declined': 'Оплата была отклонена. Проверьте данные оплаты и наличие средств. Вы будете перенаправлены на предыдущую страницу.',
  'extension.ref': 'Ссылка на продление пребывания',
  'extensions.desc': 'Продление пребывания на рассмотрении',
  'extend': 'Продлить',
  'err.SpouseBirthDay.maxDate': 'Дата рождения супруга должна быть раньше сегодняшнего дня',
  'CheckboxVisaExtendFees': 'Подтверждаю, что предоставленная информация верна, и соглашаюсь с оплатой за продление пребывания, условиями и политикой конфиденциальности.',
  'err.AttestantPhone.pattern': 'Номер телефона должен иметь правильный формат, например: +263771234567',
  'err.AttestantEmail.email': 'Электронная почта должна иметь правильный формат',
  'err.AttestantDocument.required': 'Документ поручителя обязателен',
  'err.AffidavitCustody.required': 'Аффидевит об опеке обязателен',
  'err.CopyOfEntry.required': 'Копия въезда обязательна',
  'err.DepartureTicket.required': 'Билет на выезд обязателен',
  'AttestantDocument': 'Документ поручителя',
  'AffidavitCustody': 'Аффидевит об опеке',
  'CopyOfEntry': 'Копия въезда',
  'DepartureTicket': 'Билет на выезд',
  'DYN.Self': 'Сам',
  'DYN.Spouse/Dependent': 'Супруг/Зависимый',
  'DYN.Employee/Employer': 'Сотрудник/Работодатель',
  'DYN.Co-Director': 'Содиректор',
  'DYN.Lawyer': 'Адвокат',
  'VisaReference': 'Ссылка на визу',
  'ReasonForExtension': 'Причина продления',
  'AttestantID': 'ID поручителя',
  'AttestantName': 'Имя поручителя',
  'AttestantPhone': 'Телефон поручителя',
  'AttestantEmail': 'Электронная почта поручителя',
  'AttestantAddress': 'Адрес поручителя',
  'AttestantRelation': 'Связь с поручителем',
  'err.VisaReference.required': 'Ссылка на визу обязательна',
  'err.ReasonForExtension.required': 'Причина продления обязательна',
  'err.AttestantID.required': 'ID поручителя обязателен',
  'err.AttestantName.required': 'Имя поручителя обязательно',
  'err.AttestantPhone.required': 'Телефон поручителя обязателен',
  'err.AttestantEmail.required': 'Электронная почта поручителя обязательна',
  'err.AttestantAddress.required': 'Адрес поручителя обязателен',
  'err.AttestantRelation.required': 'Связь с поручителем обязательна',
  'start_extend': 'Продлить пребывание',
  'visaExtend.title': 'Продление пребывания',
  'days': 'дней',
  'day': 'день',
  'err.Email.email': 'Электронная почта должна иметь правильный формат',
  'err.EmergencyEmail.email': 'Электронная почта для экстренной связи должна иметь правильный формат',
  'kiosk.standby.text1': 'Начните заполнять вашу декларацию всего за несколько кликов на этом планшете или онлайн.',
  'kiosk.standbyDesc.text1': 'Перед началом ознакомьтесь с важной информацией:',
  'kiosk.standbyDesc.li1': 'Вы заполняете электронную форму, которая собирает ваши личные данные и информацию о поездке.',
  'kiosk.standbyDesc.li2': 'Вся предоставленная информация должна быть точной и соответствовать вашим проездным документам (паспорт, виза и т.д.).',
  'kiosk.standbyDesc.li3': 'После заполнения формы вы можете сразу перейти на иммиграционный контроль с вашими документами.',
  'kiosk.standbyDesc.li5': 'Если у вас возникнут трудности, обратитесь за помощью к сотрудникам на пограничном посту.',
  'kiosk.standbyDesc.text2': 'Нажмите "Начать", когда будете готовы заполнить декларацию о въезде.',
  'kiosk.success.title': 'Заявка успешно подана',
  'kiosk.success.text1': 'Теперь вы можете перейти к иммиграционному контролю с вашими проездными документами. Офицер проведет проверку и подтвердит ваш въезд в страну.',
  'kiosk.success.text2': 'Если у вас возникнут вопросы или трудности, не стесняйтесь обращаться за помощью к персоналу.',
  'confirm.revision': 'Подтвердить исправление',
  'print.again': 'Нет, распечатать снова',
  'print.isDone': 'Уведомление успешно распечатано? Пожалуйста, не забудьте передать напечатанное уведомление заявителю.',
  'err.otherMissingDocument.required': 'Требуется другой отсутствующий документ',
  'err.reportAt.required': 'Требуется отчет по месту',
  'err.reportOn.required': 'Требуется отчет по времени',
  'err.reportInterval.required': 'Требуется интервал отчета',
  'err.address.required': 'Требуется адрес',
  'err.periodFrom.required': 'Требуется период с',
  'err.periodTo.required': 'Требуется период до',
  'form.hostPhone': 'Телефон хоста',
  'err.hostPhone.required': 'Телефон хоста обязателен',
  'transit': 'Транзит',
  'conditionalEntry': 'Условный въезд',
  'departure': 'Отъезд',
  'err.host.required': 'Хост обязателен',
  'err.purpose.required': 'Цель обязательна',
  'err.fullNames.required': 'Полное имя обязательно',
  'err.officer.required': 'Офицер обязателен',
  'err.paragraph.required': 'Пункт обязателен',
  'err.dateOfRefusal.required': 'Требуется дата отказа',
  'form.paragraph': 'Пункт',
  'form.dateOfRefusal': 'Дата отказа',
  'form.purpose': 'Цель',
  'form.host': 'Хост',
  'form.officer': 'Офицер',
  'form.reportAt': 'Отчет по месту',
  'form.reportOn': 'Отчет по времени',
  'form.reportInterval': 'Интервал отчета',
  'notice.desc.RESTRICTION': 'Предварительное уведомление об ограничении информирует заявителя о документах, необходимых для завершения процесса. Вы можете выбрать недостающие документы, которые заявитель должен предоставить в указанный срок.',
  'notice.REFUSAL': 'Уведомление об отказе во въезде',
  'notice.desc.REFUSAL': 'Заявителю отказано во въезде в страну.',
  'notice.desc.REPORTING': 'Уведомление для посетителя позволяет заявителю въехать в страну, но может потребовать дальнейшей отчетности. Вы можете выбрать недостающие документы, которые заявитель должен предоставить в указанный срок.',
  'err.hqReference.required': 'Требуется ссылка HQ',
  'err.stationReference.required': 'Требуется ссылка на станцию',
  'err.reportLocation.required': 'Требуется место отчета',
  'err.daysUntilReport.required': 'Требуются дни до отчета',
  'err.businessAddress.required': 'Требуется бизнес-адрес',
  'err.residentialAddress.required': 'Требуется домашний адрес',
  'form.stationReference': 'Ссылка на станцию',
  'form.fullNames': 'Полное имя',
  'form.reportLocation': 'Место отчета',
  'form.daysUntilReport': 'Дни до отчета',
  'form.otherMissingDocument': 'Другой отсутствующий документ',
  'form.residentialAddress': 'Домашний адрес',
  'form.businessAddress': 'Бизнес-адрес',
  'form.hqReference': 'Ссылка HQ',
  'nationalPassport': 'Национальный паспорт',
  'residentPermit': 'Разрешение на проживание',
  'proofOfInvestment': 'Доказательство инвестиций',
  'evidenceOfMeans': 'Доказательство наличия средств',
  'evidenceOfResidentialStatus': 'Доказательство резидентского статуса',
  'studentScholarPermit': 'Разрешение студента/ученого',
  'radiologicalCertificate': 'Радиологическое свидетельство',
  'temporaryPermit': 'Временное разрешение',
  'evidenceOfEmployment': 'Доказательство занятости',
  'temporaryEmploymentPermit': 'Временное разрешение на работу',
  'fullAddressOfDestination': 'Полный адрес назначения',
  'appealOutcome': 'Результат апелляции',
  'request.notice': 'Выберите уведомление для печати',
  'notice.title': 'Доступные уведомления для печати',
  'notice.desc': 'Пожалуйста, выберите уведомление из списка ниже. После заполнения необходимых полей вы можете распечатать уведомление для заявителя.',
  'notice.RESTRICTION': 'Предварительное уведомление об ограничении',
  'notice.REPORTING': 'Уведомление для посетителя',
  'notice.RESTRICTION.desc': 'Заявитель должен предоставить недостающие документы в указанный срок.',
  'notice.REFUSAL.desc': 'Заявителю отказано во въезде в страну.',
  'notice.REPORTING.desc': 'Заявителю разрешен въезд, но может потребоваться дальнейшая отчетность.',
  'passportType.kioskWarning': 'Выбранная национальность относится к режиму Категории С. Немедленно обратитесь к иммиграционному офицеру.',
  'free.charge.total': 'Итого: бесплатно',
  'err.entryPoints': 'Произошла ошибка при загрузке планируемых пунктов въезда',
  'err.PreviousConvictionsHomeAsk.required': 'Пожалуйста, ответьте на вопрос о предыдущих судимостях в вашей стране проживания',
  'summary.your_meta': 'Ваши данные (нажмите, чтобы открыть)',
  'summary.payOnline': 'Предпочитаю оплатить онлайн сейчас, а не на стойке офицера по прибытии.',
  'summary.payOnline.desc': 'Согласие с опцией ниже переведет вас на страницу оплаты. Если вы решите не платить онлайн, вы сможете оплатить визовый сбор на стойке офицера по прибытии за тип визы "Виза по прибытии".',
  'summary.meta': 'Данные (нажмите, чтобы открыть)',
  'EmergencyLastName': 'Фамилия контакта на случай экстренной связи',
  'EmergencyFirstName': 'Имя контакта на случай экстренной связи',
  'EmergencyEmail': 'Электронная почта контакта на случай экстренной связи',
  'EmergencyPhone': 'Телефон для экстренной связи',
  'err.EmergencyLastName.required': 'Фамилия контакта на случай экстренной связи обязательна',
  'err.EmergencyFirstName.required': 'Имя контакта на случай экстренной связи обязательно',
  'err.EmergencyEmail.required': 'Электронная почта контакта на случай экстренной связи обязательна',
  'err.EmergencyPhone.required': 'Телефон для экстренной связи обязателен',
  'person.name': 'Имя выбранного человека',
  'DYN.identity-card': 'Удостоверение личности',
  'DYN.resident-permit': 'Временное разрешение на проживание',
  'DYN.identity-card.desc': 'Удостоверение личности – это документ, выдаваемый государством для внутренних целей идентификации.',
  'DYN.resident-permit.desc': 'Временное разрешение на проживание – это официальный документ, выданный правительством, позволяющий иностранному гражданину проживать в стране в течение определенного периода.',
  'err.visaFeesAndTerms.required': 'Пожалуйста, примите визовые сборы и условия использования и политику конфиденциальности',
  'err.NonOrdinaryPassMission.required': 'Требуется причина официального визита',
  'NonOrdinaryPassMission': 'Причина официального визита',
  'err.PreviousConvictionsHome.required': 'Требуются предыдущие судимости в стране проживания',
  'PreviousConvictionsHomeAsk': 'Были ли вы или кто-либо из ваших спутников осуждены за какое-либо преступление в вашей стране проживания?',
  'PlaceOfBirthCountry': 'Страна рождения',
  'PlaceOfBirthCity': 'Город рождения',
  'OtherName': 'Другие имена',
  'travelName': 'Ссылка на заявление',
  'plannedEntry': 'Планируемый пункт въезда',
  'summary.title': 'Сводка',
  'urgent': 'Срочное',
  'visaType': 'Тип визы',
  'passportType': 'Тип документа',
  'err.plannedEntry.required': 'Планируемый пункт въезда обязателен',
  'understood': 'Согласен',
  'passportType.acknowledge': 'Выбирая этот тип паспорта, вы признаете, что несоблюдение условий использования может привести к отказу во въезде.',
  'form.travelName.already': '<b>Эта заявление уже подано с указанной ниже ссылкой на него. Если вы хотите изменить заявление, начните новое.</b>',
  'application.current_person': 'Вы редактируете заявление для',
  'DYN.USD': '$ USD',
  'DYN.EUR': '€ EUR',
  'DYN.ZWL': 'ZWL',
  'DYN.GBP': '£ GBP',
  'DYN.BWP': 'BWP',
  'participants.edit': 'Редактировать заявителей',
  'request.existSaved': 'Найдено сохраненное заявление, хотите загрузить его данные? (Если нажмете "нет", заявление будет удалено и создано новое)',
  'participant.hasData': 'Вы уже ввели данные для этого заявителя. Если продолжите, данные будут утеряны. Вы уверены, что хотите продолжить?',
  'participants.title': 'Заявители',
  'participants.desc': 'Выберите себя и/или других заявителей, которые будут частью этого заявления. </br><b>Оплата в конце будет произведена за всех выбранных заявителей.</b></br></br>Вы можете добавить/удалить заявителя или редактировать его заявку в любое время, нажав на имя, которое появится над формой.</br></br>{participantText}',
  'participant.ready': 'Когда будете готовы, нажмите "Продолжить".',
  'participant.missing': 'Перед продолжением создайте хотя бы одного заявителя.',
  'travelName.title': 'Ссылка на заявление',
  'form.travelName': 'Ссылка на заявление',
  'form.travelName.desc': 'Ссылка на заявление будет использоваться для облегчения идентификации вашего заявления.',
  'err.travelName.required': 'Ссылка на заявление обязательна',
  'nationality.title': 'Национальность',
  'nationality.desc': 'Пожалуйста, выберите вашу национальность. Список доступных типов документов поменяется в зависимости от этого выбора.',
  'passportType.title': 'Тип документа',
  'passportType.desc': 'Выберите тип документа, который вы используете для этого заявления.',
  'visaType.title': 'Доступные варианты въезда',
  'visaType.desc': 'Выберите вариант въезда, на который вы хотите подать заявление. Этот список варьируется в зависимости от вашей национальности и типа паспорта.',
  'urgent.title': 'Это срочное заявление?',
  'urgent.desc': 'Если ваше заявление на визу <b>срочное</b>, выберите этот вариант. Время обработки будет короче, но за эту услугу взимается <b>дополнительная плата</b>.</br></br>Обратите внимание, что время обработки может варьироваться в зависимости от типа визы и страны подачи заявления.',
  'urgent.checkbox': 'Я хочу, чтобы моё заявление на визу было обработано в срочном порядке, и я понимаю, что за эту услугу будет взиматься дополнительная плата.',
  'visa.paidOnArrival.title': 'Виза, оплачиваемая по прибытии',
  'visa.paidOnArrival.desc': 'Позволяет имеющим право путешественникам получить визу по прибытии в пункт въезда назначения, упрощая процесс въезда для краткосрочных поездок.',
  'visa.paidOnline.title': 'Виза, оплачиваемая онлайн',
  'visa.paidOnline.desc': 'Позволяет однократный или многократный въезд в страну в течение одного (1) месяца, требуя новой визы для последующих въездов.',
  'dashboard.zweentry': 'Декларация о въезде',
  'PassportType': 'Тип паспорта',
  'PassportCountry': 'Страна выдачи паспорта',
  'form.passportCountry': 'Страна выдачи паспорта',
  'DYN.ordinary': 'Обычный паспорт',
  'DYN.diplomatic': 'Дипломатический паспорт',
  'DYN.official': 'Официальный паспорт',
  'DYN.service': 'Служебный паспорт',
  'DYN.refugee': 'Паспорт беженца',
  'DYN.ordinary.desc': 'Обычный паспорт — это самый распространенный тип паспорта, выдаваемый гражданам для общих целей путешествий.',
  'DYN.diplomatic.desc': 'Дипломатический паспорт выдается дипломатам и консулам для официальных поездок и проживания за границей.',
  'DYN.refugee.desc': 'Паспорт беженца выдается страной людям, которых она признает беженцами, для путешествий за границу.',
  'DYN.official.desc': 'Официальный паспорт выдается государственным служащим для официальных поездок и проживания за границей.',
  'DYN.service.desc': 'Служебный паспорт выдается государственным служащим для официальных поездок и проживания за границей.',
  'DYN.marine': 'Пасспорт моряка',
  'DYN.marine.desc': 'Паспорт моряка выдается морякам и членам экипажа для поездок и проживания за границей.',
  'DYN.laissez-passer': 'Паспорт ООН',
  'DYN.laissez-passer.desc': 'Паспорт ООН —  это документ, выдаваемый Организацией Объединенных Наций или её агентствами для официальных поездок и проживания за границей.',
  'err.PassportType.required': 'Требуется тип паспорта',
  'Urgent': 'Это срочный запрос. Я понимаю, что время обработки будет короче, но стоимость будет выше.',
  'photo.passport.scanned': 'Паспорт отсканирован',
  'review.nr.final': 'Окончательный обзор',
  'review.nr': 'Обзор №',
  'err.ArrivalDate.maxDateMilliTo': 'Необходимо, чтобы между датой истечения срока действия вашего паспорта и датой вашего прибытия в страну прошло не менее шести (6) месяцев. Пожалуйста, обновите этот паспорт перед подачей заявления на визу.',
  'err.DateOfExpiry.maxDateMilliTo': 'Ваш паспорт истекает менее чем через шесть (6) месяцев с даты прибытия в страну. Пожалуйста, обновите этот паспорт перед подачей заявления на визу.',
  'place.update.error': 'Произошла ошибка при обновлении места',
  'dashboard.kiosk.start': 'Начать процесс',
  'form.role.kiosk': 'Киоск',
  'currency_desc': 'Пожалуйста, выберите сумму и валюту доступных средств на момент въезда:',
  'FundsAvailable': 'Сумма',
  'FundsAvailableCurrency': 'Валюта',
  'err.FundsAvailable.required': 'Доступные средства на момент въезда обязательны',
  'err.FundsAvailableCurrency.required': 'Требуется валюта доступных средств на момент въезда',
  'emergency_contact_text': 'Укажите контактные данные человека, с которым можно связаться, в случае чрезвычайной ситуации.',
  'emergency_contact': 'Контакт для экстренной связи / Ближайший родственник',
  'err.BirthDay.mustBeEqualOrBefore': 'Дата рождения должна быть раньше или равна дате выдачи паспорта',
  'err.DateOfIssue.mustBeEqualOrAfter': 'Дата выдачи должна быть позже или равна дате рождения',
  'internet.lost': 'Интернет-соединение потеряно.</br>Пожалуйста, проверьте ваше соединение.',
  'err.DateOfExpiry.maxDateMilliFromToday': 'Ваш паспорт истекает менее чем через шесть (6) месяцев с сегодняшнего дня. Пожалуйста, обновите этот паспорт перед подачей заявления на визу.',
  'err.DepartureDate.maxDateMilliFrom': 'Дата выезда слишком отдалена от даты прибытия. Пожалуйста, обратитесь к срокам действия визы.',
  'requirements': 'Требования',
  'err.file.size': 'Размер файла слишком большой. Максимальный размер: ',
  'revision.success': 'Ваш запрос успешно обновлен. Вы получите письмо с QR-кодом, как только он будет одобрен.',
  'PAYMENT_UPDATED': 'Запрос был обновлен',
  'revision.free': 'Бесплатное исправление',
  'err.Email.pattern': 'Электронная почта должна иметь правильный формат',
  'err.Phone.pattern': 'Номер телефона должен иметь правильный формат, например: +263771234567',
  'revision.start': 'Мы заполнили поля на основе предоставленной вами информации за исключением тех полей, которые вызвали проблемы. Пожалуйста, проверьте информацию и исправьте её. Хотите продолжить?',
  'file_uploaded': 'Файл уже загружен и сохранен',
  'revision.person_not_found': 'Заявитель, прикрепленный к этому запросу, не найден.',
  'refusal.desc2': 'Следующие поля вызывают проблему:',
  'start_correction': 'Начать исправление',
  'PAYMENT_SUSPEND_CHANGE': 'Запрос приостановлен, требуется изменение.',
  'payment.selectMode': 'Выберите поля, которые вызывают проблему. Вы можете выбрать несколько полей.',
  'suspendReason.desc': 'Напишите краткое описание запроса на исправление. Это описание будет отправлено заявителю вместе с выбранными проблемными полями.',
  'refusalReason.incomplete_or_missing': 'Неполная или отсутствующая информация в форме.',
  'refusalReason.inconsistencies_or_contradictions': 'Несоответствия или противоречия в предоставленной информации.',
  'refusalReason.incorrect_misleading_info': 'Неправильная, вводящая в заблуждение или ложная информация.',
  'refusalReason.missing_or_illegible_documents': 'Требуемые документы не предоставлены, неполные или нечитаемые.',
  'refusalReason.documents_not_compliant': 'Документы не соответствуют требованиям (качество, срок действия).',
  'refusalReason.non_compliance_visa_requirements': 'Несоответствие требованиям для визы.',
  'refusalReason.terms_conditions_not_accepted': 'Условия и положения визы не приняты.',
  'refusalReason.payment_issues': 'Неоплата визовых сборов или ошибка в процессе оплаты.',
  'refusalReason.problems_with_personal_details': 'Проблемы, связанные с возрастом, национальностью или семейным положением заявителя.',
  'refusalReason.criminal_history_travel_issues': 'Проблемы с уголовной историей или историей путешествий.',
  'refusalReason.inadequate_purpose_of_visit': 'Недостаточная или несоответствующая цель визита для типа визы.',
  'refusalReason.security_background_concerns': 'Проблемы, выявленные во время проверки безопасности или фона.',
  'refusalReason.doubts_about_authenticity': 'Сомнения в подлинности предоставленной информации или документов.',
  'refusalReason.legitimacy_concerns': 'Проблемы с легитимностью пребывания или приглашения.',
  'refusalReason.form_entry_or_technical_issues': 'Ошибки при вводе формы или технические проблемы при подаче заявки.',
  'refusalReason.electronic_submission_difficulties': 'Трудности, связанные с электронной подачей документов.',
  'refusalReason.host_country_capacity_or_restrictions': 'Превышена ёмкость принимающей страны или введены текущие ограничения.',
  'refusalReason.exceptional_diplomatic_political_considerations': 'Исключительные дипломатические или политические соображения.',
  'refusalReason.exceptional_circumstances_humanitarian_reasons': 'Исключительные обстоятельства или гуманитарные причины для отказа.',
  'refusalReason.other': 'Другое, укажите.',
  'err.BirthDay.maxDate': 'Дата рождения должна быть раньше сегодняшнего дня',
  'err.DepartureDate.minDate': 'Дата выезда должна быть позже сегодняшнего дня',
  'err.ArrivalDate.minDate': 'Дата прибытия должна быть позже сегодняшнего дня',
  'err.DateOfExpiry.minDate': 'Дата окончания должна быть позже сегодняшнего дня',
  'err.DateOfIssue.maxDate': 'Дата выдачи должна быть раньше сегодняшнего дня',
  'err.ArrivalDate.mustBeEqualOrBefore': 'Дата прибытия должна быть раньше или равна дате выезда',
  'err.DepartureDate.mustBeEqualOrAfter': 'Дата выезда должна быть позже или равна дате прибытия',
  'qr.request_desc': 'Этот QR-код является цифровым пропуском для обработки визы. Правительство оставляет за собой право одобрить или отказать во въезде в момент контроля визы.',
  'PAYMENT_REFUSED': 'Оплата отклонена',
  'request_state.PENDING': 'Ожидание',
  'document.Screening': 'Проверка',
  'deletePerson.error.DYN.pass': 'Произошла ошибка при удалении человека. Вы не можете удалить человека, который уже оплатил документ.',
  'deletePerson.error': 'Произошла ошибка при удалении человека. Вы не можете удалить человека, который уже подал заявку.',
  'miscellaneousTrip': 'Детали поездки',
  'miscellaneous_text': 'Пожалуйста, введите следующую информацию правдиво',
  'err.SpouseBirthDay.max': 'Недопустимая дата рождения супруга',
  'err.ArrivalDate.min': 'Недопустимая дата прибытия',
  'err.DepartureDate.min': 'Недопустимая дата выезда',
  'err.DateOfIssue.max': 'Недопустимая дата выдачи',
  'err.BirthDay.max': 'Недопустимая дата рождения',
  'err.dependants_date_of_birth.max': 'Недопустимая дата рождения зависимого',
  'err.DateOfExpiry.min': 'Недопустимая дата окончания',
  'dependants': 'Зависимые лица',
  'err.PassportPhoto.required': 'Требуется недавнее фото лица',
  'err.PassportScan.required': 'Требуется скан паспорта',
  'err.ProofOfResidenceHost.required': 'Требуется подтверждение проживания у хоста/бронирования',
  'err.BusinessLetter.required': 'Требуется письмо от бизнеса',
  'err.BusinessProfile.required': 'Требуется бизнес-профиль',
  'err.InvitationLetterAndBusinessProfile.required': 'Требуется письмо приглашения и бизнес-профиль',
  'err.SpouseFullName.required': 'Имя супруга обязательно',
  'err.SpousePassportNumber.required': 'Номер паспорта супруга обязателен',
  'err.SpouseBirthDay.required': 'Дата рождения супруга обязательна',
  'err.SpousePlaceOfBirth.required': 'Место рождения супруга обязательно',
  'err.ArrivalDate.required': 'Дата прибытия обязательна',
  'err.DepartureDate.required': 'Дата выезда обязательна',
  'err.DateOfIssue.required': 'Дата выдачи обязательна',
  'err.DateOfExpiry.required': 'Дата окончания обязательна',
  'err.Email.required': 'Электронная почта обязательна',
  'err.Phone.required': 'Номер телефона обязателен',
  'err.HostName.required': 'Имя хоста обязательно',
  'err.LastName.required': 'Фамилия обязательна',
  'err.FirstName.required': 'Имя обязательно',
  'err.BirthDay.required': 'Дата рождения обязательна',
  'err.PlaceOfBirthCity.required': 'Город рождения обязателен',
  'err.PlaceOfBirthCountry.required': 'Страна рождения обязательна',
  'err.Gender.required': 'Пол обязателен',
  'err.MaritalStatus.required': 'Семейное положение обязательно',
  'err.Nationality.required': 'Национальность обязательна',
  'err.PurposeOfVisit.required': 'Цель визита обязательна',
  'male': 'Мужской',
  'female': 'Женский',
  'other': 'Другой',
  'single': 'Не женат/Не замужем',
  'married': 'Женат/Замужем',
  'separated': 'В разводе',
  'divorced': 'Разведен/а',
  'widowed': 'Вдова/Вдовец',
  'select_reason_first': 'Пожалуйста, выберите причину визита на предыдущей странице, чтобы увидеть список требуемых документов.',
  'visaIntro.title': 'Заявка',
  'askDocument': 'Начать заявку',
  'cart.load': 'Найдена сохраненная заявка, хотите загрузить её данные? (Если нажмете "нет", заявка будет удалена)',
  'cart.load.otherConfig': 'Найдена сохраненная заявка, но выбранный вами документ относится к другому типу визы. Хотите продолжить и удалить предыдущую заявку?',
  'cart.keep': 'Хотите сохранить запрос и продолжить позже?',
  'err.email.pattern': 'Электронная почта должна иметь правильный формат',
  'err.phone.pattern': 'Номер телефона должен иметь правильный формат, например: +263 77 123 4567',
  'remove': 'Удалить',
  'dependant_add': 'Добавить зависимого',
  'dependant': 'Зависимый',
  'err.dependants_name.required': 'Имя зависимого обязательно',
  'err.dependants_passport_number.required': 'Номер паспорта зависимого обязателен',
  'err.dependants_date_of_birth.required': 'Дата рождения зависимого обязательна',
  'err.dependants_place_of_birth.required': 'Место рождения зависимого обязательно',
  'upload_file': 'Загрузить файл (< {size})',
  'PassportPhoto': 'Недавнее фото лица',
  'PassportScan': 'Скан паспорта',
  'ProofOfResidenceHost': 'Подтверждение проживания у хоста/бронирования',
  'AddressAtDestination': 'Адрес по месту назначения',
  'HostInvitationLetter': 'Письмо-приглашение от хоста',
  'ProofOfResidence': 'Подтверждение проживания',
  'ApplicationLetter': 'Заявление',
  'ResidentialStatusOfHost': 'Статус проживания хоста',
  'ReasonOfVisitProof': 'Подтверждение цели визита',
  'BusinessLetter': 'Письмо от бизнеса',
  'BusinessProfile': 'Бизнес-профиль',
  'InvitationLetterAndBusinessProfile': 'Письмо приглашения и бизнес-профиль',
  'InvitationLetter': 'Письмо приглашения',
  'LetterOfAcceptanceFromSchool': 'Письмо о приеме',
  'err.AddressAtDestination.required': 'Адрес по месту назначения обязателен',
  'err.HostInvitationLetter.required': 'Письмо-приглашение от хоста обязательно',
  'err.ProofOfResidence.required': 'Подтверждение проживания обязательно',
  'err.ApplicationLetter.required': 'Заявление обязательно',
  'err.ResidentialStatusOfHost.required': 'Статус проживания хоста обязателен',
  'err.ReasonOfVisitProof.required': 'Подтверждение цели визита обязательно',
  'err.LetterOfAcceptanceFromSchool.required': 'Письмо о приеме обязательно',
  'err.InvitationLetter.required': 'Письмо приглашения обязательно',
  'DYN.male': 'Мужской',
  'DYN.female': 'Женский',
  'DYN.other': 'Другой',
  'DYN.single': 'Не женат/Не замужем',
  'DYN.married': 'Женат/Замужем',
  'DYN.separated': 'В разводе',
  'DYN.divorced': 'Разведен/а',
  'DYN.widowed': 'Вдова/Вдовец',
  'passport.valid': 'Сканированный документ не выглядит измененным или аннулированным и подписан страной, выдавшей его.',
  'travel_information_title': 'Предупреждение',
  'zwevisa_application_title': 'Перед началом убедитесь, что',
  'zwevisa_application_text': '<ol><li><b>1. </b>Ваша информация точна и правдива</li><li><b>2. </b>У вас есть карта MasterCard или Visa с поддержкой 3D Secure и достаточно средств для оплаты визового сбора.</li><li><b>3. </b>Оплачивая и подавая это заявление, вы также подтверждаете, что все введенные данные верны и точны. Подделка информации для получения визы является незаконной и подлежит наказанию при осуждении.</li></ol>',
  'visaPrice.desc': 'Вам потребуется произвести оплату Visa или MasterCard в конце заявки. Эта оплата не подлежит возврату.',
  'visaPrice': 'Стоимость визы',
  'required': 'Обязательно',
  'DYN.holiday_visa': 'Отдых',
  'DYN.live_here': 'Долгосрочное проживание',
  'DYN.business_visa': 'Бизнес',
  'DYN.conferencing_visa': 'Конференция',
  'DYN.transit_visa': 'Транзит',
  'DYN.student_visa': 'Учеба',
  'paypal': 'PayPal',
  'document': 'Документ',
  'Dependants': 'Зависимые лица',
  'dependants_name': 'Имя',
  'dependants_passport_number': 'Номер паспорта',
  'dependants_date_of_birth': 'Дата рождения',
  'dependants_place_of_birth': 'Место рождения',
  'Spouse': 'Супруг',
  'SpouseFullName': 'Имя супруга',
  'SpousePassportNumber': 'Номер паспорта супруга',
  'SpouseBirthDay': 'Дата рождения супруга',
  'SpousePlaceOfBirth': 'Место рождения супруга',
  'ArrivalDate': 'Ожидаемая дата прибытия',
  'DepartureDate': 'Ожидаемая дата выезда',
  'ReferencePreviousVisa': 'Ссылка на предыдущую визу',
  'PreviousConvictionsHome': 'Суть судимости в стране проживания',
  'travel_information': 'Информация о поездке',
  'DateOfIssue': 'Дата выдачи',
  'DateOfExpiry': 'Дата окончания',
  'PlaceofIssue': 'Место выдачи',
  'Email': 'Электронная почта',
  'Phone': 'Номер телефона',
  'Occupation': 'Профессия',
  'HomeAddress': 'Текущий адрес проживания',
  'HostName': 'Имя принимающей стороны',
  'form.seniority': 'Уровень старшинства',
  'LastName': 'Фамилия',
  'FirstName': 'Имя (имена)',
  'BirthDay': 'Дата рождения',
  'PlaceOfBirth': 'Место рождения',
  'Gender': 'Пол',
  'MaritalStatus': 'Семейное положение',
  'CheckboxVisaFees': 'Я подтверждаю, что предоставленная информация верна и точна, и я соглашаюсь с визовыми сборами, условиями и политикой конфиденциальности.',
  'PassportNumber': 'Номер паспорта',
  'Nationality': 'Национальность',
  'PurposeOfVisit': 'Цель визита',
  'VisaType': 'Тип визы',
  'ResidenceProof': 'Доказательство проживания',
  'DestinationAddress': 'Адрес назначения',
  'visitor_details': 'Данные заявителя',
  'personal_details': 'Личные данные',
  'contact_details': 'Контактные данные',
  'miscellaneous_details': 'Информация о поездке',
  'spouse_details': 'Данные супруга',
  'payment': 'Оплата',
  'dashboard.documents.DYN.pass': 'Запрос документов',
  'global.scan_qr': 'Проверить QR-код',
  'qr.nosuch': 'Загрузка… QR-код еще не найден в системе. Проверьте подключение к интернету.',
  'qr.faked': 'Ответ сервера: QR-код не существует. Вероятно, это подделка!',
  'qr.quicklook': 'Ключевая информация',
  'global.toPayments.DYN.pass': 'Просмотреть платежи',
  'dashboard.documents.DYN.zwevisa': 'Заявка на визу',
  'global.toPayments.DYN.zwevisa': 'Просмотр заявок',
  'form.createPlaceId': 'ID места',
  'payment.seeDuplicata': 'Открыть дубликат',
  'payment.duplicate.open': 'Открыть новый платеж?',
  'ONE_DAY': '1 день',
  'THREE_DAYS': '3 дня',
  'ONE_WEEK': '1 неделя',
  'TWO_WEEKS': '2 недели',
  'ONE_MONTH': '1 месяц',
  'TWO_MONTHS': '2 месяца',
  'SIX_MONTHS': '6 месяцев',
  'ONE_YEAR': '1 год',
  'dropdown.since': 'С момента',
  'payments.hasFilter': 'Фильтр активен',
  'payments.clear': 'Сбросить',
  'payments.clearFilters': 'Очистить все фильтры',
  'place_created.open': 'Открыть страницу информации о месте',
  'place_created.desc': 'Новое место создано и доступно для использования.',
  'payment_status.DYN.NONE': '– Нет фильтра –',
  'dropdown.state': 'Статус',
  'payment.create_noExternalId': 'Платеж зарегистрирован, но требуется проверка супервизора перед его использованием. Вы получите уведомление по электронной почте, как только платеж будет принят или отклонен.',
  'payment.qrList.title': 'Список QR-платежей',
  'see.qr_list': 'Посмотреть список QR-кодов',
  'form.role.partner_registrer': 'Регистрация партнера',
  'form.reason': 'Причина',
  'err.reason.required': 'Требуется указать причину',
  'global.reject': 'Отклонить',
  'payment.validate_desc': 'Вы уверены, что хотите подтвердить этот платеж?',
  'payment.reject_desc': 'Вы уверены, что хотите отклонить этот платеж? Не забудьте указать причину.',
  'qr.title': 'QR-код',
  'payments.count.DYN.pass': 'Количество платежей: ',
  'payments.count.DYN.zwevisa': 'Количество заявок: ',
  'dashboard': 'Панель управления',
  'dashboard.document': 'Заявка на визу',
  'dashboard.follow_up': 'Отслеживание заявления',
  'dashboard.people.DYN.pass': 'Люди',
  'dashboard.people.DYN.zwevisa': 'Сохраненные заявители',
  'dashboard.my_account': 'Мой аккаунт',
  'form.refunded_date': 'Дата возврата средств',
  'form.enter_message': 'Введите сообщение',
  'form.end_date': 'Дата окончания',
  'form.start_date': 'Дата начала',
  'form.search_anything': 'Искать что угодно',
  'form.invalid_end_date': 'Неверная дата окончания',
  'form.invalid_start_date': 'Неверная дата начала',
  'form.id': 'ID',
  'form.enter_date_range': 'Введите диапазон дат',
  'payments.scan': 'Сканировать QR',
  'print.qr.desc': 'Это уникальный и защищенный QR-код, который можно использовать столько раз, сколько указано. Не царапайте, чтобы он оставался действительным.',
  'print.qr.title': 'QR-код',
  'print.informations': 'Информация',
  'print.notice.folding': 'Инструкции по сгибанию',
  'print.notice.desc2': 'В случае любых вопросов, связанных с этим документом, свяжитесь с поддержкой по телефону или электронной почте.',
  'print.notice.desc1': 'Держите этот документ подальше от воды и огня.',
  'print.notice.title': 'Уведомление',
  'client.extended.openSuchPayment': 'Платеж уже активен для этого пользователя',
  'form.choose_role': 'Выберите роль',
  'create_card_payment.fail_title': 'Платеж в процессе',
  'create_card_payment.fail_text': 'Это может занять некоторое время. Вы получите уведомление по электронной почте, как только процесс будет завершен. Платеж и QR-код будут доступны в разделе “Платежи”.',
  'err.externalId.required': 'Требуется ссылка на платеж',
  'err.placeExternalId.required': 'Требуется внешний ID',
  'form.externalId': 'Ссылка на платеж',
  'form.userExternalId': 'Внешний ID',
  'global.confirm': 'Подтвердить',
  'global.submit': 'Отправить',
  'global.create': 'Создать',
  'global.back': 'Назад',
  'global.close': 'Закрыть',
  'global.contact_support': 'Связаться с поддержкой',
  'global.previous': 'Назад',
  'global.next': 'Далее',
  'global.accept': 'Принять',
  'global.under_dev': 'В разработке',
  'global.logout': 'Выйти',
  'global.support': 'Поддержка',
  'global.consume': 'Использовать',
  'global.validate': 'Подтвердить',
  'global.refund': 'Вернуть средства',
  'global.duplicate': 'Дублировать',
  'global.delete': 'Удалить',
  'global.see_more': 'Показать больше',
  'global.update': 'Обновить',
  'global.modify': 'Изменить',
  'global.download': 'Скачать',
  'global.personal': 'Персональный',
  'global.print': 'Печать',
  'global.print_c7': 'Распечатать билет',
  'global.print_a4': 'Распечатать сертификат',
  'global.checkIn': 'Зарегистрироваться',
  'global.checkOut': 'Выписаться',
  'choose-lang.title': 'Предпочтительный язык',
  'choose-lang.desc': 'Пожалуйста, выберите язык, который будет использоваться в этом приложении.',
  'global.all': 'Все',
  'global.personalNum': 'Персональный номер',
  'global.passportNum': 'Номер паспорта',
  'global.not_available': 'Недоступно',
  'global.show_qr': 'Показать QR-код',
  'global.show_qr_signature': 'QR-код (подписанный)',
  'global.no_results_for': 'Нет результатов для',
  'global.delete_sure': 'Это действие необратимо, вы уверены, что хотите удалить это?',
  'global.add_new': 'Добавить новый',
  'global.add': 'Добавить',
  'global.permissions': 'Разрешения',
  'global.send': 'Отправить',
  'global.block': 'Заблокировать',
  'global.blocked': 'Заблокирован',
  'global.unblock': 'Разблокировать',
  'global.daily_otp': 'Ежедневный код',
  'global.refresh': 'Обновить',
  'global.connect': 'Войти',
  'global.disconnect': 'Отключиться',
  'form.email': 'Электронная почта',
  'form.name': 'Имя',
  'form.firstName': 'Имя (имена)',
  'form.lastName': 'Фамилия',
  'form.password': 'Пароль',
  'form.password2': 'Повторите пароль',
  'form.linkType': 'Как связан заявитель с вами?',
  'form.userId': 'ID пользователя',
  'form.parentId': 'ID родителя',
  'form.amount': 'Сумма',
  'form.currency': 'Валюта',
  'form.metadata': 'Метаданные',
  'form.buyingOption': 'Купленный документ',
  'form.place': 'Место',
  'form.terms': 'Я соглашаюсь с Условиями использования и Политикой конфиденциальности',
  'form.role': 'Роль',
  'form.no_data': 'Нет данных, соответствующих фильтру',
  'form.filter': 'Фильтры',
  'form.phoneNumbers': 'Номера телефонов',
  'form.connectUAPattern': 'Шаблон пользователя-агента',
  'form.connectIPRange': 'Диапазон IP-адресов',
  'form.state': 'Штат',
  'form.type': 'Тип',
  'form.operation': 'Операция',
  'form.created_date': 'Дата создания',
  'form.created_place': 'Место создания',
  'form.consumed_place': 'Место потребления',
  'form.refunded_place': 'Место возврата средств',
  'form.email_code': 'Код из электронной почты',
  'form.addToPlace': 'ID места',
  'form.placeToAdd': 'Место для добавления',
  'form.message': 'Сообщение',
  'form.phone': 'Номер телефона',
  'form.worker': 'ID сотрудника',
  'form.camera': 'Камера',
  'form.comment': 'Комментарий (необязательно)',
  'form.password_old': 'Текущий пароль',
  'form.password_new': 'Новый пароль',
  'form.password_new2': 'Повторите новый пароль',
  'form.idCard': 'Номер ID карты',
  'form.type.CASH': 'Наличные',
  'form.type.CARD': 'Карта',
  'form.currency.EUR': 'Евро',
  'form.currency.USD': 'Доллар США',
  'form.currency.ZAR': 'Рэнд',
  'form.role.customer': 'Клиент',
  'form.role.worker': 'Работник',
  'form.confirmed': 'Подтверждено?',
  'yes': 'Да',
  'no': 'Нет',
  'form.borderPass': 'Выдать пропуск на границу',
  'form.role.orbis_consumer': 'Потребитель Orbis',
  'form.role.admin': 'Администратор',
  'form.choose_currency': 'Выберите валюту',
  'form.choose_place': 'Выберите место',
  'form.choose_type': 'Выберите тип',
  'form.choose_buyingOption': 'Выберите документ',
  'form.choose_camera': 'Выберите камеру',
  'form.perm1': 'Создать QR-код',
  'form.perm2.DYN.pass': 'Использовать QR-код',
  'form.perm2.DYN.zwevisa': 'Использовать заявку на визу',
  'form.perm3.DYN.pass': 'Дублировать / Возврат средств по QR-коду и Z подконтрольных мест',
  'form.perm3.DYN.zwevisa': 'Проверить заявку на визу',
  'form.perm4.DYN.pass': 'Создание/управление пользователями и создание подконтрольных мест',
  'form.perm4.DYN.zwevisa': 'Создание/управление местами, пользователями и визовыми заявками',
  'done.password_changed': 'Пароль изменен',
  'done.user_created': 'Пользователь создан',
  'done.place_created': 'Место создано',
  'done.account_created': 'Аккаунт создан, проверьте электронную почту для подтверждения',
  'client.extended.clientNotAllowed': 'Ваш аккаунт не имеет доступа к этому месту. Проверьте настройки подключения.',
  'err.submit': 'Ошибка при отправке формы',
  'err.email.required': 'Электронная почта обязательна',
  'err.email.email': 'Электронная почта должна быть в формате: email@mail.com',
  'err.password.required': 'Пароль обязателен',
  'err.password.minlength': 'Пароль должен быть длиной от 6 до 20 символов',
  'err.password.maxlength': 'Пароль должен быть длиной от 6 до 20 символов',
  'err.password2.required': 'Необходимо повторное введение пароля',
  'err.password2.mustMatch': 'Пароли не совпадают',
  'err.role.required': 'Роль обязательна',
  'err.name.required': 'Имя обязательно',
  'err.firstName.required': 'Имя (имена) обязательно',
  'err.lastName.required': 'Фамилия обязательна',
  'err.terms.required': 'Необходимо согласие с условиями и политикой',
  'err.amount.required': 'Сумма обязательна',
  'err.currency.required': 'Валюта обязательна',
  'err.metadata.required': 'Метаданные обязательны',
  'err.place.required': 'Место обязательно',
  'err.noSmsPerm': 'Право на отправку SMS не предоставлено',
  'err.noSmsReceive': 'Не удается связаться с чтением SMS',
  'err.code.required': 'Код из электронной почты обязателен',
  'err.buyingOption.required': 'Выбор документа обязателен',
  'err.addToPlace.required': 'ID места обязателен',
  'err.phone.required': 'Номер телефона обязателен',
  'err.message.required': 'Сообщение обязательно',
  'err.text.maxlength': 'Текст слишком длинный',
  'err.old.required': 'Текущий пароль обязателен',
  'err.idCard.required': 'Номер ID карты обязателен',
  'helps.showProceed': 'Показать ход процесса',
  'helps.showScan': 'Показать документ сканирования',
  'helps.captureFingerprint': 'Захватить отпечаток пальца',
  'payment_status.DYN.undefined': 'Текущий статус - не операция',
  'client.extended.missingResourceLogin': 'Не удалось подключиться, неверные учетные данные',
  'client.extended.linkedAccountExists': 'Аккаунт с этим адресом электронной почты уже существует',
  'client.extended.attachedPayments': 'Вы не можете изменить этого человека, поскольку с ним уже связана созданная заявка.',
  'client.extended.badPayload': 'Неправильная информация в запросе',
  'client.extended.placeNotAllowed': 'Место не разрешено',
  'client.extended.locked': 'Аккаунт заблокирован',
  'client.extended.clientBadOtp': 'Неверный ежедневный код',
  'time': 'Время',
  'place': 'Место',
  'home.first': 'Ваш аккаунт подтвержден, вы можете войти в систему.',
  'payOnline': 'Оплатить онлайн',
  'login.sign_in': 'Войти',
  'login.sign_up': 'Зарегистрироваться',
  'login.forgot_password': 'Забыли пароль?',
  'photo.entryCountry': 'Фото при регистрации/выписке',
  'photo.unavailable': 'Фото недоступно',
  'global.entry': 'Вход',
  'global.exit': 'Выход',
  'menu.create_user': 'Пользователь +',
  'menu.create_place': 'Место +',
  'menu.create_payment': 'Платеж +',
  'menu.users': 'Пользователи',
  'menu.places': 'Места',
  'menu.payments': 'Платежи',
  'menu.about': 'О нас',
  'menu.contact': 'Контакты',
  'create_user.title': 'Создать пользователя',
  'create_payment.title': 'Создать платеж',
  'create_place.title': 'Создать место',
  'users.title': 'База данных пользователей',
  'payments.title': 'База данных платежей',
  'payments.employee_graph': 'График платежей по сотрудникам',
  'payments.open_scan': 'Сканировать QR-код',
  'payments.download_csv': 'Скачать CSV',
  'create_card_payment.title': 'Онлайн платеж',
  'create_card_payment.success_title': 'Платеж получен!',
  'create_card_payment.success_text': 'Ваш платеж успешно получен. QR-код был отправлен вам по электронной почте и также доступен, нажав на кнопку ниже.',
  'create_card_payment.no_data': 'Этот платеж больше недоступен здесь, проверьте свою электронную почту, чтобы получить QR-код или выберите “Платежи” в меню',
  'places.title': 'Список мест',
  'forgot_password.title': 'Сброс пароля',
  'forgot_password.title_new': 'Установить новый пароль',
  'forgot_password.sent': 'Подтверждение отправлено!',
  'forgot_password.sent_text': 'Подтверждающее письмо было отправлено с ссылкой для сброса пароля.',
  'forgot_password.done': 'Пароль сброшен',
  'forgot_password.done_text': 'Вы успешно сбросили пароль. Теперь вы можете войти в систему.',
  'welcome.first': 'Ваш аккаунт подтвержден!',
  'welcome.title_1.DYN.pass': 'Привет!',
  'welcome.text_1.DYN.pass': 'Спасибо, что присоединились к {appName}. Мы рады предоставить вам лучший способ конвертировать ваш платеж в защищенный и уникальный QR-код.',
  'welcome.text_1.DYN.zwevisa': 'Спасибо, что присоединились к {appName}. Мы рады предоставить вам лучший способ подать заявку на визу онлайн.',
  'welcome.text_1.1.DYN.pass': 'Давайте узнаем больше о нас, не так ли?',
  'welcome.text_1.1.DYN.zwevisa': 'Давайте узнаем больше о нас, не так ли?',
  'welcome.title_2.DYN.pass': 'Что такое {appName}?',
  'welcome.text_2.DYN.pass': '{appName} позволяет вам оплатить услугу онлайн в обмен на уникальный и защищенный QR-код.',
  'welcome.text_2.DYN.zwevisa': '{appName} позволяет подать заявление на визу онлайн и получить её по электронной почте, а также уникальный защищенный QR-код, содержащий всю необходимую информацию для вашей визы.',
  'welcome.text_2.1.DYN.pass': 'Этот код затем можно использовать для оплаты официального документа, например, удостоверения личности, в местной администрации.',
  'welcome.text_2.1.DYN.zwevisa': 'Этот код можно использовать как доказательство вашего утвержденного заявления на визу на границе.',
  'welcome.title_3': 'Как сотрудник',
  'welcome.text_3.DYN.pass': 'С {appName} клиент может прийти к вам для оплаты официального документа. Вы можете зарегистрировать оплату картой или наличными.',
  'welcome.text_3.DYN.zwevisa': 'С {appName} вы можете регистрировать въезд и выезд людей на границе. Вы также можете утверждать или отклонять заявления на визу в зависимости от ситуации и ваших полномочий.',
  'welcome.text_3.1.DYN.pass': 'После этого генерируется уникальный и защищенный QR-код, который можно распечатать для вашего клиента.',
  'welcome.text_3.1.DYN.zwevisa': 'Вы также можете управлять пользователями и местами вашей организации.',
  'welcome.title_4.DYN.pass': 'И это ещё не всё',
  'welcome.text_4.DYN.pass': 'Вы даже можете регистрировать оплаты оффлайн. Мы уверены в безопасности и надежности нашего способа генерации QR-кодов.',
  'welcome.text_4.DYN.zwevisa': 'Вы даже можете управлять заявлениями на визу оффлайн. Мы уверены в безопасности и надежности нашего способа генерации QR-кодов.',
  'welcome.text_4.1.DYN.pass': 'Как только вы вернетесь онлайн, все данные будут синхронизированы.',
  'welcome.text_4.1.DYN.zwevisa': 'Как только вы вернетесь онлайн, все данные будут синхронизированы.',
  'welcome.title_5.DYN.pass': 'Наслаждайтесь!',
  'welcome.text_5.DYN.pass': 'Спасибо за использование {appName}, и не стесняйтесь отправлять нам отзывы, используя кнопку «Контакт» на главной странице.',
  'payment_status.DYN.zwevisa.PROPOSED': 'Ожидается оплата',
  'payment_status.DYN.zwevisa.PAID': 'Готов к регистрации',
  'payment_status.DYN.zwevisa.PAID_NOT_CONFIRMED': 'Ожидается проверка',
  'payment_status.DYN.zwevisa.USED': 'Завершен',
  'payment_status.DYN.zwevisa.DUPLICATED': 'Дублирован',
  'payment_status.DYN.zwevisa.REFUNDED': 'Возвращено',
  'payment_status.DYN.zwevisa.EXPIRED': 'Отклонен',
  'payment_status.DYN.zwevisa.PENDING': 'Ожидается исправление',
  'payment_status.DYN.zwevisa.DELAY_PAY_NOT_CONFIRMED': 'Оплата позже - не подтверждена',
  'payment_status.DYN.zwevisa.DELAY_PAY_NOT_CONFIRMED.FREE': 'Бесплатно - не подтверждено',
  'payment_status.DYN.zwevisa.DELAY_PAY': 'Готов к оплате по прибытии',
  'payment_status.DYN.pass.PROPOSED': 'Предложено',
  'payment_status.DYN.pass.PAID': 'Оплачено',
  'payment_status.DYN.pass.PAID_NOT_CONFIRMED': 'Не подтверждено',
  'payment_status.DYN.pass.USED': 'Использовано',
  'payment_status.DYN.pass.DUPLICATED': 'Дублирован',
  'payment_status.DYN.pass.REFUNDED': 'Возвращено',
  'payment_status.DYN.pass.EXPIRED': 'Истек',
  'payments.make_bundle': 'Создать пакет',
  'payments.validate': 'Подтвердить',
  'passportPage.askPassport': 'Заявление на получение паспорта',
  'documentContinue.title': 'Продолжить покупки',
  'documentContinue.stop': 'Снова выполнить покупку',
  'documentContinue.desc1': 'Вы уже начали процесс подачи заявления на документ.',
  'documentContinue.desc2': 'Хотите продолжить покупку?',
  'payment.history': 'История платежей',
  'permission.no_place_found': 'У вас нет доступного нового места для этого пользователя',
  'bt.available_devices': 'Доступные Bluetooth-устройства',
  'bt.no_available_devices': 'Доступные Bluetooth-устройства не обнаружены',
  'emailSend.title': 'Подтверждение по электронной почте',
  'emailSend.description': 'Для того, чтобы подтвердить ваш аккаунт, вы должны нажать на ссылку, содержащуюся в письме, отправленном на вашу электронную почту в течение 24 часов.',
  'emailValid.title': 'Аккаунт подтвержден',
  'emailValid.description': 'Ваш аккаунт в {appName} успешно подтвержден. Теперь вы можете войти в свой личный кабинет, используя свои учетные данные.',
  'documentManagement.title': 'Заявление на визу',
  'passportPage.select': 'Выберите людей, которые хотят подать заявление на получение паспорта.',
  'passportPage.choosePerson': 'Выберите заявителя(ей)',
  'preenroll.title': 'Предварительная регистрация',
  'preenroll.description': 'Предварительная регистрация позволяет вам начать первый этап перед завершением оформления документа. Затем вам нужно только посетить одобренный центр для его верификации.',
  'passportEmbassy.title': 'Документ посольства',
  'passportEmbassy.description': 'Посольский паспорт — это документ, выдаваемый суверенным государством своим дипломатам для их деловых поездок, а также сопровождающим их членам.',
  'zwevisa.title': 'Виза',
  'passwordSend.title': 'Подтверждение отправлено',
  'passwordSend.description': 'Вы получили письмо с подтверждением, содержащее ссылку для сброса пароля. У вас есть 24 часа, чтобы нажать на неё.',
  'forgotPassword.title': 'Забыли пароль',
  'forgotPassword.description': 'Чтобы сбросить пароль, введите ниже свою электронную почту.',
  'forgotPassword.description.electron': 'Чтобы сбросить пароль, введите ниже свою электронную почту. Если вы пытаетесь восстановить пароль для учетной записи <b>активного каталога</b>, пожалуйста, свяжитесь с поддержкой.',
  'passwordNew.title': 'Новый пароль',
  'modifiedPassword.title': 'Пароль изменен',
  'modifiedPassword.description': 'Пароль был изменен. Пожалуйста, войдите снова.',
  'people.title.DYN.pass': 'Люди',
  'people.title.DYN.zwevisa': 'Сохраненные заявители',
  'people.addPerson.DYN.pass': 'Добавить человека',
  'people.addPerson.DYN.zwevisa': 'Создать нового заявителя',
  'meansPayment.title': 'Способы оплаты',
  'onlinePayment.title': 'Оплата через PayPal',
  'onlinePayment.description': 'Завершите свою оплату, оплатив онлайн с помощью вашей кредитной карты.',
  'onlinePayment.pay': 'Оплатить',
  'qrPayment.title': 'Оплата с помощью QR-кода',
  'qrPayment.description': 'Для завершения оплаты отсканируйте действующий QR-код.',
  'qrPayment.scan': 'Сканировать QR-код',
  'processPayment.description': 'Для оплаты введите данные вашей кредитной карты.',
  'form.cardNumber': 'Номер карты',
  'form.expireDate': 'Срок действия',
  'successPayment.title': 'Регистрация заявления',
  'successPayment.description1': 'Оплата была произведена. Документ обрабатывается, и вам будет отправлено уведомление, когда он будет готов или если потребуется его пересмотр.',
  'successPayment.description2': 'Пожалуйста, свяжитесь со службой поддержки на странице вашего аккаунта, если вы не получите уведомление.',
  'trackingControl.title.DYN.pass': 'Отслеживание запроса',
  'trackingControl.title.DYN.zwevisa': 'Отслеживание этого заявления',
  'trackingControl.enroll': 'Регистрация готова',
  'trackingControl.treatment': 'Обработка',
  'modifyData': 'Изменить данные',
  'stopModifyData': 'Прекратить изменение данных',
  'trackingControl.revision': 'Запрос на пересмотр',
  'askRevisionProcess': 'Процесс обработки продолжается',
  'askRevision': 'Запрошен пересмотр',
  'seeRevision': 'Просмотр пересмотра',
  'askOrdinaryPassport': 'Запрос на обычный документ',
  'askPassport': 'Запросить документ',
  'askVisa': 'Запросить визу',
  'actualRevision': 'Рассмотрение запроса',
  'askRevision.description': 'Для обработки вашего запроса на обычный паспорт, пожалуйста, отправьте нам копию вашего текущего свидетельства о месте жительства.',
  'peopleNew.title.DYN.pass': 'Новые люди',
  'peopleNew.title.DYN.zwevisa': 'Новый заявитель',
  'peopleFound.title.DYN.pass': 'Найден человек',
  'peopleFound.title.DYN.zwevisa': 'Найден человек',
  'peopleFound.description.DYN.pass': 'Человек, соответствующий введенной вами информации, был добавлен в список сохраненных людей.',
  'peopleFound.description.DYN.zwevisa': 'Человек, соответствующий введенной вами информации, был добавлен в список сохраненных людей.',
  'payment.commandHistory': 'История платежей',
  'menu.placesPerm': 'Места и разрешения',
  'block.message': 'Вы хотите заблокировать этого пользователя?',
  'menu.addPlace': 'Добавить место',
  'label.numberAccount': 'Номер счета',
  'place.sheet.open': 'Открыть карточку места',
  'place.delete': 'Удалить связь с этим местом',
  'place.update.success': 'Место успешно обновлено.',
  'place.new': 'Новое место',
  'place.create': 'Создать место',
  'place.add.ask': 'Вы хотите привязать это место к этому пользователю?',
  'place.add.ok': 'Место успешно привязано к этому пользователю.',
  'place.delete.link': 'Удалить связь с этим местом',
  'place.delete.link.ask': 'Вы уверены, что хотите отменить привязку этого места?',
  'place.delete.link.done': 'Вы успешно удалили привязку к месту.',
  'user.new': 'Новый пользователь',
  'user.create': 'Создать пользователя',
  'user.created': 'Создание нового пользователя прошло успешно. Теперь можно использовать этот аккаунт.',
  'user.sheet.open': 'Открыть карточку пользователя',
  'payment.sheet.open': 'Открыть карточку платежа',
  'err.createplace': 'Не удалось создать. Идентификатор уже существует.',
  'place.parent': 'Родительское место',
  'dropdown.sorting': 'Сортировать',
  'dropdown.qr': 'Сканировать QR-код',
  'dropdown.pass': 'Сканировать документ',
  'err.noReader': 'Читатель не подключен. Пожалуйста, подключите читателя для продолжения.',
  'err.noCard': 'В устройстве не обнаружен действующий биометрический документ. Это может быть связано с использованием небиометрического документа, предъявлением неразрешенной копии или подделки, либо неисправностью антенны для чтения.',
  'err.noConnect': 'Этот документ не соответствует стандартным протоколам ICAO. Это может быть связано с использованием небиометрического документа, предъявлением неразрешенной копии или подделки, либо неисправностью антенны для чтения.',
  'err.noBAC': 'Не удалось установить защищенный канал с документом. Он подлинный? Тщательно проверьте документ вручную и опросите владельца.',
  'err.alteredDoc': 'Поля документа не читаются. Он подлинный? Тщательно проверьте документ вручную и опросите владельца.',
  'err.revokedDocSigner': 'Подписант этого документа был аннулирован его выдающей организацией. Возможно, этот документ был подписан мошенническим способом. Тщательно опросите владельца об этом.',
  'err.unverifiedDoc': 'Подлинность документа не удалось проверить, ошибка в протоколе аутентификации. Это может быть признаком того, что документ был выдан за пределами страны, не по стандартам ICAO, является подделкой или неисправностью антенны для чтения.',
  'err.noPassiveAuthentication': 'Подлинность документа не удалось проверить, ошибка в протоколе аутентификации. Это может быть подделка или неисправность антенны для чтения.',
  'err.noResult': 'Файл не найден',
  'dropdown.user': 'Пользователь',
  'dropdown.meansPayment': 'Виды оплаты',
  'payment.new.DYN.pass': 'Новый платеж',
  'payment.new.DYN.zwevisa': 'Новый выезд без визы',
  'payment.create': 'Создать платеж',
  'payment.created.register': 'Зарегистрированный платеж',
  'payment.created': 'Ваш платеж сохранен и теперь отображается в списке платежей.',
  'payment.created.qr': 'На указанный адрес электронной почты отправлено письмо с сгенерированным QR-кодом.',
  'text.status': 'Статус',
  'phone.number': 'Номер телефона',
  'idcard.number': 'Номер удостоверения личности',
  'form.userCreator': 'Создано пользователем',
  'form.userRefund': 'Возвращено пользователем',
  'form.userConsumed': 'Использовано пользователем',
  'PRICEUP': 'Цена 0-9',
  'PRICEDOWN': 'Цена 9-0',
  'RECENT': 'Самые новые',
  'LATEST': 'Более старые',
  'STATUSUP': 'Статус a-z',
  'STATUSDOWN': 'Статус z-a',
  'payment.success': 'Ваш платеж зарегистрирован',
  'err.meansPayment.required': 'Требуется метод оплаты',
  'payment.consume.ask.DYN.pass': 'Вы действительно хотите использовать этот платеж?',
  'payment.consume.ask.DYN.zwevisa': 'Вы действительно хотите зарегистрировать/выписать этот визовый запрос?',
  'payment.consume.done.DYN.pass': 'Ваш платеж успешно использован',
  'payment.consume.done.DYN.zwevisa': 'Ваш визовый запрос успешно зарегистрирован/выписан',
  'payment.consume.refuse.DYN.zwevisa': 'Этот визовый запрос был успешно отклонен.',
  'checkinOut.refuse': 'Вы действительно хотите отклонить этот запрос?',
  'payment.duplicate.ask': 'Вы действительно хотите дублировать этот платеж?',
  'payment.duplicate.done': 'Ваш платеж успешно дублирован',
  'payment.refund.ask': 'Вы действительно хотите вернуть этот платеж?',
  'payment.refund.done': 'Ваш платеж успешно возвращен',
  'payment.refund.exist': 'Этот платеж уже был возвращен',
  'payment.paid.error': 'Ваш платеж должен быть в статусе «Завершено»',
  'unlink.place.error': 'У вас должно быть хотя бы одно активное место, чтобы отвязать это. Пожалуйста, сначала добавьте другое место',
  'block.done': 'Аккаунт заблокирован',
  'block.unblock': 'Аккаунт разблокирован',
  'dailyOtp.txt': 'Ежедневный код безопасности',
  'place-add.add': 'Добавление места',
  'permission.title': 'Требуется разрешение: Камера',
  'qr.see': 'Посмотреть QR-код',
  'qr.desc.DYN.pass': 'Вот QR-код для платежа. Он появится при печати и будет отправлен на электронный адрес, указанный при регистрации.',
  'qr.desc.DYN.zwevisa': 'Вот QR-код для заявления. Он появится при печати и будет отправлен на электронный адрес, указанный при регистрации.',
  'global.print.a4': 'Распечатать документ A4',
  'dashboard.place.verif': 'Если вы не ввели местоположение, сделайте это',
  'uptodate': 'Вы в курсе',
  'block.yourself': 'Вы не можете заблокировать себя',
  'lastPlace.delete': 'Последнее место в этой учетной записи будет удалено. Это сделает эту учетную запись непригодной для использования. Продолжить?',
  'place.add.done': 'Это место успешно добавлено этому пользователю',
  'notallow.payment': 'У вас нет прав на создание платежа',
  'no.connection': 'Для доступа к этой странице необходимо подключение к интернету. Пожалуйста, войдите снова',
  'place.delete.success': 'Это место было удалено.',
  'wrong.current': 'Вы ввели неверный текущий пароль',
  'no.place': 'Нет места для этой учетной записи.',
  'no.payment': 'Нет платежей, соответствующих текущему фильтру',
  'email.error': 'Похожая электронная почта уже существует',
  'email.notexist': 'Электронная почта не существует',
  'one.payment': 'Должен быть создан хотя бы один платеж и/или применен фильтр.',
  'err.client.extended.uuidUsed': 'Этот платеж уже был использован',
  'no.activecamera': 'Активная камера не найдена',
  'no.accessPermission': 'Эта учетная запись не имеет доступа',
  'download.success': 'Ваш файл был загружен в папку Загрузки',
  'print.name': 'Принтер',
  'print.success': 'Ваш билет напечатан',
  'print.need': 'Сначала необходимо подключиться к принтеру, чтобы иметь возможность печатать',
  'printer.connect': 'Вы подключены к принтеру:',
  'permission.desc': 'Для использования сервиса необходимо разрешение на использование камеры вашего устройства',
  'err.personnalNR.required': 'Требуется личный номер',
  'err.passportNR.required': 'Требуется номер паспорта',
  'createPeople.ask.DYN.pass': 'Вы действительно хотите создать этого человека?',
  'createPeople.ask.DYN.zwevisa': 'Вы действительно хотите создать нового заявителя?',
  'createPeopleDuplicate.ask.DYN.zwevisa': 'Похоже, что человек с таким же именем уже существует в списке зарегистрированных людей. Вы все еще хотите создать этого человека?',
  'createPeople.done.DYN.pass': 'Человек создан',
  'createPeople.done.DYN.zwevisa': 'Человек создан',
  'deletePerson.DYN.pass': 'Удалить этого человека',
  'deletePerson.DYN.zwevisa': 'Удалить этого заявителя',
  'deletePerson.ask.DYN.pass': 'Вы уверены, что хотите удалить этого человека?',
  'deletePerson.ask.DYN.zwevisa': 'Вы уверены, что хотите удалить этого человека?',
  'deletePerson.valid.DYN.pass': 'Человек удален',
  'deletePerson.valid.DYN.zwevisa': 'Человек удален',
  'personModification.ask.DYN.pass': 'Вы уверены, что хотите изменить данные этого человека?',
  'personModification.ask.DYN.zwevisa': 'Вы уверены, что хотите изменить данные этого человека?',
  'personModification.valid.DYN.pass': 'Данные человека изменены',
  'personModification.valid.DYN.zwevisa': 'Данные человека изменены',
  'noPayment': 'Ни одного платежа',
  'selfie.desc': 'Для запроса документа требуется фотография. Пожалуйста, убедитесь, что вы находитесь в хорошем освещении и держите телефон устойчиво, чтобы избежать размытого изображения. Также убедитесь, что все черты вашего лица четко видны на фотографии.',
  'selfie.take': 'Сделать фото',
  'ZWEVISA.title': 'Виза',
  'EMBASSYPASS.title': 'Посольский паспорт',
  'PREENROLL.title': 'Предварительная регистрация',
  'pay.temp': 'Оплата временно недоступна, пожалуйста, выберите предварительную регистрацию',
  'OK': 'Запрос принят. Готово к регистрации',
  'OK_MODIFIED': 'Запрос принят. Обработка продолжается.',
  'DYN.NOK': 'Запрос отклонен',
  'NOK_TO_MODIFY': 'Запрос отклонен. Требуются действия',
  'PROCESSING': 'В процессе обработки',
  'form.merchantReference': 'Ссылка на продавца',
  'form.pan': 'Номер карты',
  'err.MerchantReference.required': 'Требуется ссылка на продавца',
  'err.PAN.required': 'Требуется номер карты',
  'err.ExpiryDate.required': 'Требуется дата окончания срока действия',
  'WIP': 'Работа в процессе',
  'PAYMENT_CREATED': 'Платеж создан',
  'PAYMENT_VALIDATED': 'Платеж подтвержден',
  'menu.requests.DYN.zwevisa': 'Заявления',
  'menu.createRequests.DYN.zwevisa': 'Новое заявление',
  'menu.requests.DYN.pass': 'Запросы',
  'menu.createRequests.DYN.pass': 'Новый запрос',
  'no.data': 'Нет данных, соответствующих текущему фильтру',
  'dropdown.requestState': 'Статус запроса',
  'dropdown.paymentState': 'Статус платежа',
  'one.request': 'Необходимо как минимум одно видимое заявление.',
  'request_state.SUBMITTED': 'Отправлено',
  'request_state.CONFIRMED': 'Подтверждено',
  'request_state.TREATED': 'Обработано',
  'request_state.TRANSFERRED': 'Передано',
  'request_state.CANCELLED': 'Отменено',
  'request_state.REFUSED': 'Отклонено',
  'request_state.UNUSABLE': 'Недействительно',
  'request_state.NULL': 'Неоплачено',
  'request_state.NONE': '-- Без фильтра --',
  'getPayment.error': 'Произошла ошибка при получении платежа.',
  'adminPaymentResult.tilte': 'Результат платежа',
  'ask.disconnect': 'Вы уверены, что хотите выйти?',
  'global.yes': 'Да',
  'zwevisa.type.holiday': 'Отдых',
  'zwevisa.type.business': 'Бизнес',
  'zwevisa.type.conferencing': 'Конференции',
  'zwevisa.type.transit': 'Транзит',
  'zwevisa.type.student': 'Учеба',
  'zwevisa.entry.single': 'Однократная виза',
  'zwevisa.entry.double': 'Двукратная виза',
  'zwevisa.entry.kaza': 'Kaza Univisa',
  'applicant.gender.male': 'Мужской',
  'applicant.gender.female': 'Женский',
  'applicant.gender.other': 'Другой',
  'marital.status.single': 'Холост/Не замужем',
  'marital.status.married': 'Женат/Замужем',
  'marital.status.separated': 'Разделен',
  'marital.status.divorced': 'Разведен(а)',
  'marital.status.widowed': 'Вдовец/Вдова',
  'form.search': 'Поиск',
  'role.customer': 'Клиент',
  'role.partner_registrer': 'Партнер-регистратор',
  'request_state.PROPOSED': 'Ожидает оплаты',
  'request_state.PAID': 'Завершено',
  'request_state.USED': 'Закрыто',
  'request_state.DUPLICATED': 'Дублировано',
  'request_state.REFUNDED': 'Возвращено',
  'request_state.EXPIRED': 'Отклонено',
  'request_state.PAID_NOT_CONFIRMED': 'Ожидает проверки',
  'request_state.DELAY_PAY_NOT_CONFIRMED': 'Оплата позже - не подтверждено',
  'request_state.DELAY_PAY': 'Готово к оплате по прибытии',
  'payment_status.DYN.zwevisa.0': 'Ожидает оплаты',
  'payment_status.DYN.zwevisa.1': 'Готов к регистрации',
  'payment_status.DYN.zwevisa.6': 'Ожидает проверки',
  'payment_status.DYN.zwevisa.2.9': 'Ожидает контроля',
  'payment_status.DYN.zwevisa.2.10': 'Контроль завершен',
  'payment_status.desc.DYN.zwevisa.2.10': 'Заявление было рассмотрено супервайзером и готово к обработке офицером пограничного контроля.',
  'payment_status.desc.DYN.zwevisa.2.9': 'Заявление ожидает контроля. Возможно, супервайзер должен рассмотреть заявление, прежде чем его можно будет обработать офицером пограничного контроля.',
  'payment_status.DYN.zwevisa.7': 'Ожидает исправления',
  'payment_status.DYN.zwevisa.8': 'Оплата позже - не подтверждено',
  'payment_status.DYN.zwevisa.9': 'Готово к оплате по прибытии',
  'payment_status.DYN.zwevisa.9.entry': 'Готово к регистрации',
  'payment_status.DYN.zwevisa.9.exit': 'Готово к выезду',
  'payment_status.DYN.zwevisa.2': 'Выезд завершен',
  'payment_status.DYN.zwevisa.3': 'Дублировано',
  'payment_status.DYN.zwevisa.4': 'Возвращено',
  'payment_status.DYN.zwevisa.5': 'Отклонено',
  'payment_status.extension.DYN.zwevisa.5': 'Продление отклонено',
  'payment_status.DYN.zwevisa.5.blacklisted': 'Отклонено - В черном списке',
  'payment_status.DYN.pass.0': 'Предложено',
  'payment_status.DYN.pass.1': 'Оплачено',
  'payment_status.DYN.pass.6': 'Не подтверждено',
  'payment_status.DYN.pass.2': 'Использовано',
  'payment_status.DYN.pass.3': 'Дублировано',
  'payment_status.DYN.pass.4': 'Возвращено',
  'payment_status.DYN.pass.5': 'Истекло',
  'treatmentDone': 'Обработка завершена. Запрошен пересмотр',
  'sendRevision': 'Пересмотр отправлен',
  'enrolReady': 'Заявление принято. Готово к регистрации',
  'err.personalNr.required': 'Требуется личный номер',
  'err.passportNr.required': 'Требуется номер паспорта',
  'createPeople.success.DYN.pass': 'Человек успешно создан',
  'createPeople.success.DYN.zwevisa': 'Заявитель успешно создан',
  'request.0': 'Заявление принято. Готово к регистрации',
  'request.1': 'Заявление принято. Обработка продолжается',
  'request.2': 'Заявление отклонено',
  'request.3': 'Заявление отклонено. Требуются действия',
  'request.4': 'Обработка продолжается',
  'adminRequestResult.title.DYN.pass': 'Выбранный запрос - {paymentID}',
  'adminRequestResult.title.DYN.zwevisa': 'Выбранное заявление - {paymentID}',
  'form.requestState': 'Статус запроса',
  'form.paymentType': 'Тип платежа',
  'form.paymentState': 'Статус платежа',
  'payment.type.PHYSICAL': 'Физический',
  'payment.type.PAYPAL': 'PayPal',
  'payment.type.WU': 'Western Union',
  'payment.type.MNTA': 'CBZ',
  'payment.type.online': 'Онлайн',
  'payment.type.offline': 'Офлайн',
  'request.state.DYN.pass': 'Статус запроса',
  'request.state.DYN.zwevisa': 'Статус заявления',
  'payment.continue': 'Продолжить оплату',
  'form.birthDate': 'Дата рождения',
  'form.birthCountry': 'Страна рождения',
  'form.placeOfBirth': 'Город рождения',
  'form.residenceAdress': 'Адрес проживания',
  'form.marialStatus': 'Семейное положение',
  'form.dateOfIssue': 'Дата выдачи паспорта',
  'form.dateOfExpiry': 'Дата окончания срока действия паспорта',
  'form.placeOfIssue': 'Место выдачи',
  'form.departureDate': 'Дата выезда',
  'form.arrivalDate': 'Дата прибытия',
  'form.referencePreviousVisa': 'Ссылка на предыдущую визу',
  'form.passportNumber': 'Номер паспорта',
  'form.passportType': 'Тип паспорта',
  'form.hostName': 'Имя хозяина',
  'form.purposeOfVisit': 'Цель визита',
  'form.previousConvictionsHome': 'Предыдущие судимости в стране проживания',
  'form.spouse': 'Супруг(а)',
  'form.SpousePlaceOfBirth': 'Место рождения супруга(и)',
  'form.spouse.birthDay': 'Дата рождения супруга(и)',
  'form.spousePassportNumber': 'Номер паспорта супруга(и)',
  'form.spousePlaceOfBirth': 'Место рождения супруга(и)',
  'form.dependants': 'Зависимые',
  'form.dependantFullName': 'Полное имя зависимого',
  'form.dependantPassportNumber': 'Номер паспорта зависимого',
  'form.dependantPlaceOfBirth': 'Место рождения зависимого',
  'form.dependantBirthDay': 'Дата рождения зависимого',
  'global.approve': 'Одобрить',
  'global.refuse': 'Отклонить',
  'global.suspend': 'Запросить пересмотр',
  'global.unprocess': 'Отменить обработку',
  'personal.data': 'Личные данные',
  'contact.data': 'Контактные данные',
  'divers.data.DYN.zwevisa': 'Детали путешествия',
  'divers.data.DYN.pass': 'Детали запроса',
  'dependant.data': 'Данные зависимых',
  'spouse.data': 'Данные супруга(и)',
  'paymentState.0': 'Ожидает оплаты',
  'paymentState.1': 'Регистрация/выписка',
  'paymentState.2': 'Закрыто',
  'paymentState.3': 'Дублировано',
  'paymentState.4': 'Возвращено',
  'paymentState.5': 'Отклонено',
  'paymentState.6': 'Оплачено - не подтверждено',
  'payment.refuse.ask.DYN.pass': 'Вы действительно хотите <b>ОТКЛОНИТЬ</b> этот платеж?',
  'payment.refuse.ask.DYN.zwevisa': 'Вы действительно хотите <b>ОТКЛОНИТЬ</b> это визовое заявление?',
  'payment.refuse.ask.extension.DYN.zwevisa': 'Вы действительно хотите <b>ОТКЛОНИТЬ</b> этот запрос на продление?',
  'payment.approve.ask.DYN.pass': 'Вы действительно хотите <b>ОДОБРИТЬ</b> этот платеж?',
  'payment.approve.ask.DYN.zwevisa': 'Вы действительно хотите <b>ОДОБРИТЬ</b> это визовое заявление?',
  'payment.approve.ask.extension.DYN.zwevisa': 'Вы действительно хотите <b>ОДОБРИТЬ</b> этот запрос на продление?',
  'payment.review.approve.ask.DYN.zwevisa': 'Вы действительно хотите рассмотреть это визовое заявление как <b>РЕКОМЕНДУЕМОЕ</b>?',
  'payment.review.approve.success.DYN.zwevisa': 'Это визовое заявление было рассмотрено как <b>РЕКОМЕНДУЕМОЕ</b>.',
  'payment.review.approve.success.extension.DYN.zwevisa': 'Этот запрос на продление был рассмотрен как <b>РЕКОМЕНДУЕМОЕ</b>.',
  'payment.review.refuse.ask.DYN.zwevisa': 'Вы действительно хотите рассмотреть это визовое заявление как <b>НЕ РЕКОМЕНДУЕМОЕ</b>?',
  'payment.review.refuse.ask.extension.DYN.zwevisa': 'Вы действительно хотите рассмотреть этот запрос на продление как <b>НЕ РЕКОМЕНДУЕМОЕ</b>?',
  'payment.review.refuse.success.DYN.zwevisa': 'Это визовое заявление было рассмотрено как <b>НЕ РЕКОМЕНДУЕМОЕ</b>.',
  'payment.review.refuse.success.extension.DYN.zwevisa': 'Этот запрос на продление был рассмотрен как <b>НЕ РЕКОМЕНДУЕМОЕ</b>.',
  'payment.review.approve.extension.ask.DYN.zwevisa': 'Вы действительно хотите <b>ОДОБРИТЬ</b> этот запрос на продление?',
  'payment.validate.ask.DYN.pass': 'Вы действительно хотите <b>ОДОБРИТЬ</b> этот платеж?',
  'payment.validate.ask.DYN.zwevisa': 'Вы действительно хотите <b>ОДОБРИТЬ</b> это визовое заявление?',
  'payment.validate.extension.ask.DYN.zwevisa': 'Вы действительно хотите <b>ОДОБРИТЬ</b> этот запрос на продление?',
  'payment.validate.review.ask.DYN.pass': 'Вы действительно хотите <b>ОДОБРИТЬ</b> этот платеж? Запрос на пересмотр будет отправлен следующему рецензенту',
  'payment.validate.review.ask.DYN.zwevisa': 'Вы действительно хотите <b>РЕКОМЕНДОВАТЬ</b> это визовое заявление? Запрос на пересмотр будет отправлен следующему рецензенту',
  'payment.refuse.success.DYN.pass': 'Платеж успешно <b>ОТКЛОНЕН</b>.',
  'payment.refuse.success.DYN.zwevisa': 'Визовое заявление успешно <b>ОТКЛОНЕНО</b>.',
  'payment.refuse.success.extension.DYN.zwevisa': 'Запрос на продление успешно <b>ОТКЛОНЕН</b>.',
  'payment.approve.success.DYN.pass': 'Платеж успешно <b>ОДОБРЕН</b>.',
  'payment.approve.success.DYN.zwevisa': 'Визовое заявление успешно <b>ОДОБРЕНО</b>. Клиент будет уведомлен по электронной почте.',
  'payment.approve.success.extension.DYN.zwevisa': 'Запрос на продление успешно <b>ОДОБРЕН</b>.',
  'payment.suspend.success.DYN.pass': 'Платеж успешно <b>ПРИОСТАНОВЛЕН</b>. Запрос на пересмотр отправлен клиенту.',
  'payment.suspend.success.DYN.zwevisa': 'Визовое заявление успешно <b>ПРИОСТАНОВЛЕНО</b>. Запрос на пересмотр отправлен клиенту.',
  'payment.validate.success.DYN.pass': 'Платеж успешно <b>ОДОБРЕН</b>.',
  'payment.validate.success.DYN.zwevisa': 'Визовое заявление успешно <b>ОДОБРЕНО</b>.',
  'error.payment.refuse.error.DYN.pass': 'Произошла ошибка при отклонении платежа.',
  'error.payment.refuse.error.DYN.zwevisa': 'Произошла ошибка при отклонении визового заявления.',
  'error.payment.suspend.error.DYN.pass': 'Произошла ошибка при приостановке платежа.',
  'error.payment.suspend.error.DYN.zwevisa': 'Произошла ошибка при приостановке визового заявления.',
  'error.payment.validate.error.DYN.pass': 'Произошла ошибка при одобрении платежа.',
  'error.payment.validate.error.DYN.zwevisa': 'Произошла ошибка при одобрении визового заявления.',
  'global.no': 'Нет',
  'global.Yes': 'Да',
  'global.No': 'Нет',
  'zwevisa.type.holiday_visa': 'Отдых',
  'photo.entryContry': 'Фото страны въезда',
  'documents': 'Документы',
  'photo.passport': 'Фото паспорта',
  'data.biographic': 'Биографическая страница паспорта',
  'residence.proof': 'Доказательство проживания',
  'address.destination': 'Адрес назначения',
  'previous.visa': 'Предыдущая виза',
  'invite.letter': 'Пригласительное письмо',
  'residential.status': 'Статус проживания',
  'biometric.data': 'Биометрические данные',
  'hostInvitation.letter': 'Пригласительное письмо хозяина',
  'proof.residenceHost': 'Доказательство проживания хозяина',
  'hostResidential.status': 'Статус проживания хозяина',
  'reasonOfVisit.proof': 'Доказательство причины визита в страну назначения',
  'inviteBusiness.letter': 'Бизнес-письмо компании отправителя',
  'business.letter': 'Бизнес-письмо',
  'business.profile': 'Профессиональный профиль путешественника',
  'application.letter': 'Письмо-заявление на визу',
  'acceptanceFromScool.letter': 'Письмо-подтверждение',
  'date.creation': 'Дата создания',
  'document.Passport': 'Паспорт',
  'completeName': 'Полное имя',
  'price': 'Цена',
  'global.retry': 'Повторить попытку',
  'err.loginWeak': 'Проблема с подключением, попробуйте снова',
  'URGENTPASS.title': 'Срочный паспорт',
  'refusalReason': 'Причина отказа',
  'error.payment.noRefuseReason': 'Пожалуйста, введите сообщение.',
  'error.payment.noSuspendReason': 'Пожалуйста, введите причину приостановки. Эта причина будет отправлена клиенту.',
  'refusal.desc.DYN.pass': 'Этот платеж был отклонен.',
  'refusal.desc.DYN.zwevisa': 'Это визовое заявление было отклонено.',
  'refusal.desc.extension.DYN.zwevisa': 'Этот запрос на продление был отклонен.',
  'refusal.desc.DYN.zwevisa.blacklisted': 'Это визовое заявление было отклонено и занесено в черный список.',
  'approval.desc.DYN.pass': 'Этот платеж был одобрен.',
  'approval.desc.DYN.zwevisa': 'Это визовое заявление было одобрено.',
  'approval.desc.extension.DYN.zwevisa': 'Этот запрос на продление был одобрен.',
  'review.approval.desc.DYN.zwevisa': 'Это визовое заявление соответствует требованиям.',
  'review.approval.desc.extension.DYN.zwevisa': 'Этот запрос на продление соответствует требованиям.',
  'review.refusal.desc.DYN.zwevisa': 'Это визовое заявление не соответствует требованиям.',
  'review.refusal.desc.extension.DYN.zwevisa': 'Этот запрос на продление не соответствует требованиям.',
  'err.getUsers: client.extended.notActiveToken': 'Ваша сессия истекла. Пожалуйста, войдите снова.',
  'forgetPassword.success': 'Ваш пароль был успешно сброшен. Теперь вы можете войти.',
  'VISA_DOUBLE.title': 'Виза для двукратного въезда',
  'VISA_KAZA.title': 'Виза Kaza',
  'history': 'История',
  'noPaymentFilter': 'Нет заявок в обработке',
  'menu.request': 'Заявления',
  'menu.tracking': 'Отслеживание',
  'menu.people.DYN.pass': 'Люди',
  'menu.people.DYN.zwevisa': 'Сохраненные заявители',
  'DYN.AGO': 'Ангола',
  'DYN.ABW': 'Аруба',
  'DYN.ATG': 'Антигуа и Барбуда',
  'DYN.BHS': 'Багамы',
  'DYN.BRB': 'Барбадос',
  'DYN.BLZ': 'Белиз',
  'DYN.BWA': 'Ботсвана',
  'DYN.CYM': 'Каймановы острова',
  'DYN.CYP': 'Кипр',
  'DYN.FJI': 'Фиджи',
  'DYN.COD': 'Демократическая Республика Конго',
  'DYN.GHA': 'Гана',
  'DYN.GRD': 'Гренада',
  'DYN.HKG': 'Гонконг (специальный административный район Китая)',
  'DYN.KEN': 'Кения',
  'DYN.KIR': 'Кирибати',
  'DYN.JAM': 'Ямайка',
  'DYN.LDW': 'Острова Подветренные',
  'DYN.LSO': 'Лесото',
  'DYN.MDG': 'Мадагаскар',
  'DYN.MWI': 'Малави',
  'DYN.MYS': 'Малайзия',
  'DYN.MDV': 'Мальдивы',
  'DYN.MLT': 'Мальта',
  'DYN.MUS': 'Маврикий',
  'DYN.MSR': 'Монтсеррат',
  'DYN.MOZ': 'Мозамбик',
  'DYN.NAM': 'Намибия',
  'DYN.NRU': 'Науру',
  'DYN.LCA': 'Сент-Люсия',
  'DYN.VCT': 'Сент-Винсент и Гренадины',
  'DYN.WSM': 'Западное Самоа',
  'DYN.SYC': 'Сейшельские острова',
  'DYN.SGP': 'Сингапур',
  'DYN.SLB': 'Соломоновы острова',
  'DYN.ZAF': 'Южно-Африканская Республика',
  'DYN.KNA': 'Сент-Китс и Невис',
  'DYN.SWZ': 'Свазиленд',
  'DYN.TON': 'Тонга',
  'DYN.TTO': 'Тринидад и Тобаго',
  'DYN.TCA': 'Острова Теркс и Кайкос',
  'DYN.TUV': 'Тувалу',
  'DYN.UGA': 'Уганда',
  'DYN.TZA': 'Объединенная Республика Танзания',
  'DYN.VUT': 'Вануату',
  'DYN.VAT': 'Ватикан',
  'DYN.VEN': 'Венесуэла (Боливарианская Республика)',
  'DYN.USA': 'Соединенные Штаты Америки',
  'DYN.URY': 'Уругвай',
  'DYN.UZB': 'Узбекистан',
  'DYN.UKR': 'Украина',
  'DYN.ARE': 'Объединенные Арабские Эмираты',
  'DYN.GBR': 'Великобритания',
  'DYN.TUR': 'Турция',
  'DYN.TKM': 'Туркменистан',
  'DYN.SWE': 'Швеция',
  'DYN.CHE': 'Швейцария',
  'DYN.TJK': 'Таджикистан',
  'DYN.KAZ': 'Казахстан',
  'DYN.SUR': 'Суринам',
  'DYN.ESP': 'Испания',
  'DYN.SVK': 'Словакия',
  'DYN.SVN': 'Словения',
  'DYN.SEN': 'Сенегал',
  'DYN.SRB': 'Сербия',
  'DYN.SMR': 'Сан-Марино',
  'DYN.STP': 'Сан-Томе и Принсипи',
  'DYN.NLD': 'Нидерланды',
  'DYN.NCL': 'Новая Каледония',
  'DYN.NZL': 'Новая Зеландия',
  'DYN.NIC': 'Никарагуа',
  'DYN.MEX': 'Мексика',
  'DYN.FSM': 'Федеративные Штаты Микронезии',
  'DYN.MDA': 'Молдова',
  'DYN.MCO': 'Монако',
  'DYN.MHL': 'Маршалловы острова',
  'DYN.LVA': 'Латвия',
  'DYN.NFK': 'Остров Норфолк',
  'DYN.MNP': 'Северные Марианские острова',
  'DYN.NOR': 'Норвегия',
  'DYN.PLW': 'Палау',
  'DYN.PSE': 'Палестина',
  'DYN.PAN': 'Панама',
  'DYN.PNG': 'Папуа-Новая Гвинея',
  'DYN.PRY': 'Парагвай',
  'DYN.PER': 'Перу',
  'DYN.POL': 'Польша',
  'DYN.PRT': 'Португалия',
  'DYN.PRI': 'Пуэрто-Рико',
  'DYN.REU': 'Реюньон',
  'DYN.ROU': 'Румыния',
  'DYN.RUS': 'Россия',
  'DYN.RWA': 'Руанда',
  'DYN.LIE': 'Лихтенштейн',
  'DYN.LTU': 'Литва',
  'DYN.LUX': 'Люксембург',
  'DYN.MAC': 'Макао (специальный административный район Китая)',
  'DYN.JPN': 'Япония',
  'DYN.KWT': 'Кувейт',
  'DYN.KGZ': 'Кыргызстан',
  'DYN.KOR': 'Южная Корея',
  'DYN.GUY': 'Гайана',
  'DYN.HTI': 'Гаити',
  'DYN.HND': 'Гондурас',
  'DYN.IRL': 'Ирландия',
  'DYN.ISR': 'Израиль',
  'DYN.ITA': 'Италия',
  'DYN.HUN': 'Венгрия',
  'DYN.ISL': 'Исландия',
  'DYN.IND': 'Индия',
  'DYN.IDN': 'Индонезия',
  'DYN.IRN': 'Иран',
  'DYN.GUM': 'Гуам',
  'DYN.GTM': 'Гватемала',
  'DYN.GIB': 'Гибралтар',
  'DYN.GRC': 'Греция',
  'DYN.GEO': 'Грузия',
  'DYN.DEU': 'Германия',
  'DYN.DNK': 'Дания',
  'DYN.EST': 'Эстония',
  'DYN.ETH': 'Эфиопия',
  'DYN.FIN': 'Финляндия',
  'DYN.FRA': 'Франция',
  'DYN.GUF': 'Французская Гвиана',
  'DYN.PYF': 'Французская Полинезия',
  'DYN.ATF': 'Южные и Антарктические территории Франции',
  'DYN.CZE': 'Чехия',
  'DYN.COM': 'Коморы',
  'DYN.DOM': 'Доминиканская Республика',
  'DYN.ECU': 'Эквадор',
  'DYN.EGY': 'Египет',
  'DYN.SLV': 'Сальвадор',
  'DYN.GNQ': 'Экваториальная Гвинея',
  'DYN.CHL': 'Чили',
  'DYN.CHN': 'Китай',
  'DYN.CHN_NT': 'Китай (не туристический)',
  'DYN.COK': 'Острова Кука',
  'DYN.CRI': 'Коста-Рика',
  'DYN.HRV': 'Хорватия',
  'DYN.CUB': 'Куба',
  'DYN.BDI': 'Бурунди',
  'DYN.CAN': 'Канада',
  'DYN.CPV': 'Кабо-Верде',
  'DYN.BRA': 'Бразилия',
  'DYN.VGB': 'Британские Виргинские острова',
  'DYN.BRN': 'Бруней-Даруссалам',
  'DYN.BGR': 'Болгария',
  'DYN.BMU': 'Бермуды',
  'DYN.BTN': 'Бутан',
  'DYN.BIH': 'Босния и Герцеговина',
  'DYN.BLR': 'Беларусь',
  'DYN.BEL': 'Бельгия',
  'DYN.BHR': 'Бахрейн',
  'DYN.ARG': 'Аргентина',
  'DYN.ARM': 'Армения',
  'DYN.AUS': 'Австралия',
  'DYN.AUT': 'Австрия',
  'DYN.AZE': 'Азербайджан',
  'DYN.ALB': 'Албания',
  'DYN.DZA': 'Алжир',
  'DYN.AIA': 'Ангилья',
  'DYN.BGD': 'Бангладеш',
  'DYN.BEN': 'Бенин',
  'DYN.AFG': 'Афганистан',
  'DYN.BOL': 'Боливия',
  'DYN.BFA': 'Буркина-Фасо',
  'DYN.KHM': 'Камбоджа',
  'DYN.CMR': 'Камерун',
  'DYN.CIV': 'Кот-д’Ивуар',
  'DYN.CAF': 'Центральноафриканская Республика',
  'DYN.TCD': 'Чад',
  'DYN.COL': 'Колумбия',
  'DYN.ERI': 'Эритрея',
  'DYN.COG': 'Республика Конго',
  'DYN.PRK': 'Корейская Народно-Демократическая Республика',
  'DYN.GAB': 'Габон',
  'DYN.GMB': 'Гамбия',
  'DYN.DJI': 'Джибути',
  'DYN.GIN': 'Гвинея',
  'DYN.GNB': 'Гвинея-Бисау',
  'DYN.IRQ': 'Ирак',
  'DYN.KOS': 'Косово',
  'DYN.LAO': 'Лаос',
  'DYN.JOR': 'Иордания',
  'DYN.LBR': 'Либерия',
  'DYN.LBY': 'Ливийская Арабская Джамахирия',
  'DYN.MLI': 'Мали',
  'DYN.QAT': 'Катар',
  'DYN.REF': 'Беженец',
  'DYN.PHL': 'Филиппины',
  'DYN.OMN': 'Оман',
  'DYN.PAK': 'Пакистан',
  'DYN.LBN': 'Ливан',
  'DYN.MRT': 'Мавритания',
  'DYN.MNG': 'Монголия',
  'DYN.MNE': 'Черногория',
  'DYN.NER': 'Нигер',
  'DYN.NGA': 'Нигерия',
  'DYN.MMR': 'Мьянма',
  'DYN.MAR': 'Марокко',
  'DYN.NPL': 'Непал',
  'DYN.SAU': 'Саудовская Аравия',
  'DYN.SLE': 'Сьерра-Леоне',
  'DYN.SOM': 'Сомали',
  'DYN.LKA': 'Шри-Ланка',
  'DYN.SSD': 'Южный Судан',
  'DYN.SDN': 'Судан',
  'DYN.THA': 'Таиланд',
  'DYN.MKD': 'Бывшая Югославская Республика Македония',
  'DYN.TGO': 'Того',
  'DYN.SYR': 'Сирийская Арабская Республика',
  'DYN.TWN': 'Тайвань',
  'DYN.TUN': 'Тунис',
  'DYN.VNM': 'Вьетнам',
  'DYN.YEM': 'Йемен',
  'DYN.TLS': 'Тимор-Лесте',
  'DYN.AND': 'Андорра',
  'DYN.VIR': 'Виргинские острова (США)',
  'DYN.XKX': 'Косово',
  'DYN.ZWE': 'Зимбабве',
  'DYN.ZMB': 'Замбия',
  'DYN.ASM': 'Американское Самоа',
  'DYN.DMA': 'Доминика',
  'DYN.XXA': 'Лицо без гражданства',
  'DYN.XXB': 'Беженец (Женевская конвенция)',
  'DYN.XXC': 'Беженец (Другие)',
  'DYN.UNA': 'Агентство Организации Объединенных Наций',
  'DYN.UNO': 'Организация Объединенных Наций',
  'err.Occupation.pattern': 'Профессия должна быть в правильном формате',
  'err.PreviousConvictionsHome.pattern': 'Предыдущие судимости в стране проживания должны быть в правильном формате',
  'err.PlaceOfBirth.pattern': 'Место рождения должно быть в правильном формате',
  'PAYMENT_USED': 'Платеж закрыт',
  'document.type.DYN.pass': 'Тип документа',
  'document.type.DYN.zwevisa': 'Тип заявки',
  'filterPayment.error': 'Произошла ошибка при фильтрации запросов. Хотите попробовать снова?',
  'filterPaymentNoFirst.error': 'Произошла ошибка при получении запросов. Хотите попробовать снова?',
  'dependantIndex': 'Зависимый {index}',
  'form.spouseFullName': 'Полное имя супруга',
  'qrDesc.valid': 'Предъявите этот QR-код на пункте визового контроля при въезде/выезде из страны. Вы не можете путешествовать без действительного QR-кода.',
  'qrDesc.invalid': 'Эта визовая заявка в настоящее время недействительна. Вы не можете путешествовать без действительной визы. Пожалуйста, свяжитесь с визовым офисом для получения дополнительной информации.',
  'err.pay': 'Произошла ошибка во время оплаты. Попробуйте снова.',
  'payment.noEvents': 'В настоящее время это заявление не содержит каких-либо событий, связанных с путешествием (использование заявки).',
  'global.cancel': 'Отмена',
  'end.suspend': 'Завершить исправление',
  'visa_application': 'Требования к визовому заявлению',
  'trips.title': 'Отслеживание заявлений',
  'trips.desc': 'Заявление(я)',
  'BatchId': 'Ссылка на заявление',
  'newBatchId': 'Добавить',
  'err.BatchId.required': 'Требуется ссылка на заявление',
  'newBatchId.desc': 'Введите имя для этого путешествия.',
  'ask.newBatchId': 'Пожалуйста, создайте новую ссылку на заявление.',
  'personLinkType.DYN.0': 'Неизвестно',
  'personLinkType.DYN.1': 'Ребенок',
  'personLinkType.DYN.2': 'Родитель',
  'personLinkType.DYN.3': 'Супруг(а)',
  'personLinkType.DYN.4': 'Другоe',
  'personLinkType.DYN.5': 'Я сам',
  'createPeople.error.DYN.pass': 'Произошла ошибка при создании заявителя.',
  'createPeople.error.DYN.zwevisa': 'Произошла ошибка при создании заявителя.',
  'batchIds.desc': 'Заявления, связанные с поездкой {trip} :',
  'trip.name': 'Ссылка на заявление',
  'visaIntro.validFrom': 'Дата начала',
  'visaIntro.validUntil': 'Дата окончания',
  'visaIntro.usageCount': 'Количество разрешенных использований',
  'client.extended.passportNumberAlreadyExists': 'Этот номер паспорта уже используется. Пожалуйста, введите другой.',
  'client.extended.alreadyDecided': 'Вы уже рассматривали эту визовую заявку. Повторное рассмотрение невозможно.',
  'client.extended.outOfUsage': 'Эта заявка <b>вышла за пределы периода действия</b>',
  'global.review': 'Обзор',
  'ask.review.0': 'Выберите свою оценку этой визовой заявки.',
  'ask.review.extension.0': 'Выберите свою оценку этого запроса на продление.',
  'ask.review.1': 'Выберите свою рекомендацию для этой визовой заявки.',
  'ask.review.2': 'Выберите свое окончательное решение для этой визовой заявки.',
  'error.review.noChoice': 'Пожалуйста, выберите состояние обзора, чтобы продолжить.',
  'all.reviews': 'Все обзоры этой визовой заявки',
  'all.reviews.extension': 'Все обзоры этого запроса на продление',
  'validate.desc': 'Эта визовая заявка была одобрена {date} пользователем',
  'validate.extension.desc': 'Этот запрос на продление был одобрен {date} пользователем',
  'refusal.review.desc': 'Эта визовая заявка была отклонена {date} пользователем',
  'refusal.review.extension.desc': 'Этот запрос на продление был отклонен {date} пользователем',
  'refusal.review.desc.blacklisted': 'Эта визовая заявка была отклонена и занесена в черный список {date} пользователем',
  'global.revision': 'Исправление',
  'revison.cancel': 'Отменить исправление',
  'approve.text': 'Эта заявка была одобрена.',
  'refuse.text': 'Эта визовая заявка была отклонена.',
  'pending.review.desc': 'Коррекция этой визовой заявки была запрошена у заявителя {date} пользователем',
  'global.reason': 'Причина',
  'pending.desc': 'Эта визовая заявка в настоящее время ожидает исправления от клиента.',
  'success.onArrival': 'Ваша заявка успешно отправлена. Вы можете увидеть QR-код этой заявки наверху. Пожалуйста, предъявите этот QR-код на пункте визового контроля при въезде/выезде из страны. Вы не можете путешествовать без действительной визовой заявки.',
  'successRequest.title': 'Заявка успешно отправлена',
  'successRequest.title.kiosk': 'Заявка успешно подана',
  'amountDue': 'Цена: ',
  'amountDue.total.now': 'Итоговая сумма сейчас: ',
  'amountDue.total.later': 'Итоговая сумма на стойке офицера: ',
  'noPrint.mobile': 'Печать страницы не поддерживается на мобильных устройствах',
  'err.PassportNumber.required': 'Требуется номер документа (Паспорт, Вид на жительство)',
  'err.FundsAvailable.pattern': 'Доступные средства при въезде должны быть в правильном формате',
  'onArrival.reset': 'Начать новый процесс',
  'onArrival.reset.ask': 'Вы действительно хотите начать новый процесс?',
  'stillHere': 'Вы все еще здесь? Этот процесс сбросится через 20 секунд.',
  'stillHere.successPayment': 'Вы все еще здесь?',
  'print.request': 'Распечатать заявку',
  'EXPIRED.globalExplain': 'Эта заявка была отклонена. Она не соответствует нашим стандартам.',
  'payment_status.desc.DYN.7': 'Эта визовая заявка была рассмотрена как ожидающая. В настоящее время она ожидает исправления от клиента.',
  'payment_status.DYN.zwevisa.6.review': 'Ожидает рассмотрения - {reviewCount}/2',
  'payment_status.desc.DYN.zwevisa.6.review': 'Эта визовая заявка в настоящее время ожидает рассмотрения. Она уже была рассмотрена {reviewCount} раз(а).',
  'payment_status.DYN.zwevisa.6.finalReview': 'Ожидает окончательного рассмотрения',
  'payment_status.desc.DYN.zwevisa.6.finalReview': 'Эта визовая заявка была рассмотрена и в настоящее время ожидает окончательного рассмотрения.',
  'payment_status.desc.DYN.zwevisa.0': 'Эта заявка в настоящее время ожидает проверки оплаты в платежном учреждении. Если платеж еще не был произведен, его можно завершить сейчас.',
  'payment_status.desc.DYN.zwevisa.7': 'Эта визовая заявка ожидает исправления от клиента.',
  'payment_status.desc.DYN.zwevisa.5': 'Эта визовая заявка была отклонена.',
  'payment_status.desc.extension.DYN.zwevisa.5': 'Этот запрос на продление был отклонен.',
  'payment_status.desc.DYN.zwevisa.5.blacklisted': 'Эта визовая заявка была отклонена, и человек был занесен в черный список.',
  'payment_status.desc.DYN.zwevisa.5.checkIn': 'Эта заявка была отклонена при регистрации на стойке офицера.',
  'payment_status.desc.DYN.zwevisa.5.checkIn.reason': 'Эта заявка была отклонена при регистрации на стойке офицера. <p><b>Причина</b>: <br/> {reason}</p>',
  'payment_status.DYN.zwevisa.5.checkIn': 'Отклонено при регистрации',
  'payment_status.desc.DYN.zwevisa.5.checkOut': 'Эта заявка была отклонена при выезде на стойке офицера.',
  'payment_status.DYN.zwevisa.5.checkOut': 'Отклонено при выезде',
  'payment_status.desc.DYN.zwevisa.9': 'Эта визовая заявка готова к оплате на стойке офицера. Регистрация может быть завершена после оплаты.',
  'payment_status.desc.DYN.zwevisa.9.entry': 'Эта заявка готова к регистрации на стойке офицера.',
  'payment_status.desc.DYN.zwevisa.9.exit': 'Эта заявка готова к выезду и требует оплаты за пропуск на границе, выданный на стойке офицера.',
  'payment_status.DYN.zwevisa.2.out': 'Готово к выезду',
  'payment_status.DYN.zwevisa.2.outFinish': 'Выезд завершен',
  'payment_status.DYN.zwevisa.2.closed': 'Закрыто',
  'payment_status.desc.DYN.zwevisa.2.closed': 'Эта заявка была закрыта.',
  'payment_status.desc.DYN.zwevisa.2.zweexit': 'Эта заявка готова к выезду на стойке офицера.',
  'payment_status.DYN.zwevisa.2.entryFinish': 'Регистрация завершена',
  'payment_status.DYN.zwevisa.2.in': 'Готово к регистрации',
  'payment_status.desc.DYN.zwevisa.2.out': 'Эта заявка была зарегистрирована. Следующий шаг - выезд на стойке офицера.',
  'payment_status.desc.DYN.zwevisa.2.outFinish': 'Эта заявка была завершена. Клиент может теперь путешествовать.',
  'payment_status.desc.DYN.zwevisa.2.entryFinish': 'Эта заявка была зарегистрирована. Клиент теперь может въехать в страну.',
  'payment_status.desc.DYN.zwevisa.2.in': 'Эта заявка ожидает регистрации на стойке офицера.',
  'ask.isArrivalPaid': 'Следующий платеж должен быть оплачен сейчас в любой из следующих валют:',
  'ask.isArrivalPaid.exit': 'Граничный пропуск должен быть оплачен сейчас в любой из следующих валют:',
  'ask.isArrivalPaid.confirm': 'Подтверждаете ли вы, что платеж был произведен? Если вы подтверждаете, пожалуйста, введите ссылку на платеж, метод оплаты и используемую валюту. Вы также можете изменить максимальную дату окончания пребывания (Вы можете только уменьшить дату).',
  'ask.isArrivalPaid.confirm.exit': 'Подтверждаете ли вы, что платеж был произведен? Если вы подтверждаете, пожалуйста, введите ссылку на платеж и используемую валюту.',
  'ask.checkoutExpired.confirm': 'Подтверждаете ли вы, что платеж был произведен? Если вы подтверждаете, пожалуйста, введите ссылку на платеж и используемую валюту.',
  'no_trips': 'У вас нет путешествий.',
  'err.canActivate': 'Произошла ошибка',
  'form.passportNumber.dateOfIssue': 'Дата выдачи паспорта',
  'form.passportNumber.dateOfExpiry': 'Дата истечения срока действия паспорта',
  'form.passportNumber.placeOfIssue': 'Место выдачи паспорта',
  'nextOfKin.data': 'Экстренный контакт / Ближайший родственник',
  'form.otherLinkType': 'Укажите тип родственной связи',
  'err.passportNr.pattern': 'Номер паспорта должен иметь правильный формат',
  'err.otherLinkType.required': 'Вы должны указать тип родственной связи',
  'err.otherLinkType.pattern': 'Тип родственной связи должен быть в правильном формате',
  'camera.problem.manual': 'Вы собираетесь зарегистрировать/вывести эту визовую заявку без фотографии клиента. Вы уверены, что хотите продолжить?',
  'camera.problem.button': 'Проблема с камерой?',
  'global.noFilter': '--Без фильтра--',
  'zweentry': 'Въезд без визы',
  'zweentry.title': 'Въезд без визы',
  'zweentry.description': 'Путешественники из выбранных стран могут въезжать без получения визы заранее, обычно для краткосрочного пребывания.',
  'free.charge': 'Бесплатно',
  'camera.init.error': 'Произошла ошибка при инициализации камеры. Вы хотите зарегистрировать/вывести эту заявку без фотографии клиента?',
  'error.payment.noPaymentReference': 'Пожалуйста, введите ссылку на платеж, метод оплаты и используемую валюту.',
  'paymentReference.ask': 'Ссылка на платеж',
  'paymentReference.askExpired': 'Ссылка на платеж за просрочку',
  'session.expired': 'Ваша сессия истекла. Пожалуйста, войдите снова.',
  'error.approveRefuse.DYN.zwevisa': 'Произошла ошибка при одобрении или отклонении визовой заявки.',
  'error.approveRefuse.DYN.pass': 'Произошла ошибка при одобрении или отклонении платежа.',
  'phone.pattern': ' (например: +263771234567)',
  'global.receipt.id': 'ID заявки: {paymentId}',
  'global.preview': 'Предварительный просмотр',
  'ask.place.updatePermissions': 'Вы уверены, что хотите обновить права доступа для этого места?',
  'place.updatePermissions.success': 'Права доступа для этого места были успешно обновлены.',
  'err.place.updatePermissions': 'Произошла ошибка при обновлении прав доступа для этого места.',
  'err.seniority.required': 'Старшинство должно быть числом от 1 до 3.',
  'err.seniority.pattern': 'Старшинство должно быть числом от 1 до 3.',
  'global.urgent': 'Срочно',
  'form.usageAfter': 'Действительно с',
  'form.usageUntil': 'Действительно до',
  'form.localUsageUntil': 'Максимальная дата окончания пребывания',
  'id.payment': 'ID платежа',
  'internalReference': 'Ссылка HQ',
  'payment_status.DYN.zwevisa.6.0': 'Ожидание рассмотрения 0/2',
  'payment_status.DYN.zwevisa.6.1': 'Ожидание рассмотрения 1/2',
  'payment_status.DYN.zwevisa.6.2': 'Ожидание окончательного рассмотрения',
  'payment_status.DYN.zwevisa.2.0': 'Регистрация завершена',
  'payment_status.DYN.zwevisa.2.4': 'Готово к выезду',
  'payment_status.DYN.zwevisa.2.5': 'Выезд завершен',
  'payment_status.DYN.zwevisa.2.6': 'Отклонено при регистрации',
  'payment_status.DYN.zwevisa.2.7': 'Отклонено при выезде',
  'payment_status.DYN.zwevisa.2.8': 'Закрыто',
  'internalReference.create': 'Добавить ссылку HQ',
  'internalReference.edit': 'Редактировать ссылку HQ',
  'internalReference.ask.edit': 'Пожалуйста, введите новую ссылку HQ.',
  'internalReference.ask.create': 'Пожалуйста, введите ссылку HQ.',
  'internalReference.done.create': 'Ссылка HQ была успешно добавлена.',
  'internalReference.done.edit': 'Ссылка HQ была успешно отредактирована.',
  'error.internalReference': 'Произошла ошибка при добавлении/редактировании ссылки HQ.',
  'error.paymentUntil.expired': 'Эта заявка вышла за пределы срока действия. Вы можете продолжить процесс выезда клиента; однако, пожалуйста, учтите, что взимается плата в размере 100 долларов США из-за просроченного статуса визовой заявки. Оплата должна быть произведена немедленно в любой из следующих валют:',
  'internalReference.desc': 'Добавить ссылку HQ к этой заявке (например, 140_2024).',
  'visaApplication.id': 'ID визовой заявки',
  'titlePrint': 'Визовая заявка </br> <b>{paymentID}</b>',
  'titlePrint.extension': 'Запрос на продление </br> <b>{paymentID}</b>',
  'visa.data': 'Данные визы',
  'validated': 'Действительно',
  'globalStatus.awaitingConfirmation': 'Ожидание подтверждения',
  'globalStatus.awaitingVerification': 'Ожидание исправления',
  'globalStatus.refused': 'Отклонено',
  'globalStatus.valid': 'Действительно',
  'globalStatus.draft': 'Ожидание оплаты',
  'PENDING.globalExplain': 'Этот запрос ожидает исправления. Пожалуйста, проверьте информацию и исправьте ее.',
  'PAID_NOT_CONFIRMED.globalExplain': 'Этот запрос ожидает подтверждения нашими службами.',
  'PROPOSED.globalExplain': 'Этот запрос ожидает оплаты. Если вы еще не подтвердили оплату, вы можете сделать это сейчас.',
  'usageValidityConditions': 'Иммиграционные власти оставляют за собой право отменить визу в любое время, если держатель визы не соблюдает связанные с ней условия.',
  'afterWithoutUntil': 'Вы можете въехать с {dateAfter} до {deadlineDate}.',
  'afterWithoutUntilAndMillis': 'Вы можете начать путешествие с {dateAfter}.',
  'afterAndUntil': 'Вы можете начать путешествие с {dateAfter} до {dateUntil}.',
  'photo.current': 'Текущая фотография',
  'photo.scanned': 'Фотография, отсканированная из паспорта',
  'createdAt': 'Дата создания',
  'all.infos': 'Информация о заявке',
  'afterRefused': 'Заявленные даты путешествия: {dateAfter}.',
  'DYN.paymentError.desc.success': `<p>Ваше заявление было успешно зарегистрировано. Если какая-либо часть этого заявления требует одобрения со стороны департамента иммиграции, вы будете уведомлены, как только этот процесс будет завершен. В противном случае знайте, что мы получили ваше заявление.</p>
    <p><b>Спасибо, что воспользовались нашими услугами.</b></p>`,
  'successPayment.kiosk': '<p>Ваша заявка была успешно зарегистрирована. Теперь вы можете пройти к стойке офицера для регистрации.</p><p><b>Спасибо, что выбрали наши услуги.</b></p>',
  'sinceOneWeek': 'Обратите внимание, что эта визовая заявка находится в статусе ожидания рассмотрения более одной недели.',
  'client.extended.onlyAdminDecide': 'Эта визовая заявка уже была рассмотрена сотрудником с таким же уровнем старшинства, как у вас. Повторное рассмотрение невозможно.',
  'error.paymentUntil.before': 'Дата слишком ранняя. Эта заявка будет действительна с {dateAfter}.',
  'applicant.gender.FEMALE': 'Женский',
  'applicant.gender.MALE': 'Мужской',
  'entry.consume': 'Въезды',
  'exit.consume': 'Выезды',
  'scan.noConsumeMode': 'Как пограничный офицер, вы должны управлять как въездами, так и выездами. Пожалуйста, выберите опцию, соответствующую вашей текущей задаче: обработка въездов или управление выездами.',
  'scan.kiosk': 'Для этого человека не найдено записи в статусе "Готов к регистрации". Пожалуйста, попросите человека создать новую заявку в киоске.',
  'scan.office': 'Для этого человека не найдено записи в статусе "Готов к регистрации". Национальность этого человека категории C, что означает, что ему требуется виза. Пожалуйста, сопроводите этого человека в офис, чтобы он срочно получил визу или ему было отказано во въезде.',
  'scan.noVisaFound': 'Для этого человека не найдено записи в статусе "Готов к выезду". Хотите создать запись, готовую к выезду?',
  'zweexit': 'Выезд без визы',
  'err.scanPassport': 'Произошла ошибка при сканировании паспорта. Пожалуйста, убедитесь, что он правильно вставлен, и попробуйте снова. Если проблема сохраняется, перезагрузите приложение или свяжитесь с поддержкой.',
  'exit.register': 'Зарегистрировать выезд',
  'entry.register': 'Зарегистрировать въезд',
  'exit.create': 'Создать выезд без визы',
  'exit.ask': 'Вы уверены, что хотите зарегистрировать выезд без визы для этого человека?',
  'exit.success': 'Выезд без визы успешно зарегистрирован. Теперь вы можете завершить выезд человека.',
  'border': 'Пограничный офицер',
  'global.signout': 'Выйти',
  'search.manually': 'Поиск заявки',
  'scan.applicationQr': 'Сканировать QR заявки',
  'err.client.extended.maxUsageCountReached': 'Достигнуто максимальное количество использований этой заявки.',
  'form.firstName.scanned': 'Отсканированное имя',
  'form.lastName.scanned': 'Отсканированная фамилия',
  'form.documentNumber.scanned': 'Отсканированный номер паспорта',
  'form.gender.scanned': 'Отсканированный пол',
  'form.birthDate.scanned': 'Отсканированная дата рождения',
  'form.nationality.scanned': 'Отсканированное гражданство',
  'scanned.passport': 'Отсканированный паспорт',
  'application.data': 'Данные заявки',
  'no.passportPhoto': 'Нет фотографии',
  'scanned.list.checkIn': 'Вот заявки, связанные с вашим номером паспорта',
  'overstay': 'Превышение срока пребывания',
  'method.payment': 'Метод оплаты',
  'review.desc': 'Одобрить/Отклонить',
  'approval.usageUntil.DYN.zwevisa': 'Изменить дату истечения срока действия (необязательно)',
  'scanned.list': 'Список найденных заявок',
  'err.EmergencyPhone.pattern': 'Номер телефона для экстренной связи должен иметь правильный формат',
  'visa.blacklist': 'Эта визовая заявка была занесена в черный список. Человеку запрещен въезд в страну.',
  'scan.noEntryFound': 'Для этого человека не найдено записи в статусе "Готов к регистрации". Хотите создать запись, готовую к регистрации?',
  'err.EmergencyEmail.pattern': 'Электронная почта для экстренной связи должна иметь правильный формат',
  'global.syncing': 'Синхронизация...',
  'payment.consume.error.DYN.zwevisa': 'Произошла ошибка при обработке заявки.',
  'tooltip.Qr': 'Вы можете напрямую сканировать QR-код, связанный с заявкой, чтобы найти подробную информацию о ней.',
  'tooltip.arrivals': 'Отметьте этот флажок, чтобы управлять въездами как пограничный офицер.',
  'tooltip.departures': 'Отметьте этот флажок, чтобы управлять выездами как пограничный офицер.',
  'tooltip.searchManually': 'Найдите конкретную заявку, используя различные критерии.',
  'tooltip.signout': 'Выйти из приложения.',
  'tooltip.my_account': 'Доступ к настройкам вашей учетной записи.',
  'tooltip.dashboard': 'Доступ к панели управления.',
  'tooltip.createRequests': 'Создание нового заявление.',
  'tooltip.trips': 'Просмотр статуса ваших заявлений и печать связанных документов, если они одобрены.',
  'tooltip.tracking': 'Отслеживание ваших заявлений.',
  'tooltip.people': 'Управление заявителями, связанными с вашей учетной записью.',
  'tooltip.users': 'Управляйте пользователями приложения.',
  'tooltip.requests': 'Управляйте запросами на заявки.',
  'tooltip.places': 'Управляйте местами в приложении.',
  'tooltip.role': 'Ваша роль и имя в приложении.',
  'tooltip.exitRegister': 'Вручную зарегистрировать выезд без визы для человека.',
  'tooltip.entryRegister': 'Вручную зарегистрировать въезд без визы для человека.',
  'tooltip.support': 'Свяжитесь с поддержкой для получения помощи в случае возникновения проблем.',
  'tooltip.chooseLang': 'Изменить язык приложения. В настоящее время доступен только английский.',
  'tooltip.changePassword': 'Изменить ваш пароль.',
  'tooltip.search': 'Отфильтруйте заявки по имени, номеру паспорта, ID платежа или ссылке на заявку.',
  'tooltip.internalReference': 'Отфильтруйте заявки по их внутренней ссылке.',
  'tooltip.internalReferenceNoFilter': 'Нажмите на внутреннюю ссылку, если она доступна, чтобы перейти по связанной ссылке.',
  'tooltip.state': 'Отфильтруйте заявки по их статусу.',
  'tooltip.createdDate': 'Отфильтруйте заявки по дате создания, используя диапазоны от и до.',
  'tooltip.createdDateSince': 'Отфильтруйте заявки по дате создания.',
  'tooltip.overstay': 'Отфильтруйте заявки по тому, превысили ли они срок пребывания в стране.',
  'tooltip.clearAllFilters': 'Очистить все примененные фильтры к заявкам.',
  'tooltip.filterLength': 'Количество заявок, соответствующих примененным фильтрам.',
  'tooltip.downloadCsv': 'Скачать заявки в формате CSV.',
  'tooltip.tablePassportTitle': 'Сортировать заявки по номеру паспорта.',
  'tooltip.tablePassport': 'Номер паспорта, связанный с заявкой',
  'tooltip.tableInternalReferenceTitle': 'Сортировать заявки по внутренней ссылке.',
  'tooltip.tableInternalReference': 'Внутренняя ссылка, связанная с заявкой',
  'tooltip.tableBatchIdTitle': 'Сортировать заявки по ссылке на заявку.',
  'tooltip.tableBatchId': 'Ссылка на заявку, связанная с заявкой',
  'tooltip.tableCreationDateTitle': 'Сортировать заявки по дате создания.',
  'tooltip.tableCreationDate': 'Дата создания заявки',
  'tooltip.tableStateTitle': 'Сортировать заявки по статусу.',
  'tooltip.tableState': 'Статус заявки',
  'tooltip.tableCompleteNameTitle': 'Сортировать заявки по полному имени.',
  'tooltip.tableCompleteName': 'Полное имя человека, связанного с заявкой',
  'tooltip.tablePriceTitle': 'Сортировать заявки по цене.',
  'tooltip.tablePrice': 'Цена заявки',
  'tooltip.tableUsageAfterTitle': 'Сортировать заявки по дате начала действия.',
  'tooltip.tableUsageAfter': 'Дата начала действия заявки',
  'tooltip.tableUsageUntilTitle': 'Сортировать заявки по дате истечения срока действия.',
  'tooltip.tableUsageUntil': 'Дата истечения срока действия заявки',
  'tooltip.tableIdTitle': 'Сортировать заявки по ID.',
  'tooltip.tableId': 'ID заявки',
  'tooltip.tablePerPage': 'Изменить количество заявок, отображаемых на странице.',
  'tooltip.tableNumberOfPage': 'Управление пагинацией и количеством заявок, отображаемых на странице, для облегчения навигации.',
  'tooltip.history': 'История платежей ваших заявок.',
  'tooltip.addPerson': 'Добавить нового человека, связанного с вашей учетной записью.',
  'tooltip.listQr': 'Просмотреть все отфильтрованные заявки и их QR-коды.',
  'tooltip.urgent': 'Это означает, что заявка помечена как срочная и должна быть обработана как можно скорее.',
  'tooltip.operationId': 'Тип заявки.',
  'tooltip.purposeOfVisit': 'Цель визита для этой заявки.',
  'tooltip.amount': 'Цена этой заявки.',
  'tooltip.paymentType': 'Тип оплаты для этой визовой заявки.',
  'tooltip.paymentReference': 'Ссылка на платеж для этой визовой заявки.',
  'tooltip.review': 'Рассмотрите эту визовую заявку как одобренную или отклоненную и добавьте комментарий.',
  'tooltip.revision': 'Запросите у клиента исправление визовой заявки.',
  'tooltip.approve': 'Одобрите эту визовую заявку в качестве окончательного решения. Вы также можете добавить комментарий. Если присутствует термин "в черном списке", человек будет занесен в черный список.',
  'tooltip.refuse': 'Отклоните эту визовую заявку в качестве окончательного решения. Вы также можете добавить комментарий.',
  'tooltip.qrSee': 'Просмотрите QR-код этой заявки и распечатайте связанный с ней документ.',
  'tooltip.internalEdit': 'Редактировать внутреннюю ссылку этой визовой заявки.',
  'tooltip.internalAdd': 'Добавить внутреннюю ссылку к этой заявке.',
  'tooltip.printApplication': 'Распечатать заявку со всей информацией.',
  'tooltip.allReviews': 'Все обзоры этой визовой заявки с указанием даты и пользователя, который выполнил обзор.',
  'tooltip.version': 'Текущая версия приложения.',
  'tooltip.consumeProfile.IN': 'Фотография, сделанная при въезде.',
  'tooltip.consumeNoProfile.IN': 'Фотография при въезде не сделана.',
  'tooltip.consumeTime.IN': 'Дата и время въезда.',
  'tooltip.consumePlace.IN': 'Место въезда.',
  'tooltip.consumeProfile.OUT': 'Фотография, сделанная при выезде.',
  'tooltip.consumeNoProfile.OUT': 'Фотография при выезде не сделана.',
  'tooltip.consumeTime.OUT': 'Дата и время выезда.',
  'tooltip.consumePlace.OUT': 'Место выезда.',
  'tooltip.passportComparison': 'Информация, отсканированная из паспорта. Это позволяет вам сравнить информацию с фактическими данными заявки.',
  'tooltip.classicComparison': 'Фактическая информация заявки. Это позволяет вам сравнить информацию с отсканированной из паспорта.',
  'tooltip.trackingValid': 'Статус вашей заявки. Здесь заявка действительна. Это означает, что вы можете путешествовать с этой заявкой в соответствии с датами ее действия.',
  'tooltip.trackingPending': 'Статус вашей заявки. Здесь заявка ожидает исправлений. Это означает, что вам нужно исправить некоторые данные, прежде чем заявка может быть одобрена.',
  'tooltip.trackingRefused': 'Статус вашей заявки. Здесь заявка отклонена. Это означает, что вы не можете путешествовать с этой заявкой.',
  'tooltip.trackingAwaitingConfirmation': 'Статус вашей заявки. Здесь заявка ожидает подтверждения нашими службами.',
  'tooltip.trackingApplication': 'Заявка, связанная с этой ссылкой на заявку',
  'tooltip.trackingApplicationReference': 'Ссылка на заявку этих заявок',
  'tooltip.allTrackingInfo': 'Вся информация об этой заявке',
  'tooltip.trackingPrint': 'Распечатать документ, связанный с этой заявкой',
  'tooltip.docFirstName': 'Введите имя человека так, как оно указано в паспорте.',
  'tooltip.docLastName': 'Введите фамилию человека так, как она указана в паспорте.',
  'tooltip.docPassportNumber': 'Введите номер паспорта человека.',
  'tooltip.docLinkType': 'Выберите тип родственной связи с этим человеком.',
  'tooltip.docLinkTypeOther': 'Уточните тип родственной связи с этим человеком.',
  'tooltip.applicationReferenceRequest': 'Ссылка на заявление. Она уникальна для каждого заявления и создается автоматически.',
  'tooltip.portEntryRequest': 'Пункт въезда в заявлении. Это место, через которое человек планирует въехать в страну.',
  'tooltip.otherName': 'Введите другое имя заявителя так, как оно указано в паспорте.',
  'tooltip.spouseCheckbox': 'Отметьте этот флажок, если у заявителя есть супруг, и заполните информацию ниже.',
  'tooltip.hostName': 'Имя человека/организации, которая примет заявителя.',
  'tooltip.scanPassport': 'Сканируйте паспорт заявителя, чтобы найти заявки, связанные с этим паспортом. Если заявки не найдены, вы можете создать новую.',
  'tooltip.passportScanned': 'Информация, отсканированная из паспорта.',
  'tooltip.scannedCard': 'Похожие заявки, найденные с отсканированной информацией.',
  'scan.error': 'Произошла ошибка при сканировании документа.',
  'tooltip.webcamProblems': 'Если камера не работает должным образом, вы все равно можете зарегистрировать или завершить заявку без фотографии.',
  'tooltip.checkOut': 'Вывести человека из страны.',
  'tooltip.checkIn': 'Зарегистрировать человека в стране.',
  'tooltip.consumeRefuse': 'Отклоните въезд или выезд человека.',
  'tooltip.finalRefuse': 'Окончательное решение по заявке. Человек не сможет въехать в страну.',
  'tooltip.finalApprove': 'Окончательное решение по заявке. Человек сможет въехать или выехать из страны.',
  'tooltip.finalPending': 'Окончательное решение по заявке. Человек не сможет въехать в страну, пока заявка не будет исправлена и одобрена.',
  'tooltip.finalRefuseBlacklist': 'Окончательное решение по заявке. Человек не сможет въехать в страну и будет занесен в черный список.',
  'ask.updateScannedPassport': 'Хотите обновить информацию заявки отсканированной информацией?',
  'done.updateScannedPassport': 'Информация заявки была успешно обновлена.',
  'error.updateScannedPassport': 'Произошла ошибка при обновлении информации заявки.',
  'photo.entryCountry.IN': 'Фотография при въезде',
  'photo.entryCountry.OUT': 'Фотография при выезде',
  'err.noPassiveAuthenticationCSCA': 'Документ действителен, но не может быть проверен у выдающего органа.',
  'err.OtherName.pattern': 'Другое имя должно содержать только неакцентированные буквы (как строчные, так и прописные), пробелы или дефисы, точно так, как это указано в паспорте.',
  'err.FirstName.pattern': 'Имя должно содержать только неакцентированные буквы (как строчные, так и прописные), пробелы или дефисы, точно так, как это указано в паспорте.',
  'err.LastName.pattern': 'Фамилия должна содержать только неакцентированные буквы (как строчные, так и прописные), пробелы или дефисы, точно так, как это указано в паспорте.',
  'err.PassportNumber.pattern': 'Номер паспорта должен содержать только неакцентированные буквы (как строчные, так и прописные), цифры, пробелы или дефисы, точно так, как это указано в паспорте.',
  'err.passport.expired': 'Срок действия паспорта истек.',
  'externalIndexedData': 'Найдено несколько паспортов',
  'client.extended.chargedBack': 'Эта визовая заявка была возвращена. Лицо может продолжить процесс выезда, но оно должно повторно оплатить въездной сбор только наличными.',
  'ask.chargedBack.confirm': 'Подтверждаете ли вы, что человек повторно оплатил въездной сбор? Если вы подтверждаете, пожалуйста, введите ссылку на платеж и используемую валюту.',
  'error.payment.noReferenceChargedBack': 'Пожалуйста, введите ссылку на платеж и используемую валюту.',
  'client.extended.entryChargedBack': 'Эта визовая заявка была возвращена. Лицо не может продолжить процесс регистрации и должно создать новую запись о въезде.',
  'tooltip.syncing': 'Количество процессов заявок, в настоящее время проходящих синхронизацию.',
  'adChoice': 'Подключение к активному каталогу',
  'form.username': 'Имя пользователя',
  'err.username.required': 'Имя пользователя обязательно',
  'err.username.minlength': 'Имя пользователя должно содержать не менее 4 символов',
  'external.down': 'Служба, от которой зависит наше приложение, в настоящее время не работает. Пожалуйста, попробуйте позже.',
  'global.connection.server': 'Служба недоступна. Извините за неудобства, повторите попытку позже.',
  'global.connection.timeout': 'Время ожидания загрузки вашего запроса истекло. Пожалуйста, проверьте соединение с интернетом и попробуйте снова.',
  'global.connection.problem': 'Ошибка загрузки вашего запроса. Пожалуйста, убедитесь, что вы не используете VPN, брандмауэры, что ваше интернет-соединение стабильно, и попробуйте снова.',
  'err.passport.issuedSoon': 'Паспорт был выдан недавно (менее месяца назад).',
  'global.reload': 'Перезагрузить',
  'error.passportScan': 'CertBytes(b64): {certBytes}',
  'glpi.err.noPassiveAuthenticationCSCA': 'Хэш сертификата паспорта не найден в главном списке ИКАО, что привело к ошибке “err.noPassiveAuthenticationCSCA”.',
  'glpi.err.unverifiedDoc': 'Не удалось получить байты сертификата для пассивной аутентификации; это приводит к ошибке “err.unverifiedDoc”.',
  'glpi.err.noPassiveAuthentication': 'Не удалось выполнить пассивную аутентификацию; это приводит к ошибке “err.noPassiveAuthentication”.',
  'glpi.err.noConnect': 'Не удалось установить соединение с чипом паспорта, что привело к ошибке “err.noConnect”.',
  'glpi.err.noBAC': 'BAC не удалось установить с предоставленной информацией MRZ, что вызвало ошибку “err.noBAC”.',
  'glpi.err.alteredDoc': 'Хеши группы данных (DGS) некорректны для одной или нескольких записей, что приводит к ошибке “err.alteredDoc”.',
  'glpi.err.revokedDocSigner': 'Серийный номер сертификата подписавшего документа найден в списке отзыва сертификатов (CRL), что приводит к ошибке “err.revokedDocSigner”.',
  'watchlistCollapse': 'Найдены списки наблюдения - {categories}',
  'watchlistResponse.level': 'Уровень предупреждения для этой корреспонденции',
  'match.type': 'Тип совпадения',
  'match.hair': 'Цвет волос',
  'match.eyes': 'Цвет глаз',
  'match.height': 'Рост',
  'match.weight': 'Вес',
  'match.race': 'Раса',
  'global.category': 'Категория',
  'watchlist.alertLevel': 'Уровень предупреждения списка наблюдения',
  'watchlist.match': 'Совпадение {numberMatch} | {matchCategory}',
  'Nationalities': 'Гражданство {index}',
  'global.score': 'Оценка',
  'watchlist.name': 'Название списка наблюдения',
  'alias': 'Псевдоним {index}',
  'document.number': 'Номер документа {index}',
  'document.issuer': 'Орган, выдавший документ {index}',
  'document.type': 'Тип документа {index}',
  'dateOfBirth': 'Дата рождения {index}',
  'watchlist.marks': 'Приметы',
  'watchlist.image.link': 'Ссылка на найденное изображение {index}',
  'image.match': 'Совпадение изображения {index}',
  'tooltip.watchlist': 'Информация списка наблюдения, найденная для этого человека.',
  'watchList.category': 'Категория списка наблюдения',
  'err.LastName.maxlength': 'Фамилия должна содержать не более 50 символов',
  'err.FirstName.maxlength': 'Имя должно содержать не более 50 символов',
  'err.OtherName.maxlength': 'Другое имя должно содержать не более 50 символов',
  'err.PlaceOfBirthCity.maxlength': 'Город рождения должен содержать не более 50 символов',
  'err.Phone.maxlength': 'Номер телефона должен содержать не более 20 символов',
  'err.Occupation.maxlength': 'Занятость должна содержать не более 50 символов',
  'err.HostName.maxlength': 'Имя принимающей стороны должно содержать не более 50 символов',
  'err.EmergencyLastName.maxlength': 'Фамилия экстренного контакта должна содержать не более 50 символов',
  'err.EmergencyFirstName.maxlength': 'Имя экстренного контакта должно содержать не более 50 символов',
  'err.EmergencyPhone.maxlength': 'Номер телефона экстренного контакта должен содержать не более 20 символов',
  'err.PlaceofIssue.maxlength': 'Место выдачи должно содержать не более 50 символов',
  'err.SpouseFullName.maxlength': 'Имя супруга должно содержать не более 50 символов',
  'err.SpousePassportNumber.maxlength': 'Номер паспорта супруга должен содержать не более 20 символов',
  'err.SpousePlaceOfBirth.maxlength': 'Место рождения супруга должно содержать не более 50 символов',
  'err.PassportNumber.maxlength': 'Номер паспорта должен содержать не более 20 символов',
  'err.PreviousConvictionsHome.maxlength': 'Предыдущие осуждения на родине должны содержать не более 50 символов',
  'err.FundsAvailable.maxlength': 'Доступные средства при въезде должны содержать не более 50 символов',
  'err.firstName.maxlength': 'Имя должно содержать не более 50 символов',
  'err.lastName.maxlength': 'Фамилия должна содержать не более 50 символов',
  'err.passportNr.maxlength': 'Номер паспорта должен содержать не более 20 символов',
  'err.otherLinkType.maxlength': 'Тип родственной связи должен содержать не более 50 символов',
  'err.id.maxlength': 'Внешний ID должен содержать не более 50 символов',
  'err.name.maxlength': 'Имя должно содержать не более 50 символов',
  'err.password2.maxlength': 'Пароль должен содержать от 6 до 20 символов',
  'err.email.maxlength': 'Электронная почта должна содержать не более 50 символов',
  'err.userId.maxlength': 'ID пользователя должен содержать не более 50 символов',
  'err.phoneNumbersCsv.required': 'Требуется номер телефона',
  'err.idParent.required': 'Требуется ID родителя',
  'err.phoneNumbersCsv.maxlength': 'Номер телефона должен содержать не более 20 символов',
  'watchlist.level': 'Уровень предупреждения',
  'ask.fingerprint': 'Хотите ли вы снять отпечатки пальцев у этого человека?',
  'scan.fingerprint.desc': 'Когда будете готовы, нажмите на кнопку ниже, чтобы начать процесс снятия отпечатков пальцев у человека. Человек должен приложить указательный палец к сканеру, когда загорится красный свет устройства для снятия отпечатков пальцев, как указано офицером.',
  'scan.fingerprint.noResponse': 'Нет ответа от сканера отпечатков пальцев. Приложил ли человек палец к сканеру? Устройство правильно подключено?',
  'scan.fingerprint': 'Отпечаток пальца',
  'fingerprint.scan.start': 'Сканировать отпечаток пальца',
  'scan.fingerprint.success': 'Отпечатки пальцев были успешно отсканированы. Качество сканированного отпечатка пальца {quality}/100. Вы можете повторить процесс, если качество недостаточно.',
  'fingerprint.notAvailable': 'Похоже, что сканер отпечатков пальцев недоступен или драйверы не установлены. Вы все равно можете продолжить регистрацию без отпечатков пальцев.',
  'skip': 'Пропустить',
  'global.checkOut.fingerprint': 'Выезд с отпечатком пальца',
  'global.checkIn.fingerprint': 'Регистрация с отпечатком пальца',
  'tooltip.checkOut.fingerprint': 'Вывести человека из страны с отпечатком пальца.',
  'tooltip.checkIn.fingerprint': 'Зарегистрировать человека в стране с отпечатком пальца.',
  'paymentError.title': 'Проблема с вашим заказом',
  'DYN.paymentError.desc.fail': 'К сожалению, платеж не прошел. Пожалуйста, попробуйте снова.',
  'DYN.paymentError.desc.error': 'К сожалению, при обработке платежа произошла ошибка. Пожалуйста, свяжитесь с нами',
  'DYN.paymentError.desc.later': 'К сожалению, обработка платежа была временно отклонена. Пожалуйста, попробуйте снова.',
  'tooltip.applicationId': 'ID заявки.',
  'client.extended.missingResource': 'Запрашиваемый вами ресурс в настоящее время недоступен. Пожалуйста, попробуйте снова позже.',
  'skip.valid': 'Автоматический пропуск рассмотренных шагов',
  'extension.data': 'Данные о продлении',
  'attestantAddress': 'Адрес свидетеля',
  'attestantEmail': 'Электронная почта свидетеля',
  'attestantID': 'ID свидетеля',
  'attestantName': 'Имя свидетеля',
  'attestantPhone': 'Телефон свидетеля',
  'attestantRelation': 'Связь со свидетелем',
  'daysRequested': 'Запрашиваемое количество дней',
  'tooltip.visaApplicationReference': 'Перейти к визовой заявке, связанной с этим запросом на продление',
  'extend.visaReference': 'Заявка, связанная с этим запросом на продление',
  'data.copyOfEntry': 'Копия въезда',
  'data.departureTicket': 'Билет на выезд',
  'data.attestantDocument': 'Документ свидетеля',
  'data.affidavitCustody': 'Заверение о хранении',
  'reasonForExtention': 'Причина продления',
  'final.ZWEVISAEXTAB': 'Этот запрос на продление должен быть одобрен офицером.',
  'zwevisaExtValid': 'Продление подтверждено',
  'zwevisaExtValid.desc': 'Этот запрос на продление был подтвержден офицером.',
  'zwevisaExtC.review.state': 'Ожидание рассмотрения',
  'zwevisaExtC.review.desc': 'Этот запрос на продление ожидает рассмотрения.',
  'zwevisaExtC.finalReview.desc': 'Этот запрос на продление был рассмотрен и в настоящее время ожидает окончательного рассмотрения.',
  'zwevisaExtC.finalReview.state': 'Ожидание окончательного рассмотрения',
  'err.AttestantName.maxlength': 'Имя свидетеля должно содержать не более 50 символов',
  'tooltip.tableLocalUsageUntilTitle': 'Сортировать заявки по максимальной дате окончания пребывания.',
  'tooltip.tableLocalUsageUntil': 'Дата истечения срока действия заявки относится к максимальной дате окончания пребывания.',
  'ask.usageUntil.confirm': 'Здесь вы можете изменить максимальную дату окончания пребывания. Вы можете только уменьшить дату.',
  'usageUntil.ask': 'Изменить максимальную дату окончания пребывания (необязательно)',
  'error.bad.localUsageUntil': 'Максимальная дата окончания пребывания должна быть раньше текущей даты истечения срока действия и после сегодняшнего дня.',
  'no.limit': 'Без ограничений',
  'to.determined': 'Будет определено',
  'none': 'Нет',
  'change.password.ad': 'Имея учетную запись в активном каталоге, вы не можете изменить свой пароль здесь. Для получения дополнительной информации, пожалуйста, свяжитесь с поддержкой.',
  'noticePrint.title': 'Информация о уведомлении посетителя, распечатанная для клиента',
  'reportingPost': 'Пост отчетности',
  'noticePrint.matTooltip': 'Информация о уведомлении посетителя, распечатанная для клиента во время процесса регистрации',
  'err.paragraph.pattern': 'Поле должно содержать только одну неакцентированную букву.',
  'ask.print': 'Хотите ли вы распечатать уведомление для посетителя или временное уведомление о ограничении?',
  'notice.confirm': 'Вы уверены в информации в уведомлении?',
  'camera.printNotice': 'Распечатать уведомление',
  'global.refusePrint': 'Распечатать и отправить',
  'global.nextPrint': 'Распечатать и продолжить',
  'err.periodFrom.periodFromGreaterThanPeriodTo': 'Период “с” должен быть до периода “до”.',
  'err.externalId.maxlength': 'Внешний ID должен содержать не более 60 символов',
  'payment_status.DYN.pass.9': 'Оплатить позже - Подтверждено',
  'payment.reject.success': 'Платеж успешно отклонен.',
  'payment.reject_reason': 'Причина отклонения',
  'err.fileSize': 'Размер файла слишком велик. Максимальный размер: {size}',
  'scan.birthCertificate': 'Загрузить свидетельство о рождении',
  'err.scanBirthCertificate.required': 'Свидетельство о рождении обязательно',
  'document.zafembpass': 'Паспорт посольства ZAF',
  'preenroll': 'Предварительная регистрация',
  'screen': 'Скрининг',
  'pass': 'Паспорт',
  'zafembpass': 'Паспорт посольства ZAF',
  'document.pass': 'Паспорт',
  'payment.validate.success': 'Оплата успешно подтверждена.',
  'document.screen': 'Скрининг',
  'payment.consume.print.done.DYN.zwevisa': 'Заявка на визу успешно оформлена. Квитанция будет распечатана.',
  'payment.ask.print': 'Квитанция напечатана?',
  'global.no.retry': 'Нет (повторить)',
  'print.receipt': 'Печать квитанции',
  'tooltip.printReceipt': 'Распечатать квитанцию(и) заявки',
  'ask.printReceipt': 'Хотите распечатать квитанцию от <b>{date}</b>?',
  'done.printReceipt': 'Квитанция успешно распечатана.',
  'error.printReceipt': 'Произошла ошибка при печати квитанции. Попробуйте еще раз.',
  'ask.selectReceipt': 'Пожалуйста, выберите квитанцию для печати.',
  'error.noReceiptSelected': 'Пожалуйста, выберите квитанцию для печати.',
  'operation.OUT': 'Выезд',
  'operation.IN': 'Въезд',
  'noChoice.selectedReceipt': 'Пожалуйста, выберите квитанцию для печати.',
  'select.receipt': 'Выбрать квитанцию',
  'receiptFor': 'Квитанция для',
  'receiptFor.alt': 'Квитанция для',
  'paymentType': 'Тип оплаты',
  'totalAmount.alt': 'Итоговая сумма',
  'totalAmount': 'Итоговая сумма',
  'totalAmountCurrency': 'Итог в оплаченной валюте',
  'electronic.receipt': 'Квитанция',
  'date': 'Дата',
  'client': 'Клиент',
  'reference': 'Референс',
  'agentID': 'ID агента',
  'desc': 'Описание',
  'details': 'Детали',
  'client.extended.minUsageNotMet': 'Дата окончания действия должна быть как минимум на 1 день позже даты начала действия.',
  'err.BirthDay.minDate': 'Дата рождения должна быть менее 150 лет назад.',
  'err.SpouseBirthDay.minDate': 'Дата рождения супруга должна быть менее 150 лет назад.',
  'payment.assigned.warning': 'Эта заявка в настоящее время используется другим офицером (Офицер: {officerId}) менее 24 часов назад. Вы действительно хотите открыть эту заявку?',
  'lock': 'Заблокировать',
  'tooltip.tableLockTitle': 'Активное использование офицером(ами)',
  'tooltip.tableLock.me': 'Вы в настоящее время являетесь активным офицером по этой заявке',
  'tooltip.tableLock.other': 'Эта заявка в настоящее время используется офицером {officer}',
  'client.extended.uuidNotPaid': 'Оплата еще не подтверждена. Пожалуйста, подтвердите оплату перед продолжением.',
  'form.operationComments': 'Обосновать операцию',
  'err.operationComments.required': 'Требуется обоснование операции',
  'err.operationComments.maxlength': 'Обоснование операции должно быть не более 500 символов',
  'err.DateOfExpiry.maxDate': 'Дата окончания срока действия должна быть менее чем через 10 лет.',
  'err.ArrivalDate.maxDate': 'Дата прибытия должна быть менее 150 лет назад.',
  'err.DepartureDate.maxDate': 'Дата отъезда должна быть менее 150 лет назад.',
  'err.periodFrom.minDate': 'Начало периода должно быть позже сегодняшнего дня.',
  'err.periodFrom.maxDate': 'Начало периода должно быть менее 150 лет назад.',
  'err.periodTo.minDate': 'Конец периода должен быть позже сегодняшнего дня.',
  'err.periodTo.maxDate': 'Конец периода должен быть менее 150 лет назад.',
  'err.reportOn.minDate': 'Дата отчета должна быть позже сегодняшнего дня.',
  'err.reportOn.maxDate': 'Дата отчета должна быть менее 150 лет назад.',
  'err.PlaceofIssue.required': 'Место выдачи обязательно',
  'err.borderPass.required': 'Пограничный пропуск обязателен',
  'exit.borderPass.success': 'Выезд без визы успешно зарегистрирован. Вы будете перенаправлены на страницу с подробностями заявки.',
  'borderPass.emit': 'Пограничный пропуск выдан',
  'tooltip.borderPass': 'Пограничный пропуск — это документ, который позволяет человеку пересечь границу. Он используется, когда у человека нет паспорта.',
  'zweexit.title': 'Выезд без визы',
  'borderPass.title': 'Пограничный пропуск',
  'print.borderPass': 'Распечатать пограничный пропуск',
  'print.borderPass.ask': 'Пограничный пропуск напечатан?',
  'err.borderPassNumber.maxlength': 'Номер пограничного пропуска должен быть не более 20 символов',
  'err.issueDate.afterExpiryDate': 'Дата выдачи должна быть до даты истечения срока действия.',
  'err.BirthDay.afterExpiryDate': 'Дата рождения должна быть до даты истечения срока действия.',
  'err.BirthDay.afterIssueDate': 'Дата рождения должна быть до даты выдачи.',
  'apiPnrCollapse': 'Найден PNR',
  'apipnrResponse.match': 'Совпадение {numberMatch}',
  'match.name': 'Имя',
  'match.score.name': 'Оценка имени',
  'match.flightCarrier': 'Авиаперевозчик',
  'match.flightNumber': 'Номер рейса',
  'match.documentType': 'Тип документа',
  'match.documentNumber': 'Номер документа',
  'match.documentIssuer': 'Эмитент документа',
  'match.timeType': 'Тип события',
  'match.timeDate': 'Дата события',
  'match.timeScope': 'Период события',
  'match.nationality': 'Гражданство',
  'match.gender': 'Пол',
  'match.uuid': 'UUID',
  'match.travelerUUID': 'UUID путешественника',
  'tooltip.apiPnr': 'Информация PNR, найденная для этого человека.',
  'match.documentIssued': 'Дата выдачи документа',
  'match.documentExpiration': 'Дата истечения срока действия документа',
  'match.documentScore': 'Оценка документа',
  'match.timeScore': 'Оценка события',
  'match.flightOriginDomestic': 'Внутреннее происхождение рейса',
  'match.flightOriginIcao': 'ICAO места отправления рейса',
  'match.flightOriginLat': 'Широта места отправления рейса',
  'match.flightOriginLng': 'Долгота места отправления рейса',
  'match.flightOriginIata': 'IATA места отправления рейса',
  'match.flightOriginCity': 'Город места отправления рейса',
  'match.flightOriginState': 'Штат места отправления рейса',
  'match.flightOriginCountry': 'Страна места отправления рейса',
  'match.flightOriginContinent': 'Континент места отправления рейса',
  'match.flightOriginName': 'Название места отправления рейса',
  'match.flightOriginTimezone': 'Часовой пояс места отправления рейса',
  'match.flightDestinationDomestic': 'Внутреннее назначение рейса',
  'match.flightDestinationIcao': 'ICAO места назначения рейса',
  'match.flightDestinationLat': 'Широта места назначения рейса',
  'match.flightDestinationLng': 'Долгота места назначения рейса',
  'match.flightDestinationIata': 'IATA места назначения рейса',
  'match.flightDestinationCity': 'Город места назначения рейса',
  'match.flightDestinationState': 'Штат места назначения рейса',
  'match.flightDestinationCountry': 'Страна места назначения рейса',
  'match.flightDestinationContinent': 'Континент места назначения рейса',
  'match.flightDestinationName': 'Название места назначения рейса',
  'match.flightDestinationTimezone': 'Часовой пояс места назначения рейса',
  'match.localDepartureDate': 'Местная дата отправления',
  'match.localDepartureTime': 'Местное время отправления',
  'match.localDepartureTotalMinutes': 'Общее количество минут местного отправления',
  'match.localDepartureDay': 'День местного отправления',
  'match.localDepartureZone': 'Зона местного отправления',
  'match.airportDepartureDate': 'Дата отправления из аэропорта',
  'match.airportDepartureTime': 'Время отправления из аэропорта',
  'match.airportDepartureTotalMinutes': 'Общее количество минут отправления из аэропорта',
  'match.airportDepartureDay': 'День отправления из аэропорта',
  'match.airportDepartureZone': 'Зона отправления из аэропорта',
  'match.utcDepartureDate': 'Дата отправления UTC',
  'match.utcDepartureTime': 'Время отправления UTC',
  'match.utcDepartureTotalMinutes': 'Общее количество минут отправления UTC',
  'match.utcDepartureDay': 'День отправления UTC',
  'match.utcDepartureZone': 'Зона отправления UTC',
  'match.originalDeparture': 'Первоначальное отправление',
  'match.flightDepartureUtcstr': 'Отправление рейса по UTC',
  'match.localArrivalDate': 'Местная дата прибытия',
  'match.localArrivalTime': 'Местное время прибытия',
  'match.localArrivalTotalMinutes': 'Общее количество минут местного прибытия',
  'match.localArrivalDay': 'День местного прибытия',
  'match.localArrivalZone': 'Зона местного прибытия',
  'match.airportArrivalDate': 'Дата прибытия в аэропорт',
  'match.airportArrivalTime': 'Время прибытия в аэропорт',
  'match.airportArrivalTotalMinutes': 'Общее количество минут прибытия в аэропорт',
  'match.airportArrivalDay': 'День прибытия в аэропорт',
  'match.airportArrivalZone': 'Зона прибытия в аэропорт',
  'match.utcArrivalDate': 'Дата прибытия UTC',
  'match.utcArrivalTime': 'Время прибытия UTC',
  'match.utcArrivalTotalMinutes': 'Общее количество минут прибытия UTC',
  'match.utcArrivalDay': 'День прибытия UTC',
  'match.utcArrivalZone': 'Зона прибытия UTC',
  'match.originalArrival': 'Первоначальное прибытие',
  'match.flightArrivalUtcstr': 'Прибытие рейса по UTC',
  'match.flightBound': 'Направление рейса',
  'print.receipt.select': 'Квитанция будет напечатана.',
  'ask.notice': 'Распечатать уведомление посетителю или временное уведомление об ограничении',
  'tooltip.checkIn.notice': 'Въезд человека в страну с печатью уведомления посетителю или временного уведомления об ограничении.',
  'global.checkIn.notice': 'Въезд с уведомлением',
  'event.downgrade': 'Эта заявка была понижена с <b>{previousPayment}</b> до <b>{newPayment}</b> {date} пользователем <b>{userId}</b>.',
  'form.numberPeople': 'Количество людей, путешествующих с заявителем',
  'feedbackForm': 'Форма обратной связи',
  'form.totalSpend': 'Общая сумма расходов за время пребывания',
  'countryPermanentResidence': 'Страна постоянного проживания',
  'countryDestination': 'Страна назначения',
  'form.feedbackComments': 'Дополнительные комментарии',
  'feedback.submit': 'Отправить отзыв',
  'err.fundsSpend.required': 'Общая сумма расходов за время пребывания обязательна',
  'err.fundsCurrency.required': 'Валюта расходов обязательна',
  'err.countryResidence.required': 'Страна постоянного проживания обязательна',
  'err.countryDestination.required': 'Страна назначения обязательна',
  'err.feedbackComment.required': 'Дополнительные комментарии обязательны',
  'feedback.submitted': 'Отзыв успешно отправлен.',
  'start_feedback': 'Оставить отзыв',
  'start': 'Начать',
  // Trads lib
  'demoMode.on': 'В настоящее время в демонстрационном режиме',
  'demoMode.off': 'Включить демонстрационный режим',
  'alreadyAccount': 'Уже есть учетная запись?',
  'close': 'Закрыть',
  'createAccount': 'Создать учетную запись',
  'err.accountValidated': 'Ваш аккаунт уже подтвержден',
  'err.confirm': 'Невозможно подтвердить электронную почту',
  'err.consumeLogin': 'Ошибка при входе в систему',
  'err.consumeLoginWeak': 'Не удалось войти в систему',
  'err.country.required': 'Страна обязательна',
  'err.err': 'Ошибка',
  'err.firstName.pattern': 'Имя не соответствует шаблону',
  'err.gender.required': 'Пол обязателен',
  'err.info': 'Ошибка при получении информации о пользователе',
  'err.lastName.pattern': 'Фамилия не соответствует шаблону',
  'err.middleName.pattern': 'Отчество не соответствует шаблону',
  'err.middleName.required': 'Отчество обязательно',
  'err.nationality.pattern': 'Гражданство не соответствует шаблону',
  'err.nationality.required': 'Гражданство обязательно',
  'err.noEncryptedPDF': 'Документ не может быть прочитан как PDF или защищен',
  'err.otp.required': 'Требуется код подтверждения (OTP 1)',
  'err.otp2.required': 'Требуется код подтверждения (OTP 2)',
  'err.profession.pattern': 'Профессия не соответствует шаблону',
  'err.profession.required': 'Профессия обязательна',
  'err.rcs.required': 'Требуется номер компании',
  'err.rearm': 'Невозможно повторно отправить письмо для подтверждения',
  'err.register': 'Не удалось создать учетную запись',
  'err.register2FA': 'Ошибка регистрации 2FA',
  'err.requiredField': 'Необходимо заполнить обязательное поле, повторите попытку',
  'err.reset': 'Не удалось запросить код сброса',
  'err.resetPassword': 'Не удалось сбросить пароль',
  'err.street.required': 'Название улицы обязательно',
  'err.streetNumber.required': 'Номер дома обязателен',
  'err.tenantLang.required': 'Язык обязателен',
  'err.zip.required': 'Почтовый индекс обязателен',
  'form.address': 'Адрес',
  'form.birthPlace': 'Место рождения',
  'form.canNr': 'Номер CAN',
  'form.choose_country': 'Выбрать страну',
  'form.choose_gmt': 'Выбрать часовой пояс',
  'form.choose_lang': 'Выбрать язык',
  'form.choose_nationality': 'Выбрать гражданство',
  'form.city': 'Город',
  'form.passportNr': 'Номер паспорта',
  'form.code': 'Код восстановления',
  'form.connectIpRange': 'Диапазон IP',
  'form.connectUaPattern': 'Шаблон UA',
  'form.country': 'Страна',
  'form.country.BEL': 'Бельгия',
  'form.country.CIV': 'Кот-д’Ивуар',
  'form.country.LUX': 'Люксембург',
  'form.country.ZWE': 'Зимбабве',
  'form.dateFrom': 'С',
  'form.dateTo': 'по',
  'form.docNr': 'Номер документа',
  'form.expiryDate': 'Дата окончания',
  'form.gender': 'Пол',
  'form.gender.female': 'Женский',
  'form.gender.male': 'Мужской',
  'form.gmt': 'Часовой пояс',
  'form.gmtShiftMinutes.DYN-360': '-6 (Нью-Йорк, …)',
  'form.gmtShiftMinutes.DYN-540': '-9 (Сан-Франциско, …)',
  'form.gmtShiftMinutes.DYN0': '+0 (Лондон, …)',
  'form.gmtShiftMinutes.DYN60': '+1 (Люксембург, Париж, Брюссель, …)',
  'form.height': 'Рост в см',
  'form.identifier': 'Идентификатор пользователя',
  'form.lang': 'Язык',
  'form.lang.EN': 'English',
  'form.lang.FR': 'Français',
  'form.lang.IT': 'Italiano',
  'form.lang.JA': '日本語',
  'form.lang.DE': 'Deutsch',
  'form.lang.CN': '中国人',
  'form.lang.RU': 'Pусский',
  'form.middleName': 'Отчество',
  'form.mrz': 'MRZ',
  'form.nationality': 'Гражданство',
  'form.nationality.BEL': 'Бельгиец',
  'form.nationality.CIV': 'Ивуариец',
  'form.nationality.LUX': 'Люксембуржец',
  'form.nationality.ZWE': 'Зимбабвиец',
  'form.personalNr': 'Личный номер',
  'form.phoneNumber': 'Номер телефона',
  'form.profession': 'Профессия',
  'form.rcs': 'Номер RCS',
  'form.street': 'Название улицы',
  'form.streetNr': 'Номер дома',
  'form.tenantLang.DYN0': 'Английский',
  'form.tenantLang.DYN1': 'Французский',
  'form.tva': 'Номер НДС',
  'form.user': 'Пользователь',
  'form.zip': 'Почтовый индекс',
  'global.clear': 'Очистить',
  'global.notAccount': 'Нет учетной записи?',
  'global.continue': 'Продолжить',
  'global.error': 'Произошла ошибка, повторите попытку',
  'global.more': 'Подробнее',
  'global.changePassword': 'Сменить пароль',
  'global.faq': 'Часто задаваемые вопросы',
  'global.myDatas': 'Мои данные',
  'global.history': 'История',
  'global.see': 'Посмотреть',
  'global.ddlDoc': 'Скачать документ',
  'global.no_data_available': 'Нет доступных данных',
  'global.not_yet_implemented.desc': 'К сожалению, эта страница еще не реализована',
  'global.not_yet_implemented.title': 'Какой сюрприз!',
  'global.save': 'Сохранить',
  'global.search': 'Поиск',
  'global.receipt': 'Квитанция',
  'global.ddlReceipt': 'Скачать квитанцию',
  'global.fileSend': 'Выбрать файл(ы)',
  'global.share': 'Поделиться',
  'global.ready': 'Готово',
  'global.start': 'Начать',
  'infinite': 'Бесконечность',
  'login.back_sign_in': 'Вернуться к входу',
  'login.code': 'код',
  'login.create_account': 'Создать учетную запись',
  'login.didReset': 'Пароль изменен, теперь вы можете войти',
  'login.email_verification': 'Подтверждение электронной почты',
  'login.email_verification2': 'Отправлено',
  'login.email_verification_text': 'Спасибо за регистрацию. Для подтверждения аккаунта и продолжения регистрации нажмите на ссылку, отправленную на вашу электронную почту',
  'login.forInvite': 'При входе в систему вы используете следующее приглашение:',
  'login.forgot': 'Забыли',
  'login.forgot_sent': 'Электронное письмо',
  'login.forgot_sent2': 'отправлено',
  'login.forgot_sent_text': 'Ваш запрос на сброс пароля был отправлен на электронную почту',
  'login.has_account': 'Существующий пользователь',
  'login.in': 'в',
  'login.invalid': 'Неверное имя пользователя или пароль, повторите попытку!',
  'login.inviteDone': 'Приглашение в компанию использовано',
  'login.lost': 'Потерян',
  'login.lost_otp': 'Потерян OTP',
  'login.lost_otp_text': 'Если у вас нет доступа к Google Authenticator (потерян или заменен телефон, например, удалено приложение), вы не сможете войти в DocSeal. Это абсолютно нормально, так как это часть нашей политики безопасности. Нажмите ниже, чтобы связаться с поддержкой, которая проверит вашу личность и поможет восстановить полный доступ к вашей учетной записи.',
  'login.noInviteDone': 'Приглашение не может быть использовано, оно истекло или уже использовано?',
  'login.otp': 'проверка',
  'login.password': 'Пароль',
  'login.registered': 'Учетная запись зарегистрирована, проверьте свою электронную почту перед входом!',
  'login.reset': 'Код сброса отправлен, проверьте свою почту',
  'login.setPassword': 'Изменить пароль',
  'login.setPasswordLong': 'Выберите удобный для входа пароль',
  'login.sign': 'Подпись',
  'login.to_sign_in': 'Вернуться к входу',
  'login.up': 'вверх',
  'nan': 'Недоступно',
  'sign.prepared': 'Документ готовится',
  'signIn.forgotPassword': 'Забыли пароль?',
  'signIn.title': 'Доступ',
  'signInPro.title': 'Профессиональный доступ',
  'signUp.title': 'Создать учетную запись',
  'global.reset': 'Сбросить'
  //
};
