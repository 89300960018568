<div id="layout" class="sign_in f_col">
    <app-view-title fromRoute="pre-enroll" [chooseLangBtn]="false">{{'scan.fingerprint' | translate}}</app-view-title>
    <div class="scroll_wrapper resp_wrapper">
      <div class="resp_c-50 resp_scroll-wrapper_l">
        <app-view-deco-image imageUrl="assets-projects/fingerprint.svg"></app-view-deco-image>
        <app-paragraph *ngIf="!qualityFingerPrint" class="mb_10">{{'scan.fingerprint.desc' | translate}}</app-paragraph>
        <app-paragraph *ngIf="qualityFingerPrint" class="mb_10">{{'scan.fingerprint.success' | translate : {quality: qualityFingerPrint} }}</app-paragraph>
        <div class="flex f_col mb_20 btn-scan">
          <div class="flex gap_10 f_align_c gap_5">
            <div class="flex f_col fullWidth f_align_c gap_5">
              <div class="flex fullWidth gap_5">
                <app-button size="xsm" color="transparent" border="true"  type="button" (click)="nav.to('admin-request-select/' + query.paymentId, undefined, {queryParams: backParams})" class="fullWidth resp_minSize_l">{{'global.back' | translate}}</app-button>
                <app-button class="btn-skip-fingerprint" size="xsm" type="button" (click)="skipFingerPrint()" class="fullWidth  resp_minSize_l">{{'skip' | translate}}</app-button>
                <app-button class="btn-fingerprint" *ngIf="!qualityFingerPrint" size="xsm" type="button" (click)="scanFingerprint()" class="fullWidth  resp_minSize_l">{{'fingerprint.scan.start' | translate}}</app-button>
                <app-button color="red" class="btn-fingerprint" *ngIf="qualityFingerPrint" size="xsm" type="button" (click)="retryFingerPrint()" class="fullWidth  resp_minSize_l">{{'global.retry' | translate}}</app-button>
              </div>
              <app-button color="green" *ngIf="qualityFingerPrint" size="xsm" type="button" (click)="confirmFingerprint()" class="fullWidth resp_minSize_l btn-fingerprint-confirm">{{'global.confirm' | translate}}</app-button>
            </div>
            </div>  
            <app-checkbox-element *ngIf="query.operation === OperationType.IN || query.operation === OperationType.INRETURNED" class="mt_10" text="ask.notice" formCtrlName="checkNotice" [formGrp]="form"></app-checkbox-element>
            <app-checkbox-element *ngIf="query.operation === OperationType.REFUSEIN || query.operation === OperationType.REFUSEOUT" class="mt_10" text="ask.refuseCamera" formCtrlName="refuseCamera" [formGrp]="form"></app-checkbox-element>
        </div>
      </div>
  </div>
  <div class="resp_maxSize_l flex gap_5 mb_20">
    <app-button border="true" class="resp_maxSize_l" (btnClicked)="nav.to('admin-request-select/' + query.paymentId, undefined, {queryParams: query})">
      <fa-icon icon="arrow-left"></fa-icon> 
    </app-button>
    <app-button type="button" (click)="skipFingerPrint()" class="fullWidth  resp_maxSize_l">{{'skip' | translate}}</app-button>
    <app-button type="button" *ngIf="!qualityFingerPrint" (click)="scanFingerprint()" class="fullWidth  resp_maxSize_l">{{'fingerprint.scan.start' | translate}}</app-button>
    <app-button *ngIf="qualityFingerPrint" color="red" type="button" (click)="retryFingerPrint()" class="fullWidth  resp_maxSize_l">{{'global.retry' | translate}}</app-button>
  </div>
  <app-button *ngIf="qualityFingerPrint" color="green" type="button" (click)="confirmFingerprint()" class="fullWidth  resp_maxSize_l mb_20">{{'global.confirm' | translate}}</app-button>
</div>
  