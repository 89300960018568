import {Component, Inject} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NavigateService, FormService, customDateValidator} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {CartService} from 'src/app/shared/services/cart.service';
import {enumToArrayObjectSelect} from 'src/app/shared/utils/enumToArrayObject';
import {DialogNewPersonComponent} from '../dialog-new-person/dialog-new-person.component';
import {format, addYears} from 'date-fns';
import {Gender} from 'src/app/shared/models/user';
import {RequestService} from 'src/app/shared/services/request.service';
import {PassFields, PassFieldsData} from 'src/app/shared/models/request';

@Component({
  selector: 'app-dialog-edit-passport',
  templateUrl: './dialog-edit-passport.component.html',
  styleUrl: './dialog-edit-passport.component.scss'
})
export class DialogEditPassportComponent {
  form: FormGroup;
  submitted = false;
  genderOptions: any;
  maxBirthDate = format(new Date(), 'yyyy-MM-dd');
  minExpiryDate = format(new Date(), 'yyyy-MM-dd');
  maxExpiryDate = format(addYears(new Date(), 100), 'yyyy-MM-dd');

  constructor(@Inject(MAT_DIALOG_DATA) public data: PassFieldsData,
    public cart: CartService,
    public dialogRef: MatDialogRef<DialogNewPersonComponent>,
    public nav: NavigateService,
    public forms: FormService,
    public api: ApiService,
    private formBuilder: FormBuilder,
    public request: RequestService) {
    this.setForm();
    this.genderOptions = enumToArrayObjectSelect(Gender, '').filter((item: any) => item.name !== Gender.OTHER);
  }

  setForm() {
    this.form = this.formBuilder.group({
      [PassFields.FIRST_NAME]: [
        this.data.firstName || '',
        this.data.firstName ? [Validators.required, Validators.pattern('^[A-Za-z \\-]+$')] : []
      ],
      [PassFields.LAST_NAME]: [
        this.data.lastName || '',
        this.data.lastName ? [Validators.required, Validators.pattern('^[A-Za-z \\-]+$')] : []
      ],
      [PassFields.BIRTH_DATE]: [
        this.data.birthDate ? format(new Date(this.data.birthDate), 'yyyy-MM-dd') : '',
        this.data.birthDate ? [
          Validators.required,
          customDateValidator({maxDate: new Date()})
        ] : []
      ],
      [PassFields.DOCUMENT_NUMBER]: [
        this.data.documentNumber || '',
        this.data.documentNumber ? [Validators.required, Validators.pattern('^[A-Za-z0-9 \\-]+$')] : []
      ],
      [PassFields.SEX]: [
        this.data.sex ? this.data.sex.toUpperCase() : '',
        this.data.sex ? [Validators.required] : []
      ],
      [PassFields.NATIONALITY]: [
        this.data.nationality || '',
        this.data.nationality ? [Validators.required] : []
      ],
      [PassFields.EXPIRATION_DATE]: [
        this.data.expirationDate ? format(new Date(this.data.expirationDate), 'yyyy-MM-dd') : '',
        this.data.expirationDate ? [
          Validators.required, 
          customDateValidator({minDate: new Date(), maxDate: addYears(new Date(), 10)
        })] : []
      ]
    });
  }

  submit() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.submitted = false;
      const updatedData: PassFieldsData = {};
      if(this.form.get(PassFields.FIRST_NAME)?.value !== this.data.firstName) {
        updatedData.firstName = this.form.get(PassFields.FIRST_NAME)?.value;
      }
      if(this.form.get(PassFields.LAST_NAME)?.value !== this.data.lastName) {
        updatedData.lastName = this.form.get(PassFields.LAST_NAME)?.value;
      }
      if(this.data.birthDate) {
        const formBirthDate = format(new Date(this.form.get(PassFields.BIRTH_DATE)?.value), 'yyyy-MM-dd');
        const dataBirthDate = format(new Date(this.data.birthDate), 'yyyy-MM-dd');
        if(formBirthDate !== dataBirthDate) {
          updatedData.birthDate = this.form.get(PassFields.BIRTH_DATE)?.value;
        }
      }
      if(this.form.get(PassFields.DOCUMENT_NUMBER)?.value !== this.data.documentNumber) {
        updatedData.documentNumber = this.form.get(PassFields.DOCUMENT_NUMBER)?.value;
      }
      if(this.form.get(PassFields.SEX)?.value.toLowerCase() !== this.data.sex.toLowerCase()) {
        updatedData.sex = this.form.get(PassFields.SEX)?.value;
      }
      if(this.form.get(PassFields.NATIONALITY)?.value !== this.data.nationality) {
        updatedData.nationality = this.form.get(PassFields.NATIONALITY)?.value;
      }
      if(this.data.expirationDate) {
        const formExpirationDate = format(new Date(this.form.get(PassFields.EXPIRATION_DATE)?.value), 'yyyy-MM-dd');
        const dataExpirationDate = format(new Date(this.data.expirationDate), 'yyyy-MM-dd');
        if(formExpirationDate !== dataExpirationDate) {
          updatedData.expirationDate = this.form.get(PassFields.EXPIRATION_DATE)?.value;
        }
      }
      this.closeDialog(updatedData);
    }
  }

  getMinExpirationDate() {
    return this.api.env.nationalityNoDeclaration.includes(this.form.get(PassFields.NATIONALITY)?.value) ? this.minExpiryDate : undefined;
  }

  closeDialog(modifiedData?: PassFieldsData) {
    this.dialogRef?.close(modifiedData);
  }

  protected readonly PassFields = PassFields;
  protected readonly format = format;
  protected readonly addYears = addYears;
}
