import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {LoaderService} from 'ngx-satoris';
import {RequestCashType, Request, RequestState} from '../models/request';
import {User, UserPlatformRole} from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  private users: {[id: number]: User} = {};
  supervisors: User[] = [];

  constructor(private api: ApiService, private loader: LoaderService) {}

  cacheUsers() {
    if(this.api.userInfo.role !== UserPlatformRole.CUSTOMER) {
      this.api.users(true).then(users => {
        this.users = {};
        users.result.forEach(u => this.users[u.id] = u);
        if(this.users) {
          this.supervisors = Object.values(this.users).filter(u => u.links.some(l => l.allowAdmin && l.place_id === this.api.userPlaceId));
        }
      }).catch(() => undefined);
    }
  }

  syncAll() {
    this.syncPayments();
  }

  userDisplay(userId: number) {
    if(this.users[userId]) return this.users[userId].accountName.split('@')[0];
    return userId;
  }

  addOfflineCashPayment(id: string, requestId: string, metadata: string, type: RequestCashType, createPlaceId: string,
    external_id?: string, confirmed = true) {
    const payment: Request = {
      id: id,
      requestId: requestId,
      metadata: metadata,
      type: type,
      created_place_id: createPlaceId,
      externalId: external_id,
      state: confirmed ? RequestState.PAID : RequestState.PAID_NOT_CONFIRMED
    };
    const payments = this.api.getStoredItem('payments_old') || [];
    payments.push(payment);
    this.api.storeItem('payments_old', payments);
  }

  syncPayments() {
    const payments: Request[] = this.api.getStoredItem('payments_old') || [];
    if(payments.length > 0) {
      this.loader.loading(true);
      payments.forEach((p: Request, index) => {
        this.api.registerCashPayment(p.id, p.requestId, p.metadata, p.type, p.created_place_id, undefined, p.externalId, undefined, p.state === RequestState.PAID)
          .catch(err =>  this.loader.loading(true, {type: 'error', message: err}));

        if(payments.length === index + 1) {
          this.api.removeStoredItem('payments_old');
          this.loader.loading(false);
        }
      });
    }
  }
}
